<script setup lang="ts">
    import {ref} from 'vue';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import {useClipboard} from '@vueuse/core';

    const props = defineProps<{
        modal: ModalType;
        token: string;
    }>();

    const clipboard = useClipboard();

    const copied = ref(false);

    function copyToken() {
        clipboard.copy(props.token);
        copied.value = true;
    }
</script>

<template>
    <MModal
        :header-title="__('user:key_added')"
        :modal="modal"
    >
        <div class="tw-mb-4 tw-text-gray-500">
            <FontAwesomeIcon
                class="tw-mr-1 tw-text-red-500"
                icon="fa-duotone fa-exclamation-triangle"
            />
            {{ __('user:copy_key_before_close') }}
        </div>

        <div class="tw-mb-4 tw-break-all">
            <CFormTextArea
                readonly
                :value="token"
            />
        </div>
        <MButton
            :label="__('common:actions.copy')"
            @click="copyToken"
        >
            <template
                v-if="copied"
                #left-icons
            >
                <FontAwesomeIcon
                    class="tw-text-green-500"
                    icon="fa-duotone fa-check"
                />
            </template>
        </MButton>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.close')"
                @click="hide"
            />
        </template>
    </MModal>
</template>
