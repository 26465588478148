<script setup lang="ts">
    import Vue, {watch} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    const props = defineProps<{
        configKey: Record<string, any>;
        onlyPrice: boolean;
    }>();

    const roundOptions = [
        {
            value: 'round',
            text: __('billing_setting:automatic'),
        },
        {
            value: 'up',
            text: __('billing_setting:upwards'),
        },
        {
            value: 'down',
            text: __('billing_setting:downwards'),
        },
    ];

    function initPrecision() {
        if (props.onlyPrice) {
            Vue.set(props.configKey, 'precision', {
                price: undefined,
                price_mode: undefined,
            });
        } else {
            Vue.set(props.configKey, 'precision', {
                price: undefined,
                price_mode: undefined,
                qty: undefined,
                qty_mode: undefined,
            });
        }
    }

    function resetPrecisionWhenEmpty() {
        // Reset to undefined if the user reset the input field. We flag it deep to watch the nested precision object.
        if (props.configKey.precision && !Number.isInteger(props.configKey.precision.price)) {
            props.configKey.precision.price = undefined;
            props.configKey.precision.price_mode = undefined;
        }

        if (props.onlyPrice) {
            return;
        }

        if (props.configKey.precision && !Number.isInteger(props.configKey.precision.qty)) {
            props.configKey.precision.qty = undefined;
            props.configKey.precision.qty_mode = undefined;
        }

        if (props.configKey.precision && !Number.isInteger(props.configKey.precision.total)) {
            props.configKey.precision.total = undefined;
            props.configKey.precision.total_mode = undefined;
        }
    }

    watch(
        () => props.configKey,
        () => {
            // Watch for changes in the configKey (ex: selecting another parent config) to set the default values if they are not set.
            // This is needed because the precision object is not always present in the configKey and we flag immediate
            // to set the default values when the component is mounted.
            if (!props.configKey.precision || Array.isArray(props.configKey.precision)) {
                initPrecision();

                return;
            }

            resetPrecisionWhenEmpty();
        },
        {immediate: true, deep: true}
    );
</script>

<template>
    <div>
        <div class="row tw-mt-2">
            <div class="col-12 col-lg-6">
                <div>{{ __('billing_setting:unit_price_round') }}</div>

                <div class="tw-flex tw-flex-col tw-gap-2 sm:tw-flex-row">
                    <CInputGroup>
                        <CInput
                            v-model.number="configKey.precision.price"
                            is-nullable
                            type="number"
                        />
                        <CInputAddon class="tw-lowercase">
                            <template v-if="configKey.precision.price && configKey.precision.price > 1">
                                {{ __('common:decimal_other') }}
                            </template>
                            <template v-else>
                                {{ __('common:decimal_one') }}
                            </template>
                        </CInputAddon>
                    </CInputGroup>
                    <CFormSelect
                        v-model="configKey.precision.price_mode"
                        :options="roundOptions"
                        :search-bar="false"
                    />
                </div>

                <template v-if="!onlyPrice">
                    <div class="tw-mt-2">
                        {{ __('billing_setting:quantity_round') }}
                    </div>
                    <div class="tw-flex tw-flex-col tw-gap-2 sm:tw-flex-row">
                        <CInputGroup>
                            <CInput
                                v-model.number="configKey.precision.qty"
                                is-nullable
                                type="number"
                            />
                            <CInputAddon class="tw-lowercase">
                                <template v-if="configKey.precision.qty && configKey.precision.qty > 1">
                                    {{ __('common:decimal_other') }}
                                </template>
                                <template v-else>
                                    {{ __('common:decimal_one') }}
                                </template>
                            </CInputAddon>
                        </CInputGroup>
                        <CFormSelect
                            v-model="configKey.precision.qty_mode"
                            :options="roundOptions"
                            :search-bar="false"
                        />
                    </div>
                </template>
            </div>
        </div>

        <div class="small tw-mt-2 tw-text-gray-400">
            {{ __('billing_setting:keep_empty_for_no_round') }}
        </div>
    </div>
</template>
