import {app} from '@meekohq/lumos';
import {BroadcastContractBinding} from '@/modules/app/utils/BroadcastContract';

export default function () {
    const broadcast = app(BroadcastContractBinding);

    return {
        sessionChannel: broadcast.sessionChannel,
        privateChannel: broadcast.privateChannel,
        notificationEvent: broadcast.notificationEvent,
    };
}
