<template>
    <textarea
        ref="inputEl"
        class="CFormTextArea"
        :class="baseClass"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :value="value"
        v-on="inputListeners($listeners)"
    />
</template>

<script lang="ts">
    import {computed, defineComponent, nextTick, onMounted, ref} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            preventBrowserStyling: {type: Boolean, default: false},
            value: {type: String},
            placeholder: {type: String},
            size: {type: String, default: 'default'},
            hasError: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            readonly: {type: Boolean, default: false},
            autofocus: {type: Boolean, default: false},
            type: {type: String, default: 'text'},
        },
        setup(props, {emit}) {
            const inputEl = ref<HTMLElement | null>(null);

            onMounted(() => {
                if (props.autofocus) {
                    nextTick(() => {
                        inputEl.value?.focus();
                    });
                }
            });

            const baseClass = computed(() => {
                const output = [] as string[];

                output.push('tw-relative tw-w-full');
                output.push('tw-transition tw-duration-200 focus:tw-z-10');

                const states = {
                    default: 'CFormTextArea CFormTextArea--light',
                    hasError: 'CFormTextArea CFormTextArea--light CFormTextArea--has-error',
                    readonly: 'CFormTextArea CFormTextArea--light CFormTextArea--is-readonly',
                };
                if (props.readonly) {
                    output.push(states['readonly']);
                } else {
                    output.push(states[props.hasError ? 'hasError' : 'default']);
                }

                if (props.preventBrowserStyling) {
                    output.push('CFormTextArea--preventBrowserStyling');
                }

                const sizes = {
                    default: 'tw-py-1 tw-px-3 tw-text-base',
                    lg: 'tw-py-2 tw-px-3 tw-text-lg',
                    sm: 'tw-py-1 tw-px-3 tw-text-sm',
                };
                output.push(sizes[props.size]);

                return output;
            });

            const inputListeners = function (l) {
                return Object.assign({}, l, {
                    input(event) {
                        emit('input', event.target.value);
                    },
                });
            };

            return {
                inputEl,
                baseClass,
                inputListeners,
            };
        },
    });
</script>

<style scoped>
    .CFormTextArea--preventBrowserStyling {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .CFormTextArea {
        @apply tw-relative;
    }

    .CFormTextArea--light {
        @apply tw-shadow-sm;
        @apply tw-border-0 tw-outline-0;
        @apply tw-ring-0 tw-ring-offset-1 focus:tw-ring-3;
        @apply tw-ring-transparent tw-ring-offset-gray-500/30 focus:tw-ring-primary-300 focus:tw-ring-offset-primary-500;

        @apply tw-transition-all tw-duration-100;
        @apply tw-text-black tw-placeholder-gray-400;
        @apply tw-rounded-md tw-bg-white;
        @apply disabled:tw-cursor-not-allowed disabled:tw-bg-gray-50 disabled:tw-text-gray-600;
    }

    .CFormTextArea--has-error {
        @apply tw-z-10;
        @apply tw-ring-offset-danger-600 focus:tw-ring-danger-300 focus:tw-ring-offset-danger-600;
    }

    .CFormTextArea--is-readonly {
        @apply tw-text-gray-700;
        @apply tw-ring-offset-gray-500/20 focus:tw-ring-gray-100 focus:tw-ring-offset-gray-400;
    }
</style>
