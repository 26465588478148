<script setup lang="ts">
    import type {PropType} from 'vue';
    import {computed, ref} from 'vue';
    import {collect, Epoch} from '@meekohq/lumos';
    import {useElementHover, whenever} from '@vueuse/core';
    import type {MDatePickerStoreType} from '@/modules/meeko-ui/composables/useMDatePicker';

    const props = defineProps({
        store: {
            type: Object as PropType<MDatePickerStoreType>,
            required: true,
        },
        day: {
            type: Object as PropType<Epoch>,
            required: true,
        },
        month: {
            type: Object as PropType<Epoch>,
            required: true,
        },
    });

    const emit = defineEmits<{
        (e: 'click', value: Epoch): void;
    }>();

    const dayElement = ref();
    const isHovered = useElementHover(dayElement);
    const {dates, hoveredDay, isPeriod, allowUndefined, virtualHoveredDay} = props.store;

    const firstPeriodDay = computed(() => {
        const collection = collect([dates.value.from, dates.value.to]);

        // If a date is missing, we add the hovered day to the collection
        if (!dates.value.from || !dates.value.to) {
            collection.push(virtualHoveredDay.value);
        }

        return Epoch.fromTimestamp(
            collection
                .reject(v => v === undefined)
                .map(v => v?.toTimestamp())
                .min()
        );
    });

    const lastPeriodDay = computed(() => {
        const collection = collect([dates.value.from, dates.value.to]);

        // If a date is missing, we add the hovered day to the collection
        if (!dates.value.from || !dates.value.to) {
            collection.push(virtualHoveredDay.value);
        }

        return Epoch.fromTimestamp(
            collection
                .reject(v => v === undefined)
                .map(v => v?.toTimestamp())
                .max()
        );
    });

    const isFromDay = computed(() => firstPeriodDay.value?.hasSame(props.day, 'day'));
    const isToDay = computed(() => lastPeriodDay.value?.hasSame(props.day, 'day'));
    const outOfMonth = computed(() => props.day.month !== props.month.month);

    /**
     * Check if day is before or after the period.
     */
    const isAnInfiniteDay = computed(() => {
        if (!isPeriod.value || !allowUndefined.value || props.store.virtualHoveredDay.value) {
            return false;
        }

        const before = !dates.value.from && props.day.lessThan(firstPeriodDay.value.startOfDay());
        const after = !dates.value.to && props.day.greaterThan(lastPeriodDay.value.endOfDay());

        return before || after;
    });

    /**
     * Check if day is selected.
     */
    const dayIsSelected = computed(() => {
        if (isPeriod.value) {
            return (isFromDay.value || isToDay.value) && (dates.value.from || dates.value.to);
        }

        return dates.value.from?.hasSame(props.day as Epoch, 'day');
    });

    /**
     * Check if day is in period.
     */
    const isInPeriod = computed(() => {
        if (!isPeriod.value) {
            return false;
        }

        return (
            firstPeriodDay.value &&
            lastPeriodDay &&
            props.day.between(firstPeriodDay.value.startOfDay(), lastPeriodDay.value.endOfDay(), true)
        );
    });

    whenever(isHovered, () => (hoveredDay.value = props.day));
</script>

<template>
    <div
        ref="dayElement"
        class="MDatePickerDay"
        @click="emit('click', day)"
    >
        <div
            class="MDatePickerDay__inner"
            :class="{
                'MDatePickerDay__inner--out-of-current-month': outOfMonth,
                'MDatePickerDay__inner--today': day.isToday,
                'MDatePickerDay__inner--selecting': (!dates.from || !dates.to) && !isAnInfiniteDay,
                'MDatePickerDay__inner--in-period': isInPeriod,
                'MDatePickerDay__inner--infinite': isAnInfiniteDay,
                'MDatePickerDay__inner--hovered': virtualHoveredDay?.hasSame(day, 'day'),
                'MDatePickerDay__inner--selected': dayIsSelected,
            }"
        >
            {{ day.toFormat('dd') }}
        </div>
    </div>
</template>

<style scoped>
    .MDatePickerDay {
        @apply tw-flex tw-aspect-square tw-w-9 tw-cursor-pointer tw-select-none tw-p-0.5;
    }

    .MDatePickerDay__inner {
        @apply tw-relative;
        @apply tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-rounded-md;
        @apply tw-text-black tw-transition-all;
    }

    .MDatePickerDay__inner--hovered {
        @apply tw-bg-gray-200;
    }

    .MDatePickerDay__inner--out-of-current-month {
        @apply tw-text-gray-400;
    }

    .MDatePickerDay__inner--today {
        &:before {
            @apply tw-absolute tw--right-0.5 tw--top-0.5 tw-content-[''];
            @apply tw-aspect-square tw-w-2 tw-rounded-full tw-bg-red-500;
        }
    }

    .MDatePickerDay__inner--in-period {
        &:not(.MDatePickerDay__inner--selecting) {
            @apply tw-bg-primary-200 tw-text-primary-900;
        }

        &.MDatePickerDay__inner--selecting {
            @apply tw-bg-gray-200;
        }
    }

    .MDatePickerDay__inner--selected {
        background-color: theme(backgroundColor.primary.500) !important;
        color: theme(textColor.white) !important;

        &.MDatePickerDay__inner--hovered {
            background-color: theme(backgroundColor.primary.600) !important;
        }
    }

    .MDatePickerDay__inner--infinite {
        @apply tw-bg-primary-200 tw-text-primary-900;
    }
</style>
