<template>
    <div>
        <CFormGroup class="tw-mb-4 tw-flex tw-items-center">
            <CLabel class="!tw-mb-0 tw-mr-2">
                {{ __('common:show_by') }}
            </CLabel>
            <CButtonGroup>
                <MButton
                    size="sm"
                    :variant="displayPreference === 'hour' ? 'primary' : 'light'"
                    @click="$emit('update:displayPreference', 'hour')"
                >
                    {{ __('common:hour_other') }}
                </MButton>
                <MButton
                    size="sm"
                    :variant="displayPreference === 'day' ? 'primary' : 'light'"
                    @click="$emit('update:displayPreference', 'day')"
                >
                    {{ __('common:day_other') }}
                </MButton>
                <MButton
                    size="sm"
                    :variant="displayPreference === 'halfday' ? 'primary' : 'light'"
                    @click="$emit('update:displayPreference', 'halfday')"
                >
                    {{ __('common:halfday_other') }}
                </MButton>
            </CButtonGroup>
        </CFormGroup>
        <CHStack
            class="tw-h-full"
            distribute="between"
            gap="2"
        >
            <div
                class="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-rounded-lg tw-bg-gray-50 tw-p-3 tw-text-center tw-text-gray-700"
            >
                <CText
                    as="div"
                    class="tw-mb-1 tw-w-full tw-tracking-wider"
                    font-size="xs"
                    font-weight="semibold"
                    uppercase
                >
                    {{ __('planning:total_counted_over_period') }}
                </CText>
                <CText
                    as="div"
                    class="tw-w-full"
                    font-size="lg"
                >
                    <FontAwesomeIcon
                        v-if="loading"
                        icon="fa-solid fa-circle-notch"
                        spin
                    />
                    <template v-else>
                        {{ totalAlreadyCountedText }}
                    </template>
                </CText>
            </div>
            <div
                class="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-rounded-lg tw-bg-blue-100 tw-p-3 tw-text-center tw-text-blue-600"
            >
                <CText
                    as="div"
                    class="tw-mb-1 tw-w-full tw-tracking-wider"
                    font-size="xs"
                    font-weight="semibold"
                    uppercase
                >
                    {{ __('planning:total_to_add') }}
                </CText>
                <CText
                    as="div"
                    class="tw-w-full"
                    font-size="lg"
                >
                    {{ totalToAddText }}
                </CText>
            </div>
            <div
                class="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-rounded-lg tw-bg-blue-100 tw-p-3 tw-text-center tw-text-blue-600"
            >
                <CText
                    as="div"
                    class="tw-mb-1 tw-tracking-wider"
                    font-size="xs"
                    font-weight="semibold"
                    uppercase
                >
                    {{ __('planning:new_total_over_period') }}
                </CText>
                <CText
                    as="div"
                    class="tw-w-full"
                    font-size="lg"
                    font-weight="semibold"
                >
                    {{ grandTotalText }}
                </CText>
            </div>
        </CHStack>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, nextTick, ref, watch} from 'vue';
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _debounce from 'lodash-es/debounce';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import useManager from '@/modules/app/composables/useManager';
    import {getTotalSummary} from '@/modules/legacy/libs/Summary';
    import {isHalfday, minutesToHours} from '@/modules/legacy/libs/planning/planning';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: {
            kid: {
                type: Object,
                required: true,
            },
            displayPreference: {
                type: String as PropType<'hour' | 'day' | 'halfday'>,
                required: true,
            },
            plannings: {
                type: Array as PropType<any[]>,
                required: true,
            },
            periodFilter: {
                type: Object as PropType<{from: string; to: string}>,
                required: true,
            },
        },
        setup(props) {
            const {legacyNursery: organization} = useManager();

            const summary = ref([]);
            const loading = ref(false);

            watch(
                () => props.periodFilter,
                () => {
                    nextTick(() => getSummary());
                },
                {immediate: true, deep: true}
            );

            function getSummary() {
                loading.value = true;
                debounceSummary();
            }

            const debounceSummary = _debounce(() => {
                useApi()
                    .legacy.get(
                        // @ts-ignore
                        route('nurseries.kids.plannings.summary', {
                            nurseries: props.kid.nursery_id,
                            kid: props.kid.id,
                        }),
                        {
                            params: {
                                from: moment(props.periodFilter.from).startOf('day').unix(),
                                to: moment(props.periodFilter.to).endOf('day').unix(),
                            },
                        }
                    )
                    .then(response => {
                        summary.value = response.data;
                        loading.value = false;
                    })
                    .catch(error => {
                        loading.value = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value) as string);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            }, 1000);

            const totalToAdd = computed(() => {
                const totalSummary: any[] = [];

                props.plannings.forEach(planning => {
                    const summaryItem = {
                        date: planning.started_at,
                        departure_overruns: [],
                        arrival_overruns: [],
                        am: {
                            absence: 0,
                            adaptation: 0,
                            occasional: 0,
                            recurrent: 0,
                        },
                        pm: {
                            absence: 0,
                            adaptation: 0,
                            occasional: 0,
                            recurrent: 0,
                        },
                    };

                    const start = moment(
                        `${planning.started_at} ${planning.start_time.toFormat('HH:mm')}`,
                        'YYYY-MM-DD HH:mm'
                    );
                    const end = moment(
                        `${planning.started_at} ${planning.end_time.toFormat('HH:mm')}`,
                        'YYYY-MM-DD HH:mm'
                    );

                    const minutes = end.diff(start, 'minutes');
                    const multipleHaldays =
                        isHalfday(planning, organization.value).am && isHalfday(planning, organization.value).pm;

                    switch (planning.type) {
                        case 'occasional':
                            if (multipleHaldays) {
                                summaryItem.am.occasional += minutes / 2;
                                summaryItem.pm.occasional += minutes / 2;
                            } else if (isHalfday(planning, organization.value).am) {
                                summaryItem.am.occasional += minutes;
                            } else if (isHalfday(planning, organization.value).pm) {
                                summaryItem.pm.occasional += minutes;
                            }
                            break;
                        case 'adaptation':
                            if (multipleHaldays) {
                                summaryItem.am.occasional += minutes / 2;
                                summaryItem.pm.occasional += minutes / 2;
                            } else if (isHalfday(planning, organization.value).am) {
                                summaryItem.am.adaptation += minutes;
                            } else if (isHalfday(planning, organization.value).pm) {
                                summaryItem.pm.adaptation += minutes;
                            }
                            break;
                        default:
                    }
                    totalSummary.push(summaryItem);
                });

                return getTotalSummary(totalSummary, props.displayPreference, false).planning;
            });
            const totalToAddText = computed(() => {
                switch (props.displayPreference) {
                    case 'hour':
                        return minutesToHours(totalToAdd.value);
                    case 'day':
                        return __('common:day_with_count', {count: totalToAdd.value});
                    case 'halfday':
                        return __('common:halfday_alternative_long', {count: totalToAdd.value});

                    default:
                        return 0;
                }
            });

            const totalAlreadyCounted = computed(() => {
                return getTotalSummary(summary.value, props.displayPreference, false).planning;
            });
            const totalAlreadyCountedText = computed(() => {
                switch (props.displayPreference) {
                    case 'hour':
                        return minutesToHours(totalAlreadyCounted.value);
                    case 'day':
                        return __('common:day_with_count', {count: totalAlreadyCounted.value});
                    case 'halfday':
                        return __('common:halfday_alternative_long', {count: totalAlreadyCounted.value});
                    default:
                        return totalAlreadyCounted.value;
                }
            });

            const grandTotal = computed(() => {
                return totalAlreadyCounted.value + totalToAdd.value;
            });
            const grandTotalText = computed(() => {
                switch (props.displayPreference) {
                    case 'hour':
                        return minutesToHours(grandTotal.value);
                    case 'day':
                        return __('common:day_with_count', {count: grandTotal.value});
                    case 'halfday':
                        return __('common:halfday_alternative_long', {count: grandTotal.value});
                    default:
                        return '';
                }
            });

            return {
                loading,
                totalToAddText,
                totalAlreadyCountedText,
                grandTotalText,
            };
        },
    });
</script>
