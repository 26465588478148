<template>
    <AbstractItem>
        <template #icon>
            <FontAwesomeIcon
                class="tw-text-purple-600"
                fixed-width
                icon="fa-duotone fa-file-lines"
                size="lg"
            />
        </template>
        <template #title>
            <div
                class="tw-flex-1 sm:tw-rounded-lg sm:tw-p-2"
                :class="{
                    'tw-cursor-pointer sm:tw-transition-colors sm:tw-ease-in-out sm:hover:tw-bg-gray-50': !isEditing,
                }"
                @click="isEditing ? undefined : setIsEditing(true)"
            >
                <a
                    :href="documentUrl"
                    target="_blank"
                    @click.stop
                >
                    <CText
                        class="tw-whitespace-pre-line tw-break-all"
                        font-size="lg"
                    >
                        <template v-if="document.exists">
                            {{ document.attributes.name }}
                            <FontAwesomeIcon
                                class="tw-ml-1"
                                icon="fa-duotone fa-external-link-alt"
                            />
                        </template>
                        <template v-else>
                            {{ __('common:document_one') }}
                        </template>
                    </CText>
                </a>
            </div>
        </template>
        <template #date>
            <CVStack
                v-if="createdAtFormatted"
                class="tw-whitespace-nowrap"
            >
                <CText variant="disabled">
                    {{ createdAtFormatted }}
                </CText>
            </CVStack>
        </template>
        <template #actions>
            <MMenu v-if="can('update', 'registrations')">
                <MMenuButton>
                    <MButton icon-ellipsis />
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        :disabled="isEditing"
                        :label="__('common:actions.update')"
                        @click="setIsEditing(true)"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-pen"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="can('delete', 'documents')"
                        :label="__('common:actions.delete')"
                        variant="danger"
                        @click="isAlertModalVisible = true"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-trash"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
            <AlertModal
                v-if="isAlertModalVisible"
                :text="__('document:delete_document_ask')"
                :title="__('document:delete_document')"
                @confirmed="remove"
                @hidden="isAlertModalVisible = false"
            />
        </template>
        <template
            v-if="isEditing"
            #content
        >
            <CVStack gap="4">
                <DocumentForm
                    :document="editedDocument"
                    :error-form="documentFormError"
                    :organizations="registration.organizations().value()"
                    :related-resource="registration"
                    :store="documentStore"
                />
                <CHStack
                    distribute="end"
                    gap="2"
                >
                    <MButton
                        :label="__('common:actions.cancel')"
                        @click="cancel"
                    />
                    <MButton
                        :loading="loading"
                        variant="primary"
                        @click="save"
                    >
                        {{ __('common:actions.save') }}
                    </MButton>
                </CHStack>
            </CVStack>
        </template>
    </AbstractItem>
</template>

<script lang="ts">
    import {computed, defineComponent, type PropType, type Ref, ref} from 'vue';
    import type DocumentModel from '@/modules/document/models/DocumentModel';
    import DocumentForm from '@/modules/document/components/organisms/DocumentForm.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import AbstractItem from '@/modules/registration/components/molecules/Timeline/AbstractItem.vue';
    import {useToggle} from '@vueuse/core';
    import type RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import useDocumentStore from '@/modules/document/stores/useDocumentStore';
    import useManager from '@/modules/app/composables/useManager';
    import AlertModal from '@/modules/app/components/organisms/AlertModal.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import useEpoch from '@/modules/app/composables/useEpoch';

    export default defineComponent({
        components: {AlertModal, DocumentForm, AbstractItem},
        props: {
            document: {
                type: Object as PropType<DocumentModel>,
                required: true,
            },
            registration: {
                type: Object as PropType<RegistrationModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {activeOrganization} = useManager();
            const {user} = useAuth();
            const {can} = useAbility();
            const {fromISOString, presets} = useEpoch();

            const isAlertModalVisible = ref(false);
            const [isEditing, setIsEditing] = useToggle(!props.document.exists);

            const documentStore = useDocumentStore(activeOrganization);
            const {
                document,
                documentFormError,
                loading,
                relatedResource,
                save,
                remove,
                setAvailableOrganizations,
                selectedOrganizations,
                bus,
            } = documentStore;

            const editedDocument = ref(props.document.clone() as DocumentModel) as Ref<DocumentModel>;

            const documentUrl = computed(() => document.value.attributes.link || document.value.computed.document_url);

            const createdAtFormatted = computed(() => {
                return props.document.computed.created_at
                    ? fromISOString(props.document.computed.created_at).toLocaleString(presets.DATETIME_SHORT)
                    : null;
            });

            // Clone the document to prevent changes to the cache
            document.value = props.document.clone();

            // Set store values
            const availableOrganizations = props.registration
                .organizations()
                .value()
                .filter(organization => {
                    return user.value
                        .organizations()
                        .value()
                        .contains(userOrga => userOrga.getKey() === organization.getKey());
                });
            setAvailableOrganizations(availableOrganizations);

            if (document.value.exists) {
                selectedOrganizations.value = document.value.organizations().value().toArray();
            }

            relatedResource.value = props.registration;

            bus.on('saved', () => {
                document.value.copyTo(props.document);
                setIsEditing(false);
            });

            bus.on('deleted', () => {
                isAlertModalVisible.value = false;
                emit('deleted', props.document);
            });

            function cancel() {
                editedDocument.value.rollback();
                setIsEditing(false);
                if (!props.document.exists) {
                    emit('deleted', props.document);
                }
            }

            return {
                isAlertModalVisible,
                editedDocument,
                isEditing,
                documentFormError,
                documentUrl,
                setIsEditing,
                save,
                remove,
                cancel,
                can,
                loading,
                documentStore,
                createdAtFormatted,
            };
        },
    });
</script>
