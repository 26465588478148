import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
import _forEach from 'lodash-es/forEach';
import NumberFormatter from '@/modules/app/utils/NumberFormatter';
import type {LegacyInvoiceLineType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceLineType';
import Calculator from '@/modules/app/utils/Calculator';

export class InvoiceAmountService {
    private readonly formatter: NumberFormatter;

    public constructor(country: string) {
        this.formatter = new NumberFormatter({
            countryCode: country,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            strategy: 'round',
        });
    }

    public sumInvoiceLines(invoice: LegacyInvoiceType): number {
        const calc = new Calculator(0);

        _forEach(invoice.lines, (line: LegacyInvoiceLineType) => {
            calc.sum(this.getLineTotal(line));
        });

        return this.formatter.parse(calc.result()).asNumber;
    }

    public getLineTotal(line: LegacyInvoiceLineType): number {
        const totalLine = Calculator.multiply(line.qty, line.price);

        return this.formatter.parse(totalLine).asNumber;
    }
}
