import {Epoch, Model} from '@meekohq/lumos';
import moment from 'moment';
import FamilyModel from '@/modules/family/models/FamilyModel';
import KidMemberPivot from '@/modules/family/models/KidMemberPivot';
import MemberModel from '@/modules/family/models/MemberModel';
import TagPivot from '@/modules/tag/models/TagPivot';
import {
    scopeWithActiveContract,
    scopeWithComingContract,
    scopeWithPastContractUntil,
} from '@/modules/family/utils/kid/scopeKidContract';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import KidOrganizationPivot from '@/modules/family/models/KidOrganizationPivot';
import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
import __ from '@/modules/app/utils/i18n-facade';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import TagModel from '@/modules/tag/models/TagModel';

export default class KidModel extends Model {
    public type = 'family/kids';

    public attributes: {
        id: string;
        account_id: string | undefined;
        organization_id: string | undefined;
        group_id: string | undefined;
        first_name: string | undefined;
        last_name: string | undefined;
        gender: string | undefined;
        hygiene: string | undefined;
        birthdate: string | undefined;
        family_id: string | undefined;
        avatar_url: string | undefined;
        email: string | undefined;
        phone: string | undefined;
        mobile_phone: string | undefined;
        office_phone: string | undefined;
    } = {
        id: this.uuid(),
        first_name: undefined,
        last_name: undefined,
        gender: undefined,
        hygiene: undefined,
        group_id: undefined,
        birthdate: undefined,
        family_id: undefined,
        account_id: undefined,
        organization_id: undefined,
        avatar_url: undefined,
        email: undefined,
        phone: undefined,
        mobile_phone: undefined,
        office_phone: undefined,
    };

    scopeWithActiveContract = scopeWithActiveContract;
    scopeWithPastContractUntil = scopeWithPastContractUntil;
    scopeWithComingContract = scopeWithComingContract;

    get fullname(): string {
        return `${this.attributes.first_name} ${this.attributes.last_name}`;
    }

    get isBoy() {
        return this.attributes.gender === 'male';
    }

    get isGirl() {
        return this.attributes.gender === 'female';
    }

    get birthdayComing(): number | null {
        const now = moment().startOf('day');
        const mappedBirthday = moment(this.attributes.birthdate).year(moment().year()).startOf('day');
        if (mappedBirthday.isBetween(now, moment().add(7, 'days'), undefined, '[]')) {
            return mappedBirthday.diff(now, 'day');
        }

        return null;
    }

    get humanAge() {
        return this.getHumanAgeAtDate(Epoch.now().startOfDay());
    }

    getHumanAgeAtDate(date: Epoch): string {
        if (!this.attributes.birthdate) {
            throw new Error('Birthdate is not set');
        }

        const birthdate = Epoch.fromISOString(this.attributes.birthdate).startOfDay();
        const compareDate = date.startOfDay();

        if (birthdate.lessThanOrEqualTo(compareDate)) {
            return birthdate.difference(compareDate).toHuman({M: 36}, {M: 'floor', Y: 'floor'});
        }

        return __('common:date_format.in_with_duration', {
            duration: compareDate.difference(birthdate).toHuman({M: 36}, {M: 'floor', Y: 'floor'}),
        });
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            KidOrganizationPivot,
            'kid_id',
            'organization_id'
        );
    }

    family() {
        return this.belongsTo('family', FamilyModel, 'family_id');
    }

    members() {
        return this.belongsToMany('members', MemberModel, KidMemberPivot, 'kid_id', 'family_member_id');
    }

    tagsPivots() {
        return this.hasMany('tagsPivots', TagPivot, 'resource_id').setQuery(query => {
            query.where('resource_type', '=', this.type);
        });
    }

    tags() {
        return this.morphToMany('tags', TagModel, TagPivot, 'resource', undefined, 'tag_id');
    }

    group() {
        return this.belongsTo('group', KidsGroupModel, 'group_id');
    }

    invoices() {
        return this.hasMany('invoices', InvoiceModel, 'kid_id');
    }
}
