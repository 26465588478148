import {ref} from 'vue';
import type {ValidationError} from '@meekohq/lumos';
import {app} from '@meekohq/lumos';
import __ from '@/modules/app/utils/i18n-facade';
import Bugsnag from '@bugsnag/js';

import useNotification from '@/modules/meeko-ui/composables/useNotification';

import {type ReportingSettingEntity} from '@/modules/meeko-family/domain/entities/ReportingSettingEntity';
import {StoreReportingSettingsInputPortBinding} from '@/modules/meeko-family/domain/use-cases/StoreReportingSettingsInputPort';

export function useSaveReportingSettings() {
    const isSaving = ref(false);
    const {success: notifySuccess, error: notifyError} = useNotification();

    async function save(organizationId: string, settings: ReportingSettingEntity[], onSuccess?: () => void) {
        isSaving.value = true;

        const interactor = app(StoreReportingSettingsInputPortBinding, {
            success() {
                notifySuccess(__('reporting:settings_saved_successfully'));
                onSuccess?.();
            },
            validationError(error: ValidationError) {
                notifyError(__('common:errors.generic'));
                Bugsnag.notify(error);
            },
            unexpectedError(error: Error) {
                notifyError(__('common:errors.generic'));
                Bugsnag.notify(error);
            },
        });

        await interactor.execute(organizationId, settings);

        isSaving.value = false;
    }

    return {
        isSaving,
        save,
    };
}
