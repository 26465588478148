<template>
    <div class="ResourceBadgeContent">
        <div
            class="ResourceBadgeContent__icon"
            :class="iconClass"
        >
            <FontAwesomeIcon
                class="tw-text-xs"
                :icon="resource.icon"
            />
        </div>
        <div class="ResourceBadgeContent__name">
            {{ resource.name }}
        </div>
        <div
            v-if="showDelete"
            class="ResourceBadgeContent__delete"
            @click.stop="$emit('delete')"
        >
            <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type {ResourceInterface} from '@/modules/app/composables/useResource';

    export default defineComponent({
        props: {
            resource: {
                type: Object as PropType<ResourceInterface>,
                required: true,
            },
            showDelete: {
                type: Boolean,
                default: true,
            },
        },

        setup(props) {
            const iconClass = computed(() => {
                const computedClass = [''];
                computedClass.push(props.resource.iconClass);

                return computedClass;
            });

            return {
                iconClass,
            };
        },
    });
</script>

<style scoped>
    .ResourceBadgeContent {
        @apply tw-relative tw-flex tw-flex-row tw-items-center;
    }

    .ResourceBadgeContent__icon {
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-h-5 tw-w-5;
        @apply tw-mr-2 tw-rounded-full;
        @apply tw-text-white;
        @apply tw-shrink-0;
    }

    .ResourceBadgeContent__name {
        @apply tw-font-semibold tw-text-primary-500;
        @apply tw-truncate tw-text-sm;
    }

    .ResourceBadgeContent__delete {
        @apply tw-absolute tw--right-2.5 tw--top-2.5;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-text-gray-400 hover:tw-text-red-500;
        @apply hover:tw-cursor-pointer;
    }
</style>
