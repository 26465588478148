import __ from '@/modules/app/utils/i18n-facade';
import TabletObservationImage from '@/assets/images/tablet/observation.svg';
import TabletAppleImage from '@/assets/images/tablet/apple_1.svg';
import TabletMoonImage from '@/assets/images/tablet/moon_1.svg';
import TabletBabyDiaperImage from '@/assets/images/tablet/baby_diaper.svg';
import TabletActivityImage from '@/assets/images/tablet/activity.svg';
import TabletThermometerImage from '@/assets/images/tablet/thermometer.svg';
import TabletBalanceImage from '@/assets/images/tablet/balance.svg';
import TabletPillsImage from '@/assets/images/tablet/pills.svg';
import TabletBedImage from '@/assets/images/tablet/baby_bed.svg';
import useAbility from '@/modules/app/composables/useAbility';
import type {LegacyPermission} from '@/modules/user/utils/LegacyUserGate';
import {computed} from 'vue';

export const useExportCategories = (permissions?: LegacyPermission[]) => {
    const {can} = useAbility(permissions);

    const categories = computed(() => {
        return [
            {
                key: 'stats',
                name: __('common:statistic_other'),
                items: [
                    {
                        key: 'planning_stats',
                        name: __('common:kid_other'),
                        icon: 'fa-solid fa-child-reaching',
                        style: 'color: #673ab7;',
                        hasPermission: can('read', 'invoices'),
                        hideWhenMultipleNurseries: true,
                    },
                    {
                        key: 'nursery_stats',
                        name: __('common:organization_one'),
                        icon: 'fa-solid fa-home',
                        style: 'color: #00bcd4;',
                        hasPermission: can('read', 'invoices'),
                        hideWhenMultipleNurseries: true,
                    },
                ],
            },
            {
                key: 'general',
                name: __('common:general'),
                items: [
                    {
                        key: 'documents',
                        name: __('common:document_other'),
                        icon: 'fa-duotone fa-copy fa-swap-opacity',
                        style: 'color: #673ab7;',
                        hasPermission: can('read', 'documents'),
                    },
                    {
                        key: 'registrations',
                        name: __('common:registration_other'),
                        icon: 'fa-duotone fa-pen-alt',
                        style: 'color: #00bcd4;',
                        hasPermission: can('read', 'registrations'),
                    },
                    {
                        key: 'invoices',
                        name: __('common:invoice_other'),
                        icon: 'fa-duotone fa-file-invoice',
                        style: 'color: #ff5722;',
                        hasPermission: can('read', 'invoices'),
                    },
                    {
                        key: 'proformas',
                        name: __('common:draft_invoice'),
                        icon: 'fa-duotone fa-file-invoice',
                        style: 'color: #f5ac81;',
                        hasPermission: can('read', 'invoices'),
                    },
                    {
                        key: 'transactions',
                        name: __('common:transactions'),
                        icon: 'fa-duotone fa-money-check-edit-alt',
                        style: 'color: #00d486;',
                        hasPermission: can('read', 'transactions'),
                    },
                    {
                        key: 'customers',
                        name: __('common:customer_other'),
                        icon: 'fa-duotone fa-file-user',
                        style: 'color: #FF7E23;',
                        hasPermission: can('read', 'invoices'),
                    },
                    {
                        key: 'invoice_stats_caf',
                        name: __('dashboard:caf_file'),
                        image: TabletObservationImage,
                        image_width: '15px',
                        hasPermission: can('read', 'invoices'),
                        hideWhenMultipleNurseries: true,
                    },
                    {
                        key: 'photos',
                        name: __('common:photos'),
                        icon: 'fa-duotone fa-images',
                        style: 'color: #2196f3;',
                        hasPermission: can('read', 'photos'),
                    },
                ],
            },
            {
                key: 'kid',
                name: __('common:kid_other'),
                items: [
                    {
                        key: 'kids',
                        name: __('common:kid_page'),
                        icon: 'fa-solid fa-child-reaching',
                        style: 'color: #673ab7;',
                        hasPermission: can('read', 'kids_details'),
                    },
                    {
                        key: 'kid_contracts',
                        name: __('common:contract_other'),
                        icon: 'fa-solid fa-handshake',
                        style: 'color: #00bcd4;',
                        hasPermission: can('read', 'kids_contracts'),
                    },
                    {
                        key: 'kid_contracts_deposits',
                        name: __('common:deposit_other'),
                        icon: 'fa-solid fa-money-check-pen',
                        style: 'color: #00bcd4;',
                        hasPermission: can('read', 'kids_contracts'),
                    },
                    {
                        key: 'kid_presences',
                        name: __('common:clocking_in_other'),
                        icon: 'fa-solid fa-clock',
                        style: 'color: #49c300;',
                        hasPermission: can('read', 'kids_planning'),
                    },
                    {
                        key: 'kid_absences',
                        name: __('common:absence_other'),
                        icon: 'fa-solid fa-calendar-minus',
                        style: 'color: #f44336;',
                        hasPermission: can('read', 'kids_planning'),
                    },
                    {
                        key: 'kid_meals',
                        name: __('common:meal'),
                        image: TabletAppleImage,
                        image_width: '15px',
                        hasPermission: can('read', 'kids_dailies'),
                    },
                    {
                        key: 'kid_naps',
                        name: __('common:naps'),
                        image: TabletMoonImage,
                        image_width: '17px',
                        hasPermission: can('read', 'kids_dailies'),
                    },
                    {
                        key: 'kid_diapers',
                        name: __('common:diaper'),
                        image: TabletBabyDiaperImage,
                        image_width: '14px',
                        hasPermission: can('read', 'kids_dailies'),
                    },
                    {
                        key: 'kid_activities',
                        name: __('common:activity_other'),
                        image: TabletActivityImage,
                        image_width: '17px',
                        hasPermission: can('read', 'kids_dailies'),
                    },
                    {
                        key: 'kid_temperatures',
                        name: __('common:temperatures'),
                        image: TabletThermometerImage,
                        image_width: '7px',
                        hasPermission: can('read', 'kids_dailies'),
                    },
                    {
                        key: 'kid_weights',
                        name: __('common:weight'),
                        image: TabletBalanceImage,
                        image_width: '17px',
                        hasPermission: can('read', 'kids_dailies'),
                    },
                    {
                        key: 'kid_drugs',
                        name: __('common:drugs'),
                        image: TabletPillsImage,
                        image_width: '17px',
                        hasPermission: can('read', 'kids_dailies'),
                    },
                    {
                        key: 'kid_observations',
                        name: __('common:observation_other'),
                        image: TabletObservationImage,
                        image_width: '15px',
                        hasPermission: can('read', 'kids_dailies'),
                    },
                    {
                        key: 'documents',
                        name: __('common:document_other'),
                        icon: 'fa-duotone fa-copy fa-swap-opacity',
                        style: 'color: #673ab7;',
                        hasPermission: can('read', 'kids_documents'),
                    },
                ],
            },
            {
                key: 'staff',
                name: __('common:team_one'),
                items: [
                    {
                        key: 'staffs',
                        name: __('common:team_card'),
                        icon: 'fa-duotone fa-users',
                        style: 'color: #e91e63;',
                        hasPermission: can('read', 'staffs_details'),
                    },
                    {
                        key: 'staff_contracts',
                        name: __('common:contract_other'),
                        icon: 'fa-solid fa-handshake',
                        style: 'color: #00bcd4;',
                        hasPermission: can('read', 'staffs_contracts'),
                    },
                    {
                        key: 'staff_events',
                        name: __('common:planning_other'),
                        icon: 'fa-solid fa-calendar-alt',
                        style: 'color: #49C300;',
                        hasPermission: can('read', 'staffs_planning'),
                    },
                    {
                        key: 'documents',
                        name: __('common:document_other'),
                        icon: 'fa-duotone fa-copy fa-swap-opacity',
                        style: 'color: #673ab7;',
                        hasPermission: can('read', 'staffs_documents'),
                    },
                ],
            },
            {
                key: 'family_member',
                name: __('common:parent_other'),
                items: [
                    {
                        key: 'family_members',
                        name: __('common:parent_card'),
                        icon: 'fa-solid fa-user-tie',
                        style: 'color: #673ab7;',
                        hasPermission: can('read', 'family_members_details'),
                    },
                    {
                        key: 'messages',
                        name: __('dashboard:exchanges'),
                        icon: 'fa-solid fa-comments',
                        style: 'color: #17A2B8;',
                        hasPermission: can('read', 'family_members_chat'),
                    },
                    {
                        key: 'documents',
                        name: __('common:document_other'),
                        icon: 'fa-duotone fa-copy fa-swap-opacity',
                        style: 'color: #673ab7;',
                        hasPermission: can('read', 'family_members_documents'),
                    },
                    {
                        key: 'sepa_mandates',
                        name: __('common:sepa_mandate_other'),
                        icon: 'fa-duotone fa-university',
                        style: 'color: #00bcd4;',
                        hasPermission: can('read', 'invoices'),
                    },
                ],
            },
            {
                key: 'nursery',
                name: __('common:organization_one'),
                items: [
                    {
                        key: 'equipment_temperatures',
                        name: __('common:equipment_other'),
                        icon: 'fal fa-temperature-frigid',
                        style: 'color: #8bc34a;',
                        hasPermission: can('read', 'equipments'),
                    },
                    {
                        key: 'room_checks',
                        name: __('common:room_other'),
                        image: TabletBedImage,
                        image_width: '18px',
                        hasPermission: can('read', 'rooms'),
                    },
                ],
            },
        ];
    });

    return {
        categories,
    };
};
