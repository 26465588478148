<template>
    <div>
        <div
            v-for="(week, key) in weeksCount"
            :key="'week_contract' + key"
            class="tw-mb-12"
        >
            <h5 class="h5 tw-mb-0">
                {{ __('family_contract:adaptation_week', {week: week}) }}
            </h5>
            <hr class="hr" />
            <div class="tw-mb-2 tw-table tw-w-full">
                <div
                    class="tw-hidden sm:tw-block"
                    style="padding-left: 85px; padding-right: 65px"
                >
                    <hours
                        :closing="closing"
                        :opening="opening"
                    />
                </div>
            </div>
            <div
                v-for="(day, i) in enumerateDaysBetweenDates(
                    weekStart.clone().add(week, 'weeks').format(),
                    weekEnd.clone().add(week, 'weeks').format()
                )"
                :key="'day_contract' + i"
                class="tw-mb-2 tw-table tw-w-full"
            >
                <div
                    class="day tw-float-none tw-mb-1 tw-font-display tw-capitalize sm:tw-float-left sm:tw-mb-0 sm:tw-pr-6 sm:tw-text-right"
                >
                    {{ day.format('dddd') }}
                </div>
                <div class="planning tw-float-none sm:tw-float-left sm:tw-pr-4">
                    <planning-generator
                        :closing="closing"
                        :day="day"
                        :events="data"
                        inner-text
                        :nursery="nursery"
                        :opening="opening"
                        :show-clock="false"
                        :user="user"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _forEach from 'lodash-es/forEach';
    import _concat from 'lodash-es/concat';
    import _find from 'lodash-es/find';
    import _map from 'lodash-es/map';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import moment from 'moment';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import _clone from 'lodash-es/clone';
    import {getPlanningEventsFromKidContractOrRegistration} from '@/modules/legacy/libs/Planning';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import {Epoch} from '@meekohq/lumos';

    export default {
        components: {
            hours: Hours,
        },
        mixins: [nursery],
        props: ['nursery', 'contract', 'user'],
        data: () => ({
            weeksCount: 1,
        }),
        computed: {
            data() {
                return this.computedPlanning;
            },
            opening() {
                return this.$getOpening(this.nursery.openingHours, false, false, this.computedPlanning);
            },
            closing() {
                return this.$getClosing(this.nursery.openingHours, false, false, this.computedPlanning);
            },
            exactOpening() {
                return this.$getOpening(this.nursery.openingHours, true, true, this.computedPlanning);
            },
            exactClosing() {
                return this.$getClosing(this.nursery.openingHours, true, true, this.computedPlanning);
            },
            weekStart() {
                return moment(this.contract.started_at).startOf('week').subtract(1, 'week');
            },
            weekEnd() {
                return this.weekStart.clone().endOf('week');
            },
            computedPlanning() {
                let events = [];

                // Create a copy of the contract to simulate the started date of the contract at now.
                // Because getPlanningEventsFromKidContractOrRegistration needed it to compute the plannings.
                // This function calculate week from the contract start date.
                const contractForPlannings = _cloneDeep(this.contract);
                contractForPlannings.started_at = Epoch.now().toFormat('yyyy-MM-dd');

                for (let i = 1; i <= this.weeksCount; i++) {
                    _forEach(
                        this.enumerateDaysBetweenDates(
                            this.weekStart.clone().add(i, 'weeks').format(),
                            this.weekEnd.clone().add(i, 'weeks').format()
                        ),
                        day => {
                            const contract = _clone(this.contract);
                            contract.plannings = contract.plannings.filter(planning => {
                                return planning.type === 'adaptation';
                            });

                            events = _concat(
                                events,
                                getPlanningEventsFromKidContractOrRegistration(day, contract, undefined, {
                                    onlyHours: true,
                                })
                            );
                        }
                    );
                }

                return events;
            },
        },
        mounted() {
            const adaptationPlannings = this.contract.plannings.filter(item => item.type === 'adaptation');
            const mapWeeks = _map(adaptationPlannings, 'week');
            if (mapWeeks.length) {
                this.weeksCount = Math.max(...mapWeeks);
            }
        },
        methods: {
            enumerateDaysBetweenDates(start, end) {
                const dates = [];

                const currDate = moment(start).startOf('day');
                const lastDate = moment(end).startOf('day');

                dates.push(currDate.clone());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    const day = currDate.clone().locale('en').format('ddd').toLowerCase();
                    if (day === 'sat' || day === 'sun') {
                        const hasDay = _find(this.nursery.openingHours, {day});
                        hasDay ? dates.push(currDate.clone()) : null;
                    } else {
                        dates.push(currDate.clone());
                    }
                }

                return dates;
            },
        },
    };
</script>

<style scoped>
    @media screen(sm) {
        .day {
            width: 100px;
        }

        .planning {
            width: calc(100% - 100px - 35px);
        }

        .add {
            width: 35px;

            .btn {
                width: 31px;
            }
        }
    }
</style>
