<template>
    <CModal
        ref="modal"
        :header-title="__('family_family:create_family')"
        size="xs"
        visible
        @closed="$emit('close')"
    >
        <FamilyForm
            ref="form"
            :name="name"
            :organization-model="organizationModel"
            @loading="loader.set($event)"
            @saved="$emit('created', $event)"
        />
        <template #footer>
            <MButton
                class="tw-ml-auto"
                :label="__('common:actions.close')"
                @click="$emit('close')"
            />
            <MButton
                class="tw-ml-2"
                :label="__('common:actions.save')"
                :loading="loader.isLoading()"
                variant="primary"
                @click="$refs.form.save()"
            />
        </template>
    </CModal>
</template>

<script lang="ts">
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import FamilyForm from '@/modules/family/components/family/FamilyForm.vue';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        components: {
            FamilyForm,
        },
        props: {
            organizationModel: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            name: {
                type: String,
                default: undefined,
            },
        },
        setup() {
            const loader = ref(new Loader());

            const form = ref();

            return {
                loader,
                form,
            };
        },
    });
</script>
