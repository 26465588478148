<template>
    <div>
        <apexcharts
            :options="chartOptions"
            :series="series"
            type="bar"
        />
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        components: {
            apexcharts: VueApexCharts,
        },
        props: {
            chartData: {},
            billingDisplay: {
                type: String,
                default: 'day',
            },
        },
        data() {
            return {
                chartOptions: {},
                series: [],
            };
        },
        watch: {
            chartData: {
                handler() {
                    this.updateChart();
                },
                deep: true,
            },
        },
        methods: {
            updateChart() {
                const self = this;

                this.series = self.chartData.series;

                this.chartOptions = {
                    chart: {
                        id: 'kid-chart',
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                    },
                    grid: {
                        show: false,
                    },
                    legend: {
                        show: false,
                    },
                    xaxis: {
                        categories: self.chartData.categories,
                    },
                    yaxis: {
                        labels: {
                            formatter(value) {
                                return self.formatValue(value);
                            },
                        },
                    },
                    colors: self.chartData.colors,
                    dataLabels: {
                        formatter(value) {
                            return self.formatValue(value);
                        },
                        offsetY: 2,
                        style: {
                            fontSize: '13px',
                            colors: ['#FFF'],
                        },
                        dropShadow: {
                            enabled: true,
                            top: 1,
                            left: 1,
                            blur: 1,
                            opacity: 0.5,
                        },
                    },
                    tooltip: {
                        shared: true,
                        followCursor: true,
                        intersect: false,
                        y: {
                            formatter(value) {
                                return self.formatValue(value);
                            },
                        },
                    },
                };
            },

            formatValue(value) {
                if (value === null || typeof value === 'undefined') {
                    return '';
                }

                if (this.billingDisplay === 'day') {
                    return value <= 1 ? value + ' jour' : value + ' jours';
                } else if (this.billingDisplay === 'halfDay') {
                    return value <= 1 ? value + ' demi-jour' : value + ' demi-jours';
                } else {
                    return this.getMin(value);
                }
            },

            getMin(value) {
                const m = value % 60;
                const h = (value - m) / 60;

                if (h === 0) {
                    return m + 'min';
                }

                return m > 0 ? h + 'h' + (m < 10 ? '0' : '') + m : h + 'h';
            },
        },
    };
</script>
