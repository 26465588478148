<template>
    <div
        ref="customersListItemEl"
        class="CustomersListItem"
    >
        <div class="CustomersListItem__container">
            <MagicIndexItemColumn
                :class="
                    showOrganization ? 'CustomersListItem__column-name' : 'CustomersListItem__column-name--expanded'
                "
                :grid-enabled="false"
            >
                <div class="tw-flex tw-min-w-0 tw-items-center tw-gap-3">
                    <BadgeTasks :tasks="tasks">
                        <CustomerAvatar
                            class="tw-w-10"
                            :customer-model="customer"
                        />
                    </BadgeTasks>
                    <div class="tw-flex tw-min-w-0 tw-flex-row tw-items-center tw-gap-4">
                        <div class="tw-flex tw-min-w-0 tw-flex-col tw-justify-center">
                            <MListItemTitle :title="customer.attributes.name" />
                            <div
                                v-if="customer.attributes.account_number"
                                class="tw-truncate tw-text-sm tw-text-gray-500"
                            >
                                {{ customer.attributes.account_number }}
                            </div>
                        </div>
                        <MTooltip v-if="customer.isArchived">
                            <CBadge>
                                {{ __('common:archived') }}
                            </CBadge>
                            <template #content>
                                {{
                                    __('billing_customer:archived_at', {
                                        date: Epoch.fromISOString(customer.attributes.archived_at).toLocaleString(
                                            Epoch.presets.DATE_SHORT
                                        ),
                                    })
                                }}
                            </template>
                        </MTooltip>
                    </div>
                </div>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                class="CustomersListItem__column-actions"
                :grid-enabled="false"
            >
                <MMenu>
                    <MMenuButton>
                        <MButton
                            icon-ellipsis
                            variant="ghost"
                            @click.prevent
                        />
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem
                            v-if="customer.isArchived"
                            :label="__('common:actions.unarchive')"
                            @click="unarchiveCustomer"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-box-archive"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuItem
                            v-else
                            :label="__('common:actions.archive')"
                            variant="danger"
                            @click="archiveCustomer"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-box-archive"
                                />
                            </template>
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                :class="
                    showOrganization ? 'CustomersListItem__column-status' : 'CustomersListItem__column-status--expanded'
                "
                :container-width="width"
                :grid-title="__('billing_customer:status')"
            >
                <CustomerBillingStatus :customer="customer" />
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                class="CustomersListItem__column-account-balance"
                :container-width="width"
                :grid-title="__('common:account_balance')"
            >
                <CustomersListItemDetailsPopover
                    class="tw-w-full"
                    :customer="customer"
                />
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                class="CustomersListItem__column-last-activity"
                :container-width="width"
                :grid-title="__('billing_customer:last_activity')"
            >
                {{
                    Epoch.fromISOString(customer.computed.balance_amount_updated_at).toLocaleString(
                        Epoch.presets.DATE_SHORT
                    )
                }}
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                v-if="showOrganization"
                class="CustomersListItem__column-organization"
                :container-width="width"
                :grid-title="__('common:organization_one')"
            >
                {{ customer.tenant().value()?.organizations().value().first()?.attributes.name }}
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                class="CustomersListItem__column-tags"
                :container-width="width"
                :grid-title="__('common:tags')"
            >
                <TagsSelector
                    prevent-default
                    size="sm"
                    stop-propagation
                    :taggable-model="customer"
                    taggable-type="customer"
                    :tags="customer.tags().value().toArray()"
                    @attached="customer.tags().value().push($event)"
                    @detached="customer.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                />
            </MagicIndexItemColumn>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, type PropType, ref} from 'vue';
    import {Epoch, type ModelCollection} from '@meekohq/lumos';
    import useArchiveCustomer from '@/modules/cashier/composables/core/customer/useArchiveCustomer';
    import type {MagicMassSelectType} from '@/modules/app/composables/useMagicMassSelect';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import {useElementSize} from '@vueuse/core';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import CustomersListItemDetailsPopover from '@/modules/cashier/components/core/customer/molecules/CustomersListItemDetailsPopover.vue';
    import CustomerBillingStatus from '@/modules/cashier/components/core/customer/atoms/CustomerBillingStatus.vue';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import CustomerAvatar from '@/modules/app/components/atoms/avatars/CustomerAvatar.vue';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';

    export default defineComponent({
        components: {
            MagicIndexItemColumn,
            CustomerAvatar,
            TagsSelector,
            CustomerBillingStatus,
            CustomersListItemDetailsPopover,
            MListItemTitle,
            BadgeTasks,
        },
        props: {
            customer: {type: Object as PropType<CustomerModel>, required: true},
            tasks: {
                type: Object as PropType<ModelCollection>,
                default: undefined,
            },
            massSelect: {type: Object as PropType<MagicMassSelectType>, default: undefined, required: false},
            showOrganization: {type: Boolean, default: false},
        },
        setup(props, {emit}) {
            /**
             * Archive customer
             */
            const {archiveByModel, unarchiveByModel} = useArchiveCustomer();
            const customersListItemEl = ref(null);
            const {width} = useElementSize(customersListItemEl);

            async function archiveCustomer() {
                await archiveByModel(props.customer);
                emit('archived', props.customer);
            }

            async function unarchiveCustomer() {
                await unarchiveByModel(props.customer);
                emit('unarchived', props.customer);
            }

            return {
                customersListItemEl,
                width,
                archiveCustomer,
                unarchiveCustomer,
                Epoch,
            };
        },
    });
</script>

<style scoped>
    .CustomersListItem {
        container-name: CustomersListItem;
        container-type: inline-size;
    }

    .CustomersListItem__container {
        @apply tw-grid tw-grid-cols-24 tw-gap-3;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-items-center;
        }
    }

    .CustomersListItem__column-name {
        @apply tw-col-span-20;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-col-span-6;
        }
    }

    .CustomersListItem__column-name--expanded {
        @apply tw-col-span-20;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-col-span-10;
        }
    }

    .CustomersListItem__column-actions {
        @apply tw-col-span-4;
        @apply tw-order-none;
        @apply tw-text-right;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-order-last tw-col-span-1;
        }
    }

    .CustomersListItem__column-status {
        @apply tw-col-span-12;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }
    }

    .CustomersListItem__column-status--expanded {
        @apply tw-col-span-full;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }
    }

    .CustomersListItem__column-account-balance {
        @apply tw-col-span-12;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-col-span-3;
        }
    }

    .CustomersListItem__column-last-activity {
        @apply tw-col-span-12;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-col-span-3;
        }
    }

    .CustomersListItem__column-organization {
        @apply tw-col-span-12;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }
    }

    .CustomersListItem__column-tags {
        @apply tw-col-span-full;

        @container CustomersListItem (min-width: 48rem) {
            @apply tw-col-span-3;
        }
    }
</style>
