<template>
    <div>
        <transition>
            <div v-if="!loading">
                <MHeading class="tw-mb-4">
                    {{ __('common:the_kids') }}
                </MHeading>
                <div
                    v-for="(kid, i) in kids"
                    :key="'kid' + i"
                    class="tw-flex tw-flex-col lg:tw-flex-row"
                    style="margin: 0 -20px; padding: 15px 20px; border-top: 1px solid #ecf0f4"
                >
                    <div class="tw-flex tw-items-center lg:tw-order-1">
                        <LegacyKidAvatar
                            class="tw-w-12"
                            :kid="kid"
                        />
                        <div class="tw-ml-4 tw-mr-auto tw-font-grandhotel tw-text-3xl tw-capitalize">
                            <router-link
                                :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                :to="{name: 'kids.show', params: {nursery: nursery.id, kid: kid.id}}"
                            >
                                {{ $fullName(kid) }}
                            </router-link>
                        </div>
                    </div>
                    <div
                        class="meta tw-mt-4 tw-flex tw-flex-col lg:tw-order-2 lg:tw-ml-auto lg:tw-mr-6 lg:tw-mt-0 lg:tw-flex-row lg:tw-items-center"
                    >
                        <div class="tw-mb-0 tw-mr-4 tw-text-lg lg:tw-text-right">
                            <template v-if="kid.permissions && kid.permissions.length">
                                <div class="tw-hidden lg:tw-block">
                                    <MTooltip :label="__('family_member:access_parent_application_enabled')">
                                        <FontAwesomeIcon
                                            class="tw-text-green-500"
                                            fixed-width
                                            icon="fa-solid fa-mobile"
                                            size="lg"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="tw-block lg:tw-hidden">
                                    <FontAwesomeIcon
                                        class="tw-text-green-500"
                                        fixed-width
                                        icon="fa-solid fa-mobile"
                                        size="lg"
                                    />
                                    {{ __('family_member:access_parent_application_enabled') }}
                                </span>
                            </template>
                            <template v-else>
                                <div class="tw-hidden lg:tw-block">
                                    <MTooltip :label="__('family_member:access_parent_application_disabled')">
                                        <FontAwesomeIcon
                                            class="tw-text-gray-500"
                                            fixed-width
                                            icon="fa-solid fa-mobile"
                                            size="lg"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="tw-block lg:tw-hidden">
                                    <FontAwesomeIcon
                                        class="tw-text-gray-500"
                                        fixed-width
                                        icon="fa-solid fa-mobile"
                                        size="lg"
                                    />
                                    {{ __('family_member:access_parent_application_disabled') }}
                                </span>
                            </template>
                        </div>
                        <div class="tw-mb-0 tw-mr-4 tw-text-lg lg:tw-text-right">
                            <template v-if="kid.can_pickup_kid">
                                <div class="tw-hidden lg:tw-block">
                                    <MTooltip :label="__('family_member:pick_up_child.authorized')">
                                        <FontAwesomeIcon
                                            class="tw-text-green-500"
                                            fixed-width
                                            icon="fa-solid fa-hands-helping"
                                            size="lg"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="tw-block lg:tw-hidden">
                                    <FontAwesomeIcon
                                        class="tw-text-green-500"
                                        fixed-width
                                        icon="fa-solid fa-hands-helping"
                                        size="lg"
                                    />
                                    {{ __('family_member:pick_up_child.authorized') }}
                                </span>
                            </template>
                            <template v-else>
                                <div class="tw-hidden lg:tw-block">
                                    <MTooltip :label="__('family_member:pick_up_child.unauthorized')">
                                        <FontAwesomeIcon
                                            class="tw-text-gray-500"
                                            fixed-width
                                            icon="fa-solid fa-hands-helping"
                                            size="lg"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="tw-block lg:tw-hidden">
                                    <FontAwesomeIcon
                                        class="tw-text-gray-500"
                                        fixed-width
                                        icon="fa-solid fa-hands-helping"
                                        size="lg"
                                    />
                                    {{ __('family_member:pick_up_child.unauthorized') }}
                                </span>
                            </template>
                        </div>
                    </div>
                    <div class="tw-mt-2 tw-self-center lg:tw-order-4 lg:tw-mt-0">
                        <MPopover placement="left">
                            <MButton
                                :label="__('family_member:update_rights')"
                                variant="primary"
                            />
                            <template #content>
                                <CForm @submit.prevent>
                                    <CFormGroup>
                                        <MemberPermissionsInput
                                            v-model="kid.permissions"
                                            container-class="tw-min-w-72"
                                            :portal="false"
                                            @input="updateKidPivot(kid, i)"
                                        />
                                    </CFormGroup>
                                    <CFormGroup>
                                        <CLabel icon="fa-solid fa-hands-helping">
                                            {{ __('family_member:pick_up_child') }}
                                        </CLabel>
                                        <CFormSelect
                                            v-model="kid.can_pickup_kid"
                                            :options="[
                                                {value: 1, text: __('common:authorized')},
                                                {value: 0, text: __('common:unauthorized')},
                                            ]"
                                        />
                                    </CFormGroup>
                                </CForm>
                            </template>
                        </MPopover>
                    </div>
                </div>
                <h1
                    v-if="!Object.keys(kids).length"
                    class="h1 tw-text-center tw-font-grandhotel"
                >
                    {{ __('common:no_kids') }}
                </h1>
            </div>
            <div
                v-else
                key="loader"
            >
                <loader custom-class="la-2x" />
            </div>
        </transition>
        <!--<store-family-member :nursery="nursery" :kid="kid" :familyMember="newFamilyMember" @add="storeFamilyMember"></store-family-member>
            <edit-family-member :nursery="nursery" :kid="kid" :familyMember="selectedFamilyMember" :withPivot="true" @update="updateFamilyMember" @delete="deleteFamilyMember" @detach="detachFamilyMember"></edit-family-member>
        -->
    </div>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import MemberPermissionsInput from '@/modules/family/components/member/MemberPermissionsInput.vue';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {LegacyKidAvatar, MemberPermissionsInput},
        props: ['nursery', 'familyMember'],
        data: () => ({
            loading: 1,
            kids: [],
            selectedKid: {},
            newKid: {},
            defaultPermissions: {
                all: [
                    'photos',
                    'meals',
                    'temperatures',
                    'drugs',
                    'diapers',
                    'naps',
                    'weights',
                    'presences',
                    'activities',
                    'observations',
                    'messages',
                    'invoices',
                    'transactions',
                    'staffs',
                    'posts',
                    'documents',
                ],
                intermediary: ['photos', 'messages', 'invoices', 'transactions', 'staffs', 'posts', 'documents'],
            },
        }),
        watch: {
            'familyMember.id': function () {
                this.getKids();
            },
        },
        mounted() {
            this.getKids();
        },
        methods: {
            getKids() {
                this.loading = 1;
                useApi()
                    .legacy.get(
                        route('nurseries.familyMembers.kids.index', {
                            nurseries: this.nursery.id,
                            familyMember: this.familyMember.id,
                        })
                    )
                    .then(response => {
                        this.loading = 0;
                        this.kids = response.data;
                    })
                    .catch(error => {
                        useNotification().error(error);
                    });
            },
            updateKidPivot(kid, i) {
                Vue.set(this.kids, i, kid);
                useApi()
                    .legacy.put(
                        route('nurseries.familyMembers.kids.update', {
                            nurseries: this.nursery.id,
                            familyMember: this.familyMember.id,
                            kid: kid.id,
                        }),
                        kid
                    )
                    .then(() => {
                        //
                    })
                    .catch(error => {
                        useNotification().error(error);
                    });
            },
        },
    };
</script>

<style scoped>
    .name {
        @media screen(lg) {
            width: calc(100% - 520px) !important;
        }

        a {
            color: inherit;
        }
    }

    .avatar {
        width: 50px;
        height: 50px;
    }

    .meta {
        /*.group {
            @media screen(lg) {
                width: 200px !important;
            }
        }*/
        .date {
            @media screen(lg) {
                width: 100px !important;
            }
        }
    }
</style>
