import type {Ref} from 'vue';
import type {Collection} from '@meekohq/lumos';
import type EventModel from '@/modules/human-resources/models/EventModel';
import {EventStatus} from '@/modules/human-resources/utils/calendar/Values/EventStatus';
import useAbility from '@/modules/app/composables/useAbility';

export function filterEvents(
    events: Collection<EventModel> | undefined,
    eventFilters: Ref<string[]>,
    onlyValidated = false
) {
    const {can} = useAbility();

    if (can('read', 'hr_request') && !onlyValidated) {
        events = events?.filter(event => event.attributes.status === EventStatus.validated || event.isPending);
    } else {
        events = events?.filter(event => event.attributes.status === EventStatus.validated);
    }

    // No forecast in filter array -> return real events
    if (!eventFilters.value.includes('forecast')) {
        events = events?.filter(event => !event.attributes.forecast);
    }
    // No real in filter array -> return forecast events
    if (!eventFilters.value.includes('real')) {
        events = events?.filter(event => event.attributes.forecast === true);
    }

    // Same logic but need to check type relation to compare "supervise"
    if (!eventFilters.value.includes('supervise')) {
        events = events?.filter(event => {
            // No need to check type because event has his own supervise value
            if (event.attributes.supervise_kid === false) {
                return true;
            }

            // Event is forecast, so check type supervision
            if (event.attributes.forecast) {
                return !event.eventType().value().attributes.enable_supervision_for_forecast;
            } else {
                return !event.eventType().value().attributes.enable_supervision_for_actual;
            }
        });
    }
    // Same logic if unsupervise filter is checked
    if (!eventFilters.value.includes('unsupervise')) {
        events = events?.filter(event => {
            if (event.attributes.supervise_kid) {
                return true;
            }

            if (event.attributes.forecast) {
                return event.eventType().value().attributes.enable_supervision_for_forecast === true;
            } else {
                return event.eventType().value().attributes.enable_supervision_for_actual === true;
            }
        });
    }

    return events;
}
