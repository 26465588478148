<template>
    <div class="RoomItem">
        <div class="RoomItem__header">
            <div class="RoomItem__icon-container">
                <FontAwesomeIcon icon="fa-solid fa-moon-stars" />
            </div>
            <MListItemTitle
                class="tw-truncate"
                :title="room.name"
            />
        </div>
        <MMenu v-if="can('update', 'rooms') || (room.id && can('delete', 'rooms'))">
            <MMenuButton>
                <MButton
                    icon-ellipsis
                    variant="ghost"
                />
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    v-if="can('update', 'rooms')"
                    :label="__('common:actions.update')"
                    @click="$emit('edit')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-pen"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    v-if="room.id && can('delete', 'rooms')"
                    :label="__('common:actions.delete_dots')"
                    variant="danger"
                    @click="$emit('delete')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import useAbility from '@/modules/app/composables/useAbility';

    export default defineComponent({
        components: {
            MListItemTitle,
        },
        props: {
            room: {
                type: Object,
                required: true,
            },
        },
        setup() {
            const {can} = useAbility();

            return {
                can,
            };
        },
    });
</script>

<style>
    .RoomItem {
        @apply tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-6;
    }

    .RoomItem__header {
        @apply tw-flex tw-items-center tw-gap-2 tw-truncate;
    }

    .RoomItem__icon-container {
        @apply tw-h-12 tw-w-12;
        @apply tw-rounded-full tw-bg-blue-100 tw-text-2xl;
        @apply tw-flex tw-shrink-0 tw-items-center tw-justify-center;
    }
</style>
