import ContractModel from '@/modules/human-resources/models/ContractModel';
import type {Ref} from 'vue';
import {computed, ref, watch} from 'vue';
import {ContractAggregate} from '@/modules/human-resources/contract/domain/ContractAggregate';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import type ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import type BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import type {ModelCollection} from '@meekohq/lumos';
import {Epoch} from '@meekohq/lumos';
import {ContractAggregateError} from '@/modules/human-resources/contract/domain/errors/ContractAggregateError';
import useError from '@/modules/app/composables/useError';
import {ContractErrorMapper} from '@/modules/human-resources/contract/infrastructure/ContractErrorMapper';

export function useContratState(staff: StaffModel, contract?: ContractModel) {
    const contractCopy = ref(initContract()) as Ref<ContractModel>;
    const trialPeriods = computed(() => contractCopy.value.trialPeriods().value());
    const balanceAllocations = computed(() => contractCopy.value.balanceAllocations().value());
    const organizations = computed(() => contractCopy.value.organizations().value());

    // Initialize the contract with the staff and the current date if it doesn't exist
    function initContract(): ContractModel {
        if (contract?.exists) {
            return contract.clone();
        }

        const newContract = new ContractModel();
        newContract.attributes.account_id = staff.attributes.account_id;
        newContract.attributes.staff_id = staff.getKey();
        newContract.attributes.started_at = Epoch.now().startOf('day').toISOString();
        newContract.attributes.hours_per_week = 35;

        return newContract;
    }

    const stateErrors = useError(ContractErrorMapper.CUSTOM_ERROR_MESSAGES);

    function preValidateContract(
        contract: ContractModel,
        staff: StaffModel,
        trialPeriods: ModelCollection<ContractTrialPeriodModel>,
        balanceAllocations: ModelCollection<BalanceAllocationModel>
    ) {
        stateErrors.reset();

        try {
            // Try to create a new ContractAggregate instance to validate the contract
            new ContractAggregate(staff, contract, trialPeriods.toArray(), balanceAllocations.toArray());
        } catch (error) {
            if (error instanceof ContractAggregateError) {
                stateErrors.addValidationError(ContractErrorMapper.mapFromContractAggregateError(error));

                return;
            }

            throw error;
        }
    }

    // Validate the contract when it changes
    watch(
        contractCopy,
        () => {
            preValidateContract(contractCopy.value, staff, trialPeriods.value, balanceAllocations.value);
        },
        {deep: true, immediate: true}
    );

    return {
        contractCopy,
        trialPeriods,
        balanceAllocations,
        organizations,
        stateErrors,
    };
}
