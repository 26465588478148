<template>
    <loader
        v-if="loading"
        class="tw-mt-4"
        light="true"
        shadow="false"
        :title="__('common:loading_dots')"
    />
    <div
        v-else
        id="doc-template"
        class="tw-mt-4"
        style="font-family: Arial, sans-serif"
    >
        <textarea
            :id="id"
            class="form-control input"
            :disabled="disabled"
        />
    </div>
</template>

<script>
    import 'trumbowyg';
    import TrumbowygIcons from 'trumbowyg/dist/ui/icons.svg';
    import 'trumbowyg/dist/langs/fr.min.js';
    import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js';
    import 'trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js';
    import 'trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min.js';
    import '@/modules/legacy/libs/trumbowyg/trumbowyg.variables.js';
    import '@/modules/legacy/libs/trumbowyg/trumbowyg.fontSize.js';
    import {Base64} from 'js-base64';

    export default {
        props: {
            editorVariables: {type: Array},
            value: {},
            id: {
                type: String,
                default: 'trumbowyg',
            },
            loading: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                TrumbowygIcons,
            };
        },
        watch: {
            loading(val) {
                if (!val) {
                    this.$nextTick(() => {
                        this.init();
                    });
                }
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.init();
            });
        },
        methods: {
            init() {
                const self = this;
                const el = $('#' + this.id);
                el.trumbowyg({
                    btns: [
                        ['undo', 'redo'],
                        ['fontsize'],
                        ['strong', 'em', 'underline'],
                        ['foreColor'],
                        ['removeformat'],
                        ['justifydrop', 'lineheight'],
                        ['horizontalRule'],
                        ['insertImage'],
                        ['viewHTML'],
                        self.editorVariables,
                        ['fullscreen'],
                    ],
                    btnsDef: {
                        justifydrop: {
                            dropdown: ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                            ico: 'justifyCenter',
                        },
                    },
                    lang: 'fr',
                    svgPath: TrumbowygIcons,
                    imageWidthModalEdit: true,
                    autogrow: true,
                })
                    .on('tbwchange', () => {
                        self.$emit('input', el.trumbowyg('html'));
                    })
                    .on('tbwpaste', () => {
                        self.$emit('input', el.trumbowyg('html'));
                    });
                el.trumbowyg('html', Base64.decode(this.value));
                this.$emit('input', Base64.decode(this.value));
            },
        },
    };
</script>

<style scoped>
    @import 'trumbowyg/dist/ui/trumbowyg.min.css';
    @import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css';
</style>

<style>
    .trumbowyg {
        margin: 0;
        background-color: #fff;
    }

    .trumbowyg-fullscreen.trumbowyg-box {
        overflow: auto;
    }

    .trumbowyg-button-pane {
        background: #f1f8ff;
    }

    .trumbowyg-dropdown {
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    #doc-template {
        .trumbowyg-editor {
            margin: 0.5cm auto !important;
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 1cm 2cm 2cm;
            width: 90%;
            max-width: 22cm;
            min-height: 21cm;
        }

        body.document-editor,
        body.document-editor td,
        body.document-editor th {
            font-size: 0.9em;
        }

        body.document-editor table {
            margin-top: 0.5cm;
            margin-bottom: 0.5cm;
        }

        body.document-editor h1 {
            margin-bottom: 1cm;
        }
    }
</style>
