<script setup lang="ts">
    import {ref} from 'vue';
    import usePopover from '@/modules/app/composables/usePopover';
    import MCalendarInput from '@/modules/meeko-ui/components/MCalendarInput/MCalendarInput.vue';
    import MCalendar from '@/modules/meeko-ui/components/MCalendar/MCalendar.vue';
    import type {DateTimeSingle} from '@/modules/meeko-ui/types/DateTime';
    import type {DateTimeModifierSingle} from '@/modules/meeko-ui/composables/useDateTimeModifiers';
    import type {MCalendarPreset} from '@/modules/meeko-ui/components/MCalendar/useMCalendarPresets';

    withDefaults(
        defineProps<{
            value: DateTimeSingle;
            modifier?: DateTimeModifierSingle;
            presets?: MCalendarPreset[];
            overridePresets?: boolean;
            hidePresets?: boolean;
            disabled?: boolean;
            allowUndefined?: boolean;
        }>(),
        {
            modifier: undefined,
            presets: undefined,
            overridePresets: false,
            hidePresets: false,
            disabled: false,
            allowUndefined: false,
        }
    );

    const emit = defineEmits<{
        (e: 'input', value: DateTimeSingle): void;
    }>();

    const popover = usePopover();
    const inputEl = ref<InstanceType<typeof MCalendarInput>>();

    function handleFocusChange(value: boolean) {
        if (value) {
            popover.show();

            return;
        }

        popover.hide();
    }
</script>

<template>
    <CPopover
        :clickable="!disabled"
        :popover="popover"
        prevent-hide-on-reference-click
        @hidden="inputEl?.resetFocus()"
    >
        <MCalendarInput
            ref="inputEl"
            :allow-undefined="allowUndefined"
            :disabled="disabled"
            mode="time"
            :modifiers="modifier"
            :value="value"
            @focus="handleFocusChange"
            @input="emit('input', $event)"
        />
        <template #content>
            <MCalendar
                :allow-undefined="allowUndefined"
                :hide-presets="hidePresets"
                mode="time"
                :modifiers="modifier"
                :override-presets="overridePresets"
                :presets="presets"
                :value="value"
                @input="emit('input', $event)"
            />
        </template>
    </CPopover>
</template>
