import type {Ref} from 'vue';
import {computed, ref, watch} from 'vue';
import type {Model} from '@meekohq/lumos';
import {collect, ModelCollection} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import type {AvailableCategory} from '@/modules/app/composables/useResourceSelectorCategories';

export default function (
    value: Ref<ModelCollection<Model> | Model | undefined>,
    allowedCategories: Ref<AvailableCategory[]>,
    selectedCategory: Ref<AvailableCategory>,
    isMultiSelect: Ref<boolean>,
    context: {emit: Function},
    bus: {emit: Function}
) {
    const selectedResources = ref<ModelCollection<Model> | Model | undefined>(value.value);

    // Short computed to check if there is a selected resource
    const hasSelectedResource = computed(() => {
        if (selectedResources.value instanceof ModelCollection) {
            return selectedResources.value.isNotEmpty();
        } else {
            return !!selectedResources.value;
        }
    });

    // Short computed to work only with a collection
    const collectionSelectedResources = computed(() => {
        if (selectedResources.value instanceof ModelCollection) {
            return selectedResources.value;
        } else {
            return collect(selectedResources.value);
        }
    });

    // Resources of the selected category
    const selectedCategoryResources = computed(() => {
        const filteredValue = collectionSelectedResources.value.filter(resource => {
            return resource.getType() === selectedCategory.value?.type;
        });

        return isMultiSelect.value ? filteredValue.all() : filteredValue.first();
    });

    function selectResource(resource: Model) {
        if (isMultiSelect.value) {
            (selectedResources.value as ModelCollection<Model>).push(resource);
        } else {
            selectedResources.value = resource;
            bus.emit('hide');
        }

        context.emit('input', selectedResources.value);
        context.emit('select', resource);
    }

    function unselectResource(resource: Model) {
        if (isMultiSelect.value) {
            selectedResources.value = (selectedResources.value as ModelCollection<Model>).reject(rsr => {
                return rsr.getKey() === resource.getKey();
            });
        } else {
            selectedResources.value = undefined;
        }

        context.emit('input', selectedResources.value);
        context.emit('unselect', resource);
    }

    // If any other resource than an organization is in the prop selectedResources we disable the organization
    // category. If any organization is in the prop we disable all the other categories
    watch(
        selectedResources,
        () => {
            const canAddStructures = !!(
                !hasSelectedResource.value ||
                collectionSelectedResources.value.first(resource => {
                    return resource.getType() === new OrganizationModel().getType();
                })
            );

            if (!hasSelectedResource.value) {
                allowedCategories.value.forEach(category => (category.disabled = false));

                return;
            }

            const structureCategory = allowedCategories.value.find(category => {
                return category.type === new OrganizationModel().getType();
            });

            const otherCategories = allowedCategories.value.filter(category => {
                return category.type != new OrganizationModel().getType();
            });

            if (canAddStructures) {
                if (structureCategory) {
                    structureCategory.disabled = false;
                }

                otherCategories.forEach(category => (category.disabled = true));
            } else {
                if (structureCategory) {
                    structureCategory.disabled = true;
                }

                otherCategories.forEach(category => (category.disabled = false));
            }
        },
        {immediate: true, deep: true}
    );

    return {
        selectedCategoryResources,
        collectionSelectedResources,
        selectedResources,
        selectResource,
        unselectResource,
    };
}
