<template>
    <list v-if="!loading">
        <BalanceTypeListItem
            v-for="balanceTypeModel in sortedBalanceTypeModels"
            :key="balanceTypeModel.id"
            :model="balanceTypeModel"
            @deleted="onDeletedItem"
        />
    </list>
    <loader
        v-else
        light="false"
        shadow="false"
        size="md"
        :title="__('hr:balance_type_loading')"
    />
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import BalanceTypeListItem from './BalanceTypeListItem.vue';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import {collect} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import {random, sortBy} from 'lodash-es';
    import useManager from '@/modules/app/composables/useManager';
    import {eventTypesRelationConstraint} from '@/modules/human-resources/components/balance-type/BalanceTypeForm.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            BalanceTypeListItem,
        },
        props: {},
        setup() {
            const {activeOrganization} = useManager();
            const loading = ref(false);

            const balanceTypeModels = ref(collect<BalanceTypeModel>());

            const fetchData = async function () {
                balanceTypeModels.value = collect<BalanceTypeModel>();
                loading.value = true;

                balanceTypeModels.value = await BalanceTypeModel.query()
                    .whereHas('organizations', query => query.scope('active'))
                    .orderBy('name')
                    .with(new BalanceTypeModel().organizationsPivots())
                    .with(new BalanceTypeModel().balanceAllocations())
                    .with(new BalanceTypeModel().eventTypes(), eventTypesRelationConstraint)
                    .with(new BalanceTypeModel().balancePeriods())
                    .get();

                loading.value = false;
            };

            const sortedBalanceTypeModels = computed(() => {
                balanceTypeModels.value.each(balanceTypeModel => {
                    const pivot = balanceTypeModel
                        .organizationsPivots()
                        .value()
                        .first(pivot => pivot.attributes.organization_id === activeOrganization.value?.id);
                    balanceTypeModel.extra = {is_archived: pivot?.attributes.archived_at !== null};
                });

                return collect(
                    sortBy(
                        balanceTypeModels.value.all(),
                        [model => model.extra.is_archived, model => model.attributes.name],
                        ['asc', 'asc']
                    )
                );
            });

            const addToResult = function (balanceType: BalanceTypeModel) {
                balanceTypeModels.value.push(balanceType);
            };

            const onDeletedItem = (balanceTypeModel: BalanceTypeModel) => {
                balanceTypeModels.value = balanceTypeModels.value.reject(item => item.id === balanceTypeModel.id);
                useNotification().success(__('hr_balance:balance_type_deleted_successfully'));
            };

            watch(
                activeOrganization,
                () => {
                    fetchData();
                },
                {deep: true, immediate: true}
            );

            return {
                loading,
                addToResult,
                fetchData,
                sortedBalanceTypeModels,
                onDeletedItem,
                random,
            };
        },
    });
</script>
