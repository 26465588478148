<template>
    <CModal
        ref="modal"
        :header-title="__('planning:add_list')"
        visible
        @hide="$emit('hide')"
        @show="onShow"
    >
        <SimulationListForm
            v-if="simulationListModel"
            ref="form"
            :simulation-list="simulationListModel"
            @loading="loading = $event"
            @saved="onSaved"
        />
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loading"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script trans="ts">
    import {defineComponent, ref} from 'vue';
    import SimulationListForm from '@/modules/planning/components/simulation/List/SimulationListForm.vue';
    import SimulationListModel from '@/modules/planning/models/SimulationListModel';
    import useManager from '@/modules/app/composables/useManager';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import __ from '@/modules/app/utils/i18n-facade';
    import moment from 'moment';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';

    export default defineComponent({
        components: {SimulationListForm},
        props: {
            searchValue: {
                default: null,
                type: String,
            },
        },
        setup(props, {emit}) {
            const loading = ref(false);
            const {activeOrganization} = useManager();
            const {modal, show, hide} = useLegacyModalBus();
            const {success} = useNotification();

            const simulationListModel = ref();

            function onShow() {
                simulationListModel.value = new SimulationListModel();
                simulationListModel.value.attributes.account_id = activeOrganization.value.attributes.account_id;
                simulationListModel.value.attributes.organization_id = activeOrganization.value.getKey();

                const currentYear = moment().year();
                const nextYear = moment().add(1, 'year').year();

                simulationListModel.value.attributes.period.started_at = moment(`${currentYear}-09-01`).toISOString();
                simulationListModel.value.attributes.period.ended_at = moment(`${currentYear + 1}-08-31`).toISOString();

                simulationListModel.value.attributes.name =
                    typeof props.searchValue === 'string' && props.searchValue.length
                        ? props.searchValue.length
                        : __('planning:back_to_nursery_with_years', {currentYear, nextYear});
            }

            function onSaved(model) {
                emit('created', model);
                success(__('planning:list_added_successfully'));
                hide();
            }

            return {
                loading,
                show,
                hide,
                onShow,
                onSaved,
                modal,
                simulationListModel,
            };
        },
    });
</script>
