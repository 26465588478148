import type TicketFilterDto from '@/modules/activity/ticket/application/dto/TicketFilterDto';
import type {Model, QueryBuilder} from '@meekohq/lumos';
import TicketModel from '@/modules/activity/ticket/domain/TicketModel';

export class TransformTicketFiltersToQueryBuilderFactory {
    static transform(filters: TicketFilterDto): QueryBuilder<TicketModel> {
        const query = TicketModel.query();

        // Filter by type
        if (filters.type !== undefined) {
            query.where('type', filters.type);
        }

        // Filter by assignee_id or/and reporter_id, so we need to use inner query
        query.where(subQuery => {
            // Filter by assignee
            subQuery.where(subQuery1 => {
                if (!filters.assignedToAnyone && filters.assignedTo !== undefined && filters.assignedTo.length > 0) {
                    subQuery1.whereIn(
                        'assignee_id',
                        filters.assignedTo.map(staff => staff.getKey())
                    );
                }

                if (!filters.assignedToAnyone && filters.notAssigned === true) {
                    filters.assignedTo !== undefined && filters.assignedTo.length > 0
                        ? subQuery1.orWhereNull('assignee_id')
                        : subQuery1.whereNull('assignee_id');
                }
            });

            // If no assigned constraints, we can use where instead of orWhere
            const reporterWhereConstrain =
                !filters.assignedToAnyone &&
                ((filters.assignedTo !== undefined && filters.assignedTo.length > 0) || filters.notAssigned === true)
                    ? 'orWhere'
                    : 'where';

            // Filter by reporter
            subQuery[reporterWhereConstrain](subQuery1 => {
                if (!filters.reportedByAnyone && filters.reportedBy !== undefined && filters.reportedBy.length > 0) {
                    subQuery1.whereIn(
                        'reporter_id',
                        filters.reportedBy.map(staff => staff.getKey())
                    );
                }

                if (!filters.assignedToAnyone && filters.notReported === true) {
                    filters.reportedBy !== undefined && filters.reportedBy.length > 0
                        ? subQuery1.orWhereNull('reporter_id')
                        : subQuery1.whereNull('reporter_id');
                }
            });
        });

        // Filter by resource
        if (filters.resource !== undefined) {
            query.whereHas(new TicketModel().ticketPivots(), subQuery => {
                subQuery.where('resource_id', (filters.resource as Model).getKey());
                subQuery.where('resource_type', (filters.resource as Model).getType());
            });
        }

        // Filter by resource_type only if resource is not set
        if (filters.resource === undefined && filters.excludeResourceTypes && filters.excludeResourceTypes.length > 0) {
            query.whereDoesntHave(new TicketModel().ticketPivots(), subQuery => {
                subQuery.whereIn('resource_type', filters.excludeResourceTypes as string[]);
            });
        }

        // Filter by completed_at or not
        if (filters.isCompleted !== undefined) {
            query.where('completed_at', filters.isCompleted ? '!=' : '=', null);
        }

        /**********************************/
        /****** SECURITY CONSTRAINTS ******/
        /**********************************/

        // Only get tasks that have at least a pivot where the user is allowed to see the resource
        query.whereHas(new TicketModel().ticketPivots(), subQuery => {
            subQuery.scope('onlyAllowed');
        });

        // Order by
        if (filters.orderBy !== undefined) {
            query.orderBy(filters.orderBy, filters.queryDirection);
        }

        return query;
    }
}
