<script setup lang="ts">
    import type {PropType} from 'vue';
    import {nextTick, ref, watch} from 'vue';
    import type {MDatePickerStoreType} from '@/modules/meeko-ui/composables/useMDatePicker';
    import {useFocus} from '@vueuse/core/index';
    import {whenever} from '@vueuse/core';

    const props = defineProps({
        store: {
            type: Object as PropType<MDatePickerStoreType>,
            required: true,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['show', 'hide']);

    const {
        isPeriod,
        placeholders,
        inputs,
        dates,
        toDateFormat,
        toTimeFormat,
        onFromInputChanged,
        onToInputChanged,
        isInputEditable,
        withTime,
    }: MDatePickerStoreType = props.store;

    const mainInputFocused = ref(false);

    const mainDivEl = ref();
    const {focused: mainDivFocused} = useFocus(mainDivEl);

    whenever(mainDivFocused, () => {
        toggleEdit('from');
        emit('show');
    });

    const fromInputEl = ref();
    const {focused: fromFocused} = useFocus(fromInputEl);

    const toInputEl = ref();
    const {focused: toFocused} = useFocus(toInputEl);

    watch([fromFocused, toFocused], () => {
        mainInputFocused.value = fromFocused.value || toFocused.value;
    });

    function toggleEdit(input: 'from' | 'to') {
        if (!isInputEditable.value && !props.disabled) {
            isInputEditable.value = true;
            nextTick(() => {
                if (input === 'from') {
                    fromFocused.value = true;
                } else {
                    toFocused.value = true;
                }
            });
        }
    }

    function hide() {
        emit('hide');
        isInputEditable.value = false;
    }
</script>

<template>
    <div
        ref="mainDivEl"
        class="MDatePickerInput CInput CInput--light CInput--base"
        :class="{
            'CInput--has-error': hasError,
            'MDatePickerInput--disabled': disabled,
        }"
        :tabindex="!disabled ? 0 : undefined"
    >
        <div
            v-show="isInputEditable"
            class="MDatePickerInput__input__wrapper"
        >
            <input
                ref="fromInputEl"
                class="MDatePickerInput__input"
                :disabled="disabled"
                :placeholder="placeholders.auto"
                tabindex="1"
                :value="inputs.from"
                @input="onFromInputChanged($event.target.value)"
                @keydown.tab="isPeriod ? toggleEdit('to') : hide()"
            />
            <FontAwesomeIcon
                v-if="isPeriod"
                class="MDatePickerInput__arrow"
                icon="fa-regular fa-arrow-right"
            />
            <input
                v-if="isPeriod"
                ref="toInputEl"
                class="MDatePickerInput__input"
                :disabled="disabled"
                :placeholder="placeholders.auto"
                tabindex="2"
                :value="inputs.to"
                @input="onToInputChanged($event.target.value)"
                @keydown.tab="hide"
            />
        </div>
        <div
            v-show="!isInputEditable"
            class="MDatePickerInput__input__wrapper"
        >
            <div
                class="MDatePickerInput__virtual-input"
                :class="{
                    'MDatePickerInput__virtual-input--disabled': disabled,
                }"
                @click="toggleEdit('from')"
            >
                <span
                    class="MDatePickerInput__virtual-input-date"
                    :class="{
                        'MDatePickerInput__virtual-input-date--disabled': !dates.from,
                    }"
                    >{{ toDateFormat(dates.from) ?? placeholders.date }}</span
                >
                <span
                    v-if="withTime"
                    class="MDatePickerInput__virtual-input-hour"
                    >{{ toTimeFormat(dates.from) ?? placeholders.time }}</span
                >
            </div>
            <FontAwesomeIcon
                v-if="isPeriod"
                class="MDatePickerInput__arrow"
                icon="fa-regular fa-arrow-right"
            />
            <div
                v-if="isPeriod"
                class="MDatePickerInput__virtual-input"
                :class="{
                    'MDatePickerInput__virtual-input--disabled': disabled,
                }"
                @click="toggleEdit('to')"
            >
                <span
                    class="MDatePickerInput__virtual-input-date"
                    :class="{
                        'MDatePickerInput__virtual-input-date--disabled': !dates.to,
                    }"
                    >{{ toDateFormat(dates.to) ?? placeholders.date }}</span
                >
                <span
                    v-if="withTime"
                    class="MDatePickerInput__virtual-input-hour"
                    >{{ toTimeFormat(dates.to) ?? placeholders.time }}</span
                >
            </div>
        </div>
    </div>
</template>

<style scoped>
    .MDatePickerInput__input__wrapper {
        @apply tw-flex tw-items-baseline tw-gap-2;
    }

    .MDatePickerInput--disabled {
        cursor: not-allowed !important;
        background-color: theme(backgroundColor.gray.50) !important;
        color: theme(textColor.gray.600) !important;
    }

    .MDatePickerInput__input {
        @apply tw-min-w-0 tw-flex-1 tw-outline-0;
    }

    .MDatePickerInput__virtual-input {
        @apply tw-flex tw-min-w-0 tw-flex-1 tw-cursor-text tw-items-center tw-gap-2 tw-outline-0;
    }

    .MDatePickerInput__virtual-input--disabled {
        @apply tw-cursor-not-allowed;
    }

    .MDatePickerInput__arrow {
        @apply tw-text-gray-400;
    }

    .MDatePickerInput__virtual-input-date {
        @apply tw-whitespace-nowrap;
    }

    .MDatePickerInput__virtual-input-date--disabled {
        @apply tw-text-gray-400;
    }

    .MDatePickerInput__virtual-input-hour {
        @apply tw-rounded tw-bg-primary-500 tw-px-1 tw-py-0.5 tw-text-sm tw-text-white;
    }
</style>
