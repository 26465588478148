<template>
    <MMenu
        arrow
        placement="right"
    >
        <MMenuButton class="tw-w-full">
            <MHoverable class="tw-w-full">
                <div
                    class="SwapOrganizationButton"
                    :class="{
                        'SwapOrganizationButton--small': small,
                    }"
                >
                    <div>
                        <MAvatar
                            class="tw-w-8"
                            fallback-initials
                            :name="nursery.name"
                            :url="nursery.logo_url"
                        />
                    </div>
                    <div class="SwapOrganizationButton__organization">
                        <div class="SwapOrganizationButton__organization-name">
                            {{ nursery.name }}
                        </div>
                        <div class="SwapOrganizationButton__organization-city">
                            {{ nursery.city }}
                        </div>
                    </div>
                    <div>
                        <div
                            class="SwapOrganizationButton__button"
                            :class="{
                                'SwapOrganizationButton__button--alt': alt,
                                'SwapOrganizationButton__button--clickable': orderedNurseries.length > 1,
                                'SwapOrganizationButton__button--small': small,
                            }"
                        >
                            <font-awesome-icon
                                v-if="orderedNurseries.length > 1"
                                icon="fa-solid fa-right-left"
                            />
                        </div>
                    </div>
                </div>
            </MHoverable>
        </MMenuButton>
        <MMenuItems>
            <div class="tw-max-h-96 tw-overflow-y-auto">
                <router-link
                    v-for="item in orderedNurseries"
                    :key="'swap-nursery-' + item.id"
                    :to="getSwitchedRoute(item)"
                >
                    <MMenuItem>
                        <div class="tw-flex tw-items-baseline tw-gap-2">
                            <CBadge
                                v-if="item.city"
                                size="sm"
                                variant="primary"
                            >
                                {{ item.city }}
                            </CBadge>
                            {{ item.name }}
                        </div>
                    </MMenuItem>
                </router-link>
            </div>
        </MMenuItems>
    </MMenu>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import {orderBy} from 'lodash-es';
    import {useRoute} from 'vue-router/composables';

    interface NurseryType {
        city: string;
        name: string;
    }

    interface UserType {
        nurseries: any[];
    }

    export default defineComponent({
        props: {
            user: {type: Object as PropType<UserType>, required: true},
            nursery: {type: Object as PropType<NurseryType>, required: true},
            buttonClass: {type: [String, Object, Array], default: undefined, required: false},
            alt: {type: Boolean, default: false, required: false},
            small: {type: Boolean, default: false, required: false},
        },
        setup(props) {
            const orderedNurseries = computed(() => {
                return orderBy(props.user.nurseries, 'name');
            });

            function getSwitchedRoute(nursery) {
                if (useRoute().meta && useRoute().meta?.redirectOnSwap) {
                    return {
                        name: useRoute().meta?.redirectOnSwap,
                        params: {nursery: nursery.id},
                        query: useRoute().query,
                    };
                }

                return {name: useRoute().name, params: {nursery: nursery.id}, query: useRoute().query};
            }

            return {
                orderedNurseries,
                getSwitchedRoute,
            };
        },
    });
</script>

<style scoped>
    .SwapOrganizationButton {
        @apply tw-cursor-pointer tw-gap-x-2;
        display: flex !important;
    }

    .SwapOrganizationButton__button {
        @apply tw-w-full tw-justify-center tw-rounded-lg tw-bg-primary-800/20 tw-p-1.5 tw-text-primary-950/50;
        @apply tw-flex tw-h-full tw-items-center;
    }

    .SwapOrganizationButton__button--clickable {
        @apply hover:tw-bg-primary-800/30;
        @apply tw-transition-all tw-duration-200 tw-ease-in-out;
    }

    .SwapOrganizationButton__button--small {
        @apply tw-rounded-lg;

        .SwapOrganizationButton__organization {
            @apply tw-gap-0.5;
        }

        .SwapOrganizationButton__logo {
            @apply tw-w-8 tw-rounded-md;
        }
    }

    .SwapOrganizationButton__button--alt {
        @apply tw-bg-primary-800/20 hover:tw-bg-primary-700/30;
        /*@apply tw-shadow tw-bg-white;*/
        @apply tw-ring tw-ring-0;
    }

    .SwapOrganizationButton__logo {
        @apply tw-aspect-square tw-w-10 tw-self-start tw-rounded-lg tw-bg-contain tw-bg-center tw-bg-no-repeat;
    }

    .SwapOrganizationButton__organization {
        @apply tw-flex tw-flex-1 tw-flex-col;
        @apply tw-gap-1;
    }

    .SwapOrganizationButton__organization-name {
        @apply tw-font-display tw-text-base tw-font-medium tw-leading-none;
        @apply tw-text-primary-950;
    }

    .SwapOrganizationButton__organization-city {
        @apply tw-font-display tw-text-sm tw-font-normal tw-leading-none;
        @apply tw-text-gray-500;
    }
</style>
