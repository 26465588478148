<template>
    <CModal
        :header-title="__('common:file_simulation')"
        :hide-footer="true"
        size="full"
        :visible="true"
        @hidden="$emit('hidden')"
    >
        <IntroductionAlert />
        <div
            v-if="simulationListsCount"
            class="SimulationModal__container"
        >
            <div
                class="SimulationModal__simulation-actions"
                :class="{'SimulationModal__simulation-actions--is-active-simulation': activeSimulation}"
            >
                <SimulationListActions
                    :query="finderQuery"
                    @changed="updateActiveSimulationList($event)"
                    @created="simulationListsCount++"
                    @deleted="simulationListsCount--"
                />
                <SimulationList
                    v-if="activeSimulation"
                    class="tw-pt-4"
                    :simulation-list="activeSimulation"
                />
            </div>
            <SimulationView
                v-if="activeSimulation"
                class="SimulationModal__simulation-view"
                :simulation="activeSimulation"
            />
        </div>
        <MEmptyIndex
            v-else
            :text="__('planning:empty_simulation')"
            :title="__('planning:try_modifying_filters_or_adding_a_list')"
        >
            <template #button>
                <CreateSimulationList @created="createdFirstSimulationList($event)" />
            </template>
        </MEmptyIndex>
    </CModal>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from 'vue';
    import SimulationListActions from '@/modules/planning/components/simulation/List/SimulationListActions.vue';
    import IntroductionAlert from '@/modules/planning/components/simulation/SimulationModal/IntroductionAlert.vue';
    import SimulationList from '@/modules/planning/components/simulation/List/SimulationList.vue';
    import SimulationView from '@/modules/planning/components/simulation/SimulationView.vue';
    import MEmptyIndex from '@/modules/meeko-ui/components/MEmptyIndex.vue';
    import CreateSimulationList from '@/modules/planning/components/simulation/List/CreateSimulationList.vue';
    import SimulationListModel from '@/modules/planning/models/SimulationListModel';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';

    export default defineComponent({
        components: {
            IntroductionAlert,
            SimulationView,
            SimulationListActions,
            SimulationList,
            MEmptyIndex,
            CreateSimulationList,
        },
        props: {},
        setup() {
            const activeSimulation = ref();
            const simulationListsCount = ref(0);

            const finderQuery = SimulationListModel.query().whereHas(new SimulationListModel().organization(), query =>
                query.scope('active')
            );

            onMounted(() => finderQuery.count().then(value => (simulationListsCount.value = value)));

            async function updateActiveSimulationList(simulation: SimulationListModel | null) {
                if (!simulation) {
                    activeSimulation.value = null;

                    return;
                }

                if (activeSimulation.value && simulation.getKey() === activeSimulation.value.getKey()) {
                    return;
                }

                activeSimulation.value = await simulation
                    .newQuery()
                    .where('id', simulation.getKey())
                    .with(simulation.registrations(), query2 => {
                        const registration = new RegistrationModel();
                        query2
                            .with(registration.organizations())
                            .with(registration.plannings())
                            .with(registration.tags());
                    })
                    .first();
            }

            function createdFirstSimulationList(simulation: SimulationListModel) {
                activeSimulation.value = simulation;
                simulationListsCount.value++;
            }

            return {
                activeSimulation,
                simulationListsCount,
                finderQuery,
                updateActiveSimulationList,
                createdFirstSimulationList,
            };
        },
    });
</script>

<style scoped>
    .SimulationModal__container {
        @apply tw-flex;
        @apply tw-flex-col lg:tw-flex-row;
        @apply tw-space-x-0 lg:tw-space-x-6;
        @apply tw-space-y-6 lg:tw-space-y-0;
        @apply tw-min-h-96;
    }

    .SimulationModal__simulation-actions {
        @apply tw-w-full;
    }

    .SimulationModal__simulation-actions--is-active-simulation {
        @apply lg:tw-w-1/3;
    }

    .SimulationModal__simulation-view {
        @apply tw-w-full tw-pb-4;
    }
</style>
