<template>
    <div>
        <CashierOrderManager
            :company="true"
            :contract="contract"
            :legacy-organization="nursery"
            :payload="cashierPayload"
            @hasCustomer="show = $event"
        />

        <div v-if="show">
            <CForm class="tw-mt-6 md:tw-w-1/2">
                <CFormGroup>
                    <CLabel>
                        {{ __('common:employee_name') }}
                    </CLabel>
                    <MInput
                        v-model="contract.company_employee"
                        :disabled="!$can('update', 'kids_contracts')"
                    />
                </CFormGroup>
                <CFormGroup>
                    <CLabel>
                        {{ __('family_contract:the_company_is_invoiced') }}
                    </CLabel>
                    <MCurrencyInput
                        v-model="contract.company_total_amount"
                        allow-undefined
                        class="tw-w-full"
                        :currency-symbol="nursery.currency"
                        :disabled="!$can('update', 'kids_contracts')"
                        :parser-options="parserOptionsCurrency"
                        placeholder="10000"
                    />
                </CFormGroup>
                <CFormTwoColumns>
                    <CFormGroup>
                        <CLabel>
                            {{ __('family_contract:start_schedule') }}
                        </CLabel>
                        <MDatePicker
                            v-model="contract.company_started_at"
                            class="tw-w-full"
                            :disabled="!$can('update', 'kids_contracts')"
                            format="yyyy-MM-dd"
                        />
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>
                            {{ __('family_contract:end_schedule') }}
                        </CLabel>
                        <MDatePicker
                            v-model="contract.company_ended_at"
                            class="tw-w-full"
                            :disabled="!$can('update', 'kids_contracts')"
                            format="yyyy-MM-dd"
                        />
                    </CFormGroup>
                </CFormTwoColumns>
                <CFormGroup>
                    <CLabel>
                        {{ __('family_contract:frequency') }}
                    </CLabel>
                    <CFormSelect
                        v-model="contract.company_cycle"
                        :disabled="!$can('update', 'kids_contracts')"
                        :options="cycleOptions"
                        :search-bar="false"
                    />
                </CFormGroup>
            </CForm>
            <div class="row tw-mt-6">
                <div class="col-12">
                    <h6
                        v-if="contract.company_invoices"
                        class="h6"
                    >
                        {{ __('family_contract:billing_schedule') }}
                        <span v-if="contract.company_invoices.length">({{ contract.company_invoices.length }})</span>
                    </h6>
                    <hr class="hr tw-mt-1" />
                    <div class="tw-p-4 lg:tw-p-0">
                        <div
                            v-for="(invoice, i) in contract.company_invoices"
                            :key="'invoice' + i"
                            class="row tw-mt-4 tw-rounded-lg tw-p-4 lg:tw-mt-4 lg:tw-p-0"
                        >
                            <div class="col-6 col-lg-2">
                                <label class="label">
                                    {{ __('common:amount_one') }}
                                </label>
                                <MCurrencyInput
                                    v-model="invoice.amount"
                                    allow-undefined
                                    class="tw-w-full"
                                    :currency-symbol="nursery.currency"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    :parser-options="parserOptionsCurrency"
                                    placeholder="10000"
                                />
                            </div>
                            <div class="col-6 tw-self-end lg:tw-hidden">
                                <MButton
                                    class="tw-w-full"
                                    variant="danger"
                                    @click="removeCycle(i)"
                                >
                                    <template #left-icons>
                                        <FontAwesomeIcon icon="fa-solid fa-times" />
                                    </template>
                                    {{ __('common:actions.delete') }}
                                </MButton>
                            </div>

                            <div class="col-6 col-lg-2 tw-mt-4 lg:tw-mt-0">
                                <label class="label">
                                    {{ __('family_contract:beginning_period') }}
                                </label>
                                <MDatePicker
                                    v-model="invoice.start_period_date"
                                    class="tw-w-full"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    format="yyyy-MM-dd"
                                />
                            </div>

                            <div class="col-6 col-lg-2 tw-mt-4 lg:tw-mt-0">
                                <label class="label">
                                    {{ __('family_contract:end_period') }}
                                </label>
                                <MDatePicker
                                    v-model="invoice.end_period_date"
                                    class="tw-w-full"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    format="yyyy-MM-dd"
                                    :has-error="invoice.start_period_date > invoice.end_period_date"
                                />
                            </div>

                            <div class="col-6 col-lg-2 tw-mt-2 lg:tw-mt-0">
                                <label class="label">
                                    {{ __('family_contract:invoice_date') }}
                                </label>
                                <MDatePicker
                                    v-model="invoice.date"
                                    class="tw-w-full"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    format="yyyy-MM-dd"
                                />
                            </div>

                            <div class="col-6 col-lg-2 tw-mt-2 lg:tw-mt-0">
                                <label class="label">
                                    {{ __('family_contract:due_date') }}
                                </label>
                                <MDatePicker
                                    v-model="invoice.due_date"
                                    class="tw-w-full"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    format="yyyy-MM-dd"
                                    :has-error="invoice.date > invoice.due_date"
                                />
                            </div>

                            <div class="col-2 tw-hidden tw-self-end tw-text-right lg:tw-flex">
                                <MButton
                                    v-if="$can('update', 'kids_contracts')"
                                    variant="danger"
                                    @click="removeCycle(i)"
                                >
                                    <template #left-icons>
                                        <FontAwesomeIcon icon="fa-solid fa-times" />
                                    </template>
                                    {{ __('common:actions.delete') }}
                                </MButton>
                            </div>
                            <div
                                v-if="
                                    invoice.date > invoice.due_date ||
                                    invoice.start_period_date > invoice.end_period_date
                                "
                                class="tw-flex tw-w-full tw-flex-col tw-gap-1 tw-p-4"
                            >
                                <CFormErrorMessage v-if="invoice.date > invoice.due_date">
                                    {{ __('family_contract:errors.company_invoice_due_date_after_date') }}
                                </CFormErrorMessage>
                                <CFormErrorMessage v-if="invoice.start_period_date > invoice.end_period_date">
                                    {{
                                        __(
                                            'family_contract:errors.company_invoice_end_period_date_after_start_period_date'
                                        )
                                    }}
                                </CFormErrorMessage>
                            </div>
                        </div>
                        <div
                            v-if="$can('update', 'kids_contracts')"
                            class="tw-mt-6 tw-text-center"
                        >
                            <MButton
                                icon-plus
                                :label="__('family_contract:add_due_date')"
                                variant="success"
                                @click="addCycle"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import CashierOrderManager from '@/modules/family/components/kid/CashierOrderManager.vue';
    import LegacyContractIntegrationService from '@/modules/family/utils/kid/LegacyContractIntegrationService';
    import {useLegacyKidContractErrors} from '@/modules/family/composables/kid/useLegacyKidContractErrors.ts';
    import i18next from 'i18next';
    import {getNumericParserContractPresetCurrency} from '@/modules/core/infrastructure/NumericParserContractPresets';

    export default {
        components: {
            CashierOrderManager,
        },
        props: {
            contract: {
                type: Object,
            },
            nursery: {
                type: Object,
            },
            kid: {
                type: Object,
            },
            cashierPayload: {},
        },
        emits: ['has-errors'],
        data() {
            return {
                show: false,
                cycleOptions: [
                    {
                        text: 'Personnalisée',
                        value: null,
                    },
                    {
                        text: 'Mensuelle',
                        value: 1,
                    },
                    {
                        text: 'Trimestrielle',
                        value: 3,
                    },
                    {
                        text: 'Semestrielle',
                        value: 6,
                    },
                    {
                        text: 'Annuelle',
                        value: 12,
                    },
                ],
                parserOptionsCurrency: getNumericParserContractPresetCurrency(i18next.language),
            };
        },
        computed: {
            LegacyContractIntegration() {
                return LegacyContractIntegrationService;
            },
            hasErrors() {
                return useLegacyKidContractErrors().hasCompanyContractErrors(this.contract);
            },
        },
        watch: {
            'contract.company_cycle': function (val) {
                if (val) {
                    this.setCycles();
                } else {
                    this.customCycle();
                }
            },
            'contract.company_total_amount': function () {
                if (this.contract.company_cycle) {
                    this.setCycles();
                } else {
                    this.customCycle();
                }
            },
            'contract.company_started_at': function () {
                if (this.contract.company_cycle) {
                    this.setCycles();
                } else {
                    this.customCycle();
                }
            },
            'contract.company_ended_at': function () {
                if (this.contract.company_cycle) {
                    this.setCycles();
                } else {
                    this.customCycle();
                }
            },
            'hasErrors': {
                handler() {
                    this.$emit('has-errors', this.hasErrors);
                },
            },
        },
        created() {
            this.parserOptionsCurrency.allowNegative = true;
        },
        mounted() {
            if (this.contract.company_employee === null) {
                const familyMember = this.kid.familyMembers.find(
                    item => item.id === this.contract.first_family_member_id
                );
                if (familyMember) {
                    this.contract.company_employee = familyMember.first_name + ' ' + familyMember.last_name;
                }
            }

            if (
                !this.contract.company_started_at &&
                this.contract.company_invoices &&
                this.contract.company_invoices.length === 0
            ) {
                Vue.set(this.contract, 'company_started_at', moment(this.contract.started_at));
            }

            if (
                !this.contract.company_ended_at &&
                this.contract.company_invoices &&
                this.contract.company_invoices.length === 0
            ) {
                Vue.set(this.contract, 'company_ended_at', moment(this.contract.ended_at));
            }
        },
        methods: {
            setCycles() {
                const cycles = [];

                // Add 1 to the diff to include the end month because it is not included by default as it is not a full month
                let months =
                    moment(this.contract.company_ended_at).diff(moment(this.contract.company_started_at), 'months') + 1;

                if (months <= 0) {
                    months = 1;
                }

                // Ceil is used to avoid having a wrong number of cycles when the contract is not a multiple of the cycle
                const n = this.contract.company_cycle ? Math.ceil(months / this.contract.company_cycle) : 1;

                for (let i = 0; i < months; i += this.contract.company_cycle) {
                    const total = this.contract.company_total_amount ? this.contract.company_total_amount / n : 0;
                    const invoice = {
                        amount: Math.round(total * 100) / 100,
                        start_period_date: moment(this.contract.company_started_at)
                            .add(i, 'month')
                            .format('YYYY-MM-DD'),
                        date: moment(this.contract.company_started_at).add(i, 'month').format('YYYY-MM-DD'),
                        due_date: moment(this.contract.company_started_at)
                            .add(i, 'month')
                            .add(1, 'month')
                            .format('YYYY-MM-DD'),
                    };
                    invoice.end_period_date = moment(invoice.start_period_date)
                        .add(this.contract.company_cycle, 'month')
                        .subtract(1, 'day')
                        .format('YYYY-MM-DD');

                    // If the end period date is after the contract end date, we set it to the contract end date
                    if (this.contract.company_ended_at < invoice.end_period_date) {
                        invoice.end_period_date = this.contract.company_ended_at;
                    }
                    cycles.push(invoice);
                }

                this.contract.company_invoices = cycles;
            },
            customCycle() {
                const invoice = {
                    amount: this.contract.company_total_amount ? this.contract.company_total_amount : 0,
                    start_period_date: moment(this.contract.company_started_at).format('YYYY-MM-DD'),
                    end_period_date: moment(this.contract.company_ended_at).format('YYYY-MM-DD'),
                    date: moment(this.contract.company_started_at).format('YYYY-MM-DD'),
                    due_date: moment(this.contract.company_started_at).add(1, 'month').format('YYYY-MM-DD'),
                };
                this.contract.company_invoices = [invoice];
            },
            addCycle() {
                const invoice = {
                    amount: 0,
                    start_period_date: moment(this.contract.company_started_at).format('YYYY-MM-DD'),
                    end_period_date: moment(this.contract.company_ended_at).format('YYYY-MM-DD'),
                    date: moment(this.contract.company_started_at).format('YYYY-MM-DD'),
                    due_date: moment(this.contract.company_started_at).add(1, 'month').format('YYYY-MM-DD'),
                };

                if (this.contract.company_invoices) {
                    this.contract.company_invoices.push(invoice);
                } else {
                    this.contract.company_invoices = [invoice];
                }
            },
            removeCycle(index) {
                this.contract.company_invoices.splice(index, 1);
            },
        },
    };
</script>

<style scoped>
    .selected-dropdown {
        .edit-button {
            display: none;
        }

        &:hover {
            .edit-button {
                display: inline;
            }
        }
    }
</style>
