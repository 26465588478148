import type {MagicMassSelectType} from '@/modules/app/composables/useMagicMassSelect';
import type {TransactionListFiltersType} from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';
import {TransactionFilterDto} from '@/modules/cashier/transaction/application/dto/TransactionFilterDto';
import {app} from '@meekohq/lumos';
import {MassDeleteTransactionsByFiltersUseCaseBinding} from '@/modules/cashier/transaction/application/use-cases/MassDeleteTransactionsByFiltersUseCase';
import {type Ref, unref} from 'vue';
import mitt, {type Emitter} from 'mitt';

/**
 * Full application logic for deleting transactions.
 */
export default function useMassDeleteTransactions() {
    const bus: Emitter<{deleted: void}> = mitt();

    async function deleteByOptions(options: Ref<Partial<TransactionListFiltersType>>, massSelect: MagicMassSelectType) {
        const optionsUnref = unref(options);

        const dto = TransactionFilterDto.make({
            period: optionsUnref.period,
            amountMin: optionsUnref.amountMin,
            amountMax: optionsUnref.amountMax,
            tenants: optionsUnref.tenants,
            customers: optionsUnref.customers,
            invoiceIds: optionsUnref.invoiceIds,
            transactionIds: optionsUnref.transactionIds,
            paymentMethodName: optionsUnref.paymentMethodName,
            hasRemainingAmount: optionsUnref.hasRemainingAmount,
            search: optionsUnref.search,
            orderBy: optionsUnref.orderBy,
            queryDirection: optionsUnref.queryDirection,
        });

        const result = await app(MassDeleteTransactionsByFiltersUseCaseBinding).massDeleteByFilters(
            massSelect.queryMode.value ? [] : massSelect.selectedIds.value.toArray(),
            dto
        );

        massSelect.reset();

        bus.emit('deleted');

        return result;
    }

    return {
        deleteByOptions,
        bus,
    };
}
