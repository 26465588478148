<script lang="ts" setup>
    import {ref, watch} from 'vue';
    import {useFocus} from '@vueuse/core/index';
    import {isNil} from 'lodash-es';
    import type {NumericParserContractOptions} from '@/modules/core/infrastructure/NumericParserContract';
    import {NumericParserContractBinding} from '@/modules/core/infrastructure/NumericParserContract';
    import {app} from '@meekohq/lumos';
    import {getNumericParserContractPresetInteger} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import i18next from 'i18next';

    const props = withDefaults(
        defineProps<{
            value?: number;
            parserOptions?: NumericParserContractOptions;
            min?: number;
            max?: number;
            variant?: string;
            autofocus?: boolean;
            placeholder?: string;
            size?: string;
            align?: 'left' | 'right';
            allowUndefined?: boolean;
            hasError?: boolean;
            readonly?: boolean;
            disabled?: boolean;
        }>(),
        {
            value: undefined,
            min: undefined,
            max: undefined,
            variant: undefined,
            parserOptions: () => getNumericParserContractPresetInteger(i18next.language),
            placeholder: undefined,
            size: undefined,
            autofocus: false,
            allowUndefined: false,
            align: undefined,
            hasError: false,
            readonly: false,
            disabled: false,
        }
    );

    const emit = defineEmits<{
        (e: 'input', value: number | undefined): void;
        (e: 'change', value: number | undefined): void;
    }>();

    const numericParser = app(NumericParserContractBinding, props.parserOptions);

    const input = ref();
    const {focused} = useFocus(input);

    const inputValue = ref(numericParser.setValue(props.value ?? NaN).asString());
    const internalValue = ref(props.value);

    function handleInput(value: string) {
        computeInternalValue(value);

        if (isNaN(internalValue.value ?? 0)) {
            return;
        }

        inputValue.value = value;
        emit('input', internalValue.value);
    }

    function handleChange(value: string | undefined) {
        computeInternalValue(value);

        if (isNaN(internalValue.value ?? 0)) {
            return;
        }

        emit('change', internalValue.value);
    }

    function computeInternalValue(value: string | undefined) {
        const parsedValue = numericParser.parse(value).asNumber();

        if (isNaN(parsedValue)) {
            internalValue.value = NaN;

            if (value?.trim().length ?? 0) {
                return;
            }

            if (props.allowUndefined) {
                internalValue.value = undefined;
            } else {
                internalValue.value = 0;
            }

            return;
        }

        if (!isNil(props.max) && parsedValue > props.max) {
            internalValue.value = props.max;

            return;
        }

        if (!isNil(props.min) && parsedValue < props.min) {
            internalValue.value = props.min;

            return;
        }

        internalValue.value = parsedValue;
    }

    function setInputValue(value?: string) {
        inputValue.value = value;
        (input.value!.$el as HTMLInputElement).value = value ?? '';
    }

    watch(
        () => props.value,
        newValue => {
            if (newValue === internalValue.value) {
                return;
            }

            internalValue.value = newValue;
            setInputValue(numericParser.setValue(newValue ?? NaN).asString());
        }
    );

    watch(focused, newValue => {
        if (!newValue) {
            handleChange(inputValue.value);

            const formattedValue = numericParser.setValue(internalValue.value ?? NaN).asString();
            setInputValue(formattedValue);
        }
    });
</script>

<template>
    <MInput
        ref="input"
        :align="align"
        :autofocus="autofocus"
        :disabled="disabled"
        :has-error="hasError"
        :placeholder="placeholder"
        :readonly="readonly"
        :size="size"
        :value="inputValue"
        :variant="variant"
        @input="handleInput"
    />
</template>
