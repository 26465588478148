<script lang="ts" setup>
    import type {PropType} from 'vue';
    import {ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import SelectImage from '@/modules/news/components/SelectImage.vue';
    import PhotoAddModal from '@/modules/photo/components/organisms/PhotoAddModal.vue';
    import useManager from '@/modules/app/composables/useManager';

    defineProps({
        modal: {
            type: Object as PropType<ModalType>,
            required: true,
        },
    });

    const {activeOrganization} = useManager();
    const selectedPhoto = ref();

    const newPhotos = ref([]);

    const emit = defineEmits(['select', 'refresh']);
</script>
<template>
    <MModal
        :header-title="__('photo:select_photo')"
        :modal="modal"
    >
        <MButton
            class="tw-w-full"
            size="lg"
            variant="primary"
            @click="$refs.photoAddModal.$refs.modal.show()"
        >
            <template #left-icons>
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa-solid fa-upload"
                />
            </template>
            {{ __('photo:add_photo') }}
        </MButton>
        <div class="tw-my-4">
            <MHeading level="h1">
                {{ __('photo:your_photos') }}
            </MHeading>
        </div>
        <SelectImage
            ref="selectImage"
            @select-photo="selectedPhoto = $event"
        />
        <template #footer="{hide}">
            <MButton
                class="tw-ml-auto"
                @click="hide"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :disabled="selectedPhoto === undefined"
                variant="primary"
                @click="emit('select', selectedPhoto)"
            >
                {{ __('common:actions.select') }}
            </MButton>
        </template>
        <PhotoAddModal
            ref="photoAddModal"
            :nursery="activeOrganization"
            @photo-created="newPhotos.push($event)"
            @refresh="emit('new-photos', newPhotos)"
        />
    </MModal>
</template>
