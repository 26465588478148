<script setup lang="ts">
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type {PropType} from 'vue';
    import Vue, {ref, watch} from 'vue';
    import type InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';

    const props = defineProps({
        invoice: {
            type: Object,
            required: true,
        },
        invoiceModel: {
            type: Object as PropType<InvoiceModel>,
            required: true,
        },
        nursery: {
            type: Object,
            required: true,
        },
        action: {
            type: String,
            required: true,
        },
        isOtherInvoices: {
            type: Boolean,
            default: false,
        },
    });

    const sepaAmount = ref<number | undefined>(undefined);

    function handleSepaAmountInput(value: number) {
        Vue.set(props.invoice, 'sepa_amount', value);
    }

    watch(
        () => props.invoice,
        () => {
            if (props.action === 'sepa' && props.invoice.selected) {
                if (typeof props.invoice.sepa_amount === 'string') {
                    sepaAmount.value = parseFloat(props.invoice.sepa_amount);
                } else {
                    sepaAmount.value = props.invoice.sepa_amount;
                }
            }
        },
        {immediate: true, deep: true}
    );
</script>

<template>
    <MBox
        class="SelectInvoicesCard"
        :class="{
            'SelectInvoicesCard--sent': invoice.sent && !invoice.selected,
            'SelectInvoicesCard--selected': invoice.selected,
            'SelectInvoicesCard--other-invoices': isOtherInvoices,
        }"
    >
        <div class="SelectInvoicesCard__name">
            <FontAwesomeIcon
                v-if="invoice.selected"
                icon="fa-solid fa-check-circle"
            />
            <FontAwesomeIcon
                v-else
                :icon="invoice.company ? 'fa-solid fa-city' : 'fa-solid fa-file'"
            />
            <div class="tw-ml-2 tw-truncate">
                {{ invoice.kid_name }}
            </div>
        </div>
        <div class="tw-text-sm">
            {{ invoice.no }}
        </div>
        <div
            class="tw-font-semibold tw-text-primary-500"
            :class="{'!tw-text-white': invoice.selected}"
        >
            {{ useFormatCurrency(invoice.currency_iso_code).format(invoice.grand_total) }}
        </div>
        <TagsSelector
            prevent-default
            stop-propagation
            :taggable-model="invoiceModel"
            taggable-type="invoice"
            :tags="invoiceModel.tags().value().toArray()"
            @attached="invoiceModel.tags().value().push($event)"
            @detached="invoiceModel.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
        />
        <CForm
            v-if="action === 'sepa' && invoice.selected"
            class="SelectInvoicesCard__form"
        >
            <CFormGroup>
                <CLabel :class="{'!tw-text-white': invoice.selected}">
                    {{ __('common:amount_one') }}
                    <MTooltip
                        class="tw-ml-2"
                        placement="bottom-start"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-info-circle" />
                        <template #content>
                            {{ __('billing_invoice:amount_displayed_explanation') }}
                        </template>
                    </MTooltip>
                </CLabel>
                <MCurrencyInput
                    class="tw-w-full tw-text-right tw-text-primary-500"
                    :currency-code="invoice.currency_iso_code"
                    :value="sepaAmount"
                    @click.native.stop
                    @input="handleSepaAmountInput"
                />
            </CFormGroup>
        </CForm>
    </MBox>
</template>

<style scoped>
    .SelectInvoicesCard {
        @apply tw-flex tw-flex-col tw-gap-1 tw-p-4;
        @apply tw-cursor-pointer;
    }

    .SelectInvoicesCard--sent {
        @apply tw-bg-gray-200;
    }

    .SelectInvoicesCard--selected {
        @apply tw-bg-primary-500;
        color: white !important;
    }

    .SelectInvoicesCard--other-invoices {
        @apply tw-bg-gray-200;
        @apply tw-cursor-default;
    }

    .SelectInvoicesCard__name {
        @apply tw-flex tw-flex-row tw-items-center;
    }

    .SelectInvoicesCard__form {
        @apply tw-mt-2 tw-border-t tw-border-gray-200 tw-pt-2;
    }
</style>
