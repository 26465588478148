<script setup lang="ts">
    import {computed} from 'vue';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import {TransactionCreditAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
    import type {TransactionDebitAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';

    const props = defineProps<{
        allocation: TransactionCreditAllocationAggregate | TransactionDebitAllocationAggregate;
    }>();

    const paymentMethodName = computed(() => {
        if (props.allocation instanceof TransactionCreditAllocationAggregate) {
            return props.allocation.source.paymentMethod().value()?.attributes?.name ?? '';
        }

        return props.allocation.destination.paymentMethod().value()?.attributes?.name ?? '';
    });

    const {format} = useFormatCurrency(props.allocation.currencyIsoCode);
</script>

<template>
    <div class="PaymentSuggestionsListItemHeaderTransaction">
        <FontAwesomeIcon
            class="PaymentSuggestionsListItemHeaderTransaction__icon-success"
            icon="fa-solid fa-arrow-up-right"
        />
        <div>
            <MHeading level="h3">
                {{ paymentMethodName }}
            </MHeading>
            <MHeading>
                {{ format(allocation.allocatableAmount) }}
            </MHeading>
        </div>
    </div>
</template>

<style scoped>
    .PaymentSuggestionsListItemHeaderTransaction {
        @apply tw-flex tw-flex-row tw-items-center tw-gap-3;
    }

    .PaymentSuggestionsListItemHeaderTransaction__icon-success {
        @apply tw-text-3xl tw-text-green-500;
    }

    .PaymentSuggestionsListItemHeaderTransaction__icon-failed {
        @apply tw-text-3xl tw-text-danger-500;
    }
</style>
