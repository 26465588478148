import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
import {type Ref, ref} from 'vue';
import useError from '@/modules/app/composables/useError';
import {InvoiceAggregate} from '@/modules/cashier/invoice/domain/InvoiceAggregate';
import {app} from '@meekohq/lumos';
import {UpdateInvoiceInputPortBinding} from '@/modules/cashier/invoice/domain/use-cases/UpdateInvoiceInputPort';
import {InvoiceTotalExceedsLimitError} from '@/modules/cashier/invoice/domain/errors/InvoiceTotalExceedsLimitError';
import {InvoiceLinePriceExceedsLimitError} from '@/modules/cashier/invoice/domain/errors/InvoiceLinePriceExceedsLimitError';

export function useInvoiceState(invoice: LegacyInvoiceType) {
    const invoiceAggregate = ref(new InvoiceAggregate(invoice)) as Ref<InvoiceAggregate>;
    const errors = useError();

    function updateInvoice(invoice: LegacyInvoiceType) {
        errors.reset();

        const interactor = app(UpdateInvoiceInputPortBinding, {
            updated(updatedInvoice: InvoiceAggregate) {
                invoiceAggregate.value = updatedInvoice;
            },
            domainError(error: Error): unknown {
                if (error instanceof InvoiceTotalExceedsLimitError) {
                    errors.addErrorCode('grand_total', InvoiceTotalExceedsLimitError.code);
                }

                if (error instanceof InvoiceLinePriceExceedsLimitError) {
                    errors.addErrorCode('line', InvoiceLinePriceExceedsLimitError.code);
                }
            },
            error(error: Error) {
                throw error;
            },
        });

        interactor.update(invoiceAggregate.value, invoice);
    }

    return {
        invoiceAggregate,
        updateInvoice,
        errors,
    };
}
