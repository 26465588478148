<template>
    <CModal
        ref="modal"
        :header-title="__('hr:update_team')"
    >
        <TeamForm
            ref="form"
            :team-model="mirror.value"
            @loading="loader.set($event)"
            @saved="updated"
        />
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loader.isLoading()"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import Mirror from '@/modules/legacy/helpers/mirror.helper';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import TeamModel from '@/modules/human-resources/models/TeamModel';
    import TeamForm from '@/modules/human-resources/components/team/Forms/TeamForm.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {TeamForm},
        props: {
            teamModel: {
                required: true,
                type: TeamModel,
            },
        },

        setup(props) {
            const modal = ref();
            const loader: Loader = new Loader();
            const mirror: Ref<Mirror> = ref(new Mirror(props.teamModel));

            function show() {
                modal.value.show();
            }

            function hide() {
                modal.value.hide();
            }

            function onTeamModelUpdated() {
                hide();
                mirror.value.commit();
            }

            function updated() {
                onTeamModelUpdated();
                useNotification().success(__('hr:team_updated_successfully'));
            }

            watch(
                props.teamModel,
                value => {
                    mirror.value = new Mirror(value);
                },
                {deep: true}
            );

            return {
                mirror,
                modal,
                loader,
                show,
                updated,
                onTeamModelUpdated,
            };
        },
    });
</script>
