<template>
    <SimpleSubPageTemplate>
        <template #title>
            <MHeading>
                {{ __('user:the_users') }}
            </MHeading>
        </template>
        <template #actions>
            <MButton
                v-if="can('create', 'users')"
                icon-plus
                :label="__('user:add_user')"
                variant="primary"
                @click="newUser"
            />
        </template>
        <template #content>
            <UsersManagerList
                :loading="loading"
                :user-menus="userMenus"
                :users="users"
                @editUser="editUser"
                @openPermissions="openPermissions"
            />
        </template>
    </SimpleSubPageTemplate>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useAbility from '@/modules/app/composables/useAbility';
    import {menusWithRessources} from '@/modules/app/static/legacyPermissions';
    import useManager from '@/modules/app/composables/useManager.ts';
    import useModal from '@/modules/app/composables/useModal';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import UsersManagerModal from '@/modules/organization/components/UsersManager/UsersManagerModal.vue';
    import UsersManagerPermissionsModal from '@/modules/organization/components/UsersManager/UsersManagerPermissionsModal.vue';
    import UsersManagerList from '@/modules/organization/components/UsersManager/UsersManagerList.vue';
    import SimpleSubPageTemplate from '@/modules/app/components/templates/SimpleSubPageTemplate.vue';

    export default {
        components: {
            SimpleSubPageTemplate,
            UsersManagerList,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            users: [],
            selectedUser: {},
            loading: false,
            isEditingUser: false,
            can: useAbility().can,
        }),
        computed: {
            usersManagerPermissionsModal() {
                return useModal({
                    component: UsersManagerPermissionsModal,
                    props: {
                        nursery: this.nursery,
                        user: this.selectedUser,
                        nurseries: this.user.nurseries,
                    },
                    listeners: () => ({
                        edit: this.putUser,
                        update: this.updateUser,
                    }),
                });
            },

            usersManagerModal() {
                return useModal({
                    component: UsersManagerModal,
                    props: {
                        nursery: this.nursery,
                        user: this.selectedUser,
                        isEditing: this.isEditingUser,
                    },
                    listeners: () => ({
                        add: this.storeUser,
                        delete: this.deleteUser,
                        edit: this.putUser,
                    }),
                });
            },

            activeOrganization() {
                return useManager().activeOrganization;
            },
        },
        mounted() {
            this.getUsers();
        },
        methods: {
            getUsers() {
                this.loading = true;
                useApi()
                    .legacy.get(route('users.index'), {})
                    .then(response => {
                        this.users = response.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            storeUser(user) {
                this.users.push(user);
            },
            updateUser(user) {
                const userInList = this.users.find(item => item.id === user.id);

                if (userInList) {
                    const index = this.users.indexOf(userInList);
                    Vue.set(this.users, index, user);
                }
            },
            newUser() {
                this.selectedUser = {
                    id: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    password: null,
                    locale: this.activeOrganization.value.attributes.locale,
                };
                this.isEditingUser = false;
                this.usersManagerModal.show();
            },
            editUser(user) {
                this.selectedUser = _cloneDeep(user);
                this.isEditingUser = true;
                this.usersManagerModal.show();
            },
            putUser(user) {
                const userToEdit = this.users.find(item => item.id === user.id);
                if (userToEdit) {
                    const index = this.users.indexOf(userToEdit);
                    Vue.set(this.users, index, user);
                }
            },
            openPermissions(user) {
                this.selectedUser = _cloneDeep(user);
                this.usersManagerPermissionsModal.show();
            },
            deleteUser(user) {
                useMagicModal().deleteConfirmationModal({
                    title: __('user:delete_this_user_s_account_ask'),
                    text: __('user:all_accesses_for_user_will_be_deleted'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('nurseries.users.destroy', {
                                    nurseries: this.nursery.id,
                                    user: user.id,
                                })
                            )
                            .then(() => {
                                this.usersManagerModal.hide();
                                this.users = this.users.filter(item => item.id !== user.id);
                                useNotification().success(__('user:user_deleted_successfully'));
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },
            userMenus(user) {
                const menus = [];
                const userNursery = user.nurseries.find(item => item.id === this.nursery.id);
                if (userNursery) {
                    menusWithRessources().forEach(menu => {
                        if (useAbility().allows(menu.ability, userNursery.permissions)) {
                            menus.push(menu);
                        }
                    });
                }

                return menus;
            },
        },
    };
</script>
