<template>
    <WeeklyPlanningSkeleton v-if="loading" />
    <CHStack
        v-else-if="!activeRegistrationsInWeek || !activeRegistrationsInWeek.length"
        align="center"
        distribute="center"
    >
        <CText>{{ __('planning:no_pre-registration') }}</CText>
    </CHStack>
    <CVStack
        v-else
        gap="2"
    >
        <CHStack
            v-for="(registration, i) in activeRegistrationsInWeek"
            :key="i"
            class="tw-flex-wrap"
        >
            <div class="tw-flex tw-w-full tw-items-center tw-text-ellipsis tw-pr-2 md:tw-w-52">
                <FontAwesomeIcon
                    class="item-icon icon tw-mr-2"
                    :class="registration.attributes.kid_gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                    fixed-width
                    icon="fa-duotone fa-pen-alt"
                />

                <div class="tw-flex tw-h-full tw-items-center tw-truncate tw-font-grandhotel tw-text-xl tw-capitalize">
                    <router-link
                        v-if="$can('read', 'registrations')"
                        :class="registration.attributes.kid_gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                        :to="{
                            name: 'registrations.show',
                            params: {nursery: activeOrganization.getKey(), registration: registration.getKey()},
                        }"
                    >
                        {{ $capitalize(registration.kidFullname) }}
                    </router-link>
                    <span
                        v-else
                        :class="[
                            registration.attributes.kid_gender === 'male' ? 'tw-text-boy' : 'tw-text-girl',
                            'tw-truncate',
                        ]"
                    >
                        {{ $capitalize(registration.kidFullname) }}
                    </span>
                </div>
            </div>
            <CHStack
                class="tw-mt-1 tw-w-full md:tw-mt-0 md:tw-flex-1"
                gap="2"
            >
                <DailyRegistrationPlanning
                    v-for="(dayOfWeek, dayIndex) in daysInPeriod"
                    :key="'day-' + dayIndex"
                    :day="dayOfWeek"
                    :registration="registration"
                />
            </CHStack>
        </CHStack>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import DailyRegistrationPlanning from '@/modules/planning/components/simulation/Planning/DailyRegistrationPlanning.vue';
    import WeeklyPlanningSkeleton from '@/modules/planning/components/simulation/Planning/WeeklyPlanningSkeleton.vue';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import type RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import useEnumerateBetweenDates from '@/modules/planning/composables/core/useEnumerateBetweenDates';
    import useManager from '@/modules/app/composables/useManager';
    import moment from 'moment';

    export default defineComponent({
        components: {DailyRegistrationPlanning, WeeklyPlanningSkeleton},
        props: {
            from: {
                type: Number,
                required: true,
            },
            to: {
                type: Number,
                required: true,
            },
            registrations: {
                type: Array as PropType<RegistrationModel[]>,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
        },
        setup(props) {
            const loading = ref(false);

            const {activeOrganization} = useManager();

            const {getDays} = useEnumerateBetweenDates();
            const daysInPeriod = computed(() => getDays(moment.unix(props.from), moment.unix(props.to)));

            // Filter registrations not in week range and which has alredy been accepted
            const activeRegistrationsInWeek = computed(() =>
                props.registrations.filter(registration => {
                    const registrationStart = moment(registration.attributes.contract_date_period.started_at).unix();
                    const registrationEnd = moment(registration.attributes.contract_date_period.ended_at).unix();

                    // Get registration pivot for active orga to check if registration has alredy been accepted
                    const registrationPivot = registration
                        .organizations()
                        .value()
                        .first(
                            (organization: OrganizationModel) =>
                                organization.getKey() === activeOrganization.value.getKey()
                        )
                        .pivot();

                    if (registrationPivot.attributes.kid_id) {
                        return false;
                    } else if (registrationStart >= props.from && registrationStart <= props.to) {
                        return true;
                    } else if (registrationEnd >= props.from && registrationEnd <= props.to) {
                        return true;
                    } else return registrationStart <= props.from && registrationEnd >= props.to;
                })
            );

            return {
                activeOrganization,
                daysInPeriod,
                loading,
                moment,
                activeRegistrationsInWeek,
            };
        },
    });
</script>
