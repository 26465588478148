import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import type {Collection, Epoch} from '@meekohq/lumos';
import {Binding} from '@meekohq/lumos';

export const RejectAndRestoreTransactionsUseCaseBinding = new Binding<RejectAndRestoreTransactionsUseCase>();

export interface RejectAndRestoreTransactionsUseCase {
    rejectTransactionsAtDate(collection: Collection<TransactionModel>, failureDate: Epoch): Promise<boolean>;

    restoreTransactions(collection: Collection<TransactionModel>): Promise<boolean>;
}
