<template>
    <CListRow :hover="false">
        <CInline align-y="baseline">
            <div class="tw-w-2/12">
                {{ Epoch.fromJSDate(new Date(invoice.date)).toLocaleString(Epoch.presets.DATE_SHORT) }}
                <support-meeko :ressource="invoice" />
            </div>
            <div class="tw-w-2/12">
                {{ invoice.number }}
            </div>
            <div class="tw-w-2/12">
                {{ invoice.data.invoice.total }}
            </div>
            <div class="tw-w-2/12">
                <CInline
                    v-if="invoice.avoir"
                    align-x="center"
                    space="xs"
                >
                    <CBadge
                        v-if="invoice.status === 'paid'"
                        size="sm"
                        variant="success"
                    >
                        {{ __('subscription:credit_note_with_refund') }}
                    </CBadge>
                    <CBadge
                        v-else
                        size="sm"
                    >
                        {{ __('common:credit_note') }}
                    </CBadge>
                </CInline>
                <CInline
                    v-else
                    align-x="center"
                    space="xs"
                >
                    <CBadge
                        v-if="invoice.status === 'open'"
                        size="sm"
                        variant="danger"
                    >
                        {{ __('common:unpaid_one') }}
                    </CBadge>
                    <CBadge
                        v-if="invoice.status === 'void'"
                        size="sm"
                    >
                        {{ __('subscription:fenced') }}
                    </CBadge>
                    <CBadge
                        v-if="invoice.status === 'canceled'"
                        size="sm"
                    >
                        {{ __('subscription:canceled_female') }}
                    </CBadge>
                    <CBadge
                        v-if="invoice.status === 'sepa_disputed'"
                        size="sm"
                        variant="danger"
                    >
                        {{ __('subscription:dispute') }}
                    </CBadge>
                    <CBadge
                        v-if="invoice.status === 'uncollectible'"
                        size="sm"
                        variant="danger"
                    >
                        {{ __('common:uncollectible') }}
                    </CBadge>
                </CInline>
            </div>
            <CVStack
                align="center"
                class="!tw-w-2/12"
            >
                <MMenu v-if="invoice.status === 'open' || invoice.status === 'sepa_disputed'">
                    <MMenuButton>
                        <MButton
                            :loading="loader.isLoading('pay')"
                            size="sm"
                            @click="fetchPaymentMethods"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-credit-card" />
                            </template>
                            {{ __('subscription:pay_invoice') }}
                        </MButton>
                    </MMenuButton>
                    <MMenuItems>
                        <template v-if="loader.isLoading('paymentMethods')">
                            <MSkeleton class="tw-mx-4 tw-my-2 tw-h-6 tw-w-60" />
                        </template>
                        <template v-else-if="cardPaymentMethods.length">
                            <MMenuItem
                                v-for="paymentMethod in cardPaymentMethods"
                                :key="paymentMethod.id"
                                @click="payInvoice(paymentMethod)"
                            >
                                <template v-if="paymentMethod.type === 'card'">
                                    <CInline space="sm">
                                        <CBadge>{{ __('subscription:debit_card') }}</CBadge>
                                        <span>{{ paymentMethod.billing_details.name }}</span>
                                        <CBadge variant="yellow"> ••••{{ paymentMethod.card.last4 }} </CBadge>
                                    </CInline>
                                </template>
                                <template v-if="paymentMethod.type === 'sepa_debit'">
                                    <CInline space="sm">
                                        <CBadge>{{ __('common:iban') }}</CBadge>
                                        <span>{{ paymentMethod.billing_details.name }}</span>
                                        <CBadge variant="yellow">
                                            {{ paymentMethod.sepa_debit.country }}••••{{
                                                paymentMethod.sepa_debit.last4
                                            }}
                                        </CBadge>
                                    </CInline>
                                </template>
                            </MMenuItem>
                        </template>
                        <template v-else>
                            <div class="tw-mx-4 tw-my-2">
                                {{ __('subscription:no_payment_method') }}
                            </div>
                        </template>
                    </MMenuItems>
                </MMenu>
            </CVStack>
            <div class="tw-w-2/12 tw-text-right">
                <a
                    :href="invoiceLink"
                    target="_blank"
                >
                    <MButton
                        size="sm"
                        variant="primary"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-download"
                        />{{ __('common:actions.download') }}
                    </MButton>
                </a>
            </div>
        </CInline>
    </CListRow>
</template>

<script lang="ts">
    import {computed, defineComponent, reactive, ref} from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import {filter, forEach} from 'lodash-es';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import route from '@/modules/legacy/libs/ziggy';
    import swal from 'sweetalert2/dist/sweetalert2';
    import moment from 'moment';
    import useAuth from '@/modules/app/composables/useAuth';
    import {Epoch} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import services from '@/config/services';

    export default defineComponent({
        components: {},
        props: {
            invoice: {type: Object, required: true},
            customer: {type: Object, required: true},
            user: {type: Object, required: true},
        },
        setup(props) {
            const loader = reactive(new Loader());
            const paymentMethods = ref();
            // @ts-ignore
            const stripeClient = window.Stripe(services.stripe.key);
            const {apiToken} = useAuth();

            const fetchPaymentMethods = function () {
                loader.start('paymentMethods');
                useApi()
                    .legacy.get(
                        route('customers.sources.index', {
                            customer: props.customer.id,
                        })
                    )
                    .then(response => {
                        paymentMethods.value = response.data.sources;
                        loader.stop('paymentMethods');
                    });
            };

            const successMessage = function () {
                swal({
                    title: __('subscription:invoice_payed_successfully'),
                    type: 'success',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonClass: 'btn btn-primary',
                    confirmButtonText: __('common:actions.close'),
                    buttonsStyling: false,
                });
            };

            const setSubscriptionPaid = function () {
                props.customer.subscription_status = 'active';
                props.invoice.status = 'paid';
            };

            const confirmPayment = function (paymentMethod, clientSecret) {
                let promise;
                if (paymentMethod.type === 'card') {
                    promise = stripeClient.confirmCardPayment(clientSecret);
                }
                if (paymentMethod.type === 'sepa_debit') {
                    promise = stripeClient.confirmSepaDebitPayment(clientSecret);
                }
                promise.then(result => {
                    if (result.error) {
                        swal({
                            title: result.error.message,
                            type: 'error',
                            showCloseButton: true,
                            showCancelButton: false,
                            confirmButtonClass: 'btn btn-primary',
                            confirmButtonText: __('common:actions.close'),
                            buttonsStyling: false,
                        });
                        // Reset Loading State
                        loader.stop('pay');
                    } else {
                        successMessage();
                        setSubscriptionPaid();
                        loader.stop('pay');
                    }
                });
            };

            const payInvoice = function (paymentMethod) {
                loader.start('pay');

                if (props.invoice.status === 'sepa_disputed') {
                    useApi()
                        .legacy.post(
                            route('customers.invoices.payWithNewPayment', {
                                customer: props.customer.id,
                                invoice: props.invoice.id,
                                payment_method: paymentMethod.id,
                            })
                        )
                        .then(response => {
                            if (response.data.status === 'requires_confirmation') {
                                confirmPayment(paymentMethod, response.data.client_secret);
                            } else {
                                successMessage();
                                setSubscriptionPaid();
                            }
                        })
                        .catch(error => {
                            forEach(error.response.data.errors, function (value) {
                                swal({
                                    title: value[0],
                                    type: 'warning',
                                    showCloseButton: true,
                                    showCancelButton: false,
                                    confirmButtonClass: 'btn btn-primary',
                                    confirmButtonText: __('common:actions.close'),
                                    buttonsStyling: false,
                                });
                            });
                        })
                        .finally(() => {
                            loader.stop('pay');
                        });
                } else {
                    useApi()
                        .legacy.post(
                            route('customers.invoices.pay', {
                                customer: props.customer.id,
                                invoice: props.invoice.id,
                                payment_method: paymentMethod.id,
                            })
                        )
                        .then(response => {
                            if (response.data.payment_intent) {
                                confirmPayment(paymentMethod, response.data.payment_intent);
                            } else {
                                successMessage();
                                setSubscriptionPaid();
                            }
                        })
                        .catch(error => {
                            forEach(error.response.data.errors, function (value) {
                                swal({
                                    title: value[0],
                                    type: 'warning',
                                    showCloseButton: true,
                                    showCancelButton: false,
                                    confirmButtonClass: 'btn btn-primary',
                                    confirmButtonText: __('common:actions.close'),
                                    buttonsStyling: false,
                                });
                            });
                        })
                        .finally(() => {
                            loader.stop('pay');
                        });
                }
            };

            const invoiceLink = computed(() => {
                const url = route('invoice', {invoice: props.invoice.id});

                return `${url}?temporary_token=${apiToken.value}`;
            });

            const cardPaymentMethods = computed(() => {
                return filter(paymentMethods.value, pm => pm.type === 'card');
            });

            return {
                Epoch,
                payInvoice,
                moment,
                invoiceLink,
                loader,
                fetchPaymentMethods,
                cardPaymentMethods,
            };
        },
    });
</script>
