<template>
    <component
        :is="dynamicTag"
        v-bind="dynamicProperties"
        :class="['CButton', styling, 'tw-group']"
        :disabled="disabledState"
        v-on="$listeners"
    >
        <div
            v-if="hasBeforeIcons"
            class="CButton__icons"
        >
            <font-awesome-icon
                v-if="loading"
                icon="fa-duotone fa-sync-alt"
                spin
            />
            <template v-else>
                <slot name="left-icons" />
                <font-awesome-icon
                    v-if="iconTrash"
                    icon="fa-solid fa-trash"
                />
                <font-awesome-icon
                    v-if="iconPlus"
                    icon="fa-solid fa-plus"
                />
                <font-awesome-icon
                    v-if="iconEdit"
                    icon="fa-duotone fa-pen"
                />
                <font-awesome-icon
                    v-if="iconEllipsis"
                    class="CButton__ellipsis-icon"
                    icon="fa-solid fa-ellipsis-h"
                />
            </template>
        </div>
        <template v-if="loading && round" />
        <div
            v-else-if="label || $slots['default']"
            class="CButton__content"
            :class="contentClass"
        >
            <slot>
                {{ label }}
            </slot>
        </div>
        <div
            v-if="hasRightIcons"
            class="CButton__icons"
        >
            <slot name="right-icons" />
        </div>
        <div
            v-if="hasAfterIcons"
            class="CButton__action-icons"
        >
            <slot name="action-icons" />
            <font-awesome-icon
                v-if="iconMenu"
                icon="fa-solid fa-caret-down"
            />
            <font-awesome-icon
                v-if="iconSort"
                icon="fa-solid fa-sort"
            />
            <font-awesome-icon
                v-if="iconExternalLink"
                icon="fa-solid fa-external-link-alt"
            />
        </div>
    </component>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {computed, defineComponent, toRef, useSlots} from 'vue';
    import useResponsiveProps, {
        containerSizeProp,
        responsiveProp,
    } from '@/modules/coherence-ui/hooks/useResponsiveProps';

    export default defineComponent({
        props: {
            label: {type: String, default: ''},
            containerSize: containerSizeProp(),
            /**
             * @values light, primary, success, danger, info, cyan, blue, green, red, orange, yellow
             */
            variant: responsiveProp<string>(String, 'light'),
            /**
             * @values xs, sm, base, lg
             */
            size: responsiveProp<string>(String, 'base'),
            align: {type: String, default: 'center'},
            truncate: {type: Boolean, default: true},
            loading: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            iconExternalLink: {type: Boolean, default: false},
            iconTrash: {type: Boolean, default: false},
            iconMenu: {type: Boolean, default: false},
            iconSort: {type: Boolean, default: false},
            iconPlus: {type: Boolean, default: false},
            iconEdit: {type: Boolean, default: false},
            iconEllipsis: {type: Boolean, default: false},
            round: {type: Boolean, default: false},
            zoom: {type: Boolean, default: false},
            to: {type: Object},
        },
        setup(props) {
            const {dynamicTag, dynamicProperties} = useDynamicComponent(toRef(props, 'to'));
            const disabledState = computed(() => {
                return props.disabled || props.loading;
            });
            const slots = useSlots();
            const hasBeforeIcons = computed(() => {
                return (
                    props.loading ||
                    props.iconTrash ||
                    props.iconPlus ||
                    props.iconEdit ||
                    props.iconEllipsis ||
                    slots['left-icons'] !== undefined
                );
            });
            const hasRightIcons = computed(() => {
                return slots['right-icons'] !== undefined;
            });
            const hasAfterIcons = computed(() => {
                return (
                    props.iconMenu || props.iconSort || props.iconExternalLink || slots['action-icons'] !== undefined
                );
            });

            const {responsiveProps} = useResponsiveProps(props, ['variant', 'size'], 'containerSize');

            const styling = computed(() => {
                const output = [] as any[];

                output.push(sizeValues[(responsiveProps.value.size as string) || 'base']);
                output.push(roundValues[props.round.toString()]);

                output.push(variantValues[(responsiveProps.value.variant as string) || 'light']);
                output.push(zoomValues[props.zoom.toString()]);

                return output;
            });

            const contentClass = computed(() => {
                const output = [] as any[];

                if (props.truncate) {
                    output.push('CButton__content--truncate');
                }

                output.push(alignValues[props.align]);

                return output;
            });

            return {
                dynamicTag,
                dynamicProperties,
                disabledState,
                hasBeforeIcons,
                hasRightIcons,
                hasAfterIcons,
                styling,
                contentClass,
            };
        },
    });

    export const variantValues = {
        'none': '',
        // Theme
        'light': 'CButton--variant-light',
        'primary': 'CButton--variant-primary',
        'success': 'CButton--variant-success',
        'danger': 'CButton--variant-danger',
        'info': 'CButton--variant-info',
        // Colors
        'cyan': 'CButton--variant-cyan',
        'blue': 'CButton--variant-blue',
        'green': 'CButton--variant-green',
        'red': 'CButton--variant-red',
        'orange': 'CButton--variant-orange',
        'yellow': 'CButton--variant-yellow',
        'purple': 'CButton--variant-purple',
        'pink': 'CButton--variant-pink',
        'gray': 'CButton--variant-gray',
        'black': 'CButton--variant-black',
        // Others
        'ghost': 'CButton--variant-ghost',
        // Links
        'link': 'CButton--variant-link-primary',
        'link-danger': 'CButton--variant-link-danger',
        'link-orange': 'CButton--variant-link-orange',
        'link-purple': 'CButton--variant-link-purple',
        'primary-alt': 'CButton--variant-primary-alt',
        'light-alt': 'CButton--variant-light-alt',
        // dasheds
        'dashed-light': 'CButton--variant-dashed-light',
    };

    export const sizeValues = {
        xs: 'CButton--size-xs',
        sm: 'CButton--size-sm',
        base: 'CButton--size-base',
        lg: 'CButton--size-lg',
    };

    export const roundValues = {
        true: 'CButton--round',
        false: '',
    };

    export const zoomValues = {
        true: 'CButton--zoom',
        false: '',
    };

    export const alignValues = {
        left: 'CButton__content--align-left',
        center: 'CButton__content--align-center',
        right: 'CButton__content--align-right',
    };

    function useDynamicComponent(to: Ref<object | undefined>) {
        const dynamicTag = computed(() => {
            return to.value ? 'RouterLink' : 'button';
        });

        const dynamicProperties = computed(() => {
            if (to.value) {
                return {
                    to: to.value,
                    replace: (to.value as any).replace || false,
                };
            }

            return {type: 'button'};
        });

        return {
            dynamicTag,
            dynamicProperties,
        };
    }
</script>
