<template>
    <ResourceFinder
        :always-open="alwaysOpen"
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-size="buttonSize"
        :button-variant="buttonVariant"
        :has-unselect="hasUnselect"
        :hide-selected-option="hideSelectedOption"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :prevent-hide-on-click="preventHideOnClick"
        :value="value"
        :wrapper="wrapper"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @input="onChange"
        @search="search = $event"
    >
        <template #trigger="{toggle}">
            <slot
                name="trigger"
                :toggle="toggle"
                :value="value"
            />
        </template>
        <template #button-text-empty>
            <slot name="button-text-empty">
                {{ __('common:select_customer_account') }}
            </slot>
        </template>

        <template #button-text="{value}">
            {{ value.attributes.name }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{ __('components:count_item', {count: values.length - 1, value: values[0].attributes.name}) }}
            </slot>
        </template>

        <template
            v-if="$slots['fallback']"
            #fallback
        >
            <slot name="fallback" />
        </template>

        <template #default="{option, searchValue}">
            <div class="tw-flex tw-flex-row tw-items-center">
                <div
                    class="tw-mr-2 tw-w-full tw-truncate"
                    v-html="highlight(searchValue, option.attributes.name)"
                />
                <CText
                    v-if="option.attributes.account_number"
                    class="tw-shrink-0"
                    font-size="sm"
                    variant="gray"
                >
                    {{ highlight(searchValue, option.attributes.account_number) }}
                </CText>
            </div>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type {Model, QueryBuilder} from '@meekohq/lumos';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import TenantModel from '@/modules/cashier/models/TenantModel';

    export default defineComponent({
        components: {ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            value: {type: [Object, Array] as PropType<Model | Model[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            hideSelectedOption: {type: Boolean, default: false},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
            buttonSize: {},
            onlyUserOrganizations: {type: Boolean, default: false},
            hasActiveContract: {type: Boolean, default: false},
            alwaysOpen: {type: Boolean, default: false},
            preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},
            wrapper: {type: String as PropType<'CPopover' | 'ResourceFinderWrapper'>, default: 'CPopover'},
        },
        emits: ['input', 'fallback'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function (value) {
                emit('input', value);
            };
            // !!! END: DO NOT EDIT !!

            const queryBuilder = computed(() => {
                const builder = CustomerModel.query()
                    .tap(query => {
                        if (search.value) {
                            query.where(query2 => {
                                query2.whereLike('account_number', `*${search.value}*`);
                                query2.orWhereLike('name', `*${search.value}*`);
                            });
                        } else {
                            query.whereNull('archived_at');
                        }
                    })
                    .orderBy('name');

                // Only get kids that are related to the current user organizations
                if (props.onlyUserOrganizations) {
                    builder.whereHas(new CustomerModel().tenant(), query => {
                        return query.whereHas(new TenantModel().organizations(), query2 => {
                            return query2.scope('userOrganizations');
                        });
                    });
                }

                if (props.hasActiveContract && !search.value) {
                    builder.scope('withActiveContract');
                }

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            const onFallback = function () {
                emit('fallback');
            };

            return {
                search,
                onChange,
                onFallback,
                queryBuilder,
                ...useHighlighter(),
            };
        },
    });
</script>
