<script setup lang="ts">
    import type {ModalType} from '@/modules/app/composables/useModal';
    import type {PropType} from 'vue';
    import {computed, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import type {MeekoTemplate} from '@/modules/organization/utils/MeekoTemplates';
    import MeekoTemplates from '@/modules/organization/utils/MeekoTemplates';
    import type {DocTemplatesDestination} from '@/modules/organization/utils/DocTemplatesDestinations';
    import DocTemplatesDestinations from '@/modules/organization/utils/DocTemplatesDestinations';
    import useError from '@/modules/app/composables/useError';
    import useMagicModal from '@/modules/app/composables/useMagicModal';

    defineProps({
        modal: {
            type: Object as PropType<ModalType>,
            required: true,
        },
    });

    const emit = defineEmits(['saved']);

    const errorHandler = useError();

    const docTemplatesDestinations = DocTemplatesDestinations();
    const meekoTemplates = MeekoTemplates();

    const emptyMeekoModel = meekoTemplates.find(template => template.textId === 'new') as MeekoTemplate;
    const emptyMeekoModelDestination = docTemplatesDestinations.find(
        destination => destination.type === emptyMeekoModel.type
    );

    const docTemplateDestination = ref<DocTemplatesDestination>(emptyMeekoModelDestination as DocTemplatesDestination);
    const docTemplateTextId = ref<string>(emptyMeekoModel.textId);
    const docTemplateName = ref(emptyMeekoModel.name);

    const selectedMeekoTemplate = computed(() => {
        return meekoTemplates.find(template => template.textId === docTemplateTextId.value);
    });

    function onMeekoTemplateSelected() {
        if (selectedMeekoTemplate.value) {
            docTemplateName.value = selectedMeekoTemplate.value.name;
            docTemplateDestination.value = docTemplatesDestinations.find(
                destination => destination.type === selectedMeekoTemplate.value!.type
            ) as DocTemplatesDestination;
        }
    }

    function selectDocTemplateDestination(templateType: DocTemplatesDestination) {
        useMagicModal().confirmationModal({
            text: __('organization:doc_templates_destination_change_confirmation', {type: templateType.name}),
            onConfirm: async () => {
                docTemplateDestination.value = templateType;
            },
        });
    }

    function saveTemplate() {
        const templateModel = selectedMeekoTemplate.value;

        errorHandler.reset();

        if (!docTemplateName.value) {
            errorHandler.addErrorCode('name', '0x2EAA809FB3');

            return;
        }

        templateModel!.id = null;
        templateModel!.name = docTemplateName.value;
        templateModel!.type = docTemplateDestination.value!.type;
        emit('saved', templateModel);
    }
</script>

<template>
    <MModal
        :header-title="__('organization:add_doc_template')"
        :modal="modal"
    >
        <CForm>
            <CFormGroup class="DocTemplatesManagerModalCreate__form-group">
                <CLabel>{{ __('organization:preset_doc_template_model_colon') }}</CLabel>
                <CFormSelect
                    v-model="docTemplateTextId"
                    :fallback-text="__('organization:select_preset_doc_template_model_dots')"
                    :options="meekoTemplates"
                    text-path="selectorName"
                    value-path="textId"
                    @input="onMeekoTemplateSelected()"
                />
            </CFormGroup>
            <MInputGroup
                v-model="docTemplateName"
                class="DocTemplatesManagerModalCreate__form-group"
                :class="{'DocTemplatesManagerModalCreate__form-group--disabled': !docTemplateTextId}"
                :errors="errorHandler.get('name')"
                :label="__('common:name_colon')"
                :placeholder="__('organization:template_name')"
            />
            <CFormGroup class="DocTemplatesManagerModalCreate__form-group">
                <CLabel>{{ __('organization:use_template_for_colon') }}</CLabel>
                <MMenu class="tw-w-full">
                    <MMenuButton class="tw-w-full">
                        <MButton
                            class="tw-w-full tw-justify-between"
                            icon-menu
                        >
                            <template v-if="docTemplateDestination">
                                <FontAwesomeIcon
                                    class="tw-mr-1 tw-text-primary-500"
                                    :fixed-width="true"
                                    :icon="docTemplateDestination.icon"
                                />
                                {{ docTemplateDestination.name }}
                            </template>
                            <template v-else>
                                {{ __('organization:select_from_list_dots') }}
                            </template>
                        </MButton>
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem
                            v-for="destination in docTemplatesDestinations"
                            :key="destination.type"
                            :label="destination.name"
                            @click="selectDocTemplateDestination(destination)"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    :fixed-width="true"
                                    :icon="destination.icon"
                                />
                            </template>
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>
            </CFormGroup>
        </CForm>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.save')"
                variant="primary"
                @click="saveTemplate"
            />
        </template>
    </MModal>
</template>

<style scoped>
    .DocTemplatesManagerModalCreate__form-group {
        @apply tw-min-w-0;
    }
</style>
