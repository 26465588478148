<template>
    <div
        ref="clickableRef"
        class="RessourceSelector"
    >
        <ResourceList
            v-if="multi"
            :resources="collectionSelectedResources.all()"
            show-delete
            @delete="unselectResource($event)"
        />
        <CPopover
            ref="dropdownRef"
            class="tw-w-min"
            :emitter="bus"
            no-padding
            placement="bottom-start"
            @hidden="toggleFinderModal(false)"
            @shown="toggleFinderModal(true)"
        >
            <MButton
                :class="buttonClass"
                :variant="variant"
            >
                <template #left-icons>
                    <FontAwesomeIcon
                        v-if="multi || (collectionSelectedResources.isEmpty() && !multi)"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        :icon="computedResources[0].icon"
                    />
                </template>
                <template #default>
                    {{ collectionSelectedResources.isNotEmpty() && !multi ? computedResources[0].name : placeholder }}
                </template>
            </MButton>
            <template #content>
                <div class="ResourcesSelector__popper-container">
                    <ul
                        v-if="showCategory"
                        class="ResourcesSelector__category-list"
                    >
                        <li
                            v-for="category in allowedCategories"
                            :key="category.type"
                        >
                            <ResourcesSelectorCategory
                                :category="category"
                                :selected="selectedCategory && category.type === selectedCategory.type"
                                @selected="selectCategory($event)"
                            />
                        </li>
                    </ul>
                    <component
                        :is="selectedCategory.finder"
                        v-if="selectedCategory && isFinderVisible"
                        always-open
                        class="ResourcesSelector__data-list"
                        :inject-query="injectQueryFinder"
                        :value="selectedCategoryResources"
                        v-bind="selectedCategory.finderProps"
                        wrapper="ResourceFinderWrapper"
                        @change="selectResource($event)"
                        @input="selectResource($event)"
                    />
                </div>
            </template>
        </CPopover>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, toRef, watch} from 'vue';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import type {Model, ModelCollection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import {onClickOutside, useToggle} from '@vueuse/core';
    import __ from '@/modules/app/utils/i18n-facade';
    import mitt from 'mitt';
    import type {EventType} from '@/modules/meeko-ui/components/CPopover.vue';
    import type {AskedCategory} from '@/modules/app/composables/useResourceSelectorCategories';
    import useResourceSelectorCategories from '@/modules/app/composables/useResourceSelectorCategories';
    import useResourceSelectorValue from '@/modules/app/composables/useResourceSelectorValue';
    import useResource from '@/modules/app/composables/useResource';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import KidModel from '@/modules/family/models/KidModel';
    import MemberModel from '@/modules/family/models/MemberModel';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import KidFinder from '@/modules/family/components/kid/KidFinder.vue';
    import MemberFinder from '@/modules/family/components/member/MemberFinder.vue';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import RegistrationFinder from '@/modules/registration/components/molecules/RegistrationFinder.vue';
    import CustomerFinder from '@/modules/cashier/components/core/customer/organisms/CustomerFinder.vue';
    import ResourceList from '@/modules/app/components/resource/organisms/ResourceList.vue';
    import ResourcesSelectorCategory from '@/modules/app/components/resource/molecules/ResourcesSelectorCategory.vue';
    import ClickOutsideIgnoredElementsStore from '@/modules/meeko-ui/stores/useClickOutsideIgnoredElementsStore';

    export default defineComponent({
        components: {
            ResourcesSelectorCategory,
            OrganizationFinder,
            KidFinder,
            MemberFinder,
            StaffFinder,
            CustomerFinder,
            RegistrationFinder,
            ResourceList,
        },
        props: {
            value: {
                type: Object as PropType<ModelCollection<Model> | Model>,
                default: () => collect(),
            },
            buttonClass: {
                type: String,
                default: '',
            },
            variant: {
                type: String,
                default: 'light',
            },
            categories: {
                type: Array as PropType<AskedCategory[]>,
                default: () => [
                    {
                        type: new KidModel().getType(),
                        finderProps: {hasActiveContract: true, onlyUserOrganizations: true, hideSelectedOption: true},
                    },
                    {
                        type: new MemberModel().getType(),
                        finderProps: {hasActiveContract: true, onlyUserOrganizations: true, hideSelectedOption: true},
                    },
                    {
                        type: new StaffModel().getType(),
                        finderProps: {hasActiveContract: true, onlyUserOrganizations: true, hideSelectedOption: true},
                    },
                    {
                        type: new RegistrationModel().getType(),
                        finderProps: {
                            ongoingRegistrations: true,
                            onlyUserOrganizations: true,
                            hideSelectedOption: true,
                        },
                    },
                    {
                        type: new CustomerModel().getType(),
                        finderProps: {hasActiveContract: true, onlyUserOrganizations: true, hideSelectedOption: true},
                    },
                    {
                        type: new OrganizationModel().getType(),
                        finderProps: {onlyUserOrganizations: true, hideSelectedOption: true},
                    },
                ],
            },
            multi: {
                type: Boolean,
                default: true,
            },
            placeholder: {
                type: String,
                default: () => __('common:actions.select'),
            },
            showCategory: {
                type: Boolean,
                default: () => true,
            },
        },
        setup(props, context) {
            const bus = mitt<EventType>();

            const [isFinderVisible, toggleFinderModal] = useToggle(false);
            const askedCatergories = toRef(props, 'categories');
            const {allowedCategories, selectedCategory, selectCategory} =
                useResourceSelectorCategories(askedCatergories);

            watch(askedCatergories, () => {
                const resourceSelectorCategories = useResourceSelectorCategories(askedCatergories);
                selectedCategory.value = resourceSelectorCategories.selectedCategory.value;
            });

            const value = toRef(props, 'value');
            const isMultiSelect = toRef(props, 'multi');
            const {
                selectedCategoryResources,
                collectionSelectedResources,
                selectedResources,
                selectResource,
                unselectResource,
            } = useResourceSelectorValue(value, allowedCategories, selectedCategory, isMultiSelect, context, bus);

            // Filter finder query to remove already selected resources
            const injectQueryFinder = computed(() => {
                const excludedResources = collectionSelectedResources.value.filter(
                    item => item.getType() === selectedCategory.value.type
                );

                const excludedIds = excludedResources.pluck('id');

                let query;

                if (selectedCategory.value.finderProps?.injectQuery) {
                    // Clone because if not, the whereNotIn will be added to the original query
                    query = _cloneDeep(selectedCategory.value.finderProps.injectQuery);
                }

                if (excludedIds.count()) {
                    if (!query) {
                        query = excludedResources.first().newQuery();
                    }
                    query.whereNotIn('id', excludedIds.toArray());
                }

                return query;
            });

            const arraySelectedResources = computed(() => collectionSelectedResources.value.all());
            const {computedResources} = useResource(arraySelectedResources);

            const clickableRef = ref();
            const dropdownRef = ref();

            const {ignore} = ClickOutsideIgnoredElementsStore();

            onClickOutside(clickableRef, () => dropdownRef.value.hide(), {ignore});

            return {
                allowedCategories,
                selectedCategoryResources,
                collectionSelectedResources,
                selectedCategory,
                injectQueryFinder,
                selectedResources,
                computedResources,
                clickableRef,
                dropdownRef,
                selectCategory,
                selectResource,
                unselectResource,
                bus,
                isFinderVisible,
                toggleFinderModal,
            };
        },
    });
</script>

<style scoped>
    .RessourceSelector {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .ResourcesSelector__popper-container {
        @apply tw-flex tw-flex-row;
    }

    .ResourcesSelector__category-list {
        @apply tw-p-2;
        @apply tw-flex tw-flex-col tw-gap-1;
    }

    .ResourcesSelector__data-list {
        @apply tw-border-l tw-border-gray-200;
    }

    .ResourcesSelector__category-list-item--active {
        @apply tw-bg-blue-100;
    }
</style>
