import {computed} from 'vue';
import type useMCalendarState from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
import __ from '@/modules/app/utils/i18n-facade';
import {Epoch} from '@meekohq/lumos';
import injectableKey from '@/modules/app/utils/injectableKey';
import {isNil} from 'lodash-es';
import type {DateTimePeriod, DateTimeSingle, DateTimeValue} from '@/modules/meeko-ui/types/DateTime';

export interface MCalendarPreset {
    label: string;
    getValue: () => DateTimeValue;
    isEnabledCustom?: () => boolean;
}

export const keyMCalendarPresets = injectableKey<ReturnType<typeof useMCalendarPresets>>();

export default function useMCalendarPresets(
    state: ReturnType<typeof useMCalendarState>,
    customPresets: MCalendarPreset[] = [],
    overridePresets = false
) {
    const {internalValue, mode, isPeriod, setDate} = state;

    const presets = computed(() => {
        if (overridePresets) {
            return customPresets;
        }

        let defaultPresets: MCalendarPreset[];
        switch (mode) {
            case 'time':
                defaultPresets = useMCalendarPresetsModeTime(state).presets.value;
                break;
            case 'dateTime':
            case 'date':
                defaultPresets = useMCalendarPresetsModeDate(state).presets.value;
                break;
            case 'month':
                defaultPresets = useMCalendarPresetsModeMonth(state).presets.value;
                break;
            case 'year':
                defaultPresets = useMCalendarPresetsModeYear(state).presets.value;
                break;
            default:
                defaultPresets = [];
                break;
        }

        return defaultPresets.concat(customPresets);
    });

    function isPresetEnabled(preset: MCalendarPreset) {
        if (preset.isEnabledCustom && !preset.isEnabledCustom()) {
            return false;
        }

        if (!isPeriod) {
            return !!internalValue.value.from?.equalTo(preset.getValue() as DateTimeSingle);
        }

        const presetValue = preset.getValue() as DateTimePeriod;

        let isFromEqual: boolean;
        if (!internalValue.value.from) {
            isFromEqual = isNil(presetValue.from);
        } else {
            isFromEqual = internalValue.value.from.equalTo(presetValue.from);
        }

        let isToEqual: boolean;
        if (!internalValue.value.to) {
            isToEqual = isNil(presetValue.to);
        } else {
            isToEqual = internalValue.value.to.equalTo(presetValue.to);
        }

        return isFromEqual && isToEqual;
    }

    function activatePreset(preset: MCalendarPreset) {
        setDate(preset.getValue());
    }

    return {
        presets,
        isPresetEnabled,
        activatePreset,
    };
}

function useMCalendarPresetsModeTime(state: ReturnType<typeof useMCalendarState>) {
    const {isPeriod} = state;

    const {untilSelectedDate, startingSelectedDate} = useMCalendarPresetsCommon(state);

    const presets = computed<MCalendarPreset[]>(() => {
        if (isPeriod) {
            return [
                {
                    label: __('common:all_day'),
                    getValue: () => ({
                        from: Epoch.now().startOfDay(),
                        to: Epoch.now().endOfDay(),
                    }),
                },
                untilSelectedDate,
                startingSelectedDate,
            ];
        }

        return [];
    });

    return {
        presets,
    };
}

function useMCalendarPresetsModeDate(state: ReturnType<typeof useMCalendarState>) {
    const {isPeriod} = state;

    const {today, tomorrow, yesterday, thisMonth, lastMonth, nextMonth, untilSelectedDate, startingSelectedDate} =
        useMCalendarPresetsCommon(state);

    const presets = computed<MCalendarPreset[]>(() => {
        if (isPeriod) {
            return [
                today,
                tomorrow,
                yesterday,
                thisMonth,
                lastMonth,
                nextMonth,
                untilSelectedDate,
                startingSelectedDate,
            ];
        }

        return [today, tomorrow, yesterday];
    });

    return {
        presets,
    };
}

function useMCalendarPresetsModeMonth(state: ReturnType<typeof useMCalendarState>) {
    const {isPeriod} = state;

    const {thisMonth, lastMonth, nextMonth, untilSelectedDate, startingSelectedDate} = useMCalendarPresetsCommon(state);

    const presets = computed<MCalendarPreset[]>(() => {
        if (isPeriod) {
            return [thisMonth, lastMonth, nextMonth, untilSelectedDate, startingSelectedDate];
        }

        return [thisMonth, lastMonth, nextMonth];
    });

    return {
        presets,
    };
}

function useMCalendarPresetsModeYear(state: ReturnType<typeof useMCalendarState>) {
    const {isPeriod} = state;

    const {thisYear, lastYear, nextYear, untilSelectedDate, startingSelectedDate} = useMCalendarPresetsCommon(state);

    const presets = computed<MCalendarPreset[]>(() => {
        if (isPeriod) {
            return [thisYear, lastYear, nextYear, untilSelectedDate, startingSelectedDate];
        }

        return [thisYear, lastYear, nextYear];
    });

    return {
        presets,
    };
}

function useMCalendarPresetsCommon(state: ReturnType<typeof useMCalendarState>) {
    const {internalValue, isPeriod, mode} = state;

    const today: MCalendarPreset = {
        label: __('common:date_format.day_from_zero'),
        getValue: () => {
            if (isPeriod) {
                return {
                    from: Epoch.now().startOfDay(),
                    to: Epoch.now().endOfDay(),
                };
            }

            return Epoch.now().startOfDay();
        },
    };

    const yesterday: MCalendarPreset = {
        label: __('common:yesterday'),
        getValue: () => {
            if (isPeriod) {
                return {
                    from: Epoch.now().subDays(1).startOfDay(),
                    to: Epoch.now().subDays(1).endOfDay(),
                };
            }

            return Epoch.now().subDays(1).startOfDay();
        },
    };

    const tomorrow: MCalendarPreset = {
        label: __('common:tomorrow'),
        getValue: () => {
            if (isPeriod) {
                return {
                    from: Epoch.now().addDays(1).startOfDay(),
                    to: Epoch.now().addDays(1).endOfDay(),
                };
            }

            return Epoch.now().addDays(1).startOfDay();
        },
    };

    const thisMonth: MCalendarPreset = {
        label: __('common:this_month'),
        getValue: () => {
            if (isPeriod) {
                return {
                    from: Epoch.now().startOfMonth(),
                    to: Epoch.now().endOfMonth(),
                };
            }

            return Epoch.now().startOfMonth();
        },
    };

    const lastMonth: MCalendarPreset = {
        label: __('common:last_month'),
        getValue: () => {
            if (isPeriod) {
                return {
                    from: Epoch.now().subMonths(1).startOfMonth(),
                    to: Epoch.now().subMonths(1).endOfMonth(),
                };
            }

            return Epoch.now().subMonths(1).startOfMonth();
        },
    };

    const nextMonth: MCalendarPreset = {
        label: __('common:next_month'),
        getValue: () => {
            if (isPeriod) {
                return {
                    from: Epoch.now().addMonths(1).startOfMonth(),
                    to: Epoch.now().addMonths(1).endOfMonth(),
                };
            }

            return Epoch.now().addMonths(1).startOfMonth();
        },
    };

    const thisYear: MCalendarPreset = {
        label: __('common:this_year'),
        getValue: () => {
            if (isPeriod) {
                return {
                    from: Epoch.now().startOfYear(),
                    to: Epoch.now().endOfYear(),
                };
            }

            return Epoch.now().startOfYear();
        },
    };

    const lastYear: MCalendarPreset = {
        label: __('common:last_year'),
        getValue: () => {
            if (isPeriod) {
                return {
                    from: Epoch.now().subYears(1).startOfYear(),
                    to: Epoch.now().subYears(1).endOfYear(),
                };
            }

            return Epoch.now().subYears(1).startOfYear();
        },
    };

    const nextYear: MCalendarPreset = {
        label: __('common:next_year'),
        getValue: () => {
            if (isPeriod) {
                return {
                    from: Epoch.now().addYears(1).startOfYear(),
                    to: Epoch.now().addYears(1).endOfYear(),
                };
            }

            return Epoch.now().addYears(1).startOfYear();
        },
    };

    const untilSelectedDate: MCalendarPreset = {
        label: __('calendar:until_selected', {context: mode}),
        getValue: () => ({
            from: undefined,
            to: internalValue.value.to || internalValue.value.from || Epoch.now(),
        }),
        isEnabledCustom: () => {
            return !!internalValue.value.to || !!internalValue.value.from;
        },
    };

    const startingSelectedDate: MCalendarPreset = {
        label: __('calendar:starting_selected', {context: mode}),
        getValue: () => ({
            from: internalValue.value.from || internalValue.value.to || Epoch.now(),
            to: undefined,
        }),
        isEnabledCustom: () => {
            return !!internalValue.value.to || !!internalValue.value.from;
        },
    };

    const thisWeek: MCalendarPreset = {
        label: __('common:this_week'),
        getValue: () => ({
            from: Epoch.now().startOfWeek(),
            to: Epoch.now().endOfWeek(),
        }),
    };

    const lastWeek: MCalendarPreset = {
        label: __('common:last_week'),
        getValue: () => ({
            from: Epoch.now().subWeeks(1).startOfWeek(),
            to: Epoch.now().subWeeks(1).endOfWeek(),
        }),
    };

    const nextWeek: MCalendarPreset = {
        label: __('common:next_week'),
        getValue: () => ({
            from: Epoch.now().addWeeks(1).startOfWeek(),
            to: Epoch.now().addWeeks(1).endOfWeek(),
        }),
    };

    return {
        today,
        yesterday,
        tomorrow,
        thisMonth,
        lastMonth,
        nextMonth,
        thisYear,
        lastYear,
        nextYear,
        untilSelectedDate,
        startingSelectedDate,
        thisWeek,
        lastWeek,
        nextWeek,
    };
}
