import moment from 'moment';
import {EventBus} from '@/modules/legacy/utils/bus';

export default function () {
    function markAsRead(notification) {
        if (!notification.read_at) {
            notification.read_at = moment().unix();
            EventBus.$emit('update:notification', notification);
        }
    }

    return {
        markAsRead,
    };
}
