import {Model} from '@meekohq/lumos';
import SimulationListPivot from '@/modules/planning/models/SimulationListPivot';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import RegistrationModel from '@/modules/registration/models/RegistrationModel';

export default class SimulationListModel extends Model {
    public type = 'planning/simulation_lists';

    public attributes: {
        id: string;
        account_id: string | undefined;
        organization_id: string | undefined;
        name: string | undefined;
        period: {
            started_at: string | undefined;
            ended_at: string | undefined;
        };
    } = {
        id: this.uuid(),
        account_id: undefined,
        organization_id: undefined,
        name: undefined,
        period: {
            started_at: undefined,
            ended_at: undefined,
        },
    };

    simulationListPivots() {
        return this.hasMany('simulationListPivots', SimulationListPivot, 'list_id');
    }

    registrations() {
        return this.morphedByMany(
            'registrations',
            RegistrationModel,
            SimulationListPivot,
            'resource',
            'list_id',
            'resource_id'
        );
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }
}
