import {Epoch} from '@meekohq/lumos';
import type {CustomerQueryOptionsType} from '@/modules/cashier/composables/core/customer/usePaginateCustomer';
import {mapOptionsToQueryBuilder} from '@/modules/cashier/composables/core/customer/usePaginateCustomer';
import type {MagicMassSelectType} from '@/modules/app/composables/useMagicMassSelect';
import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import {cloneDeep} from 'lodash-es';

export default function useArchiveCustomer() {
    async function archiveByModel(customer: CustomerModel, date: Epoch = new Epoch()) {
        customer.attributes.archived_at = date.toISOString();
        await customer.save();
    }

    async function archiveByQuery(
        options: CustomerQueryOptionsType,
        massSelect: MagicMassSelectType,
        date: Epoch = new Epoch()
    ) {
        // Clone the options to avoid mutating the original object
        options = cloneDeep(options);

        // We don't need to load any relations
        options.with = [];

        const query = mapOptionsToQueryBuilder(options);

        if (!massSelect.queryMode.value) {
            query.whereIn('id', massSelect.selectedIds.value.toArray());
        }

        // Update the query to update only non-archived customers
        query.whereNull('archived_at');

        await query.update({archived_at: date.toISOString()});
    }

    async function unarchiveByModel(customer: CustomerModel) {
        customer.attributes.archived_at = null;
        await customer.save();
    }

    async function unarchiveByQuery(options: CustomerQueryOptionsType, massSelect: MagicMassSelectType) {
        // Clone the options to avoid mutating the original object
        options = cloneDeep(options);

        // We don't need to load any relations
        options.with = [];

        const query = mapOptionsToQueryBuilder(options);

        if (!massSelect.queryMode.value) {
            query.whereIn('id', massSelect.selectedIds.value.toArray());
        }

        // Update the query to update only archived customers
        query.whereNotNull('archived_at');

        await query.update({archived_at: null});
    }

    return {
        archiveByModel,
        archiveByQuery,
        unarchiveByModel,
        unarchiveByQuery,
    };
}
