<template>
    <CFormGroup>
        <CVStack gap="4">
            <CButtonGroup>
                <MButton
                    v-for="option in taskRemindAtOptions"
                    :key="option.id"
                    :disabled="!scheduledAt"
                    :variant="selectedOptionId === option.id ? 'primary' : 'light'"
                    @click="selectOption(option)"
                >
                    {{ option.text }}
                </MButton>
            </CButtonGroup>
            <CFormDatepicker
                v-if="isCustomDate"
                v-model="remindDate"
            />
        </CVStack>
    </CFormGroup>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import {Epoch} from '@meekohq/lumos';

    export default defineComponent({
        props: {
            taskScheduledAt: {
                type: String,
                default: null,
            },
            scheduledAt: {
                type: String,
                default: null,
            },
        },
        setup(props, {emit}) {
            const selectedOptionId: Ref<number | undefined> = ref();
            const isCustomDate = ref(false);
            const remindDate: Ref<string | null> = ref(null);

            const taskRemindAtOptions = computed(() => [
                {
                    id: 1,
                    text: __('health:days_before_with_day', {days: 15}),
                    daysOffset: 15,
                    isCustomDate: false,
                },
                {
                    id: 2,
                    text: __('health:days_before_with_day', {days: 30}),
                    daysOffset: 30,
                    isCustomDate: false,
                },
                {
                    id: 3,
                    text: __('health:custom_date'),
                    daysOffset: 0,
                    isCustomDate: true,
                },
            ]);

            const selectedOption = computed(() =>
                taskRemindAtOptions.value.find(option => option.id === selectedOptionId.value)
            );

            /**
             * Update variables related to the current selection
             * @param option
             * @param date
             */
            function selectOption(option, date?) {
                selectedOptionId.value = option.id;
                remindDate.value =
                    date ?? Epoch.fromISOString(props.scheduledAt).subDays(option.daysOffset).toISOString();
                isCustomDate.value = option.isCustomDate;
            }

            /**
             * Preselect option matching the dateToCompare parameter
             * @param dateToCompare
             */
            function preselectOption(dateToCompare: string) {
                // Find a match between the taskRemindAtOptions and the date to compare
                const optionToPreselect = taskRemindAtOptions.value.find(option => {
                    return Epoch.fromISOString(props.scheduledAt)
                        .subDays(option.daysOffset)
                        .equalTo(Epoch.fromISOString(dateToCompare));
                });

                if (optionToPreselect) {
                    selectOption(optionToPreselect, dateToCompare);
                } else {
                    // If no option is matching, fallback to the custom date option
                    selectOption(
                        taskRemindAtOptions.value.find(option => option.isCustomDate),
                        dateToCompare
                    );
                }
            }

            onMounted(() => {
                if (props.scheduledAt) {
                    if (props.taskScheduledAt) {
                        preselectOption(props.taskScheduledAt);
                    } else {
                        // Fallback to the 15 days before option
                        selectOption(taskRemindAtOptions.value.find(option => option.daysOffset === 15));
                    }
                }
            });

            watch(
                () => props.scheduledAt,
                value => {
                    if (!value) {
                        // Cancel the task scheduling if the action scheduled_at date is removed
                        isCustomDate.value = false;
                        remindDate.value = null;
                        emit('changed', null);

                        return;
                    }
                    if (selectedOption.value) {
                        selectOption(selectedOption.value);
                    } else {
                        // Fallback to the 15 days before option
                        selectOption(taskRemindAtOptions.value.find(option => option.daysOffset === 15));
                    }
                }
            );

            watch(remindDate, value => {
                emit('changed', value);
            });

            return {
                selectedOption,
                selectedOptionId,
                isCustomDate,
                remindDate,
                taskRemindAtOptions,
                selectOption,
            };
        },
    });
</script>
