<template>
    <CFormGroup class="EditInvoiceConditions">
        <CLabel>
            {{ __('billing_invoice:terms_and_conditions') }}
        </CLabel>
        <CFormTextArea
            v-if="editable && can('update', 'invoices')"
            v-model="invoice.conditions"
            :placeholder="__('billing_invoice:by_virement_dots')"
            rows="3"
        />
        <CText
            v-else
            class="EditInvoiceConditions__text"
        >
            {{ invoice.conditions }}
        </CText>
    </CFormGroup>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import useAbility from '@/modules/app/composables/useAbility';

    export default defineComponent({
        props: {
            invoice: {
                type: Object,
                required: true,
            },
            editable: {
                type: Boolean,
                required: true,
            },
        },

        setup() {
            const {can} = useAbility();

            return {
                can,
            };
        },
    });
</script>

<style scoped>
    .EditInvoiceConditions__text {
        @apply tw-whitespace-pre-line;
    }
</style>
