<template>
    <input
        ref="inputEl"
        class="CInput"
        :class="styling"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :type="type"
        :value="value"
        v-on="listeners($listeners)"
    />
</template>

<script lang="ts">
    import {computed, defineComponent, nextTick, onMounted, ref} from 'vue';

    export default defineComponent({
        components: {
            //
        },
        props: {
            value: {type: [String, Number]},
            placeholder: {type: String},
            readonly: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            hasError: {type: Boolean, default: false},
            autofocus: {type: Boolean, default: false},
            type: {type: String, default: 'text'},
            variant: {type: String, default: 'border'},
            size: {type: String, default: 'default'},
            rounded: {type: String, default: 'both'},
            align: {type: String, default: 'left'},
            preventBrowserStyling: {type: Boolean, default: false},
            isNullable: {type: Boolean, default: false},
        },
        setup(props, context) {
            const inputEl = ref<HTMLElement | null>(null);

            onMounted(() => {
                if (props.autofocus) {
                    nextTick(() => {
                        inputEl.value?.focus();
                    });
                }
            });

            const listeners = function (l) {
                return Object.assign({}, l, {
                    input(event) {
                        if (props.isNullable && event.target.value === '') {
                            context.emit('input', null);

                            return;
                        }

                        context.emit(
                            'input',
                            props.type === 'number' ? Number(event.target.value) : event.target.value
                        );
                    },
                });
            };

            const styling = useStyling(props);

            return {
                inputEl,
                listeners,
                styling,
            };
        },
    });

    function useStyling(props) {
        return computed(() => {
            const output = [] as any[];

            const variants = {
                'border': useBorderVariant(props.readonly, props.hasError, props.size, props.rounded),
                'underline': useUnderlineVariant(props.readonly, props.hasError),
                'underline-hover': useUnderlineHoverVariant(props.readonly, props.hasError),
            };
            output.push(variants[props.variant]);

            const sizes = {
                default: 'CInput--base',
                lg: 'CInput--lg',
                sm: 'CInput--sm',
                xs: 'CInput--xs',
            };
            output.push(sizes[props.size]);

            const alignments = {
                left: 'tw-text-left',
                center: 'tw-text-center',
                right: 'tw-text-right',
            };
            output.push(alignments[props.align]);

            if (props.preventBrowserStyling) {
                output.push('CInput--preventBrowserStyling');
            }

            return output;
        });
    }

    function useBorderVariant(readonly: boolean, hasError: boolean, size: string, rounded: string) {
        const output = [] as any[];

        const states = {
            default: 'CInput--light',
            error: 'CInput--light CInput--has-error',
            readonly: 'CInput--light CInput--is-readonly',
        };

        output.push(selectState(states, readonly, hasError));

        switch (rounded) {
            case 'left':
                output.push('!tw-rounded-r-none');
                break;
            case 'right':
                output.push('!tw-rounded-l-none');
                break;
            case 'none':
                output.push('!tw-rounded-none');
                break;
        }

        return output;
    }

    function useUnderlineVariant(readonly: boolean, hasError: boolean): string {
        const states = {
            default:
                'tw-bg-transparent tw-border-gray-200 tw-border-b tw-border-dashed focus:tw-border-solid focus:tw-border-blue-500 tw-outline-0',
            error: 'tw-bg-transparent tw-border-red-500 tw-border-b focus:tw-border-red-500 tw-z-10',
            readonly: 'tw-bg-transparent tw-border-gray-200 tw-bg-gray-50 tw-border-b tw-opacity-75',
        };

        return selectState(states, readonly, hasError);
    }

    function useUnderlineHoverVariant(readonly: boolean, hasError: boolean): string {
        const states = {
            default:
                'tw-bg-transparent tw-border-transparent hover:tw-border-gray-200 tw-border-b focus:tw-border-blue-500',
            error: 'tw-bg-transparent tw-border-red-500 tw-border-b focus:tw-border-red-500 tw-z-10',
            readonly: 'tw-bg-transparent tw-border-gray-200 tw-bg-gray-50 tw-border-b tw-opacity-75',
        };

        return selectState(states, readonly, hasError);
    }

    function selectState(
        states: {
            default: any;
            readonly: any;
            error: any;
        },
        readonly: boolean,
        hasError: boolean
    ): any {
        if (readonly) {
            return states['readonly'];
        }

        return states[hasError ? 'error' : 'default'];
    }
</script>
