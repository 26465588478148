import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import {SaveAllocationAggregateResponseModel} from '@/modules/cashier/payment/application/SaveAllocationAggregateResponseModel';
import type {SaveAllocationAggregateUseCase} from '@/modules/cashier/payment/application/SaveAllocationAggregateUseCase';
import type {AllocationAggregatePort} from '@/modules/cashier/payment/application/ports/AllocationAggregatePort';

export class SaveAllocationAggregateInteractor implements SaveAllocationAggregateUseCase {
    private readonly _allocationAggregateRepository: AllocationAggregatePort;

    constructor(allocationAggregateRepository: AllocationAggregatePort) {
        this._allocationAggregateRepository = allocationAggregateRepository;
    }

    public async saveAll(
        allocationAggregates: AbstractAllocationAggregate[]
    ): Promise<SaveAllocationAggregateResponseModel> {
        const results = await this._allocationAggregateRepository.saveAll(allocationAggregates);

        return new SaveAllocationAggregateResponseModel(results);
    }

    public async save(allocationAggregate: AbstractAllocationAggregate): Promise<SaveAllocationAggregateResponseModel> {
        const result = await this._allocationAggregateRepository.save(allocationAggregate);

        return new SaveAllocationAggregateResponseModel([result]);
    }
}
