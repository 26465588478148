import type EventModel from '@/modules/human-resources/models/EventModel';
import moment from 'moment';
import SplittableEvent from '@/modules/human-resources/utils/calendar/SplittableEvent';
import _cloneDeep from 'lodash-es/cloneDeep';
import DateValue from '@/modules/app/utils/Values/DateValue';
import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';

export default class SplittableEventBuilder {
    public static fromEvent(event: EventModel): SplittableEvent | SplittableEvent[] {
        if (event.isFullDay && event.startedAtRaw !== event.endedAtRaw) {
            return this.splitMultiDaysEvent(event);
        }

        const endDate = event.isFullDay ? moment.utc(event.endedAtRaw).endOf('day') : moment.utc(event.endedAtRaw);

        return new SplittableEvent(moment.utc(event.startedAtRaw).unix(), endDate.unix(), [event], event.isFullDay);
    }

    public static fromTemplateEvent(event: TemplateEventModel): SplittableEvent {
        return new SplittableEvent(moment.utc(event.startedAt).unix(), moment.utc(event.endedAt).unix(), [event]);
    }

    private static splitMultiDaysEvent(event: EventModel): SplittableEvent[] {
        const severalDaysEvent: EventModel = _cloneDeep(event);
        const splitEvents: SplittableEvent[] = [];

        // Split multi days event on array of daily events
        while (severalDaysEvent.startedAtRaw !== severalDaysEvent.endedAtRaw) {
            // Secure the infinity loop
            if (
                (severalDaysEvent.startedAtRaw && !DateValue.validate(severalDaysEvent.startedAtRaw)) ||
                (severalDaysEvent.endedAtRaw && !DateValue.validate(severalDaysEvent.endedAtRaw))
            ) {
                break;
            }

            splitEvents.push(
                new SplittableEvent(
                    moment.utc(severalDaysEvent.startedAtRaw).unix(),
                    moment.utc(severalDaysEvent.startedAtRaw).endOf('day').unix(),
                    [event],
                    severalDaysEvent.isFullDay
                )
            );

            severalDaysEvent.attributes.date_event = {
                started_at: moment(severalDaysEvent.startedAtRaw).add(1, 'days').format('YYYY-MM-DD'),
                ended_at: severalDaysEvent.attributes.date_event?.ended_at,
            };
        }

        splitEvents.push(
            new SplittableEvent(
                moment.utc(severalDaysEvent.startedAtRaw).unix(),
                moment.utc(severalDaysEvent.startedAtRaw).endOf('day').unix(),
                [event],
                severalDaysEvent.isFullDay
            )
        );

        return splitEvents;
    }
}
