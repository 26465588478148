import type ContractModel from '@/modules/human-resources/models/ContractModel';
import {isNil} from 'lodash-es';
import {Epoch} from '@meekohq/lumos';
import {BaseRule} from '@/modules/app/utils/rules/BaseRule';

export class ContractEndedAtAfterStartedAtRule extends BaseRule {
    isValid(contract: ContractModel): boolean {
        if (isNil(contract.attributes.started_at) || isNil(contract.attributes.ended_at)) {
            return true;
        }

        return Epoch.parse(contract.attributes.ended_at, 'yyyy-MM-dd').greaterThanOrEqualTo(
            Epoch.parse(contract.attributes.started_at, 'yyyy-MM-dd')
        );
    }
}
