import {Epoch} from '@meekohq/lumos';
import type {UseMCalendar} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
import type {DateTimePeriod} from '@/modules/meeko-ui/types/DateTime';

export default function useMCalendarUtils() {
    function cloneUserValue(userValue: UseMCalendar): DateTimePeriod {
        if (userValue.isPeriod) {
            return {
                from: userValue.value.value.from ? Epoch.fromJSDate(userValue.value.value.from.toJSDate()) : undefined,
                to: userValue.value.value.to ? Epoch.fromJSDate(userValue.value.value.to.toJSDate()) : undefined,
            };
        }

        return {
            from: userValue.value.value ? Epoch.fromJSDate(userValue.value.value.toJSDate()) : undefined,
            to: undefined,
        };
    }

    function isUserValueEqualsToInternalValue(userValue: UseMCalendar, internalValue: DateTimePeriod): boolean {
        if (userValue.isPeriod) {
            return (
                userValue.value.value.from?.toTimestamp() === internalValue.from?.toTimestamp() &&
                userValue.value.value.to?.toTimestamp() === internalValue.to?.toTimestamp()
            );
        }

        return userValue.value.value?.toTimestamp() === internalValue.from?.toTimestamp();
    }

    return {
        cloneUserValue,
        isUserValueEqualsToInternalValue,
    };
}
