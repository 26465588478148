<template>
    <CHStack
        align="center"
        distribute="center"
        gap="4"
    >
        <template v-if="replacingEvent">
            <EventCard
                class="tw-w-56 tw-opacity-60"
                :event="event"
                :staff="staff"
            />
            <CCenter>
                <FontAwesomeIcon
                    class="tw-opacity-50"
                    icon="fa-solid fa-arrow-right"
                />
            </CCenter>
            <EventCard
                class="tw-w-56"
                :event="replacingEvent"
                :staff="staff"
            />
        </template>
        <template v-else-if="isPendingDelete">
            <EventCard
                class="tw-w-56"
                :event="event"
                :staff="staff"
            />
            <CCenter>
                <FontAwesomeIcon
                    class="tw-opacity-50"
                    icon="fa-solid fa-arrow-right"
                />
            </CCenter>
            <CCenter class="tw-h-14 tw-w-14 tw-rounded-full tw-bg-white tw-text-2xl tw-text-red-500 tw-shadow-md">
                <FontAwesomeIcon icon="fa-solid fa-trash" />
            </CCenter>
        </template>
        <template v-else>
            <EventCard
                class="tw-w-56"
                :event="event"
                :staff="staff"
            />
            <CCenter>
                <FontAwesomeIcon
                    class="tw-opacity-50"
                    icon="fa-solid fa-arrow-right"
                />
            </CCenter>
            <CCenter class="tw-h-14 tw-w-14 tw-rounded-full tw-bg-white tw-text-2xl tw-text-green-500 tw-shadow-md">
                <FontAwesomeIcon icon="fa-solid fa-calendar-plus" />
            </CCenter>
        </template>
    </CHStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import type {Collection} from '@meekohq/lumos';
    import useManager from '@/modules/app/composables/useManager';
    import EventCard from '@/modules/request/components/Resources/Types/Event/EventCard.vue';
    import {EventStatus} from '@/modules/human-resources/utils/calendar/Values/EventStatus';

    export default defineComponent({
        components: {EventCard},
        props: {
            events: {
                type: Object as PropType<Collection<EventModel>>,
                required: true,
            },
        },
        setup(props) {
            const {activeOrganization} = useManager();

            const event = computed(() => {
                if (props.events.count() === 2) {
                    return props.events.first(e => e.attributes.replaced_by_event_id !== null);
                }

                return props.events.first();
            });

            const replacingEvent = computed(() => {
                return props.events.reject(e => e.getKey() === event.value.getKey()).first();
            });

            const isPendingDelete = computed(() => {
                return event.value.attributes.status === EventStatus.pendingDelete;
            });

            const staff = computed(() => {
                if (props.events.isNotEmpty()) {
                    return props.events.first().staff().value();
                }

                return null;
            });

            return {
                staff,
                event,
                replacingEvent,
                activeOrganization,
                isPendingDelete,
            };
        },
    });
</script>
