import moment from 'moment';
import _clone from 'lodash-es/clone';
import {Epoch} from '@meekohq/lumos';
import _cloneDeep from 'lodash-es/cloneDeep';

const dayMapping = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export function getReferenceWeekToUse(startedAt, recurrentWeeks, date) {
    if (recurrentWeeks) {
        if (Number.isInteger(getWeekNbrSinceContract(startedAt, date) / recurrentWeeks)) {
            return recurrentWeeks;
        }

        let i = 1;
        while (!Number.isInteger((getWeekNbrSinceContract(startedAt, date) + i) / recurrentWeeks)) {
            i++;
        }

        return recurrentWeeks - i;
    }

    return undefined;
}

export function getWeekNbrSinceContract(startedAt, date) {
    return date.diff(moment(startedAt, 'YYYY-MM-DD').startOf('week'), 'week') + 1;
}

/**
 * Récupère le contrat actif sur le jour demandé.
 * Il est possible de prendre en compte ou non les contrats brouillons.
 *
 * @param {[]} contracts
 * @param {Moment} day
 * @param {boolean} withDraft
 */
export function getKidContractOfTheDay(contracts, day, withDraft = false) {
    const startOfDay = day.clone().startOf('day').unix();
    const endOfDay = day.clone().endOf('day').unix();

    return contracts.find(contractt => {
        const contract = _clone(contractt);

        if (contract.draft && !withDraft) {
            return false;
        }

        // Si le contrat est rompu, on utilise la date de rupture pour la fin du contrat
        if ((contract.ended_at === null && contract.broked_at !== null) || contract.broked_at < contract.ended_at) {
            contract.ended_at = contract.broked_at;
        }

        const contractStartedAt = moment(contract.started_at, 'YYYY-MM-DD').startOf('day').unix();
        const contractEndedAt = moment(contract.ended_at, 'YYYY-MM-DD').endOf('day').unix();

        return (
            (contractStartedAt <= startOfDay && contractEndedAt >= endOfDay) ||
            (contractStartedAt <= startOfDay && contractEndedAt >= startOfDay && contractEndedAt <= endOfDay) ||
            (contractStartedAt >= startOfDay && contractEndedAt <= endOfDay && contractEndedAt >= endOfDay)
        );
    });
}

/**
 * Récupère les plannings d'un enfant sur un jour en fonction de ses contrats.
 * Il est possible de prendre en compte ou non les contrats brouillons
 *
 * @param {[]} contracts
 * @param {Moment} day
 * @param {boolean} withDraft
 * @param extra
 */
export function getPlanningEventsFromKidContracts(contracts, day, withDraft = false, extra) {
    const contract = getKidContractOfTheDay(contracts, day, withDraft);
    if (contract) {
        return getPlanningEventsFromKidContractOrRegistration(day, contract, undefined, extra);
    }

    return [];
}

/**
 * Récupère les plannings d'un contrat ou d'une pré-inscription sur un jour.
 *
 * @param {Moment} day
 * @param {Object} contract
 * @param {Object} registration
 * @param {Object} extra
 */
export function getPlanningEventsFromKidContractOrRegistration(day, contract, registration, extra) {
    const plannings: any[] = [];
    const momentDay = moment(day);

    // Generate draft contrat if registration is given
    // Else use the given contract
    if (registration && !contract) {
        contract = _clone(registration);
        contract.recurrent_weeks = _clone(registration.contract_recurrent_weeks);
        contract.started_at = _clone(registration.contract_started_at);
    }

    const weekNumberSinceContract = getWeekNbrSinceContract(contract.started_at, momentDay);
    const referenceWeekToUse = getReferenceWeekToUse(contract.started_at, contract.recurrent_weeks, momentDay);

    contract.plannings
        .filter(planning => {
            if (planning.day !== dayMapping[momentDay.day()]) {
                return false;
            }

            return (
                (planning.week === referenceWeekToUse && planning.type === 'recurrent') ||
                (planning.week === weekNumberSinceContract && ['adaptation', 'occasional'].includes(planning.type))
            );
        })
        .forEach(planning => {
            const actual = momentDay.format('YYYY-MM-DD');
            const newPlanning = _clone(planning);

            newPlanning.event_type = 'planning-' + planning.type;
            newPlanning.nursery_id = contract.nursery_id;
            newPlanning.started_at = moment(actual + ' ' + planning.start_time, 'YYYY-MM-DD HH:mm:ss').unix();
            newPlanning.ended_at = moment(actual + ' ' + planning.end_time, 'YYYY-MM-DD HH:mm:ss').unix();

            // Add extra data in planning if needed for diverses reasons
            // Ex : onlyHours to display only hours in planning segment and not full date
            if (extra) {
                newPlanning.extra = extra;
            }

            plannings.push(newPlanning);
        });

    // If we have adaptation plannings, we only return adaptation and occasional plannings
    if (plannings.length > 1 && plannings.filter(planning => planning.type === 'adaptation').length > 0) {
        return plannings.filter(planning => planning.type === 'adaptation' || planning.type === 'occasional');
    }

    return plannings;
}

/**
 * Transforme des pointages en évènement affichable.
 *
 * Transformation 1 : une présence de 6 h à 18 h sur un planning de 5 h à 17 h sera affiché uniquement de 5 h à 17 h.
 * Car les dépassements sont affichés différements.
 *
 * Transformation 2 : Si le pointage commence à 6 h et qu'il n'a pas encore de pointage de fin
 * alors nous affichons l'évènement jusqu'à l'heure actuelle.
 *
 * @param {[]} presences
 * @param {[]} plannings
 */
export function getPresenceEvents(presences, plannings) {
    return _cloneDeep(presences).map(presence => {
        // Si le pointage n'est pas terminé, nous l'affichons jusqu'à now.
        if (presence.ended_at === null) {
            presence.ended_at = moment().unix();
        }

        // On récupère le planning correspondant
        const planning = plannings.find(planning => {
            // Because of the way we store recurrent planning, we need to check the planning id and the date
            // Indeed, we can have several week generated from the same planning
            return (
                planning.id === presence.planning_id &&
                Epoch.fromTimestamp(planning.started_at).differenceInDays(Epoch.fromTimestamp(presence.started_at)) <= 1
            );
        });

        presence.type = 'presence';
        presence.event_type = 'presence-recurrent';

        if (planning) {
            if (presence.ended_at > planning.ended_at) {
                presence.limited_ended_at = planning.ended_at;
            }

            if (presence.started_at < planning.started_at) {
                presence.limited_started_at = planning.started_at;
            }

            // Set fake presence type according to real planning type
            switch (planning.type) {
                case 'recurrent':
                    presence.event_type = 'presence-recurrent';
                    break;
                case 'occasional':
                    presence.event_type = 'presence-occasional';
                    break;
                case 'adaptation':
                    presence.event_type = 'presence-adaptation';
                    break;
            }
        }

        return presence;
    });
}

/**
 * Transforme des dépassements de planning en évènement affichable.
 *
 * On regarde pour chaque pointage, s'il dépasse de son planning de base.
 * S'il dépasse à l'arrivé alors nous créeons un évènement avant le pointage pour afficher le dépassement.
 * S'il dépasse à au départ nous créeons un autre évènement après le pointage pour afficher le dépassement.
 *
 * @param {[]} presences
 * @param {[]} plannings
 */
export function getOverrunEvents(presences, plannings) {
    const overruns: any[] = [];

    presences.forEach(presence => {
        // @ts-ignore
        const planning = plannings.find(
            planning =>
                planning.id === presence.planning_id &&
                moment.unix(presence.started_at).isSame(moment.unix(planning.started_at), 'week', '[]')
        );

        if (planning && presence.started_at < planning.started_at) {
            const startOverrun = _clone(presence);
            startOverrun.type = 'overrun';
            startOverrun.event_type = 'overrun';
            startOverrun.started_at = presence.started_at;
            startOverrun.ended_at = planning.started_at;
            startOverrun.limited_started_at = null;
            startOverrun.limited_ended_at = null;
            startOverrun.presence_started_at = presence.started_at;
            startOverrun.presence_ended_at = presence.ended_at;

            overruns.push(startOverrun);
        }

        if (planning && presence.ended_at > planning.ended_at) {
            const endOverrun = _clone(presence);
            endOverrun.type = 'overrun';
            endOverrun.event_type = 'overrun';
            endOverrun.started_at = planning.ended_at;
            endOverrun.ended_at = presence.ended_at;
            endOverrun.limited_started_at = null;
            endOverrun.limited_ended_at = null;
            endOverrun.presence_started_at = presence.started_at;
            endOverrun.presence_ended_at = presence.ended_at;

            overruns.push(endOverrun);
        }
    });

    return overruns;
}
