<script setup lang="ts">
    import {ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import useAuth from '@/modules/app/composables/useAuth';

    const props = withDefaults(
        defineProps<{
            event: EventModel;
            size: 'sm' | 'md' | 'lg';
        }>(),
        {
            size: 'lg',
        }
    );

    const {legacyUser} = useAuth();

    function getEventType(event: EventModel) {
        return event.eventType().value();
    }

    function getEventStyleAndClass(event: EventModel, size = props.size) {
        const color = getEventType(event).attributes.color || '';
        const forecast = event.attributes.forecast || false;

        return useEventStyle(ref(color), ref(forecast), size, false, ref(false));
    }

    function getIconClass(event: EventModel) {
        return getEventStyleAndClass(event, 'lg').iconClass.value;
    }

    function getIconStyle(event: EventModel) {
        return getEventStyleAndClass(event, 'lg').iconStyle.value;
    }

    function getOrganizationName(event: EventModel) {
        let text = __('common:other_organization'); // In case user doesn't have credentials in other organizations.
        if (legacyUser.value) {
            const nursery = legacyUser.value.nurseries.find(
                (item: {id: any}) => `${item.id}` === `${event.attributes.organization_id}`
            );
            if (nursery) {
                text = nursery.city ? `${nursery.name} - ${nursery.city}` : nursery.name;
            }
        }

        return text;
    }
</script>

<template>
    <div class="SegmentFullDayListItem">
        <div
            v-if="getEventType(event).attributes.icon_id"
            class="SegmentFullDayListItem__icon-container"
            :class="getIconClass(event)"
            :style="getIconStyle(event)"
        >
            <CIcon
                :path="getEventType(event).icon.attributes.path"
                :provider="getEventType(event).icon.attributes.provider"
            />
        </div>
        <div>
            <div class="SegmentFullDayListItem__title-container">
                <FontAwesomeIcon
                    v-if="event.isPending"
                    class="SegmentFullDayListItem__pending-icon"
                    icon="fad fa-hourglass-half"
                    size="sm"
                />
                <MHeading level="h4">
                    {{
                        event.attributes.note
                            ? getEventType(event).attributes.name + ' • ' + event.attributes.note
                            : getEventType(event).attributes.name
                    }}
                </MHeading>
            </div>

            <div
                v-if="getOrganizationName(event) || event.kidsGroup().value()"
                class="SegmentFullDayListItem__organization"
            >
                <template v-if="getOrganizationName(event) && event.kidsGroup().value()">
                    {{ getOrganizationName(event) }} • {{ event.kidsGroup().value().attributes.name }}
                </template>
                <template v-else-if="getOrganizationName(event)">
                    {{ getOrganizationName(event) }}
                </template>
                <template v-else-if="event.kidsGroup().value()">
                    {{ event.kidsGroup().value().attributes.name }}
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .SegmentFullDayListItem {
        @apply tw-flex tw-items-center tw-gap-3;
        @apply tw-px-3 tw-py-2;
        @apply tw-cursor-pointer tw-rounded-lg hover:tw-bg-gray-100;
    }

    .SegmentFullDayListItem__icon-container {
        @apply tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-shadow;
    }

    .SegmentFullDayListItem__title-container {
        @apply tw-flex tw-items-center tw-gap-2;
    }

    .SegmentFullDayListItem__pending-icon {
        @apply tw-text-gray-500;
    }

    .SegmentFullDayListItem__organization {
        @apply tw-text-sm tw-text-gray-500;
    }
</style>
