import type {DirectiveBinding} from 'vue';
import Vue from 'vue';

Vue.directive('float', {
    bind(el, binding) {
        const element = el instanceof HTMLInputElement ? el : el.querySelector('input');

        if (element) {
            return new FloatDirective(element, binding);
        }
    },
});

class FloatDirective {
    protected input: HTMLInputElement;
    protected binding: DirectiveBinding<any>;

    constructor(input, binding) {
        this.input = input;
        this.binding = binding;

        input.addEventListener('change', this.change);
    }

    public static formatDecimal(value) {
        // Replace ',' by '.'
        value = value.replace(/[,]/g, '.');

        // Naive adjustment for decimal values, breaks the number by "." and considers the last group as the decimal part
        const pieces = value.split(/[.]/);

        // Removes useless zeroes on the right
        const decimal = pieces.pop().replace(/0+$/, '');
        if (pieces.length) {
            value = (pieces.join('') || (decimal ? '0' : '')) + (decimal ? '.' + decimal : '');
        }

        return value;
    }

    change({target}) {
        let value = target.value;
        if (!value) {
            return;
        }

        // Check if is negative and a decimal
        const isNegative = /^\s*-/.test(value) && !this.binding.modifiers.unsigned;
        const isDecimal = /[.,]/.test(value);

        // Remove invalid digits (if it's a decimal, then allows "," and "." to stay)
        value = value.replace(isDecimal ? /[^\d,.]/g : /\D/g, '');

        if (isDecimal) {
            value = FloatDirective.formatDecimal(value);
        }

        // Removes useless zeroes on the left
        value = value.replace(/^(?:0(?!\b))+/, '');
        if (value && isNegative) {
            value = `-${value}`;
        }

        if (target.value !== value) {
            target.value = value;
        }
    }
}
