<script setup lang="ts">
    import {computed, onMounted, onUnmounted, ref} from 'vue';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import useAbility from '@/modules/app/composables/useAbility';
    import SegmentFullDayList from '@/modules/human-resources/components/calendar/SegmentFullDayList.vue';
    import {EventBus} from '@/modules/legacy/utils/bus';

    const props = withDefaults(
        defineProps<{
            events: EventModel[];
            size: 'sm' | 'md' | 'lg';
        }>(),
        {
            size: 'lg',
        }
    );

    const segmentFullDayPopover = ref();

    const {can} = useAbility();

    const segmentClass = computed(() => {
        return props.size === 'lg' ? 'tw-w-6' : 'tw-w-4';
    });

    onMounted(() => {
        EventBus.$on('calendar:staffs:closeFullDayPopover', () => {
            segmentFullDayPopover.value?.closePopover();
        });
    });

    onUnmounted(() => {
        EventBus.$off('calendar:staffs:closeFullDayPopover');
    });

    function getEventType(event: EventModel) {
        return event.eventType().value();
    }

    function getEventStyleAndClass(event: EventModel, size = props.size) {
        const color = getEventType(event).attributes.color || '';
        const forecast = event.attributes.forecast || false;

        return useEventStyle(ref(color), ref(forecast), size, false, ref(false));
    }

    function getEventClass(event: EventModel, index: number) {
        let eventClass = getEventStyleAndClass(event).fullDayStickClass.value;
        if (props.events.length > 2 && index > 0) {
            eventClass += props.size === 'lg' ? ' tw--ml-2' : ' tw--ml-1';
        }

        return eventClass;
    }

    function getEventStyle(event: EventModel) {
        return getEventStyleAndClass(event).fullDayStickStyle.value;
    }
</script>

<template>
    <MPopover
        ref="segmentFullDayPopover"
        class="SegmentFullDay"
        :class="segmentClass"
        placement="right"
        popover-button-class="tw-w-full"
        :style="size === 'lg' ? 'margin-left: -22px;' : 'margin-left: -16px;'"
        trigger="hover"
    >
        <div class="segmentFullDay__container">
            <div
                v-for="(event, i) in events"
                :key="'staffSegmentFullDay' + event.id"
                :class="getEventClass(event, i)"
                :style="getEventStyle(event)"
            />
        </div>
        <template #content>
            <div class="segmentFullDay__popover">
                <MHeading level="h4">
                    {{ __('hr_calendar:whole_day_long_colon') }}
                </MHeading>
                <SegmentFullDayList
                    :events="events"
                    :size="size"
                />
                <MButton
                    v-if="can('create', 'staffs_planning')"
                    class="segmentFullDay__button"
                    icon-plus
                    size="sm"
                    variant="primary"
                    @click="$emit('add')"
                >
                    {{ __('hr_calendar:add_event') }}
                </MButton>
            </div>
        </template>
    </MPopover>
</template>

<style scoped>
    .SegmentFullDay {
        @apply tw-absolute;
        @apply tw-bg-gray-200 hover:tw-bg-gray-300;
        @apply tw-h-full tw-rounded-l-md;
        @apply tw-cursor-pointer;
    }

    .segmentFullDay__container {
        @apply tw-flex tw-items-center tw-justify-evenly;
        @apply tw-h-full tw-w-full;
    }

    .segmentFullDay__popover {
        @apply tw-p-2;
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .segmentFullDay__button {
        @apply tw-mt-2 tw-w-full;
    }
</style>
