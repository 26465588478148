import {computed, type Ref, ref} from 'vue';
import {app} from '@meekohq/lumos';
import __ from '@/modules/app/utils/i18n-facade';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import Bugsnag from '@bugsnag/js';

import useNotification from '@/modules/meeko-ui/composables/useNotification';

import {ReportingType} from '@/modules/meeko-family/domain/values/ReportingType';
import {type ReportingSettingEntity} from '@/modules/meeko-family/domain/entities/ReportingSettingEntity';
import {GetReportingSettingsInputPortBinding} from '@/modules/meeko-family/domain/use-cases/GetReportingSettingsInputPort';

export function useReportingSettingsState() {
    const isFetching = ref(false);
    const {error: notifyError} = useNotification();

    const reportingSettings: Ref<ReportingSettingEntity[]> = ref([]);
    const originalSettings: Ref<ReportingSettingEntity[]> = ref([]);
    const updated = computed(() => !_isEqual(originalSettings.value, reportingSettings.value));

    async function fetch(organizationId: string) {
        isFetching.value = true;

        const interactor = app(GetReportingSettingsInputPortBinding, {
            success(settings: ReportingSettingEntity[]) {
                reportingSettings.value = sortSettings(settings);
            },
            error(error: Error) {
                notifyError(__('common:errors.generic'));
                Bugsnag.notify(error);
            },
        });

        await interactor.execute(organizationId);

        resetOriginalSettings();

        isFetching.value = false;
    }

    function sortSettings(settings: ReportingSettingEntity[]): ReportingSettingEntity[] {
        const order = [
            ReportingType.presences,
            ReportingType.meals,
            ReportingType.activities,
            ReportingType.naps,
            ReportingType.naps,
            ReportingType.diapers,
            ReportingType.photos,
            ReportingType.temperatures,
            ReportingType.weights,
            ReportingType.drugs,
            ReportingType.observations,
        ];

        return _cloneDeep(settings).sort((a, b) => {
            return order.indexOf(a.type) - order.indexOf(b.type);
        });
    }

    function setSettingByType(type: string, newSetting: ReportingSettingEntity) {
        const index = reportingSettings.value.findIndex(setting => setting.type === type);
        if (index !== -1) {
            Object.assign(reportingSettings.value[index], newSetting);
        }
    }

    function resetOriginalSettings() {
        originalSettings.value = _cloneDeep(reportingSettings.value);
    }

    return {
        originalSettings,
        setSettingByType,
        reportingSettings,
        isFetching,
        fetch,
        resetOriginalSettings,
        updated,
    };
}
