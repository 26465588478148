<template>
    <component
        :is="as"
        :class="baseClass"
        v-on="$listeners"
    >
        <slot />
    </component>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type {ResponsivePropType} from '@/modules/coherence-ui/hooks/useResponsiveProp';
    import useResponsiveProps, {containerSizeProp} from '@/modules/coherence-ui/hooks/useResponsiveProps';
    import {isNumber} from 'lodash-es';

    type Gap = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';
    type Direction = 'col' | 'row';
    type Distribute = 'none' | 'around' | 'between' | 'evenly' | 'start' | 'center' | 'end';
    type Align = 'none' | 'start' | 'center' | 'end' | 'baseline' | 'stretch';

    export default defineComponent({
        props: {
            as: {type: String, default: 'div'},
            containerSize: containerSizeProp(),
            direction: {type: [String, Object, Array] as PropType<ResponsivePropType<Direction>>, default: 'row'},
            distribute: {type: [String, Object, Array] as PropType<ResponsivePropType<Distribute>>, default: 'none'},
            align: {type: [String, Object, Array] as PropType<ResponsivePropType<Align>>, default: 'none'},
            gap: {type: [String, Number, Object, Array] as PropType<ResponsivePropType<Gap>>, default: '0'},
            reverse: {type: [Boolean, Object, Array] as PropType<ResponsivePropType<boolean>>, default: false},
            wrap: {type: [Boolean, Object, Array] as PropType<ResponsivePropType<boolean>>, default: false},
        },
        setup(props) {
            const {responsiveProps} = useResponsiveProps(
                props,
                ['direction', 'distribute', 'align', 'gap', 'reverse', 'wrap'],
                'containerSize'
            );

            const isRow = computed(() => {
                return ((responsiveProps.value.direction as string) || 'row') === 'row';
            });

            const baseClass = computed(() => {
                const output = [] as any[];

                output.push(isRow.value ? 'CHStack' : 'CVStack');

                if (responsiveProps.value.reverse as boolean) {
                    output.push(isRow.value ? 'CHStack--reverse' : 'CVStack--reverse');
                }

                if (responsiveProps.value.wrap as boolean) {
                    output.push(isRow.value ? 'CHStack--wrap' : 'CVStack--wrap');
                }

                const distributeValuesArray = isRow.value ? rowDistributeValues : colDistributeValues;
                output.push(distributeValuesArray[(responsiveProps.value.distribute as string) || 'none']);

                const alignValuesArray = isRow.value ? rowAlignValues : colAlignValues;
                output.push(alignValuesArray[(responsiveProps.value.align as string) || 'stretch']);

                const gapValue = (responsiveProps.value.gap as string | number) || 0;
                const gapAsNumber = isNumber(gapValue) ? gapValue : parseInt(gapValue);
                const gapValuesArray = isRow.value ? rowGapValues : colGapValues;
                output.push(gapValuesArray[gapAsNumber]);

                return output;
            });

            return {
                baseClass,
            };
        },
    });

    export const rowDistributeValues = {
        none: '',
        around: 'CHStack--distribute-around',
        between: 'CHStack--distribute-between',
        evenly: 'CHStack--distribute-evenly',
        start: 'CHStack--distribute-start',
        center: 'CHStack--distribute-center',
        end: 'CHStack--distribute-end',
    };

    export const rowAlignValues = {
        none: '',
        stretch: 'CHStack--align-stretch',
        baseline: 'CHStack--align-baseline',
        start: 'CHStack--align-start',
        center: 'CHStack--align-center',
        end: 'CHStack--align-end',
    };

    export const rowGapValues = {
        0: '',
        1: 'CHStack--gap-1',
        2: 'CHStack--gap-2',
        3: 'CHStack--gap-3',
        4: 'CHStack--gap-4',
        5: 'CHStack--gap-5',
        6: 'CHStack--gap-6',
        7: 'CHStack--gap-7',
        8: 'CHStack--gap-8',
    };

    export const colDistributeValues = {
        none: '',
        around: 'CVStack--distribute-around',
        between: 'CVStack--distribute-between',
        evenly: 'CVStack--distribute-evenly',
        start: 'CVStack--distribute-start',
        center: 'CVStack--distribute-center',
        end: 'CVStack--distribute-end',
    };

    export const colAlignValues = {
        stretch: 'CVStack--align-stretch',
        baseline: 'CVStack--align-baseline',
        start: 'CVStack--align-start',
        center: 'CVStack--align-center',
        end: 'CVStack--align-end',
    };

    export const colGapValues = {
        0: '',
        1: 'CVStack--gap-1',
        2: 'CVStack--gap-2',
        3: 'CVStack--gap-3',
        4: 'CVStack--gap-4',
        5: 'CVStack--gap-5',
        6: 'CVStack--gap-6',
        7: 'CVStack--gap-7',
        8: 'CVStack--gap-8',
        9: 'CVStack--gap-9',
        10: 'CVStack--gap-10',
    };
</script>
