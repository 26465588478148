<script setup lang="ts">
    import {computed, watch} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useManager from '@/modules/app/composables/useManager';
    import {useClipboard} from '@vueuse/core';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    const props = defineProps({
        website: {
            type: Object,
            required: true,
        },
    });

    const {hasManyOrganizations} = useManager();

    const personalWebsiteIframe = computed(() => {
        let iframeCode = '';

        if (hasManyOrganizations.value) {
            iframeCode = `<iframe src="${props.website.slug}.meeko.site/iframe/registration/none" style="margin: 0 auto; width: 100%;" height="2500" frameborder="0" marginheight="0" marginwidth="0"></iframe>`;
        } else {
            iframeCode = `<iframe src="${props.website.slug}.meeko.site/iframe/registration/one" style="margin: 0 auto; width: 100%;" height="2500" frameborder="0" marginheight="0" marginwidth="0"></iframe>`;
        }

        return iframeCode;
    });

    const {copied, copy} = useClipboard({source: personalWebsiteIframe});

    watch(copied, value => {
        if (value) {
            useNotification().success(__('personal_website:registration_form_copied_to_clipboard'));
        }
    });
</script>

<template>
    <div class="PersonalWebsiteIframe">
        <MHeading>{{ __('personal_website:registration_form_link') }}</MHeading>
        <div class="PersonalWebsiteIframe__subtitle">
            {{ __('personal_website:integrate_form_on_your_own_site') }}
        </div>
        <div class="PersonalWebsiteIframe__container">
            <MInput
                class="PersonalWebsiteIframe__input"
                :placeholder="__('personal_website:my_dash_organization')"
                readonly
                type="url"
                :value="personalWebsiteIframe"
            />
            <MButton @click="copy(personalWebsiteIframe)">
                <FontAwesomeIcon
                    class="tw-text-primary-500"
                    fixed-width
                    icon="fa-solid fa-copy"
                />
            </MButton>
        </div>
    </div>
</template>

<style scoped>
    .PersonalWebsiteIframe__subtitle {
        @apply tw-mt-1 tw-text-gray-500;
    }

    .PersonalWebsiteIframe__container {
        @apply tw-mt-4 tw-flex tw-gap-2;
    }

    .PersonalWebsiteIframe__input {
        @apply md:tw-max-w-80;
    }
</style>
