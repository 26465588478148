<template>
    <CPopover
        ref="dropdownRef"
        :class="buttonClass"
        :clickable="false"
        :content-class="['tw-min-w-56']"
        no-padding
        @shown="onShown"
    >
        <slot
            name="trigger"
            :toggle="toggle"
            :value="value"
        >
            <MButton
                align="left"
                :class="buttonClass"
                :disabled="disabled"
                :icon-sort="true"
                :variant="buttonVariant"
                @click="toggle"
            >
                <slot
                    name="button"
                    :value="value"
                />
            </MButton>
        </slot>
        <template #content>
            <CFinder
                ref="finder"
                :has-more-result="hasMore && !loading"
                :has-unselect="hasUnselect"
                :is-loading="loading"
                :multi="multi"
                :options="models"
                ref-path="id"
                :search-bar="true"
                :unselect-value="unselectValue"
                :value="value"
                @fallback="$emit('fallback')"
                @input="onModelSelected"
                @keydown.tab="hide"
                @search="updateSearchValue"
                @wantMore="fetchNextModels"
            >
                <template
                    v-if="$scopedSlots.fallback"
                    #fallback="{searchValue}"
                >
                    <slot
                        name="fallback"
                        :search-value="searchValue"
                    />
                </template>

                <template #unselect-item>
                    <slot
                        v-if="$scopedSlots['unselect-item']"
                        name="unselect-item"
                    />
                </template>

                <template #default="{option, searchValue}">
                    <slot
                        :option="option"
                        :search-value="searchValue"
                    />
                </template>
            </CFinder>
        </template>
    </CPopover>
</template>

<script lang="ts">
    import type {Model, QueryBuilder} from '@meekohq/lumos';
    import type {PropType, Ref} from 'vue';
    import {defineComponent, ref} from 'vue';
    import type CDropdown from '@/modules/meeko-ui/components/MDropdown.vue';
    import {debounce, isArray} from 'lodash-es';

    export default defineComponent({
        props: {
            value: {},
            model: {type: Function as PropType<Model>, required: true},
            builder: {type: Object as PropType<QueryBuilder<any>>, required: true},
            disabled: {type: Boolean, default: false},
            hideOnClick: {type: Boolean, default: true},
            multi: {type: Boolean, default: false},
            hasUnselect: {type: Boolean, default: false},
            unselectValue: {},
            dropdownPosition: {},
            dropdownWrapperClass: {},
            dropdownClass: {},
            buttonClass: {},
            popoverClass: {},
            buttonVariant: {},
        },
        emits: ['input', 'search', 'fallback'],
        setup(props, {emit}) {
            const finderRef = ref();
            const dropdownRef = ref<CDropdown>();

            const models: Ref<Model[]> = ref([]);
            const currentPage = ref(1);
            const hasMore = ref(true);
            const loading = ref(false);

            /*
                Toggle the dropdown and fetch models. The fetch is debounced to avoid concurrent calls when multiple
                props are activated and some also trigger a fetch
             */
            const toggle = function () {
                dropdownRef.value?.toggle();

                if (dropdownRef.value?.visible) {
                    fetchModelsDebounced();
                }
            };

            const updateSearchValue = function (value) {
                emit('search', value);
                fetchModelsDebounced();
            };

            const fetchModels = function (page = 1): void {
                loading.value = true;
                props.builder.paginate(100, page).then(paginator => {
                    loading.value = false;

                    currentPage.value = paginator.currentPage();
                    hasMore.value = paginator.hasMorePages();

                    if (paginator.onFirstPage()) {
                        models.value = paginator.items().all();
                    } else {
                        models.value = models.value.concat(paginator.items().all());
                    }
                });
            };

            const fetchModelsDebounced = debounce(() => fetchModels(), 300);

            const fetchNextModels = function () {
                // This method is triggered by the @wantMore event on first page if models count is < 100
                // The return statement avoid unecessary API call
                if (models.value.length < 100) {
                    return;
                }

                fetchModels(currentPage.value + 1);
            };

            const onModelSelected = function (values: any | any[]) {
                if (!props.multi) {
                    dropdownRef.value?.hide();
                }

                emit('input', values);
            };

            const hide = function () {
                dropdownRef.value?.hide();
            };

            const show = function () {
                dropdownRef.value?.show();
            };

            const onShown = function () {
                emit('search', '');
                fetchModelsDebounced();
            };

            return {
                finderRef,
                dropdownRef,
                toggle,
                hide,
                show,
                hasMore,
                models,
                onModelSelected,
                updateSearchValue,
                fetchNextModels,
                loading,
                isArray,
                onShown,
            };
        },
    });
</script>
