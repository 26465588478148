<template>
    <div
        v-if="isVisible && !isReaded"
        :class="baseClass"
    >
        <CInline>
            <CInline :class="contentBaseClass">
                <div
                    v-if="$slots.icon"
                    class="tw-mr-4 tw-self-start"
                >
                    <slot name="icon" />
                </div>
                <CVStack class="tw-flex-1">
                    <div
                        v-if="$slots.title || titleText"
                        class="tw-mb-2 tw-font-semibold"
                    >
                        <slot name="title">
                            <h1 class="h1 tw-text-lg tw-font-semibold tw-text-current">
                                {{ titleText }}
                            </h1>
                        </slot>
                    </div>
                    <slot />
                </CVStack>
            </CInline>
            <button
                v-if="closeButton"
                :class="closeIconBaseClass"
                type="button"
                @click="close"
            >
                <FontAwesomeIcon
                    fixed-width
                    icon="fa-regular fa-times"
                />
            </button>
        </CInline>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onBeforeMount} from 'vue';
    import {useToggle} from '@vueuse/core';
    import moment from 'moment/moment';
    import {cache} from '@meekohq/lumos';

    export default defineComponent({
        props: {
            variant: {type: String, default: 'default'},
            size: {type: String, default: 'default'},
            closeButton: {type: Boolean, default: true},
            titleText: {type: String, default: ''},
            rememberHash: {type: String, default: undefined},
        },
        setup(props) {
            const [isVisible, isVisibleToggle] = useToggle(true);
            const [isReaded, isReadedToggle] = useToggle(false);

            onBeforeMount(async () => {
                isReadedToggle(await cache().has(cacheKeyPath.value));
            });

            /**
             * Cache key path.
             */
            const cacheKeyPath = computed(function () {
                return 'alert-readed-state:' + props.rememberHash;
            });

            /**
             * Close the alert and save the closed state if necessary.
             */
            async function close() {
                isVisibleToggle(false);

                if (props.rememberHash) {
                    // Save the state for 90 days months.
                    await cache({[cacheKeyPath.value]: moment().toISOString()}, 60 * 60 * 24 * 90);
                }
            }

            const baseClass = computed(() => {
                const output = [] as any[];

                output.push('tw-rounded-md tw-text-base tw-relative');

                const variants = {
                    'gray': 'tw-bg-gray-50 tw-text-black',
                    'blue': 'tw-bg-blue-100 tw-text-blue-900',
                    'vivid-blue': 'tw-bg-blue-200 tw-text-blue-900',
                    'purple': 'tw-bg-purple-100 tw-text-purple-900',
                    'orange': 'tw-bg-orange-100 tw-text-orange-900',
                    'yellow': 'tw-bg-yellow-200 tw-text-yellow-900',
                    'green': 'tw-bg-green-100 tw-text-green-900',
                    'red': 'tw-bg-red-500 tw-text-white',
                };
                variants['default'] = variants.blue;
                variants['primary'] = variants.blue;
                variants['danger'] = variants.red;
                variants['warning'] = variants.yellow;
                variants['success'] = variants.green;

                output.push(variants[props.variant]);

                return output;
            });

            const contentBaseClass = computed(() => {
                const output = [] as any[];

                output.push('tw-flex-auto');

                const sizes = {
                    default: 'tw-px-4 tw-py-3',
                    lg: 'tw-p-6',
                };
                output.push(sizes[props.size]);

                return output;
            });

            const closeIconBaseClass = computed(() => {
                const output = [] as any[];

                output.push(
                    'tw-ml-auto tw-mt-3 tw-mr-4 tw-self-start tw-text-xl tw-text-current tw-text-current tw-opacity-75 hover:tw-opacity-100'
                );

                return output;
            });

            return {
                isVisible,
                isVisibleToggle,
                isReaded,
                baseClass,
                contentBaseClass,
                closeIconBaseClass,
                close,
            };
        },
    });
</script>

<style scoped></style>
