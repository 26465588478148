<template>
    <component
        :is="level"
        class="MHeading"
        :class="baseClass"
    >
        <slot />
    </component>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import {get} from 'lodash-es';

    export default defineComponent({
        components: {},
        props: {
            level: {type: String, default: 'h2'},
            variant: {type: String, default: 'primary'},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as any[];

                output.push(get(variantValues, props.variant));

                output.push(get(levelValues, props.level));

                return output;
            });

            return {
                baseClass,
            };
        },
    });

    export const variantValues = {
        primary: 'MHeading--variant-primary',
        blue: 'MHeading--variant-blue',
        green: 'MHeading--variant-green',
        black: 'MHeading--variant-black',
        white: 'MHeading--variant-white',
        gray: 'MHeading--variant-gray',
        billing: 'MHeading--variant-billing',
        danger: 'MHeading--variant-danger',
    };

    export const levelValues = {
        h1: 'MHeading--h1',
        h2: 'MHeading--h2',
        h3: 'MHeading--h3',
        h4: 'MHeading--h4',
    };
</script>

<style scoped>
    .MHeading {
        @apply tw-font-display tw-antialiased;
    }

    .MHeading--variant-primary {
        @apply tw-text-primary-950;
    }

    .MHeading--variant-black {
        @apply tw-text-black;
    }

    .MHeading--variant-white {
        @apply tw-text-white;
    }

    .MHeading--variant-gray {
        @apply tw-text-gray-500;
    }

    .MHeading--variant-blue {
        @apply tw-text-blue-500;
    }

    .MHeading--variant-green {
        @apply tw-text-green-500;
    }

    .MHeading--variant-billing {
        @apply tw-text-orange-600;
    }

    .MHeading--variant-danger {
        @apply tw-text-danger-600;
    }

    .MHeading--h1 {
        @apply tw-text-3xl;
        @apply tw-font-semibold;
    }

    .MHeading--h2 {
        @apply tw-text-2xl;
        @apply tw-font-semibold;
    }

    .MHeading--h3 {
        @apply tw-text-[17px] tw-leading-tight;
        @apply tw-font-medium;
    }

    .MHeading--h4 {
        @apply tw-text-base tw-leading-tight;
        @apply tw-font-medium;
    }
</style>
