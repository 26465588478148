<script setup lang="ts">
    import {computed, ref, watch} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useAbility from '@/modules/app/composables/useAbility';
    import {useClipboard} from '@vueuse/core';

    const props = defineProps({
        website: {
            type: Object,
            required: true,
        },
    });

    const loading = ref(false);
    const {can} = useAbility();
    const personalWebisteUrl = computed(() => {
        return `https://${sanitizeUrl(props.website.slug)}.meeko.site`;
    });
    const {copied, copy} = useClipboard({source: personalWebisteUrl});

    watch(copied, value => {
        if (value) {
            useNotification().success(__('personal_website:website_copied_to_clipboard'));
        }
    });

    function updateWebsite() {
        loading.value = true;
        useApi()
            .legacy.put(route('websites.update', {website: props.website.id}), {
                slug: sanitizeUrl(props.website.slug),
            })
            .then(() => {
                useNotification().success(__('personal_website:website_updated_successfully'));
            })
            .catch(error => {
                if (error?.response?.status === 422) {
                    _forEach(error.response.data.errors, function (value) {
                        useNotification().error(_head(value) as string);
                    });
                } else {
                    useNotification().error(error);
                }
            })
            .finally(() => {
                loading.value = false;
            });
    }

    function sanitizeUrl(url: string) {
        if (url) {
            return url.replace(/ /g, '');
        }

        return url;
    }
</script>

<template>
    <div class="PersonalWebsiteLink">
        <MHeading>{{ __('personal_website:website_link') }}</MHeading>
        <div class="PersonalWebsiteLink__subtitle">
            {{ __('personal_website:make_your_site_accessible_at_this_address') }}
        </div>
        <div class="PersonalWebsiteLink__container">
            <div class="PersonalWebsiteLink__sub-container">
                <div class="PersonalWebsiteLink__input-container">
                    <div class="PersonalWebsiteLink__input-preffix">https://</div>
                    <MInput
                        v-model="website.slug"
                        class="PersonalWebsiteLink__input"
                        :disabled="!can('update', 'nursery')"
                        :formatter="sanitizeUrl"
                        :placeholder="__('personal_website:my_dash_organization')"
                        type="url"
                    />
                    <div class="PersonalWebsiteLink__input-suffix">.meeko.site</div>
                </div>
                <MButton @click="copy(personalWebisteUrl)">
                    <FontAwesomeIcon
                        class="tw-text-primary-500"
                        fixed-width
                        icon="fa-solid fa-copy"
                    />
                </MButton>
            </div>
            <MButton
                v-if="can('update', 'nursery')"
                class="PersonalWebsiteLink__button"
                :loading="loading"
                variant="primary"
                @click="updateWebsite"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-sync" />
                </template>
                {{ __('personal_website:update_link') }}
            </MButton>
        </div>
    </div>
</template>

<style scoped>
    .PersonalWebsiteLink__subtitle {
        @apply tw-mt-1 tw-text-gray-500;
    }

    .PersonalWebsiteLink__container {
        @apply tw-mt-4 tw-flex tw-flex-col tw-gap-2 md:tw-flex-row;
    }

    .PersonalWebsiteLink__sub-container {
        @apply tw-flex tw-gap-2;
    }

    .PersonalWebsiteLink__input-container {
        @apply tw-relative tw-flex tw-w-full tw-items-center md:tw-w-80;
    }

    .PersonalWebsiteLink__input-preffix {
        @apply tw-absolute tw-left-0 tw-z-10 tw-pl-2 tw-text-gray-500;
    }

    .PersonalWebsiteLink__input {
        @apply tw-w-full tw-pl-[60px] tw-pr-[92px];
    }

    .PersonalWebsiteLink__input-suffix {
        @apply tw-absolute tw-right-0 tw-pr-2 tw-text-gray-500;
    }

    .PersonalWebsiteLink__button {
        @apply tw-w-full md:tw-w-auto;
    }
</style>
