<template>
    <ConfirmModal
        ref="confirmModal"
        :orga="organization"
        :title="__('hr:import_event_type_from_another_organization')"
        @organizationSelected="importEventTypes($event)"
    />
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import {attachModelToOrganization, getModelIDontHave} from '@/modules/app/composables/useSharedImportModel';
    import useManager from '@/modules/app/composables/useManager';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import ConfirmModal from '@/modules/app/components/ConfirmModal.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {ConfirmModal},
        props: {},
        setup(props, {emit}) {
            const {activeOrganization} = useManager();
            const confirmModal = ref();
            const organization = ref();
            const aaa = ref(new EventTypeModel());
            aaa.value.calendar();
            const eventTypes = ref(collect<EventTypeModel>());

            const show = async function () {
                organization.value = await OrganizationModel.query().find(activeOrganization.value.id);
                eventTypes.value = await EventTypeModel.query()
                    .whereHas('organizations', query => query.scope('active'))
                    .get();
                confirmModal.value.show();
            };

            const importEventTypes = async function (selectedOrganization: OrganizationModel) {
                if (selectedOrganization) {
                    try {
                        // get the query builder to get model present in other organizations
                        const {builder} = getModelIDontHave(
                            selectedOrganization,
                            eventTypes.value as Collection<EventTypeModel>
                        );

                        // fetch EventType with the builder wich is inject in query
                        const eventTypesOfSelectedOrganization = await EventTypeModel.query().inject(builder).get();

                        // attach every EventTypes of the selected Organization
                        await attachModelToOrganization(eventTypesOfSelectedOrganization.all(), organization.value);

                        emit('imported');
                        useNotification().success(__('hr:import_event_type_success'));
                    } catch (e) {
                        useNotification().error(__('hr:errors.import_event_type'));
                    }
                }
            };

            return {
                show,
                importEventTypes,
                confirmModal,
                organization,
            };
        },
    });
</script>
