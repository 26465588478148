import {Binding} from '@meekohq/lumos';
import type {InvoiceAggregate} from '@/modules/cashier/invoice/domain/InvoiceAggregate';
import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
import type {UpdateInvoiceOutputPort} from '@/modules/cashier/invoice/domain/use-cases/UpdateInvoiceOutputPort';

export const UpdateInvoiceInputPortBinding = new Binding<UpdateInvoiceInputPort, UpdateInvoiceOutputPort>();

export interface UpdateInvoiceInputPort {
    update(invoiceAggregate: InvoiceAggregate, invoiceChanged: LegacyInvoiceType): unknown;
}
