import type {Collection} from '@meekohq/lumos';
import {collect} from '@meekohq/lumos';
import type ISplittable from '@/modules/legacy/libs/periodSplitter/ISplittable';
import _clone from 'lodash-es/clone';
import getOverlappingEvents from '@/modules/legacy/libs/periodSplitter/getOverlappingEvents';
import _differenceWith from 'lodash-es/differenceWith';
import _isEqual from 'lodash-es/isEqual';

export type StrategyCallbackType<TSplittable extends ISplittable> = (
    originalEvent: TSplittable,
    overlappingEvent: TSplittable
) => TSplittable[];

export default class ExcludeEvent<TSplittable extends ISplittable> {
    protected _events: Collection<TSplittable>;

    /**
     * @param events
     */
    public constructor(events: TSplittable[]) {
        this._events = ExcludeEvent.sortEvents(collect(events));
    }

    /**
     * Sort events by startedAt date
     *
     * @param events
     */
    protected static sortEvents<TSplittable extends ISplittable>(events: Collection<TSplittable>) {
        return events.sortBy('startedAt');
    }

    /**
     * define the strategyCallback that exclude overlapping events
     *
     * @param strategyCallback
     */
    public setStrategyCallback(strategyCallback: StrategyCallbackType<TSplittable>) {
        this._strategyCallback = strategyCallback;

        return this;
    }

    /**
     * Get events not excluded by the strategy
     */
    public exclude(): TSplittable[] {
        let excludedEvents: TSplittable[] = [];

        for (const event of this._events.all()) {
            const newEvents = this.processItem(event);

            if (newEvents.length) {
                excludedEvents = excludedEvents.concat(newEvents);
            }
        }

        // Return events that are in this._events but not in excludedEvents
        return _differenceWith(this._events.all(), excludedEvents, _isEqual);
    }

    /**
     * Items returned by the strategy are the ones we want to exclude
     */

    protected _strategyCallback: StrategyCallbackType<TSplittable> = () => [];

    /**
     * Get an array of events that overlap the event and are excluded with the strategy callback
     * @param event
     */
    protected processItem(event: TSplittable): TSplittable[] {
        let newEvents: TSplittable[] = [];

        //Get all events overlapping
        const overlappingEvents = getOverlappingEvents(event, this._events);

        const cloneEvent = _clone(event);

        if (overlappingEvents.length) {
            for (const overlappingEvent of overlappingEvents) {
                const excludedEvents = this._strategyCallback(cloneEvent, overlappingEvent);
                if (excludedEvents.length) {
                    newEvents = newEvents.concat(excludedEvents);
                }
            }
        }

        return collect(newEvents).unique().toArray();
    }
}
