import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import type FamilyModel from '@/modules/family/models/FamilyModel';
import type {Transaction} from '@meekohq/lumos';
import {MqlTransaction} from '@meekohq/lumos';
import StoreCustomerFamilyAction from './StoreCustomerFamilyAction';
import type FamilyCustomerPivot from '@/modules/family/models/FamilyCustomerPivot';

export default class StoreCustomerAction {
    storeCustomer(customer: CustomerModel, mqlRunner: MqlTransaction = new MqlTransaction()): Promise<CustomerModel> {
        return customer.save({mqlRunner});
    }

    storeAndAssociateCustomer(
        customer: CustomerModel,
        family: FamilyModel | undefined,
        mqlRunner: Transaction
    ): {customerPromise: Promise<CustomerModel>; pivotPromise: Promise<FamilyCustomerPivot> | undefined} {
        const customerPromise: Promise<CustomerModel> = this.storeCustomer(customer, mqlRunner);

        let pivotPromise: Promise<FamilyCustomerPivot> | undefined;

        const customerFamily: FamilyModel = customer.families().value().first();
        if (!customer.isCompany && !customerFamily) {
            const familyAction: StoreCustomerFamilyAction = new StoreCustomerFamilyAction();
            pivotPromise = familyAction.store(family, customer, mqlRunner);
        }

        return {
            customerPromise,
            pivotPromise,
        };
    }
}
