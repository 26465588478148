<template>
    <div>
        <ResourceFinder
            :builder="queryBuilder"
            :button-class="buttonClass"
            :button-variant="buttonVariant"
            :has-unselect="hasUnselect"
            :multi="multi"
            :multi-minimum="multiMinimum"
            :search-bar="searchBar"
            :value="value"
            :wrapper-class="wrapperClass"
            @fallback="onFallback"
            @input="onChange"
            @search="search = $event"
        >
            <template #button-text-empty>
                <slot name="button-text-empty" />
            </template>

            <template #button-text="{value}">
                {{ value.attributes.name }}
            </template>

            <template #default="{option, searchValue}">
                <div class="tw-flex tw-pr-4">
                    <div
                        class="tw-truncate"
                        v-html="highlight(searchValue, option.attributes.name)"
                    />
                </div>
            </template>
        </ResourceFinder>
        <slot
            :modal="{search}"
            name="modal"
        >
            <CreateSimulationListModal
                v-if="showModal"
                ref="modal"
                :search-value="search"
                @created="onModelCreated($event)"
                @hide="showModal = !showModal"
            />
        </slot>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import CreateSimulationListModal from '@/modules/planning/components/simulation/List/CreateSimulationListModal.vue';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import SimulationListModel from '@/modules/planning/models/SimulationListModel';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import type {QueryBuilder} from '@meekohq/lumos';
    import {type Model} from '@meekohq/lumos';

    export default defineComponent({
        components: {CreateSimulationListModal, ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            value: {type: [Object, Array] as PropType<Model | Model[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            hasUnselect: {type: Boolean, default: false},
            searchBar: {type: Boolean, default: true},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
        },
        emits: ['input'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function (value) {
                emit('input', value);
            };
            // !!! END: DO NOT EDIT !!

            const showModal = ref(false);
            const queryBuilder = computed(() => {
                let builder = SimulationListModel.query().orderBy('name');

                if (search.value) {
                    builder = builder.whereLike('name', `*${search.value}*`);
                }

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            function onModelCreated(model) {
                onChange(model);
            }

            const onFallback = function () {
                showModal.value = !showModal.value;
            };

            return {
                ...useHighlighter(),
                search,
                onChange,
                onFallback,
                queryBuilder,
                onModelCreated,
                showModal,
            };
        },
    });
</script>
