import type AllocationModel from '@/modules/cashier/models/AllocationModel';
import type TenantModel from '@/modules/cashier/models/TenantModel';
import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import type CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import {Epoch, MqlTransaction} from '@meekohq/lumos';
import {datadogRum} from '@datadog/browser-rum';

/**
 * StoreAllocationAction class.
 */
export default class StoreAllocationAction {
    /**
     * Store an allocation and associate it with the tenant, customer and currency.
     * @param {AllocationModel} allocation
     * @param {TenantModel} tenant
     * @param {CustomerModel|undefined} customer
     * @param {CurrencyModel|undefined} currency
     * @param {MqlTransaction} mqlRunner
     * @return {Promise<AllocationModel>}
     */
    async storeAndAssociate(
        allocation: AllocationModel,
        tenant: TenantModel,
        customer: CustomerModel | undefined,
        currency: CurrencyModel | undefined,
        mqlRunner: MqlTransaction = new MqlTransaction()
    ): Promise<AllocationModel> {
        if (!allocation.exists) {
            allocation.tenant().associate(tenant);

            if (customer) {
                allocation.customer().associate(customer);
            }

            if (!allocation.attributes.date) {
                allocation.attributes.date = Epoch.now().toISOString();
            }
        }

        if (currency) {
            allocation.currency().associate(currency);
        }

        datadogRum.addAction('save Allocation', {
            transaction: allocation.attributes,
            customer: customer?.getKey(),
        });

        return allocation.save({mqlRunner});
    }
}
