<template>
    <div class="TagsManagerHeader">
        <MHeading>
            {{ __('tag:tags_list') }}
        </MHeading>
        <div class="TagsManagerHeader__buttons-container">
            <MTooltip
                v-if="nurseries.length > 1"
                :label="__('common:import_from_another_organization')"
            >
                <MButton @click="$emit('importTags')">
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-download" />
                    </template>
                    {{ __('common:actions.import') }}
                </MButton>
            </MTooltip>
            <MButton
                v-if="can('create', 'tags')"
                icon-plus
                :label="__('common:actions.add_tag')"
                variant="primary"
                @click="$emit('initNewTagAndShowModal')"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import useAbility from '@/modules/app/composables/useAbility';

    export default defineComponent({
        props: {
            nurseries: {
                type: Array,
                required: true,
            },
        },
        setup() {
            const {can} = useAbility();

            return {
                can,
            };
        },
    });
</script>

<style scoped>
    .TagsManagerHeader {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2;
    }

    .TagsManagerHeader__buttons-container {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2;
    }
</style>
