<template>
    <div>
        <loader
            v-if="loading"
            class="tw-p-4"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <div v-else-if="sortedInvoices.length">
            <InvoiceItem
                v-for="invoice in sortedInvoices"
                :key="invoice.id"
                :invoice="invoice"
                :organization="nursery"
                :with-tva="withTva"
            />
        </div>
        <div
            v-else
            class="tw-w-full tw-p-4 tw-text-center"
        >
            {{ __('billing_invoice:no_invoice_expected') }}
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import _orderBy from 'lodash-es/orderBy';
    import InvoiceItem from '@/modules/cashier/components/billing/invoice/legacy/EstimateInvoices/InvoiceItem.vue';

    const props = defineProps({
        nursery: {
            type: Object,
            required: true,
        },
        invoices: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    });

    const sortedInvoices = computed(() => {
        return _orderBy(props.invoices, 'date');
    });

    const withTva = computed(() => {
        return props.nursery.invoice_tva > 0;
    });
</script>

<style scoped>
    .invoice-row:nth-child(even) {
        background-color: #f6fbff;
    }

    .table {
        thead {
            th {
                border: none;
            }
        }
    }
</style>
