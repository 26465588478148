<template>
    <textarea
        :id="id"
        class="form-control input"
        :disabled="disabled"
    />
</template>

<script>
    import 'trumbowyg';
    import TrumbowygIcons from 'trumbowyg/dist/ui/icons.svg';
    import 'trumbowyg/dist/langs/fr.min.js';
    import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js';
    import 'trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min.js';
    import 'trumbowyg/dist/plugins/emoji/trumbowyg.emoji.min.js';
    import '@/modules/legacy/libs/trumbowyg/trumbowyg.variables.js';
    import '@/modules/legacy/libs/trumbowyg/trumbowyg.selectImage.js';
    import '@/modules/legacy/libs/trumbowyg/trumbowyg.fontSize.js';

    export default {
        props: {
            value: {},
            id: {
                type: String,
                default: 'trumbowyg',
            },
            editorVariables: {
                type: Array,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                TrumbowygIcons,
            };
        },
        mounted() {
            this.$nextTick(() => {
                this.init();
            });
        },
        beforeDestroy() {
            $('.trumbowyg-modal-reset').trigger('click');
        },
        methods: {
            init() {
                const self = this;
                const el = $('#' + this.id);
                el.trumbowyg({
                    btns: [
                        ['undo', 'redo'],
                        ['fontsize', 'foreColor', 'backColor'],
                        ['strong', 'em', 'underline'],
                        ['justifydrop'],
                        ['image'],
                        ['emoji'],
                        ['link'],
                        self.editorVariables,
                        ['viewHTML'],
                    ],
                    btnsDef: {
                        image: {
                            dropdown: ['insertImage', 'selectImage'],
                            ico: 'insertImage',
                        },
                        justifydrop: {
                            dropdown: ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                            ico: 'justifyCenter',
                        },
                    },
                    lang: 'fr',
                    svgPath: TrumbowygIcons,
                    imageWidthModalEdit: true,
                    autogrow: true,
                    minimalLinks: true,
                    removeformatPasted: true,
                    tagsToRemove: ['script', 'link'],
                })
                    .on('tbwchange', () => {
                        self.$emit('input', el.trumbowyg('html'));
                    })
                    .on('tbwpaste', () => {
                        self.$emit('input', el.trumbowyg('html'));
                    });

                el.trumbowyg('html', this.value);
            },
        },
    };
</script>

<style>
    @import 'trumbowyg/dist/ui/trumbowyg.min.css';
    @import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css';
    @import 'trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.min.css';

    .trumbowyg {
        margin: 0;
        background-color: #fff;
    }

    .trumbowyg-editor {
        min-height: 300px !important;
    }

    .trumbowyg-button-pane {
        background: #f1f8ff;
    }
</style>
