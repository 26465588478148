<template>
    <div class="TransactionListNavigation">
        <MButton
            v-if="hasFromAndTo"
            class="TransactionListNavigation__button"
            :disabled="lockPeriod"
            variant="light-alt"
            @click="lockPeriod ? undefined : $emit('previousMonth')"
        >
            <FontAwesomeIcon icon="fa-solid fa-angle-left" />
        </MButton>
        <div class="TransactionListNavigation__period">
            <MSkeleton
                class="tw-h-5 tw-w-24"
                :loaded="!isLoading && !loadingTransactions"
            >
                <div class="TransactionListNavigation__period-amount">
                    {{ formattedAmount }}
                </div>
            </MSkeleton>
            <div class="TransactionListNavigation__period-period">
                {{ toHuman }}
            </div>
        </div>
        <MButton
            v-if="hasFromAndTo"
            class="TransactionListNavigation__button"
            :disabled="lockPeriod"
            variant="light-alt"
            @click="lockPeriod ? undefined : $emit('nextMonth')"
        >
            <FontAwesomeIcon icon="fa-solid fa-angle-right" />
        </MButton>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, toRef} from 'vue';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import useSumTransactionsTotalByCurrencies from '@/modules/cashier/transaction/infrastructure/components/useSumTransactionsTotalByCurrencies';
    import useHumanizePeriod from '@/modules/app/composables/useHumanizePeriod';
    import type {TransactionListFiltersType} from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';
    import type {Emitter} from 'mitt';

    export default defineComponent({
        props: {
            bus: {
                type: Object as PropType<Emitter<{deleted: void}>>,
                required: true,
            },
            loadingTransactions: {
                type: Boolean,
                required: true,
            },
            filters: {
                type: Object as PropType<Partial<TransactionListFiltersType>>,
                required: true,
            },
            lockPeriod: {type: Boolean, default: false},
        },
        setup(props) {
            const hasFromAndTo = computed(() => {
                return props.filters.period?.from && props.filters.period?.to;
            });

            const humanizeObject = computed(() => {
                return {
                    from: props.filters.period?.from ? new Date(props.filters.period.from) : undefined,
                    to: props.filters.period?.to ? new Date(props.filters.period.to) : undefined,
                };
            });

            const {toHuman} = useHumanizePeriod(humanizeObject);

            const {fetchTotalAmountByCurrency, isLoading, totalAmountByCurrency, watchOptions} =
                useSumTransactionsTotalByCurrencies(toRef(props, 'filters'));

            watchOptions();

            props.bus.on('deleted', () => {
                fetchTotalAmountByCurrency();
            });

            const formattedAmount = computed(() => {
                const currency = totalAmountByCurrency.value[0];

                if (!currency) {
                    return '';
                }

                const {format} = useFormatCurrency(currency.code);

                return format(currency.amount);
            });

            return {
                toHuman,
                formattedAmount,
                totalAmountByCurrency,
                isLoading,
                hasFromAndTo,
            };
        },
    });
</script>

<style scoped>
    .TransactionListNavigation {
        @apply tw-flex tw-w-full tw-justify-center tw-gap-2;
    }

    .TransactionListNavigation__button {
        @apply tw-px-3;
    }

    .TransactionListNavigation__period {
        @apply tw-flex tw-min-w-[190px] tw-flex-col tw-items-center tw-justify-center;
    }

    .TransactionListNavigation__period-amount {
        @apply tw-h-5 tw-font-display tw-text-xl tw-font-semibold tw-leading-none;
    }

    .TransactionListNavigation__period-period {
        @apply tw-font-display tw-text-lg tw-capitalize tw-leading-snug;
    }
</style>
