<template>
    <div :class="wrapperStyle">
        <div
            :class="buttonStyle"
            @click="toggleDisclosure"
        >
            <slot name="title">
                {{ title }}
            </slot>
            <CCenter class="CDisclosureButtonIcon">
                <font-awesome-icon
                    v-if="loading"
                    icon="fa-regular fa-spinner-third"
                    spin
                />
                <font-awesome-icon
                    v-else
                    icon="fa-solid fa-chevron-up"
                />
            </CCenter>
        </div>
        <!--        <transition-->
        <!--            enter-active-class="tw-transition tw-duration-100 tw-ease-out"-->
        <!--            enter-class="tw-scale-95 tw-opacity-0"-->
        <!--            enter-to-class="tw-scale-100 tw-opacity-100"-->
        <!--            leave-active-class="tw-transition tw-duration-75 tw-ease-out"-->
        <!--            leave-class="tw-scale-100 tw-opacity-100"-->
        <!--            leave-to-class="tw-scale-95 tw-opacity-0"-->
        <!--        >-->
        <div
            v-show="isOpen"
            class="CDisclosurePanel"
        >
            <slot />
        </div>
        <!--        </transition>-->
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';

    type Variant = 'blue' | 'gray' | 'purple' | 'white';
    type Size = 'base' | 'sm';

    export default defineComponent({
        components: {
            //
        },
        props: {
            title: {type: String as PropType<string>},
            variant: {type: String as PropType<Variant>, default: 'blue'},
            size: {type: String as PropType<Size>, default: 'base'},
            open: {type: Boolean as PropType<boolean>, default: true},
            ghost: {type: Boolean as PropType<boolean>, default: false},
            loading: {type: Boolean as PropType<boolean>, default: false},
            bodyPadding: {type: Boolean as PropType<boolean>, default: true},
        },
        setup(props) {
            const isOpen = ref(props.open);

            const toggleDisclosure = function () {
                isOpen.value = !isOpen.value;
            };

            const wrapperStyle = computed(() => {
                const output = [] as any[];

                output.push('CDisclosure');
                output.push(sizeValues[props.size]);
                if (!props.bodyPadding) {
                    output.push('CDisclosure-disable-body-padding');
                }

                return output;
            });

            const buttonStyle = computed(() => {
                const output = [] as any[];

                output.push('CDisclosureButton');
                isOpen.value
                    ? output.push('CDisclosureButton-state-open')
                    : output.push('CDisclosureButton-state-closed');
                output.push(variantValues[props.variant]);
                if (props.ghost && !isOpen.value) {
                    output.push('CDisclosureButton-ghost');
                }

                return output;
            });

            return {
                isOpen,
                toggleDisclosure,
                wrapperStyle,
                buttonStyle,
            };
        },
    });

    export const variantValues = {
        blue: 'CDisclosureButton-variant-blue',
        purple: 'CDisclosureButton-variant-purple',
        gray: 'CDisclosureButton-variant-gray',
        white: 'CDisclosureButton-variant-white',
    };

    export const sizeValues = {
        base: 'CDisclosure-size-base',
        sm: 'CDisclosure-size-sm',
    };
</script>

<style scoped>
    .CDisclosure {
        @apply tw-w-full;
    }

    .CDisclosureButton {
        @apply tw-flex tw-w-full tw-cursor-pointer tw-select-none tw-items-center tw-justify-between;
        @apply tw-text-left tw-font-semibold;
        @apply tw-transition;
        /*
        focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-500 focus-visible:tw-ring-opacity-75
        */
    }

    .CDisclosureButtonIcon {
        @apply tw-transition tw-duration-300 tw-ease-in-out;
    }

    .CDisclosureButton-state-open {
        .CDisclosureButtonIcon {
            @apply tw-rotate-180;
        }
    }

    .CDisclosure-size-base {
        .CDisclosureButton {
            @apply tw-rounded-lg tw-px-4 tw-py-2;
            @apply tw-text-base;
        }

        .CDisclosureButtonIcon {
            @apply tw-h-5 tw-w-5;
        }

        .CDisclosurePanel {
            @apply tw-px-4 tw-pb-2 tw-pt-4;
        }
    }

    .CDisclosure-size-sm {
        .CDisclosureButton {
            @apply tw-rounded-md tw-px-2 tw-py-1;
            @apply tw-text-sm;
        }

        .CDisclosureButtonIcon {
            @apply tw-h-3 tw-w-3;
        }

        .CDisclosurePanel {
            @apply tw-px-2 tw-pb-1 tw-pt-2 tw-text-sm;
        }
    }

    .CDisclosure-disable-body-padding {
        .CDisclosurePanel {
            @apply tw-px-0;
        }
    }

    .CDisclosureButton-variant-blue {
        @apply tw-bg-blue-200/50 tw-text-blue-800 hover:tw-bg-blue-200/75;

        .CDisclosureButtonIcon {
            @apply tw-text-blue-800;
        }
    }

    .CDisclosureButton-variant-purple {
        @apply tw-bg-purple-200/50 tw-text-purple-800 hover:tw-bg-purple-200/75;

        .CDisclosureButtonIcon {
            @apply tw-text-purple-800;
        }
    }

    .CDisclosureButton-variant-gray {
        @apply tw-bg-gray-300/50 tw-text-gray-900 hover:tw-bg-gray-300/75;

        .CDisclosureButtonIcon {
            @apply tw-text-gray-900;
        }
    }

    .CDisclosureButton-variant-white {
        @apply tw-bg-white tw-text-gray-900 hover:tw-bg-white/75;

        .CDisclosureButtonIcon {
            @apply tw-text-gray-900;
        }
    }

    .CDisclosureButton-ghost {
        @apply tw-bg-transparent;
    }
</style>
