<template>
    <MBox
        v-if="loader.isDone('fetchJobModels')"
        class="PlanningSettingsAllKids"
        :class="{'PlanningSettingsAllKids--editing': editGeneral}"
    >
        <PlanningSettingsAllKidsCard
            v-if="!editGeneral"
            :job-models="jobModels"
            :my-nursery="myNursery"
            @editRule="editGeneral = true"
        />
        <PlanningSettingsAllKidsForm
            v-else
            :my-nursery="myNursery"
            :nursery="nursery"
            @cancelNursery="cancelNursery"
            @saveNursery="saveNursery"
        />
    </MBox>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import {collect} from '@meekohq/lumos';
    import JobModel from '@/modules/human-resources/models/JobModel';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import PlanningSettingsAllKidsCard from '@/modules/planning/components/settings/PlanningSettingsGroup/molecules/PlanningSettingsAllKidsCard.vue';
    import PlanningSettingsAllKidsForm from '@/modules/planning/components/settings/PlanningSettingsGroup/molecules/PlanningSettingsAllKidsForm.vue';

    export default {
        components: {
            PlanningSettingsAllKidsForm,
            PlanningSettingsAllKidsCard,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                editGeneral: false,
                myNursery: {},
                jobModels: collect(),
                loader: new Loader(),
            };
        },
        async mounted() {
            this.myNursery = _cloneDeep(this.nursery);
            this.loader.start('fetchJobModels');
            this.jobModels = await JobModel.query().get();
            this.loader.stop('fetchJobModels');
        },

        methods: {
            saveNursery() {
                useApi()
                    .legacy.put(route('nurseries.update', {nurseries: this.nursery.id}), {
                        places: this.myNursery.places,
                        supervision_rules:
                            this.myNursery.supervision_rules.length > 0 ? this.myNursery.supervision_rules : null,
                    })
                    .then(response => {
                        this.nursery.places = response.data.places;
                        this.nursery.supervision_rules = response.data.supervision_rules;
                        this.editGeneral = false;
                        useNotification().success(__('common:parameters_updated_successfully'));
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            cancelNursery() {
                this.myNursery = _cloneDeep(this.nursery);
                this.editGeneral = false;
            },
        },
    };
</script>

<style scoped>
    .PlanningSettingsAllKids {
        @apply tw-m-auto tw-w-full tw-p-4 xl:tw-w-2/3 2xl:tw-w-1/2;
    }

    .PlanningSettingsAllKids--editing {
        @apply tw-bg-gray-100;
    }
</style>
