<template>
    <MPopover
        ref="tagSelectorPopover"
        class="TagSelector"
    >
        <MButton icon-menu>
            <FontAwesomeIcon
                v-if="tag.icon_id"
                class="tw-px-px"
                :icon="`fa-solid ${icon.attributes.path}`"
                size="lg"
                :style="'color: ' + tag.color"
            />
            <FontAwesomeIcon
                v-else
                class="tw-px-px"
                icon="fa-solid fa-square"
                size="lg"
                :style="'color: ' + tag.color"
            />
        </MButton>
        <template #content>
            <div
                class="TagSelector__popper-container"
                :style="'color: ' + tag.color"
            >
                <div
                    class="TagSelector__no-icon-container"
                    @click="changeIcon(null)"
                >
                    <FontAwesomeIcon
                        class="tw-text-2xl"
                        icon="fa-solid fa-square"
                    />
                    <span class="tw-ml-2 tw-text-gray-800">
                        {{ __('components:without_icone') }}
                    </span>
                </div>
                <div
                    v-for="(category, index) in categories"
                    :key="'cat' + index"
                >
                    <MHeading
                        class="tw-mb-1"
                        level="h4"
                    >
                        <template v-if="index === 'people'">
                            {{ __('common:people') }}
                        </template>
                        <template v-else-if="index === 'communication'">
                            {{ __('common:communication') }}
                        </template>
                        <template v-else-if="index === 'files'">
                            {{ __('common:files') }}
                        </template>
                        <template v-else-if="index === 'objects'">
                            {{ __('common:objects') }}
                        </template>
                        <template v-else-if="index === 'others'">
                            {{ __('common:other_other') }}
                        </template>
                    </MHeading>
                    <div class="TagSelector--icon-list">
                        <div
                            v-for="(icon, i) in category"
                            :key="'cat' + index + 'icon' + i"
                            @click="changeIcon(icon)"
                        >
                            <FontAwesomeIcon
                                class="tw-cursor-pointer tw-text-xl"
                                :icon="`fa ${icon.attributes.path}`"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </MPopover>
</template>

<script>
    import IconModel from '@/modules/app/models/IconModel';
    import DataStore from '@/modules/legacy/store/data.store';
    import {cloneDeep, groupBy} from 'lodash-es';

    export default {
        props: {
            value: {
                type: Object,
                default: null,
            },
            size: {
                type: String,
                default: 'sm',
            },
        },
        data: () => ({
            icons: [],
            icon: new IconModel(),
            categories: [],
            tag: {
                icon_id: null,
                path: null,
                provider: null,
                color: '#000',
            },
        }),

        watch: {
            value: {
                handler(val) {
                    this.tag = val;
                },
                deep: true,
            },
        },
        mounted() {
            this.icons = DataStore.store.data.icons;
            this.categories = groupBy(this.icons, icon => icon.attributes.category);
            this.tag = this.value ? cloneDeep(this.value) : null;
            if (this.value) {
                const icon = DataStore.iconPath(this.value.icon_id);
                this.icon.attributes = {...this.icon.attributes, ...icon?.attributes};
            }
        },

        methods: {
            changeIcon(icon) {
                if (!icon) {
                    this.tag.icon_id = null;
                } else {
                    this.tag.icon_id = icon.id;
                    this.icon.attributes = {...icon.attributes};
                }
                this.$emit('input', this.tag);
                this.$refs.tagSelectorPopover.closePopover();
            },
        },
    };
</script>

<style scoped>
    .TagSelector__popper-container {
        @apply tw-flex tw-flex-col tw-gap-4 tw-p-2;
        @apply tw-max-h-60 tw-overflow-auto;
    }

    .TagSelector__no-icon-container {
        @apply tw-flex tw-cursor-pointer tw-flex-row tw-items-center;
    }

    .TagSelector--icon-list {
        @apply tw-flex tw-flex-row tw-flex-wrap tw-gap-x-3 tw-gap-y-1;
    }
</style>
