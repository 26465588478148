import {Model} from '@meekohq/lumos';
import {join, trim} from 'lodash-es';
import EndpointModel from '@/modules/cashier/models/EndpointModel';

export default class ContactModel extends Model {
    public type = 'cashier/customer_contacts';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        customer_id: string | undefined;
        title: string | undefined;
        first_name: string | undefined;
        last_name: string | undefined;
        service: string | undefined;
        position: string | undefined;
        email: string | undefined;
        phone: string | undefined;
    } = {
        id: this.uuid(),
        tenant_id: undefined,
        customer_id: undefined,
        title: undefined,
        first_name: undefined,
        last_name: undefined,
        service: undefined,
        position: undefined,
        email: undefined,
        phone: undefined,
    };

    get fullname(): string {
        return trim(join([this.attributes.title, this.attributes.first_name, this.attributes.last_name], ' '));
    }

    get job() {
        return trim(join([this.attributes.service, this.attributes.position], ' / '), ' / ');
    }

    notificationEndpoint() {
        return this.morphOne('notificationEndpoint', EndpointModel, 'resource');
    }
}
