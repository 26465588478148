<template>
    <div>
        <div>
            <div
                v-if="showCAF()"
                class="tw-mb-4 tw-rounded-md tw-bg-gray-50 tw-p-2"
            >
                <a
                    class="tw-block tw-font-semibold"
                    href="https://partenaires.caf.fr/portal/auth/login"
                    target="_blank"
                >
                    {{ __('family_contract:consult_benefits_folder') }}
                    <FontAwesomeIcon
                        class="tw-ml-1"
                        icon="fa-solid fa-external-link"
                    />
                </a>
                <div
                    v-if="firstParent && firstParent.allocator_no"
                    class="tw-mt-1 tw-text-base tw-capitalize tw-text-gray-700"
                >
                    {{ $fullName(firstParent) }} {{ ' - ' + firstParent.allocator_no }}
                </div>
                <div
                    v-if="secondParent && secondParent.allocator_no"
                    class="tw-text-base tw-capitalize tw-text-gray-700"
                >
                    {{ $fullName(secondParent) }} {{ ' - ' + secondParent.allocator_no }}
                </div>
            </div>
            <div class="form-group">
                <div class="form-row">
                    <div class="col-12 col-sm-6">
                        <label
                            class="label"
                            for="cafpro_resources"
                        >
                            {{ __('billing_core:annual_resources') }}
                        </label>
                        <input
                            id="cafpro_resources"
                            v-model.number="contract.cafpro_resources"
                            class="form-control"
                            :disabled="disabled || !$can('update', 'kids_contracts')"
                            :placeholder="__('billing_core:annual_resources')"
                            type="text"
                        />
                    </div>
                    <div class="col-12 col-sm-6 tw-mt-2 sm:tw-mt-0">
                        <label
                            class="label"
                            for="resources_year"
                        >
                            {{ __('common:year_one') }}
                        </label>
                        <input
                            id="resources_year"
                            v-model.number="contract.resources_year"
                            class="form-control"
                            :disabled="disabled || !$can('update', 'kids_contracts')"
                            :placeholder="__('family_contract:resources_year')"
                            type="text"
                        />
                    </div>
                    <div class="col-12 col-sm-6 tw-mt-4">
                        <label
                            class="label"
                            for="dependent_children"
                        >
                            {{ __('common:dependent_children') }}
                        </label>
                        <input
                            id="dependent_children"
                            v-model.number="contract.dependent_children"
                            class="form-control"
                            :disabled="disabled || !$can('update', 'kids_contracts')"
                            :placeholder="__('family_contract:number_of_dependent_children')"
                            type="text"
                        />
                    </div>
                    <div class="col-12 col-sm-6 tw-mt-4">
                        <label
                            class="label"
                            for="handicapped_children"
                        >
                            {{ __('family_contract:children_with_disabilities') }}
                        </label>
                        <input
                            id="handicapped_children"
                            v-model.number="contract.handicapped_children"
                            class="form-control"
                            :disabled="disabled || !$can('update', 'kids_contracts')"
                            :placeholder="__('family_contract:number_of_children_with_disabilities')"
                            type="text"
                        />
                    </div>
                </div>
            </div>

            <MButton
                class="tw-mt-2"
                icon-menu
                :label="showDetail ? __('common:hide_detail') : __('common:view_detail')"
                @click="showDetail = !showDetail"
            />
            <div v-if="showDetail">
                <div class="form-group tw-mt-6">
                    <div
                        class="form-row tw-text-primary-500 lg:tw-text-center"
                        style="font-size: 20px"
                    >
                        <div class="col">
                            <div v-if="!contract.first_family_member_id">
                                {{ __('common:first_parent') }}
                            </div>
                            <div v-else>
                                {{ getFamilyMemberNameById(contract.first_family_member_id) }}
                            </div>
                        </div>
                        <div class="col">
                            <div v-if="!contract.second_family_member_id">
                                {{ __('common:second_parent') }}
                            </div>
                            <div v-else>
                                {{ getFamilyMemberNameById(contract.second_family_member_id) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-row">
                        <div class="col">
                            <label
                                class="label"
                                for="first_salary"
                            >
                                {{ __('family_contract:annual_earning') }}
                            </label>
                            <input
                                id="first_salary"
                                v-model.number="contract.first_salary"
                                class="form-control"
                                :disabled="disabled || !$can('update', 'kids_contracts')"
                                :placeholder="__('family_contract:annual_earning')"
                                type="text"
                            />
                        </div>
                        <div class="col">
                            <label
                                class="label"
                                for="second_salary"
                            >
                                {{ __('family_contract:annual_earning') }}
                            </label>
                            <input
                                id="second_salary"
                                v-model.number="contract.second_salary"
                                class="form-control"
                                :disabled="disabled || !$can('update', 'kids_contracts')"
                                :placeholder="__('family_contract:annual_earning')"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {nursery} from '@/modules/legacy/mixins/nursery';

    export default {
        mixins: [nursery],
        props: ['nursery', 'kid', 'contract'],
        data: () => ({
            tab: 'cafpro',
            showDetail: false,
            disabled: false,
        }),
        computed: {
            firstParent() {
                const member = this.kid.familyMembers.find(item => item.id === this.contract.first_family_member_id);

                return member ? member : null;
            },
            secondParent() {
                const member = this.kid.familyMembers.find(item => item.id === this.contract.second_family_member_id);

                return member ? member : null;
            },
        },
        mounted() {
            this.contract.cafpro_resources !== null ? (this.showDetail = false) : (this.showDetail = true);
        },
        methods: {
            getFamilyMemberNameById(id) {
                const familyMember = this.kid.familyMembers.find(item => item.id === id);

                return familyMember ? familyMember.first_name + ' ' + familyMember.last_name : null;
            },
            showCAF() {
                return this.$showCAF(this.nursery.country);
            },
        },
    };
</script>
