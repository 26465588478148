import type TemplateModel from '@/modules/human-resources/models/TemplateModel';
import type {WeeksDto} from '@/modules/human-resources/apply-template/application/dto/WeeksDto';
import type TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import type {Collection} from '@meekohq/lumos';
import {Binding} from '@meekohq/lumos';
import type InvalidEvent from '@/modules/human-resources/apply-template/domain/InvalidEvent';

export const ApplyTemplateUseCaseBinding = new Binding<ApplyTemplateUseCase>();

export interface ApplyTemplateUseCase {
    applyTemplate(
        template: TemplateModel,
        weeks: WeeksDto,
        deleteRealEvents: boolean,
        fallbackOrganizationId: string
    ): Promise<Collection<InvalidEvent>>;

    applyTemplatePlanning(
        planning: TemplatePlanningModel,
        weeks: WeeksDto,
        deleteRealEvents: boolean,
        fallbackOrganizationId: string
    ): Promise<Collection<InvalidEvent>>;
}
