<template>
    <component :is="as">
        <!-- LOADING -->
        <CCenter
            v-if="loading"
            class="tw-w-2/3"
        >
            <CVStack
                v-if="showHalfDay"
                gap="2"
            >
                <CSkeletonItem
                    v-if="showRate"
                    class="tw-h-5 tw-w-full"
                />
                <CHStack
                    v-if="showCount"
                    gap="2"
                >
                    <CSkeletonItem class="tw-h-5 tw-w-10" />
                    <CSkeletonItem class="tw-h-5 tw-w-10" />
                </CHStack>
            </CVStack>
            <CHStack
                v-else
                gap="2"
            >
                <CSkeletonItem
                    v-if="showCount"
                    class="tw-h-5 tw-w-10"
                />
                <CSkeletonItem
                    v-if="showRate"
                    class="tw-h-5 tw-w-10"
                />
            </CHStack>
        </CCenter>

        <!-- CLOSED -->
        <CBadge
            v-else-if="getOccupationRate() == null && getFulldayCount() === null"
            :class="{'tw-mt-4': showHalfDay}"
        >
            {{ __('common:closed') }}
        </CBadge>

        <!-- HALFDAY -->
        <CVStack
            v-else-if="showHalfDay"
            gap="2"
        >
            <CBadge
                v-if="showRate"
                :variant="getOccupationColor(getOccupationRate())"
            >
                {{ getOccupationRate() }}%
            </CBadge>
            <CHStack
                v-if="showCount"
                gap="2"
            >
                <CBadge :variant="getDayKidsColor(getHalfdayCount(0))">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-child-reaching"
                    />{{ getHalfdayCount(0) }}
                </CBadge>
                <CBadge :variant="getDayKidsColor(getHalfdayCount(1))">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-child-reaching"
                    />{{ getHalfdayCount(1) }}
                </CBadge>
            </CHStack>
        </CVStack>

        <!-- FULLDAY -->
        <CHStack
            v-else
            gap="2"
        >
            <CBadge
                v-if="showRate"
                :variant="getOccupationColor(getOccupationRate())"
            >
                {{ getOccupationRate() }}%
            </CBadge>
            <CBadge
                v-if="showCount"
                :variant="getDayKidsColor(getFulldayCount())"
            >
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fa-solid fa-child-reaching"
                />{{ getFulldayCount() }}
            </CBadge>
        </CHStack>
    </component>
</template>

<script lang="ts">
    import {computed, defineComponent, type PropType, toRef} from 'vue';
    import type {Moment} from 'moment';
    import type {OccupationType} from '@/modules/planning/utils/core/LegacySummaryTypes';
    import useOccupationBadge from '@/modules/planning/composables/core/useOccupationBadge';
    import useOccupationColors from '@/modules/planning/composables/core/useOccupationColors';

    export default defineComponent({
        props: {
            day: {
                type: Object as PropType<Moment>,
                default: null,
            },
            occupations: {
                type: Array as PropType<OccupationType[]>,
                required: true,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            halfDay: {
                type: Boolean,
                default: false,
            },
            enableHalfDay: {
                type: Boolean,
                default: false,
            },
            enableCount: {
                type: Boolean,
                default: true,
            },
            enableRate: {
                type: Boolean,
                default: true,
            },
            as: {
                type: String,
                default: 'CCenter',
            },
        },
        setup(props) {
            const {
                userDaySeparator,
                userDisplayCount,
                userDisplayRate,
                getOccupationRate,
                getFulldayCount,
                getHalfdayCount,
            } = useOccupationBadge(toRef(props, 'occupations'), toRef(props, 'day'));

            const {getOccupationColor, getDayKidsColor} = useOccupationColors();

            const showHalfDay = computed(() => {
                return props.enableHalfDay && userDaySeparator.value === 'halfday';
            });
            const showCount = computed(() => {
                return props.enableCount && userDisplayCount.value;
            });
            const showRate = computed(() => {
                return props.enableRate && userDisplayRate.value;
            });

            return {
                showHalfDay,
                showCount,
                showRate,

                getOccupationRate,
                getFulldayCount,
                getHalfdayCount,
                getOccupationColor,
                getDayKidsColor,
            };
        },
    });
</script>
