<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {watch} from 'vue';
    import type {MDatePickerStoreType, OutputValueType} from '@/modules/meeko-ui/composables/useMDatePicker';
    import MDatePickerCalendar from '@/modules/meeko-ui/components/MDatePicker/MDatePickerCalendar.vue';
    import MDatePickerTime from '@/modules/meeko-ui/components/MDatePicker/MDatePickerTime.vue';
    import {isEqual} from 'lodash-es';
    import {useDateTimeModifiersPresets} from '@/modules/meeko-ui/composables/useDateTimeModifiers';

    const props = defineProps({
        store: {
            type: Object as PropType<MDatePickerStoreType>,
            required: true,
        },
        allowUndefined: {
            type: Boolean,
            default: true,
        },
    });

    const emit = defineEmits<{
        (e: 'input', value: OutputValueType): void;
    }>();

    const {
        isPeriod,
        setDate,
        dates,
        equalToDates,
        setFromDate,
        setToDate,
        withTime,
        calendarDate,
        outputDates,
        userValue,
    }: MDatePickerStoreType = props.store;

    const {startOfMinute} = useDateTimeModifiersPresets();

    watch(
        outputDates,
        () => {
            if (!isEqual(outputDates.value, userValue.value)) {
                emit('input', outputDates.value);
            }
        },
        {deep: true}
    );
</script>

<template>
    <div class="MDatePickerContent">
        <div
            v-if="isPeriod"
            class="MDatePickerContent__presets"
        >
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().startOfDay(),
                        Epoch.now().endOfDay()
                    ),
                }"
                @click="setDate(Epoch.now().startOfDay(), Epoch.now().endOfDay())"
            >
                {{ __('common:date_format.day_from_zero') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().subDays(1).startOfDay(),
                        Epoch.now().subDays(1).endOfDay()
                    ),
                }"
                @click="setDate(Epoch.now().subDays(1).startOfDay(), Epoch.now().subDays(1).endOfDay())"
            >
                {{ __('common:yesterday') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().addDays(1).startOfDay(),
                        Epoch.now().addDays(1).endOfDay()
                    ),
                }"
                @click="setDate(Epoch.now().addDays(1).startOfDay(), Epoch.now().addDays(1).endOfDay())"
            >
                {{ __('common:tomorrow') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().startOfWeek(),
                        Epoch.now().endOfWeek()
                    ),
                }"
                @click="setDate(Epoch.now().startOfWeek(), Epoch.now().endOfWeek())"
            >
                {{ __('common:this_week') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().subWeeks(1).startOfWeek(),
                        Epoch.now().subWeeks(1).endOfWeek()
                    ),
                }"
                @click="setDate(Epoch.now().subWeeks(1).startOfWeek(), Epoch.now().subWeeks(1).endOfWeek())"
            >
                {{ __('common:last_week') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().addWeeks(1).startOfWeek(),
                        Epoch.now().addWeeks(1).endOfWeek()
                    ),
                }"
                @click="setDate(Epoch.now().addWeeks(1).startOfWeek(), Epoch.now().addWeeks(1).endOfWeek())"
            >
                {{ __('common:next_week') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().startOfMonth(),
                        Epoch.now().endOfMonth()
                    ),
                }"
                @click="setDate(Epoch.now().startOfMonth(), Epoch.now().endOfMonth())"
            >
                {{ __('common:this_month') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().subMonths().startOfMonth(),
                        Epoch.now().subMonths().endOfMonth()
                    ),
                }"
                @click="setDate(Epoch.now().subMonths().startOfMonth(), Epoch.now().subMonths().endOfMonth())"
            >
                {{ __('common:last_month') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().addMonths().startOfMonth(),
                        Epoch.now().addMonths().endOfMonth()
                    ),
                }"
                @click="setDate(Epoch.now().addMonths().startOfMonth(), Epoch.now().addMonths().endOfMonth())"
            >
                {{ __('common:next_month') }}
            </button>
            <button
                v-if="allowUndefined"
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(undefined, undefined),
                }"
                @click="setDate(undefined, undefined)"
            >
                {{ __('common:all_time') }}
            </button>
        </div>
        <div
            v-else
            class="MDatePickerContent__presets"
        >
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(Epoch.now().startOfDay(), undefined),
                }"
                @click="setDate(Epoch.now().startOfDay(), undefined)"
            >
                {{ __('common:date_format.day_from_zero') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().subDays(1).startOfDay(),
                        undefined
                    ),
                }"
                @click="setDate(Epoch.now().subDays(1).startOfDay(), undefined)"
            >
                {{ __('common:yesterday') }}
            </button>
            <button
                class="MDatePickerContent__preset-button"
                :class="{
                    'MDatePickerContent__preset-button--active': equalToDates(
                        Epoch.now().addDays(1).startOfDay(),
                        undefined
                    ),
                }"
                @click="setDate(Epoch.now().addDays(1).startOfDay(), undefined)"
            >
                {{ __('common:tomorrow') }}
            </button>
        </div>
        <MDatePickerCalendar
            class="MDatePickerContent__calendar"
            :month="calendarDate"
            :store="store"
            @navigate="calendarDate = $event"
        />
        <div
            v-if="withTime && (dates.from || dates.to)"
            class="MDatePickerContent__time-picker"
        >
            <div class="MDatePickerContent__time-picker-box">
                <template v-if="dates.from">
                    <MHeading
                        class="tw-whitespace-nowrap tw-px-1 tw-pt-1"
                        level="h4"
                    >
                        {{ dates.from.toLocaleString(Epoch.presets.DATE_MED) }}
                    </MHeading>
                    <MDatePickerTime
                        :modifier="startOfMinute"
                        :only-time="false"
                        :value="dates.from"
                        @input="setFromDate($event)"
                    />
                </template>
            </div>
            <div
                v-if="isPeriod"
                class="MDatePickerContent__time-picker-box"
            >
                <template v-if="dates.to">
                    <MHeading
                        class="tw-whitespace-nowrap tw-px-1 tw-pt-1"
                        level="h4"
                    >
                        {{ dates.to.toLocaleString(Epoch.presets.DATE_MED) }}
                    </MHeading>
                    <MDatePickerTime
                        :modifier="startOfMinute"
                        :only-time="false"
                        :value="dates.to"
                        @input="setToDate($event)"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .MDatePickerContent {
        @apply tw-flex tw-gap-4;
    }

    .MDatePickerContent__presets {
        @apply tw-flex tw-min-w-24 tw-flex-col tw-gap-1;
    }

    .MDatePickerContent__preset-button {
        @apply tw-cursor-pointer tw-select-none tw-rounded tw-p-2 tw-py-1.5 tw-font-display tw-text-sm tw-text-gray-500;
        @apply tw-whitespace-nowrap tw-text-left tw-transition-all hover:tw-bg-primary-100 hover:tw-text-primary-600;
    }

    .MDatePickerContent__preset-button--active {
        @apply tw-bg-primary-100 tw-text-primary-600;
    }

    .MDatePickerContent__calendar {
        @apply tw-min-w-[252px] tw-flex-1;
    }

    .MDatePickerContent__time-picker {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .MDatePickerContent__time-picker-box {
        @apply tw-flex tw-h-1/2 tw-w-28 tw-flex-col tw-items-center tw-justify-between tw-gap-2 tw-rounded-lg tw-border tw-border-gray-200 tw-p-2;
    }
</style>
