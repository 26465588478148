import mitt from 'mitt';
import useUniqueComponentId from '@/modules/app/composables/useUniqueComponentId';
import {ref} from 'vue';

export interface PopoverEventType {
    show: number | undefined;
    shown: void;
    hide: number | undefined;
    hidden: void;
    toggle: number | undefined;
    clickOutside: void;
    forceUpdate: void;
}

export type PopoverType = ReturnType<typeof usePopover>;

function usePopover() {
    const id = useUniqueComponentId();

    const bus = mitt<PopoverEventType>();

    function show(delay?: number) {
        bus.emit('show', delay);
    }

    function hide(delay?: number) {
        bus.emit('hide', delay);
    }

    function toggle(delay?: number) {
        bus.emit('toggle', delay);
    }

    const isVisible = ref(false);

    bus.on('shown', () => (isVisible.value = true));
    bus.on('hidden', () => (isVisible.value = false));

    return {
        id,
        bus,
        show,
        hide,
        toggle,
        isVisible,
    };
}

export default usePopover;
