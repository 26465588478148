import {Model} from '@meekohq/lumos';
import moment from 'moment';
import ContractModel from '@/modules/human-resources/models/ContractModel';
import StaffsOrganizationsPivot from '@/modules/human-resources/models/StaffsOrganizationsPivot';
import TeamStaffPivot from '@/modules/human-resources/models/TeamStaffPivot';
import TeamModel from '@/modules/human-resources/models/TeamModel';
import EventModel from '@/modules/human-resources/models/EventModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import TagPivot from '@/modules/tag/models/TagPivot';
import TagModel from '@/modules/tag/models/TagModel';
import DocumentModel from '@/modules/document/models/DocumentModel';
import DocumentPivot from '@/modules/document/models/DocumentPivot';
import UserModel from '@/modules/user/models/UserModel';

export default class StaffModel extends Model {
    public type = 'hr/staffs';

    public attributes: {
        id: string;
        account_id: string | undefined;

        first_name: string | null | undefined;
        last_name: string | null | undefined;
        maiden_name: string | undefined;
        gender: string | null | undefined;
        email: string | null | undefined;
        nationality: string | undefined;
        birthdate: string | null | undefined;
        birth_place: string | undefined;
        biography: string | null | undefined;
        job: string | null | undefined;
        phone: string | null | undefined;
        mobile_phone: string | null | undefined;
        note: string | null | undefined;
        no: string | null | undefined;
        code: string | null | undefined;
        avatar: string | null | undefined;
        social_security_number: string | undefined;

        address: {
            line1: string | null | undefined;
            line2: string | null | undefined;
            postal_code: string | null | undefined;
            city: string | null | undefined;
            country_code: string | null | undefined;
            state: string | null | undefined;
        };
    } = {
        id: this.uuid(),
        account_id: undefined,

        first_name: undefined,
        last_name: undefined,
        maiden_name: undefined,
        gender: undefined,
        email: undefined,
        nationality: undefined,
        birthdate: undefined,
        birth_place: undefined,
        biography: undefined,
        job: undefined,
        phone: undefined,
        mobile_phone: undefined,
        note: undefined,
        no: undefined,
        code: undefined,
        avatar: undefined,
        social_security_number: undefined,

        address: {
            line1: null,
            line2: null,
            postal_code: null,
            city: null,
            country_code: null,
            state: null,
        },
    };

    public computed: {
        avatar_url: string | null;
    } = {
        avatar_url: null,
    };

    get fullname(): string {
        return this.attributes.first_name + ' ' + this.attributes.last_name;
    }

    get genderColor(): string {
        if (!this.attributes.gender) {
            return 'tw-text-teal-500';
        }

        return this.attributes.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl';
    }

    contracts() {
        return this.hasMany('contracts', ContractModel, 'staff_id');
    }

    activeContract(from: string | undefined = moment().format(), to: string | undefined) {
        let currentContract: ContractModel | null = null;

        // Search in range
        if (to) {
            // Range search in broken contracts
            const brokenContracts = this.contracts()
                .value()
                .filter(contract => contract.attributes.broked_at !== null);
            currentContract = brokenContracts.first(
                contracts =>
                    moment(contracts.attributes.started_at).isBetween(from, to, 'day', '[]') ||
                    moment(contracts.attributes.broked_at).isBetween(from, to, 'day', '[]') ||
                    (moment(contracts.attributes.broked_at).isSameOrAfter(to, 'day') &&
                        moment(contracts.attributes.started_at).isSameOrBefore(from, 'day'))
            );

            if (!currentContract) {
                // No contract found, search in unbroken contracts
                const unbrokenContracts = this.contracts()
                    .value()
                    .filter(contract => contract.attributes.broked_at === null);
                currentContract = unbrokenContracts.first(
                    contracts =>
                        moment(contracts.attributes.started_at).isBetween(from, to, 'day', '[]') ||
                        moment(contracts.attributes.ended_at).isBetween(from, to, 'day', '[]') ||
                        ((contracts.attributes.ended_at === null ||
                            moment(contracts.attributes.ended_at).isSameOrAfter(to, 'day')) &&
                            moment(contracts.attributes.started_at).isSameOrBefore(from, 'day'))
                );
            }
            // Search only from start date
        } else {
            // Search contracts without end but start is before selected date
            const contractsWithoutEnd = this.contracts()
                .value()
                .filter(contract => contract.attributes.ended_at === null && contract.attributes.broked_at === null);
            currentContract = contractsWithoutEnd.first(contract =>
                moment(contract.attributes.started_at).isSameOrBefore(from, 'day')
            );

            if (!currentContract) {
                // Search contracts with end (or broken) where selected date is between
                const contractsWithEnd = this.contracts()
                    .value()
                    .filter(
                        contract => contract.attributes.ended_at !== null || contract.attributes.broked_at !== null
                    );
                currentContract = contractsWithEnd.first(contract =>
                    moment(from).isBetween(
                        contract.attributes.started_at,
                        contract.attributes.broked_at ? contract.attributes.broked_at : contract.attributes.ended_at,
                        'day',
                        '[]'
                    )
                );
            }
        }

        return currentContract;
    }

    events() {
        return this.hasMany('events', EventModel, 'staff_id');
    }

    tags() {
        return this.morphToMany('tags', TagModel, TagPivot, 'resource', undefined, 'tag_id');
    }

    tagsPivots() {
        return this.hasMany('tagsPivots', TagPivot, 'resource_id').setQuery(query => {
            query.where('resource_type', '=', this.type);
        });
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', StaffsOrganizationsPivot, 'staff_id');
    }

    teams() {
        return this.belongsToMany('teams', TeamModel, TeamStaffPivot, 'staff_id', 'team_id');
    }

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            StaffsOrganizationsPivot,
            'staff_id',
            'organization_id'
        );
    }

    balanceAllocations() {
        return this.hasMany('balanceAllocations', BalanceAllocationModel, 'staff_id');
    }

    documents() {
        return this.morphToMany('documents', DocumentModel, DocumentPivot, 'resource', undefined, 'document_id');
    }

    user() {
        return this.hasOne('user', UserModel, 'email', 'email');
    }
}
