import type {InvoiceAggregate} from '@/modules/cashier/invoice/domain/InvoiceAggregate';
import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
import type {UpdateInvoiceInputPort} from '@/modules/cashier/invoice/domain/use-cases/UpdateInvoiceInputPort';
import type {UpdateInvoiceOutputPort} from '@/modules/cashier/invoice/domain/use-cases/UpdateInvoiceOutputPort';
import {InvoiceTotalExceedsLimitError} from '@/modules/cashier/invoice/domain/errors/InvoiceTotalExceedsLimitError';
import {InvoiceLinePriceExceedsLimitError} from '@/modules/cashier/invoice/domain/errors/InvoiceLinePriceExceedsLimitError';

export class UpdateInvoiceInteractor implements UpdateInvoiceInputPort {
    constructor(private readonly presenter: UpdateInvoiceOutputPort) {}

    public update(invoiceAggregate: InvoiceAggregate, invoiceChanged: LegacyInvoiceType): unknown {
        try {
            invoiceAggregate.invoice = invoiceChanged;
            return this.presenter.updated(invoiceAggregate);
        } catch (error) {
            if (error instanceof InvoiceTotalExceedsLimitError || error instanceof InvoiceLinePriceExceedsLimitError) {
                return this.presenter.domainError(error);
            }

            return this.presenter.error(error);
        }
    }
}
