<script setup lang="ts">
    import FeesInput from '@/modules/cashier/components/settings/RulesCards/FeesInput.vue';
    import {getNumericParserContractPresetCurrency} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import i18next from 'i18next';

    defineProps({
        nursery: {
            type: Object,
            required: true,
        },
        fee: {
            type: Object,
            required: true,
        },
    });

    const parserOptionsCurrency = getNumericParserContractPresetCurrency(i18next.language);
    parserOptionsCurrency.allowNegative = true;
</script>

<template>
    <div>
        <div class="tw-mb-4 tw-grid tw-grid-cols-4 tw-gap-4">
            <FeesInput
                class="tw-col-span-full md:tw-col-span-2"
                :fee="fee"
                @removeFees="$emit('removeFees', fee)"
            />
        </div>
        <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-4">
            <CFormGroup>
                <CLabel>
                    {{ __('common:hygiene_types.diaper') }}
                </CLabel>
                <MCurrencyInput
                    v-model="fee.amount.baby"
                    :currency-symbol="nursery.currency"
                    :parser-options="parserOptionsCurrency"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    {{ __('common:learning') }}
                </CLabel>
                <MCurrencyInput
                    v-model="fee.amount.mixed"
                    :currency-symbol="nursery.currency"
                    :parser-options="parserOptionsCurrency"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel class="tw-shrink-0">
                    {{ __('common:hygiene_types.potty') }}
                </CLabel>
                <MCurrencyInput
                    v-model="fee.amount.potty"
                    :currency-symbol="nursery.currency"
                    :parser-options="parserOptionsCurrency"
                />
            </CFormGroup>
        </div>
    </div>
</template>
