import {computed, ref, watch} from 'vue';
import _clone from 'lodash-es/clone';
import _cloneDeep from 'lodash-es/cloneDeep';
import _isEqual from 'lodash/isEqual';
import useManager from '@/modules/app/composables/useManager';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import {FamilyAppSettingsEntity} from '@/modules/meeko-family/domain/entities/FamilyAppSettingsEntity';

export function useFamilyAppSettingsState() {
    const {activeOrganization} = useManager();

    const familyAppSettings = ref(getFamilyAppSetting(activeOrganization.value));
    const originalSettings = ref(_cloneDeep(familyAppSettings.value));

    const updated = computed(() => !_isEqual(originalSettings.value, familyAppSettings.value));

    function getFamilyAppSetting(organization: OrganizationModel) {
        return new FamilyAppSettingsEntity(
            _clone(organization.getKey()),
            _clone(organization.attributes.second_phone),
            _clone(organization.attributes.second_email),
            _clone(organization.attributes.website),
            _clone(organization.attributes.display_staff_last_name_on_family)
        );
    }

    function resetOriginalSettings() {
        originalSettings.value = _cloneDeep(familyAppSettings.value);
    }

    // Remove empty strings
    watch(
        familyAppSettings,
        value => {
            ['email', 'phone', 'website'].forEach(attribute => {
                if (value[attribute]?.trim().length === 0) {
                    value[attribute] = null;
                }
            });
        },
        {deep: true}
    );

    return {
        familyAppSettings,
        resetOriginalSettings,
        updated,
    };
}
