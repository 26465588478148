import {ServiceProvider} from '@meekohq/lumos';
import Vue from 'vue';

export default class MeekoUIServiceProvider extends ServiceProvider {
    public boot() {
        // Elements
        Vue.component('MBadge', () => import('./components/Elements/MBadge.vue'));
        Vue.component('MMenu', () => import('./components/Elements/MMenu/MMenu.vue'));
        Vue.component('MSubMenu', () => import('./components/Elements/MMenu/MSubMenu.vue'));
        Vue.component('MMenuButton', () => import('./components/Elements/MMenu/MMenuButton.vue'));
        Vue.component('MMenuItems', () => import('./components/Elements/MMenu/MMenuItems.vue'));
        Vue.component('MMenuItem', () => import('./components/Elements/MMenu/MMenuItem.vue'));
        Vue.component('MMenuDivider', () => import('./components/Elements/MMenu/MMenuDivider.vue'));
        Vue.component('MMenuOverlay', () => import('./components/Elements/MMenu/MMenuOverlay.vue'));
        Vue.component('MAvatar', () => import('./components/Elements/MAvatar.vue'));
        Vue.component('MAvatarEditor', () => import('./components/Elements/MAvatarEditor.vue'));
        Vue.component('MButton', () => import('./components/Elements/MButton.vue'));

        // Forms
        Vue.component('MLabel', () => import('../meeko-ui/components/Forms/MLabel.vue'));

        /**
         * COMPONENTS NOT VALIDATED YET :
         */

        // Legacy
        Vue.component('CModal', () => import('./components/MModal.vue'));
        Vue.component('CPopover', () => import('./components/CPopover.vue'));
        Vue.component('CTooltip', () => import('./components/MTooltip.vue'));
        Vue.component('CButton', () => import('./components/Elements/MButton.vue'));
        Vue.component('CInput', () => import('./components/MInput.vue'));
        Vue.component('CSkeletonItem', () => import('./components/MSkeleton.vue'));
        Vue.component('CModalPortal', () => import('./components/MPortal.vue'));
        Vue.component('CDropdown', () => import('./components/MDropdown.vue'));
        Vue.component('CBadge', () => import('./components/Elements/MBadge.vue'));
        Vue.component('CLabel', () => import('../meeko-ui/components/Forms/MLabel.vue'));

        // Layout
        Vue.component('MBox', () => import('./components/MBox.vue'));

        // Typography
        Vue.component('MPageTitle', () => import('./components/MPageTitle.vue'));
        Vue.component('MHeading', () => import('./components/MHeading.vue'));
        Vue.component('MClamp', () => import('./components/MClamp.vue'));

        // Overlay
        Vue.component('MPortal', () => import('./components/MPortal.vue'));
        Vue.component('MModal', () => import('./components/MModal.vue'));
        Vue.component('MTooltip', () => import('./components/MTooltip.vue'));
        Vue.component('MDropdown', () => import('./components/MDropdown.vue'));
        Vue.component('MHelpTooltip', () => import('./components/MHelpTooltip.vue'));

        Vue.component('MPopover', () => import('./components/MPopover.vue'));
        Vue.component('MPopoverButton', () => import('./components/MPopoverButton.vue'));
        Vue.component('MPopoverPanel', () => import('./components/MPopoverPanel.vue'));
        Vue.component('MPopoverOverlay', () => import('./components/MPopoverOverlay.vue'));

        Vue.component('MTabs', () => import('./components/MTabs.vue'));
        Vue.component('MTabsItem', () => import('./components/MTabsItem.vue'));

        // Forms
        Vue.component('MButtonSelect', () => import('./components/MButtonSelect.vue'));
        Vue.component('MInput', () => import('./components/MInput.vue'));
        Vue.component('MNumberInput', () => import('./components/MNumberInput.vue'));
        Vue.component('MInputGroup', () => import('./components/MInputGroup.vue'));
        Vue.component('MTimePicker', () => import('./components/MTimePicker.vue'));
        Vue.component('MTimePeriodPicker', () => import('./components/MTimePeriodPicker.vue'));
        Vue.component('MDateTimePicker', () => import('./components/MDateTimePicker.vue'));
        Vue.component('MDateTimePeriodPicker', () => import('./components/MDateTimePeriodPicker.vue'));
        Vue.component('MDatePicker', () => import('./components/MDatePicker/MDatePicker.vue'));
        Vue.component('MDatePeriodPicker', () => import('./components/MDatePeriodPicker.vue'));
        Vue.component('MMonthPicker', () => import('./components/MMonthPicker.vue'));
        Vue.component('MMonthPeriodPicker', () => import('./components/MMonthPeriodPicker.vue'));
        Vue.component('MYearPicker', () => import('./components/MYearPicker.vue'));
        Vue.component('MYearPeriodPicker', () => import('./components/MYearPeriodPicker.vue'));
        Vue.component('MCurrencyInput', () => import('./components/MCurrencyInput.vue'));

        // StepPanel
        Vue.component('MStepPanel', () => import('./components/MStepPanel/MStepPanel.vue'));
        Vue.component('MStepPanelContent', () => import('./components/MStepPanel/MStepPanelContent.vue'));
        Vue.component('MStepPanelHeader', () => import('./components/MStepPanel/MStepPanelHeader.vue'));
        Vue.component('MStepPanelHeaderItem', () => import('./components/MStepPanel/MStepPanelHeaderItem.vue'));

        // Feedback
        Vue.component('MSkeleton', () => import('./components/MSkeleton.vue'));
        Vue.component('MHoverable', () => import('./components/MHoverable.vue'));

        // Others
        Vue.component('MPromised', () => import('./components/MPromised.vue'));
        Vue.component('MSettingsButton', () => import('./components/MSettingsButton.vue'));

        // Icons
        Vue.component('MIconDownload', () => import('./components/MIconDownload.vue'));
        Vue.component('MIconEdit', () => import('./components/MIconEdit.vue'));
        Vue.component('MIconDelete', () => import('./components/MIconDelete.vue'));

        // Errors
        Vue.component('MErrorMessage', () => import('./components/MErrorMessage.vue'));
        Vue.component('MErrorMessageList', () => import('./components/MErrorMessageList.vue'));
    }
}
