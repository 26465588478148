import {Model} from '@meekohq/lumos';
import moment from 'moment';
import {minutesToHours} from '@/modules/legacy/libs/planning/planning';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import type ICanBeSplitted from '@/modules/human-resources/utils/calendar/ICanBeSplitted';
import type SplittableEvent from '@/modules/human-resources/utils/calendar/SplittableEvent';
import SplittableEventBuilder from '@/modules/human-resources/utils/calendar/Builders/SplittableEventBuilder';
import {isInteger} from 'lodash-es';

export default class TemplateEventModel extends Model implements ICanBeSplitted {
    public type = 'hr/calendar/template/events';

    public attributes: {
        id: string;
        account_id: string | undefined;
        organization_id: string | null | undefined;
        kids_group_id: string | null | undefined;
        planning_template_id: string | null | undefined;
        type_id: string | undefined;
        day: number | null | undefined;
        week: number | null | undefined;
        start_time: number | undefined;
        timelapse: number | undefined;
        note: string | null | undefined;
        forecast: boolean | undefined;
        supervise_kid: boolean | null | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        organization_id: undefined,
        kids_group_id: undefined,
        planning_template_id: undefined,
        type_id: undefined,
        day: undefined,
        week: undefined,
        start_time: undefined,
        timelapse: undefined,
        note: undefined,
        forecast: undefined,
        supervise_kid: undefined,
    };

    get startedAt() {
        return this.getFakeDay().add(this.attributes.start_time, 'seconds');
    }

    set startedAt(val) {
        this.attributes.day = moment(val).day();
        this.attributes.start_time = moment(val).hours() * 3600 + moment(val).minutes() * 60;
    }

    get endedAt() {
        return this.getFakeDay().add((this.attributes.start_time ?? 0) + (this.attributes.timelapse ?? 0), 'seconds');
    }

    set endedAt(val) {
        this.attributes.timelapse = moment(val).diff(this.startedAt, 'seconds');
    }

    get isSameDay(): boolean {
        return moment(this.startedAt).isSame(this.endedAt, 'day');
    }

    get duration(): number {
        return this.endedAt.unix() - this.startedAt.unix();
    }

    get readableHours(): string {
        return minutesToHours(this.duration / 60);
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }

    eventType() {
        return this.belongsTo('eventType', EventTypeModel, 'type_id');
    }

    kidsGroup() {
        return this.belongsTo('group', KidsGroupModel, 'kids_group_id');
    }

    mapToSplittableEvent(): SplittableEvent {
        return SplittableEventBuilder.fromTemplateEvent(this);
    }

    /**
     * Sunday is considered by moment the day 0, we add or subtract one week to get a full week starting monday
     * to sunday for the current week.
     * @private
     */
    private getFakeDay() {
        const day = isInteger(this.attributes.day) ? (this.attributes.day as number) : 1;

        // If the current day is not sunday and the event day is sunday we add one week to get the current week sunday
        if (moment().day() !== 0 && day === 0) {
            return moment().add(1, 'week').day(day).startOf('day');
        }

        // If the current day is sunday and the event day is different than sunday, we substract one week to get the current week day
        if (moment().day() === 0 && day !== 0) {
            return moment().subtract(1, 'week').day(day).startOf('day');
        }

        // Otherwise, we don't change the week, the current day is not sunday and day is not sunday or the current day
        // is sunday and day is also sunday
        return moment().day(day).startOf('day');
    }
}
