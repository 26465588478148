import {app} from '@meekohq/lumos';
import {SudoModeUseCaseBinding} from '@/modules/auth/sudo-mode/application/SudoModeUseCase';
import useModal from '@/modules/app/composables/useModal';
import SudoModeModal from '@/modules/auth/sudo-mode/infrastructure/components/SudoModeModal.vue';

export type AcquireSudoModeResult = AcquireSudoModeResultAcquiredType | AcquireSudoModeResultDismissedType;
export interface AcquireSudoModeResultAcquiredType {
    isAcquired: true;
    isDismissed: false;
    token: string;
}
export interface AcquireSudoModeResultDismissedType {
    isAcquired: false;
    isDismissed: true;
}

export const useSudoMode = () => {
    async function acquireSudoMode(): Promise<AcquireSudoModeResult> {
        const existingToken = app(SudoModeUseCaseBinding).getSudoModeToken();

        if (existingToken) {
            return {
                isAcquired: true,
                isDismissed: false,
                token: existingToken,
            };
        }

        const newToken = await openSudoModeModal();
        if (!newToken) {
            return {
                isAcquired: false,
                isDismissed: true,
            };
        }

        return {
            isAcquired: true,
            isDismissed: false,
            token: newToken,
        };
    }

    async function openSudoModeModal() {
        return new Promise<string | null>(resolve => {
            useModal({
                component: SudoModeModal,
                listeners: modal => ({
                    confirmed(token: string) {
                        resolve(token);
                        modal.hide();
                    },
                    dismissed() {
                        resolve(null);
                        modal.hide();
                    },
                }),
            }).show();
        });
    }

    return {
        acquireSudoMode,
    };
};
