import __ from '@/modules/app/utils/i18n-facade';
import {app} from '@meekohq/lumos';
import {NumericFormatterContractBinding} from '@/modules/core/infrastructure/NumericFormatterContract';
import i18next from 'i18next';

export default function (quantity: number, unit: string) {
    const numericFormatter = app(NumericFormatterContractBinding, {
        locale: i18next.language,
        minimumFractionDigits: 2,
    });

    const quantityFormatted = numericFormatter.formatNumber(quantity);

    switch (unit) {
        case 'hour':
            return __('billing_invoice:quantity_unit_hour', {qtyString: quantityFormatted, count: quantity});
        case 'day':
            return __('billing_invoice:quantity_unit_day', {qtyString: quantityFormatted, count: quantity});
        case 'halfday':
            return __('billing_invoice:quantity_unit_halfday', {qtyString: quantityFormatted, count: quantity});
        case 'pack':
            return __('billing_invoice:quantity_unit_pack', {qtyString: quantityFormatted, count: quantity});
        case 'unit':
            return __('billing_invoice:quantity_unit', {qtyString: quantityFormatted, count: quantity});
        default:
            return quantityFormatted;
    }
}
