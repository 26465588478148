import type {Ref} from 'vue';
import type RuleSet from '@/modules/calendar/utils/RuleSet/RuleSet';
import Rule from '@/modules/calendar/utils/RuleSet/Rule';

export default function (ruleSet: Ref<RuleSet>) {
    function updateRuleSet(changes) {
        const rulesetCopy = ruleSet.value.copy();

        Object.keys(changes).forEach(change => {
            rulesetCopy[change] = changes[change];

            // If ruleset dtstart or until changed, apply this changes on all rules of ruleset
            if (['dtstart', 'until'].includes(change)) {
                rulesetCopy.rules = rulesetCopy.rules.map(rule => {
                    rule[change] = changes[change];

                    return rule;
                });
            }
        });

        ruleSet.value = rulesetCopy;
    }

    function updateRuleByIndex(ruleIndex: number, changes) {
        const updatedRule = Rule.fromJSON(changes);

        const rules = [...ruleSet.value.rules];
        rules[ruleIndex] = updatedRule;

        updateRuleSet({rules});
    }

    function addRule(rule: Rule) {
        const rulesetCopy = ruleSet.value.copy();
        rulesetCopy.addRule(rule);

        ruleSet.value = rulesetCopy;
    }

    function removeRule(rule: Rule) {
        const rulesetCopy = ruleSet.value.copy();
        rulesetCopy.removeRule(rule);

        ruleSet.value = rulesetCopy;
    }

    return {
        addRule,
        updateRuleSet,
        updateRuleByIndex,
        removeRule,
    };
}
