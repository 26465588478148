<template>
    <div
        class="invoice-row tw-cursor-pointer tw-p-4"
        @click="showDetail = !showDetail"
    >
        <div class="row">
            <div class="col-6">
                <h6 class="h6 tw-mb-0 tw-mt-1">
                    <FontAwesomeIcon
                        v-if="invoice.type === 'company'"
                        class="tw-mr-1"
                        icon="fa-solid fa-city"
                    />
                    <FontAwesomeIcon
                        v-else
                        class="tw-mr-1"
                        icon="fa-solid fa-file"
                    />
                    {{ invoiceDate }}
                </h6>
            </div>
            <div class="col-6 tw-text-right">
                <strong>{{ formattedGrandTotal }}</strong>
                <MButton
                    class="tw-ml-12"
                    icon-menu
                    @click.stop="showDetail = !showDetail"
                >
                    {{ showDetail ? __('common:actions.close') : __('common:actions.detail') }}
                </MButton>
            </div>
        </div>
        <div
            v-if="showDetail"
            class="table-responsive tw-mt-4"
        >
            <table class="table tw-m-0">
                <thead>
                    <tr>
                        <th class="tw-text-left">
                            {{ __('billing_invoice:invoice_detail') }}
                        </th>
                        <th
                            v-if="withTva"
                            style="min-width: 70px"
                        >
                            {{ __('common:vat') }}
                        </th>
                        <th
                            class="tw-text-right"
                            style="min-width: 100px"
                        >
                            {{ __('billing_invoice:price_with_vat') }}
                        </th>
                        <th
                            class="tw-text-right"
                            style="min-width: 100px"
                        >
                            {{ __('billing_invoice:quantity') }}
                        </th>
                        <th
                            class="tw-text-right"
                            style="min-width: 100px"
                        >
                            {{ __('billing_invoice:total_with_vat') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(line, i) in formattedLines"
                        :key="i"
                        class="no-border"
                    >
                        <td class="table-name">
                            <invoice-type v-model="line.type" />
                            {{ line.name }}
                        </td>
                        <td
                            v-if="withTva"
                            class="table-tva tw-text-right"
                        >
                            {{ line.tva }}%
                        </td>
                        <td class="table-price tw-text-right">
                            {{ line.price }}
                        </td>
                        <td class="table-qty tw-text-right">
                            {{ line.unit }}
                        </td>
                        <td class="table-total tw-text-right">
                            {{ line.total }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="no-border">
                        <td
                            class="table-empty"
                            :colspan="withTva ? 3 : 2"
                        />
                        <th class="table-label">
                            <strong>{{ __('billing_invoice:total_with_vat') }}</strong>
                        </th>
                        <th class="table-amount tw-text-right">
                            <strong>{{ formattedGrandTotal }}</strong>
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script setup lang="ts">
    import InvoiceType from '@/modules/cashier/components/billing/invoice/InvoiceType.vue';
    import {Epoch} from '@meekohq/lumos';
    import {computed, ref} from 'vue';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import CurrencySymbolToIsoCode from '@/modules/cashier/utils/core/currency/CurrencySymbolToIsoCode';
    import humanizeQuantity from '@/modules/cashier/composables/billing/invoice/humanizeQuantity';

    const props = defineProps({
        invoice: {
            type: Object,
            required: true,
        },
        withTva: {
            type: Boolean,
            default: false,
        },
        organization: {
            type: Object,
            required: true,
        },
    });

    const showDetail = ref(false);
    const {format} = useFormatCurrency(CurrencySymbolToIsoCode[props.organization.currency]);

    const invoiceDate = computed(() => {
        return Epoch.parse(props.invoice.date, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT);
    });

    const formattedGrandTotal = computed(() => {
        return format(props.invoice.grand_total);
    });

    const formattedLines = computed(() => {
        return props.invoice.lines.map(line => {
            return {
                ...line,
                price: format(line.price, {minFractionDigits: 2}),
                unit: humanizeQuantity(line.qty, line.unit),
                total: format(line.total),
            };
        });
    });
</script>
