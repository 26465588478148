import type {Model, ModelAvailableAttributesType, QueryBuilderOrderByDirectionType} from '@meekohq/lumos';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';

export interface TicketFilterDtoMakeType {
    type: 'task' | 'note';
    assignedTo: StaffModel[];
    notAssigned: boolean;
    assignedToAnyone: boolean;
    reportedBy: StaffModel[];
    notReported: boolean;
    reportedByAnyone: boolean;
    assignedToOrReportedByConstrain: boolean;
    resource: Model;
    excludeResourceTypes: string[];
    isCompleted?: boolean;
    orderBy: ModelAvailableAttributesType<TransactionModel> | undefined;
    queryDirection: QueryBuilderOrderByDirectionType | undefined;
}

/**
 * TicketFilterDto is a data transfer object that is used to filter tickets query
 * So it does not reflect any business logic
 */
export default class TicketFilterDto {
    constructor(
        public readonly type?: 'task' | 'note' | undefined,
        public readonly assignedTo?: StaffModel[],
        public readonly notAssigned?: boolean,
        public readonly assignedToAnyone?: boolean,
        public readonly reportedBy?: StaffModel[],
        public readonly notReported?: boolean,
        public readonly reportedByAnyone?: boolean,
        public readonly assignedToOrReportedByConstrain?: boolean,
        public readonly resource?: Model,
        public readonly excludeResourceTypes?: string[],
        public readonly isCompleted?: boolean,
        public readonly orderBy: ModelAvailableAttributesType<TransactionModel> | undefined = 'expired_at',
        public readonly queryDirection: QueryBuilderOrderByDirectionType | undefined = 'asc'
    ) {}

    /**
     * Create a new instance of TicketFilterDto with the given values
     */
    public static make(values: Partial<TicketFilterDtoMakeType>): TicketFilterDto {
        return new TicketFilterDto(
            values.type,
            values.assignedTo,
            values.notAssigned,
            values.assignedToAnyone,
            values.reportedBy,
            values.notReported,
            values.reportedByAnyone,
            values.assignedToOrReportedByConstrain,
            values.resource,
            values.excludeResourceTypes,
            values.isCompleted,
            values.orderBy,
            values.queryDirection
        );
    }
}
