<template>
    <CForm
        class="EmailForm"
        @submit.prevent="sendResetLink"
    >
        <transition name="fade">
            <div
                v-if="hasError"
                class="EmailForm__error"
            >
                <ul>
                    <template v-for="error in errors">
                        <li :key="error.value">
                            {{ _head(error) }}
                        </li>
                    </template>
                </ul>
            </div>
        </transition>
        <CFormGroup class="EmailForm__text-container--is-bordered">
            {{ __('auth:select_email_to_start') }}
        </CFormGroup>
        <CFormGroup class="EmailForm__input-container">
            <FontAwesomeIcon
                class="EmailForm__input-icon"
                icon="fa-regular fa-user"
            />
            <input
                v-model="email"
                autofocus
                class="EmailForm__input"
                :placeholder="__('common:email_example')"
                required
                type="email"
            />
        </CFormGroup>
        <CFormGroup>
            <AuthButton
                :is-loading="isLoading"
                :label="__('common:actions.continue')"
                @click.native="sendResetLink"
            />
        </CFormGroup>
    </CForm>
</template>

<script>
    import _head from 'lodash-es/head';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import * as Alert from '@/modules/legacy/libs/Alert';
    import AuthButton from '@/modules/auth/components/atoms/AuthButton.vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        components: {AuthButton},
        data: () => ({
            isLoading: false,
            email: '',
            errors: [],
        }),
        computed: {
            hasError() {
                return Object.keys(this.errors).length > 0;
            },
            _head() {
                return _head;
            },
        },
        methods: {
            sendResetLink() {
                this.isLoading = true;

                const data = {
                    email: this.email,
                };

                useApi()
                    .legacy.post(route('password.email'), data)
                    .then(() => {
                        this.errors = [];

                        Alert.success({
                            showCloseButton: false,
                            allowOutsideClick: false,
                            title: __('auth:password_reset_email_sent'),
                            text: __('auth:email_with_instruction_sent_to_your_email_adress_etc', {email: this.email}),
                            confirmButtonText: __('common:understand'),
                        }).then(result => {
                            if (result.value) {
                                this.$router.push({name: 'login'});
                            }
                        });

                        this.isLoading = false;
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            this.errors = error.response.data.errors;
                        }

                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style scoped>
    .EmailForm {
        @apply tw-gap-0 tw-overflow-hidden tw-rounded-xl tw-bg-white tw-shadow-lg;
    }

    .EmailForm__error {
        @apply tw-rounded-lg tw-bg-red-100 tw-shadow-sm;
        @apply tw-m-4 tw-px-4 tw-py-2;
        @apply tw-text-left tw-font-semibold tw-text-red-500;
    }

    .EmailForm__text-container--is-bordered {
        @apply tw-text-left;
        @apply tw-px-4 tw-py-2;
        @apply tw-border-b-2 tw-border-dashed tw-border-primary-200;
    }

    .EmailForm__input-container {
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-px-4;
    }

    .EmailForm__input-icon {
        @apply tw-px-2 tw-text-2xl tw-text-primary-500;
    }

    .EmailForm__input {
        @apply tw-h-16 tw-w-full;
        @apply tw-tracking-tight tw-outline-none;
        @apply tw-px-4;
        @apply tw-text-2xl tw-text-primary-500 tw-antialiased;

        &::placeholder {
            @apply tw-font-grandhotel tw-text-4xl;
            @apply tw-text-primary-300;
        }
    }
</style>
