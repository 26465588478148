<template>
    <div>
        <NotificationsTabHeader
            class="tw-mb-4"
            :loading="loading"
            @save="save"
        />
        <div class="NotificationsTab__grid">
            <MBox
                v-for="notificationsGroup in defaultNotifications"
                :key="notificationsGroup.headerText"
                class="tw-p-4"
            >
                <NotificationsListItemHeader
                    class="tw-mb-4"
                    :select-all-broadcast="notificationsGroup.isAllViaBroadcastSelected"
                    :select-all-mail="notificationsGroup.isAllViaMailSelected"
                    @change:select-all-broadcast="
                        toggleAllNotificationsInGroup(notificationsGroup, 'viaBroadcast', $event)
                    "
                    @change:select-all-mail="toggleAllNotificationsInGroup(notificationsGroup, 'viaMail', $event)"
                >
                    {{ notificationsGroup.headerText }}
                </NotificationsListItemHeader>
                <div class="tw-flex tw-flex-col tw-gap-2">
                    <NotificationsListItem
                        v-for="notification in notificationsGroup.notificationsList"
                        :key="notification.value"
                        :notification="notification"
                        @change:via-broadcast="
                            notificationSettingChanged(notificationsGroup, notification, 'viaBroadcast', $event)
                        "
                        @change:via-mail="
                            notificationSettingChanged(notificationsGroup, notification, 'viaMail', $event)
                        "
                    />
                </div>
            </MBox>
            <MBox class="tw-p-4">
                <MHeading
                    class="tw-mb-4"
                    level="h3"
                >
                    {{ __('activity:show_notification_badge') }}
                </MHeading>
                <CHStack
                    align="center"
                    gap="2"
                >
                    <div class="tw-relative">
                        <MAvatar
                            class="tw-w-8"
                            fallback-user
                        />
                        <MNotificationBadge
                            class="tw-absolute tw--right-1 tw--top-1"
                            :count="2"
                        />
                    </div>
                    <MInput
                        v-model.number="taskExpiredDaysLimit"
                        class="tw-w-16"
                        max="180"
                        type="number"
                        @input="validateExpiredAtDateLimit($event)"
                    />
                    <CText>
                        {{ __('activity:days_before_due_date_without_count', {count: taskExpiredDaysLimit}) }}
                    </CText>
                </CHStack>
                <CCheckbox
                    v-model="showVaccines"
                    class="tw-mt-4"
                >
                    {{ __('activity:show_vaccine_reminders') }}
                </CCheckbox>
            </MBox>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref, watch} from 'vue';
    import {MqlTransaction} from '@meekohq/lumos';
    import type {NotificationGroup, NotificationType, ViaChannels} from '@/modules/user/utils/NotificationsTypes';
    import type UserModel from '@/modules/user/models/UserModel';
    import _flatten from 'lodash-es/flatten';
    import _debounce from 'lodash-es/debounce';
    import {ViaChannelsMappedToViaIsAllChecked} from '@/modules/user/utils/ViaChannelsMappedToViaIsAllChecked';
    import useNotifications from '@/modules/user/composables/useNotifications';
    import useAuth from '@/modules/app/composables/useAuth';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useNotificationStore from '@/modules/app/composables/useNotificationStore';
    import __ from '@/modules/app/utils/i18n-facade';
    import UserSettingModel from '@/modules/user/models/UserSettingModel';
    import MNotificationBadge from '@/modules/meeko-ui/components/MNotificationBadge.vue';
    import NotificationsTabHeader from '@/modules/user/components/NotificationsTabHeader.vue';
    import NotificationsListItem from '@/modules/user/components/NotificationsListItem.vue';
    import NotificationsListItemHeader from '@/modules/user/components/NotificationsListItemHeader.vue';

    export default defineComponent({
        components: {
            MNotificationBadge,
            NotificationsTabHeader,
            NotificationsListItem,
            NotificationsListItemHeader,
        },
        setup() {
            const {user} = useAuth();
            const loading = ref(false);

            const {success: toastSuccess} = useNotification();

            const {defaultNotifications} = useNotifications();
            const userNotifications = mapDefaultToUserNotificationsSettings(defaultNotifications.value, user.value);

            // Create a default value for the vaccines setting if it doesn't exist
            let vaccinesUserSetting = new UserSettingModel({
                account_id: user.value.attributes.account_id,
                user_id: user.value.getKey(),
                name: 'tasks:show_vaccines',
                value: false,
            });

            // Create a default value for the expired days limit setting if it doesn't exist
            let taskExpiredDaysLimitUserSetting = new UserSettingModel({
                account_id: user.value.attributes.account_id,
                user_id: user.value.getKey(),
                name: 'expired_days_limit',
                value: 3,
            });

            // Create a ref for the vaccines setting
            const showVaccines = ref<boolean>(vaccinesUserSetting.attributes.value);

            // Create a ref for the expired days limit setting
            const taskExpiredDaysLimit = ref(taskExpiredDaysLimitUserSetting.attributes.value);

            const {setShowVaccines, setTaskExpiredDaysLimit} = useNotificationStore();

            onMounted(async () => {
                const userSettings = await user.value.settings().load();

                // Get the vaccines setting if it exists, otherwise use the default value
                vaccinesUserSetting = userSettings.first(
                    setting => setting.attributes.name === 'tasks:show_vaccines',
                    vaccinesUserSetting
                );

                showVaccines.value = vaccinesUserSetting.attributes.value === true;

                // Get the expired days limit setting if it exists, otherwise use the default value
                taskExpiredDaysLimitUserSetting = userSettings.first(
                    setting => setting.attributes.name === 'expired_days_limit',
                    taskExpiredDaysLimitUserSetting
                );

                taskExpiredDaysLimit.value =
                    taskExpiredDaysLimitUserSetting.attributes.value != null
                        ? taskExpiredDaysLimitUserSetting.attributes.value
                        : 3;
            });

            watch(showVaccines, value => {
                // Update the model, cast value to string to conform to the expected type
                vaccinesUserSetting.attributes.value = value;
            });

            watch(taskExpiredDaysLimit, value => {
                // Update the model, cast value to string to conform to the expected type
                taskExpiredDaysLimitUserSetting.attributes.value = value;
            });

            /**
             * Update setting in notifications and check if notificationsGroup is all checked by via type
             * @param via
             * @param notification
             * @param notificationsGroup
             * @param value
             */
            function notificationSettingChanged(
                notificationsGroup: NotificationGroup,
                notification: NotificationType,
                via: ViaChannels,
                value: boolean
            ) {
                notification[via] = value;

                // Check if the "all selected" property for this channel should be changed after this notification update
                notificationsGroup[ViaChannelsMappedToViaIsAllChecked[via]] = areAllTypeNotificationsInGroupChecked(
                    notificationsGroup,
                    via
                );
            }

            /**
             * Check if notificationsGroup is all checked by via type
             * @param via
             * @param notificationsGroup
             * @returns true if all notifications are checked
             */
            function areAllTypeNotificationsInGroupChecked(notificationsGroup: NotificationGroup, via: ViaChannels) {
                return !notificationsGroup.notificationsList
                    .map((notification: NotificationType) => {
                        return notification[via];
                    })
                    .includes(false);
            }

            /**
             * Toggle all notifications in the group by via type
             * @param via
             * @param notificationsGroup
             * @param value
             */
            function toggleAllNotificationsInGroup(
                notificationsGroup: NotificationGroup,
                via: ViaChannels,
                value: boolean
            ) {
                notificationsGroup.notificationsList.forEach((notification: NotificationType) => {
                    notification[via] = value;
                });

                notificationsGroup[ViaChannelsMappedToViaIsAllChecked[via]] = value;
            }

            /**
             * Map the user preferences into the default notifications array
             *
             * @param defaultNotificationGroups
             * @param user
             */
            function mapDefaultToUserNotificationsSettings(
                defaultNotificationGroups: NotificationGroup[],
                user: UserModel
            ): NotificationGroup[] {
                return defaultNotificationGroups.map(notificationsGroup => {
                    notificationsGroup.notificationsList.forEach(notification => {
                        if (user.attributes.via_mail) {
                            notification.viaMail = user.attributes.via_mail.includes(notification.value);
                        }

                        if (user.attributes.via_broadcast) {
                            notification.viaBroadcast = user.attributes.via_broadcast.includes(notification.value);
                        }
                    });

                    const viaChannels: ViaChannels[] = ['viaMail', 'viaBroadcast'];
                    viaChannels.forEach(via => {
                        // For each via channel, assign to the group "all selected" property true or false if all notifications are
                        // present in the user settings for this channel
                        notificationsGroup[ViaChannelsMappedToViaIsAllChecked[via]] =
                            areAllTypeNotificationsInGroupChecked(notificationsGroup, via);
                    });

                    return notificationsGroup;
                });
            }

            /**
             * Save all checked notifications from all notifications groups to user notifications settings
             */
            async function save() {
                loading.value = true;

                const mandatoryNotifications = ['throttle_login'];

                const allNotifications = _flatten(
                    userNotifications.map(notificationsGroup => notificationsGroup.notificationsList)
                );

                const checkedViaMailNotificationsValues = allNotifications
                    .filter(notification => notification.viaMail)
                    .map(notification => notification.value);
                const checkedViaBroadcastNotificationsValues = allNotifications
                    .filter(notification => notification.viaBroadcast)
                    .map(notification => notification.value);

                user.value.attributes.via_mail = [...mandatoryNotifications, ...checkedViaMailNotificationsValues];
                user.value.attributes.via_broadcast = [
                    ...mandatoryNotifications,
                    ...checkedViaBroadcastNotificationsValues,
                ];

                const mqlRunner = new MqlTransaction();

                user.value.save({mqlRunner});

                vaccinesUserSetting.save({mqlRunner}).then(() => {
                    // Update the store when the user changes the value
                    setShowVaccines(vaccinesUserSetting.attributes.value === 'true');
                });

                taskExpiredDaysLimitUserSetting.save({mqlRunner}).then(() => {
                    // Update the store when the user changes the value
                    setTaskExpiredDaysLimit(parseInt(taskExpiredDaysLimitUserSetting.attributes.value as string));
                });

                await mqlRunner
                    .run(true)
                    .then(() => {
                        toastSuccess(__('notification:notification_saved_successfully'));
                    })
                    .finally(() => (loading.value = false));
            }

            // The function is debounced to allow sync between variable and input to work properly
            const validateExpiredAtDateLimit = _debounce(value => {
                let sanitizedValue = Number(value);

                if (Number.isNaN(sanitizedValue)) {
                    taskExpiredDaysLimit.value = 0;

                    return;
                }

                sanitizedValue = parseInt(sanitizedValue.toFixed());

                if (sanitizedValue > 180) {
                    sanitizedValue = 180;
                }

                taskExpiredDaysLimit.value = sanitizedValue;
            }, 10);

            return {
                loading,
                defaultNotifications,
                taskExpiredDaysLimit,
                notificationSettingChanged,
                toggleAllNotificationsInGroup,
                save,
                validateExpiredAtDateLimit,
                showVaccines,
                vaccinesUserSetting,
                taskExpiredDaysLimitUserSetting,
            };
        },
    });
</script>

<style scoped>
    .NotificationsTab__grid {
        @apply tw-grid tw-gap-4;
        @apply tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-3;
    }
</style>
