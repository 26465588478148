<template>
    <div>
        <div>
            <div class="row tw-mb-4">
                <div class="col-12 col-sm-4 tw-order-3 tw-mt-2 sm:tw-order-1 sm:tw-mt-0">
                    <form autocomplete="off">
                        <input
                            id="search"
                            v-model="search"
                            autocomplete="!off"
                            class="form-control form-control-sm"
                            :placeholder="__('common:search_dots')"
                            @input="onSearch"
                        />
                    </form>
                </div>
                <div class="col-12 col-sm-4 tw-order-2 tw-mt-2 sm:tw-order-2 sm:tw-mt-0">
                    <NewsTimeFrameFilter
                        v-model="publishFilter"
                        class="tw-w-full sm:tw-w-auto"
                        @input="onTimeFrameFilterChange"
                    />
                </div>
                <div
                    v-if="$can('create', 'posts')"
                    class="col-12 col-sm-4 tw-order-1 tw-ml-auto sm:tw-order-2 sm:tw-text-right"
                >
                    <button
                        class="btn btn-sm btn-primary"
                        @click="addPostModal"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-plus"
                        />{{ __('news:publish_news') }}
                    </button>
                </div>
            </div>
            <div
                v-if="!loading"
                class="row"
            >
                <div class="tw-w-full tw-p-4">
                    <transition-group
                        name="list"
                        tag="div"
                    >
                        <div
                            v-for="(post, i) in orderedPosts"
                            :key="'post' + post.id"
                            class="card tw-mb-4"
                        >
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-7">
                                        <MListItemTitle :title="post.title" />
                                    </div>
                                    <div class="col-12 col-md-5 tw-my-2 md:tw-mb-0 md:tw-mt-1 md:tw-text-right">
                                        <a
                                            v-if="$can('update', 'posts')"
                                            class="btn btn-sm btn-info tw-mr-2"
                                            href="#"
                                            @click="editPostModal(post)"
                                        >
                                            <FontAwesomeIcon
                                                class="tw-mr-2"
                                                icon="fa-solid fa-edit"
                                            />{{ __('common:actions.update') }}
                                        </a>
                                        <a
                                            v-if="$can('delete', 'posts')"
                                            class="btn btn-sm btn-danger"
                                            href="#"
                                            @click="deletePost(post, i)"
                                        >
                                            <FontAwesomeIcon
                                                class="tw-mr-2"
                                                icon="fa-solid fa-trash"
                                            />{{ __('common:actions.delete') }}
                                        </a>
                                    </div>
                                </div>
                                <div class="tw-flex tw-items-center tw-font-semibold tw-text-gray-500">
                                    <CTooltip
                                        class="tw-mr-1 tw-text-sm"
                                        placement="bottom-start"
                                    >
                                        <FontAwesomeIcon
                                            v-if="post.notified_at"
                                            class="tw-text-green-500"
                                            icon="fa-duotone fa-check-circle"
                                        />
                                        <FontAwesomeIcon
                                            v-else
                                            class="tw-text-gray-600"
                                            icon="fa-duotone fa-clock"
                                        />
                                        <template #content>
                                            <span v-if="post.notified_at">
                                                {{
                                                    __('news:send_at_with_date_female', {
                                                        date: Epoch.fromTimestamp(post.notified_at).toLocaleString(
                                                            Epoch.presets.DATE_SHORT
                                                        ),
                                                        time: Epoch.fromTimestamp(post.notified_at).toLocaleString(
                                                            Epoch.presets.TIME_SIMPLE
                                                        ),
                                                    })
                                                }}
                                            </span>
                                            <span v-else>
                                                {{ __('news:not_sent') }}
                                            </span>
                                        </template>
                                    </CTooltip>
                                    <template v-if="post.expire_at">
                                        {{
                                            __('common:date_format.human_from_to_short_with_time', {
                                                from: Epoch.fromTimestamp(post.posted_at).toLocaleString(
                                                    Epoch.presets.DATE_SHORT
                                                ),
                                                fromTime: Epoch.fromTimestamp(post.posted_at).toLocaleString(
                                                    Epoch.presets.TIME_SIMPLE
                                                ),
                                                to: Epoch.fromTimestamp(post.expire_at).toLocaleString(
                                                    Epoch.presets.DATE_SHORT
                                                ),
                                                toTime: Epoch.fromTimestamp(post.expire_at).toLocaleString(
                                                    Epoch.presets.TIME_SIMPLE
                                                ),
                                            })
                                        }}
                                    </template>
                                    <template v-else>
                                        {{
                                            __('common:date_format.date_with_time', {
                                                date: Epoch.fromTimestamp(post.posted_at).toLocaleString(
                                                    Epoch.presets.DATE_SHORT
                                                ),
                                                time: Epoch.fromTimestamp(post.posted_at).toLocaleString(
                                                    Epoch.presets.TIME_SIMPLE
                                                ),
                                            })
                                        }}
                                    </template>
                                </div>
                                <div
                                    v-if="post.web_access || post.parents_access || post.staffs_access"
                                    class="tw-text-gray-400"
                                >
                                    <span
                                        v-if="post.staffs_access"
                                        class="badge badge-primary tw-mr-1 tw-px-2 tw-py-1"
                                        >{{ __('common:for_team') }}
                                    </span>
                                    <span
                                        v-if="post.parents_access"
                                        class="badge badge-secondary tw-mr-1 tw-px-2 tw-py-1"
                                        >{{ __('common:for_parents') }}
                                    </span>
                                    <span
                                        v-if="post.web_access"
                                        class="badge badge-info tw-mr-1 tw-px-2 tw-py-1"
                                        >{{ __('common:website_meeko') }}
                                    </span>
                                </div>
                                <small
                                    v-else
                                    class="small tw-text-gray-400"
                                    >&nbsp;-&nbsp;{{ __('news:not_displayed_female') }}
                                </small>
                                <hr class="hr" />
                                <p
                                    class="trumbowyg-viewer tw-mb-0 tw-mt-4"
                                    v-html="post.content"
                                />
                            </div>
                        </div>
                    </transition-group>
                </div>
                <div
                    v-if="total > perPage"
                    class="tw-mt-4 tw-flex tw-w-full tw-justify-center"
                >
                    <MPagination
                        :paginator="paginator"
                        @navigate="onNavigate"
                    />
                </div>
                <add-post-modal
                    ref="addPostModal"
                    :current-post="currentPost"
                    modal-id="addPostModal"
                    :nursery="nursery"
                    :user="user"
                    @postEdited="editPost"
                    @postStored="addPost"
                />
            </div>
            <h1
                v-if="!Object.keys(posts).length"
                class="h1 tw-text-center tw-font-grandhotel"
            >
                {{ __('news:there_are_no_activities') }}
            </h1>
        </div>
        <div
            v-if="loading"
            key="loading"
        >
            <loader custom-class="la-2x" />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, {defineComponent} from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import _orderBy from 'lodash-es/orderBy';
    import _debounce from 'lodash-es/debounce';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import useApi from '@/modules/app/composables/useApi';
    import {Base64} from 'js-base64';
    import AddPostModal from './AddPostModal.vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import {Epoch, LengthAwarePaginator} from '@meekohq/lumos';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import {url} from '@/modules/legacy/mixins/url';
    import NewsTimeFrameFilter from '@/modules/news/components/NewsTimeFrameFilter.vue';

    export default defineComponent({
        components: {
            NewsTimeFrameFilter,
            MPagination,
            MListItemTitle,
            'add-post-modal': AddPostModal,
        },
        mixins: [url],
        props: ['nursery', 'user'],
        data: () => ({
            loading: 1,
            posts: [],
            currentPage: 1,
            total: 0,
            perPage: 20,
            search: null,
            publishFilter: [],
            currentPost: {},
            paginator: undefined,
        }),
        computed: {
            Epoch() {
                return Epoch;
            },
            orderedPosts() {
                const posts = [];
                this.posts.forEach(post => {
                    post.content = this.tryDecodeBase64(post.content);
                    posts.push(post);
                });

                return _orderBy(posts, 'posted_at', 'desc');
            },
        },
        watch: {
            paginator() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        mounted() {
            this.currentPage = this.$route.query.p || 1;
            this.search = localStorage.getItem('news:search');
            this.publishFilter = JSON.parse(localStorage.getItem('news:published') ?? '[]');
            this.getPosts();
        },
        methods: {
            getPosts() {
                this.loading = 1;
                useApi()
                    .legacy.get(route('posts.index'), {
                        params: {
                            nursery_id: this.nursery.id,
                            page: this.currentPage,
                            time_frame: this.publishFilter,
                            per_page: this.perPage,
                            search: this.search,
                        },
                    })
                    .then(response => {
                        this.posts = response.data.data;
                        this.total = response.data.total;
                        this.perPage = response.data.per_page;
                        this.paginator = new LengthAwarePaginator(
                            response.data.data,
                            parseInt(response.data.total),
                            parseInt(response.data.per_page),
                            parseInt(response.data.current_page),
                            parseInt(response.data.last_page)
                        );
                        this.loading = 0;
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            debounceGetPosts: _debounce(function () {
                this.getPosts();
            }, 500),
            onNavigate(page: number) {
                this.currentPage = page;
                this.getPosts();
            },
            onSearch() {
                this.currentPage = 1;
                localStorage.setItem('news:search', this.search);
                this.debounceGetPosts();
            },
            onTimeFrameFilterChange() {
                this.currentPage = 1;
                localStorage.setItem('news:published', JSON.stringify(this.publishFilter));
                this.getPosts();
            },
            addPostModal() {
                this.currentPost = {
                    id: null,
                    title: '',
                    content: '',
                    parents_access: 0,
                    web_access: 0,
                    staffs_access: 0,
                    posted_at: Epoch.now().toTimestamp(),
                    expire_at: null,
                    nurseries: [],
                };
                setTimeout(() => {
                    this.$refs.addPostModal.$refs.modal.show();
                }, 300);
            },
            addPost() {
                this.currentPage = 1;
                this.getPosts();
            },
            editPostModal(post) {
                this.currentPost = _cloneDeep(post);
                setTimeout(() => {
                    this.$refs.addPostModal.$refs.modal.show();
                }, 300);
            },
            editPost(post) {
                const postToEdit = this.posts.find(item => item.id === post.id);
                if (postToEdit) {
                    const index = this.posts.indexOf(postToEdit);
                    Vue.set(this.posts, index, post);
                }
            },
            deletePost(post, index) {
                useMagicModal().deleteConfirmationModal({
                    title: __('news:delete_news'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('posts.destroy', {
                                    post: post.id,
                                })
                            )
                            .then(() => {
                                const postToDelete = this.posts.find(item => item.id === post.id);
                                if (postToDelete) {
                                    index = this.posts.indexOf(postToDelete);
                                    this.posts.splice(index, 1);
                                    useNotification().success(__('news:news_deleted_successfully'));
                                }
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, function (value) {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            tryDecodeBase64(str: string) {
                return this.isBase64(str) ? Base64.decode(str) : str;
            },

            isBase64(str: string) {
                try {
                    const decodedStr = Base64.decode(str);

                    return window.btoa(unescape(encodeURIComponent(decodedStr))) === str;
                } catch (err) {
                    return false;
                }
            },
        },
    });
</script>

<style scoped>
    .avatar {
        float: left;
        margin-top: -8px;
        margin-right: 10px;
        margin-left: -10px;
        width: 80px;
        height: 80px;
    }

    .informations {
        margin: 0 -20px;
        background-color: #e3f2fd;
        padding: 8px 20px;
        color: #2b4c65;
    }

    .general-equipments {
        > div {
            display: inline-block;
            font-size: 0.8rem;
            text-align: center;
        }

        img {
            width: 50px;
            height: 50px;
        }
    }
</style>
