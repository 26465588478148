<template>
    <div>
        <MMenu
            arrow
            placement="left"
        >
            <MMenuButton>
                <MButton
                    icon-ellipsis
                    size="sm"
                    variant="ghost"
                />
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('common:actions.update')"
                    @click="showUpdateModal = true"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-pen"
                        />
                    </template>
                </MMenuItem>
                <template v-if="!eventType.attributes.internal_id">
                    <MMenuDivider />
                    <MMenuItem
                        v-if="!isPivotArchived"
                        :label="__('common:actions.archive')"
                        @click="toggleArchive()"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-archive"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-else
                        :label="__('common:actions.unarchive')"
                        @click="toggleArchive()"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-archive"
                            />
                        </template>
                    </MMenuItem>
                    <template v-if="user.is_owner && isUsedInAnotherOrganization">
                        <MMenuItem
                            v-if="!isPivotArchived"
                            :label="__('common:actions.archive_of_all_organizations')"
                            @click="toggleArchiveForAll"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-archive"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuItem
                            v-else
                            :label="__('common:actions.unarchive_of_all_organizations')"
                            @click="toggleArchiveForAll"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-archive"
                                />
                            </template>
                        </MMenuItem>
                    </template>
                    <MMenuItem
                        v-if="!hasEvents"
                        :label="__('common:actions.delete')"
                        variant="danger"
                        @click="deleteEventType()"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-trash"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="user.is_owner && isUsedInAnotherOrganization && !hasEvents"
                        :label="__('common:actions.delete_of_all_organizations')"
                        variant="danger"
                        @click="$refs.deleteModal.show()"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-trash"
                            />
                        </template>
                    </MMenuItem>
                </template>
            </MMenuItems>
        </MMenu>
        <UpdateEventTypeModal
            v-if="showUpdateModal"
            ref="updateEventTypeModal"
            :model="eventType"
            :shared="isUsedInAnotherOrganization"
            @hide="showUpdateModal = !showUpdateModal"
        />
        <DeleteEventTypeModal
            ref="deleteModal"
            :model="eventType"
            :shared="isUsedInAnotherOrganization"
            @deleted="$emit('deleted', $event)"
        />
    </div>
</template>

<script lang="ts">
    import moment from 'moment';
    import type EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import type EventTypeOrganizationModel from '@/modules/human-resources/models/EventTypeOrganizationModel';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import UpdateEventTypeModal from '@/modules/human-resources/components/event-type/Modals/UpdateEventTypeModal.vue';
    import DeleteEventTypeModal from '@/modules/human-resources/components/event-type/Modals/DeleteEventTypeModal.vue';
    import useAuth from '@/modules/app/composables/useAuth';

    export default defineComponent({
        components: {DeleteEventTypeModal, UpdateEventTypeModal},
        props: {
            eventType: {required: true, type: Object as PropType<EventTypeModel>},
            activePivot: {required: true, type: Object as PropType<EventTypeOrganizationModel>},
            isPivotArchived: {required: true, type: Boolean},
            isUsedInAnotherOrganization: {required: true, type: Boolean},
            hasEvents: {required: true, type: Boolean},
        },
        setup(props, {emit}) {
            const organizationsPivot = props.eventType.organizationsPivots().value();
            const {legacyUser: user} = useAuth();
            const showUpdateModal = ref(false);

            function toggleArchive() {
                const pivot = props.activePivot;
                toggleArchiveAndSavePivot(pivot);
            }

            function toggleArchiveForAll() {
                organizationsPivot.each(pivot => {
                    toggleArchiveAndSavePivot(pivot);
                });
            }

            function toggleArchiveAndSavePivot(pivot: EventTypeOrganizationModel) {
                if (pivot.attributes.archived_at === null) {
                    pivot.attributes.archived_at = moment().toISOString(true);
                } else {
                    pivot.attributes.archived_at = null;
                }
                pivot.save();
            }

            async function deleteEventType() {
                if (props.isUsedInAnotherOrganization) {
                    // delete pivot because its used in another organization
                    await props.activePivot.delete();
                } else {
                    // delete eventType because its used only in this organization
                    await props.eventType.delete();
                }
                emit('deleted', props.eventType);
            }

            return {
                user,
                toggleArchive,
                toggleArchiveForAll,
                deleteEventType,
                showUpdateModal,
            };
        },
    });
</script>
