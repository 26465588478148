<template>
    <div class="sticky-header">
        <div class="tw-flex tw-w-11/12 tw-items-center">
            <div class="tw-flex-1 tw-items-center tw-pr-2 tw-text-center">
                <CText font-weight="semibold">
                    {{ __('common:the_staff') }}
                </CText>
            </div>

            <div
                v-for="type in balanceTypes"
                :key="type.attributes.name"
                class="tw-flex-1 tw-items-center tw-px-2 tw-text-center"
            >
                <CText font-weight="semibold">
                    {{ type.attributes.name }}
                </CText>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import type BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';

    export default defineComponent({
        name: 'StaffAllocationsStickyHeader',
        props: {
            balanceTypes: {
                type: Object as PropType<Collection<BalanceTypeModel>>,
                required: true,
            },
        },
    });
</script>

<style scoped>
    .sticky-header {
        @apply tw-my-3 tw-rounded-lg tw-border tw-border-gray-200 tw-bg-gray-200 tw-px-4 tw-py-2;

        > div > div {
            @apply tw-border-r-2 tw-border-gray-300/60;
        }

        > div > div:last-child {
            @apply tw-border-r-0;
        }
    }
</style>
