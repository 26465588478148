<template>
    <CModal
        ref="modal"
        :header-title="__('billing_core:delete_address')"
        :hide-header-fullscreen="true"
        size="xs"
    >
        <p class="tw-mb-0">
            {{ __('billing_core:delete_address_warning') }}
        </p>
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.cancel') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loader.isLoading()"
                variant="danger"
                @click="deleteModel()"
            >
                {{ __('billing_core:delete_address') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import type AddressModel from '@/modules/cashier/models/AddressModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {},
        props: {
            addressModel: {
                type: Object as PropType<AddressModel>,
                required: true,
            },
        },

        setup(props, {emit}) {
            const loader = new Loader();
            const {modal, show, hide, orders} = display(props.addressModel);

            function deleteModel() {
                loader.start();
                if (orders.value.isEmpty()) {
                    props.addressModel.delete().then(() => {
                        loader.stop();
                        hide();
                        useNotification().success(__('billing_core:address_deleted_successfully'));
                        emit('deleted', props.addressModel);
                    });
                } else {
                    loader.stop();
                    hide();
                    useNotification().error(__('billing_core:errors.address_used'));
                }
            }

            return {
                modal,
                loader,
                show,
                hide,
                deleteModel,
                orders,
            };
        },
    });

    export function display(addressModel: AddressModel) {
        const modal = ref();
        const orders = computed(() => {
            return addressModel.orders().value();
        });

        function show() {
            modal.value.show();
        }

        function hide() {
            modal.value.hide();
        }

        return {
            modal,
            show,
            hide,
            orders,
        };
    }
</script>
