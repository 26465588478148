<template>
    <div>
        <SelectResource
            ref="SelectResource"
            :builder="builder"
            :button-class="buttonClass"
            :disabled="disabled"
            :has-unselect="hasUnselect"
            :hide-on-click="!checkable"
            :model="contractTypeModel"
            :unselect-value="unselectValue"
            :value="value"
            @fallback="showModal"
            @input="onModelSelected"
            @search="onSearchValue"
        >
            <template #button>
                <template v-if="value">
                    {{ value.attributes.name }}
                </template>
                <template v-else>
                    <slot name="unselectedValue">
                        {{ __('hr_contract:select_contract_type') }}
                    </slot>
                </template>
            </template>

            <template
                v-if="createOptionEnabled"
                #fallback="{searchValue}"
            >
                <template v-if="searchValue">
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />{{ __('common:actions.add') }} {{ searchValue }}
                </template>
                <template v-else>
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />{{ __('common:actions.add') }}
                </template>
            </template>

            <template
                v-if="value"
                #unselect-item
            >
                <slot name="unselect">
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-duotone fa-unlink"
                    />{{ __('hr_contract:unselect_contract_type') }}
                </slot>
            </template>

            <template #default="{option, searchValue}">
                <div class="tw-flex tw-pr-4">
                    <div
                        class="tw-truncate"
                        v-html="TextHighlighter.highlight(searchValue, option.attributes.name)"
                    />
                </div>
            </template>
        </SelectResource>
    </div>
</template>

<script>
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';
    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import ContractTypeModel from '@/modules/human-resources/models/ContractTypeModel';
    import {collect} from '@meekohq/lumos';
    import useAuth from '@/modules/app/composables/useAuth';

    export default {
        components: {SelectResource},
        props: {
            unselectValue: {
                default: undefined,
            },
            value: {
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            customModal: {
                type: Boolean,
                default: false,
            },
            checkable: {
                type: Boolean,
                default: false,
            },
            createOptionEnabled: {
                type: Boolean,
                default: false,
            },
            hasUnselect: {
                type: Boolean,
                default: false,
            },
            buttonClass: {},
        },
        emits: ['input', 'hideModal', 'showModal'],
        data() {
            return {
                contractTypeModel: ContractTypeModel,
                loader: new Loader(),
                TextHighlighter: new TextHighlighter(),
                searchValue: '',
            };
        },

        computed: {
            userNuseriesIds() {
                return collect(useAuth().legacyUser.value.nurseries).keyBy('id').keys().all();
            },

            builder() {
                return ContractTypeModel.query()
                    .whereHas('organizations', q => q.whereIn('id', this.userNuseriesIds))
                    .orderBy('name')
                    .whereLike('name', `*${this.searchValue}*`);
            },

            isValue() {
                return this.value;
            },

            defaultModal: {
                cache: false,
                get() {
                    return this.$refs.defaultModal;
                },
            },

            finder: {
                cache: false,
                get() {
                    return this.$refs.finder;
                },
            },

            SelectResource: {
                cache: false,
                get() {
                    return this.$refs.SelectResource;
                },
            },
        },

        methods: {
            onSearchValue(event) {
                this.searchValue = event;
            },

            onModelCreated(model) {
                this.hideModal();
                this.onModelSelected(model);
            },

            onModelSelected(model) {
                this.$emit('input', model);
            },

            hideModal() {
                if (this.customModal) {
                    this.$emit('hideModal');
                } else {
                    this.defaultModal.hide();
                }
            },

            showModal() {
                if (this.customModal) {
                    this.$emit('showModal');
                } else {
                    this.defaultModal.show();
                }
                this.SelectResource.hide();
            },
        },
    };
</script>
