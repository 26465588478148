<template>
    <CHStack
        gap="2"
        wrap
    >
        <tags
            filter
            :selected-tags.sync="selectedTags"
            taggable-type="kid"
            :tags="nursery.tags"
        />

        <FilterContractStates
            size="sm"
            :value="contractStates"
            @input="$emit('contract:updated', $event)"
        />

        <FilterGroup
            v-if="nursery.groups && nursery.groups.length"
            class="tw-w-min"
            :nursery="nursery"
            size="sm"
            :value="selectedGroup"
            @input="$emit('group:updated', $event)"
        />

        <CInput
            v-if="showSearch"
            class="tw-w-full md:tw-ml-auto md:tw-w-1/4"
            :placeholder="__('common:search_dots')"
            :value="search"
            @input="$emit('search:updated', $event)"
        />
    </CHStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, nextTick, ref, watch} from 'vue';
    import _debounce from 'lodash-es/debounce';
    import type {QueryBuilder} from '@meekohq/lumos';
    import {now} from '@meekohq/lumos';
    import {type Model} from '@meekohq/lumos';
    import KidModel from '@/modules/family/models/KidModel';
    import FilterGroup from '@/modules/family/components/kid/Index/FilterGroup.vue';
    import FilterContractStates from '@/modules/family/components/kid/Index/FilterContractStates.vue';

    export default defineComponent({
        components: {
            FilterGroup,
            FilterContractStates,
        },
        props: {
            selectedGroup: {
                type: String,
                default: null,
            },
            nursery: {
                type: Object,
                required: true,
            },
            contractStates: {
                type: Array,
                required: true,
            },
            search: {
                type: String,
                default: null,
            },
            showSearch: {
                type: Boolean,
                default: false,
            },
            filterQuery: {
                type: Object as PropType<QueryBuilder<Model>>,
                default: null,
            },
        },
        setup(props, {emit}) {
            const selectedTags = ref(null);
            watch(selectedTags, () => emit('tags:updated', selectedTags.value));

            if (props.filterQuery) {
                const filters = computed(() => {
                    return [props.contractStates, props.selectedGroup, selectedTags.value];
                });
                watch(
                    () => filters,
                    () => {
                        nextTick(() => updateQuery());
                    },
                    {immediate: true, deep: true}
                );
                watch(
                    () => props.search,
                    _debounce((val, oldVal) => {
                        if (val || oldVal) {
                            updateQuery();
                        }
                    }, 1000),
                    {immediate: true}
                );
            }

            function updateQuery() {
                const mainQuery = KidModel.query();

                const withoutDrafts = !props.contractStates.includes('including_draft');
                const contractStateMappedToScopeFunctions = {
                    coming: query => query.scope('withComingContract', withoutDrafts),
                    current: query => query.scope('withActiveContract', withoutDrafts),
                    past: query => query.scope('withPastContractUntil', now().toISOString(), withoutDrafts),
                };

                const contractStateMappedToScope = props.contractStates
                    // Filter states to remove including_draft as it's not a possible scope in the mapped array
                    .filter(contractState => contractState !== 'including_draft')
                    // Map selected contract filters to matching scope functions in contractStateMappedToScopeFunctions object
                    .map(contractState => contractStateMappedToScopeFunctions[contractState as string]) as Function[];

                mainQuery.where(query1 => {
                    contractStateMappedToScope.forEach((scope, index) => {
                        // First where must be AND
                        if (index === 0) {
                            query1.where(query2 => {
                                scope(query2);
                            });
                        } else {
                            query1.orWhere(query2 => {
                                scope(query2);
                            });
                        }
                    });
                });

                if (selectedTags.value && selectedTags.value.length) {
                    mainQuery.whereHas(new KidModel().tagsPivots(), query1 => {
                        query1.whereIn('tag_id', selectedTags.value as string[]);
                    });
                } else if (selectedTags.value) {
                    mainQuery.whereDoesntHave(new KidModel().tagsPivots());
                }

                if (props.selectedGroup) {
                    mainQuery.where('group_id', '==', props.selectedGroup);
                }

                if (props.search) {
                    mainQuery.where(query1 => {
                        query1
                            .whereLike('first_name', `*${props.search}*`)
                            .orWhereLike('last_name', `*${props.search}*`);
                    });
                }

                emit('filterQuery:updated', mainQuery);
            }

            return {
                selectedTags,
            };
        },
    });
</script>
