<template>
    <div class="Maintenance__wrapper">
        <div class="Maintenance__logo">
            <img
                alt="logo"
                :src="MeekoMaintenance"
            />
        </div>
        <div class="Maintenance__content">
            <div>
                {{ __('app:meeko_s_team_is_making_a_few_adjustments') }}
            </div>
            <div>
                {{ __('app:we_ll_be_back_in_a_few_moments_dots') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import MeekoMaintenance from '@/assets/images/MeekoMaintenance.png';

    export default defineComponent({
        setup() {
            return {
                MeekoMaintenance,
            };
        },
    });
</script>

<style scoped>
    .Maintenance__wrapper {
    }

    .Maintenance__logo {
        img {
            @apply tw-absolute tw-rotate-[10deg] tw-transition-all tw-duration-300 tw-ease-in-out;
            bottom: -50px;
            left: -40px;
            z-index: 0;
            width: 500px;

            @media screen(md) {
                left: -60px;
                width: 650px;
            }

            @media screen(2xl) {
                left: -80px;
                width: 750px;
            }
        }
    }

    .Maintenance__content {
        @apply tw-absolute tw-inset-x-8 tw-inset-y-0 tw-z-10 tw-mt-48 tw-flex tw-flex-col tw-justify-start tw-text-center;
        @apply tw-bg-gradient-to-br tw-from-blue-600 tw-to-cyan-500 tw-bg-clip-text tw-font-display tw-text-4xl tw-text-transparent;

        @media screen(md) {
            @apply tw-mt-32 md:tw-text-5xl;
        }

        @media screen(xl) {
            @apply tw-mt-0 tw-justify-center tw-text-left;
            left: 45%;
        }

        @media screen(xl) {
            @apply tw-mt-0 tw-justify-center tw-text-left;
            left: 45%;
        }
    }
</style>

<style>
    body {
        /*@apply tw-bg-gradient-to-br tw-from-[#fff] tw-from-50% tw-to-primary-600 tw-to-50%*/
    }
</style>
