<template>
    <div class="MBillingDetailItem">
        <div class="MBillingDetailItem__container">
            <div
                class="MBillingDetailItem__colored-border"
                :class="{
                    'MBillingDetailItem__colored-border--is-blue': color === 'blue',
                    'MBillingDetailItem__colored-border--is-red': color === 'red',
                    'MBillingDetailItem__colored-border--is-orange': color === 'orange',
                    'MBillingDetailItem__colored-border--is-green': color === 'green',
                    'MBillingDetailItem__colored-border--is-gray': color === 'gray',
                }"
            />
            <div class="MBillingDetailItem__title">
                {{ title }}
                <br />
                <span
                    v-if="subtitle"
                    class="MBillingDetailItem__subtitle"
                >
                    {{ subtitle }}
                </span>
            </div>
        </div>

        <div class="MBillingDetailItem__value">
            {{ value }}
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            title: {
                type: String,
                required: true,
            },
            subtitle: {
                type: String,
                default: undefined,
            },
            value: {
                type: String,
                required: true,
            },
            color: {
                type: String,
                required: true,
            },
        },
    });
</script>

<style scoepd>
    .MBillingDetailItem {
        @apply tw-flex tw-flex-row;
    }

    .MBillingDetailItem__container {
        @apply tw-relative tw-flex tw-items-baseline;
    }

    .MBillingDetailItem__colored-border {
        @apply tw-absolute tw-inset-y-0 tw-w-1 tw-rounded-full;

        &--is-blue {
            @apply tw-bg-blue-500;
        }

        &--is-red {
            @apply tw-bg-red-500;
        }

        &--is-orange {
            @apply tw-bg-orange-500;
        }

        &--is-green {
            @apply tw-bg-green-500;
        }

        &--is-gray {
            @apply tw-bg-gray-300;
        }
    }

    .MBillingDetailItem__title {
        @apply tw-ml-3 tw-mr-4 tw-font-semibold tw-leading-none;
    }

    .MBillingDetailItem__subtitle {
        @apply tw-whitespace-nowrap tw-text-sm tw-text-gray-600;
    }

    .MBillingDetailItem__value {
        @apply tw-flex-1 tw-whitespace-nowrap tw-text-right;
    }
</style>
