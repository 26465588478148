<template>
    <MTooltip
        :hoverable="!canExport"
        :label="__('billing_transaction:select_a_period_to_export_data')"
        placement="top-end"
    >
        <MButton
            ref="exportRef"
            data-action-name="export"
            :disabled="!canExport"
            :loading="exportLoading"
            @click="onExportClicked"
        >
            <template #left-icons>
                <FontAwesomeIcon icon="fa-solid fa-download" />
            </template>
            {{ __('common:actions.export') }}
        </MButton>
    </MTooltip>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, onBeforeMount, onBeforeUnmount, ref} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import {useXlsExport} from '@/modules/app/composables/useXlsExport';
    import type {OutputType} from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';
    import {Epoch} from '@meekohq/lumos';

    export default defineComponent({
        props: {
            period: {
                type: Object as PropType<OutputType['periodFilter']>,
                required: true,
            },
        },
        setup(props) {
            const {activeOrganization} = useManager();

            const {exportLoading, getExport, bindExportBroadcast, unbindExportBroadcast} = useXlsExport();

            const exportRef = ref();

            const canExport = computed(() => {
                return props.period.data.value?.from && props.period.data.value?.to;
            });

            onBeforeMount(() => {
                bindExportBroadcast();
            });

            onBeforeUnmount(() => {
                unbindExportBroadcast();
            });

            function onExportClicked() {
                exportToXls();
            }

            function exportToXls() {
                exportLoading.value = true;
                getExport(
                    [activeOrganization.value.getKey()],
                    [
                        {
                            name: 'transactions',
                            filters: {
                                from: Epoch.fromISOString(props.period.data.value.from as string).toFormat(
                                    'yyyy-LL-dd'
                                ),
                                to: Epoch.fromISOString(props.period.data.value.to as string).toFormat('yyyy-LL-dd'),
                            },
                        },
                    ]
                );
            }

            return {
                canExport,
                exportLoading,
                exportRef,
                onExportClicked,
            };
        },
    });
</script>
