<template>
    <router-link
        v-if="to"
        :active-class="baseActiveClass"
        :class="baseClass"
        :exact="exact"
        :to="to"
        v-on="$listeners"
    >
        <slot />
    </router-link>
    <div
        v-else
        :class="baseClass"
        v-on="$listeners"
    >
        <slot />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            active: {type: Boolean, default: false},
            size: {type: String, default: 'default'},
            variant: {type: String, default: 'default'},
            customClass: {},
            to: {},
            exact: {type: Boolean, default: false},
        },
        setup(props) {
            const variants = {
                default: {
                    base: 'tw-text-black tw-cursor-pointer',
                    active: '!tw-text-white !tw-bg-blue-400',
                    inactive: 'hover:tw-text-blue-900 hover:tw-bg-blue-200',
                },
                orange: {
                    base: 'tw-text-black tw-cursor-pointer',
                    active: '!tw-text-white !tw-bg-orange-400',
                    inactive: 'hover:tw-text-orange-900 hover:tw-bg-orange-200',
                },
            };

            const baseClass = computed(() => {
                const output = [] as any[];

                output.push(props.customClass);

                const sizes = {
                    default: 'tw-px-3 tw-py-2 tw-text-base',
                    sm: 'tw-px-2 tw-py-1 tw-text-sm',
                };
                output.push(sizes[props.size]);

                output.push('tw-block tw-no-underline tw-rounded-lg tw-font-semibold');

                output.push(variants[props.variant]['base']);
                if (props.active && !props.to) {
                    output.push(variants[props.variant]['active']);
                } else {
                    output.push(variants[props.variant]['inactive']);
                }

                return output;
            });

            const baseActiveClass = computed(() => {
                return variants[props.variant]['active'];
            });

            return {
                baseClass,
                baseActiveClass,
                variants,
            };
        },
    });
</script>
