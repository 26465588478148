import type {Ref} from 'vue';
import {computed} from 'vue';
import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import TicketPivot from '@/modules/activity/ticket/domain/TicketPivot';

export default function useAttachTicket(ticket: Ref<TicketModel>, resourceId: string, resourceType: string) {
    const pivot = computed(() =>
        ticket.value
            .ticketPivots()
            .value()
            .where('attributes.resource_id', resourceId)
            .where('attributes.resource_type', resourceType)
            .first()
    );

    // Attach resource to ticket
    async function attach() {
        if (pivot.value) {
            return;
        }

        const newPivot = new TicketPivot();
        newPivot.attributes.account_id = ticket.value.attributes.account_id;
        newPivot.attributes.ticket_id = ticket.value.id;
        newPivot.attributes.resource_id = resourceId;
        newPivot.attributes.resource_type = resourceType;

        await newPivot.save();

        ticket.value.ticketPivots().value().push(newPivot);
    }

    // Detach resource from ticket
    async function detach() {
        if (!pivot.value) {
            return;
        }

        await pivot.value.delete();
    }

    return {attach, detach};
}
