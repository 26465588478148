import type {Ref} from 'vue';
import {computed, reactive, ref, watch} from 'vue';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import type {LengthAwarePaginator} from '@meekohq/lumos';
import {Arr, type Collection, type ModelCollection} from '@meekohq/lumos';
import DocumentModel from '@/modules/document/models/DocumentModel';
import TagModel from '@/modules/tag/models/TagModel';
import {debounce} from 'lodash-es';
import useAuth from '@/modules/app/composables/useAuth';

interface GetDocumentsOptionsType {
    organizationsToConstrainTo: Ref<Collection<OrganizationModel>>;
    documentableTypeToConstrainTo: Ref<string>;
    documentableIdToConstrainTo: Ref<string>;
    search: Ref<string>;
    tagsToConstrainTo: Ref<TagModel[]>;
    withoutTag: Ref<boolean>;
    with: Ref<string[]>;
    paginationAmount: Ref<number>;
}

const defaultOptions: Partial<GetDocumentsOptionsType> = {
    with: ref([
        new DocumentModel().tags().getApiRelationName(),
        new DocumentModel().organizations().getApiRelationName(),
    ]),
    paginationAmount: ref(20),
};

/*
 * This is a composition function to fetch documents. Its options conform
 * to a partial of the GetDocumentsOptionsType interface
 */
export default function (
    options: Partial<GetDocumentsOptionsType> = defaultOptions,
    currentPage: Ref<number> = ref(1)
) {
    const {user} = useAuth();

    // Merge the default options with the provided options
    for (const key in defaultOptions) {
        if (options[key] === undefined) {
            options[key] = defaultOptions[key];
        }
    }

    // If no organizationsToConstrainTo are provided, we default to the organizations of the current user
    if (!options.organizationsToConstrainTo) {
        options.organizationsToConstrainTo = ref(user.value.organizations().value()) as Ref<
            ModelCollection<OrganizationModel>
        >;
    }

    const paginator: Ref<LengthAwarePaginator<DocumentModel> | undefined> = ref();

    const isLoading = ref(false);

    const documentsQuery = computed(() => {
        const documentQuery = DocumentModel.query();

        // Constrain the documentQuery to documents that are related to the organizations provided
        if (options.organizationsToConstrainTo?.value.count()) {
            documentQuery.whereHas(new DocumentModel().organizationsPivots(), query2 => {
                const organizationsIds = (
                    options.organizationsToConstrainTo as Ref<ModelCollection<OrganizationModel>>
                ).value
                    .map<string>(organization => organization.getKey())
                    .toArray<string>();

                query2.whereIn('organization_id', organizationsIds);
            });
        }

        // Constraint the documentQuery to documents that are related to a resource of the provided type (e.g. 'hr/staffs')
        if (options.documentableTypeToConstrainTo?.value) {
            documentQuery.whereHas(new DocumentModel().resources(), query2 => {
                query2
                    .where('documentable_type', options.documentableTypeToConstrainTo?.value)
                    .whereIn('documentable_id', Arr.wrap(options.documentableIdToConstrainTo?.value as string));
            });
        } else {
            // If no documentableTypeToConstrainTo is provided, we constrain the documentQuery to documents
            // that are not related to any resource to fetch nursery documents
            documentQuery.whereDoesntHave(new DocumentModel().resources());
        }

        // Constraint the documentQuery to documents that have a title that matches the provided string
        if (options.search?.value) {
            documentQuery.where('name', 'like', `%${options.search.value}%`);
        }

        // Constraint the documentQuery to documents that have a tag that matches the provided tag ids
        if (options.tagsToConstrainTo?.value.length) {
            documentQuery.whereHas(new DocumentModel().tags(), query2 => {
                const tagsIds = (options.tagsToConstrainTo as Ref<TagModel[]>).value.map<string>(tag => tag.getKey());

                query2.whereIn(new TagModel().getKeyName(), tagsIds);
            });
        }

        if (options.withoutTag?.value) {
            documentQuery.whereDoesntHave(new DocumentModel().tags());
        }

        if (options.with?.value?.length) {
            // For each relationship name in the with array, we add a with statement to the documentQuery
            // Works only on the first level of relationships
            options.with?.value.forEach(relation => {
                documentQuery.with(relation);
            });
        }

        return documentQuery.orderBy('created_at', 'desc');
    });

    async function paginateDocuments() {
        isLoading.value = true;

        const result = await documentsQuery.value.paginate(options.paginationAmount?.value, currentPage.value);

        paginator.value = result;
        isLoading.value = false;

        return result;
    }

    async function getAllDocuments() {
        isLoading.value = true;

        const result = await documentsQuery.value.get();

        isLoading.value = false;

        return result;
    }

    const debounceGetDocuments = debounce(paginateDocuments, 1500, {
        leading: false,
        trailing: true,
    });

    function updatePagination(page: number) {
        currentPage.value = page;
        paginateDocuments();
    }

    /*
     * Initiate a watch on the options object. When the options change, we reset the current page
     * to 1 and fetch the transactions again.
     */
    function initWatch() {
        // We wrap in a reactive object to make sure the watch is triggered when the options object changes
        watch(
            reactive({...options}),
            () => {
                isLoading.value = true;

                currentPage.value = 1;

                debounceGetDocuments();
            },
            {deep: true}
        );
    }

    return {
        paginator,
        currentPage,
        isLoading,
        paginateDocuments,
        getAllDocuments,
        debounceGetDocuments,
        updatePagination,
        initWatch,
    };
}
