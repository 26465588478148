import {ServiceProvider} from '@meekohq/lumos';
import Vue from 'vue';

export default class CoherenceServiceProvider extends ServiceProvider {
    public boot() {
        // Form
        Vue.component('CButtonGroup', () => import('./components/Forms/CButtonGroup.vue'));
        Vue.component('CForm', () => import('./components/Forms/CForm.vue'));
        Vue.component('CFormGroup', () => import('./components/Forms/CFormGroup.vue'));
        Vue.component('CFormTwoColumns', () => import('./components/Forms/CFormTwoColumns.vue'));
        Vue.component('CFormThreeColumns', () => import('./components/Forms/CFormThreeColumns.vue'));
        Vue.component('CInputGroup', () => import('./components/Forms/CInputGroup.vue'));
        Vue.component('CInputAddon', () => import('./components/Forms/CInputAddon.vue'));
        Vue.component('CInputSuggestions', () => import('./components/Forms/CInputSuggestions.vue'));
        Vue.component('CFormTextArea', () => import('./components/Forms/CFormTextArea.vue'));
        Vue.component('CCheckbox', () => import('./components/Forms/CCheckbox.vue'));
        Vue.component('CSwitch', () => import('./components/Forms/CSwitch.vue'));
        Vue.component('CRadio', () => import('./components/Forms/CRadio.vue'));
        Vue.component('CFormSelect', () => import('./components/Forms/CFormSelect.vue'));
        Vue.component('CPhoneInput', () => import('./components/Forms/CPhoneInput.vue'));
        Vue.component('CFormErrorMessage', () => import('./components/Forms/CFormErrorMessage.vue'));
        Vue.component('CFormErrorMessageList', () => import('./components/Forms/CFormErrorMessageList.vue'));
        Vue.component('CFormSubmitTrigger', () => import('./components/Forms/CFormSubmitTrigger.vue'));
        Vue.component('CFormDatepicker', () => import('./components/Forms/CFormDatepicker.vue'));
        Vue.component('CFileInput', () => import('./components/Forms/CFileInput.vue'));

        // List
        Vue.component('CList', () => import('./components/List/CList.vue'));
        Vue.component('CListRow', () => import('./components/List/CListRow.vue'));
        Vue.component('CListHeader', () => import('./components/List/CListHeader.vue'));
        Vue.component('CListHeaderItem', () => import('./components/List/CListHeaderItem.vue'));
        Vue.component('CListSection', () => import('./components/List/CListSection.vue'));
        Vue.component('CListCornerLoader', () => import('./components/List/ListLoader.vue'));
        Vue.component('CListNoResult', () => import('./components/List/CListNoResult.vue'));
        Vue.component('CListHasMoreTrigger', () => import('./components/List/HasMoreTrigger.vue'));

        // Tab Menu
        Vue.component('CTabMenu', () => import('./components/TabMenu/TabMenu.vue'));
        Vue.component('CTabMenuItem', () => import('./components/TabMenu/TabMenuItem.vue'));

        // Tools
        Vue.component('CLoader', () => import('./components/Tools/Loader.vue'));
        Vue.component('CPagination', () => import('./components/Tools/CPagination.vue'));
        Vue.component('CFinder', () => import('./components/Tools/Finder.vue'));
        Vue.component('CSkeleton', () => import('./components/Tools/CSkeleton.vue'));
        Vue.component('CIcon', () => import('./components/Tools/CIcon.vue'));

        // Overlay
        Vue.component('CAlert', () => import('./components/Feedback/CAlert.vue'));
        Vue.component('CDropdownMenuItem', () => import('./components/Overlay/CDropdownMenuItem.vue'));

        // Layout
        Vue.component(
            'CVerticalNavigationWrapper',
            () => import('./components/Layout/VerticalNavigation/CVerticalNavigationWrapper.vue')
        );
        Vue.component(
            'CVerticalNavigationItem',
            () => import('./components/Layout/VerticalNavigation/CVerticalNavigationItem.vue')
        );
        Vue.component('CStack', () => import('./components/Layout/CStack.vue'));
        Vue.component('CInline', () => import('./components/Layout/CInline.vue'));
        Vue.component('CVStack', () => import('./components/Layout/CVStack.vue'));
        Vue.component('CHStack', () => import('./components/Layout/CHStack.vue'));
        Vue.component('CCenter', () => import('./components/Layout/CCenter.vue'));

        // Card
        Vue.component('CCard', () => import('./components/Layout/Card/CCard.vue'));
        Vue.component('CCardTitle', () => import('./components/Layout/Card/CCardTitle.vue'));
        Vue.component('CCardHeroTitle', () => import('./components/Layout/Card/CCardHeroTitle.vue'));

        // Typography
        Vue.component('CText', () => import('./components/Typography/CText.vue'));
        Vue.component('CParagraph', () => import('./components/Typography/CParagraph.vue'));
        Vue.component('CLongText', () => import('./components/Text/LongText.vue'));

        Vue.component('CDisclosure', () => import('./components/Disclosure/CDisclosure.vue'));
    }
}
