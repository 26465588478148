import {Model} from '@meekohq/lumos';
import ContractModel from '@/modules/human-resources/models/ContractModel';

export default class ContractTrialPeriodModel extends Model {
    public type = 'hr/contract/trial_periods';

    public attributes: {
        id: string;
        account_id: string | undefined;
        contract_id: string | undefined;
        ended_at: string | undefined;
        explanation: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        contract_id: undefined,
        ended_at: undefined,
        explanation: undefined,
    };

    contract() {
        return this.belongsTo('contract', ContractModel, 'contract_id');
    }
}
