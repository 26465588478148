<template>
    <div v-html="computedValue" />
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import {clickableUrl} from '@/modules/coherence-ui/components/Text/UrlReplacer';

    export default defineComponent({
        props: {
            value: {type: String, required: true},
            clickableUrl: {type: Boolean, default: true},
            shortenUrl: {type: Boolean, default: true},
        },
        setup(props) {
            const computedValue = computed(() => {
                let value = props.value;

                if (props.clickableUrl) {
                    value = clickableUrl(props.value, props.shortenUrl);
                }

                return value;
            });

            return {
                computedValue,
            };
        },
    });
</script>

<style scoped></style>
