<script setup lang="ts">
    import {useSlots} from 'vue';

    interface Props {
        icon?: string;
        label?: string;
    }

    withDefaults(defineProps<Props>(), {
        icon: undefined,
        label: undefined,
    });

    const slots = useSlots();

    const hasIconSlot = slots.icon !== undefined;
</script>

<template>
    <label class="MLabel">
        <span
            v-if="hasIconSlot || icon"
            class="MLabel__icon"
        >
            <slot name="icon">
                <FontAwesomeIcon
                    v-if="icon"
                    :icon="icon"
                />
            </slot>
        </span>
        <slot>
            {{ label }}
        </slot>
    </label>
</template>

<style scoped>
    .MLabel {
        @apply tw-font-display tw-font-medium tw-leading-6 tw-text-gray-600;
        @apply tw-flex tw-items-baseline;
        /*@apply tw-gap-x-2;*/
    }

    .MLabel__icon {
        @apply tw-text-primary-500;
        @apply tw-mr-2;
    }
</style>
