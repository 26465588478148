<template>
    <CModal
        ref="modal"
        :header-title="title"
        @show="onShow()"
    >
        <CVStack>
            <CFormGroup>
                <slot name="label" />
                <SelectOrganization v-model="model" />
            </CFormGroup>
        </CVStack>
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                variant="primary"
                @click="onSave()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script>
    import SelectOrganization from '@/modules/organization/components/SelectOrganization.vue';

    export default {
        components: {SelectOrganization},
        props: {
            title: {type: String, default: 'titre'},
            select: {type: Array},
            orga: {},
        },
        data() {
            return {
                model: null,
            };
        },

        methods: {
            onSave() {
                this.hide();
                this.$emit('organizationSelected', this.model);
            },
            onShow() {
                this.model = null;
            },
            show() {
                this.$refs.modal.show();
            },

            hide() {
                this.$refs.modal.hide();
            },
        },
    };
</script>
