<template>
    <CCard
        :border="false"
        header-size="sm"
        shadow
    >
        <template #header>
            <div class="CardItem__header">
                <KidTitle
                    :nursery="nursery"
                    :registration="registration"
                />
                <TagsSelector
                    :editable="can('update', 'registrations')"
                    :taggable-model="registrationModel"
                    taggable-type="registration"
                    :tags="registrationModel.tags().value().toArray()"
                    @attached="registrationModel.tags().value().push($event)"
                    @detached="registrationModel.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                />
            </div>
        </template>
        <div class="CardItem__body">
            <strong class="CardItem__kid-age">
                {{
                    `${useKidAge().getByBirthdate(
                        Epoch.fromISOString(registration.kid_birthdate),
                        Epoch.fromISOString(registration.contract_started_at)
                    )} ${__('registration:on_entry')}`
                }}
            </strong>
            <strong class="CardItem__contract-start">
                {{
                    __('common:date_format.human_starting_from', {
                        from: parse(registration.contract_started_at, 'yyyy-MM-dd').toLocaleString(
                            Epoch.presets.DATE_SHORT
                        ),
                    })
                }}
            </strong>
            <div class="CardItem__row">
                <div class="CardItem__texts">
                    <div class="CardItem__light-gray-text">
                        <FontAwesomeIcon
                            class="CardItem__icon"
                            icon="fa-solid fa-folder"
                        />
                        {{ __('common:registration') }}
                    </div>
                    <div class="CardItem__gray-text">
                        {{ parse(registration.requested_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT) }}
                    </div>
                </div>
                <div class="CardItem__texts">
                    <div class="CardItem__light-gray-text">
                        <FontAwesomeIcon
                            class="CardItem__icon"
                            icon="fa-solid fa-money-bill-alt"
                        />
                        {{ __('common:income') }}
                    </div>
                    <div class="CardItem__gray-text">
                        {{ registration.first_parent_salary + registration.second_parent_salary }}{{ nursery.currency }}
                    </div>
                </div>
            </div>
            <div class="CardItem__separator" />
            <kid-planning-summary
                :nursery="nursery"
                :plannings="registration.plannings"
                :weeks="registration.contract_recurrent_weeks"
            />
        </div>
        <template #footer>
            <div class="CardItem__footer">
                <MButton
                    v-if="can('read', 'invoices')"
                    variant="light"
                    @click="$emit('estimate', registration)"
                >
                    <FontAwesomeIcon
                        class="CardItem__icon"
                        icon="fa-solid fa-file-pdf"
                    />
                    {{ __('common:quote') }}
                </MButton>
                <MButton
                    :to="{name: 'registrations.show', params: {nursery: nursery.id, registration: registration.id}}"
                    variant="primary"
                >
                    <FontAwesomeIcon
                        class="CardItem__icon"
                        icon="fa-solid fa-eye"
                    />
                    {{ __('registration:consult_file') }}
                </MButton>
            </div>
        </template>
    </CCard>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import KidPlanningSummary from '@/modules/legacy/components/Modules/KidPlanningSummary.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import KidTitle from '@/modules/registration/components/molecules/List/KidTitle.vue';
    import {Epoch} from '@meekohq/lumos';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import useKidAge from '@/modules/family/composables/kid/useKidAge';

    export default defineComponent({
        components: {
            TagsSelector,
            KidTitle,
            KidPlanningSummary,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            registration: {
                type: Object,
                required: true,
            },
            registrationModel: {
                type: Object,
                required: true,
            },
        },
        setup() {
            const {can} = useAbility();
            const {parse} = useEpoch();

            return {
                Epoch,
                can,
                parse,
            };
        },
        methods: {useKidAge},
    });
</script>

<style scoped>
    .CardItem {
    }

    .CardItem__header {
        @apply tw-flex tw-items-center tw-justify-between;
    }

    .CardItem__body {
        @apply tw-flex tw-flex-col;
    }

    .CardItem__footer {
        @apply tw-flex tw-flex-col tw-space-y-3;
    }

    .CardItem__icon {
        @apply tw-mr-1;
    }

    .CardItem__tags {
        @apply tw-ml-3;
    }

    .CardItem__kid-age {
        @apply tw-uppercase tw-tracking-wide tw-text-gray-800;
    }

    .CardItem__contract-start {
        @apply tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-600;
    }

    .CardItem__row {
        @apply tw-mt-3 tw-flex tw-gap-6;
    }

    .CardItem__texts {
        @apply tw-text-sm tw-font-bold;
    }

    .CardItem__gray-text {
        @apply tw-text-gray-800;
    }

    .CardItem__light-gray-text {
        @apply tw-uppercase tw-text-gray-400;
    }

    .CardItem__separator {
        @apply tw-my-3 tw-h-1 tw-w-full tw-rounded-2xl tw-bg-gray-200;
    }
</style>
