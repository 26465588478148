import DocumentModel from '@/modules/document/models/DocumentModel';
import type {Collection, Model, ModelCollection, ModelOptionsType} from '@meekohq/lumos';
import {MqlTransaction} from '@meekohq/lumos';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import {ref} from 'vue';
import _startWith from 'lodash-es/startsWith';
import {upload} from '@/modules/legacy/libs/usercontent';
import type DocumentOrganizationPivot from '@/modules/document/models/DocumentOrganizationPivot';
import useAuth from '@/modules/app/composables/useAuth';

export interface SaveDocumentTransport {
    document: DocumentModel;
    file: File;
    organizationsToAttach: OrganizationModel[];
    organizationsToDetach: OrganizationModel[];
    organizationsPivotsToUpdate: ModelCollection<DocumentOrganizationPivot> | Collection<DocumentOrganizationPivot>;
    documentVisibility: {
        staffs_access: boolean;
        parents_access: boolean;
        web_access: boolean;
    };
    resource: Model;
}
export default function () {
    const loading = ref(false);
    const {user} = useAuth();

    async function uploadFile(file: SaveDocumentTransport['file'], document: DocumentModel) {
        document.attributes.link = null;

        const uploadedFile: {key: any; hash: any} = await upload(file);

        document.attributes.original_filename = file ? file.name : '';

        document.attributes.filename = JSON.stringify({
            key: uploadedFile.key,
            hash: uploadedFile.hash,
        });
    }

    function saveDocument(document: SaveDocumentTransport['document'], options?: ModelOptionsType) {
        // If the document is a link, we set the file related attributes to null in the model to avoid bugs
        if (document.attributes.link) {
            document.attributes.filename = null;
            document.attributes.original_filename = null;

            // If the link does not start with http or https, we add https to the link
            if (!_startWith(document.attributes.link, 'http')) {
                document.attributes.link = 'https://' + document.attributes.link;
            }
        }

        // If the document is new, we set the account_id to the current user's account_id
        if (!document.exists) {
            document.attributes.account_id = user.value.attributes.account_id;
        }

        // We save the document
        document.save(options);

        return document;
    }

    function attachDocumentToOrganizations(
        document: SaveDocumentTransport['document'],
        organizationsToAttach: SaveDocumentTransport['organizationsToAttach'],
        documentVisibility: SaveDocumentTransport['documentVisibility'],
        options: ModelOptionsType
    ) {
        // We attach the document to the organizations with the visibilty provided in the options
        organizationsToAttach.forEach((organization: OrganizationModel) => {
            document.organizations().attach(
                organization,
                {
                    account_id: document.attributes.account_id,
                    ...documentVisibility,
                },
                options
            );
        });
    }

    function detachDocumentFromOrganizations(
        document: SaveDocumentTransport['document'],
        organizationsToDetach: SaveDocumentTransport['organizationsToDetach'],
        options: ModelOptionsType
    ) {
        // We detach the document from the organizations provided
        organizationsToDetach.forEach((organization: OrganizationModel) => {
            document.organizations().detach(organization, options);
        });
    }

    function updateDocumentOrganizationPivots(
        pivots: SaveDocumentTransport['organizationsPivotsToUpdate'],
        visibility: SaveDocumentTransport['documentVisibility'],
        options: ModelOptionsType
    ) {
        // Update the visibility of the document for the organizations
        pivots.each((pivot: DocumentOrganizationPivot) => {
            pivot.attributes.staffs_access = visibility.staffs_access;
            pivot.attributes.parents_access = visibility.parents_access;
            pivot.attributes.web_access = visibility.web_access;
            pivot.save(options);
        });
    }

    function attachDocumentToResource(
        document: SaveDocumentTransport['document'],
        resource: SaveDocumentTransport['resource'],
        options: ModelOptionsType
    ) {
        // We attach the document to the resource provided (e.g. a child, a staff, etc.)
        DocumentModel.attachResource(document, resource.getKey(), resource.getType(), options);
    }

    /*
     * Runs the complete flow to save a document, attaching it to the organizations provided,
     * detaching it from the organizations provided, updating the visibility of the document
     * and attaching it to the resource provided
     */
    async function saveAndAttachDocument(
        document: SaveDocumentTransport['document'],
        file?: SaveDocumentTransport['file'],
        organizationsToAttach?: SaveDocumentTransport['organizationsToAttach'],
        organizationsToDetach?: SaveDocumentTransport['organizationsToDetach'],
        organizationsPivotsToUpdate?: SaveDocumentTransport['organizationsPivotsToUpdate'],
        documentVisibility?: SaveDocumentTransport['documentVisibility'],
        resource?: SaveDocumentTransport['resource']
    ) {
        const mqlRunner = new MqlTransaction();

        const documentExists = document.exists;

        if (file) {
            // If the document is not a link, we upload the file to the usercontent service
            // and save the key and hash to the model. We also remove the link from the model to avoid bugs
            await uploadFile(file, document);
        }

        saveDocument(document, {mqlRunner});

        if (organizationsToDetach) {
            detachDocumentFromOrganizations(document, organizationsToDetach, {mqlRunner});
        }

        if (organizationsToAttach && documentVisibility) {
            attachDocumentToOrganizations(document, organizationsToAttach, documentVisibility, {mqlRunner});
        }

        if (organizationsPivotsToUpdate && documentVisibility) {
            updateDocumentOrganizationPivots(organizationsPivotsToUpdate, documentVisibility, {mqlRunner});
        }

        if (!documentExists && resource) {
            attachDocumentToResource(document, resource, {mqlRunner});
        }

        await mqlRunner.run(true);
        await document.organizations().fresh();

        return document;
    }

    return {
        loading,
        saveAndAttachDocument,
    };
}
