import Pusher from 'pusher-js';
import route from '@/modules/legacy/libs/ziggy';
import type {AxiosInstance} from 'axios';
import type {BroadcastContract} from '@/modules/app/utils/BroadcastContract';
import {app, lumosBindings} from '@meekohq/lumos';
import services from '@/config/services';

export default class PusherBroadcast implements BroadcastContract {
    notificationEvent = 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated';
    sessionId = new Date().getTime();
    pusher: Pusher | undefined;
    privateChannel: any | undefined;
    sessionChannel: any | undefined;

    public setup(userId: string, apiToken: string) {
        return new Promise<void>(resolve => {
            this.initialize(apiToken);
            this.subscribePrivateChannel(userId);
            this.subscribeSessionChannel(userId);
            app(lumosBindings.SetupMqlBinding).connectBroadcast(this.pusher as any, userId);

            this.pusher?.connection.bind('connected', function () {
                resolve();
            });

            // Temp fix for pusher timeout
            setTimeout(() => {
                resolve();
            }, 5000);
        });
    }

    public disconnect() {
        if (this.pusher) {
            this.pusher.disconnect();
        }

        this.pusher = undefined;
    }

    public deleteAxiosSessionId(instance: AxiosInstance) {
        delete instance.defaults.headers['X-Broadcast-Session-Id'];
    }

    public setAxiosSessionId(instance: AxiosInstance) {
        instance.defaults.headers['X-Broadcast-Session-Id'] = this.sessionId;
    }

    protected initialize(apiToken) {
        this.pusher = new Pusher(services.pusher.key, {
            cluster: 'eu',
            authEndpoint: route('broadcasting.auth'),
            auth: {
                headers: {
                    Accept: 'application/json ',
                    Authorization: `Bearer ${apiToken}`,
                },
            },
        });
    }

    protected subscribePrivateChannel(user) {
        this.privateChannel = this.pusher?.subscribe(`private-App.Models.User.${user}`);
    }

    protected unsubscribePrivateChannel(user) {
        this.privateChannel = this.pusher?.unsubscribe(`private-App.Models.User.${user}`);
    }

    protected subscribeSessionChannel(user) {
        this.sessionChannel = this.pusher?.subscribe(`private-App.Models.User.${user}@${this.sessionId}`);
    }

    protected unsubscribeSessionChannel(user) {
        this.sessionChannel = this.pusher?.unsubscribe(`private-App.Models.User.${user}@${this.sessionId}`);
    }
}
