import type {PeriodFilterType} from '@/modules/legacy/components/Filter/PeriodFilterType';
import type TenantModel from '@/modules/cashier/models/TenantModel';
import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import type {ModelAvailableAttributesType, QueryBuilderOrderByDirectionType} from '@meekohq/lumos';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';

interface TransactionFilterDtoMakeType {
    perPage: number;
    page: number;
    period: PeriodFilterType;
    amountMin: number | null;
    amountMax: number | null;
    tenants: TenantModel[];
    customers: CustomerModel[];
    invoiceIds: string[];
    transactionIds: string[];
    paymentMethodName: string | undefined;
    hasRemainingAmount: boolean;
    search: string | undefined;
    orderBy: ModelAvailableAttributesType<TransactionModel> | undefined;
    queryDirection: QueryBuilderOrderByDirectionType | undefined;
}

export class TransactionFilterDto {
    constructor(
        public readonly perPage = 20,
        public readonly page = 1,
        public readonly period?: PeriodFilterType,
        public readonly amountMin?: number | null,
        public readonly amountMax?: number | null,
        public readonly tenants?: TenantModel[],
        public readonly customers?: CustomerModel[],
        public readonly invoiceIds?: string[],
        public readonly transactionIds?: string[],
        public readonly paymentMethodName?: string | undefined,
        public readonly hasRemainingAmount?: boolean,
        public readonly search?: string | undefined,
        public readonly orderBy: ModelAvailableAttributesType<TransactionModel> | undefined = 'date',
        public readonly queryDirection: QueryBuilderOrderByDirectionType | undefined = 'asc'
    ) {}

    public static make(values: Partial<TransactionFilterDtoMakeType>): TransactionFilterDto {
        return new TransactionFilterDto(
            values.perPage,
            values.page,
            values.period,
            values.amountMin,
            values.amountMax,
            values.tenants,
            values.customers,
            values.invoiceIds,
            values.transactionIds,
            values.paymentMethodName,
            values.hasRemainingAmount,
            values.search,
            values.orderBy,
            values.queryDirection
        );
    }
}
