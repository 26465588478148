<template>
    <div class="CustomerTabTransactions">
        <div class="CustomerTabTransactions_actions">
            <MButton
                v-if="can('create', 'transactions')"
                ref="createCreditRef"
                :to="{name: 'cashier/customers.show.payments.create', query: {type: TransactionTypeValue.credit}}"
            >
                <template #left-icons>
                    <FontAwesomeIcon
                        class="tw-text-success-500"
                        icon="fa-solid fa-arrow-up-right"
                    />
                </template>
                {{ __('billing_transaction:actions.create_credit') }}
            </MButton>
            <MButton
                v-if="can('create', 'transactions')"
                ref="createDebitRef"
                :to="{name: 'cashier/customers.show.payments.create', query: {type: TransactionTypeValue.debit}}"
            >
                <template #left-icons>
                    <FontAwesomeIcon
                        class="tw-text-danger-500"
                        icon="fa-solid fa-arrow-down-left"
                    />
                </template>
                {{ __('billing_transaction:actions.create_debit') }}
            </MButton>
        </div>
        <TransactionList
            :emitter="emitter"
            :filters-store="filtersStore"
            :show-more-filters="false"
            @deleted="$emit('refreshStats')"
            @rejected="$emit('refreshStats')"
            @restored="$emit('refreshStats')"
            @updated="$emit('refreshStats')"
        />
        <router-view
            :customer="customer"
            @created="onTransactionCreated"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type {EventType} from '@/modules/cashier/transaction/infrastructure/components/TransactionList.vue';
    import TransactionList from '@/modules/cashier/transaction/infrastructure/components/TransactionList.vue';
    import useFiltersState from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';
    import TransactionTypeValue from '@/modules/cashier/transaction/domain/TransactionTypeValue';
    import useAbility from '@/modules/app/composables/useAbility';
    import mitt from 'mitt';

    export default defineComponent({
        components: {
            TransactionList,
        },
        props: {
            customer: {
                type: Object as PropType<CustomerModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {can} = useAbility();
            const createCreditRef = ref();
            const createDebitRef = ref();

            const emitter = mitt<EventType>();

            const filtersStore = useFiltersState({
                period: {
                    defaultValue: {
                        from: null,
                        to: null,
                    },
                },
                customers: {
                    defaultValue: [props.customer],
                },
            });

            /**
             * Refresh the list when a transaction is created.
             */
            async function onTransactionCreated() {
                emit('refreshStats');
                emitter.emit('refresh');
            }

            return {
                can,
                createCreditRef,
                createDebitRef,
                emitter,
                onTransactionCreated,
                filtersStore,
            };
        },
        computed: {
            TransactionTypeValue() {
                return TransactionTypeValue;
            },
        },
    });
</script>

<style scoped>
    .CustomerTabTransactions {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .CustomerTabTransactions_actions {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-start tw-gap-2 md:tw-justify-end;
    }
</style>
