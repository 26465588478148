<script setup lang="ts">
    import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import useAbility from '@/modules/app/composables/useAbility';
    import {isNil} from 'lodash-es';
    import {computed, inject, toRefs} from 'vue';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import useSaveAllocations from '@/modules/cashier/payment/infrastructure/components/useSaveAllocations';
    import PaymentAllocationsListAbstractItem from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsListAbstractItem.vue';
    import PaymentAllocationsActions from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsActions.vue';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import type {SaveAllocationResultFailed} from '@/modules/cashier/payment/application/SaveAllocationAggregateResponseModel';
    import AvailableCreditAmount from '@/modules/cashier/customer/components/AvailableCreditAmount.vue';

    const props = defineProps<{
        allocations: AbstractAllocationAggregate[];
        countAllocatableWithRemaining: number;
        editingAllocations: boolean;
        invoice: LegacyInvoiceType;
        isFetching: boolean;
        payment: PaymentModel;
        paymentRemainingAmount: number;
    }>();

    const customer = inject('customer');

    const emit = defineEmits<{
        (e: 'saved'): void;
        (e: 'created'): void;
        (e: 'editingAllocations', editingAllocations: boolean): void;
    }>();

    const {can} = useAbility();
    const {format} = useFormatCurrency(props.invoice.currency_iso_code);

    const {allocations, paymentRemainingAmount} = toRefs(props);

    const {saveAll, isSaving, errors} = useSaveAllocations();

    function toggleEditingAllocations() {
        emit('editingAllocations', !props.editingAllocations);
    }

    async function onSaved() {
        if (await saveAll(allocations.value)) {
            toggleEditingAllocations();
            emit('saved');
        }
    }

    function onCanceled() {
        // Reset all allocations amount
        allocations.value.forEach(allocation => {
            allocation.resetAllocationAmount();
        });

        toggleEditingAllocations();
    }

    function getAllocationItemError(key: string): SaveAllocationResultFailed[] | null {
        if (isNil(errors.value)) {
            return [];
        }

        return errors.value.filter((error: SaveAllocationResultFailed) => error.sourceKey === key);
    }

    const hasError = computed(() => {
        return (
            paymentRemainingAmount.value < 0 ||
            allocations.value.some(allocation => {
                return isNil(allocation.amount) || allocation.amount > allocation.maxAllocatableAmount;
            })
        );
    });
</script>

<template>
    <div class="PaymentAllocationsAbstractList">
        <div class="PaymentAllocationsAbstractList__header">
            <div class="PaymentAllocationsAbstractList__sub-header">
                <MHeading v-if="payment && payment.isRefund === false">
                    {{ __('billing_invoice:transactions_linked') }}
                </MHeading>
                <MHeading v-else>
                    {{ __('billing_invoice:refunds_linked') }}
                </MHeading>
                <AvailableCreditAmount
                    v-if="customer.availableTotalCreditAmount > 0"
                    :customer="customer"
                />
            </div>
            <template v-if="can('update', 'transactions')">
                <MButton
                    v-if="!editingAllocations && allocations?.length > 0"
                    @click="toggleEditingAllocations"
                >
                    {{ __('billing_transaction:actions.edit_allocations') }}
                    <CBadge
                        v-if="paymentRemainingAmount > 0 && countAllocatableWithRemaining > 0"
                        size="sm"
                        variant="blue"
                    >
                        {{ countAllocatableWithRemaining }}
                    </CBadge>
                </MButton>
                <div
                    v-if="editingAllocations"
                    class="tw-flex tw-items-center tw-gap-2"
                >
                    <MButton
                        :label="__('common:actions.cancel')"
                        @click="onCanceled"
                    />
                    <MButton
                        :disabled="hasError"
                        :label="__('common:actions.save')"
                        :loading="isSaving"
                        variant="primary"
                        @click="onSaved"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-save" />
                        </template>
                    </MButton>
                </div>
            </template>
        </div>
        <Loader v-if="isFetching" />
        <div
            v-else
            class="PaymentAllocationsAbstractList__list"
        >
            <div
                v-for="allocation in allocations"
                :key="allocation.allocation?.getKey()"
                class="PaymentAllocationsAbstractList__list-item"
            >
                <PaymentAllocationsListAbstractItem
                    :allocation="allocation"
                    :currency="invoice.currency_iso_code"
                    :editable="editingAllocations"
                    :saving-error="getAllocationItemError(allocation.sourceKey)"
                >
                    <template #item>
                        <slot
                            :allocation="allocation"
                            name="item"
                        />
                    </template>
                </PaymentAllocationsListAbstractItem>
            </div>
        </div>
        <div class="PaymentAllocationsAbstractList__actions">
            <PaymentAllocationsActions
                v-if="can('create', 'transactions')"
                :editing-allocations="editingAllocations"
                :invoice="invoice"
                :payment="payment"
                @created="emit('created')"
            />
        </div>
        <MHeading
            class="PaymentAllocationsAbstractList__remaining-amount"
            level="h3"
            :variant="paymentRemainingAmount < 0 ? 'danger' : 'base'"
        >
            {{ __('billing_invoice:outstanding_balance_amount_colon', {amount: format(paymentRemainingAmount)}) }}
        </MHeading>
    </div>
</template>

<style scoped>
    .PaymentAllocationsAbstractList__header {
        @apply tw-mb-4 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2;
    }

    .PaymentAllocationsAbstractList__list {
        @apply tw-flex tw-flex-col tw-gap-4;
    }

    .PaymentAllocationsAbstractList__actions {
        @apply tw-mt-4 tw-flex tw-flex-wrap tw-gap-2;
    }

    .PaymentAllocationsAbstractList__remaining-amount {
        @apply tw-mt-4 tw-text-end;
    }
</style>
