import {ref} from 'vue';
import i18next from 'i18next';

export type AvailableLanguage = 'de' | 'en' | 'fr';

// Not need to translate this because languages are always in specific languages
const availableLanguages: {value: AvailableLanguage; text: string}[] = [
    {value: 'fr', text: 'Français'},
    {value: 'en', text: 'English'},
    {value: 'de', text: 'Deutsch'},
];

export default function () {
    // Language currently used by the application (if not logged in, it's the browser language)
    // We split the language to remove the country code (e.g. 'en-GB' => 'en')
    const currentLanguage = ref(i18next.language.split('-')[0]);

    return {
        availableLanguages,
        currentLanguage,
    };
}
