import {Model} from '@meekohq/lumos';
import CurrencySymbolToIsoCode from '@/modules/cashier/utils/core/currency/CurrencySymbolToIsoCode';
import TenantModel from '@/modules/cashier/models/TenantModel';

export default class CurrencyModel extends Model {
    public type = 'cashier/currencies';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        code: string | undefined;
        archived_at: string | undefined | null;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
        id: this.uuid(),
        tenant_id: undefined,
        code: undefined,
        archived_at: undefined,
        created_at: undefined,
        updated_at: undefined,
    };

    /**
     * Get the currency symbol from the code
     * @returns {string}
     */
    get symbol() {
        const symbols = Object.keys(CurrencySymbolToIsoCode).filter(
            code => CurrencySymbolToIsoCode[code] === this.attributes.code
        );

        return symbols.length > 0 ? symbols[0] : '';
    }

    tenant() {
        return this.belongsTo('tenant', TenantModel, 'tenant_id');
    }
}
