<template>
    <div>
        <CCard
            :border="false"
            class="tw-col-span-2 tw-border-t tw-border-purple-400"
            :shadow="true"
        >
            <template #header>
                <div class="tw-flex tw-justify-between">
                    <div>
                        <div
                            class="tw-flex tw-items-center tw-font-display tw-text-[13px] tw-font-medium tw-tracking-wide tw-text-purple-500"
                        >
                            <FontAwesomeIcon
                                class="tw-mr-2 tw-text-purple-500"
                                icon="fa-duotone fa-address-book"
                            />{{ __('common:family') }}
                        </div>
                        <MHeading class="tw-mt-4">
                            <transition
                                mode="out-in"
                                name="transition-placeholder"
                            >
                                <div
                                    v-if="familyModel"
                                    class="tw-w-48"
                                >
                                    {{ familyModel.attributes.name }}
                                </div>
                                <CSkeleton
                                    v-else
                                    class="tw-w-48"
                                    size="lg"
                                />
                            </transition>
                        </MHeading>
                    </div>
                    <div>
                        <div
                            v-if="familyModel"
                            class="tw-flex tw-gap-2"
                        >
                            <MButton @click="$refs.updateFamily.show()">
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-duotone fa-pen" />
                                </template>
                                {{ __('common:actions.update') }}
                            </MButton>
                            <FamilyActionButton
                                :family-model="familyModel"
                                @deleted="
                                    $router.push({
                                        name: 'families.index',
                                        params: {nursery: manager.activeOrganization.id},
                                    })
                                "
                                @merged="onMergedFamily"
                            />
                        </div>
                        <CSkeleton
                            v-else
                            class="tw-w-36"
                            size="xl"
                        />
                        <UpdateFamilyModal
                            v-if="familyModel"
                            ref="updateFamily"
                            :family-model="familyModel"
                        />
                    </div>
                </div>
            </template>
            <div class="tw-flex tw-flex-col tw-gap-4 md:tw-flex-row md:tw-justify-between">
                <div class="tw-order-2 tw-flex-1 md:tw-order-1">
                    <div
                        v-if="loader.isDone() && (kidModelsOrdered.length || memberModelsOrdered.length)"
                        class="tw-flex tw-flex-col tw-gap-6"
                    >
                        <div
                            v-if="kidModelsOrdered.length"
                            class="tw-flex tw-flex-col tw-gap-2"
                        >
                            <MHeading level="h3">
                                {{ __('common:kid_other') }}
                            </MHeading>
                            <transition-group
                                class="tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-gap-y-2"
                                name="zoom-300"
                                tag="div"
                            >
                                <template v-if="$can('read', 'kids_details')">
                                    <KidCard
                                        v-for="kidModel in kidModelsOrdered"
                                        :key="'kid-' + kidModel.id"
                                        class="sm:tw-w-52"
                                        :groups="manager.activeOrganization.extra.legacy_groups"
                                        :kid-model="kidModel"
                                    />
                                </template>
                            </transition-group>
                        </div>
                        <div
                            v-if="memberModelsOrdered.length"
                            class="tw-flex tw-flex-col tw-gap-2"
                        >
                            <MHeading level="h3">
                                {{ __('family_family:parents_and_relatives') }}
                            </MHeading>
                            <transition-group
                                class="tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-gap-y-2"
                                name="zoom-300"
                                tag="div"
                            >
                                <template v-if="$can('read', 'family_members_details')">
                                    <MemberCard
                                        v-for="memberModel in memberModelsOrdered"
                                        :key="'member-' + memberModel.id"
                                        class="tw-group tw-relative sm:tw-w-52"
                                        :member-model="memberModel"
                                    >
                                        <div
                                            class="ShowFamily__detach-button"
                                            @click.prevent="askToDetach(memberModel)"
                                        >
                                            <FontAwesomeIcon
                                                class="tw-text-xs"
                                                icon="fa-solid fa-link-slash"
                                            />
                                        </div>
                                    </MemberCard>
                                </template>
                            </transition-group>
                        </div>
                    </div>
                    <div
                        v-else-if="loader.isLoading()"
                        class="tw-flex tw-flex-col tw-gap-6"
                    >
                        <div
                            v-for="i in 2"
                            :key="i"
                        >
                            <CSkeleton
                                class="tw-mb-2 tw-w-full tw-max-w-40"
                                :count="1"
                                size="lg"
                            />
                            <CSkeleton
                                class="tw-w-full tw-max-w-80"
                                :count="3"
                                layout="grid"
                                size="lg"
                            />
                        </div>
                    </div>
                    <MagicIndexEmptyList v-else>
                        <template #empty-list>
                            {{ __('family_family:empty_family_index') }}
                        </template>
                        <template #empty-list-action>
                            {{ __('family_family:try_adding_a_child_or_parent') }}
                        </template>
                    </MagicIndexEmptyList>
                </div>
                <div class="md: tw-order-1 tw-border-gray-200 md:tw-order-2 md:tw-border-l-2 md:tw-pl-4">
                    <div class="tw-grid tw-grid-cols-1 tw-grid-rows-3 tw-gap-3">
                        <MButton
                            v-if="$can('create', 'kids_details')"
                            class="tw-w-full tw-text-left"
                            @click="$refs.createKidModal.show()"
                        >
                            <template #left-icons>
                                <IconKidPlus class="tw-h-5" />
                            </template>
                            {{ __('family_kid:add_kid') }}
                        </MButton>
                        <CreateKidModal
                            ref="createKidModal"
                            :family-model="familyModel"
                            :organization-model="manager.activeOrganization"
                            @created="kidModels.unshift($event)"
                        />
                        <MButton
                            v-if="$can('create', 'family_members_details')"
                            class="tw-w-full tw-text-left"
                            @click="$refs.createMemberModal.show()"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-user-plus" />
                            </template>
                            {{ __('family_family:add_parent') }}
                        </MButton>
                        <AddOrCreateMemberModal
                            v-if="familyModel"
                            ref="createMemberModal"
                            :family-model="familyModel"
                            :organization-model="manager.activeOrganization"
                            @created="memberModels.unshift($event)"
                            @selected="memberModels.unshift($event)"
                        />
                        <ShowCustomerButton
                            v-if="allows('access-to-billings-module') && customerModels.length"
                            class="tw-w-full"
                            :customer-models="customerModels"
                        >
                            <MButton
                                class="tw-w-full"
                                :icon-external-link="true"
                                variant="link-orange"
                            >
                                <template #left-icons>
                                    <font-awesome-icon icon="fa-duotone fa-wallet" />
                                </template>
                                {{ __('family_family:show_billing_button') }}
                            </MButton>
                        </ShowCustomerButton>
                    </div>
                </div>
            </div>
        </CCard>
        <AlertModal
            v-if="isDetachConfirmModalVisible"
            :confirm-text="__('common:actions.detach')"
            :text="__('family_family:detach_ask')"
            :title="__('common:actions.detach')"
            @confirmed="detachMember"
            @hidden="isDetachConfirmModalVisible = !isDetachConfirmModalVisible"
        />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import {useRoute, useRouter} from 'vue-router/composables';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import {sortBy} from 'lodash-es';
    import KidCard from '@/modules/family/components/kid/KidCard.vue';
    import AlertModal from '@/modules/app/components/organisms/AlertModal.vue';
    import MemberCard from '@/modules/family/components/member/MemberCard.vue';
    import type KidModel from '@/modules/family/models/KidModel';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import CreateKidModal from '@/modules/family/components/kid/CreateKidModal.vue';
    import Manager from '@/modules/legacy/store/manager.store';
    import ShowCustomerButton from '@/modules/family/components/family/ShowCustomerButton.vue';
    import UpdateFamilyModal from '@/modules/family/components/family/UpdateFamilyModal.vue';
    import IconKidPlus from '@/modules/legacy/components/icons/IconKidPlus.vue';
    import AddOrCreateMemberModal from '@/modules/family/components/member/AddOrCreateMemberModal.vue';
    import FamilyActionButton from '@/modules/family/components/family/FamilyActionButton.vue';
    import KidMemberPivot from '@/modules/family/models/KidMemberPivot';
    import FamilyMemberPivot from '@/modules/family/models/FamilyMemberPivot';
    import {MqlTransaction} from '@meekohq/lumos';
    import MagicIndexEmptyList from '@/modules/magic-index/components/atoms/MagicIndexEmptyList.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useManager from '@/modules/app/composables/useManager';

    export default defineComponent({
        components: {
            MagicIndexEmptyList,
            AlertModal,
            FamilyActionButton,
            AddOrCreateMemberModal,
            IconKidPlus,
            UpdateFamilyModal,
            ShowCustomerButton,
            KidCard,
            MemberCard,
            CreateKidModal,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const familyModel = ref<FamilyModel>();
            const customerModels = ref([]);
            const kidModels = ref([]);
            const memberModels = ref([]);
            const loader = ref(new Loader());
            const isDetachConfirmModalVisible = ref(false);
            const memberToDetach = ref<MemberModel>();
            const router = useRouter();
            const route = useRoute();
            const {allows} = useAbility();
            const {activeOrganization} = useManager();

            const manager = computed(() => {
                return Manager;
            });
            const kidModelsOrdered = computed(() => {
                return sortBy(kidModels.value, (kid: KidModel) => kid.fullname);
            });

            const memberModelsOrdered = computed(() => {
                return sortBy(memberModels.value, (member: MemberModel) => member.fullname);
            });
            const fetchFamily = async () => {
                loader.value.start();

                const familyId = route.params.family;
                try {
                    const response = await FamilyModel.query()
                        .where('id', familyId)
                        .with(new FamilyModel().organizationsPivot())
                        .with(new FamilyModel().kids())
                        .with(new FamilyModel().members())
                        .with(new FamilyModel().customers())
                        .first();

                    const familyOrganizationIds: number[] = response
                        .organizationsPivot()
                        .value()
                        .pluck('attributes.organization_id')
                        .all();
                    const isFamilyInOrganization = familyOrganizationIds.includes(props.nursery.id.toString());

                    if (isFamilyInOrganization) {
                        familyModel.value = response;
                        customerModels.value = response.customers().value().all();
                        kidModels.value = response.kids().value().all();
                        memberModels.value = response.members().value().all();
                        loader.value.stop();
                    } else {
                        redirectUser(familyOrganizationIds);
                        loader.value.stop();
                    }
                } catch (error) {
                    loader.value.stop();
                }
            };

            const redirectUser = (familyOrganizationIds: number[]) => {
                if (familyOrganizationIds && familyOrganizationIds.length) {
                    router.push({
                        name: 'families.show',
                        params: {
                            nursery: familyOrganizationIds[0].toString(),
                        },
                    });
                } else {
                    router.push({
                        name: 'families.index',
                        params: {
                            nursery: props.nursery.id,
                        },
                    });
                }
            };

            const askToDetach = (memberModel: MemberModel) => {
                memberToDetach.value = memberModel;
                isDetachConfirmModalVisible.value = !isDetachConfirmModalVisible.value;
            };

            const detachMember = () => {
                loader.value.start();
                const familyId = route.params.family;
                const kidMemberQuery = KidMemberPivot.query()
                    .where('family_member_id', memberToDetach.value?.getKey())
                    .whereHas('kid', subquery => {
                        subquery.where('family_id', familyId);
                    });
                const familyMemberQuery = FamilyMemberPivot.query()
                    .where('family_id', familyId)
                    .where('member_id', memberToDetach.value?.getKey());

                const mqlRunner = new MqlTransaction();

                familyMemberQuery.delete({mqlRunner});
                kidMemberQuery.delete({mqlRunner});

                mqlRunner
                    .run()
                    .then(() => {
                        memberModels.value = memberModels.value.filter(
                            (member: MemberModel) => member.getKey() != memberToDetach.value?.getKey()
                        );
                        isDetachConfirmModalVisible.value = false;
                    })
                    .finally(() => loader.value.stop());
            };

            function onMergedFamily(family: FamilyModel) {
                if (route.params.family === family.getKey()) {
                    fetchFamily();

                    return;
                }

                router.push({
                    name: 'families.show',
                    params: {
                        nursery: activeOrganization.value.getKey(),
                        family: family.getKey(),
                    },
                });
            }

            watch(
                () => route.params.family,
                () => {
                    fetchFamily();
                },
                {immediate: true}
            );

            return {
                fetchFamily,
                familyModel,
                customerModels,
                kidModels,
                isDetachConfirmModalVisible,
                memberModels,
                loader,
                redirectUser,
                askToDetach,
                detachMember,
                manager,
                kidModelsOrdered,
                memberModelsOrdered,
                allows,
                onMergedFamily,
            };
        },
    });
</script>

<style scoped>
    .ShowFamily__detach-button {
        @apply tw-invisible group-hover:tw-visible;
        @apply tw-absolute tw--right-2 tw--top-2;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-rounded-full tw-bg-gray-200 tw-p-1 tw-text-gray-500;
        @apply hover:tw-cursor-pointer hover:tw-bg-gray-500 hover:tw-text-white;
    }
</style>
