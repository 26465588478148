<script setup lang="ts">
    import type {PropType} from 'vue';
    import {toRef} from 'vue';
    import type {OutputValueType} from '@/modules/meeko-ui/composables/useMDatePicker';
    import useMDatePicker from '@/modules/meeko-ui/composables/useMDatePicker';
    import MDatePickerInput from '@/modules/meeko-ui/components/MDatePicker/MDatePickerInput.vue';
    import MDatePickerContent from '@/modules/meeko-ui/components/MDatePicker/MDatePickerContent.vue';
    import usePopover from '@/modules/app/composables/usePopover';

    const props = defineProps({
        value: {type: [String, Object] as PropType<OutputValueType>, default: undefined},
        /**
         * @values dd/MM/yyyy, dd/MM/yyyy HH:mm, iso8601
         */
        format: {
            type: String,
            default: 'iso8601',
        },
        allowUndefined: {
            type: Boolean,
            default: false,
        },
        withTime: {
            type: Boolean,
            default: false,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    });

    const store = useMDatePicker(
        toRef(props, 'value'),
        toRef(props, 'format'),
        toRef(props, 'allowUndefined'),
        toRef(props, 'withTime')
    );

    const {isInputEditable} = store;

    const emit = defineEmits<{
        (e: 'input', value: OutputValueType): void;
    }>();

    const popover = usePopover();

    defineExpose({
        show: popover.show,
        hide: popover.hide,
        toggle: popover.toggle,
    });

    function valueChanged(value: OutputValueType) {
        emit('input', value);

        if (!props.withTime) {
            popover.hide();
        }
    }
</script>

<template>
    <CPopover
        :clickable="!disabled"
        :popover="popover"
        prevent-hide-on-reference-click
        @hidden="isInputEditable = false"
    >
        <MDatePickerInput
            :disabled="disabled"
            :has-error="hasError"
            :store="store"
            :with-time="withTime"
            @hide="popover.hide"
            @show="popover.show"
        />
        <template #content>
            <MDatePickerContent
                :store="store"
                @input="valueChanged($event)"
            />
        </template>
    </CPopover>
</template>

<style scoped></style>
