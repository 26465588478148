<template>
    <div />
</template>

<script lang="ts">
    import {defineComponent, onMounted} from 'vue';
    import {useRouter} from 'vue-router/composables';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import TransactionModalCreate from '@/modules/cashier/transaction/infrastructure/components/TransactionModalCreate.vue';
    import useManager from '@/modules/app/composables/useManager';
    import useModal from '@/modules/app/composables/useModal';

    export default defineComponent({
        setup(props, {emit}) {
            const router = useRouter();
            const {activeTenant} = useManager();

            function showCreateTransactionModal() {
                useModal({
                    component: TransactionModalCreate,
                    props: {
                        canSelectCustomer: true,
                        tenantId: activeTenant.value.getKey(),
                    },
                    listeners: () => ({
                        created: (transaction: TransactionModel) => {
                            onCreated(transaction);
                        },
                        hidden: () => {
                            redirect();
                        },
                    }),
                }).show();
            }

            onMounted(() => {
                showCreateTransactionModal();
            });

            function redirect() {
                router.push({name: 'payments.index'});
            }

            function onCreated(transaction: TransactionModel) {
                emit('created', transaction);
            }

            return {};
        },
    });
</script>
