<template>
    <div
        class="CButtonGroup"
        :class="styling"
    >
        <slot />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type {ResponsivePropType} from '@/modules/coherence-ui/hooks/useResponsiveProp';
    import useResponsiveProp from '@/modules/coherence-ui/hooks/useResponsiveProp';

    export default defineComponent({
        components: {},
        props: {
            direction: {type: [String, Object, Array] as PropType<ResponsivePropType<string>>, default: 'row'},
            gridCols: {type: [Number, Object, Array] as PropType<ResponsivePropType<number>>, default: 2},
            gridGap: {type: [Number, Object, Array] as PropType<ResponsivePropType<number>>, default: 2},
        },
        setup(props) {
            const {prop: directionValue} = useResponsiveProp(props.direction, 'row');
            const {prop: gridColsValue} = useResponsiveProp(props.gridCols, 2);
            const {prop: gridGapValue} = useResponsiveProp(props.gridGap, 2);

            const styling = computed(() => {
                const output = [] as any[];

                output.push(directionValues[directionValue.value]);

                if (directionValue.value === 'grid') {
                    output.push(gridColsValues[gridColsValue.value]);
                    output.push(gridGapValues[gridGapValue.value]);
                }

                return output;
            });

            return {
                styling,
            };
        },
    });

    const directionValues = {
        row: 'CButtonGroup--flex-row',
        column: 'CButtonGroup--flex-column',
        grid: 'CButtonGroup--grid',
    };

    const gridColsValues = {
        1: 'CButtonGroup--grid-cols-1',
        2: 'CButtonGroup--grid-cols-2',
        3: 'CButtonGroup--grid-cols-3',
        4: 'CButtonGroup--grid-cols-4',
    };

    const gridGapValues = {
        1: 'CButtonGroup--grid-gap-1',
        2: 'CButtonGroup--grid-gap-2',
        3: 'CButtonGroup--grid-gap-3',
        4: 'CButtonGroup--grid-gap-4',
    };
</script>

<style>
    .CButtonGroup--grid {
        @apply tw-grid;

        &-cols-1 {
            @apply tw-grid-cols-1;
        }

        &-cols-2 {
            @apply tw-grid-cols-2;
        }

        &-cols-3 {
            @apply tw-grid-cols-3;
        }

        &-cols-4 {
            @apply tw-grid-cols-4;
        }

        &-gap-1 {
            @apply tw-gap-1;
        }

        &-gap-2 {
            @apply tw-gap-2;
        }

        &-gap-3 {
            @apply tw-gap-3;
        }

        &-gap-4 {
            @apply tw-gap-4;
        }
    }

    .CButtonGroup--flex-row {
        @apply tw-inline-flex;

        > .MPopover > * > *,
        > .FeatureFlag > .MMenu > * > *,
        > .FeatureFlag > *,
        > #downloadWordFile > *,
        > .MMenu > * > *,
        > .MTooltip > *,
        > .CFormTextArea,
        > .CButton,
        > .CInput,
        > .CInputAddon,
        > .CTooltip > *,
        > .CPopover > .CPopover__reference > *,
        > .CDropdown > .CDropdown__clickable > *,
        > .CFileInput > .CFileInput__button {
            @apply tw-ml-px;
            border-radius: 0 !important;

            &:focus {
                z-index: 10;
            }
        }

        > :first-child {
            &.MPopover > * > *,
            &.FeatureFlag > .MMenu > * > *,
            &.FeatureFlag > *,
            &#downloadWordFile > *,
            &.MMenu > * > *,
            &.MTooltip > *,
            &.CFormTextArea,
            &.CButton,
            &.CInput,
            &.CInputAddon,
            &.CTooltip > *,
            &.CPopover > .CPopover__reference > *,
            &.CDropdown > .CDropdown__clickable > *,
            &.CFileInput > .CFileInput__button {
                border-top-left-radius: theme(borderRadius.lg) !important;
                border-bottom-left-radius: theme(borderRadius.lg) !important;
            }
        }

        > :last-child {
            &.MPopover > * > *,
            &.FeatureFlag > .MMenu > * > *,
            &.FeatureFlag > *,
            &#downloadWordFile > *,
            &.MMenu > * > *,
            &.MTooltip > *,
            &.CFormTextArea,
            &.CButton,
            &.CInput,
            &.CInputAddon,
            &.CTooltip > *,
            &.CPopover > .CPopover__reference > *,
            &.CDropdown > .CDropdown__clickable > *,
            &.CFileInput > .CFileInput__button {
                border-top-right-radius: theme(borderRadius.lg) !important;
                border-bottom-right-radius: theme(borderRadius.lg) !important;
            }
        }
    }

    .CButtonGroup--flex-column {
        @apply tw-block;

        > .MPopover > * > *,
        > .FeatureFlag > .MMenu > * > *,
        > .FeatureFlag > *,
        > #downloadWordFile > *,
        > .MMenu > * > *,
        > .MTooltip > *,
        > .CFormTextArea,
        > .CButton,
        > .CInput,
        > .CInputAddon,
        > .CTooltip > *,
        > .CPopover > .CPopover__reference > *,
        > .CDropdown > .CDropdown__clickable > *,
        > .CFileInput > .CFileInput__button {
            @apply tw--mt-px;
            border-radius: 0 !important;

            &:focus {
                z-index: 10;
            }
        }

        > :first-child {
            &.MPopover > * > *,
            &.FeatureFlag > .MMenu > * > *,
            &.FeatureFlag > *,
            &#downloadWordFile > *,
            &.MMenu > * > *,
            &.MTooltip > *,
            &.CFormTextArea,
            &.CButton,
            &.CInput,
            &.CInputAddon,
            &.CTooltip > *,
            &.CPopover > .CPopover__reference > *,
            &.CDropdown > .CDropdown__clickable > *,
            &.CFileInput > .CFileInput__button {
                border-top-right-radius: theme(borderRadius.lg) !important;
                border-top-left-radius: theme(borderRadius.lg) !important;
            }
        }

        > :last-child {
            &.MPopover > * > *,
            &.FeatureFlag > .MMenu > * > *,
            &.FeatureFlag > *,
            &#downloadWordFile > *,
            &.MMenu > * > *,
            &.MTooltip > *,
            &.CFormTextArea,
            &.CButton,
            &.CInput,
            &.CInputAddon,
            &.CTooltip > *,
            &.CPopover > .CPopover__reference > *,
            &.CDropdown > .CDropdown__clickable > *,
            &.CFileInput > .CFileInput__button {
                border-bottom-right-radius: theme(borderRadius.lg) !important;
                border-bottom-left-radius: theme(borderRadius.lg) !important;
            }
        }
    }
</style>
