<script setup lang="ts">
    import type {ComputedRef} from 'vue';
    import {computed, onMounted, ref, watch} from 'vue';
    import SelectAddress from '@/modules/cashier/components/core/address/organisms/SelectAddress.vue';
    import SelectCustomer from '@/modules/cashier/components/core/customer/organisms/SelectCustomer.vue';
    import NotificationsOfflineList from '@/modules/cashier/components/core/notification/organisms/NotificationsOfflineList.vue';
    import CustomerModalCreateWithAddress from '@/modules/cashier/components/core/customer/pages/CustomerModalCreateWithAddress.vue';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import OrderModel from '@/modules/cashier/models/OrderModel';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import TenantModel from '@/modules/cashier/models/TenantModel';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type AddressModel from '@/modules/cashier/models/AddressModel';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import type {PayloadType} from '@/modules/family/utils/kid/LegacyContractIntegrationService';
    import useAuth from '@/modules/app/composables/useAuth';
    import useAbility from '@/modules/app/composables/useAbility';
    import type ContactModel from '@/modules/cashier/models/ContactModel';

    const {legacyOrganization, contract, kid, company, payload} = withDefaults(
        defineProps<{
            legacyOrganization: any;
            contract: any;
            kid?: any;
            company?: boolean;
            payload: PayloadType;
        }>(),
        {
            company: false,
            kid: undefined,
        }
    );

    const emit = defineEmits<{
        (e: 'hasCustomer', value: boolean): void;
    }>();

    const {can} = useAbility();

    const familyModel = ref<FamilyModel>(payload.familyModel!);
    const selectCustomerModalCreateWithAddress = ref(null);

    const tenantModel = ref(new TenantModel());
    const customerModel = ref<CustomerModel | undefined>(undefined);
    const billingAddressModel = ref<AddressModel | undefined>(undefined);
    const loader = ref(new Loader());
    const showCustomerModalCreateWithAddress = ref(false);
    const orderModel = ref<OrderModel | undefined>(undefined);

    const modalityMembers = computed(() => {
        let firstMember = memberModels.value?.find(member => {
            return isPriorityMember(member, contract.first_family_member_id);
        });

        // If there is no mother or father, we set the first suggested member as the first member of the array
        if (!firstMember && memberModels.value && memberModels.value?.length > 0) {
            firstMember = memberModels.value[0];
        }

        let secondMember = memberModels.value?.find(member => {
            // We exclude the first member if there is one to avoid duplicates
            if (member.getKey() === firstMember?.getKey()) {
                return false;
            }

            return isPriorityMember(member, contract.second_family_member_id);
        });

        // If there is no mother or father, we set the second suggested member as the first unused member of the array
        if (!secondMember && memberModels.value && memberModels.value?.length > 1) {
            secondMember = memberModels.value.find(member => member.getKey() !== firstMember?.getKey());
        }

        const members: MemberModel[] = [];

        // We push the members to the array if they exist as the kid can have no family members yet
        if (firstMember) {
            members.push(firstMember);
        }

        if (secondMember) {
            members.push(secondMember);
        }

        return members;
    });

    const userEmail = computed(() => {
        const user = useAuth().user.value;
        if (user && user.attributes.is_owner && user.attributes.email) {
            return user.attributes.email;
        }

        return null;
    });

    const autoSubscribeMember = computed(() => {
        return contract?.[contractOrderIdKey.value] === null;
    });

    const isLocked = computed(() => {
        if (!can('update', 'invoices')) {
            return true;
        }

        return orderModel.value && orderModel.value.attributes.status === OrderModel.SUCCEEDED;
    });

    const customerBuilder = computed(() => {
        if (company) {
            return CustomerModel.query().with(new CustomerModel().addresses()).with(new CustomerModel().orders());
        }

        if (familyModel.value !== undefined) {
            return CustomerModel.query()
                .with(new CustomerModel().addresses())
                .with(new CustomerModel().orders())
                .whereHas(new CustomerModel().families(), query => {
                    query.where('id', familyModel.value!.getKey() as string);
                });
        }

        return CustomerModel.query().with(new CustomerModel().orders());
    });

    const memberModels = computed(() => {
        if (familyModel.value && !company) {
            return familyModel.value.members().value().all();
        } else {
            return undefined;
        }
    });

    const contractOrderIdKey = computed(() => {
        return company ? 'company_order_id' : 'family_order_id';
    });

    const endpoints: ComputedRef<(ContactModel | MemberModel)[]> = computed(() => {
        if (company) {
            return payload.companyEndpoints;
        }

        return payload.familyEndpoints;
    });

    // Methods
    function isMemberSetInContract(member, contractMemberId) {
        return member.getKey() === contractMemberId;
    }

    function isMemberParent(member) {
        // Get the member role for the kid
        const memberRole = member.kids().value().first()?.pivot().attributes.role;

        // If the member role is mother or father we prioritize it over other roles
        return memberRole === 'mother' || memberRole === 'father';
    }

    function isPriorityMember(member, contractMemberId) {
        return isMemberSetInContract(member, contractMemberId) || isMemberParent(member);
    }

    function endpointsChanged(newEndpoints) {
        if (company) {
            payload.companyEndpoints = newEndpoints;
        } else {
            payload.familyEndpoints = newEndpoints;
        }
    }

    watch(familyModel, () => {
        if (familyModel.value) {
            payload.familyModel = familyModel.value as FamilyModel;
        }
    });

    watch(orderModel, () => {
        if (company) {
            payload.companyOrderModel = orderModel.value as OrderModel;
        } else {
            payload.familyOrderModel = orderModel.value as OrderModel;
        }
    });

    watch(billingAddressModel, addressModel => {
        if (orderModel.value) {
            orderModel.value.attributes.billing_address_id = addressModel ? addressModel.getKey() : null;
            if (addressModel) {
                orderModel.value.defaultBillingAddress().set(addressModel as AddressModel);
            }
        }
    });

    watch(customerModel, newCustomer => {
        if (orderModel.value) {
            orderModel.value.attributes.customer_id = newCustomer ? newCustomer.id : null;
            if (newCustomer) {
                orderModel.value.customer().set(newCustomer as CustomerModel);
            }
        }

        if (newCustomer) {
            emit('hasCustomer', true);
            if (orderModel.value) {
                orderModel.value.extra.markForDelete = false;
            }
        } else {
            emit('hasCustomer', false);
            if (orderModel.value) {
                if (orderModel.value.exists) {
                    orderModel.value.extra.markForDelete = true;
                } else {
                    orderModel.value = undefined;
                }

                contract[contractOrderIdKey.value] = null;

                // Reset the endpoints when the customer is removed
                if (company) {
                    payload.companyEndpoints = [];
                } else {
                    payload.familyEndpoints = [];
                }
            }
        }
    });

    function onCustomerSelected(customer) {
        customerModel.value = customer;

        if (!orderModel.value) {
            orderModel.value = new OrderModel();
            orderModel.value.attributes.tenant_id = tenantModel.value.getKey();
        }

        if (customerModel.value) {
            billingAddressModel.value = customerModel.value.addresses().value().first();
        } else {
            billingAddressModel.value = undefined;
        }
    }

    onMounted(() => {
        const promises = [fetchDefaultTenant()] as Promise<any>[];
        if (kid) {
            promises.push(fetchFamily());
        }

        Promise.all(promises).then(() => {
            // If switched from tab and order already defined
            orderModel.value = company ? payload.companyOrderModel : payload.familyOrderModel;
            if (orderModel.value) {
                getCustomerAndAddressFromLocalOrder();
            } else if (contract.id) {
                if (contract[contractOrderIdKey.value]) {
                    getOrderCustomerAndAddressById();
                } else {
                    getOrderCustomerAndAddressByNumber();
                }
            } else if (!company && !contract.id && !contract.preventAutoSelectFamily) {
                autoSelectCustomerAndAddressFromDatabase();
                contract.preventAutoSelectFamily = true;
            }
        });
    });

    // Other functions
    function fetchDefaultTenant() {
        return new Promise<void>(resolve => {
            loader.value.start('tenant');
            TenantModel.query()
                .with(new TenantModel().organizations())
                .whereHas(new TenantModel().organizations(), query => {
                    query.where('id', legacyOrganization.id);
                })
                .get()
                .then(response => {
                    loader.value.stop('tenant');
                    tenantModel.value = response.first();
                    resolve();
                });
        });
    }

    function getCustomerAndAddressFromLocalOrder() {
        customerModel.value = orderModel.value?.customer().value();
        billingAddressModel.value = orderModel.value?.defaultBillingAddress().value();
    }

    function getOrderCustomerAndAddressByNumber() {
        loader.value.start('order');

        const number =
            contractOrderIdKey.value === 'company_order_id'
                ? `${contract.no}-E-${contract.id}`
                : `${contract.no}-${contract.id}`;

        OrderModel.query()
            .with(new OrderModel().customer(), query => {
                query.with(new CustomerModel().orders());
            })
            .with(new OrderModel().defaultBillingAddress())
            .where('number', number)
            .first()
            .then((order: OrderModel) => {
                loader.value.stop('order');

                orderModel.value = order;
                customerModel.value = order?.customer().value();
                billingAddressModel.value = order?.defaultBillingAddress().value();
            });
    }

    function getOrderCustomerAndAddressById() {
        loader.value.start('order');

        OrderModel.query()
            .with(new OrderModel().customer(), query => {
                query.with(new CustomerModel().orders());
            })
            .with(new OrderModel().defaultBillingAddress())
            .find(contract[contractOrderIdKey.value])
            .then((order: OrderModel) => {
                loader.value.stop('order');

                orderModel.value = order;
                customerModel.value = order?.customer().value();
                billingAddressModel.value = order?.defaultBillingAddress().value();
            });
    }

    async function fetchFamily() {
        familyModel.value = await FamilyModel.query()
            .with(new FamilyModel().members(), query => {
                // Eager load the kid to have the pivot with the family member roles for this kid
                query.with(new FamilyModel().kids(), query1 => {
                    query1.where('id', kid.id);
                });
            })
            .find(kid.family_id);
    }

    function autoSelectCustomerAndAddressFromDatabase() {
        CustomerModel.query()
            .where('person', 'natural')
            .whereHas(new CustomerModel().families(), query1 => {
                query1.where('id', familyModel.value!.getKey() as string);
            })
            .with(new CustomerModel().addresses())
            .with(new CustomerModel().orders())
            .get()
            .then(response => {
                if (response.all().length) {
                    onCustomerSelected(response.first());
                }
            });
    }
</script>
<template>
    <div>
        <CAlert
            v-if="!company"
            v-show="loader.isDone('customers')"
            class="tw-mb-4"
            remember-hash="cashier-news-june-2020"
            size="lg"
            :title-text="__('family_kid:billing.what_is_account_customer')"
        >
            <template #icon>
                <div class="tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-300">
                    <FontAwesomeIcon
                        class="tw-text-2xl tw-text-blue-700"
                        icon="fa-solid fa-info"
                    />
                </div>
            </template>
            <div>
                <p v-html="__('family_kid:billing.account_customer_explanation')" />
                <MButton
                    :icon-external-link="true"
                    onclick="Intercom('showArticle', 4167164)"
                    size="none"
                    variant="link"
                >
                    {{ __('common:more_informations') }}
                </MButton>
            </div>
        </CAlert>
        <!-- TODO: Au 30/04/2025 - À modifier / supprimer en fonction du partenariat -->
        <CAlert
            v-else-if="userEmail"
            v-show="loader.isDone('customers')"
            class="tw-mb-4"
            remember-hash="cmc-partnership-june-2023"
            size="lg"
            :title-text="__('family_kid:your_advantages_with_the_company_contract_colon')"
        >
            <template #icon>
                <div class="tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-300">
                    <FontAwesomeIcon
                        class="tw-text-2xl tw-text-blue-700"
                        icon="fa-solid fa-gift"
                    />
                </div>
            </template>
            <div>
                <p
                    class="tw-mb-3"
                    v-html="
                        __(
                            'family_contract:family_get_reduced_price_my_organization_get_benefits_and_annual_subscription_reimbursed'
                        )
                    "
                />
                <a
                    :href="`https://go.meeko.pro/choisir-ma-creche-client?email=${userEmail}&utm_campaign=ChoisirMaCrèche&utm_medium=meeko`"
                    target="_blank"
                >
                    <MButton variant="primary">
                        {{ __('family_kid:obtain_benefits') }}
                    </MButton>
                </a>
            </div>
        </CAlert>
        <CCard
            v-if="loader.isDone('order') && loader.isDone('tenant')"
            :collapsable="true"
            :visible="false"
        >
            <template #header>
                <div class="tw-flex tw-w-full tw-flex-wrap tw-items-center">
                    <div class="tw-w-full tw-text-lg tw-font-semibold md:tw-w-auto">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-orange-500"
                            icon="fa-solid fa-wallet"
                        />
                        {{ __('common:customer_one') }}
                    </div>
                    <div class="tw-flex tw-items-center">
                        <SelectCustomer
                            v-model="customerModel"
                            class="tw-mt-2 md:tw-ml-4 md:tw-mt-0"
                            :disabled="isLocked"
                            :extend-builder="customerBuilder"
                            :tenant-model="tenantModel"
                            :with-legal="company"
                            :with-natural="!company"
                            @hideModal="selectCustomerModalCreateWithAddress.hide()"
                            @input="onCustomerSelected"
                            @showModal="showCustomerModalCreateWithAddress = true"
                        >
                            <template #modal="{searchValue}">
                                <CustomerModalCreateWithAddress
                                    v-if="showCustomerModalCreateWithAddress"
                                    ref="selectCustomerModalCreateWithAddress"
                                    :company="company"
                                    :family-model="familyModel"
                                    :member-models="modalityMembers"
                                    :name="searchValue"
                                    :tenant-model="tenantModel"
                                    @created="onCustomerSelected($event)"
                                    @hide="showCustomerModalCreateWithAddress = false"
                                />
                            </template>
                        </SelectCustomer>
                        <MHelpTooltip class="tw-ml-2 tw-text-lg">
                            {{ __('family_kid:select_customer_account_for_billing_family_or_company') }}
                        </MHelpTooltip>
                    </div>
                </div>
            </template>
            <div class="tw-flex tw-flex-col lg:tw-flex-row">
                <div class="tw-w-full tw-pb-5 lg:tw-w-1/2 lg:tw-pr-5">
                    <CForm @submit.prevent="">
                        <CFormGroup>
                            <CLabel>
                                {{ __('common:billing_address_one') }}
                            </CLabel>
                            <SelectAddress
                                v-model="billingAddressModel"
                                button-class="tw-w-full"
                                :customer-model="customerModel"
                                dropdown-wrapper-class="tw-w-full md:tw-w-auto"
                            />
                        </CFormGroup>
                        <CFormGroup v-if="company">
                            <CLabel>
                                {{ __('family_kid:purchase_order') }}
                            </CLabel>
                            <CInput
                                v-if="orderModel"
                                v-model="orderModel.attributes.purchase_order_number"
                                :radonly="true"
                            />
                            <CInput
                                v-else
                                :readonly="true"
                            />
                        </CFormGroup>
                    </CForm>
                </div>
                <div
                    v-if="orderModel && customerModel"
                    class="tw-w-full tw-pt-5 lg:tw-w-1/2 lg:tw-border-l lg:tw-border-gray-300 lg:tw-pl-5"
                >
                    <NotificationsOfflineList
                        v-if="endpoints"
                        :auto-subcribe-member="autoSubscribeMember"
                        :company="company"
                        :contract="contract"
                        :customer-model="customerModel"
                        :endpoint-models="endpoints"
                        :order-model="orderModel"
                        :tenant-model="tenantModel"
                        @endpointsChanged="endpointsChanged"
                    />
                </div>
            </div>
        </CCard>
    </div>
</template>
