<template>
    <div class="tw-contents">
        <CustomerTabInvoicesActionsPopover
            :customer="customer"
            :kids="kids"
            :nursery="nursery"
            :set-invoice-type-popover="InvoiceTypeValue.invoice"
            :title="__('billing_invoice:create_invoice')"
            @init="$emit('init', $event)"
        />
        <CustomerTabInvoicesActionsPopover
            :customer="customer"
            :kids="kids"
            :nursery="nursery"
            :set-invoice-type-popover="InvoiceTypeValue.creditNote"
            :title="__('billing_invoice:create_credit_note')"
            @init="$emit('init', $event)"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type KidModel from '@/modules/family/models/KidModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue';
    import CustomerTabInvoicesActionsPopover from '@/modules/cashier/components/core/customer/organisms/CustomerTabInvoicesActionsPopover.vue';

    export default defineComponent({
        components: {CustomerTabInvoicesActionsPopover},
        props: {
            customer: {
                type: Object as PropType<CustomerModel>,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            kids: {
                type: Array as PropType<KidModel[]>,
                default: () => [],
            },
        },
        setup() {
            return {
                InvoiceTypeValue,
            };
        },
    });
</script>
