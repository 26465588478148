import type {GeneratePersonalAccessTokenRequest} from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenRequest';
import {collect, MqlOperation, MqlUnprocessableEntityError, ValidationError} from '@meekohq/lumos';
import PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
import type {GeneratePersonalAccessTokenResponse} from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenResponse';
import {SudoModeRequiredError} from '@/modules/auth/sudo-mode/application/SudoModeError';

export class GeneratePersonalAccessTokenInteractor {
    async generate(request: GeneratePersonalAccessTokenRequest): Promise<GeneratePersonalAccessTokenResponse> {
        try {
            // Generate the token
            const result = await new MqlOperation<{token: string}>('auth/personal_access_tokens/generate', {
                token_name: request.tokenName,
                consumer: request.consumer,
                sudo_token: request.sudoToken,
            }).run();
            const token = result.content.token;

            // Find the model using the id from the token
            const personalAccessTokenId = token.split('|')[0];
            const personalAccessTokenModel = await PersonalAccessTokenModel.find(personalAccessTokenId);

            return {
                model: personalAccessTokenModel,
                token,
            };
        } catch (err) {
            if (SudoModeRequiredError.isSudoModeRequiredError(err)) {
                throw new SudoModeRequiredError();
            }
            if (err instanceof MqlUnprocessableEntityError) {
                const resultContent = err.operation.result.content as {data: any} | null | undefined;

                if (!resultContent) {
                    throw err;
                }

                throw new ValidationError(collect(resultContent.data));
            }
            throw err;
        }
    }
}
