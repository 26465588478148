<template>
    <div
        class="range-bg tw-relative tw-rounded"
        :class="{small: small}"
    >
        <div
            v-if="loading"
            class="progress !tw-h-full"
        >
            <div
                class="progress-bar progress-bar-striped progress-bar-animated tw-w-full !tw-bg-gray-400"
                role="progressbar"
            />
        </div>
        <MTooltip
            v-for="(range, i) in summary"
            v-else
            :key="'range' + i"
            class="range tw-flex tw-items-center tw-justify-center"
            :label="__('common:kid_with_count', {count: range.kids ?? 0})"
            :style="style(range)"
        >
            {{ range.kids ? range.kids : 0 }}
        </MTooltip>
    </div>
</template>

<script>
    import _minBy from 'lodash-es/minBy';
    import _maxBy from 'lodash-es/maxBy';
    import {unixToPercent} from '@/modules/legacy/libs/planning/planning';

    export default {
        props: {
            nursery: {},
            opening: {},
            closing: {},
            day: {},
            summary: {},
            small: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            maxKids() {
                if (_maxBy(this.summary, 'kids')) {
                    return _maxBy(this.summary, 'kids').kids;
                }

                return 0;
            },

            minKids() {
                if (_minBy(this.summary, 'kids')) {
                    return _minBy(this.summary, 'kids').kids;
                }

                return 0;
            },
        },
        methods: {
            style(range) {
                const startedAt = range.started_at;
                const endedAt = range.ended_at;

                const opacity = range.kids ? (range.kids * 100) / this.maxKids / 100 : 0.12;

                let tooManyKids = false;
                if (this.nursery) {
                    const nbPercent = Math.ceil((this.nursery.places * this.nursery.occ_max_rate) / 100);
                    tooManyKids = range.kids > nbPercent;
                }

                return {
                    'left': unixToPercent(startedAt, this.day, this.opening, this.closing).toFixed(2) + '%',
                    'right': (100 - unixToPercent(endedAt, this.day, this.opening, this.closing)).toFixed(2) + '%',
                    'background-color': tooManyKids ? '#ff4b4a' : `rgba(0,122,255,${opacity})`,
                };
            },
        },
    };
</script>

<style scoped>
    .range {
        position: absolute;
        background-color: #007aff;
        height: 100%;
        overflow: hidden;
        color: white;

        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/
        font-weight: 600;
        text-overflow: ellipsis;
        text-shadow: 0 1px 1px rgba(20, 117, 236, 0.5);
        white-space: nowrap;
    }

    .range-bg {
        background-color: rgba(0, 122, 255, 0.1);
        height: 30px;
        overflow: hidden;

        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/
        font-size: 14px;

        &.small {
            height: 20px;
            font-size: 13px;
        }
    }
</style>
