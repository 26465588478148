<template>
    <div class="MCustomerBillingStatusBadge">
        <CBadge
            v-if="customer.computed.unpaid_payment_amount > 0"
            variant="danger"
        >
            <FontAwesomeIcon
                class="MCustomerBillingStatus__icon"
                icon="fa-solid fa-exclamation-triangle"
            />
            {{
                __('common:unpaid_since', {
                    value: useDuration().toHuman({
                        dateFrom: Epoch.now(),
                        dateTo: Epoch.fromISOString(customer.computed.first_unpaid_payment_at),
                    }),
                })
            }}
        </CBadge>
        <CBadge
            v-else-if="customer.computed.pending_payment_amount > 0 || customer.computed.upcoming_payment_amount > 0"
            variant="warning"
        >
            <FontAwesomeIcon
                class="MCustomerBillingStatus__icon"
                icon="fa-solid fa-clock"
            />
            {{ __('common:pending') }}
        </CBadge>
        <CBadge v-else-if="customer.computed.balance_amount < 0">
            <FontAwesomeIcon
                class="MCustomerBillingStatus__icon"
                icon="fa-duotone fa-coins"
            />
            {{ __('billing_customer:state_too_much_collected') }}
        </CBadge>
        <CBadge
            v-else
            variant="success"
        >
            <FontAwesomeIcon
                class="MCustomerBillingStatus__icon"
                icon="fa-solid fa-check"
            />
            {{ __('billing_customer:up_to_date') }}
        </CBadge>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import useDuration from '@/modules/app/composables/useDuration';
    import {Epoch} from '@meekohq/lumos';

    export default defineComponent({
        props: {
            customer: {type: Object as PropType<CustomerModel>, required: true},
        },
        setup() {
            return {
                useDuration,
            };
        },
        computed: {
            Epoch() {
                return Epoch;
            },
        },
    });
</script>

<style scoped>
    .MCustomerBillingStatus {
        @apply tw-w-40;
    }
</style>
