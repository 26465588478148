<template>
    <header
        ref="el"
        class="TopBar"
    >
        <div class="TopBar__overlay" />
        <div class="TopBar__header">
            <SwapOrganizationButton
                v-if="nursery && !isOnNurseriesPages && user.nurseries.length > 1"
                alt
                class="TopBar__swap"
                :nursery="nursery"
                small
                :user="user"
            />
            <div class="TopBar__widgets">
                <MButton
                    v-if="
                        user.is_owner &&
                        !isEmpty(customer) &&
                        (customer.subscription_status === 'incomplete' ||
                            customer.subscription_status === 'past_due' ||
                            customer.subscription_status === 'unpaid' ||
                            customer.has_unpaid_invoices)
                    "
                    size="sm"
                    :to="{name: 'subscriptions.invoices'}"
                    variant="danger"
                >
                    {{ __('common:unpaid_invoice_one') }}
                </MButton>

                <MPopover
                    v-if="!isEmpty(customer) && !customer.subscribed && customer.on_trial"
                    ref="trialPeriodsPopover"
                    class="TopBar__widgets-trial"
                >
                    <MButton>
                        {{ __('subscription:trial_periods') }}
                        <span class="tw-text-danger-500"> {{ customer.trial_ends_at_in_days }}j </span>
                    </MButton>
                    <template #content>
                        <div>
                            <template v-if="!customer.subscribed && customer.on_trial">
                                <span
                                    v-html="
                                        __('subscription:trial_period_ends_in_days', {
                                            days: customer.trial_ends_at_in_days,
                                        })
                                    "
                                />
                            </template>
                            <template v-else>
                                {{ __('subscription:trial_period_is_finished') }}
                            </template>
                        </div>
                        <MButton
                            v-if="user.is_owner"
                            class="tw-mt-2 tw-w-full"
                            :to="{name: 'subscriptions.plan'}"
                            variant="primary"
                            @click.native="onSubscriptionClicked"
                        >
                            {{ __('common:actions.subscribe') }}
                        </MButton>
                    </template>
                </MPopover>
                <TodoWidgetToggle v-if="!isOnNurseriesPages && (can('read', 'hr_request') || can('read', 'tasks'))" />
                <NotificationsToggle
                    v-if="!isOnNurseriesPages"
                    :nursery="legacyNursery"
                    :user="legacyUser"
                    :user_id="user.id"
                />

                <CTooltip placement="bottom">
                    <a
                        href="http://support.meeko.app"
                        target="_blank"
                    >
                        <MButton
                            round
                            zoom
                        >
                            <FontAwesomeIcon
                                class="tw-text-lg tw-text-info-500"
                                icon="fa-solid fa-question"
                            />
                        </MButton>
                    </a>
                    <template #content>
                        {{ __('app:meeko_support') }}
                    </template>
                </CTooltip>

                <MMenu placement="bottom-end">
                    <MMenuButton>
                        <MButton
                            round
                            zoom
                        >
                            <FontAwesomeIcon
                                class="tw-text-gray-700"
                                icon="fa-solid fa-user"
                            />
                        </MButton>
                    </MMenuButton>
                    <MMenuItems>
                        <template v-if="!isOnNurseriesPages">
                            <RouterLink
                                tabindex="-1"
                                :to="{name: 'settings.profile', query: {nav: 'profile'}}"
                            >
                                <MMenuItem :label="__('user:my_profile')">
                                    <template #icon>
                                        <FontAwesomeIcon
                                            fixed-width
                                            icon="fa-solid fa-user"
                                        />
                                    </template>
                                </MMenuItem>
                            </RouterLink>
                            <RouterLink
                                tabindex="-1"
                                :to="{name: 'settings.profile', query: {nav: 'notifications'}}"
                            >
                                <MMenuItem :label="__('app:manage_notifications')">
                                    <template #icon>
                                        <FontAwesomeIcon
                                            fixed-width
                                            icon="fa-solid fa-bell"
                                        />
                                    </template>
                                </MMenuItem>
                            </RouterLink>
                            <RouterLink
                                v-if="authUser.attributes.is_owner"
                                tabindex="-1"
                                :to="{name: 'settings.profile', query: {nav: 'pat'}}"
                            >
                                <MMenuItem :label="__('user:login_keys')">
                                    <template #icon>
                                        <FontAwesomeIcon
                                            fixed-width
                                            icon="fa-solid fa-key"
                                        />
                                    </template>
                                </MMenuItem>
                            </RouterLink>
                            <MMenuDivider />
                        </template>
                        <MSubMenu
                            arrow
                            placement="left"
                        >
                            <MMenuButton>
                                <!-- On ajoute manual car on est pas censé trouver un MMenuItem dans un MMenuButton, les 2 clics se contredisent, à modifier -->
                                <MMenuItem
                                    :label="__('common:language')"
                                    manual
                                >
                                    <template #icon>
                                        <FontAwesomeIcon
                                            fixed-width
                                            icon="fa-solid fa-earth"
                                        />
                                    </template>
                                </MMenuItem>
                            </MMenuButton>
                            <UserLanguageSelector />
                        </MSubMenu>
                        <MMenuDivider />
                        <MMenuItem
                            :label="__('app:logouts')"
                            variant="danger"
                            @click="logout"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-power-off"
                                />
                            </template>
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>
            </div>
            <div
                v-if="!isOnNurseriesPages"
                class="TopBar__search"
            >
                <MButton
                    v-if="!isEmpty(nursery)"
                    class="TopBar__search-back"
                    @click="$router.go(-1)"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                    </template>
                    {{ __('common:actions.back') }}
                </MButton>
                <search
                    v-if="!isEmpty(nursery)"
                    class="tw-max-w-96 tw-grow"
                    :nursery="nursery"
                />
            </div>
            <div class="TopBar__widget-portal">
                <portal-target
                    multiple
                    name="topbar-widget"
                />
            </div>
        </div>
    </header>
</template>

<script lang="ts">
    import {computed, ref} from 'vue';
    import {app} from '@meekohq/lumos';
    import _isEmpty from 'lodash-es/isEmpty';
    import {AuthenticationContractBinding} from '@/modules/auth/utils/AuthenticationContract';
    import useAuth from '@/modules/app/composables/useAuth';
    import useAbility from '@/modules/app/composables/useAbility';
    import {useRoute, useRouter} from 'vue-router/composables';
    import useManager from '@/modules/app/composables/useManager';
    import {useIntervalFn} from '@vueuse/core';
    import useUI from '@/modules/app/composables/useUI';
    import NotificationsToggle from '@/modules/notification/components/NotificationsWidgetToggle.vue';
    import Search from '@/modules/legacy/components/Modules/Search/Search.vue';
    import TodoWidgetToggle from '@/modules/todo-widget/components/atoms/TodoWidgetToggle.vue';
    import SwapOrganizationButton from '@/modules/app/components/layouts/SwapOrganizationButton.vue';
    import UserLanguageSelector from '@/modules/app/components/organisms/UserLanguageSelector.vue';

    export default {
        components: {
            UserLanguageSelector,
            SwapOrganizationButton,
            TodoWidgetToggle,
            NotificationsToggle,
            search: Search,
        },
        props: {
            nursery: {
                type: Object,
                default: null,
            },
            user: {
                type: Object,
                default: null,
            },
            customer: {
                type: Object,
                default: null,
            },
        },
        setup() {
            const router = useRouter();
            const {user: authUser} = useAuth();
            const route = useRoute();
            const {legacyNursery} = useManager();
            const {legacyUser} = useAuth();
            const trialPeriodsPopover = ref();

            const isOnNurseriesPages = computed(() => {
                return route.name === 'nurseries';
            });

            const el = ref<HTMLElement>();
            const {topBarSize} = useUI();
            useIntervalFn(() => {
                topBarSize.value = el.value?.clientHeight || 0;
            }, 2000);

            async function logout() {
                await app(AuthenticationContractBinding).logout();

                await router?.push({name: 'login'});
            }

            function onSubscriptionClicked() {
                trialPeriodsPopover.value?.closePopover();
            }

            return {
                ...useAbility(),
                isEmpty: _isEmpty,
                el,
                authUser,
                logout,
                isOnNurseriesPages,
                legacyNursery,
                legacyUser,
                trialPeriodsPopover,
                onSubscriptionClicked,
            };
        },
    };
</script>

<style scoped>
    .TopBar {
        @apply tw-sticky tw-top-0 tw-pb-4 tw-pt-2 lg:tw-pt-4;

        @apply print:tw-hidden;
    }

    .TopBar__overlay {
        @apply tw-absolute tw-inset-0 tw-backdrop-blur-sm;
        z-index: -1;
    }

    .TopBar__header {
        @apply tw-flex tw-flex-wrap tw-items-center tw-gap-2;
    }

    .TopBar__swap {
        @apply tw-order-1;
        @media screen(lg) {
            display: none !important;
        }
    }

    .TopBar__widgets {
        @apply tw-order-2;
        @apply tw-flex tw-flex-1 tw-justify-end tw-gap-2;
    }

    .TopBar__widgets-trial {
        @apply tw-hidden md:tw-block;
    }

    .TopBar__search {
        @apply tw-order-4 lg:tw-order-1;
        @apply tw-flex tw-flex-1 tw-basis-full tw-gap-2 lg:tw-basis-auto;
    }

    .TopBar__search-back {
        @apply tw-hidden sm:tw-flex;
    }

    .TopBar__widget-portal {
        @apply tw-order-3;
        @apply tw-w-full;
        @apply tw-relative;
    }
</style>
