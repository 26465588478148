import type {Ref} from 'vue';
import {computed} from 'vue';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import type BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
import {ceil} from 'lodash-es';
import type {ICalculable} from '@/modules/human-resources/utils/balance/ICalculable';

export default class<TSource extends ICalculable> {
    private readonly source: Ref<TSource>;
    private readonly staff: Ref<StaffModel>;
    private readonly balanceType: Ref<BalanceTypeModel>;

    public constructor(source: Ref<TSource>, staff: Ref<StaffModel>, balanceType: Ref<BalanceTypeModel>) {
        this.source = source;
        this.staff = staff;
        this.balanceType = balanceType;
    }

    public get value() {
        return computed(() => {
            return this.calculate(this.source.value, this.unit.value);
        });
    }

    protected get unit() {
        return computed(() => this.balanceType.value.attributes.unit as string);
    }

    protected calculate(source: TSource, unit: string) {
        let value = 0;

        const diffInMinutes = source.duration / 60;
        const diffInHours = diffInMinutes / 60;
        const diffInDays = diffInHours / 24;

        if (source.isFullDay && unit === 'hour') {
            const workingDiffInMinutes = source.workingDuration / 60;
            const workingDiffInHours = workingDiffInMinutes / 60;

            value = workingDiffInHours > 0 ? ceil(workingDiffInHours) : 0;
        } else {
            switch (unit) {
                case 'day':
                    value = diffInDays > 0 ? ceil(diffInDays) : 0;
                    break;

                case 'hour':
                    value = diffInHours > 0 ? ceil(diffInHours) : 0;
                    break;
            }
        }

        return value;
    }
}
