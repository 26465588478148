import type {DeletePersonalAccessTokenRequest} from '@/modules/auth/personal-access-token/application/DeletePersonalAccessTokenRequest';
import {Binding} from '@meekohq/lumos';

export const DeletePersonalAccessTokenUseCaseBinding = new Binding<DeletePersonalAccessTokenUseCase>();

/**
 * Delete a personal access token. Requires sudo mode.
 */
export interface DeletePersonalAccessTokenUseCase {
    delete(request: DeletePersonalAccessTokenRequest): Promise<void>;
}
