<script setup lang="ts">
    import {computed, onMounted, ref, type Ref, watch} from 'vue';
    import _filter from 'lodash-es/filter';
    import useAbility from '@/modules/app/composables/useAbility';

    const props = defineProps({
        name: {
            type: String,
            default: 'ID',
        },
        ressource: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: 'Sans titre',
        },
        editableFields: {
            type: Array,
            default: () => [],
        },
    });

    const {allows} = useAbility();

    const ressourceProps: Ref<any[]> = ref([]);
    const search = ref('');
    const expanded = ref(false);

    const filteredObj = computed(() => {
        if (search.value) {
            return _filter(ressourceProps.value, item => {
                const searchResult = search.value
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');

                return item.key && item.key.indexOf(searchResult) > -1;
            });
        } else {
            return ressourceProps.value;
        }
    });

    watch(
        () => props.ressource,
        () => {
            init();
        },
        {deep: true}
    );

    onMounted(() => {
        init();
    });

    function init() {
        ressourceProps.value = [];

        // Flattend all props
        const newObj = flatten(props.ressource);

        // Create new array with key / value
        Object.entries(newObj).forEach(key => {
            ressourceProps.value.push({
                key: key[0],
                value: key[1],
                editing: false,
                editable: props.editableFields.includes(key[0]),
            });
        });
    }

    function flatten(obj) {
        const newObj = {};
        dive('', obj, newObj);

        return newObj;
    }

    function dive(currentKey, into, target) {
        for (const i in into) {
            // eslint-disable-next-line no-prototype-builtins
            if (into.hasOwnProperty(i)) {
                let newKey = i;
                const newVal = into[i];

                if (currentKey.length > 0) {
                    newKey = currentKey + '.' + i;
                }

                if (typeof newVal === 'object') {
                    dive(newKey, newVal, target);
                } else {
                    target[newKey] = newVal;
                }
            }
        }
    }
</script>

<template>
    <section
        v-if="allows('support')"
        class="SupportMeeko"
    >
        <div
            class="SupportMeeko__header"
            @click="expanded = !expanded"
        >
            <FontAwesomeIcon
                class="SupportMeeko__dev-icon"
                icon="fa-solid fa-code"
            />
            <h1 class="SupportMeeko__title">
                <span>{{ title }}</span>
                <FontAwesomeIcon
                    class="SupportMeeko__title-separator"
                    icon="fa-solid fa-slash-forward"
                />
                <span> {{ name }}: {{ ressource.id }} </span>
            </h1>
            <div class="SupportMeeko__icon">
                <FontAwesomeIcon
                    v-if="expanded"
                    icon="fa-duotone fa-chevron-down"
                />
                <FontAwesomeIcon
                    v-else
                    icon="fa-duotone fa-chevron-right"
                />
            </div>
        </div>
        <div
            v-if="expanded"
            class="SupportMeeko__content"
        >
            <CFormGroup>
                <CLabel>{{ __('app:search_a_key_colon') }}</CLabel>
                <CInput
                    v-model="search"
                    type="search"
                />
            </CFormGroup>
            <div class="SupportMeeko__items">
                <div
                    v-for="(obj, i) in filteredObj"
                    :key="'obj' + i"
                    class="SupportMeeko__item"
                >
                    <span class="SupportMeeko__key">
                        {{ obj.key }}
                    </span>
                    <span>:</span>
                    <CButtonGroup v-if="obj.editing">
                        <CInput
                            v-model="obj.value"
                            size="sm"
                        />
                        <MButton
                            size="sm"
                            variant="light"
                            @click="obj.editing = false"
                        >
                            <FontAwesomeIcon
                                class="tw-px-0.5"
                                icon="fa-solid fa-times"
                            />
                        </MButton>
                    </CButtonGroup>
                    <div
                        v-else-if="obj.editable"
                        class="SupportMeeko__value SupportMeeko__value--editable"
                        @click="obj.editing = true"
                    >
                        {{ obj.value }}
                    </div>
                    <div
                        v-else
                        class="SupportMeeko__value"
                    >
                        {{ obj.value }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .SupportMeeko {
        @apply tw-mb-2 tw-rounded-md tw-bg-gray-200 tw-p-1;
    }

    .SupportMeeko__header {
        @apply tw-flex tw-items-center tw-justify-start tw-rounded tw-px-1 tw-py-0.5;
        @apply tw-cursor-pointer hover:tw-bg-gray-300;
    }

    .SupportMeeko__title {
        @apply tw-text-sm tw-font-semibold tw-text-gray-500;
    }

    .SupportMeeko__title-separator {
        @apply tw-mx-1 tw-text-gray-400;
    }

    .SupportMeeko__icon {
        @apply tw-ml-auto tw-mr-1 tw-text-gray-600;
    }

    .SupportMeeko__dev-icon {
        @apply tw-mr-1.5 tw-text-gray-600;
    }

    .SupportMeeko__content {
        @apply tw-mt-2 tw-px-2;
    }

    .SupportMeeko__items {
        @apply tw-my-3 tw-flex tw-flex-col tw-gap-y-1;
    }

    .SupportMeeko__item {
        @apply tw-flex tw-items-baseline tw-gap-x-0.5 tw-text-sm;
    }

    .SupportMeeko__key {
        @apply tw-font-mono;
    }

    .SupportMeeko__value {
        @apply tw-font-semibold;
    }

    .SupportMeeko__value--editable {
        @apply tw-cursor-pointer tw-text-primary-600 hover:tw-text-primary-800;
    }
</style>
