<template>
    <router-link
        v-if="customerModels.length === 1"
        :to="{name: 'cashier/customers.show.summary', params: {customer: head(customerModels).id}}"
    >
        <slot />
    </router-link>
    <MMenu
        v-else
        arrow
        placement="left"
    >
        <MMenuButton>
            <slot />
        </MMenuButton>
        <MMenuItems>
            <router-link
                v-for="customerModel in customerModels"
                :key="customerModel.id"
                custom
                :to="{name: 'cashier/customers.show.summary', params: {customer: customerModel.id}}"
            >
                <MMenuItem manual>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-duotone fa-file-user" />
                    </template>
                    {{ customerModel.attributes.name }}
                </MMenuItem>
            </router-link>
        </MMenuItems>
    </MMenu>
</template>

<script lang="ts">
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import {head} from 'lodash-es';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            customerModels: {
                type: Array as PropType<CustomerModel[]>,
                required: true,
            },
        },
        setup() {
            return {
                head,
            };
        },
    });
</script>
