<template>
    <div class="tw-flex tw-flex-col tw-gap-4">
        <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2">
            <MHeading>
                {{ __('common:all_kids') }}
            </MHeading>
            <div class="tw-flex tw-flex-row tw-gap-2">
                <MButton
                    variant="light"
                    @click="$emit('cancelNursery')"
                >
                    {{ __('common:actions.cancel') }}
                </MButton>
                <MButton
                    variant="primary"
                    @click="$emit('saveNursery')"
                >
                    {{ __('common:actions.save') }}
                </MButton>
            </div>
        </div>
        <CInputGroup>
            <MInput
                v-model.number="myNursery.places"
                size="lg"
                type="number"
            />
            <CInputAddon class="tw-shrink-0">
                {{ __('common:place', {count: myNursery.places}) }}
            </CInputAddon>
        </CInputGroup>
        <PlanningSettingsRuleEditCard
            v-for="(rule, n) in myNursery.supervision_rules"
            :key="'rule' + n"
            :index="n"
            :my-nursery="myNursery"
            :nursery="nursery"
            :rule="rule"
        />
        <MButton
            icon-plus
            :label="__('common:add_rule')"
            variant="light"
            @click="addRule(null)"
        />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import PlanningSettingsRuleEditCard from '@/modules/planning/components/settings/PlanningSettingsGroup/molecules/PlanningSettingsRuleEditCard.vue';

    export default defineComponent({
        components: {PlanningSettingsRuleEditCard},
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            myNursery: {
                type: Object,
                required: true,
            },
        },

        setup(props) {
            function addRule(group) {
                const rule = {
                    staffs: [
                        {
                            job_id: null,
                            staffs: 1,
                        },
                    ],
                    kids: 3,
                };
                if (group) {
                    group.rules.push(rule);
                } else {
                    props.myNursery.supervision_rules.push(rule);
                }
            }

            return {
                addRule,
            };
        },
    });
</script>
