<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="registration">
                {{ __('app:pages.registrations') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MButton
                v-if="$can('read', 'kids_planning')"
                @click="toggleSimulationModal"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-lines-pen" />
                </template>
                {{ __('common:file_simulation') }}
            </MButton>
            <SimulationModal
                v-if="showSimulationModal"
                @hidden="toggleSimulationModal"
            />
            <a
                v-if="$can('settings', 'nursery_website') && nursery.websites[0]"
                :href="'https://' + nursery.websites[0].slug + '.meeko.site/login/' + temporaryToken"
                target="_blank"
            >
                <MButton>
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-file-alt" />
                    </template>
                    {{ __('registration:parent_form') }}
                </MButton>
            </a>
            <MButton
                v-if="$can('create', 'registrations')"
                icon-plus
                :label="__('registration:add_registration')"
                variant="primary"
                @click="storeRegistration"
            />
        </template>
        <template #content>
            <div class="tw-mb-4 tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                <TagsResourceFinder
                    v-model="selectedTagsModels"
                    button-size="sm"
                    has-unselect
                    :inject-query="tagFinderQuery"
                    multi
                    only-active-organization
                    :search-bar="false"
                    :without-tags-option-is-selected="withoutTags"
                    @without-tags-option-is-selected="withoutTags = $event"
                />
                <MMenu>
                    <MMenuButton>
                        <MButton
                            icon-menu
                            size="sm"
                        >
                            <template
                                v-if="tab !== null"
                                #left-icons
                            >
                                <FontAwesomeIcon
                                    v-if="tab === 'waiting'"
                                    icon="fa-solid fa-clock"
                                />
                                <FontAwesomeIcon
                                    v-if="tab === 'accepted'"
                                    icon="fa-solid fa-check"
                                />
                                <FontAwesomeIcon
                                    v-if="tab === 'rejected'"
                                    icon="fa-solid fa-archive"
                                />
                            </template>
                            <template v-if="tab === null">
                                {{ __('registration:all_registrations') }}
                            </template>
                            <template v-if="tab === 'waiting'">
                                {{ __('registration:pended_registrations') }}
                            </template>
                            <template v-if="tab === 'accepted'">
                                {{ __('registration:accepted_registrations') }}
                            </template>
                            <template v-if="tab === 'rejected'">
                                {{ __('registration:rejected_registrations') }}
                            </template>
                        </MButton>
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem
                            :label="__('registration:all_registrations')"
                            @click="tab = null"
                        />
                        <MMenuDivider />
                        <MMenuItem
                            :label="__('registration:accepted')"
                            @click="tab = 'accepted'"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-check"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuItem
                            :label="__('common:pending')"
                            @click="tab = 'waiting'"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-clock"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuItem
                            :label="__('registration:registration_status_rejected')"
                            @click="tab = 'rejected'"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-archive"
                                />
                            </template>
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>
                <MFilterSort
                    :filters="orderFilter"
                    :options="sortOptions"
                />
                <MTooltip
                    :label="__('registration:change_display_mode')"
                    placement="right"
                >
                    <MButton
                        size="sm"
                        @click="changeView"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon :icon="selectedView === 'list' ? 'fa-solid fa-th' : 'fa-solid fa-list'" />
                        </template>
                        <template v-if="selectedView === 'list'">
                            {{ __('common:grid') }}
                        </template>
                        <template v-else>
                            {{ __('common:list') }}
                        </template>
                    </MButton>
                </MTooltip>
            </div>

            <transition
                mode="out-in"
                name="transition-fade"
            >
                <div v-if="!loader">
                    <RegistrationsView
                        :nursery="nursery"
                        :registrations="registrations"
                        :registrations-models="registrationsModels"
                        :view-mode="selectedView === 'list' ? 'list' : 'cards'"
                        @estimate="estimate"
                    />
                    <MBox v-if="!registrations.length">
                        <MEmptyIndex>
                            <template #button>
                                <MButton
                                    icon-plus
                                    variant="primary"
                                    @click="storeRegistration"
                                >
                                    {{ __('registration:click_to_add_registration') }}
                                </MButton>
                            </template>
                        </MEmptyIndex>
                    </MBox>
                </div>
                <div
                    v-else
                    key="loader"
                >
                    <loader custom-class="la-2x" />
                </div>
            </transition>
            <div
                v-if="total > perPage"
                class="tw-mt-4 tw-flex tw-justify-center"
            >
                <MPagination
                    :paginator="paginator"
                    @navigate="currentPage = $event"
                />
            </div>
            <estimate-invoice
                ref="estimateInvoice"
                :contract="contract"
                :kid="kid"
                :nursery="nursery"
                :registration="selectedRegistration"
                :user="user"
                @registrationUpdated="updateRegistration"
            />
        </template>
    </SimplePageTemplate>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _debounce from 'lodash-es/debounce';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import StoreRegistration from '@/modules/registration/components/Store.vue';
    import EstimateInvoice from '@/modules/registration/components/Estimate/Modal.vue';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import SimulationModal from '@/modules/planning/components/simulation/SimulationModal/SimulationModal.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import RegistrationsView from '@/modules/registration/components/organisms/RegistrationsView.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import MFilterSort from '@/modules/meeko-ui/components/MFilterSort.vue';
    import MEmptyIndex from '@/modules/meeko-ui/components/MEmptyIndex.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import TagModel from '@/modules/tag/models/TagModel';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import useTagLegacyModelBridge from '@/modules/app/composables/useTagLegacyModelBridge';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import {url} from '@/modules/legacy/mixins/url';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import useModal from '@/modules/app/composables/useModal';

    export default {
        components: {
            MPagination,
            TagsResourceFinder,
            MEmptyIndex,
            MFilterSort,
            SimplePageTemplate,
            SimulationModal,
            EstimateInvoice,
            RegistrationsView,
        },
        mixins: [url],
        props: {
            user: {type: Object},
            nursery: {type: Object},
        },
        data() {
            return {
                loader: true,
                registrations: [],
                registrationsModels: [],
                newRegistration: {},
                selectedContract: {},
                selectedRegistration: null,
                contract: {},
                kid: {},
                tab: localStorage.getItem('registrations:index:state') || null,
                withoutTags: JSON.parse(localStorage.getItem('without_tags:registrations:index')) || false,
                selectedTagsModels: [],
                selectedView: localStorage.getItem('registrations:index:view'),
                currentPage: Number(this.$route.query.page) || 1,
                total: 0,
                perPage: 20,
                exportLoading: false,
                showSimulationModal: false,
                orderFilter: {
                    orderBy: localStorage.getItem('registrations:index:orderBy') || 'requested_at',
                    queryDirection: localStorage.getItem('registrations:index:order') || 'desc',
                },
                sortOptions: [
                    {
                        value: 'requested_at',
                        label: __('registration:sort_by_registration_date'),
                        sortLabel: __('registration:sort_by_registration_date'),
                    },
                    {
                        value: 'contract_started_at',
                        label: __('registration:sort_by_entry_date'),
                        sortLabel: __('registration:sort_by_entry_date'),
                    },
                ],
                paginator: undefined,
            };
        },
        computed: {
            temporaryToken() {
                const {legacyToken} = useAuth();

                return legacyToken.value;
            },
            tagFinderQuery() {
                return TagModel.query().where('type', 'registration');
            },
            tagsFilters() {
                return {
                    selectedTagsModels: this.selectedTagsModels,
                    withoutTags: this.withoutTags,
                };
            },
        },
        watch: {
            'currentPage'() {
                this.debounceGetRegistrations();
            },
            'tab'(val) {
                this.currentPage = 1;
                this.debounceGetRegistrations();
                if (val) {
                    localStorage.setItem('registrations:index:state', val);
                } else {
                    localStorage.removeItem('registrations:index:state');
                }
            },
            'orderFilter.queryDirection': function (val) {
                this.currentPage = 1;
                this.debounceGetRegistrations();
                localStorage.setItem('registrations:index:order', val);
            },
            'orderFilter.orderBy': function (val) {
                if (val === 'contract_started_at' && this.order === 'desc') {
                    this.order = 'asc';
                } else {
                    this.currentPage = 1;
                    this.debounceGetRegistrations();
                }
                localStorage.setItem('registrations:index:orderBy', val);
            },
            'tagsFilters'({selectedTagsModels, withoutTags}) {
                if (selectedTagsModels) {
                    localStorage.setItem(
                        'tags:registrations:index',
                        JSON.stringify(selectedTagsModels.map(item => item.getKey()))
                    );
                } else {
                    localStorage.removeItem('tags:registrations:index');
                }
                localStorage.setItem('without_tags:registrations:index', JSON.stringify(withoutTags));

                this.currentPage = 1;
                this.debounceGetRegistrations();
            },
            'selectedContract'(registration) {
                this.contract = {
                    id: registration.id,
                    billing_config_id: registration.billing_config_id,
                    started_at: registration.contract_started_at,
                    ended_at: registration.contract_ended_at,
                    recurrent_weeks: registration.contract_recurrent_weeks,
                    contract_recurrent_weeks: registration.contract_recurrent_weeks,
                    dependent_children: registration.contract_dependent_children,
                    handicapped_children: registration.contract_handicapped_children,
                    vacation_days: registration.contract_vacation_days,
                    plannings: registration.plannings,
                    type: 'recurrent',
                    cafpro_resources: registration.first_parent_salary + registration.second_parent_salary,
                    config: registration.config,
                };
                this.kid = {
                    id: 0,
                    first_name: registration.kid_first_name ? registration.kid_first_name : '',
                    last_name: registration.kid_last_name ? registration.kid_last_name : '',
                    hygiene: registration.kid_hygiene ? registration.kid_hygiene : 'baby',
                    meal_type: registration.kid_meal_type ? registration.kid_meal_type : 'milk',
                    birthdate: registration.kid_birthdate,
                };
            },
            'paginator'() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        mounted() {
            this.getSelectedTagsModels();
            useBroadcast().sessionChannel.bind('export_data', response => {
                const link = document.createElement('a');
                link.href = response.url;
                link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                this.exportLoading = false;
            });
        },
        beforeDestroy() {
            useBroadcast().sessionChannel.unbind('export_data');
        },
        methods: {
            toggleSimulationModal() {
                this.showSimulationModal = !this.showSimulationModal;
            },

            getRegistrations(page = this.currentPage || 1) {
                this.loader = true;

                const tags = useTagLegacyModelBridge().tagsModelsAndWithoutTagsToLegacyFilter(
                    this.selectedTagsModels,
                    this.withoutTags
                );

                useApi()
                    .legacy.get(route('registrations.index'), {
                        params: {
                            state: this.tab,
                            page,
                            per_page: this.perPage,
                            order: this.orderFilter.queryDirection,
                            order_by: this.orderFilter.orderBy,
                            tags,
                            nurseries: [this.nursery.id],
                        },
                    })
                    .then(response => {
                        this.total = response.data.total;
                        this.perPage = Number(response.data.per_page);
                        this.paginator = new LengthAwarePaginator(
                            response.data.data,
                            parseInt(response.data.total),
                            parseInt(response.data.per_page),
                            parseInt(response.data.current_page),
                            parseInt(response.data.last_page)
                        );

                        return this.getRegistrationsModels(response.data.data);
                    })
                    .then(() => {
                        this.loader = false;
                    })
                    .catch(error => {
                        this.loader = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            async debounceGetRegistrations() {
                _debounce(this.getRegistrations, 500)();
            },

            async getRegistrationsModels(registrations) {
                if (!registrations.length) {
                    this.registrationsModels = [];
                    this.registrations = [];

                    return;
                }

                this.registrationsModels = (
                    await RegistrationModel.query()
                        .with(new RegistrationModel().tags())
                        .whereIn(
                            'id',
                            registrations.map(registration => registration.id)
                        )
                        .get()
                ).all();
                this.registrations = registrations;
            },

            storeRegistration() {
                this.newRegistration = undefined;

                useModal({
                    component: StoreRegistration,
                    props: {
                        nurseries: this.user.nurseries,
                        nursery: this.nursery,
                        registration: this.newRegistration,
                        user: this.user,
                    },
                    listeners: () => ({
                        registrationCreated: () => {
                            this.getRegistrations(1);
                        },
                    }),
                }).show();
            },

            estimate(registration) {
                if (registration) {
                    this.selectedRegistration = registration;
                    // Config is watching id so it refreshes
                    this.selectedContract.id = 0;
                    setTimeout(() => {
                        this.selectedContract = _cloneDeep(registration);
                        this.selectedContract.started_at = this.selectedContract.contract_started_at;
                        this.selectedContract.ended_at = this.selectedContract.contract_ended_at;
                        this.selectedContract.recurrent_weeks = this.selectedContract.contract_recurrent_weeks;
                        this.selectedContract.dependent_children = this.selectedContract.contract_dependent_children;
                        this.selectedContract.handicapped_children =
                            this.selectedContract.contract_handicapped_children;
                        this.selectedContract.vacation_days = this.selectedContract.contract_vacation_days;
                        this.selectedContract.type = 'recurrent';

                        Vue.set(
                            this.selectedContract,
                            'cafpro_resources',
                            registration.first_parent_salary + registration.second_parent_salary
                        );
                        this.$refs.estimateInvoice.$refs.modal.show();
                    }, 300);
                }
            },

            updateRegistration(registration) {
                const registrationToUpdate = this.registrations.find(item => item.id === registration.id);
                if (registrationToUpdate) {
                    const index = this.registrations.indexOf(registrationToUpdate);
                    Vue.set(this.registrations, index, registration);
                }
            },

            getXlsFile() {
                this.exportLoading = true;
                useApi()
                    .legacy.post(route('export'), {
                        nursery_id: this.nursery.id,
                        resources: [
                            {
                                name: 'registrations',
                                filters: {
                                    state: this.tab,
                                },
                            },
                        ],
                    })
                    .catch(error => {
                        this.exportLoading = false;

                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            changeView() {
                this.selectedView && this.selectedView === 'cards'
                    ? (this.selectedView = 'list')
                    : (this.selectedView = 'cards');
                localStorage.setItem('registrations:index:view', this.selectedView);
            },

            async getSelectedTagsModels() {
                const selectedTagIds = JSON.parse(localStorage.getItem('tags:registrations:index'));
                this.selectedTagsModels = await useTagLegacyModelBridge().getTagsModelsFromIds(selectedTagIds);
            },
        },
    };
</script>
