import type {LengthAwarePaginator} from '@meekohq/lumos';
import {Binding} from '@meekohq/lumos';
import type PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
import type {PaginatePersonalAccessTokenRequest} from '@/modules/auth/personal-access-token/application/PaginatePersonalAccessTokenRequest';

export const PaginatePersonalAccessTokenUseCaseBinding = new Binding<PaginatePersonalAccessTokenUseCase>();

/**
 * Paginate user's personal access tokens.
 */
export interface PaginatePersonalAccessTokenUseCase {
    paginate(request: PaginatePersonalAccessTokenRequest): Promise<LengthAwarePaginator<PersonalAccessTokenModel>>;
}
