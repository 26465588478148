<template>
    <CVStack gap="8">
        <CHStack gap="2">
            <CHStack
                class="tw-flex-1"
                gap="2"
            >
                <CButtonGroup>
                    <MButton
                        :variant="showEmployeesList ? 'primary' : 'light'"
                        @click="showEmployeesList = true"
                    >
                        {{ __('common:employee_other') }}
                    </MButton>
                    <template v-if="!hasInterns">
                        <CTooltip>
                            <MButton :disabled="!hasInterns">
                                {{ __('hr:intern') }}
                            </MButton>
                            <template #content>
                                <CText>{{ __('hr:no_intern') }}</CText>
                            </template>
                        </CTooltip>
                    </template>
                    <template v-else>
                        <MButton
                            :variant="!showEmployeesList ? 'primary' : 'light'"
                            @click="showEmployeesList = false"
                        >
                            {{ __('hr:intern') }}
                        </MButton>
                    </template>
                </CButtonGroup>
                <OrganizationFinder
                    v-model="selectedOrganizations"
                    has-unselect
                    multi
                    only-user-organizations
                >
                    <template #button-text-empty>
                        {{ __('components:choose_organization') }}
                    </template>
                </OrganizationFinder>
            </CHStack>
            <MButton
                class="tw-ml-2"
                :disabled="loading"
                variant="primary"
                @click="exportRegister"
            >
                {{ __('common:actions.export') }}
            </MButton>
        </CHStack>
        <StaffRegisterList
            ref="list"
            :selected-organizations="selectedOrganizations"
            @hasInterns="hasInterns = $event"
            @loading="loading = $event"
        />
    </CVStack>
</template>

<script>
    import StaffRegisterList from '@/modules/human-resources/components/registry/List/StaffRegisterList.vue';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import {defineComponent, ref} from 'vue';
    import {JsonAPISchema} from '@meekohq/lumos';
    import useStaffList from '@/modules/human-resources/composables/staff-register/useStaffList';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            OrganizationFinder,
            StaffRegisterList,
        },
        props: {},
        setup() {
            const loading = ref(false);
            const hasInterns = ref(false);
            const selectedOrganizations = ref([]);
            const {showEmployeesList} = useStaffList();

            const exportRegister = async function () {
                // Using the post method of the ApiLegacy to send a JsonApiDocument on hr/staffregister endpoint.
                useApi()
                    .legacy.post(
                        route('hr/staff/report/register'),
                        new JsonAPISchema.JsonApiDocument(
                            // Instanciation of the JsonApiData for the data of the JsonApiDocument.
                            new JsonAPISchema.JsonApiData({
                                // Pass an object with key/value of what we want to post.
                                organizations: selectedOrganizations.value.map(String),
                            })
                        ),
                        {responseType: 'blob'} // Set the response type on blob.
                    )
                    .then(response => {
                        // Create a Blob and pass the data's response to it.
                        const blob = new Blob([response.data]);
                        // Create an anchor Node.
                        const link = document.createElement('a');
                        // Set the title of the downloaded file.
                        link.download = __('hr:staff_register_filename');
                        // Set the URL loading to another window.
                        link.target = '_blank';
                        // Create an URL wich represent the blob passed in parameter.
                        link.href = window.URL.createObjectURL(blob);

                        // Dispatch a bubbling mouse event click on window.
                        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                        useNotification().success(__('common:exports.downloaded'));
                    })
                    .catch(error => {
                        if (!(error && error.response && error.response.status === 422)) {
                            useNotification().error(error);
                        }
                    });
            };

            return {
                loading,
                selectedOrganizations,
                showEmployeesList,
                hasInterns,
                exportRegister,
            };
        },
    });
</script>
