<template>
    <MMenu class="tw-w-full">
        <MMenuButton class="tw-w-full">
            <MButton
                class="tw-w-full"
                icon-menu
                :size="size"
                :variant="variant"
                @click="$refs.groupFilter.toggle()"
            >
                {{ groupBtnText }}
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem @click="$emit('input', null)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="!value"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('common:all_groups') }}
            </MMenuItem>
            <MMenuDivider />
            <MMenuItem
                v-for="(group, i) in nursery.groups"
                :key="'group' + i"
                @click="$emit('input', group.id)"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="value === group.id"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ group.name }}
            </MMenuItem>
        </MMenuItems>
    </MMenu>
</template>

<script>
    import _filter from 'lodash-es/filter';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: {
            value: {
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            variant: {
                type: String,
                default: 'light',
            },
            size: {
                type: String,
                default: 'base',
            },
        },
        computed: {
            groupBtnText() {
                if (this.value === null || !this.getGroupById(this.value)) {
                    this.$emit('input', null);

                    return __('common:all_groups');
                } else {
                    return this.getGroupById(this.value).name;
                }
            },
        },
        methods: {
            getGroupById(id) {
                return _filter(this.nursery.groups, group => {
                    return group.id === id;
                })[0];
            },
        },
    };
</script>
