import {Application} from '@meekohq/lumos';
import confApp from '@/config/app';
import confHttp from '@/config/http';
import providers from '@/bootstrap/providers';

export default Application.configure()
    .withConfig({
        app: confApp,
        http: confHttp,
    })
    .withProviders(providers)
    .create();
