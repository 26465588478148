import axios from 'axios';
import * as Alert from '@/modules/legacy/libs/Alert';
import __ from '@/modules/app/utils/i18n-facade';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import {Auth} from '@/facades';
import app from '@/config/app';

export default function () {
    const api = axios.create({
        baseURL: app.api_base_url,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    });

    api.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error && error.response) {
                if (error.response.status === 403) {
                    // HTTP_FORBIDDEN
                    useNotification().error(__('common:errors.forbidden'));

                    return Promise.reject(error);
                } else if (error.response.status === 500) {
                    // HTTP_INTERNAL_SERVER_ERROR
                    Alert.error({
                        title: __('common:errors.generic'),
                        html: __('app:you_can_contact_meeko_technical_support_by_entering_the_error_code', {
                            code: error.response.data.errors.id,
                        }),
                    });

                    return Promise.reject(error);
                }
            }

            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error?.response?.status === 401) {
                // HTTP_UNAUTHORIZED
                Auth.logout();
            }

            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error?.response?.status === 502 || error?.response?.status === 503) {
                window.location.replace('/maintenance');
            }

            return Promise.reject(error);
        }
    );

    return api;
}
