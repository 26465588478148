import {Model} from '@meekohq/lumos';
import TagModel from '@/modules/tag/models/TagModel';
import TagPivot from '@/modules/tag/models/TagPivot';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import PhotoKidPivot from '@/modules/photo/models/PhotoKidPivot';
import KidModel from '@/modules/family/models/KidModel';

export default class PhotoModel extends Model {
    public type = 'media/photos';

    public attributes: {
        id: string | undefined;
        legacy_id: number | undefined;
        account_id: string | undefined;
        organization_id: string | undefined;
        staff_id: string | undefined;
        description: string | undefined;
        filename: string | null | undefined;
        original_filename: string | undefined | null;
        extension: string | null | undefined;
    } = {
        id: undefined,
        legacy_id: undefined,
        account_id: undefined,
        organization_id: undefined,
        staff_id: undefined,
        description: undefined,
        filename: undefined,
        original_filename: undefined,
        extension: undefined,
    };

    staff() {
        return this.belongsTo('staff', StaffModel, 'staff_id');
    }

    kids() {
        return this.belongsToMany('kids', KidModel, PhotoKidPivot, 'photo_id', 'kid_id');
    }

    tags() {
        return this.morphToMany('tags', TagModel, TagPivot, 'resource', undefined, 'tag_id');
    }
}
