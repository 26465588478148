<template>
    <div class="Tools">
        <transition-group
            class="Tools__container"
            name="list-complete"
            tag="div"
        >
            <template v-for="item in menu">
                <div
                    v-if="item.type === 'group'"
                    :key="item.id"
                    class="Tools_group-name list-complete-item"
                >
                    {{ item.name }}
                </div>
                <router-link
                    v-if="item.type === 'item'"
                    :key="item.id"
                    class="Tools_item list-complete-item tw-group"
                    :class="item.colorClass"
                    :to="item.to"
                    @mouseenter.native="onItemHovered(item.id)"
                    @mouseleave.native="onItemUnhovered"
                >
                    <FontAwesomeIcon
                        class="Tools__item-icon"
                        fixed-width
                        :icon="item.icon"
                        :style="{color: item.color}"
                    />
                    <span class="Tools__item-name">{{ item.text }}</span>
                </router-link>
                <router-link
                    v-if="item.type === 'subitem'"
                    :key="item.id"
                    class="Tools__subitem list-complete-item"
                    :class="item.colorClass"
                    :to="item.to"
                    @mouseenter.native="onItemHovered(item.parent_id)"
                    @mouseleave.native="onItemUnhovered"
                >
                    <FontAwesomeIcon
                        class="Tools__subitem-icon"
                        :icon="item.icon"
                        :style="{color: item.color}"
                    />
                    <span class="Tools__subitem-name">{{ item.text }}</span>
                </router-link>
            </template>
        </transition-group>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import {useRoute, useRouter} from 'vue-router/composables';
    import useAccessRoute from '@/modules/app/composables/useAccessRoute';
    import {computedEager} from '@vueuse/core';

    export default defineComponent({
        props: {
            user: {type: Object, required: true},
            nursery: {type: Object, required: true},
        },
        setup(props) {
            const hoverTimeout = ref();
            const hoveredItem = ref<string | undefined>('');
            const route = useRoute();
            const router = useRouter();
            const {checkAccessToRouteName} = useAccessRoute(router);

            const planningModuleVisible = computedEager(() => modulePathActive('planning'));
            const familyModuleVisible = computedEager(() => modulePathActive('family'));
            const billingModuleVisible = computedEager(() => modulePathActive('billing'));
            const hrModuleVisible = computedEager(() => modulePathActive('hr'));

            function removeNullUndefined(obj: any) {
                return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
            }

            const menu = computed(() => {
                const items = [] as any[];

                if (checkAccessToRouteName('dashboard.show')) {
                    items.push({
                        type: 'item',
                        id: 'dashboard',
                        text: __('app:menu.dashboard'),
                        icon: 'fa-solid fa-gauge-high',
                        colorClass: 'color-cyan',
                        to: {name: 'dashboard.show', params: {nursery: props.nursery.id}},
                    });
                }

                if (
                    checkAccessToRouteName('module.planning') ||
                    checkAccessToRouteName('module.family') ||
                    checkAccessToRouteName('registrations.index') ||
                    checkAccessToRouteName('module.billing') ||
                    checkAccessToRouteName('module.hr')
                ) {
                    items.push({
                        type: 'group',
                        id: 'manage',
                        name: __('app:menu.manage'),
                    });
                }

                if (checkAccessToRouteName('module.planning')) {
                    items.push({
                        type: 'item',
                        id: 'plannings',
                        text: __('app:menu.plannings'),
                        icon: 'fa-duotone fa-calendar-alt',
                        colorClass: 'color-blue',
                        to: {name: 'module.planning', params: {nursery: props.nursery.id}},
                    });
                }

                if (hoveredItem.value === 'plannings' || planningModuleVisible.value) {
                    if (checkAccessToRouteName('nursery')) {
                        items.push({
                            id: 'plannings-kid',
                            parent_id: 'plannings',
                            type: 'subitem',
                            text: __('app:menu.kids_planning'),
                            icon: 'fa-solid fa-child-reaching',
                            colorClass: 'color-blue',
                            to: {
                                name: 'nursery',
                                params: {nursery: props.nursery.id},
                                query: removeNullUndefined({
                                    date: route.query.date,
                                    from: route.query.from,
                                    to: route.query.to,
                                }),
                            },
                        });
                    }

                    if (checkAccessToRouteName('planning.staffs')) {
                        items.push({
                            id: 'plannings-staff',
                            parent_id: 'plannings',
                            type: 'subitem',
                            text: __('app:menu.staffs_planning'),
                            icon: 'fa-solid fa-users',
                            colorClass: 'color-blue',
                            to: {
                                name: 'planning.staffs',
                                params: {nursery: props.nursery.id},
                                query: removeNullUndefined({
                                    date: route.query.date,
                                    from: route.query.from,
                                    to: route.query.to,
                                }),
                            },
                        });
                    }
                }

                if (checkAccessToRouteName('module.family')) {
                    items.push({
                        type: 'item',
                        id: 'family',
                        text: __('app:menu.family'),
                        icon: 'fa-duotone fa-family-pants',
                        colorClass: 'color-purple',
                        to: {name: 'module.family', params: {nursery: props.nursery.id}},
                    });
                }

                if (hoveredItem.value === 'family' || familyModuleVisible.value) {
                    if (checkAccessToRouteName('kids.index')) {
                        items.push({
                            id: 'family-kids',
                            parent_id: 'family',
                            type: 'subitem',
                            text: __('app:menu.kids'),
                            icon: 'fa-solid fa-child-reaching',
                            to: {name: 'kids.index', params: {nursery: props.nursery.id}},
                            colorClass: 'color-purple',
                        });
                    }
                    if (checkAccessToRouteName('familyMembers.index')) {
                        items.push({
                            id: 'family-members',
                            parent_id: 'family',
                            type: 'subitem',
                            text: __('app:menu.members'),
                            icon: 'fa-solid fa-user-tie',
                            to: {name: 'familyMembers.index', params: {nursery: props.nursery.id}},
                            colorClass: 'color-purple',
                        });
                    }
                    if (checkAccessToRouteName('families.index')) {
                        items.push({
                            id: 'family-families',
                            parent_id: 'family',
                            type: 'subitem',
                            text: __('app:menu.families'),
                            icon: 'fa-duotone fa-address-book',
                            to: {name: 'families.index', params: {nursery: props.nursery.id}},
                            colorClass: 'color-purple',
                        });
                    }
                }

                if (checkAccessToRouteName('registrations.index')) {
                    items.push({
                        type: 'item',
                        id: 'registrations',
                        text: __('app:menu.registrations'),
                        icon: 'fa-duotone fa-pen-alt',
                        colorClass: 'color-cyan',
                        to: {name: 'registrations.index', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('module.billing')) {
                    items.push({
                        type: 'item',
                        id: 'billings',
                        text: __('app:menu.billing'),
                        icon: 'fa-duotone fa-wallet',
                        colorClass: 'color-orange',
                        to: {name: 'module.billing', params: {nursery: props.nursery.id}},
                    });
                }

                if (hoveredItem.value === 'billings' || billingModuleVisible.value) {
                    if (checkAccessToRouteName('billings.index')) {
                        items.push({
                            id: 'billings-invoices',
                            parent_id: 'billings',
                            type: 'subitem',
                            colorClass: 'color-orange',
                            text: __('app:menu.invoices'),
                            icon: 'fa-duotone fa-file-invoice',
                            to: {name: 'billings.index', params: {nursery: props.nursery.id}},
                        });
                    }

                    if (checkAccessToRouteName('payments.index')) {
                        items.push({
                            id: 'billings-payments',
                            parent_id: 'billings',
                            type: 'subitem',
                            colorClass: 'color-orange',
                            text: __('app:menu.transactions'),
                            icon: 'fa-duotone fa-money-check-edit-alt !tw-text-base',
                            to: {name: 'payments.index', params: {nursery: props.nursery.id}},
                        });
                    }

                    if (checkAccessToRouteName('cashier/customers.index')) {
                        items.push({
                            id: 'billings-customers',
                            parent_id: 'billings',
                            type: 'subitem',
                            colorClass: 'color-orange',
                            text: __('app:menu.customers'),
                            icon: 'fa-duotone fa-file-user',
                            to: {name: 'cashier/customers.index', params: {nursery: props.nursery.id}},
                        });
                    }
                }

                if (checkAccessToRouteName('module.hr')) {
                    items.push({
                        type: 'item',
                        id: 'staffs',
                        text: __('app:menu.team'),
                        icon: 'fa-duotone fa-users',
                        colorClass: 'color-pink',
                        to: {name: 'module.hr', params: {nursery: props.nursery.id}},
                    });
                }

                if (hoveredItem.value === 'staffs' || hrModuleVisible.value) {
                    if (checkAccessToRouteName('staffs.index')) {
                        items.push({
                            id: 'staffs-index',
                            parent_id: 'staffs',
                            type: 'subitem',
                            text: __('app:menu.staffs'),
                            icon: 'fa-duotone fa-id-badge',
                            colorClass: 'color-pink',
                            to: {name: 'staffs.index', params: {nursery: props.nursery.id}},
                        });
                    }
                    if (checkAccessToRouteName('humanresources/reports')) {
                        items.push({
                            id: 'staffs-reports',
                            parent_id: 'staffs',
                            type: 'subitem',
                            text: __('app:menu.reports'),
                            icon: 'fa-duotone fa-file-chart-pie',
                            colorClass: 'color-pink',
                            to: {name: 'humanresources/reports', params: {nursery: props.nursery.id}},
                        });
                    }
                }

                if (
                    checkAccessToRouteName('photos.index') ||
                    checkAccessToRouteName('documents.index') ||
                    checkAccessToRouteName('communication.index') ||
                    checkAccessToRouteName('website.index')
                ) {
                    items.push({
                        type: 'group',
                        id: 'communicate',
                        name: __('app:menu.communicate'),
                    });
                }

                if (checkAccessToRouteName('photos.index')) {
                    items.push({
                        type: 'item',
                        id: 'photos',
                        text: __('app:menu.photos'),
                        icon: 'fa-duotone fa-image',
                        colorClass: 'color-blue',
                        to: {name: 'photos.index', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('documents.index')) {
                    items.push({
                        type: 'item',
                        id: 'documents',
                        text: __('app:menu.documents'),
                        icon: 'fa-duotone fa-file',
                        colorClass: 'color-purple',
                        to: {name: 'documents.index', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('communication.index')) {
                    items.push({
                        type: 'item',
                        id: 'communication',
                        colorClass: 'color-yellow',
                        text: __('app:menu.news'),
                        icon: 'fa-duotone fa-bullhorn',
                        to: {name: 'module.communication', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('website.index')) {
                    items.push({
                        type: 'item',
                        id: 'website',
                        colorClass: 'color-cyan',
                        text: __('common:website'),
                        icon: 'fa-solid fa-globe',
                        to: {name: 'website.index', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('equipment.index') || checkAccessToRouteName('settings')) {
                    items.push({
                        type: 'group',
                        id: 'settings-group',
                        name: __('app:menu.tools'),
                    });
                }

                if (checkAccessToRouteName('equipment.index')) {
                    items.push({
                        type: 'item',
                        id: 'equipments',
                        text: __('app:menu.equipments'),
                        icon: 'fa-duotone fa-boxes-alt',
                        colorClass: 'color-light-green',
                        to: {name: 'equipment.index', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('settings')) {
                    items.push({
                        type: 'item',
                        id: 'settings',
                        text: __('app:menu.settings'),
                        icon: 'fa-solid fa-cog',
                        colorClass: 'color-settings',
                        to: {name: 'settings', params: {nursery: props.nursery.id}},
                    });
                }

                return items;
            });

            function modulePathActive(name: string) {
                if (route.path.startsWith('/nurseries')) {
                    const url = route.path.split('/');
                    // Remove '/', 'nurseries' and ':id'
                    url.shift();
                    url.shift();
                    url.shift();

                    return url[0] === name;
                }

                return false;
            }

            function onItemHovered(item: any) {
                clearTimeout(hoverTimeout.value);
                hoverTimeout.value = setTimeout(() => {
                    hoveredItem.value = item;
                }, 300);
            }

            function onItemUnhovered() {
                clearTimeout(hoverTimeout.value);
                hoverTimeout.value = setTimeout(() => {
                    hoveredItem.value = undefined;
                }, 300);
            }

            return {
                hoveredItem,
                menu,
                onItemHovered,
                onItemUnhovered,
            };
        },
    });
</script>

<style scoped>
    .Tools__container {
        @apply tw-flex tw-gap-1;
        @media screen(sm) {
            @apply tw-flex-col;
        }
    }

    .Tools_group-name {
        @apply tw-hidden;

        @media screen(lg) {
            @apply tw-mt-1 tw-block tw-truncate tw-px-2 tw-font-display tw-text-xs tw-font-semibold tw-uppercase tw-text-primary-950/40;
        }
    }

    .Tools_item {
        @apply tw-min-h-[38px];
        @apply tw-flex tw-flex-col tw-items-center tw-gap-1;
        @apply tw-shrink-0 tw-rounded-lg tw-p-1.5 sm:tw-px-0.5;
        @apply tw-text-primary-950/70 hover:tw-bg-primary-800/10 hover:tw-text-primary-950/90;

        @media screen(lg) {
            @apply tw-flex-row tw-justify-center tw-gap-2 tw-rounded-xl tw-p-2 tw-leading-6;
        }
    }

    .Tools__item-name {
        @apply tw-flex-1 tw-truncate tw-font-display tw-text-xs;
        @apply tw-w-full tw-truncate tw-text-center tw-leading-tight;
        @apply lg:tw-block lg:tw-text-left lg:tw-text-base;
    }

    .Tools__item-icon {
        @apply tw-w-6 tw-shrink-0 tw-text-xl tw-leading-none;
        @apply tw-flex tw-items-center tw-justify-center;
        color: var(--color);
    }

    .Tools_item.router-link-active {
        @apply tw-font-medium;
        background: var(--color) linear-gradient(to bottom, var(--color) 0%, var(--color-to) 100%);

        .Tools__item-name,
        .Tools__item-icon {
            @apply tw-text-white;
        }
    }

    .Tools__subitem {
        @apply tw-min-h-[32px];
        @apply tw-flex tw-flex-col tw-items-center tw-gap-1 tw-font-display;
        @apply tw-shrink-0 tw-rounded-lg tw-p-1.5 sm:tw-px-0.5;
        @apply tw-text-primary-950/70 hover:tw-bg-primary-800/10 hover:tw-text-primary-950/90;

        @media screen(lg) {
            @apply tw-flex-row tw-justify-center;
            @apply tw-rounded-lg tw-leading-6;
            @apply tw-ml-4 tw-px-2 tw-py-0.5;
        }
    }

    .Tools__subitem-name {
        @apply tw-flex-1 tw-truncate tw-text-xs;
        @apply tw-w-full tw-truncate tw-text-center tw-leading-tight;
        @apply lg:tw-block lg:tw-text-left lg:tw-text-base;
    }

    .Tools__subitem.router-link-active {
        @apply tw-bg-primary-800/10 tw-font-medium;
        color: var(--color);
    }

    .Tools__subitem-icon {
        color: var(--color);
        @apply tw-w-6 tw-shrink-0 tw-text-center tw-text-2xl tw-leading-none lg:tw-text-xl;
    }

    .color-cyan {
        --color: #00bcd4;
        --color-to: #00a5bb;
    }

    .color-blue {
        --color: #2196f3;
        --color-to: #0d8aee;
    }

    .color-purple {
        --color: #9446ed;
        --color-to: #872feb;
    }

    .color-yellow {
        --color: #eabe33;
        --color-to: #de9f18;
    }

    .color-orange {
        --color: #f8771c;
        --color-to: #ef6809;
    }

    .color-pink {
        --color: #e91e63;
        --color-to: #d81558;
    }

    .color-green {
        --color: #009688;
        --color-to: #007d71;
    }

    .color-light-green {
        --color: #65c300;
        --color-to: #45ad00;
    }

    .color-settings {
        --color: #7790a7;
        --color-to: #68839d;
    }

    .list-complete-item {
        transition:
            opacity 500ms,
            transform 500ms;
    }

    .list-complete-enter {
        opacity: 0;
    }

    .list-complete-leave-to {
        opacity: 0;
    }

    .list-complete-leave-active {
        display: none;
    }
</style>
