<template>
    <div ref="target">
        <div
            class="PhotoListItem"
            :style="thumbnailImage(item)"
        >
            <div
                v-if="item.video"
                class="PhotoListItem__video"
            >
                <FontAwesomeIcon icon="fa-solid fa-play" />
            </div>
            <div
                class="PhotoListItem__trigger"
                @click="$emit('selected')"
            />
            <div class="PhotoListItem__informations-wrapper">
                <div class="tw-truncate">
                    {{ item.description }}
                </div>
                <div>
                    {{ Epoch.fromJSDate(date).toLocaleString(Epoch.presets.DATE_SHORT) }}
                </div>
            </div>
            <div class="PhotoListItem__actions-wrapper">
                <div
                    class="PhotoListItem__actions"
                    :class="{'PhotoListItem__actions-visible': tagsSelectorVisible}"
                >
                    <TagsSelector
                        :editable="can('update', 'photos')"
                        size="sm"
                        :taggable-model="itemModel"
                        taggable-type="photo"
                        :tags="itemModel.tags().value().toArray()"
                        @attached="itemModel.tags().value().push($event)"
                        @detached="itemModel.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                        @hidden="tagsSelectorVisible = false"
                        @shown="tagsSelectorVisible = true"
                    />
                    <MMenu>
                        <MMenuButton>
                            <MButton
                                icon-ellipsis
                                size="sm"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem
                                v-if="can('update', 'photos')"
                                :label="__('common:actions.update')"
                                @click="$refs.photoEditModal.$refs.modal.show()"
                            >
                                <template #icon>
                                    <FontAwesomeIcon
                                        fixed-width
                                        icon="fa-solid fa-pen-square"
                                    />
                                </template>
                            </MMenuItem>
                            <MMenuItem
                                :label="__('common:actions.download')"
                                @click="$emit('download')"
                            >
                                <template #icon>
                                    <FontAwesomeIcon
                                        fixed-width
                                        icon="fa-solid fa-download"
                                    />
                                </template>
                            </MMenuItem>
                            <MMenuItem
                                v-if="can('delete', 'photos')"
                                :label="__('common:actions.delete')"
                                variant="danger"
                                @click="$emit('delete')"
                            >
                                <template #icon>
                                    <FontAwesomeIcon
                                        fixed-width
                                        icon="fa-solid fa-trash"
                                    />
                                </template>
                            </MMenuItem>
                        </MMenuItems>
                    </MMenu>
                </div>
            </div>
        </div>
        <PhotoEditModal
            ref="photoEditModal"
            :modal-id="'modalPhoto' + item.id"
            :nursery="nursery"
            :photo="item"
            :photo-model="itemModel"
        />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, type PropType, ref, watch} from 'vue';
    import moment from 'moment';
    import PhotoEditModal from '@/modules/photo/components/organisms/PhotoEditModal.vue';
    import {useElementVisibility, useIntersectionObserver} from '@vueuse/core';
    import {Epoch} from '@meekohq/lumos';
    import type PhotoModel from '@/modules/photo/models/PhotoModel';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import useAbility from '@/modules/app/composables/useAbility';

    export default defineComponent({
        components: {TagsSelector, PhotoEditModal},
        props: {
            item: {
                type: Object,
                required: true,
            },
            itemModel: {
                type: Object as PropType<PhotoModel>,
                required: true,
            },
            nursery: {
                type: undefined,
                required: true,
            },
            selectedPhoto: {
                type: undefined,
                required: true,
            },
        },

        setup(props, {emit}) {
            const target = ref(null);
            const targetIsVisible = useElementVisibility(target);
            const tagsSelectorVisible = ref(false);
            const {can} = useAbility();

            useIntersectionObserver(target, ([{isIntersecting}]) => {
                targetIsVisible.value = isIntersecting;
            });

            watch(targetIsVisible, isVisible => {
                if (isVisible) {
                    emit('visible', props.item);
                } else {
                    emit('hidden', props.item);
                }
            });

            const thumbnailImage = (photo: {thumbnail_url: string}) => {
                return `background-image: url("${photo.thumbnail_url}")`;
            };

            const date = computed(() => {
                return Epoch.fromTimestamp(props.item.taken_at).toJSDate();
            });

            return {
                thumbnailImage,
                moment,
                target,
                date,
                tagsSelectorVisible,
                Epoch,
                can,
            };
        },
    });
</script>

<style scoped>
    .PhotoListItem {
        @apply tw-relative tw-aspect-square tw-bg-cover tw-bg-center;

        &:hover {
            .PhotoListItem__actions {
                @apply tw-opacity-100;
            }
        }
    }

    .PhotoListItem__video {
        @apply tw-absolute tw-left-2/4 tw-top-2/4;
        @apply tw-rounded-full tw-bg-white/50;
        @apply tw-text-2xl tw-text-white;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-h-12 tw-w-12;
        @apply tw--translate-x-2/4 tw--translate-y-2/4;
    }

    .PhotoListItem__trigger {
        @apply tw-absolute;
        @apply tw-h-full tw-w-full;
        @apply tw-cursor-pointer;
    }

    .PhotoListItem__informations-wrapper {
        @apply tw-absolute tw-inset-x-0 tw-bottom-0 tw-w-full tw-min-w-0 tw-p-1 tw-text-sm tw-text-white;
        @apply tw-bg-black/30;
        text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
    }

    .PhotoListItem__actions-wrapper {
        @apply tw-absolute tw-inset-x-0 tw-top-0;
    }

    .PhotoListItem__actions {
        @apply tw-opacity-0;
        @apply tw-w-full;
        @apply tw-transition-all tw-duration-300;
        @apply tw-p-1;
        @apply tw-bg-black/30;
        @apply tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-between tw-gap-1;
    }

    .PhotoListItem__actions-visible {
        opacity: theme(opacity.100) !important;
    }
</style>
