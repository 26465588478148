<template>
    <div
        class="printable tw-relative tw-block tw-w-full tw-bg-gray-50"
        :class="lineClass"
        :style="lineStyle"
        @mouseleave="showAddEvent = false"
        @mouseover="showAddEvent = true"
    >
        <div
            v-if="hasPendingEvents"
            class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-animate-pulse tw-rounded tw-bg-gray-300/80 tw-opacity-80"
        />
        <clock
            v-if="clockEnabled && showClock"
            :closing="closing"
            :day="momentDay"
            :opening="opening"
        />
        <divider
            v-if="showDivider || showDividerOnPrint"
            :closing="closing"
            :opening="opening"
            :show-divider="showDivider"
            :show-divider-on-print="showDividerOnPrint"
            :simple="size !== 'lg'"
        />
        <SegmentFullDay
            v-if="filteredFullDayEvents.length || alwaysShowFullDay"
            :events="filteredFullDayEvents"
            :size="size"
            @add="$emit('add')"
        />
        <SegmentGhost
            v-if="
                $can('create', 'staffs_planning') &&
                ((showAddEvent && (shift || alt || copyActive || alwaysShowGhost)) || ghostLoading)
            "
            :auto-save="autoSave"
            :closing="closing"
            :day="momentDay"
            :event="latestEvent"
            :events="filteredSegments"
            :is-template="isTemplate"
            :loading.sync="ghostLoading"
            :open-event-modal="createWithModal"
            :opening="opening"
            :show-cancel-copy="!alwaysShowGhost"
            :size="size"
            :staff="staff"
            :week-template="weekTemplate"
            @pushEvent="$emit('pushEvent', $event)"
            @pushEventTemplate="$emit('pushEventTemplate', $event)"
        />
        <Segment
            v-for="event in filteredSegments"
            :key="'staffSegment ' + event.id"
            :auto-save="autoSave"
            :closing="closing"
            :day="momentDay"
            :event="event"
            :events="filteredSegments"
            :is-template="isTemplate"
            :opening="opening"
            :resizable="$can('update', 'staffs_planning') && !event.isPending"
            :show-menu="$can('update', 'staffs_planning')"
            :show-note="showNote"
            :size="size"
        />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, onUnmounted, type PropType, ref, watch} from 'vue';
    import {useMagicKeys} from '@vueuse/core';
    import moment, {type Moment} from 'moment';
    import chroma from 'chroma-js';
    import _concat from 'lodash-es/concat';
    import _orderBy from 'lodash-es/orderBy';
    import {EventBus} from '@/modules/legacy/utils/bus';
    import useLine from '@/modules/legacy/libs/planning/useLine';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
    import Segment from '@/modules/human-resources/components/calendar/Segment.vue';
    import SegmentFullDay from '@/modules/human-resources/components/calendar/SegmentFullDay.vue';
    import SegmentGhost from '@/modules/human-resources/components/calendar/SegmentGhost.vue';
    import Divider from '@/modules/legacy/components/Modules/PlanningGenerator/Divider.vue';
    import Clock from '@/modules/legacy/components/Modules/PlanningGenerator/Clock.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import {Epoch} from '@meekohq/lumos';

    export default defineComponent({
        components: {
            Segment,
            SegmentFullDay,
            SegmentGhost,
            Divider,
            Clock,
        },
        props: {
            staff: {
                type: Object,
            },
            day: {
                type: Object,
                required: true,
            },
            opening: {
                required: true,
            },
            closing: {
                required: true,
            },
            templateEvents: {
                type: Array as PropType<TemplateEventModel[]>,
                required: false,
            },
            events: {
                type: Array as PropType<EventModel[]>,
                required: false,
            },
            fullDayEvents: {
                type: Array as PropType<EventModel[]>,
                required: false,
            },
            alwaysShowFullDay: {
                type: Boolean,
                default: false,
            },
            showMenu: {
                type: Boolean,
                default: false,
            },
            showClock: {
                type: Boolean,
                default: true,
            },
            showDivider: {
                type: Boolean,
                default: true,
            },
            showDividerOnPrint: {
                type: Boolean,
                default: true,
            },
            showNote: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'lg',
            },
            displayFullDay: {
                type: Boolean,
                default: false,
            },
            resizable: {
                type: Boolean,
                default: false,
            },
            alwaysShowGhost: {
                type: Boolean,
                default: false,
            },
            isTemplate: {
                type: Boolean,
                default: false,
            },
            autoSave: {
                type: Boolean,
                default: true,
            },
            weekTemplate: {
                type: String,
            },
            createWithModal: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const {shift, alt, escape} = useMagicKeys();
            watch(escape, v => {
                v ? EventBus.$emit('calendar:staff:event:clearCopy') : null;
            });
            const copyActive = ref(false);
            onMounted(() => {
                EventBus.$on('calendar:staff:event:copy', () => {
                    copyActive.value = true;
                });
                EventBus.$on('calendar:staff:event:clearCopy', () => {
                    copyActive.value = false;
                });
            });

            onUnmounted(() => {
                EventBus.$off('calendar:staff:event:copy');
                EventBus.$off('calendar:staff:event:clearCopy');
            });

            const showAddEvent = ref(false);
            const ghostLoading = ref(false);
            const hasPendingEvents = ref(false);

            const {can} = useAbility();

            const momentDay = computed<Moment>(() => {
                if (props.day instanceof Epoch) {
                    return moment(props.day.toISOString());
                } else {
                    return moment(props.day);
                }
            });

            const {latestEvent, clockEnabled, outOfDay} = useLine(momentDay.value, props.opening, props.closing);

            // Events (template) for current day only
            const filteredPlanningTemplates = computed(() => {
                const planningTemplates: TemplateEventModel[] = [];
                const dayPlannings = props.templateEvents?.filter(
                    planning => planning.attributes.day === momentDay.value.day()
                );
                dayPlannings?.forEach(planning => {
                    const isOutOfDay = outOfDay(planning.startedAt.unix(), planning.endedAt.unix());
                    !isOutOfDay ? planningTemplates.push(planning) : null;
                });

                return planningTemplates;
            });
            // Events (datetime) for current day only
            const filteredEvents = computed(() => {
                const events: EventModel[] = [];
                const dayEvents = props.events?.filter(event => {
                    return (
                        event.attributes.date_event === null &&
                        moment(event.attributes.datetime_event?.started_at).isSame(momentDay.value, 'day')
                    );
                });
                dayEvents?.forEach(event => {
                    const isOutOfDay = outOfDay(
                        moment(event.attributes.datetime_event?.started_at).unix(),
                        moment(event.attributes.datetime_event?.ended_at).unix()
                    );
                    !isOutOfDay ? events.push(event) : null;
                });

                return events;
            });

            // Events (fullday) for current day only
            const filteredFullDayEvents = computed(() => {
                return (
                    props.fullDayEvents?.filter(event => {
                        return (
                            momentDay.value.clone().isBetween(event.startedAt, event.endedAt, 'day', '[]') &&
                            event.attributes.staff_id === props.staff?.id
                        );
                    }) || []
                );
            });

            const filteredSegments = computed(() => {
                const allSegments = _concat([] as any[], filteredPlanningTemplates.value, filteredEvents.value);

                return _orderBy(allSegments, item => item.startedAt);
            });

            const lineClass = computed(() => {
                let computedClass = 'tw-rounded-2xl';
                if (props.fullDayEvents?.length || props.alwaysShowFullDay) {
                    if (props.size === 'lg') {
                        computedClass = 'tw-rounded-l tw-rounded-r-md';
                    } else {
                        computedClass = 'tw-rounded-l-none tw-rounded-r-md';
                    }
                }

                return computedClass;
            });
            const lineStyle = computed(() => {
                let style = '';
                switch (props.size) {
                    case 'sm':
                        style = 'height: 25px;';
                        break;
                    case 'md':
                        style = 'height: 29px;';
                        break;
                    case 'lg':
                        style = 'height: 34px;';
                        break;
                }

                if (props.fullDayEvents?.length || props.alwaysShowFullDay) {
                    style += ' width: calc(100% - 20px); margin-left: 20px;';
                }

                // Add background color from fullDayEvent
                hasPendingEvents.value = false;
                const orderedFullDayEvents = _orderBy(
                    filteredFullDayEvents.value,
                    event => event.eventType().value().factor
                );
                if (orderedFullDayEvents.length) {
                    const fullDayEvent = orderedFullDayEvents[0];
                    const color = fullDayEvent.eventType().value().attributes.color || null;
                    if (color) {
                        const backgroundColor = chroma(color).luminance(0.7).saturate(0.7).brighten(0.7);
                        style += ` background-color: ${backgroundColor};`;

                        if (fullDayEvent.isPending && can('read', 'hr_request')) {
                            hasPendingEvents.value = true;
                        }
                    }
                }

                return style;
            });

            return {
                momentDay,
                latestEvent,
                showAddEvent,
                ghostLoading,
                hasPendingEvents,
                clockEnabled,
                filteredFullDayEvents,
                filteredSegments,
                lineClass,
                lineStyle,

                shift,
                alt,
                copyActive,
            };
        },
    });
</script>
