import useApi from '@/modules/app/composables/useApi';
import route from '@/modules/legacy/libs/ziggy';
import {forEach, head} from 'lodash-es';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import {ref} from 'vue';
import useBroadcast from '@/modules/app/composables/useBroadcast';

/**
 * This is a composition function that is used to export data to xls file.
 * It uses the legacy api to export data. The resources are passed as a parameter
 * of the getExport function to specify which resources to export.
 * It binds to the export_data event and when the broadcast is received,
 * it creates a link and clicks it to download the file.
 */
export function useXlsExport() {
    const exportLoading = ref(false);
    const {error: toastError} = useNotification();

    /**
     * Post export data using the legacy api. The resources parameter is an array of objects
     * that specify the resource name and the filters to apply to the resource.
     * Example: [{name: 'transactions', filters: {from: '2023-03-01', to: '2023-03-31'}]
     * @param nurseries
     * @param resources
     */
    function getExport(nurseries: string[], resources: {name: string; filters: Record<string, any>}[]) {
        exportLoading.value = true;
        useApi()
            .legacy.post(route('export'), {
                nurseries,
                resources,
            })
            .catch(error => {
                exportLoading.value = false;

                if (error?.response?.status === 422) {
                    forEach(error.response.data.errors, value => {
                        toastError(head(value));
                    });
                } else {
                    toastError(error);
                }
            });
    }

    /**
     * Bind to the export_data event and when the broadcast is received,
     * it creates a link and clicks it to download the file.
     */
    function bindExportBroadcast() {
        useBroadcast().sessionChannel.bind('export_data', response => {
            const link = document.createElement('a');
            link.href = response.url;
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );
            exportLoading.value = false;
        });
    }

    /**
     * Unbind from the export_data event. Used when the component is destroyed.
     */
    function unbindExportBroadcast() {
        useBroadcast().sessionChannel.unbind('export_data');
    }

    return {
        exportLoading,
        getExport,
        bindExportBroadcast,
        unbindExportBroadcast,
    };
}
