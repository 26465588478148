<script setup lang="ts">
    import {provide, ref, watch} from 'vue';
    import MCalendarDayItem from '@/modules/meeko-ui/components/MCalendar/MCalendarDaysItem.vue';
    import useMCalendarDays, {keyMCalendarDays} from '@/modules/meeko-ui/components/MCalendar/useMCalendarDays';
    import {useElementHover} from '@vueuse/core';

    const daysState = useMCalendarDays();
    provide(keyMCalendarDays, daysState);

    const {weekDays, daysInActiveMonth, hoveredDay} = daysState;

    const daysElement = ref();
    const isHovered = useElementHover(daysElement);

    watch(isHovered, newValue => {
        if (!newValue) {
            hoveredDay.value = undefined;
        }
    });
</script>

<template>
    <div>
        <div class="MCalendarDays__grid">
            <div
                v-for="day in weekDays"
                :key="day"
                class="MCalandarDays__weekday"
            >
                {{ day }}
            </div>
        </div>
        <div
            ref="daysElement"
            class="MCalendarDays__grid"
        >
            <MCalendarDayItem
                v-for="dayDate in daysInActiveMonth"
                :key="dayDate.day.toTimestamp()"
                :day="dayDate"
            />
        </div>
    </div>
</template>

<style scoped>
    .MCalendarDays__grid {
        @apply tw-grid tw-grid-flow-row tw-grid-cols-7;
    }

    .MCalandarDays__weekday {
        @apply tw-flex tw-justify-center tw-py-2 tw-font-display tw-text-sm tw-capitalize tw-text-gray-500;
    }
</style>
