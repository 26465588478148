import type {GeneratePersonalAccessTokenRequest} from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenRequest';
import type {GeneratePersonalAccessTokenResponse} from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenResponse';
import {Binding} from '@meekohq/lumos';

export const GeneratePersonalAccessTokenUseCaseBinding = new Binding<GeneratePersonalAccessTokenUseCase>();

/**
 * Generate a personal access token. Requires sudo mode.
 * @returns The generated personal access token with the model
 */
export interface GeneratePersonalAccessTokenUseCase {
    generate(request: GeneratePersonalAccessTokenRequest): Promise<GeneratePersonalAccessTokenResponse>;
}
