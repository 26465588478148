<template>
    <div class="showHours tw-relative tw-h-7 tw-w-full">
        <div
            v-for="(minute, i) in enumerateMinutes"
            v-show="showHour(i)"
            :key="'min' + minute"
            class="hour tw-absolute"
            :style="{left: unixToPercent(minute.unix()) + '%'}"
        >
            <CBadge
                :class="[{'!tw-px-1 !tw-text-sm': small}]"
                :rounded="true"
                size="sm"
                variant="primary"
            >
                {{ formatHours(minute) }}
            </CBadge>
            <div
                v-if="gridOnHover"
                class="showGrid tw-absolute tw-right-0 tw-top-3 tw-z-50 tw-w-1/2"
            >
                <div class="tw-h-full tw-w-1 tw-border-l tw-border-dashed tw-border-gray-300" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import type {Moment} from 'moment';
    import moment from 'moment';

    export default defineComponent({
        props: {
            opening: {
                type: String,
            },
            closing: {
                type: String,
            },
            simple: {
                type: Boolean,
                default: false,
            },
            small: {
                type: Boolean,
                default: false,
            },
            gridOnHover: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const enumerateMinutes = computed(() => {
                const minutes: Moment[] = [];

                const currentDate = moment()
                    .hour(moment.duration(props.opening).hours())
                    .minute(moment.duration(props.opening).minutes())
                    .second(0)
                    .add(30, 'minutes')
                    .startOf('hours');
                // Trick to round hours (7:45 become 8.00), not a step.

                const lastDate = moment()
                    .hour(moment.duration(props.closing).hours())
                    .minute(moment.duration(props.closing).minutes())
                    .second(0)
                    .add(30, 'minutes')
                    .startOf('hours');

                // Planning 24/24
                lastDate.hours() === 23 && lastDate.minutes() > 0 ? lastDate.add(1, 'hour') : null;

                minutes.push(currentDate.clone());
                while (currentDate.add(60, 'minutes').diff(lastDate) <= 0) {
                    minutes.push(currentDate.clone());
                }

                return minutes;
            });

            function showHour(index: number) {
                if (props.simple) {
                    return index % 2 === 0;
                }

                return true;
            }

            function unixToPercent(unix: number) {
                const openingTimestamp = moment()
                    .hours(moment.duration(props.opening).hours())
                    .minutes(moment.duration(props.opening).minutes())
                    .seconds(moment.duration(props.opening).seconds())
                    .unix();

                const closingTimestamp = moment()
                    .hours(moment.duration(props.closing).hours())
                    .minutes(moment.duration(props.closing).minutes())
                    .seconds(moment.duration(props.closing).seconds())
                    .unix();

                return ((unix - openingTimestamp) * 100) / (closingTimestamp - openingTimestamp);
            }

            function formatHours(min: Moment) {
                const hourFormat = props.small ? 'H' : 'HH';

                return min.minutes() === 0 ? min.format(`${hourFormat}`) + 'h' : min.format(`${hourFormat}:mm`);
            }

            return {
                enumerateMinutes,
                showGrid: false,
                showHour,
                unixToPercent,
                formatHours,
            };
        },
    });
</script>

<style scoped>
    .hour {
        &:after {
            position: absolute;
            top: 93%;
            left: 50%;
            margin-left: -5px;
            border: solid transparent;
            border-width: 5px;
            border-top-color: #ddeefb;
            content: ' ';
        }
    }

    .showGrid {
        @apply tw-opacity-0;
    }

    .showHours:hover {
        .showGrid {
            @apply tw-h-screen tw-opacity-50;
        }
    }

    @media print {
        .showGrid {
            @apply tw-h-screen tw-opacity-50;
        }
    }
</style>
