<template>
    <WidgetCore>
        <div class="NotificationsWidget__header">
            <MMenu
                arrow
                placement="left-start"
                :portal="false"
            >
                <MMenuButton>
                    <MButton
                        icon-menu
                        size="sm"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon
                                v-if="notificationCharacteristicsFromFilter"
                                :icon="notificationCharacteristicsFromFilter.icon"
                            />
                            <FontAwesomeIcon
                                v-else
                                icon="fa-solid fa-filter"
                            />
                        </template>
                        <template v-if="notificationCharacteristicsFromFilter">
                            {{ notificationCharacteristicsFromFilter.title }}
                        </template>
                        <template v-else>
                            {{ __('common:actions.filter') }}
                        </template>
                    </MButton>
                </MMenuButton>

                <MMenuItems>
                    <MMenuItem
                        :label="__('notification:all_notifications')"
                        @click="filter = null"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-check-double"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuDivider v-if="haveManyNurseries" />
                    <MSubMenu
                        v-if="haveManyNurseries"
                        arrow
                        placement="left-start"
                    >
                        <MMenuButton>
                            <MMenuItem manual>
                                <template #icon>
                                    <FontAwesomeIcon
                                        fiexed-width
                                        icon="fa-solid fa-home"
                                    />
                                </template>
                                {{ __('common:actions.filter_by_organizations') }}
                                <CBadge
                                    v-if="nurseries.length > 0"
                                    class="tw-ml-2"
                                    size="sm"
                                    variant="blue"
                                >
                                    {{ __('common:selected_elements_count', {count: nurseries.length}) }}
                                </CBadge>
                            </MMenuItem>
                        </MMenuButton>
                        <MMenuItems container-class="tw-w-72">
                            <div
                                v-for="(nurseryItem, i) in user.nurseries"
                                :key="'nurseryItem' + i"
                                class="tw-flex tw-items-center tw-gap-2 tw-px-3 tw-py-2"
                            >
                                <CCheckbox
                                    :value="nurseries.includes(nurseryItem.id)"
                                    @click="switchNurseryInFilter(nurseryItem.id)"
                                >
                                    <div class="tw-line-clamp-1">
                                        {{ nurseryItem.name }}
                                    </div>
                                </CCheckbox>
                                <CBadge variant="blue">
                                    {{ nurseryItem.city }}
                                </CBadge>
                            </div>
                        </MMenuItems>
                    </MSubMenu>
                    <MMenuDivider />
                    <div class="tw-max-h-72 tw-overflow-y-auto">
                        <MMenuItem
                            v-for="(notification, i) in visibleNotifications"
                            :key="'notification' + i"
                            :label="notification.title"
                            :value="notification.type"
                            @click="filter = notification.values"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    :icon="notification.icon"
                                />
                            </template>
                        </MMenuItem>
                    </div>
                </MMenuItems>
            </MMenu>
            <div class="NotificationsWidget__actions">
                <MButton
                    :label="__('common:mark_all_as_read')"
                    size="sm"
                    @click="markAsRead"
                />
                <MButton
                    :label="__('common:settings')"
                    size="sm"
                    @click="openSettings"
                />
            </div>
        </div>
        <div class="NotificationsWidget__list">
            <div
                v-if="!notifications.data?.length"
                class="NotificationsWidget__empty-list"
            >
                {{ __('notification:there_is_no_notification') }}
            </div>
            <MScrollLoading
                v-else
                :distance="0.5"
                :end-of-search="endOfSearch"
                :loading="newNotificationFetching"
                @load-more="getNextNotifications"
            >
                <template v-for="notification in notifications.data">
                    <notification
                        :key="'notifications-' + notification.id"
                        class="NotificationsWidget__list-item"
                        :notification="notification"
                        :user="user"
                        @deleteNotification="deleteNotification($event)"
                        @updateNotification="updateNotification($event)"
                    />
                </template>
            </MScrollLoading>
        </div>
    </WidgetCore>
</template>

<script>
    import Vue, {nextTick} from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import _concat from 'lodash-es/concat';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import {notification} from '@/modules/legacy/mixins/notification';
    import Notification from '@/modules/notification/components/Notification.vue';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import WidgetCore from '@/modules/app/components/atoms/WidgetCore.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MScrollLoading from '@/modules/meeko-ui/components/MScrollLoading.vue';

    export default {
        components: {
            MScrollLoading,
            WidgetCore,
            Notification,
        },
        mixins: [notification],
        props: {
            nursery: {
                type: Object,
            },
            user: {
                type: Object,
            },
        },
        data: () => ({
            newNotificationFetching: false,
            notifications: [],
            filter: null,
            notificationCharacteristicsFromFilter: null,
            nurseries: [],
        }),
        computed: {
            haveManyNurseries() {
                if (this.user && this.user.nurseries) {
                    return this.user.nurseries.length > 1;
                }
            },
            visibleNotifications() {
                return this.notificationsCharacteristics.filter(item => item.visible);
            },
            visibleNotificationsTypes() {
                return this.notificationsCharacteristics.filter(item => item.visible).flatMap(item => item.values);
            },
            moment() {
                return moment;
            },
            endOfSearch() {
                return this.notifications.current_page === this.notifications.last_page;
            },
        },
        watch: {
            filter: {
                handler(val) {
                    if (val) {
                        this.notificationCharacteristicsFromFilter = this.notificationsCharacteristics.find(
                            item => this.formateType(item.type) === val[0]
                        );
                    } else {
                        this.notificationCharacteristicsFromFilter = null;
                    }
                    this.getNotifications();
                },
                deep: true,
            },
            nurseries: {
                handler() {
                    this.getNotifications();
                },
                deep: true,
            },
        },
        beforeDestroy() {
            this.$bus.$off('refreshNotification');
            this.$bus.$off('update:notification');
            this.$bus.$off('updateMessageNotification');
        },
        mounted() {
            this.getNotifications();

            this.$bus.$on('refreshNotification', notification => {
                const notif =
                    this.notifications && this.notifications.data
                        ? this.notifications.data.find(item => item.id === notification.id)
                        : null;
                if (notif) {
                    const index = this.notifications.data.indexOf(notif);
                    Vue.set(this.notifications.data, index, notification);
                }
            });

            this.$bus.$on('update:notification', notification => {
                this.updateNotification(notification);
            });

            this.$bus.$on('updateMessageNotification', notification => {
                this.updateMessage(notification);
            });

            useBroadcast().privateChannel.bind(useBroadcast().notificationEvent, () => {
                this.getNotifications();
            });
        },
        methods: {
            getNotifications() {
                useApi()
                    .legacy.get(route('notifications.index'), {
                        params: {
                            page: 1,
                            nurseries: this.nurseries ? this.nurseries : undefined,
                            types: this.filter ? this.filter : this.visibleNotificationsTypes,
                        },
                    })
                    .then(response => {
                        this.notifications = response.data;
                    });
            },
            formateType(type) {
                return type.substr(0, type.length - 3);
            },
            getNextNotifications() {
                this.newNotificationFetching = true;

                // Scroll the loading component into view
                nextTick(() =>
                    document
                        .getElementsByClassName('NotificationsWidget__list')[0]
                        .lastElementChild.scrollIntoView(false)
                );

                setTimeout(() => {
                    useApi()
                        .legacy.get(route('notifications.index'), {
                            params: {
                                page: this.notifications.current_page + 1,
                                nurseries: this.nurseries ? this.nurseries : undefined,
                                types: this.filter ? this.filter : this.visibleNotificationsTypes,
                            },
                        })
                        .then(response => {
                            this.notifications.current_page = response.data.current_page;
                            this.notifications.data = _concat(this.notifications.data, response.data.data);
                            this.newNotificationFetching = false;
                        });
                }, 1000);
            },

            updateNotification(notification) {
                useApi()
                    .legacy.put(route('notifications.update', {notification: notification.id}), {
                        state: notification.state,
                        read_at: notification.read_at,
                    })
                    .then(response => {
                        const notifToUpdate =
                            this.notifications && this.notifications.data
                                ? this.notifications.data.find(item => item.id === notification.id)
                                : null;
                        if (notifToUpdate) {
                            const index = this.notifications.data.indexOf(notifToUpdate);
                            Vue.set(this.notifications.data, index, response.data);
                        }
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            deleteNotification(notification) {
                if (this.notifications.data) {
                    useApi()
                        .legacy.delete(
                            route('notifications.destroy', {
                                notification: notification.id,
                            })
                        )
                        .then(() => {
                            const notificationToDelete = this.notifications.data.find(
                                item => item.id === notification.id
                            );
                            if (notificationToDelete) {
                                const index = this.notifications.data.indexOf(notificationToDelete);
                                this.notifications.data.splice(index, 1);
                                useNotification().success(__('notification:notification_deleted'));
                            }
                        })
                        .catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                }
            },

            updateMessage(notification) {
                useApi()
                    .legacy.put(
                        route('nurseries.messages.update', {
                            nurseries: notification.data.nursery_id,
                            message: notification.data.message_id,
                        }),
                        {
                            state: notification.state,
                        }
                    )
                    .then(() => {
                        const notifToUpdate = this.notifications.data.find(item => item.id === notification.id);
                        if (notifToUpdate) {
                            const index = this.notifications.data.indexOf(notifToUpdate);
                            Vue.set(this.notifications.data, index, notification);
                        }
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            markAsRead() {
                useApi()
                    .legacy.post(route('notifications.markasread'), {
                        types: this.visibleNotificationsTypes,
                    })
                    .then(() => {
                        this.notifications.data.forEach(function (notification) {
                            if (!notification.read_at) {
                                notification.read_at = moment().unix();
                            }
                        });
                        this.$bus.$emit('markAllAsRead');
                    });
            },

            getNursery() {
                if (this.nursery && this.nursery.id) {
                    return this.nursery.id;
                } else {
                    return this.user.nurseries.length ? this.user.nurseries[0].id : null;
                }
            },

            openSettings() {
                this.$router.push({
                    name: 'settings.profile',
                    params: {nursery: this.getNursery()},
                    query: {nav: 'notifications'},
                });
                this.$emit('close');
            },

            switchNurseryInFilter(nurseryId) {
                const index = this.nurseries.indexOf(nurseryId);

                if (index === -1) {
                    this.nurseries.push(nurseryId);
                } else {
                    this.nurseries.splice(index, 1);
                }
            },
        },
    };
</script>

<style scoped>
    .NotificationsWidget__header {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2 tw-p-2;
    }

    .NotificationsWidget__actions {
        @apply tw-flex tw-flex-wrap tw-items-center tw-gap-2;
    }

    .NotificationsWidget__list {
        @apply tw-overflow-y-auto tw-rounded-b-inherit;
    }

    .NotificationsWidget__list-item {
        @apply tw-px-3 tw-py-2 tw-pb-3;
    }

    .NotificationsWidget__empty-list {
        @apply tw-p-4 tw-text-center tw-text-gray-500;
    }
</style>
