import type {SerializerType} from '@/modules/app/composables/useMagicFilter';
import type {QueryBuilderOrderByDirectionType} from '@meekohq/lumos';

export default {
    read: async (value: string) => {
        const [orderBy, queryDirection] = value.split(',');

        return {
            orderBy,
            queryDirection,
        };
    },
    write: (value: {orderBy: string; queryDirection: QueryBuilderOrderByDirectionType}) => {
        return value.orderBy + ',' + value.queryDirection;
    },
} as SerializerType;
