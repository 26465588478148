import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import type {Ref} from 'vue';
import {ref} from 'vue';
import {app} from '@meekohq/lumos';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import {GetAllocationsSuggestionsForPaymentUseCaseBinding} from '@/modules/cashier/payment/application/GetAllocationsSuggestionsForPaymentUseCase';

export function usePaymentSuggestionsState(payment: PaymentModel) {
    const isFetching = ref(false);

    const allocations: Ref<AbstractAllocationAggregate[]> = ref([]);

    async function fetch(suggestionType: 'transaction' | 'credit_note') {
        isFetching.value = true;

        // Get payment suggestions
        const interactor = app(GetAllocationsSuggestionsForPaymentUseCaseBinding);

        if (suggestionType === 'transaction' && payment.isRefund === false) {
            allocations.value = await interactor.fetchCreditTransaction(payment);
        } else if (suggestionType === 'transaction' && payment.isRefund === true) {
            allocations.value = await interactor.fetchDebitTransaction(payment);
        } else {
            allocations.value = await interactor.fetchCreditNote(payment);
        }

        // Order payments suggestions by date
        allocations.value =
            allocations.value?.sort((a: AbstractAllocationAggregate, b: AbstractAllocationAggregate) => {
                return a.allocatableDate?.greaterThan(b.allocatableDate) ? 1 : -1;
            }) ?? [];

        isFetching.value = false;
    }

    return {
        allocations,
        isFetching,
        fetch,
    };
}
