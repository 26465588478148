<template>
    <CForm class="RegisterForm">
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>
                    {{ __('common:first_name') }}
                </CLabel>
                <CInput
                    id="first_name"
                    v-model="first_name"
                    class="RegisterForm__input"
                    :has-error="!!errors.first_name"
                    size="lg"
                />
                <CFormErrorMessage
                    v-if="errors.first_name"
                    class="tw-text-left"
                >
                    <span class="tw-text-[11px]">{{ _head(errors.first_name) }}</span>
                </CFormErrorMessage>
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    {{ __('common:last_name') }}
                </CLabel>
                <CInput
                    id="last_name"
                    v-model="last_name"
                    class="RegisterForm__input"
                    :has-error="!!errors.last_name"
                    size="lg"
                />
                <CFormErrorMessage
                    v-if="errors.last_name"
                    class="tw-text-left"
                >
                    <span class="tw-text-[11px]">{{ _head(errors.last_name) }}</span>
                </CFormErrorMessage>
            </CFormGroup>
        </CFormTwoColumns>
        <CFormGroup>
            <CLabel>
                {{ __('common:email_address') }}
            </CLabel>
            <CInput
                id="email"
                v-model="email"
                class="RegisterForm__input"
                :has-error="!!errors.email"
                size="lg"
            />
            <CFormErrorMessage
                v-if="errors.email"
                class="tw-text-left"
            >
                <span class="tw-text-[11px]">{{ _head(errors.email) }}</span>
            </CFormErrorMessage>
        </CFormGroup>
        <CFormGroup>
            <CLabel>
                {{ __('common:password') }}
            </CLabel>
            <CInput
                id="password"
                v-model="password"
                class="RegisterForm__input"
                :has-error="!!errors.password"
                size="lg"
                type="password"
            />
            <CFormErrorMessage
                v-if="errors.password"
                class="tw-text-left"
            >
                <span class="tw-text-[11px]">{{ _head(errors.password) }}</span>
            </CFormErrorMessage>
            <PasswordStrengthMeter
                class="RegisterForm__password-strength-meter"
                :form-sent="formSent"
                :password="password"
                :valid.sync="strengthPassword"
            />
        </CFormGroup>
        <CFormGroup>
            <CLabel>
                {{ __('auth:registration_code') }}
            </CLabel>
            <CInput
                id="code"
                v-model="code"
                class="RegisterForm__input"
                :has-error="!!errors.code"
                size="lg"
            />
            <CFormErrorMessage
                v-if="errors.code"
                class="tw-text-left"
            >
                <span class="tw-text-[11px]">{{ _head(errors.code) }}s</span>
            </CFormErrorMessage>
        </CFormGroup>
        <CFormGroup>
            <MButton
                class="RegisterForm__button"
                :disabled="isLoading"
                size="lg"
                variant="primary"
                @click="register"
            >
                {{ __('auth:create_my_account') }}
                <FontAwesomeIcon
                    v-if="!isLoading"
                    class="RegisterForm--is-not-loading"
                    icon="fa-regular fa-arrow-alt-circle-right"
                />
                <FontAwesomeIcon
                    v-else
                    class="RegisterForm--is-loading"
                    icon="fa-regular fa-spinner-third"
                    spin
                />
            </MButton>
        </CFormGroup>
        <CFormGroup>
            <p
                class="RegisterForm__condition-link"
                v-html="
                    __('auth:by_registering_you_certify_that_you_have_read_and_accepted_our', {
                        termsOfUseLink: link,
                    })
                "
            />
        </CFormGroup>
    </CForm>
</template>

<script>
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import _head from 'lodash-es/head';
    import PasswordStrengthMeter from '@/modules/coherence-ui/components/PasswordStrengthMeter/PasswordStrengthMeter.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useLocale from '@/modules/app/composables/useLocale.ts';
    import {AuthenticationContractBinding} from '@/modules/auth/utils/AuthenticationContract';
    import {app} from '@meekohq/lumos';

    export default {
        components: {PasswordStrengthMeter},
        data: () => ({
            isLoading: false,
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            code: '',
            errors: [],
            strengthPassword: false,
            formSent: false,
        }),
        computed: {
            link() {
                return `<a  class="tw-ml-1"
                        href="https://www.meeko.pro/conditions-utilisations-solution-saas"
                        target="_blank"
                    >${__('subscription:terms_of_use')}</a>`;
            },
            _head() {
                return _head;
            },
            browserLanguage() {
                return useLocale().currentLanguage;
            },
        },
        methods: {
            register() {
                this.formSent = true;
                this.isLoading = true;

                const data = {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    password: this.password,
                    code: this.code,
                    locale: this.browserLanguage.value,
                };

                useApi()
                    .legacy.post(route('register'), data)
                    .then(() => {
                        this.errors = [];

                        app(AuthenticationContractBinding)
                            .loginByEmail(this.email, this.password)
                            .then(() => {
                                this.isLoading = false;
                                this.$router.push({name: 'dashboard', query: {welcome: 'true'}});
                            });
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            this.errors = error.response.data.errors;
                        }

                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style scoped>
    .RegisterForm__input {
        @apply tw-shadow-sm;
    }

    .RegisterForm__password-strength-meter {
        @apply tw-pt-2 tw-text-left;
    }

    .RegisterForm__button {
        @apply tw-relative tw-w-full;
    }

    .RegisterForm--is-not-loading {
        @apply tw-absolute tw-right-4 tw-top-0.5;
        @apply tw-text-3xl tw-text-primary-700;
    }

    .RegisterForm--is-loading {
        @apply tw-absolute tw-right-4 tw-top-0.5;
        @apply tw-text-3xl tw-text-white;
    }

    .RegisterForm__condition-link {
        @apply tw-text-left tw-text-sm;
    }
</style>
