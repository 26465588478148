import __ from '@/modules/app/utils/i18n-facade';
import {ReportingType} from '@/modules/meeko-family/domain/values/ReportingType';

export function useReportingSettingsWording(type: ReportingType) {
    const wordings: {
        [key in ReportingType]: {
            title: string;
            text: string;
            confirmationTitle: string;
            confirmationText: string;
        };
    } = {
        [ReportingType.presences]: {
            title: __('common:clocking_in_other'),
            text: __('reporting:reporting_text_activities'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_presences'),
        },
        [ReportingType.meals]: {
            title: __('common:meal'),
            text: __('reporting:reporting_text_meals'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_meals'),
        },
        [ReportingType.activities]: {
            title: __('common:activity_other'),
            text: __('reporting:reporting_text_activities'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_activities'),
        },
        [ReportingType.naps]: {
            title: __('common:naps'),
            text: __('reporting:reporting_text_naps'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_naps'),
        },
        [ReportingType.diapers]: {
            title: __('news:diapers'),
            text: __('reporting:reporting_text_diapers'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_diapers'),
        },
        [ReportingType.photos]: {
            title: __('common:photos'),
            text: __('reporting:reporting_text_photos'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_photos'),
        },
        [ReportingType.temperatures]: {
            title: __('common:temperatures'),
            text: __('reporting:reporting_text_temperatures'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_temperatures'),
        },
        [ReportingType.weights]: {
            title: __('common:weight'),
            text: __('reporting:reporting_text_weights'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_weights'),
        },
        [ReportingType.drugs]: {
            title: __('common:drugs'),
            text: __('reporting:reporting_text_drugs'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_drugs'),
        },
        [ReportingType.observations]: {
            title: __('common:observation_other'),
            text: __('reporting:reporting_text_observations'),
            confirmationTitle: __('common:confirm_your_choice'),
            confirmationText: __('reporting:reporting_confirmation_modal_text_observations'),
        },
    };

    return {
        wording: wordings[type],
    };
}
