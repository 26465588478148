import type {Ref} from 'vue';
import {computed} from 'vue';
import type CallModel from '@/modules/activity/models/CallModel';
import CallPivot from '@/modules/activity/models/CallPivot';

export default function useAttachCall(call: Ref<CallModel>, resourceId: string, resourceType: string) {
    const pivot = computed(() =>
        call.value
            .callPivots()
            .value()
            .where('attributes.resource_id', resourceId)
            .where('attributes.resource_type', resourceType)
            .first()
    );

    // Attach resource to call
    async function attach() {
        if (pivot.value) {
            return;
        }

        const newPivot = new CallPivot();
        newPivot.attributes.call_id = call.value.id;
        newPivot.attributes.account_id = call.value.attributes.account_id;
        newPivot.attributes.resource_id = resourceId;
        newPivot.attributes.resource_type = resourceType;

        await newPivot.save();

        call.value.callPivots().value().push(newPivot);
    }

    // Detach resource from call
    async function detach() {
        if (!pivot.value) {
            return;
        }

        await pivot.value.delete();
    }

    return {attach, detach};
}
