<template>
    <div class="DashboardLoader">
        <div class="DashboardLoader__spinner">
            <RingLoaderIcon />
        </div>
        <h1 class="DashboardLoader__heading">
            {{ __('app:account_loading_dots') }}
        </h1>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import RingLoaderIcon from '@/modules/app/components/atoms/RingLoaderIcon.vue';

    export default defineComponent({
        components: {RingLoaderIcon},
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style scoped>
    .DashboardLoader {
        @apply tw-flex tw-flex-col tw-p-4;
    }

    .DashboardLoader__spinner {
        @apply tw-flex tw-justify-center tw-py-6;

        svg {
            @apply tw-h-12 tw-w-12 tw-stroke-gray-600/40;
        }
    }

    .DashboardLoader__heading {
        @apply tw-text-center tw-font-grandhotel tw-text-5xl tw-antialiased;
        @apply tw-text-gray-600;
    }
</style>
