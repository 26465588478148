import ManagerStore from '@/modules/legacy/store/manager.store';
import moment from 'moment';

export default function () {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const openingHours = ManagerStore.legacyNursery.openingHours;
    const currentDay = moment();

    if (!openingHours.length) {
        return currentDay.startOf('week');
    }

    let foundMatch = false;
    let daysChecked = 0;
    do {
        const match = openingHours.find(openingHour => openingHour.day === days[currentDay.day()]);
        if (match) {
            foundMatch = true;
        } else {
            currentDay.subtract(1, 'day');
            daysChecked++;
        }
        if (daysChecked >= 7) {
            break; // Ensure we don't loop indefinitely
        }
    } while (!foundMatch);

    return currentDay;
}
