<template>
    <div class="segment-container">
        <!-- PRESENCE -->
        <MTooltip
            v-if="
                event.event_type === 'presence-recurrent' ||
                event.event_type === 'presence-occasional' ||
                event.event_type === 'presence-adaptation'
            "
            :key="'presence-' + event.id"
            class="planning-segment animate presence z-index-2"
            :class="['id-p-' + event.id, presenceClass]"
            dont-interact-with-panel
            :style="style"
            @mouseenter="$('.id-p-' + event.id).addClass('hover')"
            @mouseleave="$('.id-p-' + event.id).removeClass('hover')"
        >
            <div
                class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-xl"
                @click="callback($event)"
            >
                <div
                    v-if="showNote"
                    class="inner-text note-text"
                >
                    <div class="row tw-mx-0">
                        <div
                            v-if="event.drop_note"
                            class="col tw-truncate tw-text-left"
                        >
                            {{ event.drop_note }}
                        </div>
                        <div
                            v-if="event.pick_up_note"
                            class="col tw-truncate tw-text-right"
                        >
                            {{ event.pick_up_note }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="innerText"
                    class="inner-text !tw-text-white"
                >
                    {{ titleHours(startedAt, endedAt) }}
                </div>
            </div>
            <template #content>
                <div class="tw-whitespace-pre-line">
                    {{ __('planning:presence_colon_and_value', {value: titlePresence(startedAt, endedAt, event)}) }}
                </div>
            </template>
        </MTooltip>

        <!-- OVERRUN -->
        <MTooltip
            v-else-if="event.event_type === 'overrun'"
            :key="'overrun-' + event.id"
            class="planning-segment animate progress-bar-striped depassement z-index-3 tw-bg-blue-500/65"
            :class="'id-d-' + event.id"
            dont-interact-with-panel
            :style="style"
            @mouseenter="$('.id-d-' + event.id).addClass('hover')"
            @mouseleave="$('.id-d-' + event.id).removeClass('hover')"
        >
            <div
                class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-xl"
                @click="callback($event)"
            >
                <div
                    v-if="innerText"
                    class="inner-text tw-text-white"
                >
                    {{ titleHours(startedAt, endedAt) }}
                </div>
            </div>
            <template #content>
                <div class="tw-whitespace-pre-line">
                    {{ __('planning:overrun_value', {value: titleHours(event.started_at, event.ended_at)}) }}
                </div>
            </template>
        </MTooltip>

        <!-- PLANNING -->
        <MTooltip
            v-else-if="event.event_type === 'planning-recurrent'"
            :key="'planning-recurrent-' + event.id"
            class="planning-segment animate progress-bar-striped planning z-index-1 tw-bg-green-500/65 hover:tw-ring-3 hover:tw-ring-green-700"
            dont-interact-with-panel
            :style="style"
        >
            <div
                class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-xl"
                @click="callback($event)"
            >
                <div
                    v-if="showNote && event.note"
                    class="inner-text note-text"
                >
                    <div class="row tw-mx-0">
                        <div
                            v-if="event.note"
                            class="col-12 tw-truncate tw-text-center tw-text-sm !tw-text-black"
                        >
                            {{ innerText ? titleHours(startedAt, endedAt) + ' - ' + event.note : event.note }}
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="innerText"
                    class="inner-text !tw-text-black"
                >
                    {{ titleHours(startedAt, endedAt) }}
                </div>
            </div>
            <template #content>
                <div class="tw-whitespace-pre-line">
                    {{
                        __('planning:planning', {
                            context: event.extra && event.extra.onlyHours ? 'hour' : 'day',
                            start: fromTimestamp(event.started_at).toLocaleString({
                                day: 'numeric',
                                month: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                            end: fromTimestamp(event.ended_at).toLocaleString({
                                day: 'numeric',
                                month: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                        })
                    }}
                </div>
            </template>
        </MTooltip>

        <!-- OCCASIONNAL -->
        <MTooltip
            v-else-if="event.event_type === 'planning-occasional'"
            :key="'planning-occasional-' + event.id"
            class="planning-segment animate progress-bar-striped planning z-index-1 tw-bg-blue-500/65 hover:tw-ring-3 hover:tw-ring-blue-700"
            dont-interact-with-panel
            :style="style"
        >
            <div
                class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-xl"
                @click="callback($event)"
            >
                <div
                    v-if="showNote && event.note"
                    class="inner-text note-text"
                >
                    <div class="row tw-mx-0">
                        <div
                            v-if="event.note"
                            class="col-12 tw-truncate tw-text-center tw-text-sm"
                        >
                            {{ innerText ? titleHours(startedAt, endedAt) + ' - ' + event.note : event.note }}
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="innerText"
                    class="inner-text !tw-text-black"
                >
                    {{ titleHours(startedAt, endedAt) }}
                </div>
            </div>
            <template #content>
                <div class="tw-whitespace-pre-line">
                    {{
                        __('planning:occasional', {
                            context: event.extra && event.extra.onlyHours ? 'hour' : 'day',
                            start: fromTimestamp(event.started_at).toLocaleString({
                                day: 'numeric',
                                month: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                            end: fromTimestamp(event.ended_at).toLocaleString({
                                day: 'numeric',
                                month: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                        })
                    }}
                </div>
            </template>
        </MTooltip>

        <!-- ADAPTATION -->
        <MTooltip
            v-else-if="event.event_type === 'planning-adaptation'"
            :key="'planning-adaptation-' + event.id"
            class="planning-segment animate progress-bar-striped planning z-index-1 tw-bg-yellow-600/65 hover:tw-ring-3 hover:tw-ring-yellow-700"
            dont-interact-with-panel
            :style="style"
        >
            <div
                class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-xl"
                @click="callback($event)"
            >
                <div
                    v-if="showNote && event.note"
                    class="inner-text note-text"
                >
                    <div class="row tw-mx-0">
                        <div
                            v-if="event.note"
                            class="col-12 tw-truncate tw-text-center tw-text-sm tw-text-black"
                        >
                            {{ innerText ? titleHours(startedAt, endedAt) + ' - ' + event.note : event.note }}
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="innerText"
                    class="inner-text !tw-text-black"
                >
                    {{ titleHours(startedAt, endedAt) }}
                </div>
            </div>
            <template #content>
                <div class="tw-whitespace-pre-line">
                    {{
                        __('planning:adaptation', {
                            context: event.extra && event.extra.onlyHours ? 'hour' : 'day',
                            start: fromTimestamp(event.started_at).toLocaleString({
                                day: 'numeric',
                                month: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                            end: fromTimestamp(event.ended_at).toLocaleString({
                                day: 'numeric',
                                month: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                        })
                    }}
                </div>
            </template>
        </MTooltip>

        <!-- ABSENCE -->
        <MTooltip
            v-else-if="event.type === 'absence'"
            :key="'absence-' + event.id"
            class="planning-segment animate absence z-index-3 tw-bg-red-500 hover:tw-ring-3 hover:tw-ring-red-700"
            dont-interact-with-panel
            :style="style"
        >
            <div
                class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-xl"
                @click="callback($event)"
            >
                <div
                    v-if="showNote && event.note"
                    class="inner-text note-text"
                >
                    <div class="row tw-mx-0">
                        <div
                            v-if="event.note"
                            class="col tw-truncate tw-text-left"
                        >
                            {{ event.note }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="innerText"
                    class="inner-text tw-text-white"
                >
                    {{ titleHours(startedAt, endedAt) }}
                </div>
            </div>
            <template #content>
                <div class="tw-whitespace-pre-line">
                    {{
                        __('planning:absence_day', {
                            start: fromTimestamp(event.started_at).toLocaleString({
                                day: 'numeric',
                                month: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                            end: fromTimestamp(event.ended_at).toLocaleString({
                                day: 'numeric',
                                month: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                        })
                    }}
                </div>
            </template>
        </MTooltip>
    </div>
</template>

<script>
    import moment from 'moment';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import __ from '@/modules/app/utils/i18n-facade';
    import {Epoch} from '@meekohq/lumos';
    import useEpoch from '@/modules/app/composables/useEpoch';

    export default {
        props: {
            user: {},
            nursery: {},
            day: {},
            opening: {},
            closing: {},
            event: {},
            showMenu: {
                type: Boolean,
                default: false,
            },
            innerText: {
                type: Boolean,
                default: false,
            },
            showNote: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            date: moment(),
        }),
        computed: {
            moment() {
                return moment();
            },
            $() {
                return $;
            },
            startedAt() {
                if (this.event.limited_started_at) {
                    return this.event.limited_started_at;
                }

                return this.event.started_at;
            },
            endedAt() {
                if (this.event.limited_ended_at) {
                    return this.event.limited_ended_at;
                }

                return this.event.ended_at;
            },
            style() {
                let startedAt = this.event.started_at;
                let endedAt = this.event.ended_at;
                if (this.event.limited_started_at) {
                    startedAt = this.event.limited_started_at;
                }
                if (this.event.limited_ended_at) {
                    endedAt = this.event.limited_ended_at;
                }

                return {
                    left: this.unixToPercent(startedAt) + '%',
                    right: 100 - this.unixToPercent(endedAt) + '%',
                };
            },
            presenceClass() {
                switch (this.event.event_type) {
                    case 'presence-occasional':
                        return 'tw-bg-blue-500 hover:tw-ring-3 hover:tw-ring-blue-700';
                    case 'presence-adaptation':
                        return 'tw-bg-yellow-600 hover:tw-ring-3 hover:tw-ring-yellow-700';
                    default:
                        return 'tw-bg-green-500 hover:tw-ring-3 hover:tw-ring-green-700';
                }
            },
        },
        methods: {
            fromTimestamp: useEpoch().fromTimestamp,
            unixToPercent(unix) {
                const openingTimestamp = this.day
                    .clone()
                    .hours(moment.duration(this.opening).hours())
                    .minutes(moment.duration(this.opening).minutes())
                    .seconds(moment.duration(this.opening).seconds())
                    .unix();

                const closingTimestamp = this.day
                    .clone()
                    .hours(moment.duration(this.closing).hours())
                    .minutes(moment.duration(this.closing).minutes())
                    .seconds(moment.duration(this.closing).seconds())
                    .unix();

                const percent = ((unix - openingTimestamp) * 100) / (closingTimestamp - openingTimestamp);

                if (percent > 100) {
                    return 100;
                } else if (percent < 0) {
                    return 0;
                }

                return percent;
            },
            unixToPercentRelative(unix, openingTimestamp, closingTimestamp) {
                const percent = ((unix - openingTimestamp) * 100) / (closingTimestamp - openingTimestamp);

                if (percent > 100) {
                    return 100;
                } else if (percent < 0) {
                    return 0;
                }

                return percent;
            },
            titlePresence(startedAt, endedAt, event) {
                let text = '';
                startedAt = moment.unix(startedAt);
                endedAt = moment.unix(endedAt);
                text += startedAt.format('HH:mm') + ' à ' + endedAt.format('HH:mm');

                if (event.drop_note) {
                    text += '\n' + `${__('planning:arrival_note_colon')} ${event.drop_note}`;
                }

                if (event.pick_up_note) {
                    text += '\n' + `${__('planning:departure_note_colon')} ${event.pick_up_note}`;
                }

                return text;
            },
            titleHours(startedAt, endedAt) {
                startedAt = Epoch.fromTimestamp(startedAt);
                endedAt = Epoch.fromTimestamp(endedAt);

                return (
                    startedAt.toLocaleString(Epoch.presets.TIME_24_SIMPLE) +
                    ' - ' +
                    endedAt.toLocaleString(Epoch.presets.TIME_24_SIMPLE)
                );
            },
            callback(event) {
                if (!this.showMenu) {
                    return;
                }

                const newEvent = _cloneDeep(this.event);
                if (newEvent.type === 'overrun') {
                    newEvent.type = 'presence';
                    newEvent.started_at =
                        newEvent.started_at < newEvent.droped_at ? newEvent.started_at : newEvent.droped_at;
                    newEvent.ended_at =
                        newEvent.ended_at > newEvent.picked_up_at ? newEvent.ended_at : newEvent.picked_up_at;
                }

                if (this.showMenu) {
                    const target = $(event.target);
                    const contextMenu = $('#contextMenu');

                    target.addClass('z-index-1001');
                    contextMenu.css('left', this.style.left);

                    contextMenu
                        .appendTo(event.currentTarget.parentElement.parentElement)
                        .promise()
                        .done(() => {
                            this.$bus.$emit('clicked:event', newEvent);
                        });
                } else {
                    this.$bus.$emit('clicked:event', newEvent);
                }
            },
            getOtherNurseryName() {
                let text = '';
                if (this.user) {
                    const nursery = this.user.nurseries.find(event => event.id === this.event.nursery_id);
                    if (nursery) {
                        text = nursery.city ? nursery.name + ' - ' + nursery.city : nursery.name;
                    }
                }

                return text;
            },
        },
    };
</script>

<style scoped>
    .planning-segment {
        @apply tw-flex tw-cursor-pointer tw-items-center tw-justify-center;
        position: absolute;
        border-radius: 1rem;
        height: 100%;

        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/

        .inner-text {
            overflow: hidden;
            color: white;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: -0.5px;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.note-text {
                letter-spacing: 0;
            }
        }

        &.z-index-1 {
            z-index: 1;
        }

        &.z-index-2 {
            z-index: 2;
        }

        &.z-index-3 {
            z-index: 3;
        }

        &.z-index-1001 {
            z-index: 1001 !important;
        }

        &.no-pointer-events {
            pointer-events: none;
        }

        &.planning {
            &.bg-other-nursery {
                background: rgba(160, 237, 255, 0.7);
            }
        }

        &.presence {
            &.bg-other-nursery {
                background: #3edbff;
            }
        }

        &:hover,
        &.hover {
            opacity: 1;
            z-index: 10;

            &.bg-other-nursery {
                box-shadow: 0 0 0 3px darken(#a0edff, 25%);
            }
        }
    }

    @media print {
        .planning-segment {
            .inner-text {
                padding-top: 5px;
                font-weight: 700;
                font-size: 10px;
                letter-spacing: -0.8px;
                text-overflow: clip;
            }
        }
    }
</style>
