<template>
    <AbstractItem>
        <template #icon>
            <font-awesome-icon
                class="tw-text-yellow-600"
                fixed-width
                icon="fa-duotone fa-memo-pad"
                size="lg"
            />
        </template>
        <template #title>
            <div
                class="tw-flex-1 sm:tw-rounded-lg sm:tw-p-2"
                :class="{
                    'tw-cursor-pointer sm:tw-transition-colors sm:tw-ease-in-out sm:hover:tw-bg-gray-50': !isEditing,
                }"
                @click="isEditing ? undefined : setIsEditing(true)"
            >
                <CText
                    class="tw-whitespace-pre-line tw-break-all"
                    font-size="lg"
                >
                    <template v-if="note.exists">
                        {{ note.attributes.description }}
                    </template>
                    <template v-else>
                        {{ __('common:note_one') }}
                    </template>
                </CText>
            </div>
        </template>
        <template #date>
            <CVStack class="tw-whitespace-nowrap">
                <CText
                    v-if="createdAtFormatted"
                    variant="disabled"
                >
                    {{ createdAtFormatted }}
                </CText>
                <CText
                    v-if="reporter"
                    font-size="sm"
                    variant="disabled"
                >
                    {{ __('common:by') }} {{ $capitalize(reporter.fullname) }}
                </CText>
            </CVStack>
        </template>
        <template #actions>
            <MMenu v-if="can('update', 'registrations')">
                <MMenuButton>
                    <MButton icon-ellipsis />
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        :disabled="isEditing"
                        :label="__('common:actions.update')"
                        @click="setIsEditing(true)"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-pen"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        :label="__('common:actions.delete')"
                        variant="danger"
                        @click="remove"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-trash"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
        </template>
        <template
            v-if="isEditing"
            #content
        >
            <NoteForm
                :disable-save-button="saving"
                :note="editedNote"
                :registration="registration"
                @saved="save"
                @undo="cancel"
            />
        </template>
    </AbstractItem>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, toRef} from 'vue';
    import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';
    import useAttachTicket from '@/modules/activity/composables/useAttachTicket';
    import NoteForm from '@/modules/activity/components/NoteForm.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import AbstractItem from '@/modules/registration/components/molecules/Timeline/AbstractItem.vue';
    import {useToggle} from '@vueuse/core';
    import __ from '@/modules/app/utils/i18n-facade';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';

    export default defineComponent({
        components: {NoteForm, AbstractItem},
        props: {
            note: {
                type: Object as PropType<TicketModel>,
                required: true,
            },
            registration: {
                type: RegistrationModel,
                required: true,
            },
        },
        setup(props, {emit}) {
            const [isEditing, setIsEditing] = useToggle(!props.note.exists);
            const reporter = computed(() => props.note.reporter().value());
            // Edition functions
            const editedNote = ref(props.note.clone());
            const {attach} = useAttachTicket(
                toRef(props, 'note'),
                props.registration.getKey().toString(),
                'registration/registrations'
            );
            const saving = ref(false);

            const {can} = useAbility();

            const {fromISOString, presets} = useEpoch();

            const createdAtFormatted = computed(() => {
                return props.note.computed.created_at
                    ? fromISOString(props.note.computed.created_at).toLocaleString(presets.DATETIME_SHORT)
                    : null;
            });

            // Update ticket with saved data and create pivot if not exists
            async function save(note: TicketModel) {
                saving.value = true;
                await note.copyTo(props.note);
                await attach();

                setIsEditing(false);
                emit('saved', props.note);
                saving.value = false;
            }

            async function remove() {
                await useMagicModal().deleteConfirmationModal({
                    title: __('registration:delete_note'),
                    text: __('registration:delete_note_ask'),
                    onConfirm: async () => {
                        if (props.note.exists) {
                            await props.note.delete();
                        }

                        emit('deleted', props.note);
                    },
                });
            }

            function cancel() {
                editedNote.value.rollback();
                setIsEditing(false);
                if (!props.note.exists) {
                    emit('deleted', props.note);
                }
            }

            return {
                saving,
                editedNote,
                reporter,
                remove,
                save,
                cancel,
                can,
                isEditing,
                setIsEditing,
                createdAtFormatted,
            };
        },
    });
</script>
