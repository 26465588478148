<template>
    <div class="tw-contents print:tw-hidden">
        <manage-planning
            v-if="$can('create', 'kids_planning')"
            :date="date"
            :nursery="nursery"
        />
        <MTooltip
            v-if="print"
            :label="__('planning:print_planning')"
            placement="top-end"
        >
            <MButton
                variant="gray"
                @click="printPage"
            >
                <FontAwesomeIcon icon="fa-solid fa-print" />
            </MButton>
        </MTooltip>
        <MSettingsButton
            :to="{name: 'settings.plannings', params: {nursery: nursery.id}}"
            :tooltip="__('planning:planning_settings')"
        />
    </div>
</template>

<script>
    import ManagePlanning from './ManagePlanning.vue';
    import usePrint from '@/modules/app/composables/usePrint';

    export default {
        components: {
            'manage-planning': ManagePlanning,
        },
        props: {
            nursery: {},
            date: {},
            currentDisplay: {},
            print: {
                type: Boolean,
                default: true,
            },
        },
        methods: {
            printPage() {
                usePrint().print();
            },
        },
    };
</script>
