import _sum from 'lodash-es/sum';
import __ from '@/modules/app/utils/i18n-facade';

// Total to display in chart
export function getData(summary, billingDisplay) {
    const total = getTotal(summary, billingDisplay);

    return {
        categories: [__('common:forecasted_male_one'), __('common:real_one')],
        colors: ['#49c300', '#007bff', '#ffc107', '#dc3545', '#007bff'],
        series: [
            {
                name: __('common:recurrent'),
                data: [total.recurrent, total.recurrent_presence],
            },
            {
                name: __('common:occasional'),
                data: [total.occasional, total.occasional_presence],
            },
            {
                name: __('common:adaptation'),
                data: [total.adaptation, total.adaptation_presence],
            },
            {
                name: __('common:absence'),
                data: [total.absence, total.absence],
            },
            {
                name: __('common:overrun_other'),
                data: [0, total.overrun * 60],
            },
        ],
    };
}

// Total for each type from summary
export function getTotal(summary, billingDisplay) {
    const total = {
        recurrent: 0,
        occasional: 0,
        adaptation: 0,
        recurrent_presence: 0,
        occasional_presence: 0,
        adaptation_presence: 0,
        absence: 0,
        overrun: 0,
    };

    if (billingDisplay === 'halfDay' || billingDisplay === 'halfday') {
        summary.forEach(day => {
            day.am.recurrent ? total.recurrent++ : null;
            day.am.occasional ? total.occasional++ : null;
            day.am.adaptation ? total.adaptation++ : null;
            day.am.recurrent_presence ? total.recurrent_presence++ : null;
            day.am.occasional_presence ? total.occasional_presence++ : null;
            day.am.adaptation_presence ? total.adaptation_presence++ : null;
            day.am.absence ? total.absence++ : null;

            day.pm.recurrent ? total.recurrent++ : null;
            day.pm.occasional ? total.occasional++ : null;
            day.pm.adaptation ? total.adaptation++ : null;
            day.pm.recurrent_presence ? total.recurrent_presence++ : null;
            day.pm.occasional_presence ? total.occasional_presence++ : null;
            day.pm.adaptation_presence ? total.adaptation_presence++ : null;
            day.pm.absence ? total.absence++ : null;
        });
    } else if (billingDisplay === 'hour') {
        summary.forEach(day => {
            total.recurrent += day.am.recurrent;
            total.recurrent += day.pm.recurrent;

            total.occasional += day.am.occasional;
            total.occasional += day.pm.occasional;

            total.adaptation += day.am.adaptation;
            total.adaptation += day.pm.adaptation;

            total.recurrent_presence += day.am.recurrent_presence;
            total.recurrent_presence += day.pm.recurrent_presence;

            total.occasional_presence += day.am.occasional_presence;
            total.occasional_presence += day.pm.occasional_presence;

            total.adaptation_presence += day.am.adaptation_presence;
            total.adaptation_presence += day.pm.adaptation_presence;

            total.absence += day.am.absence;
            total.absence += day.pm.absence;

            total.overrun += day.arrival_overruns / 60 + day.departure_overruns / 60;
        });
    } else {
        summary.forEach(day => {
            day.am.recurrent || day.pm.recurrent ? total.recurrent++ : null;
            day.am.occasional || day.pm.occasional ? total.occasional++ : null;
            day.am.adaptation || day.pm.adaptation ? total.adaptation++ : null;
            day.am.recurrent_presence || day.pm.recurrent_presence ? total.recurrent_presence++ : null;
            day.am.occasional_presence || day.pm.occasional_presence ? total.occasional_presence++ : null;
            day.am.adaptation_presence || day.pm.adaptation_presence ? total.adaptation_presence++ : null;
            day.am.absence || day.pm.absence ? total.absence++ : null;
        });
    }

    return total;
}

// Global total from summary
export function getTotalSummary(summary, billingDisplay, withText = true) {
    const total = getTotal(summary, billingDisplay);

    let total_arrival: any = 0;
    let total_departure: any = 0;

    summary.forEach(day => {
        total_arrival += _sum(day.arrival_overruns);
        total_departure += _sum(day.departure_overruns);
    });

    let total_planning: string | number = total.recurrent + total.occasional + total.adaptation + total.absence;
    let total_presences: string | number =
        total.recurrent_presence + total.occasional_presence + total.adaptation_presence + total.absence;

    if (withText) {
        if (billingDisplay === 'day') {
            total_planning = __('common:day_with_count', {count: total_planning});
            total_presences = __('common:day_with_count', {count: total_presences});
        } else if (billingDisplay === 'halfDay' || billingDisplay === 'halfday') {
            total_planning = __('common:halfday_alternative_long', {count: total_planning});
            total_presences = __('common:halfday_alternative_long', {count: total_presences});
        } else {
            total_planning = getTimeFromMins(total_planning);
            total_presences = getTimeFromMins(total_presences);
        }
        total_arrival = getTimeFromMins(total_arrival);
        total_departure = getTimeFromMins(total_departure);
    }

    return {
        planning: total_planning,
        presences: total_presences,
        arrival_overruns: total_arrival,
        departure_overruns: total_departure,
    };
}

// Parse to hours and minutes
export function getTimeFromMins(minutes) {
    const m = minutes % 60;
    const h = (minutes - m) / 60;
    if (h === 0) {
        return m + 'min';
    }

    return m > 0 ? h + 'h' + (m < 10 ? '0' : '') + m : h + 'h';
}
