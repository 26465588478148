import type {ValidationError} from '@meekohq/lumos';
import {app} from '@meekohq/lumos';
import {ref} from 'vue';
import Bugsnag from '@bugsnag/js';
import {SendRealTimeDeactivationFeedbackInputPortBinding} from '@/modules/meeko-family/domain/use-cases/SendRealTimeDeactivationFeedbackInputPort';

export function useReportingSettingNotification() {
    const isSending = ref(false);

    async function sendRealTimeDeactivationFeedback(feedback: string) {
        isSending.value = true;

        const interactor = app(SendRealTimeDeactivationFeedbackInputPortBinding, {
            success() {
                // Do nothing
            },
            validationError(error: ValidationError) {
                Bugsnag.notify(error);
            },
            unexpectedError(error: Error) {
                Bugsnag.notify(error);
            },
        });

        await interactor.execute(feedback);

        isSending.value = false;
    }

    return {
        isSending,
        sendRealTimeDeactivationFeedback,
    };
}
