import type UserModel from '@/modules/user/models/UserModel';
import {Binding} from '@meekohq/lumos';

export interface CredentialsType {
    token: string;
    legacy_token: string;
}
export interface UserObjectType {
    userModel: UserModel;
    legacyUser: any;
}

export const AuthenticationContractBinding = new Binding<AuthenticationContract>();

export interface AuthenticationContract {
    defineBeforeLoginCallback(callback: (credentials: CredentialsType) => Promise<boolean>): AuthenticationContract;

    defineAfterLoginCallback(
        callback: (user: UserObjectType, credentials: CredentialsType) => Promise<boolean>
    ): AuthenticationContract;

    defineAfterLogoutCallback(callback: () => Promise<void>): AuthenticationContract;

    hasCredentials(): boolean;

    getCredentials(): CredentialsType | null;

    storeCredentials(token: string, legacyToken: string): void;

    deleteCredentials(): void;

    loginByEmail(email: string, password: string): Promise<UserModel | undefined>;

    loginByCredentials(): Promise<UserModel | undefined>;

    logout(): Promise<void>;

    supportLoginByEmail(email: string, token: string): Promise<UserModel | undefined>;
}
