<template>
    <MModal
        :header-title="__('registration:add_registration')"
        :modal="modal"
        size="6xl"
    >
        <MStepPanel
            ref="stepper"
            @has-next-step="hasNextStep = $event"
            @has-previous-step="hasPreviousStep = $event"
        >
            <MStepPanelHeader>
                <MStepPanelHeaderItem
                    :is-completed="isStepOneCompleted"
                    :step="1"
                    :title="__('common:the_kid')"
                >
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-child-reaching" />
                    </template>
                </MStepPanelHeaderItem>
                <MStepPanelHeaderItem
                    :is-completed="isStepTwoCompleted"
                    :step="2"
                    :title="__('common:the_contract_one')"
                >
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-file" />
                    </template>
                </MStepPanelHeaderItem>
                <MStepPanelHeaderItem
                    :is-completed="isStepThreeCompleted"
                    :step="3"
                    :title="__('common:the_parents')"
                >
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-users" />
                    </template>
                </MStepPanelHeaderItem>
            </MStepPanelHeader>
            <MStepPanelContent v-slot="{activeStep}">
                <CForm v-if="activeStep === 1">
                    <CFormThreeColumns>
                        <CFormGroup>
                            <CLabel>{{ __('common:first_name') }}</CLabel>
                            <MInput
                                id="kid_first_name"
                                v-model="registration.kid_first_name"
                                :placeholder="__('common:first_name')"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('common:last_name') }}*</CLabel>
                            <MInput
                                id="kid_last_name"
                                v-model="registration.kid_last_name"
                                :placeholder="__('common:last_name')"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>
                                <template #icon>
                                    <FontAwesomeIcon icon="fa-solid fa-venus-mars" />
                                </template>
                                {{ __('common:gender_one') }}
                            </CLabel>
                            <CFormSelect
                                v-model="registration.kid_gender"
                                :label="__('common:gender_one')"
                                :options="[
                                    {value: 'male', text: __('common:gender.boy')},
                                    {value: 'female', text: __('common:gender.girl')},
                                    {value: '', text: __('common:gender.unknow')},
                                ]"
                                :search-bar="false"
                                text-path="text"
                                value-path="value"
                            />
                        </CFormGroup>
                    </CFormThreeColumns>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>
                                <template #icon>
                                    <FontAwesomeIcon icon="fa-solid fa-birthday-cake" />
                                </template>
                                {{ __('common:date_of_birth_or_estimate') }}*
                            </CLabel>
                            <MDatePicker
                                v-model="registration.kid_birthdate"
                                class="tw-w-full"
                                format="yyyy-MM-dd"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>
                                <template #icon>
                                    <FontAwesomeIcon icon="fa-solid fa-map-marker" />
                                </template>
                                {{ __('common:birth_place') }}
                            </CLabel>
                            <MInput
                                id="kid_birthplace"
                                v-model="registration.kid_birthplace"
                                :placeholder="__('common:birth_place')"
                            />
                        </CFormGroup>
                    </CFormTwoColumns>
                </CForm>

                <div v-else-if="activeStep === 2">
                    <div class="form-group">
                        <div class="form-row">
                            <div class="col-12 col-lg-4">
                                <label class="label">{{ __('common:start_contract') }}*</label>
                                <MDatePicker
                                    v-model="registration.contract_started_at"
                                    class="tw-w-full"
                                    format="yyyy-MM-dd"
                                />
                            </div>
                            <div class="col-12 col-lg-4">
                                <label class="label">{{ __('common:end_contract') }}*</label>
                                <MDatePicker
                                    v-model="registration.contract_ended_at"
                                    class="tw-w-full"
                                    format="yyyy-MM-dd"
                                />
                            </div>
                            <div class="col-12 col-lg-4">
                                <label class="label"
                                    >{{ __('registration:flexible_planning') }}
                                    <MHelpTooltip>
                                        {{ __('registration:can_the_planning_be_adjusted') }}
                                    </MHelpTooltip>
                                </label>
                                <select
                                    v-model="registration.negotiable"
                                    class="form-control form-control-sm"
                                    :title="__('registration:flexible_planning')"
                                >
                                    <option :value="1">
                                        {{ __('common:yes') }}
                                    </option>
                                    <option :value="0">
                                        {{ __('common:no') }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mt-4">
                        <h3 class="h3 tw-text-center tw-font-grandhotel">
                            {{ __('registration:desired_planning') }}
                        </h3>
                        <div class="tw-mt-2">
                            <div class="small tw-text-center">
                                {{ __('registration:to_modify_a_range_click_on_it') }}
                            </div>
                            <module-planning
                                v-if="registration"
                                :nursery="nursery"
                                :registration="registration"
                            />
                        </div>
                    </div>
                </div>

                <div v-else-if="activeStep === 3">
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-gap-y-8 lg:tw-grid-cols-2">
                        <div class="tw-flex tw-flex-col tw-gap-4">
                            <MHeading level="h3">
                                {{ __('common:first_parent') }}
                            </MHeading>
                            <select
                                v-model="registration.first_parent_role"
                                class="form-control"
                                :title="__('common:role')"
                            >
                                <option value="mother">
                                    {{ __('common:relationship.parent_female') }}
                                </option>
                                <option value="father">
                                    {{ __('common:relationship.parent_male') }}
                                </option>
                                <option value="brother">
                                    {{ __('common:relationship.sibling_male') }}
                                </option>
                                <option value="sister">
                                    {{ __('common:relationship.sibling_female') }}
                                </option>
                                <option value="uncle">
                                    {{ __('common:relationship.pibling_male') }}
                                </option>
                                <option value="aunt">
                                    {{ __('common:relationship.pibling_female') }}
                                </option>
                                <option value="stepmother">
                                    {{ __('common:relationship.parent_in_law_female') }}
                                </option>
                                <option value="stepfather">
                                    {{ __('common:relationship.parent_in_law_male') }}
                                </option>
                                <option value="grandparent">
                                    {{ __('common:relationship.grandparent') }}
                                </option>
                                <option value="legal_custodian_male">
                                    {{ __('common:relationship.legal_custodian_male') }}
                                </option>
                                <option value="legal_custodian_female">
                                    {{ __('common:relationship.legal_custodian_female') }}
                                </option>
                                <option value="other">
                                    {{ __('common:other_one') }}
                                </option>
                            </select>
                            <div class="tw-flex tw-gap-4">
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="first_parent_first_name"> {{ __('common:first_name') }}* </CLabel>
                                    <input
                                        id="first_parent_first_name"
                                        v-model="registration.first_parent_first_name"
                                        class="form-control"
                                        :placeholder="__('common:first_name')"
                                    />
                                </CFormGroup>
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="first_parent_last_name"> {{ __('common:last_name') }}* </CLabel>
                                    <input
                                        id="first_parent_last_name"
                                        v-model="registration.first_parent_last_name"
                                        class="form-control"
                                        :placeholder="__('common:last_name')"
                                    />
                                </CFormGroup>
                            </div>
                            <div class="tw-flex tw-gap-4">
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="first_parent_email"> {{ __('common:email') }}* </CLabel>
                                    <input
                                        id="first_parent_email"
                                        v-model="registration.first_parent_email"
                                        class="form-control"
                                        :placeholder="__('common:email')"
                                    />
                                </CFormGroup>
                                <CFormGroup
                                    v-if="showCAF()"
                                    class="tw-basis-full"
                                >
                                    <CLabel for="first_parent_alloc_no">
                                        {{ __('family_member:beneficiary_number_short') }}
                                    </CLabel>
                                    <input
                                        id="first_parent_alloc_no"
                                        v-model="registration.first_parent_alloc_no"
                                        class="form-control"
                                        :placeholder="__('family_member:beneficiary_number_short')"
                                    />
                                </CFormGroup>
                            </div>
                            <hr />
                            <CFormGroup>
                                <CLabel for="first_parent_address">
                                    {{ __('common:address.address') }}
                                </CLabel>
                                <input
                                    id="first_parent_address"
                                    v-model="registration.first_parent_address"
                                    class="form-control"
                                    :placeholder="__('common:address.address')"
                                />
                            </CFormGroup>
                            <div class="tw-flex tw-gap-4">
                                <CFormGroup class="tw-basis-1/3">
                                    <CLabel for="first_parent_zipcode">
                                        {{ __('common:address.zip_code') }}
                                    </CLabel>
                                    <input
                                        id="first_parent_zipcode"
                                        v-model="registration.first_parent_zipcode"
                                        class="form-control"
                                        :placeholder="__('common:address.zip_code')"
                                    />
                                </CFormGroup>
                                <CFormGroup class="tw-basis-2/3">
                                    <CLabel for="first_parent_city">
                                        {{ __('common:address.city') }}
                                    </CLabel>
                                    <input
                                        id="first_parent_city"
                                        v-model="registration.first_parent_city"
                                        class="form-control"
                                        :placeholder="__('common:address.city')"
                                    />
                                </CFormGroup>
                            </div>
                            <div class="tw-flex tw-gap-4">
                                <CFormGroup class="tw-basis-full">
                                    <CLabel>{{ __('common:address.country') }}</CLabel>
                                    <SelectCountry
                                        v-model="registration.first_parent_country"
                                        button-class="tw-py-2"
                                    />
                                </CFormGroup>
                            </div>
                            <hr />
                            <div class="tw-flex tw-gap-4">
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="first_parent_phone">
                                        {{ __('common:phone.landline') }}
                                    </CLabel>
                                    <input
                                        id="first_parent_phone"
                                        v-model="registration.first_parent_phone"
                                        class="form-control"
                                        :placeholder="__('common:phone.landline')"
                                    />
                                </CFormGroup>
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="first_parent_mobile_phone">
                                        {{ __('common:phone.cellular') }}
                                    </CLabel>
                                    <input
                                        id="first_parent_mobile_phone"
                                        v-model="registration.first_parent_mobile_phone"
                                        class="form-control"
                                        :placeholder="__('common:phone.cellular')"
                                    />
                                </CFormGroup>
                            </div>
                            <hr />
                            <CFormGroup>
                                <CLabel for="first_parent_salary">
                                    {{ __('common:annual_income_for_the_year', {year: salaryDate}) }}
                                </CLabel>
                                <input
                                    id="first_parent_salary"
                                    v-model.number="registration.first_parent_salary"
                                    class="form-control"
                                    :placeholder="__('common:annual_income')"
                                />
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel for="first_parent_job">
                                    {{ __('common:job') }}
                                </CLabel>
                                <input
                                    id="first_parent_job"
                                    v-model="registration.first_parent_job"
                                    class="form-control"
                                    :placeholder="__('common:job')"
                                />
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel for="first_parent_company_name">
                                    {{ __('common:company_name') }}
                                </CLabel>
                                <input
                                    id="first_parent_company_name"
                                    v-model="registration.first_parent_company_name"
                                    class="form-control"
                                    :placeholder="__('common:name')"
                                />
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>
                                    {{ __('common:company_contract') }}
                                    <MHelpTooltip class="tw-ml-1">
                                        {{ __('registration:company_can_finance_nursery_place') }}
                                    </MHelpTooltip>
                                </CLabel>
                                <select
                                    v-model="registration.first_parent_company_contract"
                                    class="form-control"
                                    :title="__('registration:flexible_planning')"
                                >
                                    <option :value="1">
                                        {{ __('common:yes') }}
                                    </option>
                                    <option :value="0">
                                        {{ __('common:no') }}
                                    </option>
                                </select>
                            </CFormGroup>
                            <template v-if="registration.first_parent_company_contract">
                                <CFormGroup>
                                    <CLabel for="first_parent_company_address">
                                        {{ __('common:company_address') }}
                                    </CLabel>
                                    <input
                                        id="first_parent_company_address"
                                        v-model="registration.first_parent_company_address"
                                        class="form-control"
                                        :placeholder="__('common:address.address')"
                                    />
                                </CFormGroup>
                                <div class="tw-flex tw-gap-4">
                                    <CFormGroup class="tw-basis-1/3">
                                        <CLabel for="first_parent_company_zipcode">
                                            {{ __('common:address.zip_code') }}
                                        </CLabel>
                                        <input
                                            id="first_parent_company_zipcode"
                                            v-model="registration.first_parent_company_zipcode"
                                            class="form-control"
                                            :placeholder="__('common:address.zip_code')"
                                        />
                                    </CFormGroup>
                                    <CFormGroup class="tw-basis-2/3">
                                        <CLabel for="second_parent_company_city">
                                            {{ __('common:address.city') }}
                                        </CLabel>
                                        <input
                                            id="first_parent_company_city"
                                            v-model="registration.first_parent_company_city"
                                            class="form-control"
                                            :placeholder="__('common:address.city')"
                                        />
                                    </CFormGroup>
                                </div>
                                <CFormGroup>
                                    <CLabel>{{ __('common:address.country') }}</CLabel>
                                    <SelectCountry
                                        v-model="registration.first_parent_company_country"
                                        button-class="tw-py-2"
                                    />
                                </CFormGroup>
                                <div class="tw-flex tw-gap-4">
                                    <CFormGroup class="tw-basis-full">
                                        <CLabel for="first_parent_company_phone">
                                            {{ __('common:phone.landline') }}
                                        </CLabel>
                                        <input
                                            id="first_parent_company_phone"
                                            v-model="registration.first_parent_company_phone"
                                            class="form-control"
                                            :placeholder="__('common:phone.landline')"
                                        />
                                    </CFormGroup>
                                    <CFormGroup class="tw-basis-full">
                                        <CLabel for="first_parent_company_email">
                                            {{ __('common:email') }}
                                        </CLabel>
                                        <input
                                            id="first_parent_company_email"
                                            v-model="registration.first_parent_company_email"
                                            class="form-control"
                                            :placeholder="__('common:email')"
                                        />
                                    </CFormGroup>
                                </div>
                            </template>
                        </div>
                        <div class="tw-flex tw-flex-col tw-gap-4">
                            <MHeading level="h3">
                                {{ __('common:second_parent') }}
                            </MHeading>
                            <select
                                v-model="registration.second_parent_role"
                                class="form-control"
                                :title="__('common:role')"
                            >
                                <option value="mother">
                                    {{ __('common:relationship.parent_female') }}
                                </option>
                                <option value="father">
                                    {{ __('common:relationship.parent_male') }}
                                </option>
                                <option value="brother">
                                    {{ __('common:relationship.sibling_male') }}
                                </option>
                                <option value="sister">
                                    {{ __('common:relationship.sibling_female') }}
                                </option>
                                <option value="uncle">
                                    {{ __('common:relationship.pibling_male') }}
                                </option>
                                <option value="aunt">
                                    {{ __('common:relationship.pibling_female') }}
                                </option>
                                <option value="stepmother">
                                    {{ __('common:relationship.parent_in_law_female') }}
                                </option>
                                <option value="stepfather">
                                    {{ __('common:relationship.parent_in_law_male') }}
                                </option>
                                <option value="grandparent">
                                    {{ __('common:relationship.grandparent') }}
                                </option>
                                <option value="legal_custodian_male">
                                    {{ __('common:relationship.legal_custodian_male') }}
                                </option>
                                <option value="legal_custodian_female">
                                    {{ __('common:relationship.legal_custodian_female') }}
                                </option>
                                <option value="other">
                                    {{ __('common:other_one') }}
                                </option>
                            </select>
                            <div class="tw-flex tw-gap-4">
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="second_parent_first_name">
                                        {{ __('common:first_name') }}
                                    </CLabel>
                                    <input
                                        id="second_parent_first_name"
                                        v-model="registration.second_parent_first_name"
                                        class="form-control"
                                        :placeholder="__('common:first_name')"
                                    />
                                </CFormGroup>
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="second_parent_last_name">
                                        {{ __('common:last_name') }}
                                    </CLabel>
                                    <input
                                        id="second_parent_last_name"
                                        v-model="registration.second_parent_last_name"
                                        class="form-control"
                                        :placeholder="__('common:last_name')"
                                    />
                                </CFormGroup>
                            </div>
                            <div class="tw-flex tw-gap-4">
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="second_parent_email">
                                        {{ __('common:email') }}
                                    </CLabel>
                                    <input
                                        id="second_parent_email"
                                        v-model="registration.second_parent_email"
                                        class="form-control"
                                        :placeholder="__('common:email')"
                                    />
                                </CFormGroup>
                                <CFormGroup
                                    v-if="showCAF()"
                                    class="tw-basis-full"
                                >
                                    <CLabel for="second_parent_alloc_no">
                                        {{ __('family_member:beneficiary_number_short') }}
                                    </CLabel>
                                    <input
                                        id="second_parent_alloc_no"
                                        v-model="registration.second_parent_alloc_no"
                                        class="form-control"
                                        :placeholder="__('family_member:beneficiary_number_short')"
                                    />
                                </CFormGroup>
                            </div>
                            <hr />
                            <CFormGroup>
                                <CLabel for="second_parent_address">
                                    {{ __('common:address.address') }} -
                                    <MButton
                                        class="tw-ml-1"
                                        variant="link"
                                        @click="copyAddress"
                                    >
                                        {{ __('registration:copy_first_parent_address') }}
                                    </MButton>
                                </CLabel>
                                <input
                                    id="second_parent_address"
                                    v-model="registration.second_parent_address"
                                    class="form-control"
                                    :placeholder="__('common:address.address')"
                                />
                            </CFormGroup>
                            <div class="tw-flex tw-gap-4">
                                <CFormGroup class="tw-basis-1/3">
                                    <CLabel for="second_parent_zipcode">
                                        {{ __('common:address.zip_code') }}
                                    </CLabel>
                                    <input
                                        id="second_parent_zipcode"
                                        v-model="registration.second_parent_zipcode"
                                        class="form-control"
                                        :placeholder="__('common:address.zip_code')"
                                    />
                                </CFormGroup>
                                <CFormGroup class="tw-basis-2/3">
                                    <CLabel for="second_parent_city">
                                        {{ __('common:address.city') }}
                                    </CLabel>
                                    <input
                                        id="second_parent_city"
                                        v-model="registration.second_parent_city"
                                        class="form-control"
                                        :placeholder="__('common:address.city')"
                                    />
                                </CFormGroup>
                            </div>
                            <CFormGroup>
                                <CLabel>{{ __('common:address.country') }}</CLabel>
                                <SelectCountry
                                    v-model="registration.second_parent_country"
                                    button-class="tw-py-2"
                                />
                            </CFormGroup>
                            <hr />
                            <div class="tw-flex tw-gap-4">
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="second_parent_phone">
                                        {{ __('common:phone.landline') }}
                                    </CLabel>
                                    <input
                                        id="second_parent_phone"
                                        v-model="registration.second_parent_phone"
                                        class="form-control"
                                        :placeholder="__('common:phone.landline')"
                                    />
                                </CFormGroup>
                                <CFormGroup class="tw-basis-full">
                                    <CLabel for="second_parent_mobile_phone">
                                        {{ __('common:phone.cellular') }}
                                    </CLabel>
                                    <input
                                        id="second_parent_mobile_phone"
                                        v-model="registration.second_parent_mobile_phone"
                                        class="form-control"
                                        :placeholder="__('common:phone.cellular')"
                                    />
                                </CFormGroup>
                            </div>
                            <hr />
                            <CFormGroup>
                                <CLabel for="second_parent_salary">
                                    {{ __('common:annual_income_for_the_year', {year: salaryDate}) }}
                                </CLabel>
                                <input
                                    id="second_parent_salary"
                                    v-model.number="registration.second_parent_salary"
                                    class="form-control"
                                    :placeholder="__('common:annual_income')"
                                />
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel for="second_parent_job">
                                    {{ __('common:job') }}
                                </CLabel>
                                <input
                                    id="second_parent_job"
                                    v-model="registration.second_parent_job"
                                    class="form-control"
                                    :placeholder="__('common:job')"
                                />
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel for="second_parent_company_name">
                                    {{ __('common:company_name') }}
                                </CLabel>
                                <input
                                    id="second_parent_company_name"
                                    v-model="registration.second_parent_company_name"
                                    class="form-control"
                                    :placeholder="__('common:name')"
                                />
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>
                                    {{ __('common:company_contract') }}
                                    <MHelpTooltip class="tw-ml-1">
                                        {{ __('registration:company_can_finance_nursery_place') }}
                                    </MHelpTooltip>
                                </CLabel>
                                <select
                                    v-model="registration.second_parent_company_contract"
                                    class="form-control"
                                    :title="__('registration:flexible_planning')"
                                >
                                    <option :value="1">
                                        {{ __('common:yes') }}
                                    </option>
                                    <option :value="0">
                                        {{ __('common:no') }}
                                    </option>
                                </select>
                            </CFormGroup>
                            <template v-if="registration.second_parent_company_contract">
                                <CFormGroup>
                                    <CLabel for="second_parent_company_address">
                                        {{ __('common:company_address') }}
                                    </CLabel>
                                    <input
                                        id="second_parent_company_address"
                                        v-model="registration.second_parent_company_address"
                                        class="form-control"
                                        :placeholder="__('common:address.address')"
                                    />
                                </CFormGroup>
                                <div class="tw-flex tw-gap-4">
                                    <CFormGroup class="tw-basis-1/3">
                                        <CLabel for="second_parent_company_zipcode">
                                            {{ __('common:address.zip_code') }}
                                        </CLabel>
                                        <input
                                            id="second_parent_company_zipcode"
                                            v-model="registration.second_parent_company_zipcode"
                                            class="form-control"
                                            :placeholder="__('common:address.zip_code')"
                                        />
                                    </CFormGroup>
                                    <CFormGroup class="tw-basis-2/3">
                                        <CLabel for="second_parent_company_city">
                                            {{ __('common:address.city') }}
                                        </CLabel>
                                        <input
                                            id="second_parent_company_city"
                                            v-model="registration.second_parent_company_city"
                                            class="form-control"
                                            :placeholder="__('common:address.city')"
                                        />
                                    </CFormGroup>
                                </div>
                                <CFormGroup>
                                    <CLabel>{{ __('common:address.country') }}</CLabel>
                                    <SelectCountry
                                        v-model="registration.second_parent_company_country"
                                        button-class="tw-py-2"
                                    />
                                </CFormGroup>
                                <div class="tw-flex tw-gap-4">
                                    <CFormGroup class="tw-basis-full">
                                        <CLabel for="second_parent_company_phone">
                                            {{ __('common:phone.landline') }}
                                        </CLabel>
                                        <input
                                            id="second_parent_company_phone"
                                            v-model="registration.second_parent_company_phone"
                                            class="form-control"
                                            :placeholder="__('common:phone.landline')"
                                        />
                                    </CFormGroup>
                                    <CFormGroup class="tw-basis-full">
                                        <CLabel for="second_parent_company_email">
                                            {{ __('common:email') }}
                                        </CLabel>
                                        <input
                                            id="second_parent_company_email"
                                            v-model="registration.second_parent_company_email"
                                            class="form-control"
                                            :placeholder="__('common:email')"
                                        />
                                    </CFormGroup>
                                </div>
                            </template>
                        </div>
                    </div>
                    <CFormGroup class="tw-mt-4 tw-w-1/2 sm:tw-w-1/4">
                        <CLabel for="contract_dependent_children">
                            {{ __('common:dependent_children') }}
                        </CLabel>
                        <input
                            id="contract_dependent_children"
                            v-model="registration.contract_dependent_children"
                            class="form-control"
                            :placeholder="__('registration:one')"
                            type="number"
                        />
                    </CFormGroup>
                </div>
            </MStepPanelContent>
        </MStepPanel>

        <template #footer-start>
            <MButton
                v-if="hasPreviousStep"
                variant="primary"
                @click="$refs.stepper.previousStep()"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </template>
                {{ __('common:actions.previous') }}
            </MButton>
        </template>

        <template #footer-end>
            <MButton
                class="tw-mr-2"
                @click="cancelRegistration"
            >
                {{ __('common:actions.cancel') }}
            </MButton>

            <template v-if="hasNextStep">
                <MButton
                    variant="primary"
                    @click="$refs.stepper.nextStep()"
                >
                    <template #right-icons>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                    </template>
                    {{ __('common:actions.next') }}
                </MButton>
            </template>
            <template v-else>
                <MButton
                    :loading="loading"
                    variant="primary"
                    @click="save()"
                >
                    {{ __('common:actions.save') }}
                </MButton>
            </template>
        </template>
    </MModal>
</template>

<script>
    import _clone from 'lodash-es/clone';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import defaultRegistration from '@/modules/legacy/contents/defaultRegistration';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import Planning from './Planning/Planning.vue';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {Epoch} from '@meekohq/lumos';

    export default {
        components: {
            SelectCountry,
            'module-planning': Planning,
        },
        mixins: [nursery],
        props: {
            modal: {
                type: Object,
                required: true,
            },
            nursery: {},
            registration: {
                type: Object,
                default() {
                    return _cloneDeep(defaultRegistration);
                },
            },
        },
        data() {
            return {
                loading: false,
                hasPreviousStep: false,
                hasNextStep: false,
            };
        },
        computed: {
            registrationExists() {
                return this.registration && this.registration.id !== null;
            },
            salaryDate() {
                if (this.registration.contract_started_at) {
                    return Epoch.fromISOString(this.registration.contract_started_at).subYears(2).year;
                }

                return '';
            },
            isStepOneCompleted() {
                return !!this.registration.kid_last_name && !!this.registration.kid_birthdate;
            },
            isStepTwoCompleted() {
                return !!this.registration.contract_started_at && !!this.registration.contract_ended_at;
            },
            isStepThreeCompleted() {
                return (
                    !!this.registration.first_parent_first_name &&
                    !!this.registration.first_parent_last_name &&
                    !!this.registration.first_parent_email
                );
            },
        },
        watch: {
            'registration.first_parent_role': function (val) {
                this.setParentGenderFromRole(val, 'first_parent_gender');
            },
            'registration.second_parent_role': function (val) {
                this.setParentGenderFromRole(val, 'second_parent_gender');
            },
            'registration.id': function (val) {
                if (val === null) {
                    this.$refs.wizard.navigateToTab(0);
                }
            },
        },
        methods: {
            save() {
                const data = _clone(this.registration);

                this.loading = true;

                if (!data.requested_at) {
                    data.requested_at = Epoch.now().toFormat('yyyy-MM-dd');
                }

                this.store(data, registration => {
                    this.registration.id = registration.id;
                    this.attach(registration => {
                        this.$emit('registrationCreated', registration);
                        useNotification().success(__('registration:update_success'));
                        this.modal.hide();
                    });
                });
            },

            store(data, callback) {
                useApi()
                    .legacy.post(route('registrations.store'), data)
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            attach(callback) {
                useApi()
                    .legacy.post(
                        route('nurseries.registrations.attach', {
                            nurseries: this.nursery.id,
                            registration: this.registration.id,
                        })
                    )
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            copyAddress() {
                this.registration.second_parent_address = this.registration.first_parent_address;
                this.registration.second_parent_city = this.registration.first_parent_city;
                this.registration.second_parent_country = this.registration.first_parent_country;
                this.registration.second_parent_zipcode = this.registration.first_parent_zipcode;
                this.registration.second_parent_phone = this.registration.first_parent_phone;
            },

            cancelRegistration() {
                this.modal.hide();
            },

            showCAF() {
                return this.$showCAF(this.nursery.country);
            },

            setParentGenderFromRole(role, registrationParentGenderkey) {
                const females = ['mother', 'sister', 'aunt', 'stepmother', 'legal_custodian_female'];

                if (females.indexOf(role) >= 0) {
                    this.registration[registrationParentGenderkey] = 'female';
                } else {
                    this.registration[registrationParentGenderkey] = 'male';
                }
            },
        },
    };
</script>

<style scoped>
    #store-registration {
        @media screen(sm) {
            .modal-lg {
                max-width: 80%;
            }
        }
    }
</style>
