<template>
    <CForm>
        <CFormTwoColumns v-if="allFamilyMembers.length">
            <CFormGroup>
                <CLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-users" />
                    </template>
                    {{ __('common:parent') }}
                </CLabel>
                <MemberFinder
                    button-class="tw-w-full"
                    class="tw-block"
                    :has-unselect="true"
                    :inject-query="memberFinderQueryBuilder"
                    :value="selectedFamilyMember"
                    @input="$emit('selectExistingMember', $event)"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-handshake" />
                    </template>
                    {{ __('family_member:kinship') }}
                </CLabel>
                <CFormSelect
                    v-model="newFamilyMember.role"
                    :options="relationshipOptions"
                    :search-bar="false"
                    text-path="text"
                    value-path="value"
                />
            </CFormGroup>
        </CFormTwoColumns>

        <CFormTwoColumns>
            <CFormGroup>
                <MemberPermissionsInput v-model="newFamilyMember.permissions" />
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-hands-helping" />
                    </template>
                    {{ __('family_member:pick_up_child') }}
                </CLabel>
                <CFormSelect
                    v-model="newFamilyMember.can_pickup_kid"
                    :options="[
                        {value: 1, text: __('common:yes')},
                        {value: 0, text: __('common:no')},
                    ]"
                    :search-bar="false"
                    text-path="text"
                    value-path="value"
                />
            </CFormGroup>
        </CFormTwoColumns>
    </CForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, toRef} from 'vue';
    import MemberPermissionsInput from '@/modules/family/components/member/MemberPermissionsInput.vue';
    import MemberFinder from '@/modules/family/components/member/MemberFinder.vue';
    import MemberModel from '@/modules/family/models/MemberModel';
    import useMemberRoleTranslations from '@/modules/family/composables/member/useMemberRoleTranslations';

    export default defineComponent({
        components: {MemberFinder, MemberPermissionsInput},
        props: {
            newFamilyMember: {
                type: Object,
                required: true,
            },
            allFamilyMembers: {
                type: Array,
                required: true,
            },
            selectedFamilyMember: {
                type: Object as PropType<MemberModel>,
                default: null,
            },
            kidFamilyMemberIds: {
                type: Array<string>,
                required: true,
            },
        },
        setup(props) {
            const memberFinderQueryBuilder = computed(() => {
                return MemberModel.query()
                    .whereHas(new MemberModel().organizations(), query => query.scope('active'))
                    .whereNotIn('id', props.kidFamilyMemberIds);
            });

            const {relationshipOptions} = useMemberRoleTranslations(toRef(props.newFamilyMember, 'gender'));

            return {
                relationshipOptions,
                memberFinderQueryBuilder,
            };
        },
    });
</script>
