<template>
    <div class="tw-contents">
        <TenantFinder
            v-if="showTenantsFilter"
            ref="tenantsFilter"
            v-model="filtersStore.tenantsFilter.data.value"
            button-size="sm"
            has-active-contract
            multi
            only-user-organizations
        />
        <CustomerFinder
            v-if="showCustomersFilter"
            ref="customersFilter"
            v-model="filtersStore.customersFilter.data.value"
            button-class="tw-w-full"
            button-size="sm"
            has-active-contract
            :inject-query="injectQueryFinder"
            multi
            only-user-organizations
        />
        <CFormSelect
            ref="paymentMethodFilter"
            v-model="filtersStore.paymentMethodFilter.data.value"
            button-size="sm"
            :fallback-text="__('billing_payment:means_payment')"
            has-unselect
            :options="paymentMethodsOptionsNames"
            :search-bar="false"
            text-path="name"
            value-path="value"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import paymentMethodsOptions from '@/modules/cashier/utils/payment-method/paymentMethodsOptions';
    import CustomerFinder from '@/modules/cashier/components/core/customer/organisms/CustomerFinder.vue';
    import TenantFinder from '@/modules/cashier/components/tax/tax-certificate/organisms/TenantFinder.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type {OutputType} from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';

    export default defineComponent({
        components: {
            TenantFinder,
            CustomerFinder,
        },
        props: {
            filtersStore: {
                type: Object as PropType<OutputType>,
                required: true,
            },
            showTenantsFilter: {
                type: Boolean,
                default: false,
            },
            showCustomersFilter: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const tenantsFilter = ref();
            const customersFilter = ref();
            const paymentMethodFilter = ref();

            const paymentMethodsOptionsNames = paymentMethodsOptions().map(option => {
                return {
                    name: option.name,
                    value: option.name,
                };
            });

            const injectQueryFinder = computed(() => {
                // Same behavior as transaction item (if no tenant is selected, we show all tenants)
                const queryCustomer = CustomerModel.query();

                if (props.filtersStore.tenantsFilter.data.value.length > 0) {
                    queryCustomer.whereIn(
                        'tenant_id',
                        props.filtersStore.tenantsFilter.data.value.map(tenant => tenant.getKey())
                    );
                }

                return queryCustomer;
            });

            return {
                paymentMethodsOptionsNames,
                injectQueryFinder,
                tenantsFilter,
                customersFilter,
                paymentMethodFilter,
            };
        },
    });
</script>
