<template>
    <div class="RegistrationFilters">
        <div class="RegistrationFilters__dropdowns">
            <MDatePeriodFilter
                v-model="periodFilter"
                allow-undefined
            />
            <TagsResourceFinder
                button-variant="light"
                class="!tw-static"
                has-unselect
                :inject-query="tagQuery"
                multi
                :search-bar="false"
                :value="tagsFilters"
                :without-tags-option-is-selected="withoutTagsOptionIsSelected"
                @input="tagsFilters = $event"
                @without-tags-option-is-selected="withoutTagsOptionIsSelected = $event"
            />
            <RegistrationStateFilter v-model="registrationStatusFilter" />
        </div>
        <CInput
            :placeholder="__('registration:search_first_name_or_last_name_dots')"
            type="search"
            :value="nameFilter"
            @input="updateNameFilter($event)"
        />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import RegistrationStateFilter from '@/modules/registration/components/organisms/RegistrationStateFilter.vue';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import TagModel from '@/modules/tag/models/TagModel';
    import useManager from '@/modules/app/composables/useManager';
    import useRegistrationFilters from '@/modules/registration/composables/useRegistrationFilters';

    export default defineComponent({
        components: {
            MDatePeriodFilter,
            RegistrationStateFilter,
            TagsResourceFinder,
        },
        props: {
            storageKeyPrefix: {
                type: String,
                required: true,
            },
        },
        setup(props, {emit}) {
            // Init stored filters
            const {
                registrationStatusFilter,
                nameFilter,
                tagsFilters,
                withoutTagsOptionIsSelected,
                contractStartDate,
                contractEndDate,
            } = useRegistrationFilters(props.storageKeyPrefix);

            const tagQuery = TagModel.query().where('type', 'registration');

            const {activeOrganization} = useManager();

            const periodFilter = ref({
                from: contractStartDate.value,
                to: contractEndDate.value,
            });

            /*
                Compute the RegistrationModel query to add constraints from filters if they are activated
             */
            const queryWithFilters = computed(() => {
                const query = RegistrationModel.query();

                applyRegistrationStateConstraints(
                    query,
                    registrationStatusFilter.value,
                    activeOrganization.value.getKey()
                );
                applyNameConstraints(query, nameFilter.value);
                applyTagsConstraints(query, tagsFilters.value);
                applyDatesConstraints(query, periodFilter.value);
                applyWithoutTagsConstraint(query, withoutTagsOptionIsSelected.value);

                return query;
            });

            /**
             * Apply different constraints on query depending on registration status
             * @param query
             * @param status
             * @param organizationId
             */
            function applyRegistrationStateConstraints(query, status, organizationId) {
                if (!status) {
                    return query;
                }

                const constraints = {
                    pending() {
                        query
                            .whereHas(new RegistrationModel().organizationsPivots(), query2 => {
                                query2
                                    .where('organization_id', organizationId)
                                    .where('rejected', false)
                                    .whereNull('kid_id');
                            })
                            .with(new RegistrationModel().organizations(), query3 => {
                                query3.where('id', organizationId);
                            });
                    },

                    accepted_with_draft_contract() {
                        query
                            .whereHas(new RegistrationModel().organizationsPivots(), query2 => {
                                query2.where('organization_id', organizationId).whereNotNull('kid_id');
                            })
                            .whereHas('contracts', query3 => {
                                query3.where('draft', true).where('nursery_id', organizationId);
                            })
                            .with(new RegistrationModel().organizations(), query4 => {
                                query4.where('id', organizationId);
                            });
                    },
                    rejected() {
                        query
                            .whereHas(new RegistrationModel().organizationsPivots(), query2 => {
                                query2
                                    .where('organization_id', organizationId)
                                    .whereNull('kid_id')
                                    .where('rejected', true);
                            })
                            .with(new RegistrationModel().organizations(), query3 => {
                                query3.where('id', organizationId);
                            });
                    },
                };

                constraints[status](query);

                return query;
            }

            /**
             * Apply constraints on all name related fields of the registration
             * @param query
             * @param name
             */
            function applyNameConstraints(query, name) {
                if (!name) {
                    return query;
                }

                return query.where(query2 => {
                    query2
                        .whereLike('kid_first_name', `*${name}*`)
                        .orWhereLike('kid_last_name', `*${name}*`)
                        .orWhereLike('first_parent_first_name', `*${name}*`)
                        .orWhereLike('first_parent_last_name', `*${name}*`)
                        .orWhereLike('second_parent_first_name', `*${name}*`)
                        .orWhereLike('second_parent_last_name', `*${name}*`);
                });
            }

            /**
             * Apply constraints on registrations having a relationship with the tags
             * @param query
             * @param tags
             */
            function applyTagsConstraints(query, tags) {
                if (!tags || !tags.length) {
                    return query;
                }

                const tagsIds = tags.map(tag => tag.getKey()) as string[];

                return query.whereHas(new RegistrationModel().tags(), query2 => {
                    return query2.whereIn('id', tagsIds);
                });
            }

            function applyDatesConstraints(query, period) {
                const hasStartDate = period && typeof period.from === 'string' && period.from.length;
                const hasEndDate = period && typeof period.to === 'string' && period.to.length;
                if (hasStartDate && hasEndDate) {
                    return query.scope('inRange', {from: period.from, to: period.to});
                }

                if (hasStartDate && !hasEndDate) {
                    return query.where(query2 => {
                        query2
                            .where('contract_started_at', '>=', period.from)
                            .orWhere('contract_ended_at', '>=', period.from);
                    });
                }
                if (!hasStartDate && hasEndDate) {
                    return query.where(query2 => {
                        query2
                            .where('contract_started_at', '<=', period.to)
                            .orWhere('contract_ended_at', '<=', period.to);
                    });
                }

                return query;
            }

            function applyWithoutTagsConstraint(query, withoutTags) {
                if (withoutTags) {
                    return query.whereDoesntHave(new RegistrationModel().tags());
                }

                return query;
            }

            function updateNameFilter(name: string) {
                if (name.length > 2 || !name.length) {
                    nameFilter.value = name;
                }
            }

            /*
                Emit query with all selected filters constraints
             */
            watch(
                () => queryWithFilters.value.query,
                () => {
                    emit('updated', queryWithFilters.value);
                },
                {immediate: true}
            );

            return {
                registrationStatusFilter,
                nameFilter,
                tagsFilters,
                tagQuery,
                withoutTagsOptionIsSelected,
                periodFilter,
                updateNameFilter,
            };
        },
    });
</script>

<style scoped>
    .RegistrationFilters {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .RegistrationFilters__dropdowns {
        @apply tw-flex tw-flex-col tw-gap-2 sm:tw-flex-row;
    }
</style>
