<template>
    <CVStack
        class="item"
        gap="4"
    >
        <CVStack gap="2">
            <CHStack distribute="between">
                <CVStack class="tw-leading-none">
                    <CText
                        font-size="sm"
                        font-weight="semibold"
                        :uppercase="true"
                        variant="disabled"
                    >
                        {{ requestType.attributes.name }}
                    </CText>
                    <CHStack
                        align="baseline"
                        gap="1"
                    >
                        <CHStack
                            align="baseline"
                            gap="1"
                        >
                            <CTooltip placement="bottom">
                                <CText
                                    font-size="xs"
                                    variant="disabled"
                                >
                                    {{ __('common:date_format.day_from', {count: request.daysFromNow}) }}
                                </CText>
                                <template #content>
                                    <CText>
                                        {{ $formatDate(request.computed.created_at, 'DD MMMM YYYY à HH:mm') }}
                                    </CText>
                                </template>
                            </CTooltip>
                        </CHStack>

                        <CText
                            v-if="source"
                            font-size="xs"
                            variant="disabled"
                        >
                            {{ __('request:by_with_name', {value: source.fullname}) }}
                        </CText>
                    </CHStack>
                </CVStack>
                <CVStack
                    align="end"
                    gap="0"
                >
                    <CButtonGroup v-if="canEdit">
                        <RequestTypesStageFinder
                            button-size="sm"
                            :inject-query="excludePendingQueryBuilder"
                            :request-type="requestType"
                            :search-bar="false"
                            :value="requestTypeStage"
                            @input="createRequestStage($event)"
                        />
                    </CButtonGroup>
                    <CText
                        v-else
                        font-size="sm"
                        style="font-weight: 500"
                    >
                        <CHStack
                            align="center"
                            gap="1"
                        >
                            <CIcon
                                :path="requestTypeStage.icon.attributes.path"
                                :provider="requestTypeStage.icon.attributes.provider"
                                :style="{color: requestTypeStage.attributes.color}"
                            />
                            <span>{{ requestTypeStage.attributes.name }}</span>
                        </CHStack>
                    </CText>
                </CVStack>
            </CHStack>
            <CHStack
                v-if="request.attributes.description"
                align="start"
                class="tw-w-2/3 tw-leading-tight"
                gap="1"
            >
                <CText variant="primary">
                    <FontAwesomeIcon icon="fa-duotone fa-comment-lines" />
                </CText>
                <CText
                    emphase
                    family="display"
                >
                    {{ request.attributes.description }}
                </CText>
            </CHStack>
            <CHStack
                v-if="lastRequestStage.attributes.explanation"
                align="start"
                class="tw-w-2/3 tw-leading-tight"
                gap="1"
            >
                <CText variant="danger">
                    <FontAwesomeIcon icon="fa-solid fa-ban" />
                </CText>
                <CText
                    emphase
                    family="display"
                >
                    {{ lastRequestStage.attributes.explanation }}
                </CText>
            </CHStack>
        </CVStack>
        <RequestResource
            :request="request"
            :resources="resources"
        />
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type RequestModel from '@/modules/request/models/RequestModel';
    import RequestTypeStageModel from '@/modules/request/models/RequestTypeStageModel';
    import RequestResource from '@/modules/request/components/Resources/RequestResource.vue';
    import RequestTypesStageFinder from '@/modules/request/components/RequestTypesStageFinder.vue';
    import {EventStatus} from '@/modules/human-resources/utils/calendar/Values/EventStatus';
    import useRequestsStage from '@/modules/request/components/composables/useRequestsStage';
    import useAbility from '@/modules/app/composables/useAbility';

    export default defineComponent({
        components: {RequestTypesStageFinder, RequestResource},
        props: {
            request: {
                type: Object as PropType<RequestModel>,
                required: true,
            },
        },
        setup(props, context) {
            const {can} = useAbility();

            const canEdit = computed(() => {
                if (!can('update', 'hr_request')) {
                    return false;
                }

                if (props.request.events().value().isNotEmpty()) {
                    switch (props.request.events().value().first().attributes.status) {
                        case EventStatus.pendingCreate:
                            return can('create', 'staffs_planning');
                        case EventStatus.pendingDelete:
                            return can('delete', 'staffs_planning');
                        default:
                            return can('update', 'staffs_planning');
                    }
                }

                return true;
            });

            const {
                requestStages,
                lastRequestStage,
                requestType,
                requestTypeStage,
                source,
                resources,

                createRequestStage,
            } = useRequestsStage(props.request, context);

            // On empêche le Finder de lister les stage type avec un status pending pour ne pas pouvoir repasser en "En Attente"
            // Parce qu'on ne peut pas repasser les events en pending (pas d'historique de statut)
            const excludePendingQueryBuilder = computed(function () {
                const query = RequestTypeStageModel.query();

                if (props.request.requestType().value().eventTypes().value().count()) {
                    query.where('type_status', '!=', 'pending');
                }

                return query;
            });

            return {
                canEdit,
                createRequestStage,
                excludePendingQueryBuilder,
                lastRequestStage,
                requestType,
                requestTypeStage,
                requestStages,
                resources,
                source,
            };
        },
    });
</script>

<style scoped>
    .divider {
        @apply tw-mb-3 tw-mt-2 tw-h-px tw-bg-gradient-to-r tw-from-transparent tw-via-gray-200 tw-to-transparent;
    }

    .item {
        @apply tw-border-b tw-border-gray-300 tw-p-3 last:tw-border-b-0;

        &.unread {
            @apply tw-bg-blue-100/50;

            .divider {
                @apply tw-via-blue-200;
            }
        }

        &:last-of-type {
            @apply tw-rounded-b-xl;
        }
    }
</style>
