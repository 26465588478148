<script lang="ts" setup>
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import {
        invoiceStatuses,
        useInvoiceStatusState,
    } from '@/modules/cashier/utils/billing/invoice/useInvoiceStatusState';
    import {computed, toRef} from 'vue';
    import useAbility from '@/modules/app/composables/useAbility';

    const props = withDefaults(
        defineProps<{
            invoice: LegacyInvoiceType;
            withColorStatus?: boolean;
        }>(),
        {
            withColorStatus: false,
        }
    );

    const {allows} = useAbility();
    const {invoiceStatus} = useInvoiceStatusState(toRef(props, 'invoice'));

    const titleColor = computed(() => {
        if (!props.withColorStatus) {
            return 'tw-text-gray-500';
        }

        switch (invoiceStatus.value) {
            case invoiceStatuses.unpaid:
            case invoiceStatuses.uncollectible:
                return 'tw-text-red-500';
            case invoiceStatuses.incomplete:
            case invoiceStatuses.pending:
                return 'tw-text-orange-500';
            case invoiceStatuses.paid:
                return 'tw-text-green-500';
            case invoiceStatuses.editing:
                return 'tw-text-black';
            case invoiceStatuses.draft:
            default:
                return 'tw-text-gray-500';
        }
    });

    const isCreditNote = computed(() => props.invoice.invoice_type === 'credit_note');
</script>

<template>
    <div class="InvoiceStatusTitle">
        <div
            class="InvoiceStatusTitle__title"
            :class="titleColor"
        >
            {{ invoice.no }}
        </div>
        <CBadge
            v-if="allows('support') && invoice.contract_no"
            size="sm"
        >
            {{ invoice.contract_no }}
        </CBadge>
        <CBadge
            v-if="isCreditNote"
            size="sm"
        >
            {{ __('common:credit_note') }}
        </CBadge>
    </div>
</template>

<style scoped>
    .InvoiceStatusTitle {
        @apply tw-flex tw-flex-wrap tw-gap-1;
    }

    .InvoiceStatusTitle__title {
        @apply tw-font-semibold;
    }
</style>
