<template>
    <div class="tw-relative">
        <div
            class="tw-absolute tw-top-0 tw-z-0 tw-h-16 tw-w-full tw-rounded-t-xl"
            :class="topBackgroundBaseClass"
        >
            <div class="dominos tw-absolute tw-inset-0" />
        </div>
        <div class="tw-relative tw-p-4">
            <div class="tw-flex tw-justify-center">
                <div class="tw-flex tw-flex-col tw-items-center">
                    <div
                        class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-border-4 tw-border-white tw-bg-purple-200"
                    >
                        <FontAwesomeIcon
                            class="tw-mb-1 tw-text-3xl tw-text-purple-500"
                            icon="fa-duotone fa-address-book"
                        />
                    </div>
                    <div class="tw-font-semibold">
                        {{ familyModel.attributes.name }}
                    </div>
                </div>
            </div>
            <transition-group
                v-if="!loading"
                class="tw-mt-4 tw-grid tw-grid-cols-2 tw-gap-2"
                name="zoom-300"
                tag="div"
            >
                <template v-if="$can('read', 'kids_details')">
                    <kid-card
                        v-for="kidModel in kidModelsOrdered"
                        :key="'kid-' + kidModel.getKey()"
                        :groups="activeOrganization.extra.legacy_groups"
                        :kid-model="kidModel"
                    />
                </template>
                <template v-if="$can('read', 'family_members_details')">
                    <member-card
                        v-for="memberModel in memberModels"
                        :key="'member-' + memberModel.getKey()"
                        :member-model="memberModel"
                    />
                </template>
            </transition-group>
            <div
                v-else
                class="tw-mt-4 tw-w-72"
            >
                <CSkeleton
                    :count="1"
                    grid-col="2"
                    layout="grid"
                    size="lg"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type KidModel from '@/modules/family/models/KidModel';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import {sortBy} from 'lodash-es';
    import KidCard from '@/modules/family/components/kid/KidCard.vue';
    import MemberCard from '@/modules/family/components/member/MemberCard.vue';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref} from 'vue';
    import useManager from '@/modules/app/composables/useManager';

    export default defineComponent({
        components: {
            KidCard,
            MemberCard,
        },
        props: {
            familyModel: {type: Object as PropType<FamilyModel>, required: true},
            loading: {type: Boolean, default: false, required: false},
        },
        setup(props) {
            const loading = ref(false);
            const kidModels = ref<KidModel[]>([]);
            const memberModels = ref<MemberModel[]>([]);
            const {activeOrganization} = useManager();

            const kidModelsOrdered = computed<KidModel[]>(() => {
                return sortBy(kidModels.value, kid => kid.fullname) as KidModel[];
            });

            const topBackgroundBaseClass = computed(() => {
                const output = [] as any[];

                output.push('gradient');

                return output;
            });

            onMounted(() => {
                loading.value = true;
                kidModels.value = props.familyModel.kids().value().all();
                memberModels.value = props.familyModel.members().value().all();
                loading.value = false;
            });

            return {
                kidModels,
                memberModels,
                kidModelsOrdered,
                topBackgroundBaseClass,
                activeOrganization,
            };
        },
    });
</script>

<style scoped>
    .gradient {
        background-image: linear-gradient(to bottom right, theme(colors.purple.400), theme(colors.pink.400));
    }

    .dominos {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23FFF' fill-opacity='0.2' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
        /*background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 0C6.716 0 0 6.716 0 15c8.284 0 15-6.716 15-15zM0 15c0 8.284 6.716 15 15 15 0-8.284-6.716-15-15-15zm30 0c0-8.284-6.716-15-15-15 0 8.284 6.716 15 15 15zm0 0c0 8.284-6.716 15-15 15 0-8.284 6.716-15 15-15z' fill='%23FFF' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");*/
    }
</style>
