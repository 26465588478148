<template>
    <div class="tw-flex tw-min-h-screen tw-items-center tw-bg-black">
        <div class="tw-w-full">
            <div class="tw-mx-auto tw-w-full tw-px-4">
                <div class="row">
                    <div
                        class="col-12 tw-mb-12 tw-ml-auto tw-mr-auto tw-mt-12"
                        style="max-width: 430px"
                    >
                        <code
                            v-for="line in lines"
                            :class="{error: line.includes('error')}"
                        >
                            > {{ line }}<br />
                        </code>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {app} from '@meekohq/lumos';
    import {AuthenticationContractBinding} from '@/modules/auth/utils/AuthenticationContract';

    export default {
        data: () => ({
            lines: [],
        }),
        computed: {
            email() {
                return this.$route.params.email;
            },
            token() {
                return window.localStorage.getItem('support-token');
            },
        },
        mounted() {
            this.lines.push('Logout from previous session...');
            app(AuthenticationContractBinding).logout();

            this.lines.push('Try to login...');
            app(AuthenticationContractBinding)
                .supportLoginByEmail(this.email, this.token)
                .then(() => {
                    this.lines.push('Login OK.');
                    this.lines.push('Saving credentials.');
                    this.lines.push('Redirect to dashboard in 3 seconds...');
                    setTimeout(() => {
                        this.$router.push({
                            name: 'dashboard',
                        });
                    }, 3000);
                })
                .catch(error => {
                    this.lines.push('Login Error: ' + JSON.stringify(error.response.data));
                });
        },
    };
</script>

<style scoped>
    code {
        color: theme(textColor.pink.500);

        &.error {
            color: #ffc000;
        }
    }
</style>
