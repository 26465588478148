<template>
    <div class="TransactionIndexActions__wrapper">
        <MButton
            v-if="can('create', 'transactions')"
            ref="createCreditRef"
            data-action-name="create.credit"
            :to="{name: 'payments.create', query: {type: TransactionTypeValue.credit}}"
        >
            <template #left-icons>
                <FontAwesomeIcon
                    class="tw-text-success-500"
                    icon="fa-solid fa-arrow-up-right"
                />
            </template>
            {{ __('billing_transaction:actions.create_credit') }}
        </MButton>
        <MButton
            v-if="can('create', 'transactions')"
            ref="createDebitRef"
            data-action-name="create.debit"
            :to="{name: 'payments.create', query: {type: TransactionTypeValue.debit}}"
        >
            <template #left-icons>
                <FontAwesomeIcon
                    class="tw-text-danger-500"
                    icon="fa-solid fa-arrow-down-left"
                />
            </template>
            {{ __('billing_transaction:actions.create_debit') }}
        </MButton>
        <TransactionIndexExport
            v-if="filtersStore.isReady"
            :period="filtersStore.periodFilter"
        />
        <MSettingsButton
            v-if="can('settings', 'invoices')"
            :to="{name: 'settings.billings.rules', params: {nursery: organizationId}}"
            :tooltip="__('billing_invoice:invoices_settings')"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import TransactionTypeValue from '@/modules/cashier/transaction/domain/TransactionTypeValue';
    import TransactionIndexExport from '@/pages/nurseries/[id]/billing/transactions/partials/TransactionIndexExport.vue';
    import type {OutputType} from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';

    export default defineComponent({
        components: {TransactionIndexExport},
        props: {
            organizationId: {
                type: String,
                required: true,
            },
            filtersStore: {
                type: Object as PropType<OutputType>,
                required: true,
            },
        },
        setup() {
            const {can} = useAbility();

            const createCreditRef = ref();
            const createDebitRef = ref();

            return {
                createCreditRef,
                createDebitRef,
                can,
                TransactionTypeValue,
            };
        },
    });
</script>

<style scoped>
    .TransactionIndexActions__wrapper {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-start tw-gap-2 md:tw-justify-end;
    }
</style>
