<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="staff">
                {{ __('app:menu.reports') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MSettingsButton
                v-if="$can('settings', 'staffs_details')"
                :to="{name: 'humanresources/settings'}"
                :tooltip="__('hr:team_parameters')"
            />
        </template>
        <template #content>
            <ReportsMenu />
            <router-view />
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import ReportsMenu from '@/modules/human-resources/components/report/Menu.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';

    export default defineComponent({
        components: {
            SimplePageTemplate,
            ReportsMenu,
        },
    });
</script>
