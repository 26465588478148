<script setup lang="ts">
    import {computed, ref, watch} from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import Manager from '@/modules/legacy/store/manager.store';
    import {JsonAPIPaginator} from '@meekohq/lumos';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import type Finder from '@/modules/coherence-ui/components/Tools/Finder.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {debounce} from 'lodash-es';

    defineProps<{
        value?: object;
    }>();

    const emits = defineEmits<{
        (e: 'search', value: string): void;
        (e: 'input', value: object): void;
        (e: 'fallback', value: string): void;
    }>();

    const finder = ref<typeof Finder>();

    const sepas = ref([]);
    const paginator = ref<JsonAPIPaginator | null>(null);
    const loader = ref(new Loader());
    const search = ref('');
    const selectSepaPopover = ref();

    function toogle() {
        // Permet d'évité de refaire une requête si on click pour fermer (isOpen = false, car au moment du clique il n'est pas encore visible)
        if (!selectSepaPopover.value.isOpen) {
            getSepas();
        }
    }

    function onSearchValue(event) {
        search.value = event;
    }

    watch(
        () => search.value,
        debounce(function () {
            getSepas();
            emits('search', search.value);
        }, 300)
    );

    const hasMoreResult = computed(() => {
        return paginator.value?.hasMore && loader.value.isDone();
    });

    function getSepas(page = 1): void {
        loader.value.start();
        useApi()
            .legacy.get(
                route('sepas.index', {
                    nursery_id: Manager.activeOrganization.id,
                }),
                {
                    params: {
                        type: 'debtor',
                        search: search.value,
                        page,
                        per_page: paginator.value?.perPage,
                    },
                }
            )
            .then(response => {
                paginator.value = new JsonAPIPaginator({
                    has_more: response.data.current_page < response.data.last_page,
                    current_page: response.data.current_page,
                    last_page: response.data.last_page,
                    per_page: response.data.per_page,
                    from: response.data.from,
                    to: response.data.to,
                    total: response.data.total,
                });

                if (paginator.value.currentPage === 1) {
                    sepas.value = response.data.data;
                } else {
                    sepas.value = sepas.value.concat(response.data.data);
                }

                loader.value.stop();
            })
            .catch(error => {
                loader.value.stop();
                if (error && error.response && error.response.status === 422) {
                    _forEach(error.response.data.errors, value => {
                        useNotification().error(_head(value) as string);
                    });
                } else {
                    useNotification().error(error);
                }
            });
    }

    function onSepaSelected(sepa: object) {
        emits('input', sepa);
    }

    function fetchNextModels() {
        if (paginator.value) {
            getSepas(paginator.value.nextPage);
        }
    }
</script>

<template>
    <MPopover ref="selectSepaPopover">
        <MButton
            :icon-sort="true"
            @click="toogle"
        >
            <slot
                name="button"
                :value="value"
            />
        </MButton>
        <template #content>
            <CFinder
                ref="finder"
                :has-more-result="hasMoreResult"
                :is-loading="loader.isLoading()"
                :options="sepas"
                ref-path="id"
                :search-bar="true"
                :unselect-value="null"
                :value="value"
                @fallback="emits('fallback', search)"
                @input="onSepaSelected"
                @keydown.tab="selectSepaPopover.closePopover()"
                @search="onSearchValue"
                @wantMore="fetchNextModels"
            >
                <template
                    v-if="$scopedSlots.fallback"
                    #fallback="{searchValue}"
                >
                    <slot
                        name="fallback"
                        :search-value="searchValue"
                    />
                </template>

                <template #unselect-item>
                    <slot name="unselect-item" />
                </template>

                <template #default="{option}">
                    <div>
                        <div class="tw-text-blue-700">
                            {{ option.name }}
                        </div>
                        <div class="tw-text-sm tw-text-gray-600">
                            {{ option.iban }}
                        </div>
                        <div class="tw-text-sm tw-text-gray-600">
                            {{ option.rum }}
                        </div>
                    </div>
                </template>
            </CFinder>
        </template>
    </MPopover>
</template>
