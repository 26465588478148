<template>
    <div class="tw-mt-3 tw-grid tw-grid-cols-2 tw-gap-3">
        <AddressListCard
            class="tw-col-span-2 md:tw-col-span-1"
            :customer-model="customerModel"
        />
        <ContactListCard
            class="tw-col-span-2 md:tw-col-span-1"
            :customer-model="customerModel"
            @created="customerNotificationCard?.refresh()"
            @deleted="customerNotificationCard?.refresh()"
            @updated="customerNotificationCard?.refresh()"
        />
        <CustomerNotificationCard
            ref="customerNotificationCard"
            class="tw-col-span-2 md:tw-col-span-1"
            :customer-model="customerModel"
        />
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import CustomerNotificationCard from '@/modules/cashier/components/core/notification/organisms/NotificationsCard.vue';
    import AddressListCard from '@/modules/cashier/components/core/address/organisms/AddressListCard.vue';
    import ContactListCard from '@/modules/cashier/components/core/contact/organisms/ContactListCard.vue';

    defineProps<{customerModel: CustomerModel}>();
    const customerNotificationCard = ref<{refresh: () => void}>();
</script>
