import type {CalculatorContract} from '@/modules/app/utils/CalculatorContract';
import {Calc} from 'calc-js';

/**
 * This class is a calculator that can do some operations with numbers.
 */
export default class Calculator implements CalculatorContract {
    private readonly calc: Calc;

    constructor(number = 0) {
        this.calc = new Calc(number);
    }

    static sum(x: number, y: number): number {
        return new Calculator(x).sum(y).result();
    }

    static minus(x: number, y: number): number {
        return new Calculator(x).minus(y).result();
    }

    static divide(x: number, y: number): number {
        return new Calculator(x).divide(y).result();
    }

    static multiply(x: number, y: number): number {
        return new Calculator(x).multiply(y).result();
    }

    sum(value: number): this {
        this.calc.sum(value);

        return this;
    }

    minus(value: number): this {
        this.calc.minus(value);

        return this;
    }

    divide(value: number): this {
        this.calc.divide(value);

        return this;
    }

    multiply(value: number): this {
        this.calc.multiply(value);

        return this;
    }

    result(): number {
        return this.calc.finish();
    }
}
