<template>
    <CModal
        ref="dialog"
        :header-title="__('common:contract_one')"
        size="4xl"
        visible
        v-on="$listeners"
    >
        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_in_progress_dots')"
        />
        <div v-else-if="contractCopy && organizationsRelation.value()">
            <div class="tw-grid tw-grid-cols-1 tw-gap-3 md:tw-grid-cols-3">
                <CFormGroup class="tw-min-w-0">
                    <CLabel> {{ __('common:beginning') }}* </CLabel>
                    <CFormDatepicker
                        v-model="contractCopy.attributes.started_at"
                        :disabled="disableEditing"
                        format="YYYY-MM-DD"
                    />
                    <CFormErrorMessageList
                        v-if="contractErrors.has('started_at')"
                        :errors="contractErrors.get('started_at')"
                    />
                </CFormGroup>

                <CFormGroup class="tw-min-w-0">
                    <CLabel>
                        {{ __('common:end') }}
                        <span class="tw-ml-1 tw-text-xs"> ({{ __('hr_contract:keep_empty_if_permanent') }}) </span>
                    </CLabel>
                    <CFormDatepicker
                        v-model="contractCopy.attributes.ended_at"
                        :disabled="disableEditing"
                        format="YYYY-MM-DD"
                    />
                    <CFormErrorMessageList
                        v-if="contractErrors.has('ended_at')"
                        :errors="contractErrors.get('ended_at')"
                    />
                </CFormGroup>

                <CFormGroup class="tw-min-w-0">
                    <CLabel>
                        {{ __('hr_contract:contract_broken_at') }}
                    </CLabel>
                    <CFormDatepicker
                        v-model="contractCopy.attributes.broked_at"
                        :disabled="disableEditing"
                        format="YYYY-MM-DD"
                    />
                    <CFormErrorMessageList
                        v-if="contractErrors.has('broked_at')"
                        :errors="contractErrors.get('broked_at')"
                    />
                </CFormGroup>
            </div>

            <div class="tw-mt-6 tw-grid tw-grid-cols-1 tw-gap-3 md:tw-mt-4 md:tw-grid-cols-3">
                <CFormGroup class="tw-min-w-0">
                    <CLabel>
                        {{ __('hr:job_one') }}
                    </CLabel>
                    <SelectJob
                        v-model="job"
                        button-class="tw-w-full"
                        :disabled="disableEditing"
                        dropdown-class="tw-w-full"
                        :has-unselect="true"
                        :organization-model="activeOrganization"
                        :unselect-value="null"
                        :with-contract="true"
                        @input="addJob"
                    >
                        <template #unselectedValue>
                            {{ __('common:not_filled') }}
                        </template>
                    </SelectJob>
                </CFormGroup>

                <CFormGroup class="tw-min-w-0">
                    <CLabel> {{ __('common:hours_per_week') }}* </CLabel>
                    <MNumberInput
                        v-model="contractCopy.attributes.hours_per_week"
                        allow-undefined
                        :min="0"
                        :parser-options="roundFourNumberParserOptions"
                    />
                    <CFormErrorMessageList
                        v-if="contractErrors.has('hours_per_week')"
                        :errors="contractErrors.get('hours_per_week')"
                    />
                </CFormGroup>

                <CFormGroup class="tw-min-w-0">
                    <CLabel>
                        {{ __('common:salary') }}
                    </CLabel>
                    <CInput
                        v-model.number="contractCopy.attributes.salary"
                        :disabled="disableEditing"
                        :placeholder="__('common:salary')"
                    />
                    <CFormErrorMessageList
                        v-if="contractErrors.has('salary')"
                        :errors="contractErrors.get('salary')"
                    />
                </CFormGroup>
            </div>
            <div class="tw-mt-6 tw-grid tw-grid-cols-1 tw-gap-3 md:tw-mt-4 md:tw-grid-cols-3">
                <CFormGroup class="tw-min-w-0">
                    <CLabel>
                        {{ __('common:contract_type') }}
                    </CLabel>
                    <SelectContractType
                        v-model="contractType"
                        button-class="tw-w-full"
                        :disabled="disableEditing"
                        :has-unselect="true"
                        @input="addContractType"
                    />
                </CFormGroup>
                <CFormGroup class="tw-min-w-0">
                    <CLabel>
                        {{ __('hr_contract:organizations_attached_to_contract') }}
                    </CLabel>
                    <SelectOrganizationsForContract
                        button-class="tw-w-full"
                        checkable
                        :disabled="disableEditing"
                        :has-unselect="true"
                        :organizations="organizationsRelation.value()"
                        :staff="staff"
                        :staff-organizations="organizationSelected"
                        @add="addOrganization($event)"
                        @addOrganizationOnStaffAndContract="addOrganizationOnStaffAndContract"
                        @delete="deleteOrganization($event)"
                    />
                </CFormGroup>
            </div>
            <CFormGroup class="tw-mt-4 tw-w-full">
                <CLabel>
                    {{ __('common:note_one') }}
                </CLabel>
                <CFormTextArea
                    v-model="contractCopy.attributes.note"
                    :disabled="disableEditing"
                    :placeholder="__('common:note_one')"
                    rows="4"
                />
            </CFormGroup>
            <OverlapingContracts
                v-if="contractErrors.has('contract_organizations')"
                class="tw-mt-3"
                :contract-to-check="contractCopy"
                :staff="staff"
            />
            <ContractFormTrialPeriod
                class="tw-my-3"
                :contract="contractCopy"
                :disable-editing="disableEditing"
                :error-handler="contractErrors"
                :trial-periods="trialPeriodsRelation.value()"
                @added="addPeriod($event)"
                @deleted="deletePeriod($event)"
            />
            <CDisclosure
                :open="false"
                :title="__('common:additional_information')"
            >
                <CVStack gap="3">
                    <CHStack
                        class="tw-flex-1"
                        gap="3"
                    >
                        <CFormGroup class="tw-flex-1">
                            <CLabel>
                                {{ __('hr:qualifications') }}
                            </CLabel>
                            <CFormTextArea
                                v-model="contractCopy.attributes.qualification"
                                :disabled="disableEditing"
                                :placeholder="__('hr:qualifications')"
                            />
                        </CFormGroup>
                    </CHStack>
                    <template v-if="job && job.attributes.internal_id === jobName.intern">
                        <CHStack gap="3">
                            <CFormGroup class="tw-flex-1">
                                <CLabel>
                                    {{ __('hr_contract:tutor_last_name') }}
                                </CLabel>
                                <CInput
                                    v-model="contractCopy.attributes.supervisor_last_name"
                                    :disabled="disableEditing"
                                    :placeholder="__('hr_contract:tutor_last_name')"
                                />
                            </CFormGroup>
                            <CFormGroup class="tw-flex-1">
                                <CLabel>
                                    {{ __('hr_contract:tutor_first_name') }}
                                </CLabel>
                                <CInput
                                    v-model="contractCopy.attributes.supervisor_first_name"
                                    :disabled="disableEditing"
                                    :placeholder="__('hr_contract:tutor_first_name')"
                                />
                            </CFormGroup>
                        </CHStack>
                        <CFormGroup>
                            <CCheckbox
                                v-model="contractCopy.attributes.part_time_work"
                                :disabled="disableEditing"
                            >
                                {{ __('hr_contract:part_time_contract') }}
                            </CCheckbox>
                        </CFormGroup>
                    </template>
                </CVStack>
            </CDisclosure>
            <CDisclosure
                v-if="can('update', 'staffs_balances')"
                class="tw-mt-4"
                :open="false"
                :title="__('common:balances')"
            >
                <CVStack gap="4">
                    <BalanceTypeFinder
                        :inject-query="excludeBalanceType"
                        :value="selectedBalanceType"
                        @input="addBalanceType"
                    />
                    <AllocationListForContract
                        :balance-types="balanceTypes"
                        :source="contractCopy"
                        :staff="staff"
                        @changed="allocations = $event"
                    />
                </CVStack>
            </CDisclosure>
        </div>
        <template #footer="{closeDialog}">
            <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-gap-2">
                <DownloadTemplate
                    v-if="contract.exists"
                    :disabled="stateErrors.getAll().isNotEmpty()"
                    doc-type="staff_contract"
                    :item="modelsForDownload"
                    :nursery="legacyNursery"
                    sm
                    :user="user"
                    variant="light"
                    wait-for-callback
                    @saveBeforeDownloadTemplate="saveBeforeDownloadTemplate"
                />
                <div class="tw-ml-auto tw-flex tw-flex-row tw-items-center tw-gap-2">
                    <MButton
                        variant="light"
                        @click="closeDialog"
                    >
                        {{ __('common:actions.cancel') }}
                    </MButton>
                    <MButton
                        :disabled="stateErrors.getAll().isNotEmpty()"
                        :loading="isSaving"
                        variant="primary"
                        @click="saveAndCloseDialog"
                    >
                        {{ __('common:actions.save') }}
                    </MButton>
                </div>
            </div>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, onMounted, ref} from 'vue';
    import type ContractModel from '@/modules/human-resources/models/ContractModel';
    import SelectJob from '@/modules/human-resources/components/job/SelectJob.vue';
    import SelectContractType from '@/modules/human-resources/components/contract/SelectContractType.vue';
    import SelectOrganizationsForContract from '@/modules/human-resources/components/contract/SelectOrganizationsForContract.vue';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import useManager from '@/modules/app/composables/useManager';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import {jobName} from '@/modules/human-resources/models/JobModel';
    import ContractFormTrialPeriod from '@/modules/human-resources/components/contract/ContractFormTrialPeriod.vue';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import BalanceTypeFinder from '@/modules/human-resources/components/balance-type/BalanceTypeFinder.vue';
    import AllocationListForContract from '@/modules/human-resources/components/balance/AllocationListForContract.vue';
    import OverlapingContracts from '@/modules/human-resources/components/contract/OverlapingContracts.vue';
    import type CModal from '@/modules/meeko-ui/components/MModal.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import useAbility from '@/modules/app/composables/useAbility';
    import useAttachAndSaveStaff from '@/modules/human-resources/composables/staff/useAttachAndSaveStaff';
    import useAttachContract from '@/modules/human-resources/composables/contract/useAttachContract';
    import useTrialPeriod from '@/modules/human-resources/composables/contract/useTrialPeriod';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import type BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
    import {useContratState} from '@/modules/human-resources/contract/infrastructure/useContratState';
    import {useSaveContract} from '@/modules/human-resources/contract/infrastructure/useSaveContract';
    import useError from '@/modules/app/composables/useError';
    import {getNumericParserContractPresetFloatRoundFourDigits} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import i18next from 'i18next';

    export default defineComponent({
        components: {
            OverlapingContracts,
            ContractFormTrialPeriod,
            AllocationListForContract,
            BalanceTypeFinder,
            DownloadTemplate,
            SelectOrganizationsForContract,
            SelectContractType,
            SelectJob,
        },
        props: {
            contract: {
                type: Object as PropType<ContractModel>,
                required: true,
            },
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
        },
        emits: ['saved'],
        setup(props, {emit}) {
            const dialog = ref<InstanceType<typeof CModal>>();
            const {legacyUser: user} = useAuth();
            const loading = ref(false);
            const {legacyNursery, activeOrganization} = useManager();
            const {can} = useAbility();

            const balanceTypes = ref(collect()) as Ref<Collection<BalanceTypeModel>>;
            const selectedBalanceType = ref<BalanceTypeModel>();
            const allocations = ref([]) as Ref<BalanceAllocationModel[]>;

            const roundFourNumberParserOptions = getNumericParserContractPresetFloatRoundFourDigits(i18next.language);

            const {contractCopy, trialPeriods, stateErrors} = useContratState(props.staff, props.contract);

            const {save, isSaving, savingErrors} = useSaveContract();

            const contractErrors = computed(() => {
                const errors = useError();

                stateErrors.getAll().each(error => {
                    if (!errors.has(error.source)) {
                        errors.add(error.source, error.code, error.message);
                    }
                });

                savingErrors.getAll().each(error => {
                    if (!errors.has(error.source)) {
                        errors.add(error.source, error.code, error.message);
                    }
                });

                return errors;
            });

            const {staffOrganizationsPivotRelation, staffOrganizationsRelation, addStaffInOrganization} =
                useAttachAndSaveStaff(props.staff);

            const {
                job,
                addJob,
                contractType,
                jobRelation,
                contractTypeRelation,
                addContractType,
                organizationsRelation,
                addOrganization,
                deleteOrganization,
            } = useAttachContract(contractCopy);

            const {addPeriod, deletePeriod, trialPeriodsRelation} = useTrialPeriod(contractCopy);

            const modelsForDownload = computed(() => {
                return {
                    staff: props.staff,
                    contract: contractCopy.value,
                };
            });

            const allocationRelation = computed(() => contractCopy.value.balanceAllocations());

            // Disable editing when creating and user doesn't have permission to create or updating and user doesn't have permission to update
            const disableEditing = computed(() => {
                return !(
                    (can('create', 'staffs_contracts') && !props.contract.exists) ||
                    (can('update', 'staffs_contracts') && contractCopy.value.exists)
                );
            });

            // Return the selected organization collection.
            const organizationSelected = computed(() => {
                // If there are no selected organization, pre-check every organizations where the staff is attached.
                if (organizationsRelation.value.value().isEmpty()) {
                    return staffOrganizationsPivotRelation.value.value().each(pivot => {
                        // Get organization model from staffOrganizationPivot.
                        const organization = staffOrganizationsRelation.value
                            .value()
                            .first(value => value.id === pivot.attributes.organization_id);

                        if (organization) {
                            addOrganization(organization);
                        }
                    });
                } else {
                    return staffOrganizationsPivotRelation.value.value();
                }
            });

            // Exclude BalanceType of SelectBalanceType list when it was selected before.
            const excludeBalanceType = computed(() => {
                const allocationIds = allocationRelation.value.value().pluck('id').all() as string[];
                const balanceTypeIds = balanceTypes.value.pluck('id').all();

                return BalanceTypeModel.query()
                    .where(query2 => BalanceTypeModel.activeOrganizationBalanceTypeUnarchivedScope(query2))
                    .whereDoesntHave('balanceAllocations', query => query.whereIn('id', allocationIds))
                    .whereNotIn('id', balanceTypeIds as string[]);
            });

            onMounted(async () => {
                loading.value = true;
                // If contract exist, get all of its relations.
                if (props.contract.exists) {
                    contractType.value = await contractTypeRelation.value.fresh();
                    job.value = await jobRelation.value.fresh();
                    await organizationsRelation.value.fresh();
                    await allocationRelation.value.fresh();
                }

                loading.value = false;
            });

            function addOrganizationOnStaffAndContract(organization) {
                addStaffInOrganization(organization);
                addOrganization(organization);
            }

            /**
             * BalanceType
             */
            const addBalanceType = function (balanceType: BalanceTypeModel) {
                const matchingBalanceType = balanceTypes.value.first(item => item.id === balanceType.id);
                if (!matchingBalanceType) {
                    balanceTypes.value.push(balanceType);
                }
            };

            async function saveAndCloseDialog() {
                const saved = await save(
                    props.staff,
                    contractCopy.value,
                    trialPeriods.value.toArray(),
                    allocations.value
                );

                if (saved) {
                    emit('saved', contractCopy.value);
                    dialog.value?.hide();
                }
            }

            async function saveBeforeDownloadTemplate(downloadTemplateCallback: {done: () => void}) {
                const saved = await save(
                    props.staff,
                    contractCopy.value,
                    trialPeriods.value.toArray(),
                    allocations.value
                );

                if (saved) {
                    emit('saved', contractCopy.value);
                    downloadTemplateCallback.done();
                }
            }

            return {
                isSaving,
                addPeriod,
                deletePeriod,
                trialPeriodsRelation,
                activeOrganization,
                addOrganization,
                deleteOrganization,
                addStaffInOrganization,
                addContractType,
                addOrganizationOnStaffAndContract,
                addJob,
                saveAndCloseDialog,
                saveBeforeDownloadTemplate,
                addBalanceType,
                roundFourNumberParserOptions,
                allocationRelation,
                balanceTypes,
                excludeBalanceType,
                allocations,
                selectedBalanceType,
                jobName,
                job,
                modelsForDownload,
                organizationSelected,
                legacyNursery,
                user,
                contractType,
                contractCopy,
                loading,
                organizationsRelation,
                contractTypeRelation,
                stateErrors,
                contractErrors,
                dialog,
                disableEditing,
                can,
            };
        },
    });
</script>
