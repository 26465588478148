import {Model} from '@meekohq/lumos';
import type RulesetModel from '@/modules/calendar/models/RulesetModel';
import PaymentMethodModel from '@/modules/cashier/models/PaymentMethodModel';

export default class PaymentSchedulerRuleModel extends Model {
    public type = 'cashier/payment_scheduler_rules';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        payment_scheduler_id: string | undefined;
        payment_method_id: string | null;
        ruleset_id: string | undefined;
        amount: number | undefined;
        percentage: boolean | undefined;
        percentage_on: string;
        end_of_month: boolean;
        index: number | undefined;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
        id: this.uuid(),
        tenant_id: undefined,
        payment_scheduler_id: undefined,
        payment_method_id: null,
        ruleset_id: undefined,
        amount: undefined,
        percentage: false,
        percentage_on: 'total',
        end_of_month: false,
        index: undefined,
        created_at: undefined,
        updated_at: undefined,
    };

    get ruleset(): RulesetModel {
        return this.getRelation<RulesetModel>('ruleset');
    }

    set ruleset(ruleset: RulesetModel) {
        this.setRelation<RulesetModel>('ruleset', ruleset);
    }

    paymentMethod() {
        return this.belongsTo('paymentMethod', PaymentMethodModel, 'payment_method_id');
    }
}
