<template>
    <ResourceFinder
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-variant="buttonVariant"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :value="value"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @input="onChange"
        @search="search = $event"
    >
        <template #button-text-empty>
            <slot name="button-text-empty">
                {{ __('hr_balance:select_balance') }}
            </slot>
        </template>

        <template #button-text="{value}">
            {{ value.attributes.name }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{
                    __('components:count_item', {
                        count: values.length - 1,
                        value: values[0].attributes.name,
                    })
                }}
            </slot>
        </template>

        <template #default="{option, searchValue}">
            <div
                class="tw-flex tw-truncate"
                v-html="highlight(searchValue, option.attributes.name)"
            />
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type {QueryBuilder} from '@meekohq/lumos';
    import {collect, type Model} from '@meekohq/lumos';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import useManager from '@/modules/app/composables/useManager';

    export default defineComponent({
        components: {ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            value: {type: [Object, Array] as PropType<Model | Model[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
            organizations: {type: [Array] as PropType<OrganizationModel[]>},
        },
        emits: ['input'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function (value) {
                emit('input', value);
            };
            // !!! END: DO NOT EDIT !!

            const {activeOrganization} = useManager();

            const queryBuilder = computed(() => {
                let ids: string[] = [];

                if (props.organizations) {
                    ids = collect(props.organizations)
                        .keyBy(m => m.getKey())
                        .keys()
                        .all();
                } else {
                    ids = [activeOrganization.value.getKey()];
                }

                const builder = BalanceTypeModel.query()
                    .orderBy('name')
                    .whereLike('name', `*${search.value}*`)
                    .whereHas('organizations', query => {
                        query.whereIn('id', ids);
                    });

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            const onFallback = function () {
                //
            };

            return {
                search,
                onChange,
                onFallback,
                queryBuilder,
                ...useHighlighter(),
            };
        },
    });
</script>
