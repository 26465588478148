import type {Ref} from 'vue';
import {ref, watch} from 'vue';
import {Epoch} from '@meekohq/lumos';
import injectableKey from '@/modules/app/utils/injectableKey';
import {isNil} from 'lodash-es';
import useMCalendarUtils from '@/modules/meeko-ui/components/MCalendar/useMCalendarUtils';
import type {DateTimePeriod, DateTimeSingle, DateTimeValue} from '@/modules/meeko-ui/types/DateTime';
import type {DateTimeModifiers} from '@/modules/meeko-ui/composables/useDateTimeModifiers';

export type MCalendarMode =
    | 'time' // Only time (ex: hh:mm)
    | 'dateTime' // Full date with time (ex: dd/mm/yyyy hh:mm)
    | 'date' // Only date (ex: dd/mm/yyyy)
    | 'month' // Only month (ex: mm/yyyy)
    | 'year'; // Only year (ex: yyyy)

interface UseMCalendarSimple {
    isPeriod: false;
    value: Ref<DateTimeSingle>;
}

interface UseMCalendarPeriod {
    isPeriod: true;
    value: Ref<DateTimePeriod>;
}

export type UseMCalendar = UseMCalendarSimple | UseMCalendarPeriod;

export const keyMCalendarState = injectableKey<ReturnType<typeof useMCalendarState>>();

export default function useMCalendarState(
    userValue: UseMCalendar,
    mode: MCalendarMode,
    allowUndefined: boolean,
    modifiers: DateTimeModifiers | undefined
) {
    const {cloneUserValue, isUserValueEqualsToInternalValue} = useMCalendarUtils();

    const internalValue = ref(cloneUserValue(userValue)) as Ref<DateTimePeriod>;

    function setDate(date: DateTimeValue) {
        if (userValue.isPeriod) {
            setDatesPeriod(date);

            return;
        }

        setDatesSingle(date);
    }

    function setDatesSingle(date: DateTimeValue) {
        if (isNil(date) && !allowUndefined) {
            throw new Error('date cannot be null or undefined when allowUndefined is false.');
        }

        if (!isNil(date) && !(date instanceof Epoch)) {
            throw new Error('date must be an Epoch instance when calendar is not in period mode.');
        }

        internalValue.value = {
            from: date,
            to: undefined,
        };
    }

    function setDatesPeriod(date: DateTimeValue) {
        if (isNil(date) || date instanceof Epoch) {
            throw new Error('date must be an object with "from" and "to" keys when calendar is in period mode.');
        }

        if (isNil(date.from) && isNil(date.to) && !allowUndefined) {
            throw new Error('date.from and date.to cannot be null or undefined when allowUndefined is false.');
        }

        if (!isNil(date.from) && !((date.from as unknown) instanceof Epoch)) {
            throw new Error('date.from must be an Epoch instance when calendar is in period mode.');
        }

        if (!isNil(date.to) && !((date.to as unknown) instanceof Epoch)) {
            throw new Error('date.to must be an Epoch instance when calendar is in period mode.');
        }

        internalValue.value = {
            from: date.from,
            to: date.to,
        };
    }

    watch(userValue.value, () => {
        if (!isUserValueEqualsToInternalValue(userValue, internalValue.value)) {
            internalValue.value = cloneUserValue(userValue);
        }
    });

    return {
        mode,
        isPeriod: userValue.isPeriod,
        allowUndefined,
        internalValue,
        modifiers,

        cloneUserValue,

        setDate,
    };
}
