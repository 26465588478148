import type PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
import type {UpdatePersonalAccessTokenNameRequest} from '@/modules/auth/personal-access-token/application/UpdatePersonalAccessTokenRequest';
import {Binding} from '@meekohq/lumos';

export const UpdatePersonalAccessTokenUseCaseBinding = new Binding<UpdatePersonalAccessTokenUseCase>();

/**
 * Update a personal access token's name. Requires sudo mode.
 */
export interface UpdatePersonalAccessTokenUseCase {
    updateName(request: UpdatePersonalAccessTokenNameRequest): Promise<PersonalAccessTokenModel>;
}
