<template>
    <CPopover
        ref="dropdownRef"
        :clickable="!disabled"
        content-class="tw-min-w-56"
        no-padding
    >
        <MButton
            align="left"
            class="tw-w-full"
            :class="[buttonClass]"
            :disabled="disabled"
            icon-sort
            :size="buttonSize"
            :truncate="truncate"
            :variant="hasError ? 'outline-danger' : variant"
        >
            <template v-if="selectedOptionText && !forceFallback">
                {{ selectedOptionText }}
            </template>
            <template v-else>
                <slot name="fallback">
                    {{ fallbackText ?? __('common:actions.select') }}
                </slot>
            </template>
        </MButton>
        <template #content>
            <CFinder
                ref="finder"
                :funnel="funnel"
                :has-unselect="hasUnselect"
                :list-class="listClass"
                :max-height="maxHeight"
                :multi="multi"
                :options="options"
                :search-bar="searchBar"
                :search-on="[valuePath, textPath]"
                :unselect-value="unselectValue"
                :value="selectedOption"
                @input="onSelectedOption"
                @keydown.tab="$refs.dropdownRef.hide()"
            >
                <template
                    v-if="$slots['unselect-item']"
                    #unselect-item
                >
                    <slot
                        v-if="hasUnselect"
                        name="unselect-item"
                    >
                        {{ __('common:actions.unselect') }}
                    </slot>
                </template>
                <template #default="{option}">
                    {{ get(option, textPath) }}
                    <div
                        v-if="get(option, subTextPath)"
                        class="tw-mt-1 tw-line-clamp-4 tw-whitespace-normal tw-text-sm tw-text-gray-500"
                        v-html="get(option, subTextPath)"
                    />
                </template>
            </CFinder>
        </template>
    </CPopover>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import {find, get, head, isArray, isEqual} from 'lodash-es';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {},
        props: {
            id: {type: String},
            options: {type: Array, required: true, default: () => []},
            value: {},
            forceFallback: {type: Boolean, default: false},
            fallbackText: {type: String, default: undefined},
            hasError: {type: Boolean, default: false},
            hasUnselect: {type: Boolean, default: false},
            unselectValue: {default: undefined},
            truncate: {type: Boolean, default: true},
            valuePath: {type: String, default: 'value'},
            textPath: {type: String, default: 'text'},
            subTextPath: {type: String, default: undefined},
            listClass: {type: String, default: ''},
            searchBar: {type: Boolean, default: true},
            funnel: {type: Boolean, default: false},
            variant: {type: String},
            maxHeight: {type: String},
            multi: {type: Boolean, default: false},
            popoverClass: {},
            buttonClass: {},
            disabled: {type: Boolean, default: false},
            buttonSize: {default: 'base'},
        },
        setup(props, {emit}) {
            const selectedOption = ref<unknown>(props.unselectValue);

            const selectedOptionText = computed(() => {
                if (props.multi && isMultiValue.value) {
                    const values = selectedOption.value as any[];

                    if (values.length) {
                        return __('components:count_item', {
                            count: values.length - 1,
                            value: get(head(values), props.textPath),
                        });
                    }

                    return null;
                }

                return get(selectedOption.value, props.textPath);
            });

            const isMultiValue = computed(() => isArray(selectedOption.value) && props.multi);

            const resetSelectionOption = function (value, options) {
                if (props.multi) {
                    if (isArray(value)) {
                        const values: any[] = [];

                        for (const item of value) {
                            const match = find(options, option => isEqual(get(option, props.valuePath), item));
                            if (match) {
                                values.push(match);
                            }
                        }
                        selectedOption.value = values;
                    }
                } else {
                    selectedOption.value =
                        find(options, option => isEqual(get(option, props.valuePath), value)) ?? props.unselectValue;
                }
            };

            watch(
                () => props.value,
                value => {
                    resetSelectionOption(value, props.options);
                },
                {immediate: true}
            );

            watch(
                () => props.options,
                options => {
                    resetSelectionOption(props.value, options);
                }
            );

            const dropdownRef = ref();

            const onSelectedOption = function (value) {
                if (props.multi) {
                    if (isArray(value)) {
                        emit(
                            'input',
                            value.map(item => get(item, props.valuePath))
                        );
                    } else {
                        emit('input', value);
                    }
                } else {
                    dropdownRef.value?.hide();
                    emit('input', get(value, props.valuePath));
                }
            };

            return {
                selectedOption,
                selectedOptionText,
                dropdownRef,
                onSelectedOption,
                isArray,
                get,
            };
        },
    });
</script>
