<template>
    <div class="EditInvoiceDetailsEditor">
        <EditInvoiceDetailsHeader
            :invoice="invoice"
            :with-tva="withTva"
        />
        <div class="EditInvoiceDetailsEditor__list">
            <EditInvoiceDetailsEditorLine
                v-for="(line, i) in invoice.lines"
                :key="line.id"
                :currency="currency"
                :grand-total="grandTotal"
                :line="line"
                :nursery="nursery"
                :with-tva="withTva"
                @removeLine="removeLine(i)"
            />
        </div>
        <div class="EditInvoiceDetailsEditor__total-container">
            <MButton
                class="EditInvoiceDetailsEditor__add-line-button"
                variant="link"
                @click="addLine"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-plus-circle" />
                </template>
                {{ __('common:actions.add_line') }}
            </MButton>
            <div class="EditInvoiceDetailsEditor__total-item-container">
                <div class="EditInvoiceDetailsEditor__total-item">
                    {{ __('billing_invoice:total_with_vat') }}
                </div>
                <div class="EditInvoiceDetailsEditor__total-item">
                    {{ grandTotal }}
                </div>
            </div>
        </div>
        <CFormErrorMessageList
            v-if="errors.has('grand_total')"
            class="tw-mt-10"
            :errors="errors.get('grand_total')"
        />
        <CFormErrorMessageList
            v-if="errors.has('line')"
            class="tw-mt-10"
            :errors="errors.get('line')"
        />
    </div>
</template>

<script lang="ts">
    import {defineComponent, type PropType, watch} from 'vue';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import EditInvoiceDetailsEditorLine from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceDetailsEditorLine.vue';
    import EditInvoiceDetailsHeader from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceDetailsHeader.vue';
    import type {ErrorReturnType} from '@/modules/app/composables/useError';

    export default defineComponent({
        components: {EditInvoiceDetailsHeader, EditInvoiceDetailsEditorLine},
        mixins: [invoice],
        props: {
            invoice: {
                type: Object,
                required: true,
            },
            withTva: {
                type: Boolean,
                required: true,
            },
            grandTotal: {
                type: String,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            currency: {
                type: String,
                default: undefined,
            },
            errors: {
                type: Object as PropType<ErrorReturnType>,
                default: () => ({}),
            },
        },
        emits: ['updated'],
        setup(props, {emit}) {
            function addLine() {
                props.invoice.lines.push({
                    id: null,
                    name: '',
                    tva: props.nursery.invoice_tva,
                    price: 1,
                    qty: 1,
                    unit: 'hour',
                    type: 'custom',
                });
            }

            function removeLine(index) {
                props.invoice.lines.splice(index, 1);
            }

            watch(
                () => props.invoice.lines,
                () => {
                    emit('updated', props.invoice);
                },
                {deep: true}
            );

            return {
                addLine,
                removeLine,
            };
        },
    });
</script>

<style scoped>
    .EditInvoiceDetailsEditor__list {
        @apply tw-my-2 tw-flex tw-flex-col tw-gap-2 md:tw-gap-0;
    }

    .EditInvoiceDetailsEditor__total-container {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2;
        @apply tw-flex-wrap sm:tw-flex-nowrap;
    }

    .EditInvoiceDetailsEditor__add-line-button {
        @apply tw-shrink-0;
        @apply tw-mr-auto;
        @apply tw-w-full sm:tw-w-auto;
    }

    .EditInvoiceDetailsEditor__total-item-container {
        @apply tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-end tw-gap-2;
    }

    .EditInvoiceDetailsEditor__total-item {
        @apply tw-w-1/2 sm:tw-w-auto;
        @apply tw-rounded-lg tw-bg-blue-100 tw-px-2 tw-py-1;
        @apply tw-truncate tw-font-semibold tw-text-blue-800;
        @apply first:tw-text-center last:tw-text-right;
    }
</style>
