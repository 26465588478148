<template>
    <div>
        <transition
            v-if="selectedMedia"
            mode="out-in"
            name="fade"
        >
            <MMediaFullScreen
                v-if="selectedMedia"
                :selected-media="transformedMedia"
                @close="selectedMedia = undefined"
            />
        </transition>
        <div
            v-else
            id="transmission-clock"
            class="tw-absolute tw-cursor-pointer tw-text-center"
            :class="{hover: isHover}"
            :style="{left: 'calc(' + unixToPercent(summary.done_at) + '% - 25px)'}"
            @mouseenter="isHover = true"
            @mouseleave="isHover = false"
        >
            <div class="tw-relative">
                <div class="transmission-data">
                    <CHStack
                        v-if="isHover"
                        key="1"
                        class="tw-flex-wrap"
                        gap="2"
                    >
                        <CCenter class="tw-w-10">
                            <SummaryImg
                                :closing="closing"
                                :day="day"
                                line
                                :opening="opening"
                                :sum="summary"
                            />
                        </CCenter>
                        <div class="tw-flex-1">
                            <SummaryDescription
                                is-hover
                                :selected-media.sync="selectedMedia"
                                :sum="summary"
                            />
                        </div>
                    </CHStack>
                    <div
                        v-else
                        key="2"
                    >
                        <slot name="image">
                            <SummaryImg
                                :closing="closing"
                                :day="day"
                                line
                                :opening="opening"
                                small
                                :sum="summary"
                            />
                        </slot>
                    </div>
                </div>

                <div
                    class="clock tw-mx-auto tw-rounded-b-lg"
                    :class="{activities: summary.contentType === 'activities'}"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import SummaryImg from '@/modules/legacy/components/Modules/Transmission/SummaryImg.vue';
    import SummaryDescription from '@/modules/legacy/components/Modules/Transmission/SummaryDescription.vue';
    import MMediaFullScreen from '@/modules/meeko-ui/components/MMediaFullScreen.vue';

    export default {
        components: {
            MMediaFullScreen,
            SummaryDescription,
            SummaryImg,
        },
        props: ['day', 'opening', 'closing', 'summary'],
        data: () => ({
            isHover: false,
            selectedMedia: null,
        }),
        computed: {
            transformedMedia() {
                // On ecrase la valeur type de selectedMedia car le components générique de media attends le format de la video
                return {
                    ...this.selectedMedia,
                    type: this.selectedMedia.videoType,
                    taken_at: this.selectedMedia.done_at,
                };
            },
            moment() {
                return moment;
            },
        },
        watch: {
            selectedMedia(media) {
                this.selectedMedia = media;
            },
        },
        methods: {
            unixToPercent(unix) {
                const openingTimestamp = this.day
                    .clone()
                    .hours(moment.duration(this.opening).hours())
                    .minutes(moment.duration(this.opening).minutes())
                    .seconds(moment.duration(this.opening).seconds())
                    .unix();

                const closingTimestamp = this.day
                    .clone()
                    .hours(moment.duration(this.closing).hours())
                    .minutes(moment.duration(this.closing).minutes())
                    .seconds(moment.duration(this.closing).seconds())
                    .unix();

                return ((unix - openingTimestamp) * 100) / (closingTimestamp - openingTimestamp);
            },
        },
    };
</script>

<style scoped>
    .animated {
        animation-duration: 400ms;
    }

    #transmission-clock {
        z-index: 50;

        .transmission-data {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
        }

        &.hover {
            z-index: 100;

            .transmission-data {
                top: inherit;
                bottom: -25px;
                margin-left: -100px;
                width: 250px;
                @apply tw-rounded-lg tw-bg-white tw-p-3 tw-shadow-md tw-ring-1 tw-ring-black/20 tw-transition-colors tw-transition-shadow tw-ease-in-out;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    margin-bottom: -10px;
                    margin-left: -10px;
                    border: 10px solid transparent;
                    border-bottom: 0;
                    border-top-color: #407fae;
                    width: 0;
                    height: 0;
                    content: '';
                }
            }
        }
    }

    #transmission-clock {
        top: -45px;
        width: 50px;
        height: 80px;

        .clock {
            position: absolute;
            top: 40px;
            right: 0;
            left: 0;
            z-index: 21;
            background-color: #407fae;
            width: 2px;
            height: 30px;
            pointer-events: none;

            &:before {
                position: absolute;
                top: -4px;
                left: -3px;
                border-radius: 100px;
                background-color: #407fae;
                width: 8px;
                height: 8px;
                content: '';
            }
        }
    }
</style>
