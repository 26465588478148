<template>
    <div class="EditInvoiceInformationsAttributes">
        <CForm gap="2">
            <CFormGroup
                v-if="invoice.kid_name !== null"
                class="EditInvoiceInformationsAttributes__form-container"
                :class="{'EditInvoiceInformationsAttributes__form-container--is-col-on-responsive': editable}"
            >
                <template v-if="editable && can('update', 'invoices')">
                    <CInput
                        v-model="invoice.kid_name"
                        class="tw-w-full"
                    />
                    <CFormDatepicker
                        v-model="invoice.kid_birthdate"
                        class="tw-w-full"
                        format="YYYY-MM-DD"
                    />
                </template>
                <template v-else>
                    <CText
                        class="tw-truncate"
                        font-weight="semibold"
                    >
                        {{ $capitalize(invoice.kid_name) }}
                    </CText>
                    <CText
                        v-if="invoice.kid_birthdate"
                        font-weight="semibold"
                    >
                        ({{ Epoch.fromISOString(invoice.kid_birthdate).toLocaleString(Epoch.presets.DATE_SHORT) }})
                    </CText>
                </template>
            </CFormGroup>

            <CFormGroup
                v-if="invoice.allocator_no"
                class="EditInvoiceInformationsAttributes__form-container"
            >
                <CLabel class="EditInvoiceInformationsAttributes__form-label">
                    {{ __('billing_invoice:beneficiary_number_colon') }}
                </CLabel>
                <CText>
                    {{ invoice.allocator_no }}
                </CText>
            </CFormGroup>

            <CFormGroup class="EditInvoiceInformationsAttributes__form-container">
                <CLabel class="EditInvoiceInformationsAttributes__form-label">
                    {{ __('billing_invoice:invoice_number_colon') }}
                </CLabel>
                <CInput
                    v-if="allows('support') && editable"
                    v-model="invoice.no"
                />
                <CText v-else>
                    <template v-if="invoice.id === null">
                        {{ $refToDate(nursery.proforma_ref) }}{{ nursery.proforma_num }}
                    </template>
                    <template v-else>
                        {{ invoice.no }}
                    </template>
                </CText>
            </CFormGroup>

            <CFormGroup class="EditInvoiceInformationsAttributes__form-container">
                <CLabel class="EditInvoiceInformationsAttributes__form-label">
                    {{ __('billing_core:contract_number_colon') }}
                </CLabel>
                <CText>
                    <template v-if="invoice.contract_no">
                        {{ invoice.contract_no }}
                    </template>
                    <template v-else> / </template>
                </CText>
            </CFormGroup>
            <CFormGroup
                v-if="allows('support') && editable"
                class="EditInvoiceInformationsAttributes__form-container"
            >
                <CLabel class="EditInvoiceInformationsAttributes__form-label">
                    {{ __('billing_core:contract_id_colon') }}
                </CLabel>
                <CInput v-model="invoice.contract_id" />
            </CFormGroup>
            <CFormGroup
                v-if="invoice.purchase_order_number"
                class="EditInvoiceInformationsAttributes__form-container"
            >
                <CLabel class="EditInvoiceInformationsAttributes__form-label">
                    {{ __('billing_invoice:order_form_colon') }}
                </CLabel>
                <CText>
                    {{ invoice.purchase_order_number }}
                </CText>
            </CFormGroup>

            <div v-if="invoice.type !== 'custom'">
                <CFormGroup
                    class="EditInvoiceInformationsAttributes__form-container"
                    :class="{'EditInvoiceInformationsAttributes__form-container--is-col-on-responsive': editable}"
                >
                    <CLabel class="EditInvoiceInformationsAttributes__form-label">
                        {{ __('billing_invoice:period') }}
                    </CLabel>
                    <div
                        v-if="editable && can('update', 'invoices')"
                        class="EditInvoiceInformationsAttributes__period-picker-container"
                    >
                        {{ __('common:date_from') }}
                        <CFormDatepicker
                            v-model="invoice.start_period_date"
                            class="tw-w-full"
                            format="YYYY-MM-DD"
                        />
                        {{ __('billing_invoice:to') }}
                        <CFormDatepicker
                            v-model="invoice.end_period_date"
                            class="tw-w-full"
                            format="YYYY-MM-DD"
                        />
                    </div>
                    <CText v-else>
                        {{
                            __('common:date_format.human_from_to', {
                                from: Epoch.fromISOString(invoice.start_period_date).toLocaleString(
                                    Epoch.presets.DATE_SHORT
                                ),
                                to: Epoch.fromISOString(invoice.end_period_date).toLocaleString(
                                    Epoch.presets.DATE_SHORT
                                ),
                            })
                        }}
                    </CText>
                </CFormGroup>
            </div>
        </CForm>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useAbility from '@/modules/app/composables/useAbility';
    import {Epoch} from '@meekohq/lumos';

    export default defineComponent({
        mixins: [invoice, nursery],
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            invoice: {
                type: Object,
                required: true,
            },
            isNew: {
                type: Boolean,
                default: false,
            },
            editable: {
                type: Boolean,
                required: true,
            },
        },

        setup() {
            const {can, allows} = useAbility();

            return {
                Epoch,
                can,
                allows,
            };
        },
    });
</script>

<style scoped>
    .EditInvoiceInformationsAttributes {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .EditInvoiceInformationsAttributes__form-container {
        @apply tw-flex tw-flex-row tw-items-center tw-gap-2;
        @apply tw-min-w-0;
    }

    .EditInvoiceInformationsAttributes__form-container--is-col-on-responsive {
        @apply tw-flex-col tw-items-start sm:tw-flex-row sm:tw-items-center;
    }

    .EditInvoiceInformationsAttributes__form-label {
        @apply tw-shrink-0;
    }

    .EditInvoiceInformationsAttributes__period-picker-container {
        @apply tw-flex tw-w-full tw-flex-row tw-items-center tw-gap-1;
    }
</style>
