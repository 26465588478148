import type {Ref} from 'vue';
import moment from 'moment';
import {collect} from '@meekohq/lumos';
import type EventModel from '@/modules/human-resources/models/EventModel';
import {OpeningHourValue} from '@/modules/human-resources/utils/calendar/OpeningHourValue';
import useManager from '@/modules/app/composables/useManager';
import AllEventsTime from '@/modules/human-resources/utils/calendar/Services/AllEventsTime';

interface IEventTimes {
    real: number;
    forecast: number;
    total: number;
}

export default class CalculateEventTime {
    private readonly events: EventModel[];
    private readonly organization: Ref<any>;

    public constructor(events: EventModel[]) {
        this.events = events;
        this.organization = useManager().legacyNursery;
    }

    public getEventsTimeByCalendar(calendarId: string): IEventTimes {
        return this.calculateTime(
            collect(this.events)
                .filter(event => event.eventType().value().attributes.calendar_id === calendarId)
                .toArray()
        );
    }

    public getEventsTimeByType(eventTypeId: string): IEventTimes {
        return this.calculateTime(
            collect(this.events)
                .filter(event => event.attributes.type_id === eventTypeId)
                .toArray()
        );
    }

    public getEventsTime(): IEventTimes {
        return this.calculateTime(this.events);
    }

    protected getOpeningHours(): OpeningHourValue[] {
        const openingHours: OpeningHourValue[] = [];

        for (const openingHour of this.organization.value.openingHours) {
            openingHours.push(
                new OpeningHourValue({
                    day: openingHour.day,
                    open: moment(openingHour.started_at, 'HH:mm:ss'),
                    close: moment(openingHour.ended_at, 'HH:mm:ss'),
                })
            );
        }

        return openingHours;
    }

    private calculateTime(events: EventModel[]): IEventTimes {
        const {legacyNursery} = useManager();

        // Get active organization opening hours
        const openingHours = this.getOpeningHours();

        // Get separating forecast and real events
        const forecastEvents = collect(events)
            .filter(event => event.attributes.forecast === true)
            .toArray();
        const realEvents = collect(events)
            .filter(event => event.attributes.forecast === false)
            .toArray();

        // Get forecast working time
        const forecastWorkingTime = new AllEventsTime(openingHours, legacyNursery.value.timezone);
        forecastWorkingTime.setEvents(forecastEvents as EventModel[]);

        const forecast = forecastWorkingTime.getWorkingTime();

        // Get real working time
        const realWorkingTime = new AllEventsTime(openingHours, legacyNursery.value.timezone);
        realWorkingTime.setEvents(realEvents as EventModel[]);

        const real = realWorkingTime.getWorkingTime();

        return {
            forecast,
            real,
            total: forecast + real,
        };
    }
}
