<template>
    <MModal
        :header-title="modalTitle"
        :loading="isLoading"
        :modal="modal"
        size="3xl"
    >
        <TransactionModalContent
            :can-select-customer="canSelectCustomer"
            :invoice="invoice"
            :payment="payment"
            :transaction-state="transactionState"
        />
        <template #footer-end="{hide}">
            <TransactionModalFooter
                :transaction-state="transactionState"
                @hide="hide"
            />
        </template>
    </MModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, onBeforeMount, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import {loadTransactionsRelationships} from '@/modules/cashier/utils/issuing/transaction/loadTransactionsRelationships';
    import useTransactionState, {
        makeEditionTransactionState,
    } from '@/modules/cashier/transaction/infrastructure/components/useTransactionState';
    import {ModelCollection} from '@meekohq/lumos';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import TransactionModalFooter from '@/modules/cashier/transaction/infrastructure/components/TransactionModalFooter.vue';
    import TransactionModalContent from '@/modules/cashier/transaction/infrastructure/components/TransactionModalContent.vue';

    export default defineComponent({
        components: {
            TransactionModalContent,
            TransactionModalFooter,
        },
        props: {
            canSelectCustomer: {
                type: Boolean,
                default: false,
            },
            invoice: {
                type: Object as PropType<LegacyInvoiceType>,
                required: false,
                default: undefined,
            },
            modal: {
                type: Object as PropType<ModalType>,
                required: true,
            },
            payment: {
                type: Object as PropType<PaymentModel>,
                required: false,
                default: undefined,
            },
            transactionId: {
                type: String,
                required: true,
            },
        },
        setup(props, {emit}) {
            // Get transaction state
            const transactionState = useTransactionState();
            const {bus, transaction} = transactionState;
            const isLoading = ref(false);

            const modalTitle = computed(() => {
                if (transaction.value?.isCredit) {
                    return __('billing_transaction:actions.edit_credit');
                }

                return __('billing_transaction:actions.edit_debit');
            });

            bus.on('saved', async (transactionModel: TransactionModel) => {
                // Here we load the relationships the exact same way as in the TransactionList
                await loadTransactionsRelationships(new ModelCollection<TransactionModel>([transactionModel]));

                props.modal.hide();
                // Then we emit the updated transaction with the relationships loaded
                emit('updated', transactionModel);
            });

            onBeforeMount(async () => {
                isLoading.value = true;
                await makeEditionTransactionState(transactionState, props.transactionId, props.payment?.attributes.id);
                isLoading.value = false;
            });

            return {
                bus,
                isLoading,
                modalTitle,
                transaction,
                transactionState,
            };
        },
    });
</script>
