import {Model, ModelCollection} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import JobsOrganizationsPivot from '@/modules/human-resources/models/JobOrganizationPivot';
import ContractModel from '@/modules/human-resources/models/ContractModel';

export default class JobModel extends Model {
    public type = 'hr/jobs';

    public attributes: {
        id: string;
        account_id: string | undefined;
        internal_id: string | undefined;
        name: string | undefined;
        archived_at: string | undefined;
        created_at: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        internal_id: undefined,
        name: undefined,
        archived_at: undefined,
        created_at: undefined,
    };

    contracts() {
        return this.hasMany('contracts', ContractModel, 'job_id');
    }

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            JobsOrganizationsPivot,
            'job_id',
            'organization_id'
        );
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', JobsOrganizationsPivot, 'job_id');
    }

    async attachToOrga(organizationModel: OrganizationModel) {
        this.attributes.account_id = organizationModel.attributes.account_id;
        await this.save();
        const pivot = new JobsOrganizationsPivot();
        pivot.attributes.account_id = this.attributes.account_id;
        pivot.attributes.organization_id = organizationModel.id;
        pivot.job().associate(this);
        await pivot.save();
        const relations = this.organizationsPivots().value().all();
        this.organizationsPivots().set(new ModelCollection(relations.concat([pivot])));

        return pivot;
    }
}

export enum jobName {
    intern = 'ff228e1a-64cf-44b6-af20-cb8393d538f4',
}
