import type {Ref} from 'vue';
import {computed, ref, watch} from 'vue';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import ContractOrganizationPivot from '@/modules/human-resources/models/ContractOrganizationPivot';
import type JobModel from '@/modules/human-resources/models/JobModel';
import type ContractTypeModel from '@/modules/human-resources/models/ContractTypeModel';
import type ContractModel from '@/modules/human-resources/models/ContractModel';

export default function (contract: Ref<ContractModel>) {
    const contractType = ref<ContractTypeModel>();
    const job = ref<JobModel>();

    // Create computed of each relation needed for contract.
    const contractTypeRelation = computed(() => contract.value.contractType());
    const jobRelation = computed(() => contract.value.job());
    const organizationsRelation = computed(() => contract.value.organizations());

    const addOrganization = function (organization: OrganizationModel) {
        // Get the matching organization in contract's organization relation.
        const matchingOrganization: OrganizationModel = organizationsRelation.value
            .value()
            .first(item => item.id === organization.id);
        // If already exist, reset it's pivot to not delete it on save.
        if (matchingOrganization) {
            matchingOrganization.pivot<ContractOrganizationPivot>().markedForDeletion = false;
            matchingOrganization.pivot<ContractOrganizationPivot>().listeners.delete = [];
        } else {
            // If don't exist, create, attach and push the pivot in contract's organization relation.
            const pivot = new ContractOrganizationPivot();
            pivot.attributes.account_id = organization.attributes.account_id;
            pivot.organization().associate(organization, false);

            organizationsRelation.value.value().push(organization);
            organization.setRelation('pivot', pivot);
        }
    };

    const deleteOrganization = function (organization: OrganizationModel) {
        // Get the matching organization in contract's organization relation.
        const matchingModel = organizationsRelation.value.value().first(item => item.id === organization.id);

        const deleteOrganizationInCollection = function (organizationToDelete: OrganizationModel) {
            // Mutate contract's organization relations by deleting the organization passed in parameters.
            organizationsRelation.value.mutate(value => value.reject(item => item.id === organizationToDelete.id));
        };
        // If the pivot of the matchingModel exist, we set the pivot which will be deleted when saving.
        if (matchingModel.pivot().exist) {
            matchingModel.pivot().markForDeletion();
            // Push the callback in delete listener to execute it when the pivot will be deleted.
            matchingModel.pivot().on('delete', () => deleteOrganizationInCollection(matchingModel));
        } else {
            deleteOrganizationInCollection(matchingModel);
        }
    };

    const addJob = function (model: JobModel) {
        contract.value.job().associate(model);
    };

    const addContractType = function (model: ContractTypeModel) {
        contract.value.contractType().associate(model);
    };

    watch(
        contract,
        value => {
            if (value.exists) {
                // Get contract's organization relation if contract exist in database.
                organizationsRelation.value.load();
            }
        },
        {immediate: true}
    );

    return {
        job,
        jobRelation,
        addJob,
        addContractType,
        contractType,
        contractTypeRelation,
        organizationsRelation,
        addOrganization,
        deleteOrganization,
    };
}
