import {Model} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';

export default class KidsGroupModel extends Model {
    public type = 'organization/kids_groups';

    public attributes: {
        id: string;
        account_id: string | undefined;
        organization_id: string | undefined;
        name: string | undefined;
        places: number | undefined;
        rules: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        organization_id: undefined,
        name: undefined,
        places: undefined,
        rules: undefined,
    };

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }
}
