<template>
    <div>
        <p class="tw-mb-0 tw-text-base tw-text-gray-600">
            <span class="tw-font-semibold">{{ meetingStatus }} : {{ notification.data.meeting_name }}</span
            >.
        </p>
        <p class="tw-mb-0 tw-mt-1 tw-text-sm">{{ formatDate(notification.data.meeting_started_at) }} {{ duration }}</p>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import moment from 'moment';
    import {readableHoursFromDates} from '@/modules/legacy/libs/planning/planning';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            notification: {type: Object, required: true},
        },
        setup(props) {
            const {activeOrganization} = useManager();

            const meetingStatus = computed(() => {
                const statusLabel = {
                    created: __('activity:invitation_to_a_meeting'),
                    updated: __('activity:meeting_updated'),
                    deleted: __('activity:meeting_state_canceled'),
                };

                return statusLabel[props.notification.data.event_type];
            });

            function formatDate(date) {
                return moment
                    .utc(date)
                    .tz(activeOrganization.value.attributes.timezone as string)
                    .format('DD/MM/YYYY');
            }

            const duration = computed(() => {
                return readableHoursFromDates(
                    moment
                        .utc(props.notification.data.meeting_started_at)
                        .tz(activeOrganization.value.attributes.timezone as string),
                    moment
                        .utc(props.notification.data.meeting_ended_at)
                        .tz(activeOrganization.value.attributes.timezone as string)
                );
            });

            return {
                duration,
                activeOrganization,
                meetingStatus,
                formatDate,
            };
        },
    });
</script>
