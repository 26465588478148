import type {Ref} from 'vue';
import {computed, onMounted, onUnmounted, ref, watch} from 'vue';
import moment from 'moment';
import _concat from 'lodash-es/concat';
import _orderBy from 'lodash-es/orderBy';
import ManagerStore from '@/modules/legacy/store/manager.store';
import {collect, ModelCollection, MqlTransaction} from '@meekohq/lumos';
import {EventBus} from '@/modules/legacy/utils/bus';
import {daysBetweenDates, getClosing, getOpening, minutesToHours} from '@/modules/legacy/libs/planning/planning';
import useEventTemplate from '@/modules/human-resources/composables/calendar/useEventTemplate';
import TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import EventModel from '@/modules/human-resources/models/EventModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import TemplatedWorkingTime from '@/modules/human-resources/utils/calendar/Services/TemplatedWorkingTime';
import useAuth from '@/modules/app/composables/useAuth';
import __ from '@/modules/app/utils/i18n-facade';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import useApplyTemplate from '@/modules/human-resources/apply-template/infrastructure/components/useApplyTemplate';
import {WeeksDto} from '@/modules/human-resources/apply-template/application/dto/WeeksDto';
import useEventWithError from '@/modules/human-resources/composables/calendar/useEventWithError';
import usePromises from '@/modules/app/composables/usePromises';
import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import {calendarTypes} from '@/modules/human-resources/models/CalendarModel';
import {useFeatureFlag} from '@/modules/app/composables/useFeatureFlag';
import useMagicModal from '@/modules/app/composables/useMagicModal';

export default function (
    staff: StaffModel,
    defaultPlannings: Ref<EventModel[] | undefined> = ref(undefined),
    firstWeek: Ref<number | undefined> = ref(undefined),
    autoSave: boolean | undefined = true
) {
    onMounted(() => {
        EventBus.$on('calendar:staff:update:templateEvent', (planning: TemplateEventModel) => {
            editEvent(planning);
        });
    });
    onUnmounted(() => {
        EventBus.$off('calendar:staff:update:templateEvent');
    });

    const organization = computed(() => {
        return ManagerStore.legacyNursery;
    });
    const {legacyUser: user} = useAuth();

    const opening = computed(() => {
        const hoursFromPlannings = events.value.map(item =>
            moment().startOf('day').add(item.attributes.start_time, 'seconds').format('HH:mm:ss')
        );

        return getOpening(organization.value.openingHours, false, false, hoursFromPlannings);
    });
    const closing = computed(() => {
        const hoursFromPlannings = events.value.map(item =>
            moment()
                .startOf('day')
                .add(item.attributes.start_time! + item.attributes.timelapse!, 'seconds')
                .format('HH:mm:ss')
        );

        return getClosing(organization.value.openingHours, false, false, hoursFromPlannings);
    });
    const exactOpening = computed(() => {
        return getOpening(organization.value.openingHours, false, true, []);
    });
    const exactClosing = computed(() => {
        return getClosing(organization.value.openingHours, false, true, []);
    });
    const daysOfWeek = computed(() => {
        return daysBetweenDates(moment().startOf('week'), moment().endOf('week'), organization.value.openingHours);
    });

    const calendarLoading = ref(false);

    /* MANAGE WEEK TEMPLATES */

    const weekTemplates = ref<TemplatePlanningModel[]>([]);
    const filteredWeekTemplates = computed(() => {
        return _orderBy(weekTemplates.value, template => {
            return template.attributes.name;
        });
    });
    const selectedWeekTemplate: Ref<TemplatePlanningModel | null> = ref(null);
    const weekTemplatesLoading = ref(false);

    function getWeekTemplates(resetWeeks = true, selectedStaff = staff) {
        weekTemplatesLoading.value = true;
        resetWeeks ? (weekTemplates.value = []) : null;

        const indexQuery = TemplatePlanningModel.query()
            .with(new TemplatePlanningModel().staff())
            .with(new TemplatePlanningModel().template())
            .where('staff_id', '=', selectedStaff.getKey());

        indexQuery
            .get()
            .then(response => {
                if (resetWeeks) {
                    weekTemplates.value = collect(response).all() as TemplatePlanningModel[];
                } else {
                    const newWeekTemplates = collect(response).all() as TemplatePlanningModel[];
                    weekTemplates.value = _concat(newWeekTemplates, weekTemplates.value);
                }

                if (filteredWeekTemplates.value.length) {
                    selectedWeekTemplate.value = filteredWeekTemplates.value[0] as TemplatePlanningModel;
                } else {
                    selectedWeekTemplate.value = null;
                }
                weekTemplatesLoading.value = false;
            })
            .catch(() => {
                weekTemplatesLoading.value = false;
            });
    }

    watch(selectedWeekTemplate, async () => {
        calendarLoading.value = true;

        await selectedWeekTemplate.value
            ?.planningEvents()
            .setQuery(query => {
                query.orderBy('start_time');
                query.with(new TemplateEventModel().eventType());
                query.with(new TemplateEventModel().kidsGroup());
            })
            .fresh();

        await selectedWeekTemplate.value?.staff().load();

        calendarLoading.value = false;
    });

    function importTemplatesFromStaff(otherStaff: StaffModel) {
        getWeekTemplates(false, otherStaff);
    }

    const modal = ref();

    function onShown() {
        getWeekTemplates();
    }

    function onHide() {
        deleteRealEvents.value = false;
    }

    function newWeekTemplate() {
        useMagicModal().confirmationWithInputModal({
            title: __('hr_calendar:new_workweek'),
            text: __('hr_calendar:week_can_then_be_applied_for_year'),
            confirmButtonText: __('common:actions.save'),
            placeholderText: __('hr_calendar:week_name'),
            onConfirm: async result => {
                if (result) {
                    let newWeek = new TemplatePlanningModel();
                    newWeek.attributes.name = result;
                    newWeek.attributes.account_id = `${user.value.account_id}`;
                    newWeek.attributes.staff_id = staff ? `${staff.getKey()}` : null;

                    await newWeek.save().then(response => {
                        newWeek = response as TemplatePlanningModel;
                        weekTemplates.value.push(newWeek);
                        selectedWeekTemplate.value = newWeek;
                        useNotification().success(__('hr_calendar:workweek_added'));
                    });
                }
            },
        });
    }

    function saveWeekTemplate() {
        useMagicModal().confirmationWithInputModal({
            title: __('hr_calendar:update_workweek'),
            confirmButtonText: __('common:actions.save'),
            placeholderText: __('hr_calendar:week_name'),
            inputValue: selectedWeekTemplate.value!.attributes.name,
            onConfirm: async result => {
                if (result) {
                    selectedWeekTemplate.value!.attributes.name = result;

                    await selectedWeekTemplate.value?.save().then(() => {
                        useNotification().success(__('hr_calendar:workweek_updated'));
                    });
                }
            },
        });
    }

    function removeWeekTemplate() {
        useMagicModal().deleteConfirmationModal({
            title: __('hr_calendar:delete_workweek'),
            text: __('hr_calendar:delete_workweek_confirmation'),
            onConfirm: async () => {
                selectedWeekTemplate.value?.delete().then(() => {
                    const weekToRemove = weekTemplates.value.find(
                        item => item.id === selectedWeekTemplate.value?.getKey()
                    );

                    if (weekToRemove) {
                        const index = weekTemplates.value.indexOf(weekToRemove);
                        weekTemplates.value.splice(index, 1);
                    }

                    if (filteredWeekTemplates.value.length) {
                        selectedWeekTemplate.value = filteredWeekTemplates.value[0] as TemplatePlanningModel;
                    } else {
                        selectedWeekTemplate.value = null;
                    }

                    useNotification().success(__('hr_calendar:workweek_deleted_successfully'));
                });
            },
        });
    }

    function fillPlanningsWithCurrentWeek() {
        useMagicModal().confirmationModal({
            title: __('hr_calendar:use_current_week'),
            text: __('hr_calendar:use_current_week_explanation'),
            type: 'warning',
            onConfirm: async () => {
                calendarLoading.value = true;
                // Transform EventModel into TemplateEventModel from first week
                const currentWeekPlannings: TemplateEventModel[] = [];
                defaultPlannings.value?.forEach((item: EventModel) => {
                    if (
                        moment(item.attributes.datetime_event?.started_at).week() === firstWeek.value &&
                        !item.isFullDay &&
                        item.attributes.forecast
                    ) {
                        currentWeekPlannings.push(planningIntoPlanningTemplate(item));
                    }
                });

                const mqlRunner = new MqlTransaction();
                // Remove all TemplateEventModel from selectedWeek
                deleteEvents(mqlRunner);
                // Add all plannings
                createTemplateEvents(currentWeekPlannings, mqlRunner);

                await mqlRunner
                    .run()
                    .then(() => {
                        selectedWeekTemplate.value?.planningEvents().set(new ModelCollection());
                        currentWeekPlannings.forEach(templateEvent => events.value.push(templateEvent));
                    })
                    .finally(() => {
                        calendarLoading.value = false;
                    });
            },
        });
    }

    const createTemplateEvents = function (templateEvents: TemplateEventModel[], mqlRunner: MqlTransaction) {
        templateEvents.forEach(value => {
            value.save({mqlRunner});
        });
    };

    /* USE WEEK TEMPLATE */

    const selectedWeeks = ref<any[]>([]);
    const saveLoading = ref(false);
    const deleteRealEvents = ref(false);
    const {invalidEventErrors, applyTemplatePlanning} = useApplyTemplate();

    async function useWeek() {
        if (useFeatureFlag('enable-new-template-service').value === false) {
            useLegacyWeek();

            return;
        }

        if (selectedWeeks.value.length === 0 || !selectedWeekTemplate.value) {
            return;
        }

        saveLoading.value = true;

        const weeks = selectedWeeks.value.map(week => {
            return `${week.date.year()}-${week.date.isoWeek()}`;
        });

        applyTemplatePlanning(
            selectedWeekTemplate.value,
            new WeeksDto(weeks),
            deleteRealEvents.value,
            organization.value.id
        )
            .then(() => {
                EventBus.$emit('calendar:staffs:refresh', true);

                if (invalidEventErrors.getAll().isEmpty()) {
                    modal.value?.hide();
                }
            })
            .finally(() => {
                saveLoading.value = false;
            });
    }

    /*****************************************/
    /********* LEGACY APPLY TEMPLATE *********/
    /*****************************************/

    const {failedEvents, getEventsWithError} = useEventWithError();

    function useLegacyWeek() {
        if (selectedWeeks.value.length === 0) {
            return;
        }

        saveLoading.value = true;
        const eventsToCreate: EventModel[] = [];

        selectedWeeks.value.forEach(week => {
            // Get days for selected week
            const daysForWeek = daysBetweenDates(
                week.date,
                week.date.clone().endOf('week'),
                organization.value.openingHours
            );
            daysForWeek.forEach(day => {
                // Get planningsTemplates for each day
                const planningTemplates = events.value.filter(item => item.attributes.day === day.day());
                planningTemplates.forEach(planningTemplate => {
                    // Transform planningTemplate into EventModel
                    const event = planningTemplateIntoPlanning(planningTemplate, day);
                    eventsToCreate.push(event);
                });
            });
        });

        deleteEventsFromWeeks(selectedWeeks.value)
            .then(() => {
                createEvents(eventsToCreate)
                    .then(() => {
                        saveLoading.value = false;
                        // Prevent closing modal if events are on error
                        EventBus.$emit('calendar:staffs:refresh', true);
                        if (failedEvents.value.isEmpty()) {
                            modal.value?.hide();
                        }
                    })
                    .catch(() => {
                        saveLoading.value = false;
                    });
            })
            .catch(() => {
                saveLoading.value = false;
            });
    }

    async function deleteEventsFromWeeks(selectedWeeks: any[]) {
        const eventRemoveQuery = EventModel.query();
        const allocationRemoveQuery = BalanceAllocationModel.query();

        if (staff) {
            eventRemoveQuery.where('staff_id', '=', `${staff.id}`);
        }

        eventRemoveQuery.whereHas('type', query1 => {
            query1.whereDoesntHave(new EventTypeModel().calendar(), query2 => {
                query2.where('internal_id', '=', calendarTypes.absence);
            });
        });

        if (!deleteRealEvents.value) {
            eventRemoveQuery.where('forecast', '=', true);
        }

        const event = new EventModel();

        eventRemoveQuery
            .where(query1 => {
                selectedWeeks.forEach(week => {
                    const startOfWeek = week.date.clone();
                    const endOfWeek = week.date.clone().endOf('week');

                    // TODO : VueModel -> merging scope bug
                    // eventRemoveQuery.scope('inRange', [startOfWeek, endOfWeek]);
                    query1.orWhere(query2 => {
                        event.scopeInPeriod(query2, [startOfWeek, endOfWeek]);
                    });
                });
            })
            .where(subquery => {
                subquery.whereDoesntHave(new EventModel().balanceAllocations(), query2 => {
                    query2.whereNotNull('confirmed_at');
                });
            });

        const mqlRunner = new MqlTransaction();

        // Delete allocations for events, run them in a single transaction to rollback if one fails
        allocationRemoveQuery.whereNull('confirmed_at').whereHas('event', query => {
            // We constrain the confirmed_at to be null to keep them for the reports
            query.inject(eventRemoveQuery);
        });

        allocationRemoveQuery.delete({mqlRunner});
        eventRemoveQuery.delete({mqlRunner});

        return await mqlRunner.run(true);
    }

    async function createEvents(events: EventModel[]) {
        const promises: Promise<EventModel>[] = [];

        events.forEach(value => {
            promises.push(value.save());
        });

        const {rejected} = await usePromises(promises);
        getEventsWithError(rejected.value);

        return events;
    }

    /* EVENTS */

    const events = computed(() => {
        return selectedWeekTemplate.value?.planningEvents().value().all() || [];
    });

    const totalWeekPlannings = computed(() => {
        return totalHours(events.value as TemplateEventModel[]);
    });

    function totalPlannings(day) {
        return totalHours(events.value as TemplateEventModel[], day.day());
    }

    function totalHours(plannings: TemplateEventModel[], day: number | null = null) {
        // Init workingTimeInPeriod
        const templatedWorkingTime = new TemplatedWorkingTime();
        templatedWorkingTime.setEvents(plannings);
        templatedWorkingTime.setDay(day);

        return minutesToHours(templatedWorkingTime.getWorkingTime());
    }

    function planningIntoPlanningTemplate(planning: EventModel): TemplateEventModel {
        const planningTemplate = new TemplateEventModel();

        const startTime =
            moment(planning.attributes.datetime_event?.started_at).hours() * 3600 +
            moment(planning.attributes.datetime_event?.started_at).minutes() * 60;
        const timelapse = moment(planning.attributes.datetime_event?.ended_at).diff(
            planning.attributes.datetime_event?.started_at,
            'seconds'
        );

        planningTemplate.attributes.account_id = planning.attributes.account_id;
        planningTemplate.attributes.organization_id = planning.attributes.organization_id;
        planningTemplate.attributes.kids_group_id = planning.attributes.kids_group_id;
        planningTemplate.attributes.planning_template_id = selectedWeekTemplate.value?.getKey();
        planningTemplate.attributes.type_id = planning.attributes.type_id;
        planningTemplate.attributes.day = moment(planning.attributes.datetime_event?.started_at).day();
        planningTemplate.attributes.week = 1;
        planningTemplate.attributes.start_time = startTime;
        planningTemplate.attributes.timelapse = timelapse;
        planningTemplate.attributes.note = planning.attributes.note;
        planningTemplate.attributes.supervise_kid = planning.attributes.supervise_kid;
        planningTemplate.attributes.forecast = planning.attributes.forecast;
        planningTemplate.eventType().associate(planning.eventType().value());

        return planningTemplate;
    }

    function planningTemplateIntoPlanning(planningTemplate: TemplateEventModel, day: any = null): EventModel {
        const planning = new EventModel();

        planning.attributes.account_id = planningTemplate.attributes.account_id;
        planning.attributes.staff_id = staff ? staff.getKey() : null;
        planning.attributes.organization_id = planningTemplate.attributes.organization_id;
        planning.attributes.kids_group_id = planningTemplate.attributes.kids_group_id;
        planning.attributes.type_id = planningTemplate.attributes.type_id;
        planning.attributes.datetime_event = {
            started_at: planningTemplate.startedAt.format(),
            ended_at: planningTemplate.endedAt.format(),
        };
        planning.attributes.date_event = null;
        planning.attributes.note = planningTemplate.attributes.note;
        planning.attributes.supervise_kid = planningTemplate.attributes.supervise_kid;
        planning.attributes.forecast = planningTemplate.attributes.forecast;
        planning.eventType().associate(planningTemplate.eventType().value());
        planning.attributes.factor = planning.eventType().value().factor;

        if (planningTemplate.exists) {
            planning.attributes.template_event_id = planningTemplate.getKey();
        }

        if (day) {
            planning.attributes.datetime_event.started_at = day
                .clone()
                .startOf('day')
                .add(planningTemplate.attributes.start_time, 'seconds')
                .format();
            planning.attributes.datetime_event.ended_at = day
                .clone()
                .startOf('day')
                .add(planningTemplate.attributes.start_time! + planningTemplate.attributes.timelapse!, 'seconds')
                .format();
        }

        return planning;
    }

    function resetEvents() {
        useMagicModal().deleteConfirmationModal({
            title: __('hr_calendar:reset_week'),
            text: __('hr_calendar:reset_week_confirmation'),
            onConfirm: async () => {
                calendarLoading.value = true;
                const mqlRunner = new MqlTransaction();
                deleteEvents(mqlRunner);
                await mqlRunner
                    .run()
                    .then(() => {
                        selectedWeekTemplate.value?.planningEvents().set(new ModelCollection());
                    })
                    .finally(() => {
                        calendarLoading.value = false;
                    });
            },
        });
    }

    function deleteEvents(mqlRunner: MqlTransaction) {
        const planningsToRemove = selectedWeekTemplate.value?.planningEvents()!.value()?.all();
        planningsToRemove?.forEach(planning => {
            planning.delete({mqlRunner});
        });
    }

    const {selectedEvent, modalEditEvent, newEvent, addEvent, onEventAdded, editEvent, onEventEdited, onEventDeleted} =
        useEventTemplate(
            exactOpening,
            exactClosing,
            selectedWeekTemplate as Ref<TemplatePlanningModel>,
            null,
            autoSave
        );

    return {
        modal,
        onShown,
        onHide,

        opening,
        closing,
        exactOpening,
        exactClosing,
        daysOfWeek,
        calendarLoading,

        weekTemplates,
        filteredWeekTemplates,
        selectedWeekTemplate,
        weekTemplatesLoading,
        getWeekTemplates,
        importTemplatesFromStaff,
        newWeekTemplate,
        saveWeekTemplate,
        removeWeekTemplate,
        fillPlanningsWithCurrentWeek,

        selectedWeeks,
        saveLoading,
        deleteRealEvents,
        invalidEventErrors,
        failedEvents,
        useWeek,

        events,
        totalWeekPlannings,
        totalPlannings,
        planningIntoPlanningTemplate,
        planningTemplateIntoPlanning,
        resetEvents,

        selectedEvent,
        modalEditEvent,
        newEvent,
        addEvent,
        onEventAdded,
        editEvent,
        onEventEdited,
        onEventDeleted,
    };
}
