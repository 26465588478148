<template>
    <CModal
        id="useTemplate"
        ref="modal"
        :header-title="__('hr_calendar:replace_weeks_by_week_template_colon')"
        size="7xl"
        :visible="true"
        @hidden="onHidden"
        @shown="onShown"
    >
        <loader
            v-if="weekTemplatesLoading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <template v-else-if="selectedTemplate || filteredTemplates.length">
            <div
                v-if="!filteredTemplates.length"
                class="tw-p-3"
            >
                <h5 class="h5 tw-mb-0 tw-text-center">
                    {{ __('hr_calendar:no_week_template') }}
                </h5>
            </div>
            <div
                v-else
                class="tw-mb-2 tw-flex tw-flex-wrap"
            >
                <div class="tw-flex tw-w-full tw-flex-wrap">
                    <CFormGroup class="tw-mb-3 tw-w-full sm:tw-w-1/3">
                        <MMenu class="tw-w-full">
                            <MMenuButton class="tw-w-full">
                                <MButton
                                    class="tw-w-full"
                                    icon-menu
                                >
                                    {{ selectedTemplate.attributes.name }}
                                </MButton>
                            </MMenuButton>
                            <MMenuItems>
                                <MMenuItem
                                    v-for="(weekTemplate, i) in filteredTemplates"
                                    :key="'weekTemplate' + i"
                                    @click="selectedTemplate = weekTemplate"
                                >
                                    <template #icon>
                                        <FontAwesomeIcon
                                            v-if="selectedTemplate.id === weekTemplate.id"
                                            fixed-width
                                            icon="fa-solid fa-circle-check"
                                        />
                                        <FontAwesomeIcon
                                            v-else
                                            fixed-width
                                            icon="fa-regular fa-circle"
                                        />
                                    </template>
                                    {{ weekTemplate.attributes.name }}
                                </MMenuItem>
                            </MMenuItems>
                        </MMenu>
                    </CFormGroup>
                </div>
            </div>

            <loader
                v-if="calendarLoading"
                light="true"
                shadow="false"
                size="sm"
                :title="__('hr_calendar:loading_week_template_dots')"
            />

            <div
                v-else
                class="tw-mt-2 tw-rounded-lg tw-border tw-border-gray-200 tw-p-3"
            >
                <div class="tw-mb-3 tw-text-center tw-text-sm tw-text-gray-600">
                    {{ __('hr_calendar:update_typical_week_before_applying_colon') }}
                </div>

                <loader
                    v-if="calendarLoading"
                    light="true"
                    shadow="false"
                    size="sm"
                    :title="__('common:loading_dots')"
                />
                <div
                    v-else
                    class="tw-pt-3"
                >
                    <div class="tw-flex">
                        <div class="tw-mt-4 tw-w-24">
                            <div class="tw-h-9" />
                            <div
                                v-for="(planningTemplate, i) in planningsTemplates"
                                :key="'planning_template_staff' + i"
                                class="tw-mb-1 tw-flex tw-h-9 tw-w-full tw-items-center"
                            >
                                <StaffFinder
                                    :allowed-organizations="selectedTemplateOrganizations"
                                    class="tw-w-full"
                                    :excluded-ids="
                                        planningTemplate.staff().value()
                                            ? [planningTemplate.staff().value().getKey()]
                                            : null
                                    "
                                    @fallback="switchStaffPlanning(planningTemplate)"
                                    @input="switchStaffPlanning(planningTemplate, $event)"
                                >
                                    <template #fallback>
                                        {{ __('hr_calendar:not_allocated') }}
                                    </template>
                                    <template #trigger="{toggle}">
                                        <MPopover
                                            class="tw-w-full"
                                            placement="top-start"
                                            popover-button-class="tw-h-full tw-w-full tw-rounded hover:tw-bg-gray-50 tw-border tw-border-dashed tw-border-transparent hover:tw-border-blue-200 tw-cursor-pointer tw-p-1"
                                            trigger="hover"
                                            @click.native="toggle"
                                        >
                                            <div
                                                v-if="planningTemplate.staff().value()"
                                                class="tw-flex tw-h-full tw-items-center"
                                            >
                                                <StaffAvatar
                                                    class="tw-mr-1 tw-w-6 tw-shrink-0"
                                                    :staff-model="planningTemplate.staff().value()"
                                                />
                                                <div
                                                    class="tw-truncate tw-text-base"
                                                    :class="planningTemplate.staff().value().genderColor"
                                                >
                                                    {{ planningTemplate.staff().value().fullname }}
                                                </div>
                                            </div>
                                            <div
                                                v-else
                                                class="tw-flex tw-h-full tw-items-center"
                                            >
                                                <div
                                                    class="tw-mr-1 tw-flex tw-h-6 tw-w-6 tw-shrink-0 tw-items-center tw-rounded-full tw-bg-gray-300"
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-w-full tw-text-center tw-text-sm tw-text-gray-500"
                                                        icon="fa-solid fa-question"
                                                    />
                                                </div>
                                                <div class="tw-truncate tw-text-base tw-text-gray-600">N/A</div>
                                            </div>
                                            <template #content>
                                                <div class="tw-capitalize">
                                                    <div
                                                        v-if="planningTemplate.staff().value()"
                                                        :class="planningTemplate.staff().value().genderColor"
                                                    >
                                                        {{ planningTemplate.staff().value().fullname }}
                                                    </div>
                                                    <div
                                                        v-else
                                                        class="tw-text-gray-600"
                                                    >
                                                        {{ __('hr_calendar:not_allocated') }}
                                                    </div>
                                                </div>
                                                <div class="tw-my-2">
                                                    <EventTotals
                                                        :contract="
                                                            planningTemplate.staff().value() &&
                                                            planningTemplate.staff().value().activeContract()
                                                                ? `${planningTemplate.staff().value().activeContract().attributes.hours_per_week}h`
                                                                : null
                                                        "
                                                        :forecast="totalHours(planningTemplate)"
                                                        :real="null"
                                                    />
                                                </div>
                                                <div class="tw-mt-1 tw-flex tw-border-t tw-border-gray-200 tw-pt-2">
                                                    <MButton
                                                        class="tw-mr-2"
                                                        size="xs"
                                                        variant="link"
                                                        @click.stop="toggle"
                                                    >
                                                        <FontAwesomeIcon
                                                            class="tw-mr-1"
                                                            icon="fa-solid fa-retweet"
                                                        />{{ __('common:actions.change') }}
                                                    </MButton>
                                                    <MButton
                                                        size="xs"
                                                        variant="link-danger"
                                                        @click.stop="confirmRemovePlanningTemplate(planningTemplate)"
                                                    >
                                                        <FontAwesomeIcon
                                                            class="tw-mr-1"
                                                            icon="fa-solid fa-trash-alt"
                                                        />
                                                        {{ __('common:actions.delete_dots') }}
                                                    </MButton>
                                                </div>
                                            </template>
                                        </MPopover>
                                    </template>
                                </StaffFinder>
                            </div>
                        </div>

                        <div class="tw-flex tw-w-full tw-justify-between tw-overflow-x-scroll">
                            <div
                                v-for="(dayOfWeek, dayIndex) in daysOfWeek"
                                :key="'currentDay' + dayIndex"
                                class="showDayHoursTrigger tw-ml-3 tw-w-full tw-min-w-48"
                            >
                                <div class="tw-relative tw-h-5">
                                    <div class="showDay tw-w-full tw-text-center tw-capitalize tw-text-blue-500">
                                        {{ dayOfWeek.format('dddd') }}
                                    </div>
                                </div>

                                <div class="tw--ml-2 tw-mt-1 tw-h-7 tw-pr-3.5">
                                    <Hours
                                        class="showDayHours"
                                        :closing="closing(dayOfWeek, true)"
                                        grid-on-hover
                                        :opening="opening(dayOfWeek, true)"
                                        simple
                                        small
                                    />
                                </div>

                                <div
                                    v-for="(planningTemplate, i) in planningsTemplates"
                                    :key="'planning_template' + i"
                                    class="tw-mb-1 tw-flex tw-h-9 tw-items-center"
                                >
                                    <PlanningGenerator
                                        always-show-ghost
                                        :auto-save="false"
                                        :closing="closing(dayOfWeek, true)"
                                        create-with-modal
                                        :day="dayOfWeek"
                                        is-template
                                        :opening="opening(dayOfWeek, true)"
                                        :show-clock="false"
                                        :show-divider="false"
                                        show-note
                                        size="md"
                                        :template-events="eventsForCurrentDay(dayOfWeek, planningTemplate)"
                                        :week-template="planningTemplate.id"
                                        @pushEventTemplate="
                                            planningTemplate.planningEvents().value().all().push($event)
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <StaffFinder
                        :allowed-organizations="selectedTemplateOrganizations"
                        class="tw-mt-3 tw-w-full"
                        :excluded-ids="staffFinderExceptionIds"
                        @fallback="addPlanningTemplate"
                        @input="addPlanningTemplate"
                    >
                        <template #trigger="{toggle}">
                            <CVStack
                                align="center"
                                class="tw-group tw-cursor-pointer tw-rounded-xl tw-border-2 tw-border-dashed tw-border-gray-300 tw-py-1 tw-transition-all tw-duration-300 hover:tw-border-blue-500 hover:tw-bg-blue-100"
                                distribute="center"
                                @click="toggle"
                            >
                                <CText
                                    class="tw-text-gray-700 group-hover:tw-text-black"
                                    font-weight="semibold"
                                >
                                    <template v-if="planningTemplateLoading">
                                        <FontAwesomeIcon
                                            class="tw-mr-2 tw-text-blue-500"
                                            icon="fa-solid fa-circle-notch"
                                            spin
                                        />
                                        {{ __('common:adding_in_progress_dots') }}
                                    </template>
                                    <template v-else>
                                        <FontAwesomeIcon
                                            class="tw-mr-2 tw-text-blue-500"
                                            icon="fa-duotone fa-plus-circle"
                                        />
                                        {{ __('common:actions.add_line') }}
                                    </template>
                                </CText>
                            </CVStack>
                        </template>
                        <template #fallback>
                            {{ __('hr_calendar:not_allocated') }}
                        </template>
                    </StaffFinder>
                </div>
            </div>

            <div class="tw-mb-3 tw-mt-9 tw-text-base tw-font-semibold tw-text-gray-600">
                {{ __('hr_calendar:replace_weeks_by_week_template_colon') }}
            </div>

            <SelectWeeks :selected-weeks.sync="selectedWeeks" />
            <hr class="hr" />
            <CHStack>
                <CCheckbox v-model="deleteRealEvents">
                    {{ __('hr_calendar:delete_real_events_in_selected_weeks') }}
                </CCheckbox>
            </CHStack>

            <EventModal
                v-if="selectedEvent"
                ref="modalEditEvent"
                :event="selectedEvent"
                is-template
                soft-update
                @add="onEventAdded"
                @delete="onEventDeleted"
                @edit="onEventEdited"
                @hidden="selectedEvent = null"
            />
        </template>
        <div
            v-else
            class="tw-rounded-md tw-bg-blue-100 tw-p-5 tw-text-center tw-text-blue-500"
        >
            <div class="tw-mb-3">
                {{ __('hr_calendar:no_week_templates') }}
            </div>
            <div class="tw-mb-3">
                <MButton
                    :icon-external-link="true"
                    onclick="Intercom('showArticle', 5538054)"
                    size="none"
                    variant="link"
                >
                    {{ __('common:show_guide') }}
                </MButton>
            </div>
        </div>
        <div class="tw-mt-4">
            <FeatureFlag name="enable-new-template-service">
                <InvalidEventErrors :error-handler="invalidEventErrors" />
                <template #disabled>
                    <FailedEventsAlert :failed-events="failedEvents" />
                </template>
            </FeatureFlag>
        </div>
        <template #footer>
            <MButton
                class="tw-ml-auto"
                variant="light"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.cancel') }}
            </MButton>

            <MButton
                v-if="filteredTemplates.length"
                class="tw-ml-2"
                :loading="saveLoading"
                variant="primary"
                @click="!saveLoading ? useWeek() : null"
            >
                {{ __('hr_calendar:apply_week_template') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';
    import useGlobalWeekTemplate from '@/modules/human-resources/composables/calendar/useGlobalWeekTemplate';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import SelectWeeks from '@/modules/human-resources/components/calendar/CalendarTemplate/SelectWeeks.vue';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import FailedEventsAlert from '@/modules/human-resources/components/calendar/CalendarTemplate/FailedEventsAlert.vue';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import FeatureFlag from '@/modules/app/components/atoms/FeatureFlag.vue';
    import InvalidEventErrors from '@/modules/human-resources/apply-template/infrastructure/components/InvalidEventErrors.vue';

    export default defineComponent({
        components: {
            InvalidEventErrors,
            FeatureFlag,
            StaffAvatar,
            FailedEventsAlert,
            Hours,
            PlanningGenerator: Line,
            EventModal,
            EventTotals,
            StaffFinder,
            SelectWeeks,
        },
        setup(props, {emit}) {
            const {
                modal,
                onShown,
                onHide,

                calendarLoading,
                opening,
                closing,
                daysOfWeek,

                selectedWeeks,
                saveLoading,
                deleteRealEvents,
                failedEvents,
                invalidEventErrors,
                selectedTemplateOrganizations,
                useWeek,

                staffFinderExceptionIds,
                filteredTemplates,
                selectedTemplate,
                weekTemplatesLoading,

                planningsTemplates,
                planningTemplateLoading,
                addPlanningTemplate,
                switchStaffPlanning,
                confirmRemovePlanningTemplate,

                totalHours,
                eventsForCurrentDay,
                resetEvents,
                deleteEvents,
                selectedEvent,
                modalEditEvent,
                onEventAdded,
                onEventEdited,
                onEventDeleted,
            } = useGlobalWeekTemplate(ref(null), false);

            const onHidden = function () {
                onHide();
                emit('hidden');
            };

            return {
                modal,
                onShown,
                onHide,

                calendarLoading,
                opening,
                closing,
                daysOfWeek,

                selectedWeeks,
                saveLoading,
                deleteRealEvents,
                failedEvents,
                invalidEventErrors,
                selectedTemplateOrganizations,
                useWeek,

                staffFinderExceptionIds,
                filteredTemplates,
                selectedTemplate,
                weekTemplatesLoading,

                planningsTemplates,
                planningTemplateLoading,
                addPlanningTemplate,
                switchStaffPlanning,
                confirmRemovePlanningTemplate,

                totalHours,
                eventsForCurrentDay,
                resetEvents,
                deleteEvents,
                selectedEvent,
                modalEditEvent,
                onEventAdded,
                onEventEdited,
                onEventDeleted,

                onHidden,
            };
        },
    });
</script>

<style scoped>
    .showDayHours {
        @apply tw-opacity-0 tw-transition-all tw-duration-300 tw-ease-in-out;
    }

    .showDay {
        @apply tw-absolute tw-top-5 tw-transition-all tw-ease-in-out;
    }

    .showDayHoursTrigger:hover {
        .showDayHours {
            @apply tw-opacity-100;
        }

        .showDay {
            @apply tw-top-0;
        }
    }
</style>
