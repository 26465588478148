<template>
    <MTooltip
        :label="tooltip"
        placement="top-end"
    >
        <MButton
            :to="to"
            variant="gray"
        >
            <FontAwesomeIcon icon="fa-solid fa-cog" />
        </MButton>
    </MTooltip>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            tooltip: {
                type: String,
                default: () => __('common:settings'),
                required: false,
            },
            to: {
                type: Object,
                required: true,
            },
        },
    });
</script>
