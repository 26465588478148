<template>
    <MPopover class="AmountFilter">
        <MButton
            icon-menu
            :size="size"
        >
            <template #left-icons>
                <FontAwesomeIcon icon="fa-regular fa-money-bill-wave" />
            </template>
            {{ rangeName }}
        </MButton>
        <template #content>
            <div class="AmountFilter__title">
                {{ __('billing_transaction:select_amounts_range') }}
            </div>
            <CFormRangePicker
                :first-value="firstValue"
                :range-max="rangeMax"
                :range-min="rangeMin"
                :second-value="secondValue"
                :step="step"
                @change="updatePaymentAmountRange"
            />
            <div class="AmountFilter__actions">
                <MButton
                    @click="
                        $emit('update:firstValue', 0);
                        $emit('update:secondValue', resetValue);
                    "
                >
                    {{ __('billing_transaction:positive_amounts_only') }}
                </MButton>
                <MButton
                    @click="
                        $emit('update:firstValue', resetValue);
                        $emit('update:secondValue', 0);
                    "
                >
                    {{ __('billing_transaction:negative_amounts_only') }}
                </MButton>
                <MButton @click="resetPaymentAmountRange">
                    {{ __('common:actions.reset') }}
                </MButton>
            </div>
        </template>
    </MPopover>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import CFormRangePicker from '@/modules/coherence-ui/components/Forms/CFormRangePicker.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import {isNil} from 'lodash-es';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';

    export default defineComponent({
        components: {CFormRangePicker},
        props: {
            firstValue: {
                type: [] as PropType<number | null>,
                required: true,
            },
            secondValue: {
                type: [] as PropType<number | null>,
                required: true,
            },
            rangeMin: {
                type: Number,
                required: true,
            },
            rangeMax: {
                type: Number,
                required: true,
            },
            step: {
                type: Number,
                default: 100,
            },
            resetValue: {
                type: Array,
                default: null,
                required: false,
            },
            size: {
                type: String,
                default: 'base',
            },
            currency: {
                type: String,
                required: true,
            },
        },
        setup(props, {emit}) {
            function updatePaymentAmountRange({rangeMin, rangeMax}) {
                if (rangeMin !== props.firstValue) {
                    emit('update:firstValue', rangeMin);
                }
                if (rangeMax !== props.secondValue) {
                    emit('update:secondValue', rangeMax);
                }
            }

            function resetPaymentAmountRange() {
                emit('update:firstValue', props.resetValue);
                emit('update:secondValue', props.resetValue);
            }

            const rangeName = computed(() => {
                if (isNil(props.firstValue) && isNil(props.secondValue)) {
                    return __('common:amount_other');
                }

                return `${__('billing_transaction:amounts_with_currency_range', {
                    minValue: isNil(props.firstValue) ? '∞' : props.firstValue,
                    maxValue: isNil(props.secondValue) ? '∞' : props.secondValue,
                    currency: useFormatCurrency(props.currency).getSymbol(),
                })}`;
            });

            return {
                rangeName,
                updatePaymentAmountRange,
                resetPaymentAmountRange,
            };
        },
    });
</script>

<style scoped>
    .AmountFilter__title {
        @apply tw-text-sm tw-font-semibold tw-uppercase;
        @apply tw-text-gray-600;
        @apply tw-border-b tw-border-gray-200;
        @apply tw-mb-4 tw-pb-1;
    }

    .AmountFilter__actions {
        @apply tw-mt-4;
        @apply tw-flex tw-flex-col tw-gap-2;
    }
</style>
