<template>
    <CModal
        ref="modal"
        :header-title="__('hr:delete_events')"
        size="xl"
        @shown="onShown"
    >
        <CVStack gap="4">
            <CForm>
                <CFormTwoColumns>
                    <CFormGroup>
                        <CLabel>{{ __('common:date_from') }}</CLabel>
                        <CFormDatepicker v-model="fromIso8601" />
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('common:date_to') }}</CLabel>
                        <CFormDatepicker
                            v-model="toIso8601"
                            modifier="endOfDay"
                        />
                    </CFormGroup>
                </CFormTwoColumns>
                <CFormTwoColumns>
                    <CFormGroup v-if="organizationFilterEnabled">
                        <CLabel>{{ __('common:organization_other') }}</CLabel>
                        <OrganizationFinder
                            v-model="organizations"
                            button-class="tw-w-full"
                            :inject-query="organizationFinderQueryBuilder"
                            multi
                            multi-minimum="1"
                        />
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('hr:event_type') }}</CLabel>
                        <EventTypeFinder
                            v-model="eventTypes"
                            button-class="tw-w-full"
                            multi
                            :organizations="organizations"
                        />
                    </CFormGroup>
                </CFormTwoColumns>
                <CFormGroup v-if="groupsFilterEnabled">
                    <CLabel>{{ __('common:group_other') }}</CLabel>
                    <CFormTwoColumns>
                        <GroupFinder
                            v-model="groups"
                            button-class="tw-w-full"
                            multi
                        />
                        <CCheckbox v-model="nullableGroup">
                            {{ __('hr:include_events_without_groups') }}
                        </CCheckbox>
                    </CFormTwoColumns>
                </CFormGroup>
                <CFormGroup>
                    <CLabel>
                        {{ __('hr:delete_events_colon') }}
                    </CLabel>
                    <CButtonGroup>
                        <MButton
                            :variant="forecastNature === 'forecast' ? 'primary' : 'light'"
                            @click="forecastNature = 'forecast'"
                        >
                            {{ __('common:forecasted_male_other') }}
                        </MButton>
                        <MButton
                            :variant="forecastNature === 'real' ? 'primary' : 'light'"
                            @click="forecastNature = 'real'"
                        >
                            {{ __('common:real_other') }}
                        </MButton>
                        <MButton
                            :variant="forecastNature === 'both' ? 'primary' : 'light'"
                            @click="forecastNature = 'both'"
                        >
                            {{ __('common:all') }}
                        </MButton>
                    </CButtonGroup>
                </CFormGroup>
            </CForm>

            <div class="tw-mt-4">
                <div class="tw-mb-2 tw-flex tw-flex-wrap tw-items-baseline tw-justify-between tw-gap-2">
                    <MHeading level="h3">
                        {{ __('common:preview') }}
                    </MHeading>
                    <CText
                        v-if="!loading"
                        font-weight="semibold"
                        variant="danger"
                    >
                        {{ __('hr:count_events_will_be_deleted', {count: eventTypesPreviewTotal}) }}
                    </CText>
                </div>
                <CHStack
                    class="tw-w-full"
                    gap="1"
                >
                    <CCenter
                        v-if="eventTypesPreviewTotal === 0 || loading"
                        class="tw-h-12 tw-w-full tw-rounded-lg tw-bg-gray-200"
                    >
                        <CText
                            v-if="loading"
                            font-size="lg"
                        >
                            {{ __('common:loading_dots') }}
                        </CText>
                        <CText
                            v-else
                            font-size="lg"
                        >
                            {{ __('hr:no_item_to_delete') }}
                        </CText>
                    </CCenter>
                    <template
                        v-for="(value, index) in eventTypesPreview"
                        v-else
                    >
                        <CTooltip
                            :key="index"
                            class="tw-transition-all tw-duration-500 tw-ease-in-out"
                            style="min-width: 30px"
                            :style="[{width: (value.count * 100) / eventTypesPreviewTotal + '%'}]"
                        >
                            <template #content>
                                {{ value.name }}
                            </template>
                            <CCenter
                                class="tw-h-12 tw-w-full tw-rounded-lg tw-shadow-sm"
                                :style="value.style"
                            >
                                <CText
                                    font-size="lg"
                                    font-weight="semibold"
                                >
                                    {{ value.count }}
                                </CText>
                            </CCenter>
                        </CTooltip>
                    </template>
                </CHStack>
            </div>
        </CVStack>
        <template #footer>
            <MButton
                class="tw-ml-auto"
                :label="__('common:actions.close')"
                @click="$refs.modal.hide()"
            />
            <MButton
                class="tw-ml-2"
                :disabled="loading"
                :label="__('common:actions.delete_dots')"
                variant="danger"
                @click="deleteEvents"
            />
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import EventTypeFinder from '@/modules/human-resources/components/event-type/EventTypeFinder.vue';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import useManager from '@/modules/app/composables/useManager';
    import EventModel from '@/modules/human-resources/models/EventModel';
    import {collect, Epoch} from '@meekohq/lumos';
    import {debounce} from 'lodash-es';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import type {Moment} from 'moment';
    import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
    import GroupFinder from '@/modules/organization/components/GroupFinder.vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import useAuth from '@/modules/app/composables/useAuth';
    import __ from '@/modules/app/utils/i18n-facade';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';

    export default defineComponent({
        components: {GroupFinder, OrganizationFinder, EventTypeFinder},
        props: {
            staff: {type: Object as PropType<StaffModel>, required: true},
            from: {type: Object as PropType<Moment>},
            to: {type: Object as PropType<Moment>},
        },
        setup(props, {emit}) {
            const {fromISOString} = useEpoch();
            const {modal, show, hide, isVisible: modalIsVisible} = useLegacyModalBus();
            const {activeOrganization} = useManager();
            const loading = ref(true);
            const {legacyUser} = useAuth();

            const forecastNature = ref<'forecast' | 'real' | 'both'>('forecast');
            watch(forecastNature, () => getPreviewDebounced());

            /**
             * Dates
             */
            const fromIso8601 = ref();
            const toIso8601 = ref();

            watch(
                () => props.from,
                value => (fromIso8601.value = value?.toISOString()),
                {immediate: true}
            );
            watch(
                () => props.to,
                value => (toIso8601.value = value?.toISOString()),
                {immediate: true}
            );
            watch(fromIso8601, () => getPreviewDebounced());
            watch(toIso8601, () => getPreviewDebounced());

            /**
             * Organizations
             */
            const organizations = ref<OrganizationModel[]>([activeOrganization.value]);
            const organizationFilterEnabled = ref(false);
            const organizationsIds = computed(() =>
                collect(organizations.value)
                    .keyBy(m => m.getKey())
                    .keys()
                    .all()
            );
            const organizationFinderQueryBuilder = computed(() => OrganizationModel.query().scope('userOrganizations'));

            watch(organizations, async () => {
                await fetchEventTypes();
                await fetchGroups();
            });

            watch(activeOrganization, async value => (organizations.value = [value]));

            /**
             * Event Types
             */
            const eventTypes = ref<EventTypeModel[]>([]);
            const eventTypesIds = computed(() =>
                collect(eventTypes.value)
                    .keyBy(m => m.getKey())
                    .keys()
                    .all()
            );

            async function fetchEventTypes() {
                const query = await EventTypeModel.query()
                    .orderBy('name')
                    .whereNull('archived_at')
                    .whereHas(new EventTypeModel().organizationsPivots(), q => {
                        q.whereIn('organization_id', organizationsIds.value).whereNull('archived_at');
                    })
                    .get();

                eventTypes.value = query.toArray();
            }

            watch(eventTypes, () => getPreviewDebounced());

            /**
             * Groups
             */
            const groups = ref<KidsGroupModel[]>([]);
            const groupsFilterEnabled = ref(false);
            const groupsIds = computed(() =>
                collect(groups.value)
                    .keyBy(m => m.getKey())
                    .keys()
                    .all()
            );
            const nullableGroup = ref(true);

            async function fetchGroups() {
                const query = await KidsGroupModel.query()
                    .orderBy('name')
                    .whereIn('organization_id', organizationsIds.value)
                    .get();

                groupsFilterEnabled.value = query.isNotEmpty();
                groups.value = query.toArray();
            }

            watch(groups, () => getPreviewDebounced());
            watch(nullableGroup, () => getPreviewDebounced());

            /**
             * Deletion
             */
            const baseQuery = computed(() => {
                const query = EventModel.query()
                    .scope('inPeriod', [fromIso8601.value, toIso8601.value])
                    .where('staff_id', props.staff.getKey())
                    .whereIn('organization_id', organizationsIds.value)
                    .where(query1 => {
                        query1.where(query2 => {
                            if (groupsIds.value.length) {
                                query2.whereIn('kids_group_id', groupsIds.value);
                            }

                            if (nullableGroup.value) {
                                query2.orWhereNull('kids_group_id');
                            }
                        });
                    });

                if (forecastNature.value === 'forecast') {
                    query.where('forecast', true);
                } else if (forecastNature.value === 'real') {
                    query.where('forecast', false);
                }

                return query;
            });

            const deleteEvents = async function () {
                await useMagicModal().deleteConfirmationModal({
                    title: __('hr:delete_this_events'),
                    text: __('hr:delete_all_event_of_between_from_to', {
                        fullname: props.staff.fullname,
                        from: fromISOString(fromIso8601.value).toLocaleString(Epoch.presets.DATE_SHORT),
                        to: fromISOString(toIso8601.value).toLocaleString(Epoch.presets.DATE_SHORT),
                    }),
                    onConfirm: async () => {
                        const count = await baseQuery.value.clone().whereIn('type_id', eventTypesIds.value).delete();

                        useNotification().success(__('hr:items_deleted_successfully'));
                        hide();
                        emit('deleted', count);
                    },
                });
            };

            /**
             * Deletion Preview
             */
            const getEventTypeStyle = function (color) {
                if (color) {
                    return useEventStyle(ref(color), ref(false), 'md', true).badgeStyle.value;
                }

                return null;
            };

            const eventTypesPreview = ref<any[]>([]);
            const eventTypesPreviewTotal = computed(() => collect(eventTypesPreview.value).sum('count'));

            const getPreview = async function () {
                if (!modalIsVisible.value) {
                    return;
                }

                loading.value = true;

                if (eventTypes.value.length) {
                    const values: any[] = [];
                    for (const eventType of collect(eventTypes.value)
                        .sortBy(i => i.attributes.name)
                        .all()) {
                        const count = await baseQuery.value.clone().where('type_id', eventType.getKey()).count();

                        values.push({
                            name: eventType.attributes.name,
                            style: getEventTypeStyle(eventType.attributes.color),
                            count,
                        });
                    }
                    eventTypesPreview.value = values.filter(value => value.count > 0);

                    loading.value = false;
                }
            };

            const getPreviewDebounced = debounce(getPreview, 500);

            /**
             * Modal
             */
            const onShown = async function () {
                loading.value = true;
                organizationFilterEnabled.value = legacyUser.value.nurseries.length > 1;
                await fetchEventTypes();
                await fetchGroups();
                getPreviewDebounced();
            };

            return {
                Epoch,
                modal,
                loading,
                onShown,
                show,
                fromIso8601,
                toIso8601,
                organizations,
                organizationFinderQueryBuilder,
                organizationFilterEnabled,
                eventTypes,
                eventTypesPreview,
                eventTypesPreviewTotal,
                groups,
                nullableGroup,
                groupsFilterEnabled,
                deleteEvents,
                forecastNature,
                modalIsVisible,
            };
        },
    });
</script>
