<template>
    <MPopover
        class="MemberCard"
        no-padding
        popover-button-class="tw-w-full"
        trigger="hover"
    >
        <router-link
            :to="{
                name: 'familyMembers.show',
                params: {nursery: activeOrganization.getKey(), familyMember: memberModel.getKey()},
            }"
        >
            <div class="MemberCard__container">
                <MemberAvatar
                    class="MemberCard__avatar"
                    :member-model="memberModel"
                />
                <div
                    class="MemberCard__name"
                    :class="{'tw-text-boy': memberModel.isBoy, 'tw-text-girl': memberModel.isGirl}"
                >
                    {{ memberModel.fullname }}
                </div>
                <slot />
            </div>
        </router-link>
        <template #content>
            <MemberProfile :member-model="memberModel" />
        </template>
    </MPopover>
</template>

<script setup lang="ts">
    import MemberProfile from '@/modules/family/components/member/MemberProfile.vue';
    import type {PropType} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import MemberAvatar from '@/modules/app/components/atoms/avatars/MemberAvatar.vue';

    defineProps({
        memberModel: {
            type: Object as PropType<MemberModel>,
            required: true,
        },
    });

    const {activeOrganization} = useManager();
</script>

<style scoped>
    .MemberCard__container {
        @apply tw-relative tw-flex tw-items-center;
        @apply tw-cursor-pointer tw-rounded-lg tw-bg-white tw-p-3 tw-shadow hover:tw-scale-105;
        @apply tw-border tw-border-gray-200;
        @apply tw-transition tw-duration-150 tw-ease-out;
    }

    .MemberCard__avatar {
        @apply tw-mr-2 tw-min-w-10;
    }

    .MemberCard__name {
        @apply tw-truncate tw-font-display tw-text-base;
    }
</style>
