<template>
    <CForm>
        <div
            class="tw-grid tw-gap-4"
            :class="!selectedTag.exists ? 'tw-grid-cols-1 sm:tw-grid-cols-2' : 'tw-grid-cols-1'"
        >
            <CFormGroup v-if="!selectedTag.exists">
                <CLabel>
                    {{ __('tag:category_one') }}
                </CLabel>
                <MMenu class="tw-w-full">
                    <MMenuButton class="tw-w-full">
                        <MButton
                            class="tw-w-full"
                            icon-menu
                        >
                            <template
                                v-if="getCategoryByType(selectedTag.attributes.type)"
                                #left-icons
                            >
                                <FontAwesomeIcon :icon="getCategoryByType(selectedTag.attributes.type).icon" />
                            </template>
                            <template v-if="getCategoryByType(selectedTag.attributes.type)">
                                {{ getCategoryByType(selectedTag.attributes.type).name }}
                            </template>
                            <template v-else>
                                {{ __('tag:category_one') }}
                            </template>
                        </MButton>
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem
                            v-for="(cat, i) in categories"
                            :key="'selectCategory' + i"
                            :label="cat.name"
                            @click="selectCategory(cat)"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    :icon="cat.icon"
                                />
                            </template>
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>
            </CFormGroup>
            <MInputGroup
                v-model="selectedTag.attributes.name"
                :errors="errorHandler.getErrors('name')"
                :label="__('tag:tag_name')"
                :placeholder="__('tag:tag_name')"
            />
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-4">
            <CFormGroup>
                <CLabel>{{ __('common:icon') }}</CLabel>
                <TagSelector v-model="selectedTag.attributes" />
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('common:color') }}</CLabel>
                <ColorSwatches
                    v-model="selectedTag.attributes.color"
                    :colors="swatchesColors"
                />
            </CFormGroup>
        </div>
    </CForm>
</template>

<script lang="ts">
    import {defineComponent, type PropType} from 'vue';
    import type ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import type TagModel from '@/modules/tag/models/TagModel';
    import TagSelector from '@/modules/tag/components/TagSelector.vue';
    import ColorSwatches from '@/modules/app/components/ColorSwatches.vue';

    export default defineComponent({
        components: {
            ColorSwatches,
            TagSelector,
        },
        props: {
            selectedTag: {
                type: Object as PropType<TagModel>,
                required: true,
            },
            errorHandler: {
                type: Object as PropType<ErrorHandler>,
                required: true,
            },
            categories: {
                type: Array,
                required: true,
            },
            getCategoryByType: {
                type: Function,
                required: true,
            },
        },
        setup(props) {
            const swatchesColors = [
                '#f43f5e',
                '#ec4899',
                '#d946ef',
                '#a855f7',
                '#8b5cf6',
                '#6366f1',
                '#3b82f6',
                '#0ea5e9',
                '#06b6d4',
                '#14b8a6',
                '#10b981',
                '#22c55e',
                '#84cc16',
                '#eab308',
                '#f59e0b',
                '#f97316',
                '#1f2937',
                '#6b7280',
                '#d1d5db',
            ];

            function selectCategory(cat: {value: string}) {
                props.selectedTag.attributes.type = cat.value;
            }

            return {
                swatchesColors,
                selectCategory,
            };
        },
    });
</script>
