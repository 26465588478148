<template>
    <div class="md:tw-ml-2">
        <CHStack
            align="center"
            class="tw-flex-1 tw-rounded-md tw-bg-blue-50 md:tw-ml-52"
            gap="2"
        >
            <CText
                align="center"
                class="tw-w-full tw-px-1"
                ellipsis
                variant="primary"
            >
                {{ weekDays[0] }}
            </CText>
            <CText
                align="center"
                class="tw-w-full tw-px-1"
                ellipsis
                variant="primary"
            >
                {{ weekDays[1] }}
            </CText>
            <CText
                align="center"
                class="tw-w-full tw-px-1"
                ellipsis
                variant="primary"
            >
                {{ weekDays[2] }}
            </CText>
            <CText
                align="center"
                class="tw-w-full tw-px-1"
                ellipsis
                variant="primary"
            >
                {{ weekDays[3] }}
            </CText>
            <CText
                align="center"
                class="tw-w-full tw-px-1"
                ellipsis
                variant="primary"
            >
                {{ weekDays[4] }}
            </CText>
            <CText
                v-if="isOpenedSaturday"
                align="center"
                class="tw-w-full tw-px-1"
                ellipsis
                variant="primary"
            >
                {{ weekDays[5] }}
            </CText>
            <CText
                v-if="isOpenedSunday"
                align="center"
                class="tw-w-full tw-px-1"
                ellipsis
                variant="primary"
            >
                {{ weekDays[6] }}
            </CText>
        </CHStack>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import useOrganizationOpening from '@/modules/organization/composables/useOrganizationOpening';
    import moment from 'moment';
    import {EpochInfo} from '@meekohq/lumos';

    export default defineComponent({
        setup() {
            const weekDays = computed(() => EpochInfo.weekdays());

            const openingHours = useOrganizationOpening();
            const isOpenedSaturday = computed(() => openingHours.isOpenedOn(moment().day(6)));
            const isOpenedSunday = computed(() => openingHours.isOpenedOn(moment().day(0)));

            return {
                weekDays,
                isOpenedSaturday,
                isOpenedSunday,
            };
        },
    });
</script>
