<template>
    <MBox class="tw-p-4">
        <div>
            <div
                class="tw-flex tw-cursor-pointer tw-flex-wrap tw-items-center tw-justify-between tw-gap-2"
                @click="showCard = !showCard"
            >
                <div>
                    <MHeading>
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-primary-500"
                            icon="fa-solid fa-calendar-plus"
                        />
                        {{ __('common:additional_fees') }}
                    </MHeading>
                    <div class="tw-mt-1 tw-text-sm tw-text-gray-500">
                        {{ __('billing_setting:overruns_reservations_adaptations_absences') }}
                    </div>
                </div>
                <MButton
                    icon-menu
                    @click.stop="showCard = !showCard"
                >
                    <template v-if="showCard">
                        {{ __('common:actions.close') }}
                    </template>
                    <template v-else>
                        {{ __('common:actions.open') }}
                    </template>
                </MButton>
            </div>
            <div
                v-if="showCard"
                class="tw-mb-4"
            >
                <!-- OVERRUNS -->
                <div class="tw-pl-1">
                    <div class="tw-mt-4 tw-font-semibold tw-uppercase tw-text-primary-500">
                        {{ __('common:overrun_other') }}
                    </div>
                    <div class="form-row tw-mt-4">
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:bill_overruns_on_regular_childcare') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:are_overruns_billed') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MButtonSelect
                                v-model="config.overruns.recurrent.charged"
                                :options="booleanOptions"
                            />
                        </div>
                    </div>
                    <div
                        v-if="config.overruns.recurrent.charged"
                        class="form-row tw-mt-6"
                    >
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:what_is_your_hourly_rate') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:leave_blank') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-5 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MCurrencyInput
                                v-model="config.overruns.recurrent.amount"
                                allow-undefined
                                class="tw-w-full"
                                :currency-symbol="nursery.currency"
                                :parser-options="currencyPerHourPresets"
                            >
                                <template #addon>
                                    {{ __('common:currency_per_hour', {currency: nursery.currency}) }}
                                </template>
                            </MCurrencyInput>
                        </div>
                        <!-- PRECISION / ARRONDIS -->
                        <div class="tw-w-full">
                            <MButton
                                class="tw-px-1.5"
                                :label="__('billing_setting:rounding_configure')"
                                size="sm"
                                variant="link"
                                @click="showOverrunsPrecisions = !showOverrunsPrecisions"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                                </template>
                            </MButton>
                            <div
                                v-if="showOverrunsPrecisions"
                                class="tw-mt-2 tw-px-2"
                            >
                                <precisions
                                    :config-key="config.overruns.recurrent"
                                    only-price
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="config.overruns.recurrent.charged"
                        class="form-row tw-mt-6"
                    >
                        <div class="col-12">
                            <div>
                                {{ __('billing_setting:how_are_overruns_deducted') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:how_are_overruns_counted') }}
                            </small>
                            <div class="tw-mt-2 tw-flex">
                                <CText
                                    class="tw-mr-1"
                                    font-weight="semibold"
                                >
                                    {{ __('billing_setting:pre-fill-overrun-tab') }} :
                                </CText>
                                <CText
                                    as="button"
                                    class="tw-mr-2"
                                    font-weight="medium"
                                    variant="primary"
                                    @click.native="setOverrun(15)"
                                >
                                    {{ __('billing_setting:per_fifteen_minutes') }}
                                </CText>
                                <CText
                                    as="button"
                                    class="tw-mr-2"
                                    font-weight="medium"
                                    variant="primary"
                                    @click.native="setOverrun(30)"
                                >
                                    {{ __('billing_setting:per_thirty_minutes') }}
                                </CText>
                                <CText
                                    as="button"
                                    font-weight="medium"
                                    variant="primary"
                                    @click.native="setOverrun(60)"
                                >
                                    {{ __('billing_setting:per_hour') }}
                                </CText>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0" />
                    </div>
                    <div
                        v-if="config.overruns.recurrent.charged"
                        class="form-row tw-mt-2"
                    >
                        <overruns
                            :nursery="nursery"
                            :overruns="config.overruns.recurrent"
                        />
                    </div>
                    <CText
                        v-if="config.overruns.recurrent.charged"
                        as="button"
                        font-size="sm"
                        font-weight="semibold"
                        variant="primary"
                        @click.native="showAdvancedConfigForRecurrentOverrun = !showAdvancedConfigForRecurrentOverrun"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-gear"
                        />{{ __('billing_setting:advanced_config') }}
                    </CText>
                    <div
                        v-if="config.overruns.recurrent.charged && showAdvancedConfigForRecurrentOverrun"
                        class="form-row tw-mt-2"
                    >
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:combine_start_and_finish_times_before_billing') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:if_not_billed_separately') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MButtonSelect
                                v-model="config.overruns.recurrent.sum_start_and_end"
                                :options="booleanOptions"
                            />
                        </div>
                    </div>
                    <div
                        v-if="config.overruns.recurrent.charged && showAdvancedConfigForRecurrentOverrun"
                        class="form-row tw-mt-6"
                    >
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:when_do_you_invoice_them') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:current_invoice_or_next_invoice') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MButtonSelect
                                v-model="config.overruns.recurrent.charged_at"
                                :options="whenBillingOptions"
                            />
                        </div>
                    </div>
                </div>

                <!-- OCCASIONNALS -->
                <hr class="hr" />
                <div class="tw-pl-1">
                    <div class="tw-mt-4 tw-font-semibold tw-uppercase tw-text-primary-500">
                        {{ __('billing_setting:additional_bookings') }}
                    </div>
                    <div class="form-row tw-mt-4">
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:bill_occasional_users_by_colon') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:hours_halfdays_days') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <CFormSelect
                                v-model="config.occasionals.per"
                                class="tw-w-full"
                                :options="cycleOptions"
                                :search-bar="false"
                            />
                        </div>
                    </div>
                    <CText
                        as="button"
                        font-size="sm"
                        font-weight="medium"
                        variant="primary"
                        @click.native="showAdvancedConfigForOccasionnal = !showAdvancedConfigForOccasionnal"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-gear"
                        />{{ __('billing_setting:advanced_config') }}
                    </CText>
                    <div
                        v-if="showAdvancedConfigForOccasionnal"
                        class="form-row tw-mt-4"
                    >
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:when_do_you_invoice_them') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:current_invoice_or_next_invoice') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MButtonSelect
                                v-model="config.occasionals.charged_at"
                                :options="whenBillingOptions"
                            />
                        </div>
                    </div>
                    <div class="form-row tw-mt-4">
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:hourly_rate_for_occasional_users') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:leave_blank') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MCurrencyInput
                                v-model="config.occasionals.amount"
                                allow-undefined
                                class="tw-w-full"
                                :currency-symbol="nursery.currency"
                                :parser-options="currencyPerHourPresets"
                            >
                                <template #addon>
                                    {{ __('common:currency_per_hour', {currency: nursery.currency}) }}
                                </template>
                            </MCurrencyInput>
                        </div>
                        <!-- PRECISION / ARRONDIS -->
                        <div class="tw-w-full">
                            <MButton
                                class="tw-px-1.5"
                                :label="__('billing_setting:rounding_configure')"
                                size="sm"
                                variant="link"
                                @click="showOccasionalsPrecisions = !showOccasionalsPrecisions"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                                </template>
                            </MButton>
                            <div
                                v-if="showOccasionalsPrecisions"
                                class="tw-mt-2 tw-px-2"
                            >
                                <precisions
                                    :config-key="config.occasionals"
                                    only-price
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-row tw-mt-6">
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:charge_for_overruns_on_occasional_childcare') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:are_overruns_billed') }}
                            </small>
                            <div class="tw-flex">
                                <CText
                                    as="button"
                                    font-size="sm"
                                    font-weight="medium"
                                    variant="primary"
                                    @click.native="copyOverrun"
                                >
                                    <FontAwesomeIcon
                                        class="tw-mr-1"
                                        icon="fa-solid fa-copy"
                                    />{{ __('billing_setting:copy_regular_parameters') }}
                                </CText>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MButtonSelect
                                v-model="config.overruns.occasional.charged"
                                :options="booleanOptions"
                            />
                        </div>
                    </div>
                    <div
                        v-if="config.overruns.occasional.charged"
                        class="form-row tw-mt-6"
                    >
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:what_is_your_hourly_rate') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:leave_blank') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-5 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MCurrencyInput
                                v-model="config.overruns.occasional.amount"
                                allow-undefined
                                class="tw-w-full"
                                :currency-symbol="nursery.currency"
                                :parser-options="currencyPerHourPresets"
                            >
                                <template #addon>
                                    {{ __('common:currency_per_hour', {currency: nursery.currency}) }}
                                </template>
                            </MCurrencyInput>
                        </div>
                        <!-- PRECISION / ARRONDIS -->
                        <div class="tw-w-full">
                            <MButton
                                class="tw-px-1.5"
                                :label="__('billing_setting:rounding_configure')"
                                size="sm"
                                variant="link"
                                @click="showOccasionnalOverrunsPrecisions = !showOccasionnalOverrunsPrecisions"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                                </template>
                            </MButton>
                            <div
                                v-if="showOccasionnalOverrunsPrecisions"
                                class="tw-mt-2 tw-px-2"
                            >
                                <precisions
                                    :config-key="config.overruns.occasional"
                                    only-price
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="config.overruns.occasional.charged"
                        class="form-row tw-mt-6"
                    >
                        <div class="col-12">
                            <div>
                                {{ __('billing_setting:how_are_overruns_deducted') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:how_are_overruns_counted') }}
                            </small>
                            <div class="tw-mt-2 tw-flex">
                                <CText
                                    class="tw-mr-1"
                                    font-weight="semibold"
                                >
                                    {{ __('billing_setting:pre-fill-overrun-tab') }} :
                                </CText>
                                <CText
                                    as="button"
                                    class="tw-mr-2"
                                    font-weight="medium"
                                    variant="primary"
                                    @click.native="setOverrun(15, true)"
                                >
                                    {{ __('billing_setting:per_fifteen_minutes') }}
                                </CText>
                                <CText
                                    as="button"
                                    class="tw-mr-2"
                                    font-weight="medium"
                                    variant="primary"
                                    @click.native="setOverrun(30, true)"
                                >
                                    {{ __('billing_setting:per_thirty_minutes') }}
                                </CText>
                                <CText
                                    as="button"
                                    font-weight="medium"
                                    variant="primary"
                                    @click.native="setOverrun(60, true)"
                                >
                                    {{ __('billing_setting:per_hour') }}
                                </CText>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0" />
                    </div>
                    <div
                        v-if="config.overruns.occasional.charged"
                        class="form-row tw-mt-2"
                    >
                        <overruns
                            :nursery="nursery"
                            :overruns="config.overruns.occasional"
                        />
                    </div>
                    <CText
                        v-if="config.overruns.occasional.charged"
                        as="button"
                        font-size="sm"
                        font-weight="medium"
                        variant="primary"
                        @click.native="
                            showAdvancedConfigForOccasionnalOverrun = !showAdvancedConfigForOccasionnalOverrun
                        "
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-gear"
                        />{{ __('billing_setting:advanced_config') }}
                    </CText>
                    <div
                        v-if="config.overruns.occasional.charged && showAdvancedConfigForOccasionnalOverrun"
                        class="form-row tw-mt-2"
                    >
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:combine_start_and_finish_times_before_billing') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:if_not_billed_separately') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MButtonSelect
                                v-model="config.overruns.occasional.sum_start_and_end"
                                :options="booleanOptions"
                            />
                        </div>
                    </div>
                    <div
                        v-if="config.overruns.occasional.charged && showAdvancedConfigForOccasionnalOverrun"
                        class="form-row tw-mt-6"
                    >
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:when_do_you_invoice_them') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:current_invoice_or_next_invoice') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MButtonSelect
                                v-model="config.overruns.occasional.charged_at"
                                :options="whenBillingOptions"
                            />
                        </div>
                    </div>
                </div>

                <!-- ADAPTATIONS -->
                <hr class="hr" />
                <div class="tw-pl-1">
                    <div class="tw-mt-4 tw-font-semibold tw-uppercase tw-text-yellow-600">
                        {{ __('common:adaptation_other') }}
                    </div>
                    <div class="form-row tw-mt-4">
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:you_wish_to_invoice_adaptations_by') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:hours_halfdays_days') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <CFormSelect
                                v-model="config.adaptations.per"
                                class="tw-w-full"
                                :options="cycleOptions"
                                :search-bar="false"
                            />
                        </div>
                    </div>
                    <CText
                        as="button"
                        font-size="sm"
                        font-weight="medium"
                        variant="primary"
                        @click.native="showAdvancedConfigForAdaptation = !showAdvancedConfigForAdaptation"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-gear"
                        />{{ __('billing_setting:advanced_config') }}
                    </CText>
                    <div
                        v-if="showAdvancedConfigForAdaptation"
                        class="form-row tw-mt-4"
                    >
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:when_do_you_invoice_them') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:current_invoice_or_next_invoice') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MButtonSelect
                                v-model="config.adaptations.charged_at"
                                :options="whenBillingOptions"
                            />
                        </div>
                    </div>
                    <div class="form-row tw-mt-4">
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:hourly_rate_for_adaptations') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:leave_blank') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MCurrencyInput
                                v-model="config.adaptations.amount"
                                allow-undefined
                                class="tw-w-full"
                                :currency-symbol="nursery.currency"
                                :parser-options="currencyPerHourPresets"
                            >
                                <template #addon>
                                    {{ __('common:currency_per_hour', {currency: nursery.currency}) }}
                                </template>
                            </MCurrencyInput>
                        </div>
                        <!-- PRECISION / ARRONDIS -->
                        <div class="tw-w-full">
                            <MButton
                                class="tw-px-1.5"
                                :label="__('billing_setting:rounding_configure')"
                                size="sm"
                                variant="link"
                                @click="showAdaptationsPrecisions = !showAdaptationsPrecisions"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                                </template>
                            </MButton>
                            <div
                                v-if="showAdaptationsPrecisions"
                                class="tw-mt-2 tw-px-2"
                            >
                                <precisions
                                    :config-key="config.adaptations"
                                    only-price
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-row tw-mt-6">
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:deduct_existing_schedules_before_adapting_to_colon') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:meeko_can_deduct_the_schedules') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <CFormSelect
                                v-model="config.adaptations.deduct_planning_on"
                                :options="deductPlanningOptions"
                                :search-bar="false"
                                sub-text-path="subtext"
                                text-path="text"
                                value-path="value"
                            />
                        </div>
                    </div>
                </div>

                <!-- ABSENCES -->
                <hr class="hr" />
                <div class="tw-pl-1">
                    <div class="tw-mt-4 tw-font-semibold tw-uppercase tw-text-danger-500">
                        {{ __('common:absence_other') }}
                    </div>
                    <div class="form-row tw-mt-4">
                        <div class="col-12 col-sm-6">
                            <div>
                                {{ __('billing_setting:when_do_you_deduct_them') }}
                            </div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:on_which_invoice_deduct_them') }}
                            </small>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 tw-mt-2 tw-self-center sm:tw-mt-0">
                            <MButtonSelect
                                v-model="config.absences.charged_at"
                                :options="whenBillingOptions"
                            />
                        </div>
                        <!-- PRECISION / ARRONDIS -->
                        <div class="tw-w-full">
                            <MButton
                                class="tw-px-1.5"
                                :label="__('billing_setting:rounding_configure')"
                                size="sm"
                                variant="link"
                                @click="showAbsencesPrecisions = !showAbsencesPrecisions"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                                </template>
                            </MButton>
                            <div
                                v-if="showAbsencesPrecisions"
                                class="tw-mt-2 tw-px-2"
                            >
                                <precisions
                                    :config-key="config.absences"
                                    only-price
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MBox>
</template>

<script>
    import Overruns from './Price/Array/Overrun.vue';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import __ from '@/modules/app/utils/i18n-facade';
    import Precisions from '@/modules/cashier/components/settings/RulesCards/Price/Array/Precisions.vue';
    import {getNumericParserContractPresetCurrencyPerHour} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import i18next from 'i18next';

    export default {
        components: {
            Precisions,
            overruns: Overruns,
        },
        props: {
            config: {},
            nursery: {},
        },
        data() {
            return {
                showAdvancedConfigForRecurrentOverrun: false,
                showAdvancedConfigForOccasionnalOverrun: false,
                showAdvancedConfigForOccasionnal: false,
                showAdvancedConfigForAdaptation: false,
                showOverrunsPrecisions: false,
                showOccasionnalOverrunsPrecisions: false,
                showOccasionalsPrecisions: false,
                showAdaptationsPrecisions: false,
                showAbsencesPrecisions: false,
                showCard: false,
                booleanOptions: [
                    {
                        text: __('common:yes'),
                        value: true,
                    },
                    {
                        text: __('common:no'),
                        value: false,
                    },
                ],
                cycleOptions: [
                    {
                        text: __('common:hour_other'),
                        value: 'hour',
                    },
                    {
                        text: __('common:halfday_other'),
                        value: 'halfday',
                    },
                    {
                        text: __('common:day_one'),
                        value: 'day',
                    },
                ],
                chargeOnOptions: [
                    {
                        text: __('billing_setting:current_period'),
                        value: 'current_period',
                    },
                    // {
                    //     text: 'Le lissage',
                    //     value: 'smoothing',
                    // },
                ],
                deductPlanningOptions: [
                    {
                        text: __('billing_setting:deduct_recurrent_for_adaptation'),
                        subtext: __('billing_setting:deduct_recurrent_for_adaptation_subtext'),
                        value: 'current_period',
                    },
                    {
                        text: __('billing_setting:do_not_deduct'),
                        subtext: __('billing_setting:do_not_deduct_subtext'),
                        value: 'none',
                    },
                ],
                overrunTypeOptions: [
                    {
                        text: __('billing_setting:contract_rate'),
                        value: null,
                    },
                    {
                        text: __('billing_setting:custom'),
                        value: 10,
                    },
                ],
                whenBillingOptions: [
                    {
                        text: __('billing_setting:current_invoice'),
                        value: 'end',
                    },
                    {
                        text: __('billing_setting:next_invoice'),
                        value: 'start',
                    },
                ],
                currencyPerHourPresets: getNumericParserContractPresetCurrencyPerHour(i18next.language),
            };
        },
        watch: {
            'config.occasionals.amount': {
                handler(value) {
                    if (value === undefined) {
                        this.config.occasionals.amount = null;
                    }
                },
            },
            'config.adaptations.amount': {
                handler(value) {
                    if (value === undefined) {
                        this.config.adaptations.amount = null;
                    }
                },
            },
        },
        methods: {
            setOverrun(min, occasionnal = false) {
                let brackets = {
                    charged_from: [{value: 15}, {value: 60}, {value: 120}, {value: 120}],
                    charged_hours: [{value: 0}, {value: 1}, {value: 2}, {value: 3}],
                };
                switch (min) {
                    case 15:
                        brackets = {
                            charged_from: [
                                {value: 5},
                                {value: 15},
                                {value: 30},
                                {value: 45},
                                {value: 60},
                                {value: 75},
                                {value: 90},
                                {value: 105},
                                {value: 120},
                                {value: 135},
                                {value: 150},
                                {value: 165},
                                {value: 165},
                            ],
                            charged_hours: [
                                {value: 0},
                                {value: 0.25},
                                {value: 0.5},
                                {value: 0.75},
                                {value: 1},
                                {value: 1.25},
                                {value: 1.5},
                                {value: 1.75},
                                {value: 2},
                                {value: 2.25},
                                {value: 2.5},
                                {value: 2.75},
                                {value: 3},
                            ],
                        };
                        break;
                    case 30:
                        brackets = {
                            charged_from: [
                                {value: 5},
                                {value: 30},
                                {value: 60},
                                {value: 90},
                                {value: 120},
                                {value: 150},
                                {value: 150},
                            ],
                            charged_hours: [
                                {value: 0},
                                {value: 0.5},
                                {value: 1},
                                {value: 1.5},
                                {value: 2},
                                {value: 2.5},
                                {value: 3},
                            ],
                        };
                        break;
                    default:
                        brackets = {
                            charged_from: [{value: 15}, {value: 60}, {value: 120}, {value: 120}],
                            charged_hours: [{value: 0}, {value: 1}, {value: 2}, {value: 3}],
                        };
                }
                if (occasionnal) {
                    this.config.overruns.occasional.brackets = brackets;
                } else {
                    this.config.overruns.recurrent.brackets = brackets;
                }
            },

            copyOverrun() {
                this.config.overruns.occasional = _cloneDeep(this.config.overruns.recurrent);
            },
        },
    };
</script>
