<template>
    <div>
        <MMenu>
            <MMenuButton>
                <MButton
                    icon-ellipsis
                    size="sm"
                    variant="ghost"
                />
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('billing_core:modify_contact')"
                    @click="showEditContactModal = true"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-pen"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :label="__('billing_core:delete_contact_dots')"
                    variant="danger"
                    @click="showDeleteContactModal = true"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
        <UpdateContactModal
            v-if="showEditContactModal"
            :contact-model="contactModel"
            @hidden="showEditContactModal = false"
            @updated="onContactUpdated"
        />
        <DeleteContactModal
            v-if="showDeleteContactModal"
            :contact-model="contactModel"
            @deleted="onContactDeleted"
            @hidden="showDeleteContactModal = false"
        />
    </div>
</template>

<script lang="ts">
    import type ContactModel from '@/modules/cashier/models/ContactModel';
    import DeleteContactModal from '@/modules/cashier/components/core/contact/organisms/DeleteContactModal.vue';
    import UpdateContactModal from '@/modules/cashier/components/core/contact/organisms/UpdateContactModal.vue';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        components: {UpdateContactModal, DeleteContactModal},
        props: {
            contactModel: {type: Object as PropType<ContactModel>, required: true},
        },
        setup(props, {emit}) {
            const showEditContactModal = ref(false);
            const showDeleteContactModal = ref(false);

            function onContactDeleted(contactModel: ContactModel) {
                emit('deleted', contactModel);
            }

            function onContactUpdated(contactModel: ContactModel) {
                emit('updated', contactModel);
            }

            return {
                showEditContactModal,
                showDeleteContactModal,
                onContactDeleted,
                onContactUpdated,
            };
        },
    });
</script>
