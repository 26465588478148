<template>
    <div class="MEmptyIndex">
        <h1 class="MEmptyIndex__title">
            {{ title }}
        </h1>
        <h2
            v-if="showText"
            class="MEmptyIndex__text"
        >
            {{ text }}
        </h2>
        <div class="MEmptyIndex__button">
            <slot name="button" />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {},
        props: {
            title: {
                type: String,
                required: false,
                default: () => __('common:empty_result'),
            },
            text: {
                type: String,
                required: false,
                default: () => __('common:try_modifying_filters_or_adding_data'),
            },
            showText: {
                type: Boolean,
                default: true,
            },
        },
        setup() {
            return {};
        },
    });
</script>

<style scoped>
    .MEmptyIndex {
        @apply tw-flex tw-flex-col tw-items-start sm:tw-items-center;
        @apply tw-py-6;
    }

    .MEmptyIndex__title {
        @apply tw-text-left tw-text-2xl tw-font-semibold tw-text-black sm:tw-text-center;
    }

    .MEmptyIndex__text {
        @apply tw-text-left tw-font-medium tw-text-gray-400 sm:tw-text-center;
    }

    .MEmptyIndex__button {
        @apply tw-pt-4;
    }
</style>
