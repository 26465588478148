<template>
    <CCard
        :border="false"
        :shadow="true"
    >
        <StaffStatistics />
    </CCard>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import StaffStatistics from '@/modules/human-resources/components/statistics/StaffStatistics.vue';

    export default defineComponent({
        components: {
            StaffStatistics,
        },
    });
</script>
