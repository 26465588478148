<template>
    <div>
        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="sm"
        />
        <div
            v-else-if="photos.length"
            class="tw-grid tw-grid-cols-2 tw-gap-1 md:tw-grid-cols-3"
        >
            <div
                v-for="(photo, i) in photos"
                :key="'image' + i"
                class="tw-relative tw-mb-6 tw-min-h-32"
            >
                <div
                    v-if="photo.video"
                    class="tw-absolute tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-black"
                    style="
                        width: 40px;
                        height: 40px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0.8;
                    "
                >
                    <FontAwesomeIcon
                        class="tw-pl-1 tw-text-xl tw-text-white"
                        icon="fa-solid fa-play"
                    />
                </div>
                <div
                    class="tw-cursor-pointer tw-rounded-lg tw-border-2"
                    :class="{
                        'tw-border-blue-500': selectedPhoto && selectedPhoto.id === photo.id,
                        'tw-border-transparent hover:tw-border-blue-300':
                            !selectedPhoto || selectedPhoto.id !== photo.id,
                    }"
                    @click="selectPhoto(photo)"
                >
                    <div
                        class="tw-relative tw-h-32 tw-w-full tw-rounded-lg tw-bg-cover tw-bg-center"
                        :style="{
                            'background-image': `url('${photo.permanent_photo_url ? photo.permanent_photo_url : photo.photo_url}')`,
                        }"
                    />
                </div>
            </div>
        </div>
        <div v-else>
            {{ __('news:no_image_on_library') }}
        </div>
        <div
            v-if="total > per_page && photos.length"
            class="tw-mt-4 tw-flex tw-justify-center"
        >
            <MPagination
                :paginator="paginator"
                @navigate="currentPage = $event"
            />
        </div>
    </div>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import Manager from '@/modules/legacy/store/manager.store';
    import useApi from '@/modules/app/composables/useApi';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import {LengthAwarePaginator} from '@meekohq/lumos';

    export default {
        components: {MPagination},
        data: () => ({
            photos: [],
            loading: false,
            per_page: 6,
            currentPage: 1,
            page: 1,
            total: 0,
            paginator: undefined,
            selectedPhoto: null,
        }),
        watch: {
            currentPage() {
                this.getPhotos();
            },
        },
        mounted() {
            this.getPhotos();
        },
        methods: {
            getPhotos() {
                this.loading = true;
                useApi()
                    .legacy.get(
                        route('nurseries.photos.index', {
                            nurseries: Manager.legacyNursery.id,
                        }),
                        {
                            params: {
                                page: this.currentPage,
                                per_page: this.per_page,
                                permanent_photo_url: 1,
                                order: 'asc',
                            },
                        }
                    )
                    .then(response => {
                        this.photos = response.data.data;
                        this.total = response.data.total;
                        this.paginator = new LengthAwarePaginator(
                            response.data.data,
                            parseInt(response.data.total),
                            parseInt(response.data.per_page),
                            parseInt(response.data.current_page),
                            parseInt(response.data.last_page)
                        );
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            selectPhoto(photo) {
                this.selectedPhoto = photo;
                this.$emit('select-photo', photo);
            },
        },
    };
</script>
