import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';
import JobModel from '@/modules/human-resources/models/JobModel';
import * as scopes from '@/modules/human-resources/utils/contract/scopeContract';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import ContractOrganizationPivot from '@/modules/human-resources/models/ContractOrganizationPivot';
import ContractTypeModel from '@/modules/human-resources/models/ContractTypeModel';
import ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import moment from 'moment';

export default class ContractModel extends Model {
    public type = 'hr/contracts';

    public attributes: {
        id: string;
        account_id: string | undefined;
        contract_type_id: string | undefined;
        job_id: string | undefined;
        staff_id: string | number | undefined;
        organization_id: string | number | undefined;
        draft: boolean | undefined;
        hours_per_week: number | undefined;
        qualification: string | undefined;
        part_time_work: boolean | undefined;
        salary: number | undefined;
        started_at: string | undefined;
        ended_at: string | undefined;
        broked_at: string | undefined;
        note: string | undefined;
        supervisor_first_name: string | undefined;
        supervisor_last_name: string | undefined;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
        id: this.uuid(),
        draft: undefined,
        account_id: undefined,
        contract_type_id: undefined,
        job_id: undefined,
        staff_id: undefined,
        organization_id: undefined,
        hours_per_week: undefined,
        qualification: undefined,
        part_time_work: undefined,
        salary: undefined,
        started_at: undefined,
        ended_at: undefined,
        broked_at: undefined,
        note: undefined,
        supervisor_first_name: undefined,
        supervisor_last_name: undefined,
        created_at: undefined,
        updated_at: undefined,
    };

    get period(): {startedAt; endedAt; format} {
        return {startedAt: this.attributes.started_at, endedAt: this.attributes.ended_at, format: 'date'};
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'nursery_id');
    }

    job() {
        return this.belongsTo('job', JobModel, 'job_id');
    }

    scopeActive = (query: QueryBuilder<any>, args: [from: string, to: string | null, organizations: string[] | null]) =>
        scopes.scopeActive(query, args);
    scopeComing = (query: QueryBuilder<any>, date: string) => scopes.scopeComing(query, date);
    scopeUntil = (query: QueryBuilder<any>, date: string) => scopes.scopeUntil(query, date);

    trialPeriods() {
        return this.hasMany('trialPeriods', ContractTrialPeriodModel, 'contract_id');
    }

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            ContractOrganizationPivot,
            'contract_id',
            'organization_id'
        );
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', ContractOrganizationPivot, 'contract_id');
    }

    contractType() {
        return this.belongsTo('contractType', ContractTypeModel, 'contract_type_id');
    }

    staff() {
        return this.belongsTo('staff', StaffModel, 'staff_id');
    }

    balanceAllocations() {
        return this.morphMany('balanceAllocations', BalanceAllocationModel, 'source');
    }

    scopeInRange(mainQuery: QueryBuilder<any>, range: [from: string, to: string]) {
        const from = range[0];
        const to = range[1];

        const fromIso8601 = moment.isMoment(from) ? from.toISOString() : moment(from).toISOString();
        const toIso8601 = moment.isMoment(to) ? to.toISOString() : moment(to).toISOString();

        mainQuery.where(query0 => {
            query0
                .where(query => {
                    query.whereDateTime('started_at', '<=', fromIso8601).where(query1 => {
                        query1.whereDateTime('ended_at', '>', fromIso8601);
                        query1.orWhereNull('ended_at');
                    });
                })
                .orWhere(q => {
                    q.whereDateTime('started_at', '>=', fromIso8601);
                    if (to) {
                        q.whereDateTime('started_at', '<=', toIso8601);
                    }
                });
        });
    }
}
