<template>
    <div>
        <p
            class="tw-mb-0 tw-text-base tw-text-gray-600"
            v-html="
                __('hr:missing_derparture_checkin_for_name_at', {
                    name: `${notification.data.kid_first_name} ${notification.data.kid_last_name}`,
                    date: notification.data.date,
                    expectedAt: expectedAt,
                })
            "
        />
        <p class="tw-mb-0 tw-mt-1 tw-text-sm tw-text-gray-800">
            {{ __('hr:automatically_checked_in_at_scheduled_time') }}
        </p>

        <div class="tw-mt-2">
            <router-link
                class="btn btn-light btn-sm"
                :to="{
                    name: 'kids.show',
                    params: {nursery: notification.data.nursery_id, kid: notification.data.kid_id},
                }"
                @click.native="handlerEmit"
            >
                {{ __('hr:see_planning') }}
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
    import moment from 'moment';
    import {computed, defineComponent} from 'vue';
    import {EventBus} from '@/modules/legacy/utils/bus';

    export default defineComponent({
        props: {
            notification: {
                type: Object,
                required: true,
            },
        },
        setup(props, {emit}) {
            const expectedAt = computed(() => {
                const expectedAtDateTime = `${props.notification.data.date} ${props.notification.data.expected_at}`;
                const format = 'DD/MM/YYYY HH:mm';

                // Instanciate a moment object from a utc date with the format and update
                // to local time
                return moment.utc(expectedAtDateTime, format).local().format('HH:mm');
            });

            function handlerEmit() {
                emit('closeModal');

                if (!props.notification.read_at) {
                    props.notification.read_at = moment().unix();
                }

                EventBus.$emit('update:notification', props.notification);
            }

            return {
                expectedAt,
                handlerEmit,
            };
        },
    });
</script>
