import {Model} from '@meekohq/lumos';
import TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
import TeamStaffPivot from '@/modules/human-resources/models/TeamStaffPivot';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import StaffModel from '@/modules/human-resources/models/StaffModel';

export default class TeamModel extends Model {
    public type = 'hr/teams';

    public attributes: {
        id: string;
        account_id: string | undefined;
        internal_id: string | undefined;
        name: string | undefined | null;
        archived_at: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        internal_id: undefined,
        name: undefined,
        archived_at: undefined,
    };

    staffs() {
        return this.belongsToMany('staffs', StaffModel, TeamStaffPivot, 'team_id', 'staff_id');
    }

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            TeamOrganizationPivot,
            'team_id',
            'organization_id'
        );
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', TeamOrganizationPivot, 'team_id');
    }

    staffsPivots() {
        return this.hasMany('staffsPivots', TeamStaffPivot, 'team_id');
    }
}
