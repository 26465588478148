<template>
    <div class="tw-flex tw-flex-col tw-gap-3 md:tw-flex-row lg:tw-flex-col xl:tw-flex-row">
        <div class="tw-mr-auto tw-flex tw-flex-1 tw-items-center">
            <CTooltip
                v-if="!accepted"
                placement="bottom-start"
            >
                <MButton
                    class="tw-mr-3 tw-shrink-0 tw-whitespace-normal"
                    :loading="saving"
                    variant="light"
                    @click="attachDetach(id)"
                >
                    <DepositedImg
                        v-if="deposited === true"
                        class="tw-inline"
                        :class="{'opacity-50': !deposited}"
                        height="20"
                        width="20"
                    />
                    <UndepositedImg
                        v-if="deposited === false"
                        class="tw-inline"
                        :class="{'opacity-50': !deposited}"
                        height="20"
                        width="20"
                    />
                    <FontAwesomeIcon
                        class="tw-ml-2"
                        :class="{'tw-text-green-500': deposited, 'tw-text-gray-400': !deposited}"
                        icon="fa-solid fa-check"
                    />
                </MButton>
                <template #content>
                    <CText>
                        {{
                            deposited
                                ? __('registration:withdraw_registration')
                                : __('registration:deposit_registration')
                        }}
                    </CText>
                </template>
            </CTooltip>
            <CVStack>
                <CText
                    font-weight="semibold"
                    :variant="deposited ? 'black' : 'gray'"
                >
                    {{ name }}
                </CText>
                <CText
                    v-if="city"
                    variant="gray"
                >
                    {{ city }}
                </CText>
            </CVStack>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 sm:tw-flex-row sm:tw-items-center">
            <template v-if="deposited">
                <template v-if="accepted">
                    <div>
                        <CBadge
                            class="tw-w-full sm:tw-w-auto"
                            size="lg"
                            variant="success"
                        >
                            <div class="tw-flex tw-items-center tw-justify-center">
                                {{ __('registration:accepted_registration') }}
                            </div>
                        </CBadge>
                    </div>
                </template>
                <template v-else-if="declined">
                    <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                        <CBadge
                            class="tw-w-full sm:tw-w-auto"
                            size="lg"
                            variant="gray"
                        >
                            <div class="tw-flex tw-items-center tw-justify-center">
                                {{ __('registration:rejected_registration') }}
                            </div>
                        </CBadge>
                        <MButton @click="unreject(id)">
                            {{ __('common:put_on_hold') }}
                        </MButton>
                    </div>
                </template>
                <template v-else>
                    <MButton
                        :loading="saving"
                        variant="success"
                        @click="accept(id)"
                    >
                        {{ __('common:actions.accept') }}
                        <FontAwesomeIcon
                            class="tw-ml-2"
                            icon="fa-solid fa-check"
                        />
                    </MButton>
                    <MButton
                        :loading="saving"
                        variant="danger"
                        @click="reject(id)"
                    >
                        {{ __('common:actions.deny') }}
                        <FontAwesomeIcon
                            class="tw-ml-2"
                            icon="fa-solid fa-times"
                        />
                    </MButton>
                </template>
                <MButton
                    v-if="kid_id && $can('read', 'kids_details', permissions)"
                    @click="$router.push({name: 'kids.show', params: {nursery: id, kid: kid_id}})"
                >
                    {{ __('common:kid_page') }}
                </MButton>
                <MButton
                    v-if="$can('read', 'invoices', permissions)"
                    :loading="selectedNurseryLoading"
                    @click="estimateInvoice"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-file-pdf"
                    />{{ __('registration:make_a_quote') }}
                </MButton>
            </template>
            <template v-else>
                <MButton
                    :loading="saving"
                    variant="primary"
                    @click="attachDetach(id)"
                >
                    {{ __('registration:deposit_registration')
                    }}<FontAwesomeIcon
                        class="tw-ml-2"
                        icon="fa-solid fa-inbox-in"
                    />
                </MButton>
            </template>
        </div>
    </div>
</template>

<script>
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import DepositedImg from '@/modules/legacy/components/Events/img/deposited_file.vue';
    import UndepositedImg from '@/modules/legacy/components/Events/img/undeposited_file.vue';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        name: 'DepositedInNursery',
        components: {
            DepositedImg,
            UndepositedImg,
        },
        props: {
            id: {
                type: String,
                required: true,
            },
            registration: {
                type: Object,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            city: {
                type: String,
                required: false,
            },
            deposited: {
                type: Boolean,
                default: false,
                required: false,
            },
            declined: {
                type: Boolean,
                default: false,
                required: false,
            },
            accepted: {
                type: Boolean,
                default: false,
                required: false,
            },
            kid_id: {
                type: String,
                default: null,
                required: false,
            },
            permissions: {
                type: Array,
                required: true,
            },
        },
        data: () => ({
            saving: false,
            selectedNurseryLoading: false,
        }),
        computed: {
            currentNursery() {
                return useManager().legacyNursery.value;
            },
        },
        methods: {
            attach(nursery_id) {
                this.saving = true;
                useApi()
                    .legacy.post(
                        route('nurseries.registrations.attach', {
                            nurseries: nursery_id,
                            registration: this.registration.id,
                        })
                    )
                    .then(response => {
                        this.$emit('refreshNurseries', response.data.nurseries);
                        useNotification().success(__('registration:attach_registration_success'));
                    })
                    .catch(error => {
                        this.displayError(error);
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },
            detach(nursery_id) {
                this.saving = true;
                useApi()
                    .legacy.post(
                        route('nurseries.registrations.detach', {
                            nurseries: nursery_id,
                            registration: this.registration.id,
                        })
                    )
                    .then(response => {
                        this.$emit('refreshNurseries', response.data.nurseries);
                        useNotification().success(__('registration:detach_registration_success'));
                    })
                    .catch(error => {
                        this.displayError(error);
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },
            attachDetach(id) {
                if (this.deposited) {
                    this.detach(id);
                } else {
                    this.attach(id);
                }
            },
            unreject(nursery_id) {
                this.saving = true;
                useApi()
                    .legacy.put(
                        route('nurseries.registrations.update', {
                            nurseries: nursery_id,
                            registration: this.registration.id,
                        }),
                        {rejected: 0}
                    )
                    .then(response => {
                        this.$emit('refreshNurseries', response.data.nurseries);
                        useNotification().success(__('registration:unrejected_registration_success'));
                    })
                    .catch(error => {
                        this.displayError(error);
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },
            reject(nursery_id) {
                this.saving = true;
                useApi()
                    .legacy.put(
                        route('nurseries.registrations.update', {
                            nurseries: nursery_id,
                            registration: this.registration.id,
                        }),
                        {rejected: 1}
                    )
                    .then(response => {
                        this.$emit('refreshNurseries', response.data.nurseries);
                        useNotification().success(__('registration:rejected_registration_success'));
                    })
                    .catch(error => {
                        this.displayError(error);
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },
            accept(nursery_id) {
                this.saving = true;
                useApi()
                    .legacy.post(
                        route('nurseries.registrations.accept', {
                            nurseries: nursery_id,
                            registration: this.registration.id,
                        })
                    )
                    .then(response => {
                        this.$emit('refreshNurseries', response.data.nurseries);
                        useNotification().success(__('registration:accepted_registration_success'));
                    })
                    .catch(error => {
                        this.displayError(error);
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },

            async estimateInvoice() {
                if (this.selectedNurseryLoading) {
                    return;
                }

                // Get nursery from pivot
                let nursery = this.currentNursery;
                if (this.currentNursery.id !== this.id) {
                    this.selectedNurseryLoading = true;
                    nursery = (await useApi().legacy.get(route('nurseries.show', {nursery_id: this.id}))).data;
                }

                this.selectedNurseryLoading = false;
                this.$emit('estimateInvoice', nursery);
            },

            displayError(error) {
                if (error && error.response && error.response.status === 422) {
                    _forEach(error.response.data.errors, function (value) {
                        useNotification().error(_head(value));
                    });
                } else {
                    useNotification().error(error);
                }
            },
        },
    };
</script>

<style scoped>
    .opacity-50 {
        opacity: 0.6;
    }
</style>
