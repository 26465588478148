<template>
    <div>
        <MMenu
            arrow
            placement="left"
        >
            <MMenuButton>
                <MButton
                    icon-ellipsis
                    size="sm"
                    variant="ghost"
                />
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('common:actions.update')"
                    @click="$refs.updateBalanceTypeModal.show()"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-pen"
                        />
                    </template>
                </MMenuItem>
                <MMenuDivider />
                <MMenuItem
                    v-if="isPivotArchived"
                    :label="__('common:actions.unarchive')"
                    @click="toggleArchive"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-box-open"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    v-else
                    :label="__('common:actions.archive')"
                    @click="toggleArchive"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-archive"
                        />
                    </template>
                </MMenuItem>
                <template
                    v-if="
                        user.is_owner && user.account_id === model.attributes.account_id && isUsedInAnotherOrganization
                    "
                >
                    <MMenuItem
                        v-if="isPivotArchived"
                        :label="__('common:actions.unarchive_of_all_organizations')"
                        @click="toggleArchiveForAll"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-box-open"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-else
                        :label="__('common:actions.archive_of_all_organizations')"
                        @click="toggleArchiveForAll"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-archive"
                            />
                        </template>
                    </MMenuItem>
                </template>
                <MMenuItem
                    :disabled="isUsed"
                    :label="__('common:actions.delete')"
                    variant="danger"
                    @click="deleteItem"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
        <UpdateBalanceTypeModal
            ref="updateBalanceTypeModal"
            :model="model"
            :shared="isUsedInAnotherOrganization"
            @updated="onUpdatedItem"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import UpdateBalanceTypeModal from '@/modules/human-resources/components/balance-type/Modals/UpdateBalanceTypeModal.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import type BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import moment from 'moment';
    import type BalanceTypeOrganizationPivot from '@/modules/human-resources/models/BalanceTypeOrganizationPivot';
    import useAuth from '@/modules/app/composables/useAuth';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {UpdateBalanceTypeModal},
        props: {
            model: {type: Object as PropType<BalanceTypeModel>, required: true},
            organizationPivot: {type: Object as PropType<BalanceTypeOrganizationPivot>, required: true},
            isPivotArchived: {type: Boolean, required: true},
            isUsedInAnotherOrganization: {type: Boolean, required: true},
            isUsed: {type: Boolean, required: true},
        },
        setup(props, {emit}) {
            const {legacyUser: user} = useAuth();
            const organizationsPivot = props.model.organizationsPivots().value();

            const deleteItem = async function () {
                if (props.isUsed) {
                    useNotification().error(__('hr_balance:balance_cannot_be_deleted'));
                } else {
                    if (props.isUsedInAnotherOrganization) {
                        // Used elsewhere, detach from my organization
                        await props.organizationPivot.delete();
                    } else {
                        // Used only in my organization, delete item
                        await props.model.delete();
                    }
                    emit('deleted', props.model);
                }
            };

            const toggleArchive = function () {
                const pivot = props.organizationPivot;
                if (pivot.attributes.archived_at === null) {
                    pivot.attributes.archived_at = moment().toISOString(true);
                } else {
                    pivot.attributes.archived_at = null;
                }
                pivot.save();
            };

            const toggleArchiveForAll = function () {
                organizationsPivot.each(pivot => {
                    if (pivot.attributes.archived_at === null) {
                        pivot.attributes.archived_at = moment().toISOString(true);
                    } else {
                        pivot.attributes.archived_at = null;
                    }
                    pivot.save();
                });
            };

            const onUpdatedItem = function (value) {
                emit('updated', value);
            };

            return {
                emit,
                user,
                toggleArchiveForAll,
                toggleArchive,
                deleteItem,
                onUpdatedItem,
            };
        },
    });
</script>
