<script setup lang="ts">
    import {computed, onMounted, ref, watch} from 'vue';
    import {cloneDeep} from 'lodash-es';
    import {
        organizationDeviceViaBroadcast,
        parentDeviceViaBroadcast,
    } from '@/modules/organization/utils/DeviceViaBroadcast';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import useAbility from '@/modules/app/composables/useAbility';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import useAuth from '@/modules/app/composables/useAuth';
    import DevicePolicies from '@/modules/organization/components/Devices/DevicePolicies.vue';
    import DeviceReportingMandatory from '@/modules/organization/components/Devices/DeviceReportingMandatory.vue';
    import type DeviceReportingMandatoryFor from '@/modules/organization/utils/DeviceReportingMandatoryFor';

    const props = defineProps<{
        device: {
            account_id: string;
            id: string;
            device_name: string;
            nurseries: {id: string}[];
            device_policies: string[];
            device_reporting_author_mandatory_for: DeviceReportingMandatoryFor[];
            via_broadcast: string[];
            via_mail: string[];
            showNotif: boolean;
            showCredentials: boolean;
            showReportingAuthorMandatory: boolean;
        };
        modal: ModalType;
    }>();

    const emit = defineEmits<{
        (e: 'update', device: object, organizations_ids: string[]): void;
        (e: 'delete', device: object): void;
    }>();

    const {can} = useAbility();
    const editingDevice = ref(cloneDeep(props.device));
    const selectedOrganizations = ref<OrganizationModel[]>([]);
    const {legacyUser} = useAuth();

    // Utilisé pour cacher le bouton "Tout sélectionner /Tout désélectionner" si le user n'a pas toutes les crèches pour lesquelles la device serait affecté
    const displaySelectAllOptions = computed(() => {
        const countOrganizationsInAccount = OrganizationModel.query()
            .where('account_id', legacyUser.value.account_id)
            .count();

        return legacyUser.value.is_owner || countOrganizationsInAccount === legacyUser.value.nurseries.length;
    });

    watch(
        () => editingDevice.value.via_broadcast,
        (val: string[]) => {
            editingDevice.value.via_mail = val;
        },
        {immediate: true}
    );

    function updateDevice() {
        emit(
            'update',
            editingDevice.value,
            selectedOrganizations.value.map(organization => organization.id as string)
        );
    }

    function deleteDevice() {
        emit('delete', editingDevice.value);
    }

    function toggleDeviceViaBroadcast(policy: string, value: boolean, secondPolicy?: string) {
        const index = editingDevice.value.via_broadcast.findIndex((item: string) => item === policy);

        if (value && index === -1) {
            editingDevice.value.via_broadcast.push(policy);

            if (secondPolicy) {
                editingDevice.value.via_broadcast.push(secondPolicy);
            }
        } else if (!value && index !== -1) {
            editingDevice.value.via_broadcast.splice(index, 1);

            if (secondPolicy) {
                editingDevice.value.via_broadcast = editingDevice.value.via_broadcast.filter(
                    (item: string) => item !== secondPolicy
                );
            }
        } else {
            throw new Error('Toggle device via broadcast error');
        }
    }

    onMounted(async () => {
        const organizations = await OrganizationModel.query()
            .whereIn(
                'id',
                editingDevice.value.nurseries.map(nursery => nursery.id)
            )
            .get();

        selectedOrganizations.value = organizations.toArray();
    });
</script>

<template>
    <MModal
        :header-title="editingDevice.device_name"
        :modal="modal"
    >
        <CForm>
            <MInputGroup
                v-model="editingDevice.device_name"
                :label="__('organization:device_name')"
                :placeholder="__('organization:device_name')"
                @keyup.enter="updateDevice"
            />
            <CFormGroup class="tw-mt-4">
                <MButton
                    class="tw-w-full"
                    icon-menu
                    :label="__('organization:notifications_received')"
                    @click="editingDevice.showNotif = !editingDevice.showNotif"
                />
                <div
                    v-if="editingDevice.showNotif"
                    class="tw-mt-2 tw-pl-2"
                >
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
                        <CFormGroup>
                            <CLabel>{{ __('common:parent_other') }}</CLabel>
                            <CCheckbox
                                v-for="parentDeviceViaBroadcastItem in parentDeviceViaBroadcast()"
                                :key="'parentDeviceViaBroadcastItem' + parentDeviceViaBroadcastItem.value"
                                :value="editingDevice.via_broadcast.includes(parentDeviceViaBroadcastItem.value)"
                                @input="toggleDeviceViaBroadcast(parentDeviceViaBroadcastItem.value, $event)"
                            >
                                {{ parentDeviceViaBroadcastItem.label }}
                            </CCheckbox>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('common:direction') }}</CLabel>
                            <CCheckbox
                                v-for="organizationDeviceViaBroadcastItem in organizationDeviceViaBroadcast()"
                                :key="'organizationDeviceViaBroadcastItem' + organizationDeviceViaBroadcastItem.value"
                                :value="editingDevice.via_broadcast.includes(organizationDeviceViaBroadcastItem.value)"
                                @input="
                                    toggleDeviceViaBroadcast(
                                        organizationDeviceViaBroadcastItem.value,
                                        $event,
                                        organizationDeviceViaBroadcastItem.secondValue
                                    )
                                "
                            >
                                {{ organizationDeviceViaBroadcastItem.label }}
                            </CCheckbox>
                        </CFormGroup>
                    </div>
                    <div class="tw-mt-2 tw-text-sm tw-text-gray-500">
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-exclamation-triangle"
                        />
                        {{ __('organization:only_applied_to_future_messages') }}
                    </div>
                </div>
            </CFormGroup>
            <CFormGroup>
                <MButton
                    class="tw-w-full"
                    icon-menu
                    :label="__('organization:accessible_menus')"
                    @click="editingDevice.showCredentials = !editingDevice.showCredentials"
                />
                <div
                    v-if="editingDevice.showCredentials"
                    class="tw-mt-2 tw-pl-2"
                >
                    <DevicePolicies v-model="editingDevice.device_policies" />
                    <div class="tw-mt-2 tw-text-sm tw-text-gray-500">
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-exclamation-triangle"
                        />
                        {{ __('organization:close_and_then_open_app_to_see_changes') }}
                    </div>
                </div>
            </CFormGroup>
            <CFormGroup>
                <MButton
                    class="tw-w-full"
                    icon-menu
                    :label="__('organization:device_mandatory_author_reportings')"
                    @click="editingDevice.showReportingAuthorMandatory = !editingDevice.showReportingAuthorMandatory"
                />
                <div
                    v-if="editingDevice.showReportingAuthorMandatory"
                    class="tw-mt-2 tw-pl-2"
                >
                    <DeviceReportingMandatory v-model="editingDevice.device_reporting_author_mandatory_for" />
                </div>
            </CFormGroup>
            <CFormGroup class="tw-mt-4">
                <CLabel>{{ __('dashboard:for_organizations_colon') }}</CLabel>
                <OrganizationFinder
                    v-model="selectedOrganizations"
                    button-class="tw-w-full"
                    :display-select-all-options="displaySelectAllOptions"
                    multi
                    :multi-minimum="1"
                    only-user-organizations
                />
            </CFormGroup>
        </CForm>
        <template #footer-start>
            <MButton
                v-if="can('delete', 'devices')"
                :label="__('common:actions.delete')"
                variant="danger"
                @click="deleteDevice"
            />
        </template>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.save')"
                variant="primary"
                @click="updateDevice"
            />
        </template>
    </MModal>
</template>
