<template>
    <portal
        :disabled="alreadyOnPortal"
        to="overlay"
    >
        <div
            v-if="isModalEnabled"
            class="CModal tw-text-reset"
        >
            <transition
                appear
                name="transition-backdrop"
            >
                <div
                    v-if="isModalVisible"
                    class="CModal__overlay"
                />
            </transition>

            <div class="CModal__wrapper">
                <div
                    ref="modalWrapperEl"
                    class="CModal__content-wrapper"
                    @click="overlayClick"
                    @mousedown="fromTarget = $event.target"
                    @mouseup="toTarget = $event.target"
                >
                    <transition
                        appear
                        name="transition-modal"
                        @after-leave="outTransitionOver"
                    >
                        <div
                            v-if="isModalVisible"
                            ref="modalEl"
                            :aria-describedby="`CModal--body-${id}`"
                            :aria-labelledby="`CModal--header-${id}`"
                            aria-modal="true"
                            class="CModal__content"
                            :class="[cardBaseClass]"
                            tabindex="-1"
                            @keydown.esc="onEscapePressed"
                        >
                            <div
                                v-if="shared"
                                class="CModal__shared"
                            >
                                <CHStack
                                    align="baseline"
                                    class="shared-shadow progress-bar-striped tw-h-12 tw-rounded-t-2xl tw-bg-purple-500 tw-text-sm tw-font-bold tw-uppercase tw-text-white"
                                    distribute="center"
                                    style="padding-top: 5px"
                                >
                                    <FontAwesomeIcon
                                        class="tw-mr-1"
                                        icon="fa-solid fa-exclamation-triangle"
                                    />
                                    {{ __('app:used_by_other_organizations') }}
                                </CHStack>
                            </div>
                            <div class="CModal__box">
                                <header
                                    v-if="$slots.header || $slots['header-text'] || headerTitle"
                                    :class="[headerClass, 'CModal__header-container']"
                                >
                                    <slot name="header">
                                        <div
                                            :id="`CModal--header-${id}`"
                                            class="CModal__header"
                                        >
                                            <slot name="header-text">
                                                {{ headerTitle }}
                                            </slot>
                                        </div>
                                    </slot>
                                </header>
                                <button
                                    v-if="!hideHeaderClose"
                                    class="CModal__close-btn"
                                    type="button"
                                    @click="hide()"
                                >
                                    <font-awesome-icon icon="fa-regular fa-times" />
                                </button>
                                <template v-if="loading">
                                    <div class="CModal__loader">
                                        <RingLoaderIcon />
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        :id="`CModal--body-${id}`"
                                        class="CModal__body"
                                        :class="[bodyClass]"
                                    >
                                        <slot />
                                    </div>
                                    <footer
                                        v-if="!hideFooter"
                                        :class="[footerClass, 'CModal__footer']"
                                    >
                                        <slot
                                            :close-dialog="hide"
                                            :hide="hide"
                                            name="footer"
                                        >
                                            <div class="CModal__footer-start">
                                                <slot
                                                    :close-dialog="hide"
                                                    :hide="hide"
                                                    name="footer-start"
                                                />
                                            </div>
                                            <div class="CModal__footer-end">
                                                <slot
                                                    :close-dialog="hide"
                                                    :hide="hide"
                                                    name="footer-end"
                                                >
                                                    <MButton @click="hide()">
                                                        {{ __('common:actions.close') }}
                                                    </MButton>
                                                </slot>
                                            </div>
                                        </slot>
                                    </footer>
                                </template>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </portal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, watch} from 'vue';
    import type {Emitter} from 'mitt';
    import mitt from 'mitt';
    import useUniqueComponentId from '@/modules/app/composables/useUniqueComponentId';
    import {useToggle} from '@vueuse/core';
    import RingLoaderIcon from '@/modules/app/components/atoms/RingLoaderIcon.vue';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import type {EventType} from '@/modules/meeko-ui/utils/ModalEventType';

    export default defineComponent({
        components: {RingLoaderIcon},
        props: {
            id: {type: String, default: () => useUniqueComponentId()},
            visible: {type: Boolean, default: false},
            loading: {type: Boolean, default: false},
            shared: {type: Boolean, default: false},
            size: {type: String, default: 'lg'},
            closeOnOverlayClick: {type: Boolean, default: false},
            hideHeader: {type: Boolean, default: false},
            hideHeaderClose: {type: Boolean, default: false},
            hideFooter: {type: Boolean, default: false},
            preventEscape: {type: Boolean, default: false},
            headerTitle: {type: String, default: undefined},
            headerClass: {type: String, default: undefined},
            bodyClass: {type: String, default: undefined},
            footerClass: {type: String, default: undefined},
            emitter: {type: Object as PropType<Emitter<EventType>>, default: () => mitt()},
            modal: {type: Object as PropType<ModalType>, default: undefined},
        },
        setup(props, {emit}) {
            const [isModalEnabled] = useToggle(false);
            const [isModalVisible, toggleModal] = useToggle(false);

            const modalWrapperEl = ref();
            const modalEl = ref<Element>();
            const fromTarget = ref(null);
            const toTarget = ref(null);

            const cardBaseClass = computed(() => {
                const output = [] as string[];

                const sizes = {
                    'xs': 'sm:tw-max-w-xs',
                    'sm': 'sm:tw-max-w-sm',
                    'md': 'sm:tw-max-w-md',
                    'lg': 'sm:tw-max-w-lg',
                    'xl': 'sm:tw-max-w-xl',
                    '2xl': 'sm:tw-max-w-2xl',
                    '3xl': 'sm:tw-max-w-3xl',
                    '4xl': 'sm:tw-max-w-4xl',
                    '5xl': 'sm:tw-max-w-5xl',
                    '6xl': 'sm:tw-max-w-6xl',
                    '7xl': 'sm:tw-max-w-7xl',
                    'full': '',
                };
                output.push(sizes[props.size]);

                return output;
            });

            function show() {
                emit('show');
                isModalEnabled.value = true;
                nextTick(() => {
                    toggleModal(true);
                    nextTick(() => {
                        emit('shown');
                        if (props.modal) {
                            props.modal.bus.emit('shown');
                        }
                    });
                });
            }

            function onEscapePressed(e) {
                if (props.preventEscape) {
                    return;
                }

                e.stopPropagation();
                hide();
            }

            function hide(args: any = undefined) {
                emit('hide', args);
                toggleModal(false);
            }

            function outTransitionOver() {
                nextTick(() => {
                    isModalEnabled.value = false;

                    emit('hidden');
                    emit('closed');

                    if (props.modal) {
                        props.modal.bus.emit('hidden');
                    }
                });
            }

            function overlayClick(e) {
                if (e.target === modalWrapperEl.value && fromTarget.value === toTarget.value) {
                    if (props.closeOnOverlayClick) {
                        hide();
                    } else {
                        setTimeout(() => modalEl.value?.classList.add('animate__animated', 'animate__headShake'));
                        setTimeout(
                            () => modalEl.value?.classList.remove('animate__animated', 'animate__headShake'),
                            500
                        );
                    }
                }
            }

            props.emitter.on('open', () => show());
            props.emitter.on('close', () => hide());
            props.emitter.on('show', () => show());
            props.emitter.on('hide', () => hide());

            if (props.modal) {
                props.modal.bus.on('show', () => show());
                props.modal.bus.on('hide', () => hide());
            }

            onUnmounted(() => {
                props.emitter.all.clear();
            });

            if (props.visible) {
                show();
            }

            onMounted(() => {
                if (props.modal) {
                    props.modal.bus.emit('mounted');
                }
            });

            onBeforeUnmount(() => {
                if (props.modal) {
                    props.modal.hide();
                }
            });

            watch(isModalVisible, value => {
                emit('change', {visible: value});
            });

            const alreadyOnPortal = computed(() => props.modal?.alreadyOnPortal.value ?? false);

            return {
                isModalVisible,
                fromTarget,
                toTarget,
                cardBaseClass,
                show,
                hide,
                outTransitionOver,
                overlayClick,
                onEscapePressed,
                modalWrapperEl,
                isModalEnabled,
                modalEl,
                alreadyOnPortal,
            };
        },
    });
</script>

<style scoped>
    .CModal {
        @apply tw-relative;
        z-index: 3060;
    }

    .CModal__overlay {
        @apply tw-fixed tw-inset-0 tw-bg-gray-500/75 tw-transition-opacity;
    }

    .CModal__wrapper {
        @apply tw-fixed tw-inset-0 tw-overflow-y-auto tw-overscroll-contain;
    }

    .CModal__content-wrapper {
        @apply tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center;
    }

    .CModal__content {
        @apply tw-relative tw-flex tw-w-full tw-flex-col;
    }

    .CModal__box {
        @apply tw-w-full tw-rounded-2xl tw-bg-white tw-text-left tw-shadow-xl;
    }

    .CModal__shared {
        @apply tw-absolute tw-inset-x-0 tw--top-6;
        z-index: -1;
    }

    .CModal__header-container {
        @apply tw-relative;
        @apply tw-py-4 tw-pl-5 tw-pr-10;
        @apply tw-border-b tw-border-gray-200;
    }

    .CModal__header {
        @apply tw-font-display tw-text-xl tw-font-normal tw-text-primary-950;
    }

    .CModal__close-btn {
        @apply tw-absolute tw-right-2 tw-top-2 tw-z-10;
        @apply tw-flex tw-h-7 tw-w-7 tw-items-center tw-justify-center;
        @apply tw-transition-all tw-duration-100;
        @apply tw-rounded-lg tw-text-xl tw-text-gray-300 hover:tw-bg-gray-200 hover:tw-text-gray-500;
    }

    .CModal__loader {
        @apply tw-flex tw-justify-center tw-py-6;

        svg {
            @apply tw-h-12 tw-w-12 tw-stroke-gray-200;
        }
    }

    .CModal__body {
        @apply tw-relative tw-p-5 sm:tw-p-6;
    }

    .CModal__footer {
        @apply tw-relative tw-flex tw-shrink-0 tw-items-center tw-justify-between tw-px-5 tw-pb-5 sm:tw-px-6 sm:tw-pb-6;
    }

    .CModal__footer-start {
        @apply tw-flex tw-gap-2;
    }

    .CModal__footer-end {
        @apply tw-flex tw-gap-2;
    }

    .transition-modal {
        &-enter-active {
            @apply tw-duration-300 tw-ease-out;
        }

        &-enter {
            @apply tw-translate-y-4 tw-scale-95 tw-opacity-0 sm:tw-translate-y-0 sm:tw-scale-95;
        }

        &-enter-to {
            @apply tw-translate-y-0 tw-opacity-100 sm:tw-scale-100;
        }

        &-leave-active {
            @apply tw-duration-200 tw-ease-in;
        }

        &-leave {
            @apply tw-translate-y-0 tw-opacity-100 sm:tw-scale-100;
        }

        &-leave-to {
            @apply tw-translate-y-4 tw-opacity-0 sm:tw-translate-y-0 sm:tw-scale-95;
        }
    }

    .transition-backdrop {
        &-enter-active {
            @apply tw-duration-300 tw-ease-out;
        }

        &-enter {
            @apply tw-opacity-0;
        }

        &-enter-to {
            @apply tw-opacity-100;
        }

        &-leave-active {
            @apply tw-duration-200 tw-ease-in;
        }

        &-leave {
            @apply tw-opacity-100;
        }

        &-leave-to {
            @apply tw-opacity-0;
        }
    }

    .shared-shadow {
        box-shadow: inset 0 -16px 10px 3px theme(colors.purple.700);
    }
</style>
