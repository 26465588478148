<template>
    <ResourceFinder
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-size="buttonSize"
        :button-variant="buttonVariant"
        :has-unselect="hasUnselect"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :search-bar="searchBar"
        :unselect-value="unselectValue"
        :value="value"
        :wrapper="wrapper"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @input="onChange"
        @search="search = $event"
    >
        <template #button-text-empty>
            <slot name="button-text-empty">
                {{ __('components:choose_organization') }}
            </slot>
        </template>

        <template #button-text="{value}">
            {{ value.attributes.name }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{ __('components:count_item', {count: values.length - 1, value: orderizeValues(values)}) }}
            </slot>
        </template>

        <template #default="{option, searchValue}">
            <div class="tw-flex tw-flex-row tw-items-center">
                <CBadge
                    v-if="option.organizations().value().first().attributes.address.city"
                    class="tw-mr-2 tw-whitespace-nowrap tw-text-sm tw-font-semibold tw-text-blue-500"
                    size="sm"
                    variant="primary"
                >
                    {{ option.organizations().value().first().attributes.address.city }}
                </CBadge>
                <div
                    class="tw-truncate"
                    v-html="highlight(searchValue, option.attributes.name)"
                />
            </div>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type {Model, QueryBuilder} from '@meekohq/lumos';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import useManager from '@/modules/app/composables/useManager';
    import TenantModel from '@/modules/cashier/models/TenantModel';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            value: {type: [Object, Array] as PropType<Model | Model[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            searchBar: {type: Boolean, default: true},
            searchOnOrganizationsFields: {
                type: Array as PropType<string[]>,
                default: () => ['company_name'],
                required: false,
            },
            unselectValue: {},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
            buttonSize: {},
            onlyUserOrganizations: {type: Boolean, default: false},
            wrapper: {type: String as PropType<'CPopover' | 'ResourceFinderWrapper'>, default: 'CPopover'},
        },
        emits: ['input'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const {activeOrganization} = useManager();
            const onChange = function (value) {
                emit('input', value);
            };
            // !!! END: DO NOT EDIT !!

            const queryBuilder = computed(() => {
                const builder = TenantModel.query();

                builder.whereHas(new TenantModel().organizations(), query => {
                    // Only get tenants that are related to the current user organizations
                    if (props.onlyUserOrganizations) {
                        OrganizationModel.belongingToAuthUserScope(query);
                    }
                });

                builder.where(query => {
                    query
                        .whereLike('name', `*${search.value}*`)
                        .orWhereHas(new TenantModel().organizations(), query1 => {
                            // Filter by organization fields if needed
                            query1.where(query2 => {
                                props.searchOnOrganizationsFields.forEach((field, fieldIdx) => {
                                    if (fieldIdx === 0) {
                                        query2.whereLike(field, `*${search.value}*`);
                                    } else {
                                        query2.orWhereLike(field, `*${search.value}*`);
                                    }
                                });
                            });
                        });
                });

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder.with(new TenantModel().organizations()).orderBy('name');
            });

            const onFallback = function () {
                //
            };

            // Method to orderize values in order to display tenant from "activeOrganization" + 2 others" in selector button
            const orderizeValues = function (tenants: TenantModel[]) {
                const tenant = activeOrganization.value
                    .tenants()
                    .value()
                    .first(activeOrganizationTenant => {
                        return !!tenants.find(tenant => tenant.getKey() === activeOrganizationTenant.getKey());
                    });
                if (tenant) {
                    return tenant.attributes.name;
                } else {
                    return tenants[0].attributes.name;
                }
            };

            return {
                orderizeValues,
                search,
                onChange,
                onFallback,
                queryBuilder,
                ...useHighlighter(),
            };
        },
    });
</script>
