<script setup lang="ts">
    import {type PropType, provide, ref, toRef} from 'vue';
    import MFloatingContext from '@/modules/meeko-ui/components/MFloatingContext';
    import type {Placement} from '@floating-ui/vue';

    const props = defineProps({
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom-start',
            validator: (value: string) =>
                [
                    'top',
                    'top-start',
                    'top-end',
                    'right',
                    'right-start',
                    'right-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'left',
                    'left-start',
                    'left-end',
                ].includes(value),
        },
        portal: {
            type: Boolean,
            default: true,
        },
        arrow: {
            type: Boolean,
            default: false,
        },
    });

    const context = MFloatingContext({
        placement: toRef(props, 'placement'),
        trigger: ref('click'),
        portal: toRef(props, 'portal'),
        arrow: toRef(props, 'arrow'),
        offset: ref(props.arrow ? 11 : 5),
    });

    provide('MFloatingContext', context);
</script>

<template>
    <div class="MMenu">
        <slot />
    </div>
</template>

<style scoped>
    .MMenu {
        @apply tw-inline-flex;
    }
</style>
