<template>
    <component
        :is="domTag"
        v-bind="properties"
        :class="style"
        :disabled="disabled || loading"
        v-on="$listeners"
    >
        <CInline
            align-y="baseline"
            space="sm"
        >
            <div
                v-if="hasIcon"
                class="CDropdownMenuItem-icon"
            >
                <slot name="icon">
                    <font-awesome-icon
                        fixed-width
                        :icon="icon"
                    />
                    <font-awesome-icon
                        v-if="loading"
                        icon="fa-duotone fa-sync-alt"
                        spin
                    />
                </slot>
            </div>
            <div class="CDropdownMenuItem-content">
                <slot>
                    {{ label }}
                </slot>
            </div>
        </CInline>
    </component>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, useSlots} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            label: {type: String, required: false},
            active: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            loading: {type: Boolean, default: false},
            size: {type: String as PropType<string>, default: 'base'},
            variant: {type: String as PropType<string>, default: 'light'},
            to: {type: Object, default: undefined},
            icon: {type: String as PropType<string>},
            evenChild: {type: Boolean as PropType<boolean>},
        },
        setup(props) {
            const slots = useSlots();

            const hasIcon = computed(() => !!slots.icon?.() || !!props.icon || false);

            const domTag = computed(() => {
                return props.to ? 'router-link' : 'button';
            });

            const disabledState = ref(false);

            const properties = computed(() => {
                if (props.to) {
                    return {
                        to: props.to,
                    };
                }

                return {};
            });

            const style = computed(() => {
                const output = [] as any[];

                output.push('CDropdownMenuItem');

                if (props.active) {
                    output.push('CDropdownMenuItem-active');
                }

                if (props.evenChild) {
                    output.push('CDropdownMenuItem-even-child');
                }

                output.push(sizeValues[props.size]);
                output.push(variantValues[props.variant]);

                return output;
            });

            return {
                domTag,
                properties,
                style,
                disabledState,
                hasIcon,
            };
        },
    });

    export const sizeValues = {
        base: 'CDropdownMenuItem-base',
        sm: 'CDropdownMenuItem-sm',
    };

    export const variantValues = {
        light: 'CDropdownMenuItem-light',
        danger: 'CDropdownMenuItem-danger',
        yellow: 'CDropdownMenuItem-yellow',
        blue: 'CDropdownMenuItem-blue',
        red: 'CDropdownMenuItem-red',
    };
</script>

<style scoped>
    .CDropdownMenuItem {
        @apply tw-block tw-cursor-pointer tw-rounded-lg tw-text-left tw-no-underline;
        &:disabled {
            @apply tw-cursor-not-allowed tw-opacity-50;
        }
    }

    .CDropdownMenuItem-base {
        @apply tw-px-3 tw-py-2 tw-text-base;
    }

    .CDropdownMenuItem-content {
        @apply tw-flex-1 tw-font-medium;
    }

    .CDropdownMenuItem-sm {
        @apply tw-px-2 tw-py-1 tw-text-sm;
    }

    .CDropdownMenuItem-even-child {
        @apply even:tw-bg-gray-50;
    }

    .CDropdownMenuItem-light {
        @apply tw-text-black hover:tw-bg-primary-400 hover:tw-text-white;

        .CDropdownMenuItem-icon {
            @apply tw-flex tw-items-center tw-gap-1 tw-text-primary-400;
        }

        &:hover,
        &.CDropdownMenuItem-active {
            .CDropdownMenuItem-icon {
                @apply tw-text-primary-100;
            }
        }

        &.CDropdownMenuItem-active {
            @apply tw-bg-primary-400 tw-text-white;
        }
    }

    .CDropdownMenuItem-danger {
        @apply tw-text-red-500 hover:tw-bg-red-400 hover:tw-text-white;

        .CDropdownMenuItem-icon {
            @apply tw-text-red-400;
        }

        &:hover,
        &.CDropdownMenuItem-active {
            .CDropdownMenuItem-icon {
                @apply tw-text-red-100;
            }
        }

        &.CDropdownMenuItem-active {
            @apply tw-bg-red-400 tw-text-white;
        }
    }

    .CDropdownMenuItem-yellow {
        @apply tw-text-yellow-700 hover:tw-bg-yellow-600 hover:tw-text-white;

        .CDropdownMenuItem-icon {
            @apply tw-text-yellow-600;
        }

        &:hover,
        &.CDropdownMenuItem-active {
            .CDropdownMenuItem-icon {
                @apply tw-text-yellow-300;
            }
        }

        &.CDropdownMenuItem-active {
            @apply tw-bg-yellow-600 tw-text-white;
        }
    }

    .CDropdownMenuItem-blue {
        @apply tw-text-blue-500 hover:tw-bg-blue-400 hover:tw-text-white;

        .CDropdownMenuItem-icon {
            @apply tw-text-blue-400;
        }

        &:hover,
        &.CDropdownMenuItem-active {
            .CDropdownMenuItem-icon {
                @apply tw-text-blue-100;
            }
        }

        &.CDropdownMenuItem-active {
            @apply tw-bg-blue-400 tw-text-white;
        }
    }

    .CDropdownMenuItem-red {
        @apply tw-text-red-600 hover:tw-bg-red-500 hover:tw-text-white;

        .CDropdownMenuItem-icon {
            @apply tw-text-red-500;
        }

        &:hover,
        &.CDropdownMenuItem-active {
            .CDropdownMenuItem-icon {
                @apply tw-text-red-200;
            }
        }

        &.CDropdownMenuItem-active {
            @apply tw-bg-red-500 tw-text-white;
        }
    }

    .CDropdownMenuItem-icon {
        @apply tw--ml-1 tw-flex;
    }
</style>
