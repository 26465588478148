<template>
    <loader v-if="loading" />
    <div v-else-if="config">
        <div class="tw-mb-4">
            <support-meeko
                name="Config"
                :ressource="config"
            />

            <div class="tw-grid tw-grid-cols-12 tw-gap-4 lg:tw-gap-8">
                <CFormGroup
                    v-if="can('update', 'invoices')"
                    class="tw-order-2 tw-col-span-full tw-flex tw-min-w-0 tw-flex-col sm:tw-col-span-6 lg:tw-order-1 lg:tw-col-span-4"
                >
                    <CLabel>{{ __('billing_invoice:original_configuration_colon') }}</CLabel>
                    <CButtonGroup class="tw-w-full">
                        <MButton class="tw-w-full tw-justify-between">
                            {{ config.name }}
                        </MButton>
                        <MButton
                            class="tw-shrink-0"
                            variant="primary"
                            @click="importConfig"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-download" />
                            </template>
                            {{ __('common:actions.import') }}
                        </MButton>
                    </CButtonGroup>
                </CFormGroup>

                <CFormGroup
                    v-if="can('update', 'invoices')"
                    class="tw-order-3 tw-col-span-full tw-flex tw-min-w-0 tw-flex-col sm:tw-col-span-6 lg:tw-order-2 lg:tw-col-span-4"
                >
                    <CLabel>{{ __('billing_setting:configuration_colon') }}</CLabel>
                    <MButton
                        class="tw-justify-between"
                        icon-menu
                        :label="__('billing_invoice:customize_configuration')"
                        @click="showConfig = !showConfig"
                    />
                </CFormGroup>

                <CFormGroup
                    class="tw-order-1 tw-col-span-full tw-flex tw-min-w-0 tw-flex-col sm:tw-col-span-6 lg:tw-order-3 lg:tw-col-span-4"
                >
                    <CLabel>{{ __('billing_invoice:presence_tariff_colon') }}</CLabel>

                    <!-- Monthly price rate -->
                    <CButtonGroup v-if="config.billing && config.billing.cycle === 'month'">
                        <MButton
                            v-if="can('update', 'invoices')"
                            class="tw-shrink-0"
                            @click="rateLocked = !rateLocked"
                        >
                            <FontAwesomeIcon
                                v-if="rateLocked"
                                class="tw-text-primary-500"
                                icon="fa-solid fa-pencil"
                            />
                            <FontAwesomeIcon
                                v-else
                                class="tw-text-primary-500"
                                icon="fa-solid fa-rotate-left"
                            />
                        </MButton>
                        <MInput
                            v-if="rateLocked"
                            disabled
                            placeholder="10"
                            type="text"
                            :value="computedMonthlyRate"
                        />
                        <MCurrencyInput
                            v-else
                            v-model="config.pricing.amount"
                            class="tw-w-full"
                            :currency-symbol="nursery.currency"
                            :disabled="!can('update', 'invoices')"
                            :parser-options="parserOptionsCurrencyPerHour"
                            placeholder="10"
                        >
                            <template #addon>
                                <FontAwesomeIcon
                                    v-if="priceLoading"
                                    class="tw-text-primary-500"
                                    icon="fa-solid fa-circle-notch"
                                    spin
                                />
                                <span v-else>
                                    {{ __('common:currency_per_month', {currency: nursery.currency}) }}
                                </span>
                            </template>
                        </MCurrencyInput>
                    </CButtonGroup>

                    <!-- Hourly price rate -->
                    <CButtonGroup v-else>
                        <MTooltip
                            :label="
                                rateLocked
                                    ? __('billing_invoice:set_a_manual_rate')
                                    : __('billing_invoice:return_to_calculated_rate')
                            "
                        >
                            <MButton
                                v-if="can('update', 'invoices')"
                                class="tw-shrink-0"
                                @click="toggleManualPrice"
                            >
                                <FontAwesomeIcon
                                    v-if="rateLocked"
                                    class="tw-text-primary-500"
                                    icon="fa-solid fa-pencil"
                                />
                                <FontAwesomeIcon
                                    v-else
                                    class="tw-text-primary-500"
                                    icon="fa-solid fa-rotate-left"
                                />
                            </MButton>
                        </MTooltip>

                        <MInput
                            v-if="rateLocked"
                            disabled
                            placeholder="10"
                            type="text"
                            :value="computedHourlyRate"
                        />
                        <MCurrencyInput
                            v-else
                            v-model="config.pricing.amount"
                            class="tw-w-full"
                            :currency-symbol="nursery.currency"
                            :disabled="!can('update', 'invoices')"
                            :parser-options="parserOptionsCurrencyPerHour"
                            placeholder="10"
                        >
                            <template #addon>
                                <FontAwesomeIcon
                                    v-if="priceLoading"
                                    class="tw-text-primary-500"
                                    icon="fa-solid fa-spin fa-circle-notch"
                                />
                                <span v-else>
                                    {{ __('common:currency_per_hour', {currency: nursery.currency}) }}
                                </span>
                            </template>
                        </MCurrencyInput>
                    </CButtonGroup>

                    <div
                        v-if="!rateLocked && config.billing && config.billing.cycle !== 'month'"
                        class="tw-mt-2 tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-sm tw-text-gray-500"
                    >
                        <FontAwesomeIcon
                            class="tw-text-lg tw-text-gray-500"
                            icon="fa-duotone fa-circle-info"
                        />
                        <span
                            v-html="
                                __('billing_invoice:compute_tariff_details', {
                                    tariff: useFormatCurrency(CurrencySymbolToIsoCode[nursery.currency]).format(
                                        computedHourlyRate,
                                        {minFractionDigits: 2}
                                    ),
                                    variation: useFormatCurrency(CurrencySymbolToIsoCode[nursery.currency]).format(
                                        Math.round((computedHourlyRate - config.pricing.amount) * 100).toFixed(2) / 100,
                                        {minFractionDigits: 2}
                                    ),
                                })
                            "
                        />
                    </div>
                </CFormGroup>
            </div>
        </div>

        <div
            v-if="showConfig && can('update', 'invoices')"
            class="tw-mb-4 tw-flex tw-flex-col tw-gap-4"
        >
            <BasicCard
                :config="config"
                :loading="priceLoading"
                :nursery="nursery"
                :response-config="responseConfig"
            />
            <PsuPriceCard
                v-if="config.funding === 'psu' || config.funding === 'psu_cmg'"
                :config="config"
                :nursery="nursery"
            />
            <NormalPriceCard
                v-else
                :config="config"
                :nursery="nursery"
            />
            <FeesCard
                :config="config"
                :nursery="nursery"
            />
            <ExtraCard
                :config="config"
                :nursery="nursery"
            />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import swal from 'sweetalert2/dist/sweetalert2';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _orderBy from 'lodash-es/orderBy';
    import defaultConfig from '@/modules/legacy/contents/defaultConfig';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import CurrencySymbolToIsoCode from '@/modules/cashier/utils/core/currency/CurrencySymbolToIsoCode';
    import Basic from '@/modules/cashier/components/settings/RulesCards/Basic.vue';
    import Fees from '@/modules/cashier/components/settings/RulesCards/Fees.vue';
    import Extra from '@/modules/cashier/components/settings/RulesCards/Extra.vue';
    import PriceNormal from '@/modules/cashier/components/settings/RulesCards/Price/Normal.vue';
    import PricePSU from '@/modules/cashier/components/settings/RulesCards/Price/PSU.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import {getNumericParserContractPresetCurrencyPerHour} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import i18next from 'i18next';

    export default {
        components: {
            BasicCard: Basic,
            FeesCard: Fees,
            ExtraCard: Extra,
            NormalPriceCard: PriceNormal,
            PsuPriceCard: PricePSU,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            contract: {
                type: Object,
                required: true,
            },
            config: {
                type: Object,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            responseConfig: {
                type: Object,
                default: null,
            },
            computedMonthlyRate: {
                type: Number,
                default: null,
            },
            computedHourlyRate: {
                type: Number,
                default: null,
            },
            priceLoading: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            loading: false,
            configs: [],
            showConfig: false,
            rateLocked: true,
            showSupport: false,
            CurrencySymbolToIsoCode,
            can: useAbility().can,
            parserOptionsCurrencyPerHour: getNumericParserContractPresetCurrencyPerHour(i18next.language),
        }),
        watch: {
            'contract.id': function () {
                this.autoSelectConfig();
            },
            'config': function (val) {
                val === null ? this.selectConfig(this.configs[0].id) : null;
            },
            'config.billing.cycle': function (val) {
                if (val === 'month') {
                    this.config.pricing.discount.on_presence = 'none';
                }
            },
        },
        mounted() {
            this.indexConfigs();
            if (this.config && this.config.pricing) {
                // Unlock price when amount is set
                this.rateLocked = !this.config.pricing.amount;
            }
        },
        methods: {
            useFormatCurrency,
            indexConfigs() {
                this.configs = _cloneDeep(this.nursery.billingConfigs);
                this.configs = _orderBy(this.configs, 'default', 'desc');
                this.configs.push(_cloneDeep(defaultConfig));
                this.autoSelectConfig();
            },
            importConfig() {
                const options = {};
                this.configs.forEach(config => {
                    config.id ? (options[config.id] = config.name) : null;
                });
                options['new'] = __('billing_invoice:blank_configuration');
                swal({
                    title: __('billing_invoice:which_configuration_want_import'),
                    text: __('billing_invoice:param_will_be_copy_into_this_contract'),
                    type: null,
                    confirmButtonClass: 'btn btn-info tw-mr-2',
                    confirmButtonText: __('common:actions.validate'),
                    cancelButtonText: __('common:actions.cancel'),
                    input: 'select',
                    inputOptions: options,
                }).then(result => {
                    if (result.value) {
                        if (result.value === 'new') {
                            this.selectConfig(0);
                        } else {
                            this.selectConfig(result.value);
                        }
                    }
                });
            },
            attachConfig(configId) {
                useApi()
                    .legacy.post(
                        route('nurseries.billingConfigs.attach', {
                            nurseries: this.nursery.id,
                            billingConfig: configId,
                        })
                    )
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            selectConfig(id) {
                const config = this.configs.find(item => item.id === id);
                if (config) {
                    const newConfig = _cloneDeep(config);
                    newConfig.id = null;
                    if (this.contract.config && this.contract.config.id) {
                        newConfig.id = this.contract.config.id;
                    }
                    config.id ? (newConfig.original_config_id = config.id) : null;

                    // Keep track of the fact that the config is under edition and has already been set once
                    newConfig.underEdition = true;

                    Vue.set(this.contract, 'config', newConfig);
                    if (this.config && this.config.pricing) {
                        // Unlock price when amount is set
                        this.rateLocked = !this.config.pricing.amount;
                    }
                }
            },
            autoSelectConfig() {
                // If the contract is under edition, do not select a config, the user changed tabs
                if (this.contract?.config?.underEdition) {
                    return;
                }

                // If no config is set and the contract has a config, select it, otherwise select the first config
                if (this.contract?.config?.id) {
                    this.selectConfig(this.contract.config.id);
                } else {
                    this.selectConfig(this.configs[0].id);
                }
            },
            toggleManualPrice() {
                this.rateLocked = !this.rateLocked;
                if (this.config.billing && this.config.billing.cycle === 'month') {
                    this.rateLocked
                        ? (this.config.pricing.amount = null)
                        : (this.config.pricing.amount = this.computedMonthlyRate);
                } else {
                    this.rateLocked
                        ? (this.config.pricing.amount = null)
                        : (this.config.pricing.amount = this.computedHourlyRate);
                }
            },
        },
    };
</script>
