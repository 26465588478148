<template>
    <CForm @submit.prevent="save">
        <CFormGroup>
            <CLabel>
                {{ __('common:name') }}*
                <MHelpTooltip class="tw-ml-2">
                    {{ __('hr:job_name_appear_on_contract') }}
                </MHelpTooltip>
            </CLabel>
            <CInput
                v-model="jobModel.attributes.name"
                v-focus="focus"
            />
        </CFormGroup>
        <CFormSubmitTrigger />
    </CForm>
</template>

<script>
    import Manager from '@/modules/legacy/store/manager.store';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        props: {
            jobModel: {
                required: true,
            },
        },

        data() {
            return {
                focus: true,
                organizationModel: Manager.activeOrganization,
            };
        },

        methods: {
            async save() {
                this.$emit('loading', true);
                try {
                    if (!this.jobModel.exist) {
                        await this.jobModel.attachToOrga(this.organizationModel);
                    }
                    await this.jobModel.save();
                    this.$emit('saved', this.jobModel);
                    this.$emit('loading', false);
                } catch (error) {
                    this.$emit('loading', false);
                    useNotification().error(__('common:error'));
                }
            },
        },
    };
</script>

<style scoped></style>
