<script setup lang="ts">
    import __ from '@/modules/app/utils/i18n-facade';
    import {ref} from 'vue';

    defineProps<{
        value: string[];
    }>();

    const emit = defineEmits(['input']);

    const options = ref([
        {value: 'past', text: __('news:past_news')},
        {value: 'current', text: __('news:current_news')},
        {value: 'future', text: __('news:future_news')},
    ]);
</script>

<template>
    <CFormSelect
        multi
        :options="options"
        :search-bar="false"
        :value="value"
        @input="emit('input', $event)"
    >
        <template #fallback>
            {{ __('news:all_news') }}
        </template>
    </CFormSelect>
</template>
