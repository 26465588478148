import {isNil, parseInt} from 'lodash-es';
import type {
    NumericParserContract,
    NumericParserContractOptions,
} from '@/modules/core/infrastructure/NumericParserContract';

type NumericStringSpecs = NumericStringSpecsInvalid | NumericStringSpecsValid;

interface NumericStringSpecsInvalid {
    isValid: false;
}

interface NumericStringSpecsValid {
    isValid: true;
    isNegative?: boolean;
    integerStartIndex: number;
    integerEndIndex: number;
    fractionalStartIndex?: number;
    fractionalEndIndex?: number;
}

/**
 * A class for parsing numeric strings in inputs. It can parse floats and integers.
 * The class is locale-aware and can parse numbers with different fractional separators.
 * The class can also round the fractional part of the number to a specified number of digits.
 */
export class NumericParser implements NumericParserContract {
    private internalValue: number | undefined;

    public constructor(protected options: NumericParserContractOptions) {}

    /**
     * Parses the numeric string and returns the number. If the string is not a valid number, NaN is returned.
     * @param value
     */
    public parse(value?: string): this {
        if (!value) {
            this.internalValue = NaN;

            return this;
        }

        const specs = this.extractNumericStringSpecs(value);
        if (!specs.isValid) {
            this.internalValue = NaN;

            return this;
        }

        let {result} = this.parseSubstr(value.slice(specs.integerStartIndex, specs.integerEndIndex));

        if (this.options.fractionalOptions && !isNil(specs.fractionalStartIndex) && !isNil(specs.fractionalEndIndex)) {
            const {result: fractionalPart, shiftCount} = this.parseSubstr(
                value.slice(specs.fractionalStartIndex, specs.fractionalEndIndex)
            );

            // Calculate the number of digits in the fractional part.
            const digitsCount = this.countNumberDigits(fractionalPart) + shiftCount;

            // Calculate the divisor to shift the fractional part to the right position
            const divisor = Math.pow(10, digitsCount);

            // Add the factorial part to the integer and round it
            result = this.transformFractionalPart(result + fractionalPart / divisor);
        }

        if (specs.isNegative && this.options.allowNegative) {
            result = -result;
        }

        this.internalValue = result;

        return this;
    }

    public setValue(value: number | undefined): this {
        this.internalValue = value;

        return this;
    }

    public asNumber(): number {
        if (isNil(this.internalValue)) {
            throw new Error('The value has not been parsed yet.');
        }

        return this.internalValue;
    }

    public asString(): string | undefined {
        if (isNil(this.internalValue)) {
            throw new Error('The value has not been parsed yet.');
        }

        if (isNaN(this.internalValue)) {
            return undefined;
        }

        const integerPart = this.integerPartToString(this.internalValue);
        const fractionalPart = this.fractionalPartToString(this.internalValue);

        return integerPart + (fractionalPart ? this.options.localeOptions.fractionalSeparator + fractionalPart : '');
    }

    private countNumberDigits(value: number): number {
        if (value === 0) {
            return 1;
        } else {
            return Math.floor(Math.log10(value)) + 1;
        }
    }

    private countFractionalDigits(value: number): number {
        // Convert the number to a string
        let valueStr = value.toString();

        // Check if the number is in scientific notation
        if (valueStr.includes('e')) {
            const exponent = parseInt(valueStr.split('e')[1], 10);
            const decimalPlaces = Math.max(0, -exponent);
            // Convert to full decimal string without scientific notation
            valueStr = Number(value).toFixed(decimalPlaces);
        }

        // Find the decimal point position
        const decimalPointIndex = valueStr.indexOf('.');

        // If there is no fractional part, return 0
        if (decimalPointIndex === -1) {
            return 0;
        }

        // Extract the fractional part after the decimal point
        const fractionalPart = valueStr.substring(decimalPointIndex + 1);

        // The length of the fractional part is the count of fractional digits
        return fractionalPart.length;
    }

    /**
     * Converts the integer part of the number to a string and adds thousands separators if present.
     * @param value
     * @private
     */
    private integerPartToString(value: number): string {
        let result = Math.abs(Math.trunc(value)).toString();

        if (!this.options.localeOptions.thousandsSeparator) {
            if (value < 0 && this.options.allowNegative) {
                return '-' + result;
            }

            return result;
        }

        // Start inserting separators from the end, but skip the first group of three
        const parts: string[] = [];
        while (result.length > 3) {
            parts.unshift(result.slice(-3));
            result = result.slice(0, -3);
        }
        parts.unshift(result); // Add the remaining part

        result = parts.join(this.options.localeOptions.thousandsSeparator);

        if (value < 0 && this.options.allowNegative) {
            result = '-' + result;
        }

        return result;
    }

    private fractionalPartToString(value: number): string | undefined {
        const digitsCount = this.countFractionalDigits(value) ?? 0;
        let result: string = value.toFixed(digitsCount).split('.')[1];

        if (!this.options.fractionalOptions) {
            return result;
        }

        let newLength = digitsCount;
        if (digitsCount > this.options.fractionalOptions.maxFractionalDigits) {
            newLength = this.options.fractionalOptions.maxFractionalDigits;
        }
        if (digitsCount < this.options.fractionalOptions.minFractionalDigits) {
            newLength = this.options.fractionalOptions.minFractionalDigits;
        }

        if (this.options.fractionalOptions.mode === 'round') {
            result = value.toFixed(newLength).split('.')[1] ?? '';
        }

        if (this.options.fractionalOptions.mode === 'truncate') {
            const re = new RegExp(`^-?\\d+(?:.\\d{0,${newLength}})?`);
            const matched = re.exec(value.toFixed(newLength));
            result = (matched ?? [''])[0].split('.')[1] ?? '';
        }

        if (result.length < this.options.fractionalOptions.minFractionalDigits) {
            result += '0'.repeat(this.options.fractionalOptions.minFractionalDigits - result.length);
        }

        return result;
    }

    /**
     * Parses the numeric string to number, ignoring any non-numeric characters.
     * @param value
     * @private
     */
    private parseSubstr(value: string): {result: number; shiftCount: number} {
        let result = 0;
        let shiftCount = 0;
        let nonZeroOccured = false;

        for (const char of value) {
            if (char < '0' || char > '9') {
                continue;
            }

            if (!nonZeroOccured && char === '0') {
                shiftCount++;

                continue;
            }

            const numberAtIndex = parseInt(char);
            result = result * 10 + numberAtIndex;
            nonZeroOccured = true;
        }

        return {result, shiftCount};
    }

    /**
     * Rounds or truncates the fractional part of the number
     * @param value
     * @private
     */
    private transformFractionalPart(value: number): number {
        if (!this.options.fractionalOptions) {
            return value;
        }

        const factor = Math.pow(10, this.options.fractionalOptions.maxFractionalDigits);

        if (this.options.fractionalOptions.mode === 'truncate') {
            return Math.trunc(value * factor) / factor;
        }

        if (this.options.fractionalOptions.mode === 'round') {
            return Math.round(value * factor) / factor;
        }

        return value;
    }

    /**
     * Extracts numeric string specs. If the string does not contain any digits, the spec is considered invalid.
     * The fractional separator index is also extracted if present. It will always be the last occurence of the character.
     * @param value
     * @private
     */
    private extractNumericStringSpecs(value: string): NumericStringSpecs {
        let hasDigits = false;
        let fractionalSeparatorIndex: number | undefined;
        let aditionalFractionalSeparatorIndex: number | undefined;
        let isNegative = false;

        for (let i = 0; i < value.length; i++) {
            const char = value[i];

            if (char === '-' && !hasDigits) {
                isNegative = true;
            }

            if (char >= '0' && char <= '9') {
                hasDigits = true;
            }

            if (char === this.options.localeOptions.fractionalSeparator) {
                fractionalSeparatorIndex = i;
            }

            if (
                this.options.localeOptions.fractionalSeparatorAdditional &&
                char === this.options.localeOptions.fractionalSeparatorAdditional
            ) {
                aditionalFractionalSeparatorIndex = i;
            }
        }

        if (!hasDigits) {
            return {isValid: false};
        }

        if (!isNil(fractionalSeparatorIndex)) {
            return {
                isValid: true,
                isNegative,
                integerStartIndex: 0,
                integerEndIndex: fractionalSeparatorIndex,
                fractionalStartIndex: fractionalSeparatorIndex + 1,
                fractionalEndIndex: value.length,
            };
        }

        if (!isNil(aditionalFractionalSeparatorIndex)) {
            return {
                isValid: true,
                isNegative,
                integerStartIndex: 0,
                integerEndIndex: aditionalFractionalSeparatorIndex,
                fractionalStartIndex: aditionalFractionalSeparatorIndex + 1,
                fractionalEndIndex: value.length,
            };
        }

        return {
            isValid: true,
            isNegative,
            integerStartIndex: 0,
            integerEndIndex: value.length,
        };
    }
}
