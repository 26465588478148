<template>
    <CList class="tw-space-y-4">
        <template v-if="contactModels.length || memberModels.length">
            <CListSection
                v-if="contactModels.length"
                variant="orange"
            >
                <template #title>
                    {{ __('billing_customer:customer_account_contacts') }}
                </template>
                <CListRow
                    v-for="contactModel in contactModels"
                    :key="contactModel.id"
                    :hover="false"
                >
                    <NotificationsListItem :model="contactModel">
                        <NotificationsSubscriptionToggle
                            :endpoint-model="contactModel.notificationEndpoint().value()"
                            :resource-model="customerModel"
                        />
                    </NotificationsListItem>
                </CListRow>
            </CListSection>
            <CListSection
                v-if="memberModels.length"
                variant="purple"
            >
                <template #title>
                    {{ __('billing_core:parents_family') }}
                </template>
                <CListRow
                    v-for="memberModel in memberModels"
                    :key="memberModel.id"
                    :hover="false"
                >
                    <NotificationsListItem :model="memberModel">
                        <NotificationsSubscriptionToggle
                            :endpoint-model="memberModel.notificationEndpoint().value()"
                            :resource-model="customerModel"
                        />
                    </NotificationsListItem>
                </CListRow>
            </CListSection>
        </template>
        <div v-else>
            <div class="tw-p-4 tw-text-center tw-text-disabled">
                {{ __('billing_core:no_contact_or_parent_available') }}
            </div>
        </div>
    </CList>
</template>

<script setup lang="ts">
    import {watch, ref} from 'vue';
    import NotificationsListItem from '@/modules/cashier/components/core/notification/molecules/NotificationsListItem.vue';
    import NotificationsSubscriptionToggle from '@/modules/cashier/components/core/notification/atoms/NotificationsSubscriptionToggle.vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import ContactModel from '@/modules/cashier/models/ContactModel';
    import MemberModel from '@/modules/family/models/MemberModel';
    import EndpointModel from '@/modules/cashier/models/EndpointModel';
    import FamilyModel from '@/modules/family/models/FamilyModel';

    const {customerModel} = defineProps<{customerModel: CustomerModel}>();
    defineExpose({fetch});

    const contactModels = ref<ContactModel[]>([]);
    const memberModels = ref<MemberModel[]>([]);

    watch(
        () => customerModel,
        () => {
            fetch();
        },
        {immediate: true}
    );

    function fetch() {
        ContactModel.query()
            .where('customer_id', customerModel.id)
            .with(new ContactModel().notificationEndpoint(), query => {
                query.with(new EndpointModel().subscriptions());
            })
            .get()
            .then(response => {
                const models = response.all();
                models.forEach(contactModel => {
                    if (!contactModel.notificationEndpoint().value()) {
                        const endpointModel = new EndpointModel();
                        endpointModel.attributes.tenant_id = customerModel.attributes.tenant_id;
                        endpointModel.attributes.type = EndpointModel.TYPE.RESOURCE;
                        endpointModel.attributes.resource_type = contactModel.type;
                        endpointModel.attributes.resource_id = contactModel.id;
                        contactModel.notificationEndpoint().set(endpointModel);
                    }
                });
                contactModels.value = models;
            });

        MemberModel.query()
            .whereHas(new MemberModel().families(), query => {
                query.whereHas(new FamilyModel().customers(), query1 => {
                    query1.where('id', customerModel.id);
                });
            })
            .with(new MemberModel().notificationEndpoint(), query => {
                query.with(new EndpointModel().subscriptions());
            })
            .get()
            .then(response => {
                const models = response.all();
                models.forEach(memberModel => {
                    if (!memberModel.notificationEndpoint().value()) {
                        const endpointModel = new EndpointModel();
                        endpointModel.attributes.tenant_id = customerModel.attributes.tenant_id;
                        endpointModel.attributes.type = EndpointModel.TYPE.RESOURCE;
                        endpointModel.attributes.resource_type = memberModel.type;
                        endpointModel.attributes.resource_id = memberModel.id;
                        memberModel.notificationEndpoint().set(endpointModel);
                    }
                });
                memberModels.value = models;
            });
    }
</script>
