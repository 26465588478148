<template>
    <section class="SimpleSubPageTemplate">
        <header class="SimpleSubPageTemplate__header">
            <slot name="title" />
            <div
                v-if="hasActions"
                class="SimpleSubPageTemplate__actions"
            >
                <slot name="actions" />
            </div>
        </header>
        <div class="SimpleSubPageTemplate__content">
            <slot name="content" />
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, useSlots} from 'vue';

    export default {
        components: {},
        props: {
            variant: {type: String, default: 'base'},
        },
        setup() {
            const slots = useSlots();

            const hasActions = computed(() => {
                return slots['actions'] !== undefined;
            });

            return {
                slots,
                hasActions,
            };
        },
    };
</script>

<style scoped>
    .SimpleSubPageTemplate__header {
        @apply tw-rounded-t-inherit;
        @apply tw-flex tw-flex-col tw-justify-between tw-gap-6 md:tw-flex-row md:tw-items-center;
        @apply tw-mb-4 tw-mt-4;
    }

    .SimpleSubPageTemplate__actions {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-start tw-gap-2 md:tw-justify-end;
    }
</style>
