<template>
    <router-view />
</template>

<script lang="ts">
    import {store as legacystore} from '@/modules/legacy/store/store';
    import {defineComponent} from 'vue';
    import {useIdle, whenever} from '@vueuse/core';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            // Reload the page after 2 hours of inactivity
            const {idle} = useIdle(7200 * 1000);
            whenever(idle, () => location.reload());

            setTimeout(() => {
                const appLoader = document.getElementById('app-loader');
                if (appLoader) {
                    appLoader.classList.add('app-loader--hidden');
                    setTimeout(() => appLoader.remove(), 300);
                }
            }, 100);

            return {
                legacystore,
            };
        },
    });
</script>
