<template>
    <CFormGroup>
        <CLabel>
            <template #icon>
                <FontAwesomeIcon icon="fa-solid fa-mobile" />
            </template>
            {{ __('family_member:family_portal_access') }}
        </CLabel>
        <MMenu
            arrow
            class="tw-w-full"
            :portal="portal"
        >
            <MMenuButton class="tw-w-full">
                <MButton
                    class="tw-w-full tw-justify-between"
                    icon-menu
                    :size="size"
                >
                    {{ label }}
                </MButton>
            </MMenuButton>
            <MMenuItems :container-class="containerClass">
                <MMenuItem
                    v-for="key in Object.keys(options)"
                    :key="'permission-' + key"
                    :auto-close-popover="false"
                    @click="select(key)"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="selectedOptionKey === key"
                            fixed-width
                            icon="fa-solid fa-circle-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-circle"
                        />
                    </template>
                    {{ options[key].text }}
                </MMenuItem>
                <MMenuDivider />
                <MMenuItem
                    v-if="isVaccineReminderOptionAvailable"
                    :auto-close-popover="false"
                    @click="toogleVaccineReminder"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="vaccineReminder"
                            fixed-width
                            icon="fa-solid fa-circle-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-circle"
                        />
                    </template>
                    {{ __('family_member:vaccine_reminder') }}
                </MMenuItem>
                <MMenuItem
                    v-else
                    :auto-close-popover="false"
                    disabled
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-regular fa-circle"
                        />
                    </template>
                    {{ __('family_member:vaccine_reminder') }}
                </MMenuItem>
            </MMenuItems>
        </MMenu>
    </CFormGroup>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _indexOf from 'lodash-es/indexOf';
    import _intersection from 'lodash-es/intersection';
    import _remove from 'lodash-es/remove';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            value: {
                type: Array as PropType<string[]>,
                default: () => [],
            },
            size: {
                type: String,
                default: 'base',
            },
            portal: {
                type: Boolean,
                default: true,
            },
            containerClass: {
                type: String,
                default: '',
            },
        },
        setup(props, {emit}) {
            const permissions: Ref<string[] | null> = ref(null);

            // Intersect to have a clean array to compare without special options like vaccines
            const filterDefaultPermissions = computed(() => {
                return _intersection(permissions.value, defaultPermissions.all);
            });

            const defaultPermissions = {
                all: [
                    'photos',
                    'meals',
                    'temperatures',
                    'drugs',
                    'diapers',
                    'naps',
                    'weights',
                    'presences',
                    'activities',
                    'observations',
                    'messages',
                    'invoices',
                    'transactions',
                    'staffs',
                    'posts',
                    'documents',
                ],
                withoutTransmissions: [
                    'photos',
                    'messages',
                    'invoices',
                    'transactions',
                    'staffs',
                    'posts',
                    'documents',
                ],
            };

            const options = {
                all: {
                    value: defaultPermissions.all,
                    text: __('common:yes'),
                },
                withoutTransmissions: {
                    value: defaultPermissions.withoutTransmissions,
                    text: __('family_member:yes_without_transmissions'),
                },
                no: {
                    value: [],
                    text: __('common:no'),
                },
            };

            const selectedOptionKey = ref('no');

            const label = computed(() => {
                return options[selectedOptionKey.value].text;
            });

            const vaccineReminder = ref(false);

            const isVaccineReminderOptionAvailable = computed(() => {
                return ['all', 'withoutTransmissions'].includes(selectedOptionKey.value);
            });

            function select(key: string) {
                if (key === selectedOptionKey.value) {
                    return;
                }
                const newPermissions = _cloneDeep(options[key].value);
                selectedOptionKey.value = key;

                if (!newPermissions.length) {
                    _remove(newPermissions, item => item === 'vaccines');
                    vaccineReminder.value = false;
                }

                if (vaccineReminder.value) {
                    newPermissions.push('vaccines');
                }

                permissions.value = newPermissions;
                emit('input', newPermissions);
            }

            function toogleVaccineReminder() {
                if (permissions.value) {
                    vaccineReminder.value = !vaccineReminder.value;

                    const newPermissions = _cloneDeep(permissions.value);

                    if (vaccineReminder.value) {
                        newPermissions.push('vaccines');
                    } else {
                        _remove(newPermissions, item => item === 'vaccines');
                    }

                    permissions.value = newPermissions;
                    emit('input', newPermissions);
                }
            }

            watch(
                () => props.value,
                () => {
                    // Init the permissions array and select the key that matches the currently persisted option
                    if (props.value.length && permissions.value === null) {
                        permissions.value = props.value;

                        selectedOptionKey.value =
                            Object.keys(options).find(key => {
                                return options[key].value.length === filterDefaultPermissions.value.length;
                            }) ?? 'no';

                        vaccineReminder.value = _indexOf(props.value, 'vaccines') >= 0;
                    }
                },
                {immediate: true}
            );

            return {
                label,
                selectedOptionKey,
                options,
                permissions,
                select,
                toogleVaccineReminder,
                vaccineReminder,
                isVaccineReminderOptionAvailable,
                filterDefaultPermissions,
            };
        },
    });
</script>
