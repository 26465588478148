import type {FilterType} from '@/modules/app/composables/useMagicFilter';
import useMagicFilter, {type FilterOptions, setupFilter} from '@/modules/app/composables/useMagicFilter';
import type {Model} from '@meekohq/lumos';
import type {Ref} from 'vue';
import ArrayOfStringSerializer from '@/modules/magic-index/utils/serializers/ArrayOfStringSerializer';
import {merge} from 'lodash-es';
import {StoragePath} from '@/modules/magic-index/utils/serializers/StoragePath';
import NumberSerializer from '@/modules/magic-index/utils/serializers/NumberSerializer';
import ModelSerializer from '@/modules/magic-index/utils/serializers/ModelSerializer';

type Filters = 'contractStates' | 'selectedGroup' | 'tags' | 'withoutTag' | 'page';

export interface OutputType {
    contractStatesFilter: FilterType<string[]>;
    selectedGroupFilter: FilterType<string>;
    tagsFilter: FilterType<Model[]>;
    withoutTagFilter: FilterType<boolean>;
    pageFilter: FilterType<number>;
    activeCount: Ref<number>;
    isReady: Ref<boolean>;
    reset: () => void;
    allFilters: Ref<any[]>;
}

export default function useKidFiltersStore(options: Record<Filters, FilterOptions>): OutputType {
    const defaultOptions: Record<Filters, FilterOptions> = {
        contractStates: {
            defaultValue: [],
            serializer: ArrayOfStringSerializer,
            storagePath: StoragePath.filtersKidsIndexContractStates,
        },
        selectedGroup: {
            defaultValue: undefined,
            storagePath: StoragePath.filtersKidsIndexSelectedGroup,
        },
        tags: {
            defaultValue: [],
            serializer: ModelSerializer,
            storagePath: StoragePath.filtersKidsIndexTags,
        },
        withoutTag: {
            defaultValue: false,
            storagePath: StoragePath.filtersKidsIndexWithoutTag,
        },
        page: {
            defaultValue: 1,
            serializer: NumberSerializer,
            queryString: 'p',
        },
    };

    const optionsWithDefaults = merge({}, defaultOptions, options);

    const contractStatesFilter = setupFilter<string[]>(optionsWithDefaults.contractStates);
    const selectedGroupFilter = setupFilter<string>(optionsWithDefaults.selectedGroup);
    const tagsFilter = setupFilter<Model[]>(optionsWithDefaults.tags);
    const withoutTagFilter = setupFilter<boolean>(optionsWithDefaults.withoutTag);
    const pageFilter = setupFilter<number>(optionsWithDefaults.page);

    const {activeCount, isReady, reset, allFilters} = useMagicFilter(
        [contractStatesFilter, selectedGroupFilter, tagsFilter, withoutTagFilter],
        pageFilter
    );

    return {
        contractStatesFilter,
        selectedGroupFilter,
        tagsFilter,
        withoutTagFilter,
        pageFilter,
        activeCount,
        isReady,
        reset,
        allFilters,
    };
}
