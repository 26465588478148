<script setup lang="ts">
    import {type PropType, provide, toRef, watch} from 'vue';
    import MFloatingContext from '@/modules/meeko-ui/components/MFloatingContext';
    import type {Placement} from '@floating-ui/vue';

    const props = defineProps({
        trigger: {
            type: String,
            default: 'click',
            validator: (value: string) => ['none', 'click', 'hover'].includes(value),
        },
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom-start',
            validator: (value: string) =>
                [
                    'top',
                    'top-start',
                    'top-end',
                    'right',
                    'right-start',
                    'right-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'left',
                    'left-start',
                    'left-end',
                ].includes(value),
        },
        initialFocusRef: {
            type: Object,
            default: null,
        },
        portal: {
            type: Boolean,
            default: true,
        },
        popoverButtonClass: {
            type: String,
            default: '',
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
    });

    const context = MFloatingContext({
        placement: toRef(props, 'placement'),
        trigger: toRef(props, 'trigger'),
        initialFocusRefEl: toRef(props, 'initialFocusRef'),
        portal: toRef(props, 'portal'),
    });

    const emit = defineEmits(['clickOutside', 'closed']);

    const closePopover = () => {
        context.closePopover();
    };

    defineExpose({
        closePopover,
        isOpen: context.isOpen,
    });

    watch(context.isOpen, (newIsOpen, oldIsOpen) => {
        if (!newIsOpen && oldIsOpen) {
            emit('closed');
        }
    });

    provide('MFloatingContext', context);
</script>

<template>
    <div class="MPopover">
        <MPopoverButton :class="popoverButtonClass">
            <slot />
        </MPopoverButton>
        <MPopoverPanel
            :no-padding="noPadding"
            @clickOutside="emit('clickOutside')"
        >
            <slot name="content" />
        </MPopoverPanel>
    </div>
</template>

<style scoped>
    .MPopover {
        @apply tw-inline-flex;
    }
</style>
