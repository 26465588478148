import type {ComputedRef, Ref} from 'vue';
import {computed, onMounted, ref} from 'vue';
import {minutesToHours} from '@/modules/legacy/libs/planning/planning';
import type {Collection} from '@meekohq/lumos';
import {collect, ModelCollection} from '@meekohq/lumos';
import {filterEvents} from '@/modules/human-resources/composables/calendar/useEventsFilter';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import CalendarModel from '@/modules/human-resources/models/CalendarModel';
import CalculateEventTime from '@/modules/human-resources/utils/statistics/CalculateEventTime';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import AccountModel from '@/modules/app/models/AccountModel';
import useManager from '@/modules/app/composables/useManager';
import EventModel from '@/modules/human-resources/models/EventModel';

export default function (staff: Ref<StaffModel | undefined>) {
    const loading = ref(false);
    const eventTypesCollection = ref<Collection<EventTypeModel>>(collect());
    const eventTypes = ref<any[]>([]);

    const hasManyOrganizationsInAccount = ref(false);

    onMounted(() => {
        getEventTypes();
        getCalendars();
    });

    const calendars = ref<Collection<CalendarModel>>(collect());

    async function getCalendars() {
        loading.value = true;
        const {legacyAccount} = useManager();
        hasManyOrganizationsInAccount.value = await new AccountModel(legacyAccount.value.id).hasManyOrganizations();
        calendars.value = await CalendarModel.query().all();
        loading.value = false;
    }

    const getEventTypes = async function () {
        const storedValues = JSON.parse(localStorage.getItem('staffs:statistics:eventTypes') as any) || undefined;

        eventTypesCollection.value = await EventTypeModel.query()
            .whereHas('organizations', query => query.scope('active'))
            .get();
        if (!storedValues) {
            eventTypes.value = eventTypesCollection.value.all();
        } else {
            eventTypes.value = eventTypesCollection.value
                .filter(item => {
                    return storedValues.find(value => value === item.getKey());
                })
                .all();
        }
    };

    const eventFilters = ref<string[]>(
        JSON.parse(localStorage.getItem('calendar:staffs:filter:events') as string) || []
    );

    async function getStaffEvents(
        period: Ref<{from: string; to: string}>,
        organizationsIds: ComputedRef<(string | number)[]>,
        getEventsWithoutOrganizations: Ref<boolean>
    ) {
        return EventModel.query()
            .where('staff_id', staff.value.getKey())
            .with(new EventModel().organization())
            .with(new EventModel().eventType(), query3 => {
                query3.with(new EventTypeModel().calendar());
            })
            .scope('inRange', [period?.value.from, period?.value.to])
            .andWhere(query3 => {
                if (getEventsWithoutOrganizations.value) {
                    query3.whereNull('nursery_id');
                }
                query3.orWhere(query4 => {
                    query4.whereHas(new EventModel().organization(), query5 => {
                        query5.whereIn('id', organizationsIds.value);
                    });
                });
            })
            .get();
    }

    function filteringStaffEvents(events) {
        events = filterEvents(events, eventFilters, true) as ModelCollection<EventModel>;

        if (
            eventTypes.value &&
            eventTypes.value.length &&
            eventTypes.value?.every(item => item instanceof EventTypeModel)
        ) {
            events = events?.filter(event => {
                return eventTypes.value?.find(
                    item =>
                        item.attributes.id === event.eventType().value().id && event.attributes.status === 'validated'
                );
            });
        } else {
            events = new ModelCollection();
        }

        return events;
    }

    const staffEvents = ref<ModelCollection<EventModel>>(new ModelCollection());

    const totalEvents = computed(() => {
        const {real, forecast} = new CalculateEventTime(staffEvents.value?.toArray() || []).getEventsTime();

        return {
            forecast: minutesToHours(forecast),
            real: minutesToHours(real),
        };
    });

    const totalEventsForCalendar = computed(() => {
        return (calendar: CalendarModel) => {
            const {real, forecast, total} = new CalculateEventTime(
                staffEvents.value?.toArray() || []
            ).getEventsTimeByCalendar(calendar.id);

            return {
                forecast: minutesToHours(forecast),
                real: minutesToHours(real),
                total: minutesToHours(total),
            };
        };
    });

    function onEventTypeClicked(models) {
        localStorage.setItem('staffs:statistics:eventTypes', collect(models).pluck('id').toJson());
    }

    return {
        loading,
        eventFilters,
        staffEvents,
        calendars,
        hasManyOrganizationsInAccount,

        totalEvents,
        totalEventsForCalendar,

        eventTypes,
        getStaffEvents,
        filteringStaffEvents,
        onEventTypeClicked,
    };
}
