<template>
    <div>
        <div class="tw-flex tw-flex-wrap">
            <div class="tw-items-center tw-justify-center tw-p-3 lg:tw-w-1/2">
                <div class="tw-mb-3 tw-text-base tw-font-semibold tw-uppercase tw-tracking-wide tw-text-gray-600">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-user"
                    />
                    {{ __('family_contract:sepa_for_family') }}
                </div>
                <div v-if="familySepa">
                    <EditSepa
                        v-model="familySepa"
                        :billing-address-id="familyBillingAddressId"
                        :contract="contract"
                        :user="user"
                        @change="selectSepa($event)"
                        @saveBeforeDownloadTemplate="saveBeforeDownloadTemplate($event)"
                    />
                </div>
                <div v-else-if="$can('update', 'kids_contracts')">
                    <SelectSepa
                        v-model="familySepa"
                        @fallback="addNewSepa"
                    >
                        <template #button>
                            <div>
                                <template v-if="familySepa">
                                    {{ familySepa.name }}
                                </template>
                                <template v-else>
                                    {{ __('family_contract:find_or_add_sepa') }}
                                </template>
                            </div>
                        </template>
                        <template #fallback="{searchValue}">
                            <template v-if="searchValue">
                                <FontAwesomeIcon
                                    class="tw--ml-1 tw-mr-1"
                                    fixed-width
                                    icon="fa-solid fa-plus"
                                />
                                {{ __('common:actions.add_with_value', {value: searchValue}) }}
                            </template>
                            <template v-else>
                                <FontAwesomeIcon
                                    class="tw--ml-1 tw-mr-1"
                                    fixed-width
                                    icon="fa-solid fa-plus"
                                />
                                {{ __('family_contract:add_new_sepa') }}
                            </template>
                        </template>
                    </SelectSepa>
                </div>
                <h5
                    v-else
                    class="h5 tw-p-3 tw-text-center"
                >
                    {{ __('family_contract:no_mandate') }}
                </h5>
            </div>

            <div
                v-if="contract.company"
                class="tw-items-center tw-justify-center tw-p-3 lg:tw-w-1/2"
            >
                <div class="tw-mb-3 tw-text-base tw-font-semibold tw-uppercase tw-tracking-wide tw-text-gray-600">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-duotone fa-city"
                        swap-opacity
                    />
                    {{ __('family_contract:sepa_for_company') }}
                </div>
                <div v-if="companySepa">
                    <EditSepa
                        v-model="companySepa"
                        :billing-address-id="companyBillingAddressId"
                        :contract="contract"
                        :user="user"
                        @change="selectSepa($event, true)"
                        @saveBeforeDownloadTemplate="saveBeforeDownloadTemplate($event)"
                    />
                </div>
                <div v-else-if="$can('update', 'kids_contracts')">
                    <SelectSepa
                        v-model="companySepa"
                        @fallback="addNewSepa($event, true)"
                    >
                        <div slot="button">
                            <template v-if="companySepa">
                                {{ companySepa.name }}
                            </template>
                            <template v-else>
                                {{ __('family_contract:find_or_add_sepa') }}
                            </template>
                        </div>
                        <template #fallback="{searchValue}">
                            <template v-if="searchValue">
                                <FontAwesomeIcon
                                    class="tw--ml-1 tw-mr-1"
                                    fixed-width
                                    icon="fa-solid fa-plus"
                                />
                                {{ __('common:actions.add_with_value', {value: searchValue}) }}
                            </template>
                            <template v-else>
                                <FontAwesomeIcon
                                    class="tw--ml-1 tw-mr-1"
                                    fixed-width
                                    icon="fa-solid fa-plus"
                                />
                                {{ __('family_contract:add_new_sepa') }}
                            </template>
                        </template>
                    </SelectSepa>
                </div>
                <h5
                    v-else
                    class="h5 tw-p-3 tw-text-center"
                >
                    {{ __('family_contract:no_mandate') }}
                </h5>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import Vue, {defineComponent, onMounted, ref, watch} from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import moment from 'moment';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import SelectSepa from './Sepa/SelectSepa.vue';
    import EditSepa from './Sepa/EditSepa.vue';
    import OrderModel from '@/modules/cashier/models/OrderModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            SelectSepa,
            EditSepa,
        },
        props: {
            user: {
                type: Object as PropType<{}>,
                required: true,
            },
            nursery: {
                type: Object as PropType<{}>,
                required: true,
            },
            contract: {
                type: Object as PropType<{
                    id;
                    sepa_mandate_id;
                    company_sepa_mandate_id;
                    family_order_id;
                    company_order_id;
                    temporaryFamilySepa;
                    temporaryCompanySepa;
                }>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const familySepa: Ref<{id; name; iban; bic; signed_at; rum} | null> = ref(null);
            const companySepa: Ref<{id; name; iban; bic; signed_at; rum} | null> = ref(null);

            const familyBillingAddressId: Ref<string | null> = ref(null);
            const companyBillingAddressId: Ref<string | null> = ref(null);

            watch(familySepa, (val, oldVal) => {
                if (val && oldVal && val.id === oldVal.id) {
                    return;
                }
                selectSepa(val);
            });

            watch(companySepa, (val, oldVal) => {
                if (val && oldVal && val.id === oldVal.id) {
                    return;
                }
                selectSepa(val, true);
            });

            onMounted(() => {
                if (props.contract.temporaryFamilySepa) {
                    familySepa.value = _cloneDeep(props.contract.temporaryFamilySepa);
                }

                if (props.contract.temporaryCompanySepa) {
                    companySepa.value = _cloneDeep(props.contract.temporaryCompanySepa);
                }

                if (props.contract.sepa_mandate_id) {
                    getSepa(props.contract.sepa_mandate_id);
                }

                if (props.contract.company_sepa_mandate_id) {
                    getSepa(props.contract.company_sepa_mandate_id, true);
                }
            });

            /*
                Fetch sepa and auto-select it
             */
            function getSepa(id, company = false) {
                useApi()
                    .legacy.get(route('sepas.show', {sepa: id}))
                    .then(response => {
                        selectSepa(response.data, company);
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                const errorMessage = _head(value);

                                if (typeof errorMessage === 'string') {
                                    useNotification().error(errorMessage);
                                }
                            });
                        } else if (error && error.response && error.response.status === 404) {
                            if (company) {
                                props.contract.company_sepa_mandate_id = null;
                            } else {
                                props.contract.sepa_mandate_id = null;
                            }
                        } else {
                            useNotification().error(error);
                        }
                    });
            }

            /*
                Create a new sepa and switch to edit mode
             */
            function addNewSepa(name = null, company = false) {
                const newSepa = {
                    id: null,
                    name,
                    iban: null,
                    bic: null,
                    signed_at: moment().format('YYYY-MM-DD'),
                    rum: 'MK' + moment().unix(),
                };
                if (company) {
                    companySepa.value = newSepa;
                    Vue.set(props.contract, 'temporaryCompanySepa', companySepa.value);
                    Vue.set(companySepa.value, 'editing', true);
                } else {
                    familySepa.value = newSepa;
                    Vue.set(props.contract, 'temporaryFamilySepa', familySepa.value);
                    Vue.set(familySepa.value, 'editing', true);
                }
            }

            /*
                Assign or set null sepa related variables
             */
            function selectSepa(sepa, company = false) {
                sepa = _cloneDeep(sepa);

                if (company) {
                    companySepa.value = sepa;
                    if (!sepa) {
                        Vue.set(props.contract, 'temporaryCompanySepa', null);
                        props.contract.company_sepa_mandate_id = null;
                    } else if (sepa.id) {
                        props.contract.company_sepa_mandate_id = sepa.id;
                    } else {
                        Vue.set(props.contract, 'temporaryCompanySepa', sepa);
                    }
                } else {
                    familySepa.value = sepa;
                    if (!sepa) {
                        Vue.set(props.contract, 'temporaryFamilySepa', null);
                        props.contract.sepa_mandate_id = null;
                    } else if (sepa.id) {
                        props.contract.sepa_mandate_id = sepa.id;
                    } else {
                        Vue.set(props.contract, 'temporaryFamilySepa', sepa);
                    }
                }
            }

            /*
                Fetch the contract related OrderModel to assign billing addresses ids
             */
            async function getBillingAddresses() {
                if (props.contract.id && props.contract.company_order_id) {
                    await OrderModel.query()
                        .find(props.contract.company_order_id)
                        .then(orderModel => {
                            if (orderModel.attributes.billing_address_id) {
                                companyBillingAddressId.value = orderModel.attributes.billing_address_id;
                            }
                        });
                }

                if (props.contract.id && props.contract.family_order_id) {
                    await OrderModel.query()
                        .find(props.contract.family_order_id)
                        .then(orderModel => {
                            if (orderModel.attributes.billing_address_id) {
                                familyBillingAddressId.value = orderModel.attributes.billing_address_id;
                            }
                        });
                }
            }

            /*
                Wrap the DownloadTemplate component callback in another callback to get the billing addresses ids before
                opening the pdf
             */
            function saveBeforeDownloadTemplate(downloadTemplateCallback) {
                emit('saveBeforeDownloadTemplate', {
                    done() {
                        getBillingAddresses().then(() => {
                            downloadTemplateCallback.done();
                        });
                    },
                });
            }

            return {
                familySepa,
                familyBillingAddressId,
                selectSepa,
                addNewSepa,
                companySepa,
                companyBillingAddressId,
                saveBeforeDownloadTemplate,
            };
        },
    });
</script>
