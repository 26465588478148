<template>
    <loader v-if="loading" />
    <SimplePageTemplate v-else-if="staff">
        <template #title>
            <MPageTitle
                class="tw-capitalize"
                variant="staff"
            >
                {{ $fullName(staff.attributes) }}
                <template v-if="staff.attributes.job"> &nbsp;-&nbsp;{{ $capitalize(staff.attributes.job) }}</template>
            </MPageTitle>
        </template>
        <template #actions>
            <TagsSelector
                :taggable-model="staff"
                taggable-type="staff"
                :tags="staff.tags().value().toArray()"
                @attached="staff.tags().value().push($event)"
                @detached="staff.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
            />
            <DownloadTemplate
                :additional-items="additionalItems"
                doc-type="staff"
                :item="staff"
                :nursery="nursery"
                sm
                :user="user"
                variant="light"
            />
        </template>
        <template #content>
            <div class="tw-hidden tw-w-full print:!tw-block">
                <h3 class="h3 tw-w-full tw-justify-items-center tw-text-center tw-font-grandhotel tw-text-blue-800">
                    {{ $fullName(staff.attributes) }}
                    <span
                        v-if="nursery"
                        class="tw-font-display tw-text-lg"
                        >{{ `- ${nursery.name} ${nursery.city}` }}</span
                    >
                </h3>
                <hr class="hr tw-hidden print:!tw-block" />
            </div>
            <CTabMenu
                class="print:!tw-hidden"
                variant="pink"
            >
                <CTabMenuItem
                    exact
                    :to="{
                        name: 'staffs.show',
                        params: {nursery: nursery.id, staff: staff.id},
                    }"
                    variant="pink"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-user"
                    />
                    {{ __('common:summary') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'staffs_contracts')"
                    exact
                    :to="{
                        name: 'staffs.show',
                        params: {nursery: nursery.id, staff: staff.id},
                        query: {nav: 'contracts'},
                    }"
                    variant="pink"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-handshake"
                    />
                    {{ __('common:contract_other') }}
                </CTabMenuItem>
                <CTabMenuItem
                    exact
                    :to="{
                        name: 'staffs.show',
                        params: {nursery: nursery.id, staff: staff.id},
                        query: {nav: 'biography'},
                    }"
                    variant="pink"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-book"
                    />
                    {{ __('common:biography') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'staffs_documents')"
                    exact
                    :to="{
                        name: 'staffs.show',
                        params: {nursery: nursery.id, staff: staff.id},
                        query: {nav: 'documents'},
                    }"
                    variant="pink"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-file-alt"
                    />
                    {{ __('common:document_other') }}
                </CTabMenuItem>
            </CTabMenu>
            <MBox
                v-if="$route.query.nav !== 'documents'"
                class="tw-mt-4 tw-p-4"
            >
                <ModuleSummary
                    v-if="$route.query.nav === 'summary' || !$route.query.nav"
                    :staff="staff"
                />
                <ModuleContracts
                    v-if="$route.query.nav === 'contracts'"
                    :nursery="nursery"
                    :staff="staff"
                />
                <ModuleBiography
                    v-if="$route.query.nav === 'biography'"
                    :nursery="nursery"
                    :staff="staff"
                />
            </MBox>
            <SimpleSubPageTemplate v-if="$route.query.nav === 'documents' && staff">
                <template #title>
                    <MHeading>{{ __('common:documents_of', {name: staff.fullname}) }}</MHeading>
                </template>
                <template #actions>
                    <MButton
                        v-if="can('create', 'staffs_documents')"
                        icon-plus
                        :label="__('common:add_document')"
                        :to="{name: 'staffs.show.documents.create', query: {nav: 'documents'}}"
                        variant="primary"
                    />
                </template>
                <template #content>
                    <DocumentList
                        :bus="bus"
                        :edit-item-fn="editItemFn"
                        permission-key="staffs_documents"
                        :related-resource="staff"
                        :show-visibilities="{
                            staffs_access: false,
                            parents_access: false,
                            web_access: false,
                        }"
                    />
                    <router-view
                        :staff-model="staff"
                        @created="bus.emit('documentCreated', $event)"
                        @deleted="bus.emit('documentDeleted', $event)"
                        @updated="bus.emit('documentUpdated', $event)"
                    />
                </template>
            </SimpleSubPageTemplate>
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import useStaff from '@/modules/human-resources/composables/staff/useStaff';
    import Summary from '@/modules/human-resources/components/staff/Summary.vue';
    import Contracts from '@/modules/human-resources/components/staff/Contracts.vue';
    import Biography from '@/modules/human-resources/components/staff/Biography.vue';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import {useRoute, useRouter} from 'vue-router/composables';
    import DocumentList from '@/modules/document/components/organisms/DocumentList.vue';
    import mitt from 'mitt';
    import useAbility from '@/modules/app/composables/useAbility';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import SimpleSubPageTemplate from '@/modules/app/components/templates/SimpleSubPageTemplate.vue';
    import {Epoch} from '@meekohq/lumos';

    export default defineComponent({
        components: {
            SimpleSubPageTemplate,
            SimplePageTemplate,
            TagsSelector,
            DocumentList,
            ModuleSummary: Summary,
            ModuleContracts: Contracts,
            ModuleBiography: Biography,
            DownloadTemplate,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        setup() {
            const selectedTab = ref<'summary' | 'contracts' | 'documents' | 'biography'>('summary');

            const router = useRouter();

            const bus = mitt();

            const {can} = useAbility();
            const route = useRoute();

            const {selectedStaff: staff, loading, getStaff} = useStaff();
            watch(
                () => route.params.staff,
                val => {
                    getStaff(val);
                },
                {immediate: true}
            );

            // Additional items for download template
            const additionalItems = computed(() => {
                const arr: any[] = [];
                const activeContract = staff.value?.activeContract(undefined, Epoch.now().toISOString());
                if (activeContract) {
                    arr.push({
                        name: 'staff_contract_id',
                        value: activeContract.getKey(),
                    });
                }

                return arr;
            });

            function editItemFn(document) {
                router.replace({
                    name: 'staffs.show.documents.edit',
                    query: {nav: 'documents'},
                    params: {document: document.getKey()},
                });
            }

            return {
                selectedTab,
                staff,
                loading,
                additionalItems,
                bus,
                editItemFn,
                can,
            };
        },
    });
</script>
