<template>
    <CInline
        align-y="center"
        space="sm"
    >
        <input
            :id="id"
            :checked="value"
            class="form-checkbox"
            :class="baseClass"
            :disabled="disabled"
            :indeterminate.prop="halfChecked"
            type="checkbox"
            @change="$emit('input', $event.target.checked)"
            @click="$emit('click', $event)"
        />
        <CLabel
            v-if="$slots['default']"
            :for="id"
        >
            <slot />
        </CLabel>
    </CInline>
</template>

<script lang="ts">
    import {Str} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            value: {type: [Boolean, Number] as PropType<boolean>, default: false},
            halfChecked: {type: [Boolean, Number] as PropType<boolean>, default: false},
            disabled: {type: [Boolean, Number] as PropType<boolean>, default: false},
            size: {type: String as PropType<string>, default: 'default'},
        },
        emits: ['input', 'click'],
        setup(props) {
            const id = Str.uuid();
            const baseClass = computed(() => {
                const output = [] as string[];

                output.push('CCheckbox');

                output.push('tw-appearance-none');

                output.push(sizeValues[props.size]);

                return output;
            });

            return {
                baseClass,
                id,
            };
        },
    });

    export const sizeValues = {
        sm: 'tw-w-3 tw-h-3 tw-text-base tw-rounded',
        default: 'tw-w-4 tw-h-4 tw-text-xl tw-rounded',
        lg: 'tw-w-5 tw-h-5 tw-text-2xl tw-rounded-md',
    };
</script>

<style scoped>
    .CCheckbox {
        background-origin: border-box;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        @apply tw-cursor-pointer tw-transition tw-duration-200;
        @apply tw-border tw-border-gray-300 tw-bg-white tw-shadow-sm;
        @apply tw-inline-block tw-shrink-0 tw-select-none tw-align-middle tw-text-blue-500;

        &:focus {
            @apply tw-border-blue-500 tw-ring;
        }

        &:checked {
            border-color: transparent;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
            background-position: 50%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-color: currentColor;

            &:focus {
                @apply tw-border-blue-500;
            }
        }

        &:indeterminate {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%232C92FF' fill-rule='evenodd' d='M5 7h6a1 1 0 0 1 0 2H5a1 1 0 1 1 0-2Z'/%3E%3C/svg%3E");
            background-position: 50%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            @apply tw-border-blue-500;

            &:focus {
                @apply tw-border-blue-500;
            }
        }

        &:disabled {
            @apply tw-cursor-not-allowed tw-opacity-50;
        }
    }
</style>
