<template>
    <CModal
        ref="modal"
        :header-title="__('hr:add_event_type')"
        @hidden="onHide()"
        @shown="onShown()"
    >
        <EventTypeForm
            v-if="eventType"
            ref="form"
            :model="eventType"
            @loading="loading = $event"
            @saved="onSaved"
        >
            <template #importItemAlert="{value}">
                <ImportItem
                    field-to-compare="name"
                    :model="eventTypeConstructor"
                    :value="value"
                    @selected="importItem($event)"
                />
            </template>
        </EventTypeForm>
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loading"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import EventTypeOrganizationModel from '@/modules/human-resources/models/EventTypeOrganizationModel';
    import EventTypeForm from '@/modules/human-resources/components/event-type/EventTypeForm.vue';
    import DataStore from '@/modules/legacy/store/data.store';
    import useManager from '@/modules/app/composables/useManager';
    import type CalendarModel from '@/modules/human-resources/models/CalendarModel';
    import ImportItem from '@/modules/app/components/ImportItem.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';

    export default defineComponent({
        components: {ImportItem, EventTypeForm},
        props: {
            calendarModel: {required: true, type: Object as PropType<CalendarModel>},
        },
        setup(props, {emit}) {
            const loading = ref(false);
            const {activeOrganization} = useManager();
            const {modal, show, hide} = useLegacyModalBus();
            const eventTypeConstructor = EventTypeModel;

            const eventType = ref<EventTypeModel | null>();

            function onShown() {
                eventType.value = new EventTypeModel();
                eventType.value.attributes.account_id = activeOrganization.value.attributes.account_id;
                eventType.value.calendar().associate(props.calendarModel);
                eventType.value.attributes.factor = eventType.value.calendar().value().factor;
            }

            function onHide() {
                // Pass eventType.value to null to avoid the persistence of the icon in SelectIcon.
                eventType.value = undefined;
            }

            function onSaved(model) {
                hide();
                useNotification().success(__('hr:event_type_added_successfully'));
                emit('created', model);
            }

            const importItem = async function (model: EventTypeModel) {
                loading.value = true;

                const pivot = new EventTypeOrganizationModel();
                pivot.attributes.account_id = model.attributes.account_id;
                pivot.organization().associate(activeOrganization.value, false);
                pivot.eventType().associate(model, false);

                await pivot.save();
                model.organizationsPivots().value().push(pivot);

                loading.value = false;
                onSaved(model);
            };

            return {
                loading,
                DataStore,
                show,
                hide,
                onShown,
                onHide,
                onSaved,
                importItem,
                modal,
                eventType,
                eventTypeConstructor,
            };
        },
    });
</script>
