<template>
    <component
        :is="as"
        :class="baseClass"
        v-on="$listeners"
    >
        <slot />
    </component>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type {ResponsivePropType} from '@/modules/coherence-ui/hooks/useResponsiveProp';
    import useResponsiveProp from '@/modules/coherence-ui/hooks/useResponsiveProp';
    import {isNumber} from 'lodash-es';

    type Gap = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';
    type Distribute = 'none' | 'around' | 'between' | 'evenly' | 'start' | 'center' | 'end';
    type Align = 'none' | 'start' | 'center' | 'end' | 'baseline' | 'stretch';

    export default defineComponent({
        props: {
            distribute: {type: [String, Object, Array] as PropType<ResponsivePropType<Distribute>>, default: 'none'},
            align: {type: [String, Object, Array] as PropType<ResponsivePropType<Align>>, default: 'none'},
            gap: {type: [String, Number, Object, Array] as PropType<ResponsivePropType<Gap>>, default: '0'},
            reverse: {type: [Boolean, Object, Array] as PropType<ResponsivePropType<boolean>>, default: false},
            wrap: {type: [Boolean, Object, Array] as PropType<ResponsivePropType<boolean>>, default: false},
            as: {type: String, default: 'div'},
        },
        setup(props) {
            const {baseClass} = useStyling(props);

            return {baseClass};
        },
    });

    function useStyling(props) {
        const baseClass = computed(() => {
            const output = [] as any[];

            output.push('CHStack');

            const {prop: reverseValue} = useResponsiveProp(props.reverse, false);
            if (reverseValue.value) {
                output.push('CHStack--reverse');
            }

            const {prop: wrapValue} = useResponsiveProp(props.wrap, false);
            if (wrapValue.value) {
                output.push('CHStack--wrap');
            }

            const {prop: distributeValue} = useResponsiveProp(props.distribute, 'none');
            output.push(distributeValues[distributeValue.value]);

            const {prop: alignValue} = useResponsiveProp(props.align, 'stretch');
            output.push(alignValues[alignValue.value]);

            const {prop: gapValue} = useResponsiveProp(props.gap, '0');
            const gapAsNumber = isNumber(gapValue.value) ? gapValue.value : parseInt(gapValue.value);
            output.push(gapValues[gapAsNumber]);

            return output;
        });

        return {baseClass};
    }

    export const distributeValues = {
        none: '',
        around: 'CHStack--distribute-around',
        between: 'CHStack--distribute-between',
        evenly: 'CHStack--distribute-evenly',
        start: 'CHStack--distribute-start',
        center: 'CHStack--distribute-center',
        end: 'CHStack--distribute-end',
    };

    export const alignValues = {
        none: '',
        stretch: 'CHStack--align-stretch',
        baseline: 'CHStack--align-baseline',
        start: 'CHStack--align-start',
        center: 'CHStack--align-center',
        end: 'CHStack--align-end',
    };

    export const gapValues = {
        0: '',
        1: 'CHStack--gap-1',
        2: 'CHStack--gap-2',
        3: 'CHStack--gap-3',
        4: 'CHStack--gap-4',
        5: 'CHStack--gap-5',
        6: 'CHStack--gap-6',
        7: 'CHStack--gap-7',
        8: 'CHStack--gap-8',
    };
</script>
