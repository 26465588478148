<template>
    <div class="CPopover">
        <div
            v-if="$scopedSlots['default']"
            ref="slotReferenceEl"
            class="CPopover__reference"
        >
            <slot
                :hide="hide"
                :show="show"
                :toggle="toggle"
            />
        </div>

        <portal
            v-if="isEnabled"
            to="overlay"
        >
            <div :key="id">
                <transition name="fade-100">
                    <div
                        v-if="isVisible && overlay"
                        class="CPopover__overlay"
                    />
                </transition>
                <div
                    ref="popperEl"
                    class="CPopover__popper"
                    :class="popperClass"
                >
                    <transition
                        name="zoom-100"
                        @after-leave="afterLeave"
                        @enter="onEnter"
                    >
                        <div
                            v-if="isVisible"
                            class="CPopover__container"
                            :class="[computedContainerClass, containerClass]"
                            @click.stop
                        >
                            <div
                                class="CPopover__content"
                                :class="[computedContentClass, contentClass]"
                            >
                                <slot name="content" />
                            </div>
                            <div
                                v-if="arrow"
                                class="CPopover__arrow"
                                data-popper-arrow
                            />
                        </div>
                    </transition>
                </div>
            </div>
        </portal>
    </div>
</template>

<script lang="ts">
    import type {Instance} from '@popperjs/core';
    import {createPopper} from '@popperjs/core';
    import {isArray, isNumber, isString} from 'lodash-es';
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, nextTick, onBeforeUnmount, ref, toRef, watch} from 'vue';
    import type {Placement} from '@popperjs/core';
    import {onClickOutside, useElementHover, useEventListener, useParentElement, whenever} from '@vueuse/core';
    import type {Emitter} from 'mitt';
    import mitt from 'mitt';
    import type {PopoverType} from '@/modules/app/composables/usePopover';
    import usePopover from '@/modules/app/composables/usePopover';
    import type {VueInstance} from '@vueuse/core/index';
    import useUniqueComponentId from '@/modules/app/composables/useUniqueComponentId';
    import useClickOutsideIgnoredElementsStore from '@/modules/meeko-ui/stores/useClickOutsideIgnoredElementsStore';

    export interface EventType {
        show: number | undefined;
        hide: number | undefined;
        toggle: number | undefined;
    }

    export type PopoverElement = HTMLElement | VueInstance;

    export default defineComponent({
        props: {
            reference: {type: [] as PropType<PopoverElement>, default: undefined},
            placement: {type: String as PropType<Placement>, default: 'bottom-start'},
            arrow: {type: Boolean, default: true},
            delay: {
                type: [String, Number, Array],
                default: undefined,
            },
            clickable: {type: Boolean, default: true},
            clickableDelay: {
                type: [String, Number, Array],
                default: undefined,
            },
            hoverable: {type: Boolean, default: false},
            hoverableDelay: {
                type: [String, Number, Array],
                default: () => [300, 0],
            },
            preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},
            preventHideOnReferenceClick: {type: Boolean as PropType<boolean>, default: false},
            variant: {type: String, default: 'dropdown'},
            overlay: {type: Boolean, default: false},
            popperClass: {type: [String, Array, Object], default: undefined},
            containerClass: {type: [String, Array, Object], default: undefined},
            contentClass: {type: [String, Array, Object], default: undefined},
            emitter: {type: Object as PropType<Emitter<EventType>>, default: () => mitt()},
            popover: {type: Object as PropType<PopoverType>, default: () => usePopover()},
            noPadding: {type: Boolean, default: false},
            stopPropagation: {type: Boolean, default: false},
            preventDefault: {type: Boolean, default: false},
            custom: {type: Boolean, default: false},
        },
        setup(props, {emit}) {
            const id = useUniqueComponentId();
            const popperInstance = ref<Instance>();
            const popperEl = ref<HTMLElement>();
            const slotReferenceEl = ref<HTMLElement>();
            const isReferenceHovered = ref(false);
            const isReferenceClicked = ref(false);
            const isManuallyClicked = ref(false);
            const isVisible = ref(false);
            const isEnabled = ref(false);
            const parentReferenceEl = useParentElement();
            const {add, remove, ignore} = useClickOutsideIgnoredElementsStore();
            const externalReferenceHovered = useElementHover(toRef(props, 'reference') as any);
            const slotReferenceHovered = useElementHover(slotReferenceEl);
            const parentReferenceHovered = useElementHover(parentReferenceEl);
            const popperHovered = useElementHover(popperEl);

            const mustBeVisible = computed(() => {
                return (
                    (props.hoverable && isReferenceHovered.value) ||
                    (props.clickable && isReferenceClicked.value) ||
                    isManuallyClicked.value
                );
            });

            watch(
                mustBeVisible,
                value => {
                    if (value) {
                        isEnabled.value = true;
                        nextTick(() => {
                            add(popperEl as any);
                            isVisible.value = true;
                        });
                    } else {
                        isVisible.value = false;
                        nextTick(() => {
                            remove(popperEl as any);
                        });
                    }
                },
                {immediate: true}
            );

            function afterLeave() {
                if (popperInstance.value) {
                    popperInstance.value.destroy();
                    popperInstance.value = undefined;
                }
                nextTick(() => {
                    isEnabled.value = false;
                });
            }

            whenever(
                slotReferenceEl,
                value => {
                    if (value.children.length > 1) {
                        console.warn('CPopover: The default reference element should only have one child');
                    }
                },
                {immediate: true}
            );

            function extractDelay(value: string | number | any[], key: number) {
                let delay = 0;

                if (isNumber(value)) {
                    delay = value;
                } else if (isString(value)) {
                    delay = Number(value);
                } else if (isArray(value)) {
                    delay = value[key] ?? 0;
                }

                return delay;
            }

            watch([externalReferenceHovered, slotReferenceHovered, parentReferenceHovered, popperHovered], () => {
                if (props.hoverable) {
                    clearTimeouts();
                }

                if (
                    (referenceToUse.value === 'external' && externalReferenceHovered.value) ||
                    (referenceToUse.value === 'slot' && slotReferenceHovered.value) ||
                    (referenceToUse.value === 'parent' && parentReferenceHovered.value) ||
                    popperHovered.value
                ) {
                    hoverableTimeout.value = setTimeout(
                        () => (isReferenceHovered.value = true),
                        extractDelay(props.hoverableDelay ?? props.delay, 0)
                    );
                } else {
                    hoverableTimeout.value = setTimeout(
                        () => (isReferenceHovered.value = false),
                        extractDelay(props.hoverableDelay ?? props.delay, 1)
                    );
                }
            });

            const hoverableTimeout = ref();
            const clickableTimeout = ref();
            const manualTimeout = ref();

            function onReferenceClick() {
                if (props.clickable) {
                    clearTimeouts();

                    if (isReferenceClicked.value && !props.preventHideOnReferenceClick) {
                        clickableTimeout.value = setTimeout(
                            () => (isReferenceClicked.value = false),
                            extractDelay(props.clickableDelay ?? props.delay, 1)
                        );
                    } else {
                        clickableTimeout.value = setTimeout(
                            () => (isReferenceClicked.value = true),
                            extractDelay(props.clickableDelay ?? props.delay, 0)
                        );
                    }
                }
            }

            onClickOutside(
                popperEl,
                () => {
                    if (!props.preventHideOnClick) {
                        clearTimeouts();

                        if (isReferenceClicked.value) {
                            clickableTimeout.value = setTimeout(
                                () => (isReferenceClicked.value = false),
                                extractDelay(props.clickableDelay ?? props.delay, 1)
                            );
                        }

                        if (isManuallyClicked.value) {
                            manualTimeout.value = setTimeout(
                                () => (isManuallyClicked.value = false),
                                extractDelay(props.delay, 1)
                            );
                        }

                        props.popover.bus.emit('clickOutside');
                    }
                },
                {ignore: ignore}
            );

            const computedContainerClass = computed(() => {
                const output = [] as string[];

                output.push(variantValues[props.variant]);
                output.push(placementValues[popperInstance.value?.state?.placement ?? 'auto']);

                return output;
            });

            const referenceToUse = computed(() => {
                if (props.reference) {
                    return 'external';
                } else if (slotReferenceEl.value) {
                    return 'slot';
                } else {
                    return 'parent';
                }
            });

            function getReferenceEl() {
                switch (referenceToUse.value) {
                    case 'external':
                        return (props.reference as any).$el ? (props.reference as any).$el : props.reference;
                    case 'slot':
                        return slotReferenceEl.value?.children[0] as HTMLElement;
                    case 'parent':
                        return (parentReferenceEl.value as any).$el
                            ? (parentReferenceEl.value as any).$el
                            : parentReferenceEl.value;
                }
            }

            const onEnter = function () {
                popperInstance.value = createPopper(getReferenceEl(), popperEl.value as HTMLElement, {
                    placement: props.placement,
                    modifiers: [],
                });
            };

            watch(isVisible, value => {
                nextTick(function () {
                    if (value) {
                        emit('shown');
                        props.popover.bus.emit('shown');
                    } else {
                        emit('hidden');
                        props.popover.bus.emit('hidden');
                    }
                });
            });

            function clearTimeouts() {
                clearTimeout(hoverableTimeout.value);
                clearTimeout(clickableTimeout.value);
                clearTimeout(manualTimeout.value);
            }

            const show = function (delay = 0) {
                clearTimeouts();

                manualTimeout.value = setTimeout(
                    () => (isManuallyClicked.value = true),
                    extractDelay(delay ?? props.delay, 0)
                );
            };

            const hide = function (delay = 0) {
                clearTimeouts();

                manualTimeout.value = setTimeout(
                    () => {
                        isReferenceHovered.value = false;
                        isReferenceClicked.value = false;
                        isManuallyClicked.value = false;
                    },
                    extractDelay(delay ?? props.delay, 1)
                );
            };

            function toggle(delay = 0) {
                if (isManuallyClicked.value) {
                    hide(delay);
                } else {
                    show(delay);
                }
            }

            props.emitter.on('show', delay => show(delay));
            props.emitter.on('hide', delay => hide(delay));
            props.emitter.on('toggle', delay => toggle(delay));

            props.popover.bus.on('show', delay => show(delay));
            props.popover.bus.on('hide', delay => hide(delay));
            props.popover.bus.on('toggle', delay => toggle(delay));
            props.popover.bus.on('forceUpdate', () => popperInstance.value?.forceUpdate());

            const computedContentClass = computed(() => {
                const output: string[] = [];

                if (props.noPadding) {
                    output.push('CPopover--no-padding');
                }

                return output;
            });

            let cleanupEventListener: () => void;

            watch(
                referenceToUse,
                () => {
                    if (cleanupEventListener) {
                        cleanupEventListener();
                    }

                    if (!props.custom) {
                        let el: Ref;

                        switch (referenceToUse.value) {
                            case 'external':
                                el = toRef(props, 'reference') as any;
                                break;
                            case 'slot':
                                el = slotReferenceEl;
                                break;
                            case 'parent':
                                el = parentReferenceEl;
                        }

                        cleanupEventListener = useEventListener(
                            el,
                            'click',
                            event => {
                                onReferenceClick();
                                if (props.stopPropagation) {
                                    event.stopPropagation();
                                }
                                if (props.preventDefault) {
                                    event.preventDefault();
                                }
                            },
                            {passive: false}
                        );
                    }
                },
                {immediate: true}
            );

            onBeforeUnmount(() => {
                afterLeave();
                if (cleanupEventListener) {
                    cleanupEventListener();
                }
            });

            return {
                slotReferenceEl,
                computedContainerClass,
                computedContentClass,
                isVisible,
                show,
                hide,
                toggle,
                popperEl,
                isManuallyClicked,
                isReferenceClicked,
                isReferenceHovered,
                popperHovered,
                onEnter,
                afterLeave,
                isEnabled,
                mustBeVisible,
                id,
                slotReferenceHovered,
                externalReferenceHovered,
                parentReferenceHovered,
                referenceToUse,
                parentReferenceEl,
            };
        },
    });

    export const placementValues = {
        'top-start': 'tw-origin-bottom-left',
        'top': 'tw-origin-bottom',
        'top-end': 'tw-origin-bottom-right',
        'bottom-start': 'tw-origin-top-left',
        'bottom': 'tw-origin-top',
        'bottom-end': 'tw-origin-top-right',
        'left-start': 'tw-origin-top-left',
        'left': 'tw-origin-right',
        'left-end': 'tw-origin-top-right',
        'right-start': 'tw-origin-bottom-left',
        'right': 'tw-origin-left',
        'right-end': 'tw-origin-top-left',
    };

    export const variantValues = {
        dropdown: 'CPopover--variant-dropdown',
        tooltip: 'CPopover--variant-tooltip',
    };
</script>

<style scoped></style>
