<script lang="ts" setup>
    import type {PropType, Ref} from 'vue';
    import {ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import type AddressModel from '@/modules/cashier/models/AddressModel';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import AddressForm from '@/modules/cashier/components/core/address/organisms/AddressForm.vue';

    const props = defineProps({
        modal: {
            type: Object as PropType<ModalType>,
            required: true,
        },
        addressModel: {
            type: Object as PropType<AddressModel>,
            required: true,
        },
    });

    const emit = defineEmits(['update']);

    const isLoading = ref(false);

    const clonedAddressModel = ref<AddressModel>(props.addressModel.clone()) as Ref<AddressModel | undefined>;

    function onAddressUpdated(addressModel: AddressModel) {
        emit('update', addressModel);
        props.modal.hide();
    }
</script>
<template>
    <MModal
        :header-title="__('billing_setting:update_address')"
        :modal="modal"
    >
        <AddressForm
            ref="form"
            :address-model="clonedAddressModel"
            @loading="isLoading = $event"
            @saved="onAddressUpdated"
        />
        <template #footer="{hide}">
            <MButton
                class="tw-ml-auto"
                @click="hide"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="isLoading"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </MModal>
</template>
