<template>
    <RouterLink
        v-if="isLink"
        class="ResourceBadge ResourceBadge--is-link"
        :to="resource.url"
        @click.native="$emit('resourceClicked', resource)"
    >
        <ResourceBadgeContent
            :resource="resource"
            :show-delete="showDelete"
            @delete="$emit('delete')"
        />
    </RouterLink>
    <div
        v-else
        class="ResourceBadge"
        @click="$emit('resourceClicked', resource)"
    >
        <ResourceBadgeContent
            :resource="resource"
            :show-delete="showDelete"
            @delete="$emit('delete')"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type {ResourceInterface} from '@/modules/app/composables/useResource';
    import ResourceBadgeContent from '@/modules/app/components/resource/molecules/ResourceBadgeContent.vue';

    export default defineComponent({
        components: {ResourceBadgeContent},
        props: {
            resource: {
                type: Object as PropType<ResourceInterface>,
                required: true,
            },
            isLink: {
                type: Boolean,
                default: true,
            },
            showDelete: {
                type: Boolean,
                default: true,
            },
        },
    });
</script>

<style scoped>
    .ResourceBadge {
        @apply tw-min-w-0 tw-rounded-lg tw-bg-blue-100 tw-p-1;
    }

    .ResourceBadge--is-link {
        @apply tw-cursor-pointer tw-transition-all tw-ease-in-out hover:tw-scale-105;
    }
</style>
