<template>
    <div class="tw-flex tw-flex-col tw-gap-4">
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <MHeading level="h3">
                {{ group.name }}
            </MHeading>
            <MButton
                v-if="$can('update', 'groups')"
                variant="primary"
                @click="group.editing = true"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-pencil" />
                </template>
                {{ __('common:actions.update') }}
            </MButton>
        </div>
        <MHeading level="h4">
            {{ __('common:place_with_count', {count: group.places}) }}
        </MHeading>
        <template v-if="group.rules && group.rules.length">
            <MBox
                v-for="(rule, n) in group.rules"
                :key="'rule' + n"
                class="tw-flex tw-flex-col tw-gap-4 tw-p-4"
            >
                <MHeading level="h3"> {{ __('planning:rule') }} {{ n + 1 }} </MHeading>
                <PlanningSettingsKidsNumber :rule="rule" />
                <PlanningSettingsRequiredStaffs
                    :job-models="jobModels"
                    :rule="rule"
                />
            </MBox>
        </template>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import PlanningSettingsKidsNumber from '@/modules/planning/components/settings/PlanningSettingsGroup/molecules/PlanningSettingsKidsNumber.vue';
    import PlanningSettingsRequiredStaffs from '@/modules/planning/components/settings/PlanningSettingsGroup/molecules/PlanningSettingsRequiredStaffs.vue';

    export default defineComponent({
        components: {PlanningSettingsRequiredStaffs, PlanningSettingsKidsNumber},
        props: {
            jobModels: {
                type: Object,
                required: true,
            },
            group: {
                type: Object,
                required: true,
            },
        },
    });
</script>
