import type {Ref} from 'vue';
import {computed, onMounted} from 'vue';
import type {RouteMeta} from 'vue-router';
import type {Collection, ModelCollection} from '@meekohq/lumos';
import {Lang, Model} from '@meekohq/lumos';
import useManager from '@/modules/app/composables/useManager';
import useMetrics from '@/modules/app/composables/useRum';
import __ from '@/modules/app/utils/i18n-facade';
import KidModel from '@/modules/family/models/KidModel';
import RegistrationModel from '@/modules/registration/models/RegistrationModel';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import MemberModel from '@/modules/family/models/MemberModel';
import FamilyModel from '@/modules/family/models/FamilyModel';
import MedicalActionModel from '@/modules/health/models/MedicalActionModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import type TenantModel from '@/modules/cashier/models/TenantModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import type InvoiceModel from '@/modules/cashier/models/InvoiceModel';

export interface ResourceInterface {
    type: string;
    name: string;
    icon: string;
    iconClass: string;
    iconColor: string;
    url: RouteMeta;
    source: InputResourceInterface;
}

export interface InputResourceInterface {
    id: string;
    type: string;
    name: string;
    organization_ids: (string | undefined)[];
    model: Model;
}

/**
 * Composable pour gérer les ressources en fonction des modèles ou notifications.ressources
 * @param resources
 * @param groupParentResources pour regrouper certaines ressources (Vaccin + Fiche enfant = Vaccin avec lien de la fiche enfant)
 * @returns ResourceInterface
 */
export default function <TModel extends Model>(
    resources: Ref<InputResourceInterface[] | TModel[]>,
    groupParentResources = true
) {
    const computedResources = computed(() => {
        // Transforme les modèles en InputResourceInterface
        const inputResources: InputResourceInterface[] = [];
        resources.value.forEach(resource => {
            if (resource instanceof Model) {
                inputResources.push(mapModelIntoResource(resource));
            } else {
                inputResources.push(resource);
            }
        });

        if (groupParentResources && resources.value.length > 1) {
            resources.value = groupResourcesWithParent(inputResources);
        }

        // Transforme l'interface InputResource en Resource finale
        const mappedResources: ResourceInterface[] = [];
        inputResources.forEach(resource => {
            mappedResources.push(mapResource(resource));
        });

        return mappedResources;
    });

    const {addAction} = useMetrics();

    onMounted(async () => {
        const resourceOrganizationIds = resources.value?.map(resource => resource.organization_ids).flat();
        const legacyIds = resourceOrganizationIds?.filter(id => id !== undefined && Lang.isNumeric(id as string));

        // Replace legacy ids with new uuids if needed
        if (legacyIds?.length) {
            // Get organizations from legacy ids
            const resourceOrganizations = await OrganizationModel.query().whereIn('legacy_id', legacyIds).get();

            // Replace legacy ids with new uuids
            resources.value = resources.value.map(resource => {
                resource.organization_ids = resource.organization_ids.map(id => {
                    if (id !== undefined && Lang.isNumeric(id as string)) {
                        const organization = resourceOrganizations.first(
                            organization => organization.attributes.legacy_id === Number(id)
                        );

                        if (organization) {
                            return organization.getKey();
                        }
                    }

                    return id;
                });

                return resource;
            });

            // Add metric to track legacy id usage
            addAction('M_Resource_Legacy_Id');
        }
    });

    /**
     * Regroupe les ressources qui devraient avoir le même parent
     * @param resources
     * @returns resources grouppées selon des règles personnalisées
     */
    function groupResourcesWithParent(resources: InputResourceInterface[]) {
        resources.forEach((resource, i) => {
            // Liste des types qui doivent fusionner avec la ressource Kid
            const kidSubTypes = [new RegistrationModel().getType(), new MedicalActionModel().getType()];
            if (kidSubTypes.includes(resource.type)) {
                // Transforme le type de la ressource Kid pour qu'elle ait l'apparence de la ressource enfant, qu'on supprime.
                const kidResource = resources.find(item => item.type === new KidModel().getType());
                if (kidResource) {
                    kidResource.type = resource.type;
                    resources.splice(i, 1);
                }
            }
        });

        return resources;
    }

    /**
     * Transforme les ressources ou modèles reçus en ResourceInterface
     * @param resource
     * @returns ResourceInterface
     */
    function mapResource(resource: InputResourceInterface): ResourceInterface {
        switch (resource.type) {
            case new KidModel().getType():
                return {
                    type: new KidModel().getType(),
                    name: resource.name ?? __('common:show_kid_page'),
                    icon: 'fa-solid fa-child-reaching',
                    iconClass: 'tw-bg-purple-500',
                    iconColor: 'tw-text-purple-500',
                    url: {
                        name: 'kids.show',
                        params: {
                            nursery: getOrganizationId(resource),
                            kid: resource.id,
                        },
                    },
                    source: resource,
                };
            case new RegistrationModel().getType():
                return {
                    type: new RegistrationModel().getType(),
                    name: resource.name ?? __('common:see_document'),
                    icon: 'fa-solid fa-pen-alt',
                    iconClass: 'tw-bg-teal-500',
                    iconColor: 'tw-text-teal-500',
                    url: {
                        name: 'registrations.show',
                        params: {
                            nursery: getOrganizationId(resource),
                            registration: resource.id,
                        },
                    },
                    source: resource,
                };
            case new StaffModel().getType():
                return {
                    type: new StaffModel().getType(),
                    name: resource.name ?? __('components:useResource.show_staff_record'),
                    icon: 'fa-duotone fa-users',
                    iconClass: 'tw-bg-pink-500',
                    iconColor: 'tw-text-pink-500',
                    url: {
                        name: 'staffs.show',
                        params: {
                            nursery: getOrganizationId(resource),
                            staff: resource.id,
                        },
                    },
                    source: resource,
                };
            case new MemberModel().getType():
                return {
                    type: new MemberModel().getType(),
                    name: resource.name ?? __('components:useResource.show_member_record'),
                    icon: 'fa-solid fa-user-tie',
                    iconClass: 'tw-bg-purple-500',
                    iconColor: 'tw-text-purple-500',
                    url: {
                        name: 'familyMembers.show',
                        params: {
                            nursery: getOrganizationId(resource),
                            familyMember: resource.id,
                        },
                    },
                    source: resource,
                };
            case new FamilyModel().getType():
                return {
                    type: new FamilyModel().getType(),
                    name: resource.name ?? __('components:useResource.show_family_record'),
                    icon: 'fa-duotone fa-address-book',
                    iconClass: 'tw-bg-purple-500',
                    iconColor: 'tw-text-purple-500',
                    url: {
                        name: 'families.show',
                        params: {
                            nursery: getOrganizationId(resource),
                            family: resource.id,
                        },
                    },
                    source: resource,
                };
            case new MedicalActionModel().getType(): {
                const hasKidId = (resource as InputResourceInterface & {kid_id: string}).kid_id;
                // TODO: A nettoyer quand toutes les notifs auront un kid_id
                const medicalActionUrl = hasKidId
                    ? {
                          name: 'kids.show',
                          params: {
                              nursery: getOrganizationId(resource),
                              kid: (resource as InputResourceInterface & {kid_id: string}).kid_id,
                          },
                          query: {nav: 'health'},
                      }
                    : {
                          name: 'kids.index',
                          params: {
                              nursery: getOrganizationId(resource),
                          },
                      };

                return {
                    type: new MedicalActionModel().getType(),
                    name: resource.name ?? __('common:show_kid_page'),
                    icon: 'fa-solid fa-syringe',
                    iconClass: 'tw-bg-green-500',
                    iconColor: 'tw-text-green-500',
                    url: medicalActionUrl,
                    source: resource,
                };
            }
            case new CustomerModel().getType():
                return {
                    type: new CustomerModel().getType(),
                    name: resource.name ?? __('common:view_customer_account'),
                    icon: 'fa-duotone fa-file-user',
                    iconClass: 'tw-bg-orange-500',
                    iconColor: 'tw-text-orange-500',
                    url: {
                        name: 'cashier/customers.show.summary',
                        params: {
                            nursery: getOrganizationId(resource),
                            customer: resource.id,
                        },
                    },
                    source: resource,
                };
            case new OrganizationModel().getType():
                return {
                    type: new OrganizationModel().getType(),
                    name: resource.name ?? __('components:useResource.show_organization_record'),
                    icon: 'fa-duotone fa-home',
                    iconClass: 'tw-bg-blue-500',
                    iconColor: 'tw-text-blue-500',
                    url: {
                        name: 'planning.kids',
                        params: {
                            nursery: resource.id,
                        },
                    },
                    source: resource,
                };

            case new PaymentModel().getType():
                return {
                    type: new PaymentModel().getType(),
                    name: resource.name ?? __('components:useResource.show_invoice_record'),
                    icon: 'fa-duotone fa-file-invoice',
                    iconClass: 'tw-bg-orange-500',
                    iconColor: 'tw-text-orange-500',
                    url: {
                        name: 'invoices.index',
                        params: {
                            nursery: getOrganizationId(resource),
                            family: resource.id,
                        },
                    },
                    source: resource,
                };
            default:
                return {
                    type: new KidModel().getType(),
                    name: resource.name ?? __('components:useResource.show_record'),
                    icon: 'fa-solid fa-arrow-up-right',
                    iconClass: 'tw-bg-gray-500',
                    iconColor: 'tw-text-gray-500',
                    url: {
                        name: 'nursery',
                        params: {
                            nursery: getOrganizationId(resource),
                        },
                    },
                    source: resource,
                };
        }
    }

    /**
     * Récupère l'ID de l'orga en fonction de la ressource
     * Lorsque la ressource est un modèle, il faut chercher à travers les relations
     * @param resource
     * @returns organization_id
     */
    function getOrganizationId(resource: InputResourceInterface | Model) {
        const isModel = resource instanceof Model;
        const resourceOrganizationIds = (isModel ? getOrganizationsFromModel(resource) : resource.organization_ids).map(
            organizationId => `${organizationId}`
        );

        return findAllowedOrganization(resourceOrganizationIds);
    }

    /**
     * Transforme un modèle en InputResource
     * @param model
     * @returns InputResourceInterface
     */
    function mapModelIntoResource(model: Model) {
        const resource: InputResourceInterface = {
            type: model.getType(),
            id: model.getKey(),
            name: getNameFromModel(model),
            organization_ids: getOrganizationsFromModel(model),
            model,
        };

        return resource;
    }

    /**
     * Récupère le nom à afficher en fonction du modèle
     * @param model
     * @returns name
     */
    function getNameFromModel(model: Model) {
        let name = 'Voir la fiche';

        // On commence par vérifier tous les modèles ayant un fullname
        if (model['fullname']) {
            name = model['fullname'];
            // Puis les modèles ayant l'attribut name
        } else if (model.attributes.name) {
            name = model.attributes.name;
        } else {
            // Enfin les cas particuliers selon le type du modèle
            switch (model.constructor) {
                // Pour un Vaccin / MedicalAction, on doit remonter au kid
                case MedicalActionModel: {
                    const kid: KidModel = model.getRelation('kid');
                    name = kid.fullname;
                    break;
                }
                case RegistrationModel: {
                    name = model['kidFullname'];
                    break;
                }
                // Pour un paiement, on doit remonter à la facture
                case PaymentModel: {
                    const invoices: ModelCollection<InvoiceModel> = model.attributes.refund
                        ? (model as PaymentModel).creditNotes().value()
                        : (model as PaymentModel).invoices().value();
                    const invoice: InvoiceModel = invoices.first();
                    if (invoice.attributes.no) {
                        name = invoice.attributes.no;
                    }
                    break;
                }
            }
        }

        return name;
    }

    /**
     * Récupère les organizations d'un modèle
     * En fonction du modèle, il faut traverser ses relations
     * @param model
     * @returns Array of organizationIds
     */
    function getOrganizationsFromModel(model: Model) {
        let resourceOrganizationIds: (string | undefined)[] = [];

        // On commence par vérifier tous les modèles ayant la relation organizations
        if (typeof model['organizations'] === 'function') {
            // Hack because TS not allowing resource.organizations()
            const organizations: Collection<OrganizationModel> = model.getRelation('organizations');

            if (organizations) {
                resourceOrganizationIds = organizations.map(item => item.getKey()).all();
            }
            // Puis les modèles ayant la relation organization
        } else if (typeof model['organization'] === 'function') {
            const organization: OrganizationModel = model.getRelation('organization');
            resourceOrganizationIds = [organization.getKey()];
            // Les modèles n'ayant pas la relation mais ayant la foreign key
        } else if (model.attributes.organization_id) {
            resourceOrganizationIds = [model.attributes.organization_id];
        } else {
            // Enfin les cas particuliers selon le type du modèle
            switch (model.constructor) {
                // Pour un Vaccin / MedicalAction, on doit remonter au kid
                case MedicalActionModel: {
                    const kid: KidModel = model.getRelation('kid');
                    resourceOrganizationIds = [kid.attributes.organization_id];
                    break;
                }
                case CustomerModel: {
                    const tenant: TenantModel = model.getRelation('tenant');

                    const organizations: Collection<OrganizationModel> | undefined = tenant
                        ? tenant.getRelation('organizations')
                        : undefined;

                    if (organizations) {
                        resourceOrganizationIds = organizations.map(item => item.getKey()).all();
                    }
                    break;
                }
            }
        }

        return resourceOrganizationIds;
    }

    const {activeOrganization, organizationIds} = useManager();

    /**
     * Vérifie que la ressource est liée à l'orga active
     * Sinon on va chercher l'orga dont le user et la ressource sont liés
     * @param resourceOrganizationIds
     * @returns organization_id
     */
    function findAllowedOrganization(resourceOrganizationIds: (string | undefined)[]) {
        // Return activeOrganization only if resource is linked with it
        if (resourceOrganizationIds.includes(activeOrganization.value.attributes.id)) {
            return activeOrganization.value.attributes.id;
        }

        // Or return the first organization from the resource allowed in user
        return organizationIds.value.find(organizationId => resourceOrganizationIds.includes(`${organizationId}`));
    }

    return {
        computedResources,
    };
}
