<template>
    <div>
        <div class="tw-mb-3 tw-flex">
            <MButton
                v-if="$can('create', 'family_members_details')"
                class="tw-ml-auto"
                icon-plus
                :label="__('family_member:add_parent_or_relative')"
                variant="primary"
                @click="showCreateFamilyMemberModal"
            />
        </div>
        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <div v-else>
            <div v-if="orderedKidFamilyMembers.length">
                <h2 class="h2 tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-600">
                    {{ __('family_member:parent_and_relative') }}
                </h2>
                <div
                    v-for="(familyMember, i) in orderedKidFamilyMembers"
                    :key="'family' + i"
                    class="tw-my-2 tw-flex tw-flex-col tw-border-t tw-border-gray-200 tw-py-3 lg:tw-flex-row"
                >
                    <div class="tw-flex tw-items-center">
                        <LegacyMemberAvatar
                            class="tw-absolute tw-mr-4 tw-w-12"
                            :member="familyMember"
                        />
                        <div class="tw-mr-auto tw-flex tw-flex-col tw-gap-2 sm:tw-gap-0">
                            <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-x-4 tw-gap-y-2">
                                <router-link
                                    class="tw-font-grandhotel tw-text-3xl tw-capitalize tw-leading-none"
                                    :class="familyMember.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                    :to="{
                                        name: 'familyMembers.show',
                                        params: {nursery: nursery.id, familyMember: familyMember.id},
                                    }"
                                >
                                    {{ $fullName(familyMember) }}
                                </router-link>
                                <MButton
                                    :label="__('common:actions.update')"
                                    variant="primary"
                                    @click="showEditFamilyMemberModal(familyMember)"
                                >
                                    <template #left-icons>
                                        <FontAwesomeIcon icon="fa-solid fa-pencil" />
                                    </template>
                                </MButton>
                            </div>
                            <CText
                                font-size="sm"
                                variant="gray"
                            >
                                {{ getRoleText(familyMember) }}
                                <template v-if="familyMember.allocator_no">
                                    {{ ' - ' + familyMember.allocator_no }}
                                </template>
                            </CText>
                        </div>
                        <div class="tw-ml-2 tw-self-baseline lg:tw-hidden">
                            <MButton
                                round
                                zoom
                                variant="success"
                                :to="{
                                    name: 'familyMembers.show',
                                    params: {nursery: nursery.id, familyMember: familyMember.id},
                                }"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                            </MButton>
                        </div>
                    </div>
                    <div
                        class="meta tw-mt-4 tw-flex tw-flex-col lg:tw-ml-auto lg:tw-mr-6 lg:tw-mt-0 lg:tw-flex-row lg:tw-items-center"
                    >
                        <div class="tw-mb-2 tw-mr-4 tw-text-lg lg:tw-mb-0 lg:tw-text-right">
                            <template v-if="familyMember.permissions && familyMember.permissions.length">
                                <div class="tw-hidden lg:tw-block">
                                    <MTooltip :label="__('family_member:access_parent_application_enabled')">
                                        <FontAwesomeIcon
                                            class="tw-text-green-500"
                                            fixed-width
                                            icon="fa-solid fa-mobile-alt"
                                            size="lg"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="tw-block lg:tw-hidden">
                                    <FontAwesomeIcon
                                        class="tw-text-green-500"
                                        fixed-width
                                        icon="fa-solid fa-mobile-alt"
                                        size="lg"
                                    />
                                    {{ __('family_member:access_parent_application_enabled') }}
                                </span>
                            </template>
                            <template v-else>
                                <div class="tw-hidden lg:tw-block">
                                    <MTooltip :label="__('family_member:access_parent_application_disabled')">
                                        <FontAwesomeIcon
                                            class="tw-text-gray-500"
                                            fixed-width
                                            icon="fa-solid fa-mobile-alt"
                                            size="lg"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="tw-block lg:tw-hidden">
                                    <FontAwesomeIcon
                                        class="tw-text-gray-500"
                                        fixed-width
                                        icon="fa-solid fa-mobile-alt"
                                        size="lg"
                                    />
                                    {{ __('family_member:access_parent_application_disabled') }}
                                </span>
                            </template>
                        </div>
                        <div class="tw-mb-2 tw-mr-4 tw-text-lg lg:tw-mb-0 lg:tw-text-right">
                            <template v-if="familyMember.can_pickup_kid">
                                <div class="tw-hidden lg:tw-block">
                                    <MTooltip :label="__('family_member:pick_up_child.authorized')">
                                        <FontAwesomeIcon
                                            class="tw-text-green-500"
                                            fixed-width
                                            icon="fa-solid fa-hands-helping"
                                            size="lg"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="tw-block lg:tw-hidden">
                                    <FontAwesomeIcon
                                        class="tw-text-green-500"
                                        fixed-width
                                        icon="fa-solid fa-hands-helping"
                                        size="lg"
                                    />
                                    {{ __('family_member:pick_up_child.authorized') }}
                                </span>
                            </template>
                            <template v-else>
                                <div class="tw-hidden lg:tw-block">
                                    <MTooltip :label="__('family_member:pick_up_child.unauthorized')">
                                        <FontAwesomeIcon
                                            class="tw-text-gray-500"
                                            fixed-width
                                            icon="fa-solid fa-hands-helping"
                                            size="lg"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="tw-block lg:tw-hidden">
                                    <FontAwesomeIcon
                                        class="tw-text-gray-500"
                                        fixed-width
                                        icon="fa-solid fa-hands-helping"
                                        size="lg"
                                    />
                                    {{ __('family_member:pick_up_child.unauthorized') }}
                                </span>
                            </template>
                        </div>
                        <div
                            v-if="familyMember.mobile_phone || familyMember.phone || familyMember.office_phone"
                            class="tw-mb-2 tw-text-lg lg:tw-mb-0 lg:tw-text-right"
                        >
                            <div class="tw-hidden lg:tw-block">
                                <MTooltip>
                                    <FontAwesomeIcon
                                        class="tw-text-success-500"
                                        fixed-width
                                        icon="fa-solid fa-phone"
                                        size="lg"
                                    />
                                    <template #content>
                                        <div v-html="getPhoneText(familyMember)" />
                                    </template>
                                </MTooltip>
                            </div>
                            <div class="tw-flex tw-flex-row tw-items-center tw-gap-1 lg:tw-hidden">
                                <FontAwesomeIcon
                                    class="tw-text-success-500"
                                    fixed-width
                                    icon="fa-solid fa-phone"
                                    size="lg"
                                />
                                <div v-html="getPhoneText(familyMember)" />
                            </div>
                        </div>
                    </div>
                    <div class="tw-hidden tw-self-center lg:tw-block">
                        <MButton
                            variant="success"
                            round
                            zoom
                            :to="{
                                name: 'familyMembers.show',
                                params: {nursery: nursery.id, familyMember: familyMember.id},
                            }"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                        </MButton>
                    </div>
                </div>
            </div>
            <h5
                v-else
                class="h5"
            >
                {{ __('family_member:empty_family_members') }}
            </h5>

            <div
                v-if="contactFamilyMembers.length"
                class="tw-mt-10"
            >
                <h2 class="h2 tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-600">
                    {{ __('family_member:family_contact') }}
                </h2>
                <div
                    v-for="(contactFamilyMember, i) in contactFamilyMembers"
                    :key="'contactFamilyMember' + i"
                    class="tw-flex tw-items-center tw-border-t tw-border-gray-200 tw-py-2"
                >
                    <LegacyMemberAvatar
                        class="tw-mr-4 tw-w-12"
                        :member="contactFamilyMember"
                    />
                    <router-link
                        class="tw-font-grandhotel tw-text-3xl tw-capitalize tw-leading-none"
                        :class="contactFamilyMember.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                        :to="{
                            name: 'familyMembers.show',
                            params: {nursery: nursery.id, familyMember: contactFamilyMember.id},
                        }"
                    >
                        {{ $fullName(contactFamilyMember) }}
                    </router-link>
                    <MPopover
                        v-if="$can('update', 'family_members_details')"
                        class="tw-ml-auto"
                        placement="left"
                    >
                        <MButton>
                            {{ __('family_member:create_parent_link') }}
                        </MButton>
                        <template #content>
                            <CForm @submit.prevent>
                                <CFormGroup>
                                    <CLabel>
                                        <template #icon>
                                            <FontAwesomeIcon icon="fa-solid fa-handshake" />
                                        </template>
                                        {{ __('family_member:kinship') }}
                                    </CLabel>
                                    <CFormSelect
                                        v-model="contactFamilyMember.role"
                                        :options="contactFamilyMember.gender === 'female' ? roles.female : roles.male"
                                    />
                                </CFormGroup>

                                <CFormGroup>
                                    <CLabel>
                                        <template #icon>
                                            <FontAwesomeIcon icon="fa-solid fa-hands-helping" />
                                        </template>
                                        {{ __('family_member:pick_up_child') }}
                                    </CLabel>
                                    <CFormSelect
                                        v-model="contactFamilyMember.can_pickup_kid"
                                        :options="[
                                            {text: __('common:authorized'), value: 1},
                                            {text: __('common:unauthorized'), value: 0},
                                        ]"
                                        :search-bar="false"
                                    />
                                </CFormGroup>

                                <MemberPermissionsInput
                                    v-model="contactFamilyMember.permissions"
                                    container-class="tw-min-w-72"
                                    :portal="false"
                                />

                                <MButton
                                    class="tw-w-full"
                                    size="sm"
                                    variant="primary"
                                    @click="attachFamilyMember(contactFamilyMember)"
                                >
                                    {{ __('common:actions.save') }}
                                </MButton>
                            </CForm>
                        </template>
                    </MPopover>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import __ from '@/modules/app/utils/i18n-facade';
    import _sortBy from 'lodash-es/sortBy';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import MemberModel from '@/modules/family/models/MemberModel';
    import MemberPermissionsInput from '@/modules/family/components/member/MemberPermissionsInput.vue';
    import LegacyMemberAvatar from '@/modules/app/components/atoms/avatars/LegacyMemberAvatar.vue';
    import useModal from '@/modules/app/composables/useModal';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import EditFamilyMemberModal from '@/modules/family/components/kid/Kid/FamilyMember/organisms/EditFamilyMemberModal.vue';
    import CreateFamilyMemberModal from '@/modules/family/components/kid/Kid/FamilyMember/organisms/CreateFamilyMemberModal.vue';

    export default {
        components: {
            LegacyMemberAvatar,
            MemberPermissionsInput,
        },
        props: ['nursery', 'kid'],
        data: () => ({
            contactFamilyMembers: [],
            loading: false,
            selectedFamilyMember: {},
            showEditModal: false,
            newFamilyMember: {},
            defaultPermissions: {
                all: [
                    'photos',
                    'meals',
                    'temperatures',
                    'drugs',
                    'diapers',
                    'naps',
                    'weights',
                    'presences',
                    'activities',
                    'observations',
                    'messages',
                    'invoices',
                    'transactions',
                    'staffs',
                    'posts',
                    'documents',
                ],
                intermediary: ['photos', 'messages', 'invoices', 'transactions', 'staffs', 'posts', 'documents'],
            },
            roles: {
                female: [
                    {text: __('common:relationship.parent_female'), value: 'mother'},
                    {text: __('common:relationship.sibling_female'), value: 'sister'},
                    {text: __('common:relationship.pibling_female'), value: 'aunt'},
                    {text: __('common:relationship.grandparent'), value: 'grandparent'},
                    {text: __('common:relationship.nanny'), value: 'nanny'},
                    {text: __('common:relationship.parent_in_law_female'), value: 'stepmother'},
                    {text: __('common:relationship.cousin_female'), value: 'cousin'},
                    {text: __('common:relationship.legal_custodian_female'), value: 'legal_custodian_female'},
                    {text: __('common:other_one'), value: 'other'},
                ],
                male: [
                    {text: __('common:relationship.parent_male'), value: 'father'},
                    {text: __('common:relationship.sibling_male'), value: 'brother'},
                    {text: __('common:relationship.pibling_male'), value: 'uncle'},
                    {text: __('common:relationship.grandparent'), value: 'grandparent'},
                    {text: __('common:relationship.nanny'), value: 'nanny'},
                    {text: __('common:relationship.parent_in_law_male'), value: 'stepfather'},
                    {text: __('common:relationship.cousin_male'), value: 'cousin'},
                    {text: __('common:relationship.legal_custodian_male'), value: 'legal_custodian_male'},
                    {text: __('common:other_one'), value: 'other'},
                ],
            },
        }),
        computed: {
            moment() {
                return moment;
            },
            orderedKidFamilyMembers() {
                return _sortBy(this.kid.familyMembers, 'isParent');
            },
        },
        mounted() {
            this.getAllFamilyMembers();
        },
        methods: {
            showEditFamilyMemberModal(familyMember) {
                this.selectedFamilyMember = _cloneDeep(familyMember);
                this.selectedFamilyMember.index = this.kid.familyMembers.indexOf(familyMember);

                const editFamilyMemberModal = useModal({
                    component: EditFamilyMemberModal,
                    props: {
                        nursery: this.nursery,
                        kid: this.kid,
                        familyMember: this.selectedFamilyMember,
                        showAuthorizations: true,
                    },
                    listeners: modal => ({
                        update: familyMember => {
                            this.updateFamilyMember(familyMember);
                            modal.hide();
                        },
                        delete: () => {
                            this.deleteFamilyMember();
                            modal.hide();
                        },
                        detach: () => {
                            this.detachFamilyMember();
                            modal.hide();
                        },
                    }),
                });
                editFamilyMemberModal.show();
            },

            showCreateFamilyMemberModal() {
                const createFamilyMemberModal = useModal({
                    component: CreateFamilyMemberModal,
                    props: {
                        nursery: this.nursery,
                        kid: this.kid,
                        familyMember: this.newFamilyMember,
                        contactFamilyMembers: this.contactFamilyMembers,
                    },
                    listeners: modal => ({
                        add: familyMember => {
                            this.storeFamilyMember(familyMember);
                            modal.hide();
                        },
                    }),
                });
                createFamilyMemberModal.show();
            },
            getAllFamilyMembers() {
                this.loading = true;

                MemberModel.query()
                    .with(new MemberModel().kids(), query => {
                        query.where('id', this.kid.id);
                    })
                    .where(query => {
                        query
                            .whereHas(new MemberModel().kids(), query1 => {
                                query1.where('id', this.kid.id);
                            })
                            .orWhereHas(new MemberModel().families(), query1 => {
                                query1.where('id', this.kid.family_id);
                            });
                    })
                    .paginate(100, 1)
                    .then(response => {
                        this.kid.familyMembers = [];

                        response
                            .items()
                            .all()
                            .forEach(member => {
                                const legacyMappedMember = {
                                    id: member.id,
                                    nursery_id: this.nursery.id,
                                    first_name: member.attributes.first_name,
                                    last_name: member.attributes.last_name,
                                    gender: member.attributes.gender,
                                    email: member.attributes.email,
                                    job: member.attributes.job,
                                    avatar_url: member.attributes.avatar_url,
                                    birthdate: member.attributes.birthdate,
                                    allocator_no: member.attributes.allocator_no,
                                    insurance_code: member.attributes.insurance_code,

                                    line_1: member.attributes.address.line1,
                                    line_2: member.attributes.address.line2,
                                    city: member.attributes.address.city,
                                    postal_code: member.attributes.address.postal_code,
                                    state: member.attributes.address.state,
                                    country_code: member.attributes.address.country_code,

                                    mobile_phone: member.attributes.mobile_phone,
                                    office_phone: member.attributes.office_phone,
                                    phone: member.attributes.phone,

                                    no: member.attributes.no,
                                    note: member.attributes.note,

                                    role: null,
                                    can_pickup_kid: 0,
                                    permissions: [],
                                };

                                const kids = member.kids().value()?.all();
                                if (kids && kids.length) {
                                    // Works because filter by kid id in the top query so we have only one kid
                                    const pivot = kids[0].pivot();

                                    legacyMappedMember.permissions = pivot.attributes.permissions;
                                    legacyMappedMember.role = pivot.attributes.role;
                                    // Convert to tinyint because in the legacy api it's a tinyint
                                    legacyMappedMember.can_pickup_kid = pivot.attributes.can_pickup_kid ? 1 : 0;

                                    if (legacyMappedMember.role === 'mother' || legacyMappedMember.role === 'father') {
                                        Vue.set(legacyMappedMember, 'isParent', true);
                                    }

                                    this.kid.familyMembers.push(legacyMappedMember);
                                } else {
                                    this.contactFamilyMembers.push(legacyMappedMember);
                                }
                            });
                        this.loading = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            storeFamilyMember(familyMember) {
                this.kid.familyMembers.push(familyMember);
            },

            updateFamilyMember(familyMember) {
                Vue.set(this.kid.familyMembers, this.selectedFamilyMember.index, familyMember);
            },

            attachFamilyMember(familyMember) {
                useApi()
                    .legacy.post(
                        route('nurseries.kids.familyMembers.attach', {
                            nurseries: this.nursery.id,
                            kid: this.kid.id,
                            familyMember: familyMember.id,
                        }),
                        familyMember
                    )
                    .then(response => {
                        const familyToRemove = this.contactFamilyMembers.find(item => item.id === familyMember.id);
                        if (familyToRemove) {
                            const index = this.contactFamilyMembers.indexOf(familyToRemove);
                            this.contactFamilyMembers.splice(index, 1);
                        }

                        this.kid.familyMembers.push(response.data);
                        useNotification().success(this.__('family_member:contact_attached_successfully'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            deleteFamilyMember() {
                this.kid.familyMembers.splice(this.selectedFamilyMember.index, 1);
                useNotification().success(this.__('family_member:parent.deleted_successfully'));
            },

            detachFamilyMember() {
                const familyMemberToDetach = this.kid.familyMembers.find(
                    item => item.id === this.selectedFamilyMember.id
                );
                if (familyMemberToDetach) {
                    const index = this.kid.familyMembers.indexOf(familyMemberToDetach);
                    this.kid.familyMembers.splice(index, 1);
                    this.contactFamilyMembers.push(familyMemberToDetach);
                    useNotification().success(this.__('family_member:parent.detached_successfully'));
                }
            },

            getRoleText(familyMember) {
                const roles = familyMember.gender === 'female' ? this.roles.female : this.roles.male;
                const role = roles.find(item => item.value === familyMember.role) || roles['other'];

                return role.text;
            },

            getPhoneText(familyMember) {
                const fallback = this.__('common:not_filled');
                const mobile_phone = familyMember.mobile_phone ? familyMember.mobile_phone : fallback;
                const phone = familyMember.phone ? familyMember.phone : fallback;
                const office_phone = familyMember.office_phone ? familyMember.office_phone : fallback;

                return (
                    `${this.__('common:phone.cellular')} - ${mobile_phone} <br>` +
                    `${this.__('common:phone.landline')} - ${phone} <br>` +
                    `${this.__('common:phone.work')} - ${office_phone}`
                );
            },
        },
    };
</script>
