import {useWindowSize} from '@vueuse/core';
import {ref, watch} from 'vue';
import {throttle} from 'lodash-es';

const {width: useWidth} = useWindowSize();
export const width = ref();

watch(
    useWidth,
    throttle(
        () => {
            width.value = useWidth.value;
        },
        200,
        {leading: true, trailing: true}
    ),
    {immediate: true}
);
