<template>
    <div class="tw-flex tw-flex-col tw-gap-4">
        <div class="tw-flex tw-flex-col tw-gap-6 sm:tw-flex-row">
            <MInput
                v-model="group.name"
                class="tw-order-2 tw-w-full sm:tw-order-1"
                :placeholder="__('planning:group_name')"
                type="text"
            />
            <div class="tw-order-1 tw-flex tw-flex-row tw-justify-end tw-gap-2 sm:tw-order-2 sm:tw-justify-start">
                <MButton
                    variant="primary"
                    @click="$emit('saveGroup', $event)"
                >
                    {{ __('common:actions.save') }}
                </MButton>
                <MButton @click="$emit('cancelEditGroup', $event)">
                    {{ __('common:actions.cancel') }}
                </MButton>
                <CTooltip placement="bottom-start">
                    <MButton
                        variant="danger"
                        @click="$emit('deleteGroup', $event)"
                    >
                        {{ __('common:actions.delete') }}
                    </MButton>
                    <template #content>
                        {{ __('common:delete_group') }}
                    </template>
                </CTooltip>
            </div>
        </div>
        <CInputGroup>
            <MInput
                v-model.number="group.places"
                size="lg"
                type="number"
            />
            <CInputAddon class="tw-shrink-0">
                {{ __('common:place', {count: group.places}) }}
            </CInputAddon>
        </CInputGroup>
        <PlanningSettingsRuleEditCard
            v-for="(rule, n) in group.rules"
            :key="'rule' + n"
            :group="group"
            :index="n"
            :my-nursery="myNursery"
            :nursery="nursery"
            :rule="rule"
        />
        <MButton
            icon-plus
            :label="__('common:add_rule')"
            variant="light"
            @click="addRule(group)"
        />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import PlanningSettingsRuleEditCard from '@/modules/planning/components/settings/PlanningSettingsGroup/molecules/PlanningSettingsRuleEditCard.vue';

    export default defineComponent({
        components: {PlanningSettingsRuleEditCard},
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            myNursery: {
                type: Object,
                required: true,
            },
            group: {
                type: Object,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
        },

        setup(props) {
            function addRule(group) {
                const rule = {
                    staffs: [
                        {
                            job_id: null,
                            staffs: 1,
                        },
                    ],
                    kids: 3,
                };
                if (group) {
                    group.rules.push(rule);
                } else {
                    props.myNursery.supervision_rules.push(rule);
                }
            }

            return {
                addRule,
            };
        },
    });
</script>
