import PaymentModel from '@/modules/cashier/models/PaymentModel';
import InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import type {ModelCollection} from '@meekohq/lumos';

export default function useFetchInvoicePayments() {
    /**
     * Get the payment for the invoice.
     * @param invoice
     */
    function fetch(invoice: {invoice_type: string; id: string}): Promise<ModelCollection<PaymentModel>> {
        return PaymentModel.query()
            .where(query => {
                if (invoice.invoice_type === InvoiceTypeValue.invoice) {
                    query.whereHas(new PaymentModel().invoices(), query2 => {
                        query2.where('id', invoice.id);
                    });
                } else {
                    query.whereHas(new PaymentModel().creditNotes(), query2 => {
                        query2.where('id', invoice.id);
                    });
                }
            })
            .with(new PaymentModel().invoices(), query1 => {
                query1.with(new InvoiceModel().customer());
            })
            .with(new PaymentModel().creditNotes())
            .orderBy('due_date')
            .get();
    }

    return {
        fetch,
    };
}
