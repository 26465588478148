<template>
    <CModal
        ref="modal"
        :emitter="bus"
        :header-title="__('billing_core:modify_contact')"
        visible
        @hidden="$emit('hidden')"
    >
        <ContactForm
            ref="form"
            :contact-model="clone"
            @loading="loader.set($event)"
            @saved="onContactUpdated"
        />
        <template #footer="{closeDialog}">
            <MButton
                class="tw-ml-auto"
                @click="closeDialog"
            >
                {{ __('common:actions.cancel') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loader.isLoading()"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import ContactForm from '@/modules/cashier/components/core/contact/organisms/ContactForm.vue';
    import type ContactModel from '@/modules/cashier/models/ContactModel';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import mitt from 'mitt';

    export default defineComponent({
        components: {
            ContactForm,
        },
        props: {
            contactModel: {
                type: Object as PropType<ContactModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const loader = new Loader();

            const bus = mitt<{hide: void}>();
            // Create a clone of the contact model for update
            const clone = props.contactModel.clone();

            const form = ref();

            function onContactUpdated(contactModel: ContactModel) {
                // Copy the updated contact model to the clone and the original model
                contactModel.copyTo(clone);
                contactModel.copyTo(props.contactModel);

                bus.emit('hide');
                emit('updated', contactModel);
            }

            return {
                bus,
                form,
                loader,
                clone,
                onContactUpdated,
            };
        },
    });
</script>
