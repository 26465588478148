<template>
    <CForm @submit.prevent="save">
        <CFormGroup>
            <CLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-duotone fa-address-book" />
                </template>
                {{ __('common:family') }}
            </CLabel>
            <SelectFamily
                v-model="localFamilyModel"
                button-class="tw-w-full"
                :disabled="familyModel !== undefined"
                dropdown-class="tw-w-full"
                :organization-model="organizationModel"
                @input="onFamilyChange"
            />
            <CFormErrorMessageList :errors="kidError.getErrors('family_id')" />
        </CFormGroup>
        <CInline class="tw--mx-2">
            <CFormGroup class="tw-w-1/2 tw-px-2">
                <CLabel> {{ __('common:first_name') }}* </CLabel>
                <CInput
                    ref="first_name"
                    v-model="kidModel.attributes.first_name"
                    v-focus
                    :has-error="kidError.hasError('first_name')"
                    :placeholder="__('common:first_name')"
                />
                <CFormErrorMessageList :errors="kidError.getErrors('first_name')" />
            </CFormGroup>
            <CFormGroup class="tw-w-1/2 tw-px-2">
                <CLabel> {{ __('common:last_name') }}* </CLabel>
                <CInput
                    v-model="kidModel.attributes.last_name"
                    :has-error="kidError.hasError('last_name')"
                    :placeholder="__('common:last_name')"
                />
                <CFormErrorMessageList :errors="kidError.getErrors('last_name')" />
            </CFormGroup>
        </CInline>
        <CInline class="tw--mx-2">
            <CFormGroup class="tw-w-1/2 tw-px-2">
                <CLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-venus-mars" />
                    </template>
                    {{ __('common:gender_one') }}
                </CLabel>
                <CFormSelect
                    v-model="kidModel.attributes.gender"
                    :has-error="kidError.hasError('gender')"
                    :options="genderOptions"
                    :search-bar="false"
                />
                <CFormErrorMessageList :errors="kidError.getErrors('gender')" />
            </CFormGroup>
            <CFormGroup class="tw-w-1/2 tw-px-2">
                <CLabel>
                    <FontAwesomeIcon
                        class="tw-mr-2 tw-text-blue-500"
                        icon="fa-duotone fa-users"
                    />
                    {{ __('common:group_one') }}
                </CLabel>
                <CFormSelect
                    v-model="kidModel.attributes.group_id"
                    :fallback-text="__('common:no_group')"
                    :has-error="kidError.hasError('group_id')"
                    list-class="tw-max-h-32"
                    :options="nurseryGroups"
                    text-path="name"
                    :unselect-value="null"
                    value-path="id"
                />
                <CFormErrorMessageList :errors="kidError.getErrors('group_id')" />
            </CFormGroup>
        </CInline>
        <CFormGroup>
            <CLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-solid fa-birthday-cake" />
                </template>
                {{ __('common:birthdate') }}*
            </CLabel>
            <MDatePicker
                v-model="kidModel.attributes.birthdate"
                format="yyyy-MM-dd"
                :has-error="kidError.hasError('birthdate')"
            />
            <CFormErrorMessageList :errors="kidError.getErrors('birthdate')" />
        </CFormGroup>
        <CFormGroup>
            <CLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-solid fa-tint" />
                </template>
                {{ __('family_kid:hygiene') }}
            </CLabel>
            <CFormSelect
                v-model="kidModel.attributes.hygiene"
                :has-error="kidError.hasError('hygiene')"
                list-class="tw-max-h-32"
                :options="hygieneOptions"
                :search-bar="false"
            />
            <CFormErrorMessageList :errors="kidError.getErrors('hygiene')" />
        </CFormGroup>
        <CFormSubmitTrigger />
    </CForm>
</template>

<script setup lang="ts">
    import __ from '@/modules/app/utils/i18n-facade';
    import KidModel from '@/modules/family/models/KidModel';
    import KidError from '@/modules/family/utils/kid/kidError';
    import {cloneDeep, head, isEmpty} from 'lodash-es';
    import SelectFamily from '@/modules/family/components/family/SelectFamily.vue';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import {catcher, MqlTransaction, ValidationError} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, onBeforeMount, onMounted, ref} from 'vue';

    const props = defineProps({
        organizationModel: {
            type: Object as PropType<OrganizationModel>,
            required: true,
        },
        familyModel: {
            type: Object as PropType<FamilyModel>,
            required: false,
        },
    });

    const emit = defineEmits(['loading', 'created']);

    defineExpose({
        save,
    });

    const kidModel = ref(new KidModel());
    const kidError = ref(new KidError());
    const localFamilyModel = ref<FamilyModel>();

    const hygieneOptions = [
        {value: 'baby', text: __('common:hygiene_types.diaper')},
        {value: 'potty', text: __('family_kid:potty')},
        {value: 'mixed', text: __('common:learning')},
    ];

    const genderOptions = [
        {value: 'male', text: __('common:gender.boy')},
        {value: 'female', text: __('common:gender.girl')},
    ];

    const nurseryGroups = computed(() => {
        return props.organizationModel.extra.legacy_groups.map(group => {
            return {
                id: `${group.id}`,
                name: group.name,
            };
        });
    });

    function onFamilyChange(familyModel: FamilyModel | undefined) {
        if (familyModel === undefined) {
            kidModel.value.attributes.family_id = undefined;
        } else {
            kidModel.value.attributes.last_name = familyModel.attributes.name;
            kidModel.value.attributes.family_id = familyModel.getKey();
        }
    }

    async function save() {
        emit('loading', true);

        const mqlRunner = new MqlTransaction();

        kidModel.value
            .save({mqlRunner})
            .then(() => {
                kidError.value.reset();
            })
            .catch(e => {
                catcher()
                    .on(ValidationError, () => kidError.value.reset(e))
                    .catch(e);
            });

        kidModel.value
            .organizations()
            .attach(
                props.organizationModel,
                {
                    account_id: props.organizationModel.attributes.account_id,
                },
                {mqlRunner}
            )
            .then(() => {
                //
            });

        await mqlRunner.run();

        if (mqlRunner.successfull) {
            emit('created', kidModel.value);
        }

        emit('loading', false);
    }

    onMounted(() => {
        if (props.familyModel) {
            localFamilyModel.value = cloneDeep(props.familyModel);
            onFamilyChange(localFamilyModel.value);
        }
    });

    onBeforeMount(() => {
        kidModel.value.attributes.organization_id = props.organizationModel.id;
        kidModel.value.attributes.account_id = props.organizationModel.attributes.account_id;
        kidModel.value.attributes.gender = 'male';
        kidModel.value.attributes.hygiene = 'baby';

        if (!isEmpty(nurseryGroups.value)) {
            // @ts-ignore
            kidModel.value.attributes.group_id = head(nurseryGroups.value).id as string;
        }
    });
</script>
