<template>
    <CModal
        ref="modal"
        :emitter="bus"
        :header-title="__('document:edit_document')"
        :loading="isLoading"
        visible
        @hidden="$emit('hidden')"
    >
        <DocumentForm
            :displayed-visibilities="displayedVisibilities"
            :error-form="documentFormError"
            :show-organizations="showOrganizations"
            :store="documentStore"
        />
        <template #footer-start>
            <AlertModal
                v-if="isAlertModalVisible"
                :text="__('document:delete_document_ask')"
                :title="__('document:delete_document')"
                @confirmed="remove"
                @hidden="isAlertModalVisible = false"
            />
            <MButton
                v-if="can('delete', permissionKey)"
                icon-trash
                :label="__('common:actions.delete')"
                variant="danger"
                @click="isAlertModalVisible = true"
            />
        </template>
        <template #footer-end="{closeDialog}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="closeDialog"
            />
            <MButton
                :loading="loading"
                variant="primary"
                @click="save"
            >
                {{ __('common:actions.update') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, onBeforeMount, ref, toRef} from 'vue';
    import DocumentForm from '@/modules/document/components/organisms/DocumentForm.vue';
    import type UserModel from '@/modules/user/models/UserModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import DocumentModel from '@/modules/document/models/DocumentModel';
    import type DocumentOrganizationPivot from '@/modules/document/models/DocumentOrganizationPivot';
    import type {VisibilitiesType} from '@/modules/document/stores/useDocumentStore';
    import useDocumentStore from '@/modules/document/stores/useDocumentStore';
    import AlertModal from '@/modules/app/components/organisms/AlertModal.vue';
    import type {Emitter} from 'mitt';
    import mitt from 'mitt';
    import useAbility from '@/modules/app/composables/useAbility';
    import type {EventType} from '@/modules/meeko-ui/utils/ModalEventType';
    import type {SubjectsType} from '@/modules/user/utils/ActionsType';

    export default defineComponent({
        components: {AlertModal, DocumentForm},
        props: {
            documentId: {
                type: String,
                required: true,
            },
            user: {
                type: Object as PropType<UserModel>,
                default: undefined,
            },
            activeOrganization: {
                type: Object as PropType<OrganizationModel>,
                default: undefined,
            },
            showOrganizations: {
                type: Boolean,
                default: false,
            },
            displayedVisibilities: {
                type: Object as PropType<VisibilitiesType>,
                required: false,
                default: () => ({
                    staffs_access: false,
                    parents_access: false,
                    web_access: false,
                }),
            },
            bus: {type: Object as PropType<Emitter<EventType>>, default: () => mitt()},
            permissionKey: {
                type: String as PropType<SubjectsType>,
                default: 'documents',
            },
        },
        setup(props, {emit}) {
            const isLoading = ref(true);
            const isAlertModalVisible = ref(false);
            const {can} = useAbility();
            // Instantiate the store
            const documentStore = useDocumentStore(toRef(props, 'activeOrganization'));

            // Extract the properties we need for template
            const {bus, document, documentFormError, loading, save, remove} = documentStore;

            // Set store values
            onBeforeMount(async () => {
                // We need to wait for the store to be setup
                await setupStore(documentStore, props.documentId, props.user);

                isLoading.value = false;
            });

            bus.on('saved', () => emit('updated', document.value));
            bus.on('deleted', () => emit('deleted', document.value));

            return {
                isLoading,
                loading,
                documentStore,
                documentFormError,
                isAlertModalVisible,
                can,
                save,
                remove,
            };
        },
    });

    async function setupStore(documentStore: ReturnType<typeof useDocumentStore>, documentId: string, user: UserModel) {
        // Resolve the document by id
        documentStore.document.value = await DocumentModel.query()
            .with(new DocumentModel().organizations())
            .find(documentId);

        // Clone the document to prevent changes to the cache
        documentStore.document.value = documentStore.document.value.clone();

        // Load the user organizations and set them in the available organizations of the store
        documentStore.setAvailableOrganizations(await user.organizations().load());

        // Load the document organizations
        await documentStore.document.value.organizations().load();

        // Get the first pivot
        const pivot = documentStore.document.value
            .organizations()
            .value()
            .first()
            ?.pivot<DocumentOrganizationPivot>().attributes;

        // Set the visibilities from the pivot
        documentStore.visibilities.value = {
            staffs_access: pivot.staffs_access,
            parents_access: pivot.parents_access,
            web_access: pivot.web_access,
        } as VisibilitiesType;

        documentStore.selectedOrganizations.value = documentStore.document.value.organizations().value().toArray();
    }
</script>
