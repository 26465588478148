import Vue, {type Ref, ref} from 'vue';
import __ from '@/modules/app/utils/i18n-facade';
import TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import TicketTypeValue from '@/modules/activity/utils/TicketTypeValue';
import {app, collect, Epoch} from '@meekohq/lumos';
import useManager from '@/modules/app/composables/useManager';
import type RegistrationModel from '@/modules/registration/models/RegistrationModel';
import useConcurrentCallback from '@/modules/app/composables/useConcurrentCallback';
import {GetTasksUseCaseBinding} from '@/modules/activity/ticket/application/use-cases/GetTasksUseCase';
import TicketFilterDto from '@/modules/activity/ticket/application/dto/TicketFilterDto';
import useUserStaff from '@/modules/human-resources/composables/staff/useUserStaff';

export default function (resourceModel: Ref<RegistrationModel | undefined>) {
    const tasks = ref(collect<TicketModel>());
    const {resolveLastCallback} = useConcurrentCallback();

    const {activeOrganization} = useManager();
    const {userStaff, retrieveUserStaff} = useUserStaff();

    function waitForQueryToBeReady() {
        return retrieveUserStaff();
    }

    const newTask = function () {
        const task = new TicketModel();
        task.attributes.type = TicketTypeValue.task;
        task.attributes.account_id = activeOrganization.value.attributes.account_id;
        task.extra.draftDate = Epoch.now();

        if (resourceModel.value) {
            Vue.set(task.extra, 'resources', [resourceModel.value]);
        }

        tasks.value.push(task);
    };

    const removeTask = function (deletedTask: TicketModel) {
        tasks.value = tasks.value.reject(task => task.id === deletedTask.id);

        // If recurring task reload all tasks to keep on track
        if (deletedTask.attributes.parent_id || deletedTask.attributes.recurrence_ruleset) {
            getTasks();
        }
    };

    const onCompletedTask = function (completedTask: TicketModel) {
        if (completedTask.attributes.parent_id || completedTask.attributes.recurrence_ruleset) {
            getTasks();
        }
    };

    const getTasks = async function () {
        try {
            await waitForQueryToBeReady();

            const filtersDto = TicketFilterDto.make({
                type: 'task',
                resource: resourceModel.value,
                assignedTo: userStaff.value ? [userStaff.value] : undefined,
                notAssigned: true,
                reportedBy: userStaff.value ? [userStaff.value] : undefined,
                assignedToOrReportedByConstrain: true,
            });

            const tickets = await resolveLastCallback(() => {
                return app(GetTasksUseCaseBinding).getTasksByFilters(filtersDto);
            });

            tasks.value = tickets.map((ticketModel: TicketModel) => {
                // If true, we have pivots that are not related to registrations
                if (ticketModel.relationships.ticketPivots_count > ticketModel.ticketPivots().value().count()) {
                    ticketModel.extra = {
                        deleteConfirmationMessage: __('activity:this_task_also_concerns_other_records_warning'),
                    };
                }

                return ticketModel;
            });
        } catch (e) {
            // We don't do anything here, the result is outdated
            return;
        }
    };

    return {
        getTasks,
        newTask,
        onCompletedTask,
        removeTask,
        tasks,
    };
}
