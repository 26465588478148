<template>
    <div class="OverrunSubSegments">
        <div
            v-for="(segment, index) in segments"
            :key="index"
            class="tw-absolute tw-h-full"
            :class="{'tw-rounded-l-full': segment.left === '0%', 'tw-rounded-r-full': segment.right === '0%'}"
            :style="segment"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, toRef} from 'vue';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import MakeOverrunSegments from '@/modules/human-resources/utils/calendar/MakeOverrunSegments';

    export default defineComponent({
        components: {},
        props: {
            event: {type: Object as PropType<EventModel>, required: true},
            events: {type: Array as PropType<EventModel[]>, required: true},
        },
        setup(props) {
            const eventsWithLiveEvent = computed(() => props.events.concat([props.event]));

            const segments = new MakeOverrunSegments(toRef(props, 'event'), eventsWithLiveEvent).segments;

            return {
                segments,
            };
        },
    });
</script>
