<template>
    <input
        :checked="isChecked"
        class="CRadio"
        :disabled="disabled"
        type="radio"
        :value="option"
        @change="$emit('input', option)"
    />
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        props: {
            value: {type: String as PropType<string>, required: true},
            disabled: {type: Boolean as PropType<boolean>, default: false},
            option: {type: String as PropType<string>, required: true},
        },
        emits: ['input'],
        setup(props) {
            const isChecked = computed(() => props.option === props.value);

            return {
                isChecked,
            };
        },
    });
</script>

<style scoped>
    .CRadio {
        background-origin: border-box;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        @apply tw-cursor-pointer tw-appearance-none tw-rounded-full tw-border tw-border-gray-300 tw-bg-white tw-text-xl tw-transition tw-duration-200;
        @apply tw-inline-block tw-h-4 tw-w-4 tw-shrink-0 tw-select-none tw-align-middle tw-text-blue-500;

        &:focus {
            @apply tw-border-blue-500 tw-ring;
        }

        &:checked {
            border-color: transparent;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
            background-position: 50%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-color: currentColor;

            &:focus {
                @apply tw-border-blue-500;
            }
        }

        &:disabled {
            @apply tw-cursor-not-allowed tw-opacity-50;
        }
    }
</style>
