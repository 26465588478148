<script setup lang="ts">
    import {computed, onMounted, type PropType, ref} from 'vue';

    const props = defineProps({
        value: {
            type: String,
            required: false,
            default: undefined,
        },
        colors: {
            type: Array as PropType<string[]>,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    });

    const emit = defineEmits(['input']);

    onMounted(() => {
        if (!props.value) {
            emit('input', props.colors[0]);
        }
    });

    const colorInput = ref();

    const sanitizedValue = computed(() => {
        return props.value ? props.value.toLowerCase() : '';
    });

    const sanitizedColors = props.colors.map((color: string) => {
        return color.toLowerCase();
    });
</script>

<template>
    <MPopover :trigger="disabled ? 'none' : 'click'">
        <div
            class="ColorSwatches__button"
            :class="{'ColorSwatches__button--disabled': disabled}"
            :style="{backgroundColor: sanitizedValue}"
        ></div>
        <template #content>
            <div class="ColorSwatches__grid">
                <template v-for="color in sanitizedColors">
                    <div
                        :key="color"
                        class="ColorSwatches__item_wrapper"
                    >
                        <div
                            class="ColorSwatches__item"
                            :class="{'ColorSwatches__item--selected': sanitizedValue === color}"
                            :style="{backgroundColor: color}"
                            @click="emit('input', color)"
                        >
                            <span
                                v-if="sanitizedValue === color"
                                class="ColorSwatches__checked-icon"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-check" />
                            </span>
                        </div>
                    </div>
                </template>
                <div class="ColorSwatches__item_wrapper">
                    <div
                        class="ColorSwatches__item ColorSwatches__custom-item"
                        :style="{
                            backgroundColor: !sanitizedColors.includes(sanitizedValue) ? sanitizedValue : '',
                        }"
                        :class="{
                            'ColorSwatches__item--selected': !sanitizedColors.includes(sanitizedValue),
                        }"
                        @click="colorInput.click()"
                    >
                        <span class="ColorSwatches__checked-icon">
                            <FontAwesomeIcon icon="fa-solid fa-eye-dropper-half" />
                        </span>
                        <input
                            ref="colorInput"
                            :value="sanitizedValue"
                            type="color"
                            class="tw-absolute tw-h-0 tw-w-0 tw-opacity-0"
                            @input="emit('input', $event.target.value)"
                        />
                    </div>
                </div>
            </div>
        </template>
    </MPopover>
</template>

<style scoped>
    .ColorSwatches__button {
        @apply tw-flex tw-aspect-square tw-w-7 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-shadow;
    }

    .ColorSwatches__button--disabled {
        @apply tw-cursor-not-allowed;
    }

    .ColorSwatches__grid {
        @apply tw-grid tw-grid-cols-4 tw-gap-2;
    }

    .ColorSwatches__item_wrapper {
        @apply tw-flex tw-items-center tw-justify-center;
    }

    .ColorSwatches__item {
        @apply tw-flex tw-aspect-square tw-w-7 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-duration-300 hover:tw-scale-125;
    }

    .ColorSwatches__item--selected {
        transform: scale(1.25);
    }

    .ColorSwatches__checked-icon {
        @apply tw-text-[14px] tw-text-white;
    }

    .ColorSwatches__custom-item {
        @apply tw-bg-white tw-ring-1 tw-ring-gray-300;

        .ColorSwatches__checked-icon {
            @apply tw-text-black;
        }

        &.ColorSwatches__item--selected {
            @apply tw-ring-0;

            .ColorSwatches__checked-icon {
                @apply tw-text-white;
            }
        }
    }
</style>
