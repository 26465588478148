<template>
    <div>
        <div class="tw-flex tw-flex-wrap print:tw-hidden">
            <div class="tw-w-full lg:tw-w-1/2">
                <StaffAvatar
                    class="tw-float-left tw-mr-3 tw-w-16"
                    :staff-model="staff"
                />
                <div class="tw-my-2 tw-flex tw-flex-wrap tw-items-center">
                    <h4
                        class="h4 tw-mb-0 tw-mr-2 tw-inline-block tw-w-full tw-font-semibold tw-capitalize tw-text-gray-600 md:tw-w-auto"
                    >
                        {{ $fullName(staff.attributes) }}
                    </h4>
                    <MButton
                        v-if="$can('update', 'staffs_details')"
                        class="tw-mt-2 md:tw-mt-1"
                        size="sm"
                        variant="primary"
                        @click="$refs.staffModal.$refs.modal.show()"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-pencil" />
                        </template>
                        {{ __('common:actions.update') }}
                    </MButton>
                </div>
                <div>
                    <div class="tw-inline-block tw-text-gray-500">
                        <template v-if="staff.attributes.job"> {{ staff.attributes.job }}&nbsp;-&nbsp; </template>
                        {{ __('common:clockin_code') }}
                        <CBadge variant="cyan">
                            {{ staff.attributes.code ? staff.attributes.code : __('common:n_a') }}
                        </CBadge>
                    </div>
                </div>
            </div>

            <div class="tw-mt-3 tw-w-full lg:tw-mt-0 lg:tw-w-1/2">
                <CFormGroup>
                    <CFormTextArea
                        v-model="staff.attributes.note"
                        class="tw-resize-none tw-bg-blue-100"
                        disabled
                        :placeholder="__('common:note_one')"
                        rows="3"
                    />
                </CFormGroup>
            </div>
        </div>

        <div class="tw-my-6 tw-rounded-lg tw-bg-blue-50 tw-p-2 print:tw-hidden">
            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-px-5 md:tw-gap-0 md:tw-text-center">
                <div class="tw-w-full md:tw-w-1/3">
                    <template v-if="staff.attributes.mobile_phone">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-blue-500"
                            icon="fa-solid fa-mobile-alt"
                        />
                        {{ staff.attributes.mobile_phone }}
                    </template>
                </div>
                <div class="tw-w-full md:tw-w-1/3">
                    <template v-if="staff.attributes.phone">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-blue-500"
                            icon="fa-solid fa-phone"
                        />
                        {{ staff.attributes.phone }}
                    </template>
                </div>
                <div class="tw-w-full md:tw-w-1/3">
                    <template v-if="staff.attributes.email">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-blue-500"
                            icon="fa-solid fa-at"
                        />
                        {{ staff.attributes.email }}
                    </template>
                </div>
            </div>
        </div>

        <Planning
            v-if="$can('read', 'staffs_planning')"
            :staff="staff"
        />

        <StaffModal
            ref="staffModal"
            :staff="staff"
        />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import Planning from '@/modules/human-resources/components/staff/Summary/Planning/Show.vue';
    import StaffModal from '@/modules/human-resources/components/staff/StaffModal.vue';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';

    export default defineComponent({
        components: {
            StaffAvatar,
            Planning,
            StaffModal,
        },
        props: ['staff'],
    });
</script>
