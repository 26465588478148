import Vue from 'vue';
import type {ToastAction} from 'vue-toasted';

export default function useNotification() {
    return {
        show: (text: string, action?: ToastAction[]) => {
            Vue.toasted.show(text, {
                type: 'default',
                action,
            });
        },
        success: (text: string, action?: ToastAction[]) => {
            Vue.toasted.show(text, {
                type: 'success',
                iconPack: 'custom-class',
                icon: {
                    name: 'fa-regular fa-circle-check fa-fw',
                    after: false,
                },
                action,
            });
        },
        info: (text: string, action?: ToastAction[]) => {
            Vue.toasted.show(text, {
                type: 'info',
                iconPack: 'custom-class',
                icon: {
                    name: 'fa-regular fa-circle-info fa-fw',
                    after: false,
                },
                action,
            });
        },
        error: (text: string, action?: ToastAction[]) => {
            Vue.toasted.show(text, {
                type: 'error',
                iconPack: 'custom-class',
                icon: {
                    name: 'fa-regular fa-circle-xmark fa-fw',
                    after: false,
                },
                action,
            });
        },
    };
}
