<template>
    <CVStack gap="3">
        <slot name="filters" />
        <template v-if="loading">
            <SimulationListResourceSkeleton />
            <SimulationListResourceSkeleton />
        </template>
        <div
            v-else
            class="tw-relative"
        >
            <CText
                v-if="registrations.isEmpty()"
                class="tw-whitespace-nowrap"
            >
                {{ __('planning:no_registrations') }}
            </CText>
            <CVStack gap="2">
                <RegistrationItem
                    v-for="registration in registrations"
                    :key="registration.attributes.uuid"
                    :is-in-simulation="!attachToSimulation"
                    :registration="registration"
                    @add="$emit('attach', $event)"
                    @remove="$emit('detach', $event)"
                />
            </CVStack>
        </div>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import SimulationListResourceSkeleton from '@/modules/planning/components/simulation/List/SimulationListResourceSkeleton.vue';
    import RegistrationItem from '@/modules/planning/components/simulation/RegistrationItem.vue';
    import type RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import type {ModelCollection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';

    export default defineComponent({
        components: {SimulationListResourceSkeleton, RegistrationItem},
        props: {
            attachToSimulation: {type: Boolean, default: false},
            loading: {type: Boolean, default: false},
            registrations: {
                type: Object as PropType<ModelCollection<RegistrationModel>>,
                default: () => collect<RegistrationModel>(),
            },
        },
    });
</script>
