<template>
    <CTabMenu variant="orange">
        <CTabMenuItem
            :exact="true"
            :to="{name: 'cashier/customers.show.summary'}"
            variant="orange"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-solid fa-tachometer-alt"
            />{{ __('common:summary') }}
        </CTabMenuItem>
        <CTabMenuItem
            v-if="$can('read', 'invoices')"
            :to="{name: 'cashier/customers.show.invoices'}"
            variant="orange"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-solid fa-file-invoice"
            />{{ __('common:invoice_other') }}
        </CTabMenuItem>
        <CTabMenuItem
            v-if="$can('read', 'transactions')"
            :to="{name: 'cashier/customers.show.payments'}"
            variant="orange"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-solid fa-money-check-edit-alt"
            />{{ __('common:transactions') }}
        </CTabMenuItem>
    </CTabMenu>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>
