<template>
    <MMenu>
        <MMenuButton>
            <MButton icon-menu>
                {{ __('planning:planning_type') }}
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem
                v-for="option in realOptions"
                :key="option.value"
                :auto-close-popover="false"
                @click="updateFilter(option.value)"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="filterValue.includes(option.value)"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                </template>
                {{ option.text }}
            </MMenuItem>
        </MMenuItems>
    </MMenu>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            value: {
                type: Array as PropType<string[]>,
                default: () => [],
            },
        },
        emits: ['input'],
        setup(props, {emit}) {
            const filterValue = ref<string[]>(props.value);

            const realOptions = [
                {
                    value: 'forecast-occasional',
                    text: __('common:occasional'),
                },
                {
                    value: 'forecast-adaptation',
                    text: __('common:adaptation'),
                },
                {
                    value: 'real-absence',
                    text: __('common:absence_other'),
                },
            ];

            function updateFilter(value: string) {
                if (filterValue.value.includes(value)) {
                    filterValue.value = filterValue.value.filter(item => item !== value);
                } else {
                    filterValue.value.push(value);
                }

                emit('input', filterValue.value);
            }

            watch(
                () => props.value,
                () => {
                    filterValue.value = props.value;
                }
            );

            return {
                filterValue,
                realOptions,
                updateFilter,
            };
        },
    });
</script>
