import type {Ref} from 'vue';
import {ref, watch} from 'vue';
import {
    app,
    cache,
    collect,
    Collection,
    lumosBindings,
    Model,
    type ModelConstructorType,
    type QueryBuilder,
} from '@meekohq/lumos';
import _cloneDeep from 'lodash-es/cloneDeep';
import _groupBy from 'lodash-es/groupBy';
import _isNil from 'lodash-es/isNil';
import mitt from 'mitt';

export type OptionsType<T> = T | T[] | Collection<T>;

export enum OutputType {
    collection = 'collection',
    array = 'array',
    value = 'value',
}

interface SerializedModel {
    type: string;
    id: string;
}

enum SerializedType {
    custom = 'custom',
    model = 'model',
}

interface SerializedValue {
    type: SerializedType;
    value: string | SerializedModel;
}

export default function <T = unknown>(key: string, type: OutputType, defaultValue: OptionsType<T>) {
    const emitter = mitt();
    const selectedOptions = ref(defaultValue) as unknown as Ref<OptionsType<T> | undefined>;
    // At the beginning, the filter is not already hydrated
    const isLoading = ref(true);

    watch(isLoading, () => {
        emitter.emit(`userFiltersLoaded:${key}`, true);
    });

    // Is resolved when loading is finished
    async function waitForIsLoading(): Promise<void> {
        if (!isLoading.value) {
            return;
        }

        await new Promise<void>(resolve => {
            emitter.on(`userFiltersLoaded:${key}`, () => {
                resolve();
            });
        });
    }

    /**
     * Hydrate all serialized values from localstorage.
     */
    async function hydrateOptions() {
        const serializedValues = await cache().get<SerializedValue[]>(key, []);

        const customSerializedValues = collect(serializedValues)
            .where('type', SerializedType.custom)
            .toArray<SerializedValue>();
        const modelSerializedValues = collect(serializedValues)
            .where('type', SerializedType.model)
            .toArray<SerializedValue>();

        const temp = hydrateCustoms(customSerializedValues).concat(await hydrateModels(modelSerializedValues));

        if (temp && temp.length) {
            switch (type) {
                case OutputType.array:
                    selectedOptions.value = temp;
                    break;
                case OutputType.collection:
                    selectedOptions.value = collect(temp);
                    break;
                case OutputType.value:
                default:
                    selectedOptions.value = temp[0];
                    break;
            }
        }

        isLoading.value = false;
    }

    /**
     * Get custom value from serialized value.
     *
     * @param items
     */
    function hydrateCustoms(items: SerializedValue[]): T[] {
        return items.map(item => {
            return JSON.parse(item.value as string) as T;
        });
    }

    /**
     * Instanciate fresh models from serialized values.
     *
     * @param items
     */
    async function hydrateModels(items: SerializedValue[]): Promise<T[]> {
        const groups = _groupBy(items, i => (i.value as SerializedModel).type);
        let models = [];

        for (const type in groups) {
            const modelConstructor = getModelConstructorByType(type) as any | Model;
            const builder = modelConstructor.query() as unknown as QueryBuilder<any>;

            const ids = collect(groups[type]).pluck('value.id').toArray() as string[];
            const results = await builder.whereIn(builder.model.getKeyName(), ids).get();

            models = models.concat(results.toArray());
        }

        return models;
    }

    /**
     * Store selected options to localstorage.
     */
    async function serialize() {
        let options: unknown[];

        switch (type) {
            case OutputType.array:
                options = _cloneDeep(selectedOptions.value as unknown[]);
                break;
            case OutputType.collection:
                options = (_cloneDeep(selectedOptions.value) as Collection).toArray();
                break;
            case OutputType.value:
            default:
                options = [_cloneDeep(selectedOptions.value)];
                break;
        }

        const serializedValues: SerializedValue[] = options.map(serializeItem);

        await cache().put(key, serializedValues);
    }

    /**
     * Remove localstorage key in case of empty selected options.
     */
    async function purgeKey() {
        let options = selectedOptions.value;

        if (selectedOptions.value instanceof Collection) {
            options = selectedOptions.value.toArray();
        }

        if (_isNil(options) || (Array.isArray(options) && options.length < 1)) {
            await cache().forget(key);
        }
    }

    /**
     * Make a localstorage compatible value by serializing user input.
     *
     * @param item
     */
    function serializeItem(item: unknown): SerializedValue {
        if (item instanceof Model) {
            return {
                type: SerializedType.model,
                value: {
                    type: item.type,
                    id: item.id,
                },
            };
        }

        return {
            type: SerializedType.custom,
            value: JSON.stringify(item),
        };
    }

    /**
     * Get a model constructor by model type.
     *
     * @param type
     */
    function getModelConstructorByType(type: string): ModelConstructorType {
        const types: Record<string, ModelConstructorType> = {};

        const models = app(lumosBindings.ModelCollectionBinding);
        models.forEach(model => {
            types[(new model() as Model).getType()] = model;
        });

        return types[type];
    }

    hydrateOptions();

    watch(selectedOptions, async () => {
        isLoading.value = true;

        await serialize();
        await purgeKey();

        isLoading.value = false;
    });

    return {
        isLoading,
        waitForIsLoading,
        selectedOptions,
    };
}
