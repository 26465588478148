<template>
    <div class="CFileInput">
        <input
            ref="fileInput"
            class="CFileInput__input"
            type="file"
            @input="handleFile"
        />
        <MButton
            class="CFileInput__button"
            truncate
            @click="$refs.fileInput.click()"
        >
            <template v-if="file">
                {{ file.name }}
            </template>
            <template v-else>
                <slot name="placeholder">
                    {{ placeholder ? placeholder : __('components:select_file') }}
                </slot>
            </template>
        </MButton>
    </div>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {defineComponent, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            maxSize: {type: Number, required: true},
            mimeTypes: {type: Array as PropType<string[]>, default: () => []},
            placeholder: {type: String, default: ''},
        },
        setup(props, {emit}) {
            const fileInput = ref();
            const file = ref() as Ref<File>;

            function handleFile() {
                const currentFile = fileInput.value.files[0];

                if (!currentFile) {
                    return;
                }

                emit('error', []);
                const errors = handleErrors(currentFile);
                if (errors.length) {
                    emit('error', errors);
                } else {
                    emit('input', currentFile);
                    file.value = currentFile;
                }
            }

            function handleErrors(file: File) {
                const errors = [] as Error[];
                if (file.size > props.maxSize) {
                    errors.push(new Error(__('components:file_too_large', {value: props.maxSize / 1024 / 1024})));
                }

                if (props.mimeTypes?.length && !props.mimeTypes.includes(file.type)) {
                    errors.push(new Error(__('components:wrong_mime')));
                }

                return errors;
            }

            return {
                fileInput,
                file,
                handleFile,
            };
        },
    });
</script>

<style scoped>
    .CFileInput__input {
        @apply tw-hidden;
    }

    .CFileInput__button {
        @apply tw-w-full;
    }
</style>
