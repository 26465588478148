import {Model} from '@meekohq/lumos';
import RegistrationModel from '@/modules/registration/models/RegistrationModel';

export default class RegistrationPlanning extends Model {
    public type = 'registration/plannings';

    public attributes: {
        id: string;
        account_id: string | undefined;
        registration_id: string | undefined;
        week: string | undefined;
        day: string | undefined;
        time_period: {
            started_at: string | undefined;
            ended_at: string | undefined;
        };
        note: string | null;
    } = {
        id: this.uuid(),
        account_id: undefined,
        registration_id: undefined,
        week: undefined,
        day: undefined,
        time_period: {
            started_at: undefined,
            ended_at: undefined,
        },
        note: null,
    };

    registration() {
        return this.belongsTo('registration', RegistrationModel, 'registration_id');
    }
}
