import moment from 'moment';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import {computed} from 'vue';
import useMoment from '@/modules/app/composables/useMoment';

export default function (staff: StaffModel, constraint?: any) {
    let contractRelation = staff.contracts();
    const {formatDate} = useMoment();

    // If constraint, attribute to contractRelation an other contract's relation with constraint.
    if (constraint) {
        contractRelation = staff.contracts().setQuery(constraint);
    }

    // Return a sorted starting dates collection of every contracts.
    const contractsByStartingDates = computed(() => {
        const startedDates = contractRelation.value().map(contract => contract.attributes.started_at);

        return startedDates.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
    });

    // Return a sorted ending or broken dates collection of every contracts.
    const contractsByEndedDates = computed(() => {
        const endedDates = contractRelation.value().map(contract => {
            if (contract.attributes.broked_at) {
                return contract.attributes.broked_at;
            } else {
                // If 'ended_at' is null return undefined for the sort method.
                return contract.attributes.ended_at || undefined;
            }
        });

        // Return a sorted collection of ended or broken date.
        // If a value is undefined, the sort methode will move it to the last position in colection.
        // This behaviour permit you to handle if a staff have at least one permanent contract.
        return endedDates.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
    });

    const getFirstDate = function () {
        return contractsByStartingDates.value.first();
    };

    const getLastDate = function (format = false) {
        if (format) {
            return contractsByEndedDates.value.map(item => formatDate(item)).last();
        }

        // Get the last value in collection.
        // If the value is undefined, the staff have a permanent contract.
        return contractsByEndedDates.value.last();
    };

    return {
        getFirstDate,
        getLastDate,
    };
}
