<template>
    <apexcharts
        v-if="chartOptions && series"
        ref="barslim"
        :options="chartOptions"
        :series="series"
        type="bar"
    />
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    import {EpochInfo} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        components: {
            apexcharts: VueApexCharts,
        },
        props: {
            chartData: {
                type: Object,
            },
            nursery: {
                type: Object,
            },
        },
        data: () => ({
            chartOptions: null,
        }),
        mounted() {
            this.updateChart();

            setTimeout(() => {
                this.toggleBars();
            }, 200);
        },
        methods: {
            updateChart() {
                const self = this;
                self.series = self.chartData.series;
                self.chartOptions = {
                    chart: {
                        stacked: self.chartData.stacked,
                        defaultLocale: 'fr',
                        locales: [
                            {
                                name: 'fr',
                                options: {
                                    months: EpochInfo.months(),
                                    shortMonths: EpochInfo.months('short'),
                                    days: EpochInfo.weekdays(),
                                    shortDays: EpochInfo.weekdays('short'),
                                    toolbar: {
                                        exportToSVG: __('common:exports.export_to_svg'),
                                        exportToPNG: __('common:exports.export_to_png'),
                                        exportToCSV: __('common:exports.export_to_csv'),
                                        download: __('common:actions.download'),
                                        selection: __('common:selection'),
                                        selectionZoom: __('common:chart.zoom.selection'),
                                        zoomIn: __('common:chart.zoom.in'),
                                        zoomOut: __('common:chart.zoom.out'),
                                        pan: __('common:chart.pan'),
                                        reset: __('common:chart.zoom.reset'),
                                    },
                                },
                            },
                        ],
                        toolbar: {
                            show: true,
                            tools: {
                                download: true,
                            },
                            export: {
                                csv: {
                                    filename: self.chartData.name,
                                    columnDelimiter: ',',
                                    headerCategory: ' ',
                                    headerValue: ' ',
                                },
                                svg: {
                                    filename: self.chartData.name,
                                },
                                png: {
                                    filename: self.chartData.name,
                                },
                            },
                        },
                    },
                    plotOptions: {
                        bar: {
                            endingShape: self.chartData.stacked ? 'flat' : 'rounded',
                        },
                    },
                    stroke: {
                        show: true,
                        width: 3,
                        colors: ['transparent'],
                    },
                    colors: self.chartData.colors,
                    xaxis: {
                        categories: self.chartData.categories,
                        labels: {
                            style: {
                                colors: '#888',
                                fontFamily: 'Outfit',
                            },
                        },
                        crosshairs: {
                            show: true,
                            width: 'tickWidth',
                        },
                    },
                    yaxis: {
                        tickAmount: 4,
                        labels: {
                            style: {
                                color: '#888',
                                fontFamily: 'Outfit',
                            },
                        },
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'left',
                        fontSize: '12px',
                        labels: {
                            color: '#777',
                        },
                        offsetY: 5,
                        offsetX: 20,
                    },
                    dataLabels: {
                        enabled: false,
                        formatter(value) {
                            return self.formatValue(value);
                        },
                        style: {
                            fontSize: '14px',
                            colors: ['#FFF'],
                        },
                        dropShadow: {
                            enabled: true,
                            top: 1,
                            left: 1,
                            blur: 1,
                            opacity: 0.5,
                        },
                    },
                    tooltip: {
                        shared: true,
                        followCursor: true,
                        intersect: false,
                        y: {
                            formatter(value) {
                                return self.formatValue(value);
                            },
                        },
                        x: {
                            formatter(value, point) {
                                // Sum x axis values to display total for hovered bar
                                const sum = (r, a) => r.map((b, i) => a[i] + b);
                                const seriesData = self.chartData.series.map(serie => serie.data);
                                const total = seriesData.reduce(sum);

                                return `${value} - ${self.formatValue(total[point.dataPointIndex].toFixed(2))}`;
                            },
                        },
                    },
                    grid: {
                        borderColor: '#EEE',
                    },
                };
            },

            toggleBars() {
                this.series.forEach(item => {
                    item.hidden && this.$refs.barslim ? this.$refs.barslim.toggleSeries(item.name) : null;
                });
                this.$refs.barslim ? this.$refs.barslim.resetSeries() : null;
            },

            formatValue(value) {
                if (value === null || typeof value === 'undefined') {
                    return '';
                }
                const dataType =
                    this.chartData.type && typeof this.chartData.type !== 'undefined'
                        ? this.chartData.type
                        : this.nursery.currency;

                return value + ' ' + dataType;
            },
        },
    };
</script>

<style>
    .apexcharts-legend-text {
        color: #777 !important;
        font-size: 12px !important;
    }
</style>
