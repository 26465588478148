import type {CreditNoteAllocationAggregatePort} from '@/modules/cashier/payment/application/ports/CreditNoteAllocationAggregatePort';
import {type ModelCollection} from '@meekohq/lumos';
import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import {SharedRemaingAmount} from '@/modules/cashier/payment/domain/SharedRemaingAmount';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import {TransactionDebitAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';
import {InvoiceAllocationAggregate} from '@/modules/cashier/payment/domain/InvoiceAllocationAggregate';

export class MQLCreditNoteAllocationAggregateRepository implements CreditNoteAllocationAggregatePort {
    public async getAllocations(
        creditNoteId: string,
        payments: ModelCollection<PaymentModel>
    ): Promise<(InvoiceAllocationAggregate | TransactionDebitAllocationAggregate)[]> {
        const creditNote = await InvoiceModel.find(creditNoteId);
        const sharedRemainingAmount = new SharedRemaingAmount(
            creditNote.remaingAmount,
            payments.first().computed.currency_iso_code
        );

        const result = await Promise.all(
            payments.map(async payment => {
                const transactionsPromise = this.getTransactionsByPayment(payment);
                const paymentPromise = this.getPaymentByPaymentAndCreditNote(payment, creditNoteId);

                return this.loadAndMergeAllocations(
                    payment,
                    creditNote,
                    transactionsPromise,
                    paymentPromise,
                    sharedRemainingAmount
                );
            })
        );

        return result.reduce((acc, val) => acc.concat(val), []);
    }

    public async getTransactionSuggestions(payment: PaymentModel): Promise<TransactionDebitAllocationAggregate[]> {
        // Get the credit transactions that have remaining amount to distribute
        const transactionsPromise = TransactionModel.query()
            .where('type', 'debit')
            .where('customer_id', payment.attributes.customer_id)
            .where('remaining_to_distribute_amount', '>', 0)
            .where('status', '!=', 'failed')
            .with(new TransactionModel().allocationsAsDestination(), query => {
                query.where('destination_id', payment.getKey());
            })
            .with(new TransactionModel().paymentMethod())
            .get();

        const transactions = await transactionsPromise;
        const sharedRemainingAmount = new SharedRemaingAmount(
            payment.computed.remaining_amount,
            payment.computed.currency_iso_code
        );

        return transactions
            .map<AbstractAllocationAggregate>(transaction => {
                return new TransactionDebitAllocationAggregate(
                    payment,
                    transaction,
                    sharedRemainingAmount,
                    transaction.allocationsAsDestination().value()?.first()
                );
            })
            .toArray();
    }

    private getTransactionsByPayment(payment: PaymentModel): Promise<ModelCollection<TransactionModel>> {
        return TransactionModel.query()
            .where('type', 'debit')
            .whereHas(new TransactionModel().allocationsAsDestination(), query => {
                query.where('source_id', payment.getKey());
            })
            .with(new TransactionModel().allocationsAsDestination(), query1 => {
                query1.where('source_id', payment.getKey());
                query1.with(new AllocationModel().source(), query2 => {
                    query2.with(new PaymentModel().creditNotes(), query3 => {
                        query3.with(new InvoiceModel().refunds());
                    });
                });
            })
            .with(new TransactionModel().paymentMethod())
            .get();
    }

    private getPaymentByPaymentAndCreditNote(
        payment: PaymentModel,
        creditNoteId: string
    ): Promise<ModelCollection<PaymentModel>> {
        return PaymentModel.query()
            .where('refund', false)
            .where('id', payment.getKey())
            .whereHas(new PaymentModel().allocationsAsDestination(), query => {
                query.where('source_id', creditNoteId);
            })
            .with(new PaymentModel().allocationsAsDestination(), query => {
                query.where('source_id', creditNoteId);
            })
            .with(new PaymentModel().invoices())
            .get();
    }

    private async loadAndMergeAllocations(
        payment: PaymentModel,
        creditNote: InvoiceModel,
        transactionsPromises: Promise<ModelCollection<TransactionModel>>,
        paymentsPromises: Promise<ModelCollection<PaymentModel>>,
        sharedRemainingAmount: SharedRemaingAmount
    ): Promise<(InvoiceAllocationAggregate | TransactionDebitAllocationAggregate)[]> {
        const [transactions, payments] = await Promise.all([transactionsPromises, paymentsPromises]);

        // Convert the transactions and credit notes to SourceAllocationAggregate
        return transactions
            .map<AbstractAllocationAggregate>(transaction => {
                return new TransactionDebitAllocationAggregate(
                    payment, // Refund
                    transaction,
                    sharedRemainingAmount,
                    transaction.allocationsAsDestination().value()?.first()
                );
            })
            .merge<AbstractAllocationAggregate>(
                payments.all().map(paymentWithRelation => {
                    return new InvoiceAllocationAggregate(
                        creditNote,
                        paymentWithRelation,
                        sharedRemainingAmount,
                        paymentWithRelation.allocationsAsDestination().value()?.first()
                    );
                })
            )
            .toArray();
    }
}
