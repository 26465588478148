import type {LengthAwarePaginator} from '@meekohq/lumos';
import {Binding} from '@meekohq/lumos';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import type {TransactionFilterDto} from '@/modules/cashier/transaction/application/dto/TransactionFilterDto';

export const PaginateTransactionsUseCaseBinding = new Binding<PaginateTransactionsUseCase>();

export interface PaginateTransactionsUseCase {
    paginateByFilters(filters: TransactionFilterDto): Promise<LengthAwarePaginator<TransactionModel>>;
}
