<template>
    <CCard
        v-if="customerModel"
        body-size="xs"
        :border="false"
        :shadow="true"
    >
        <template #header>
            <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between">
                <CCardTitle>
                    <FontAwesomeIcon
                        class="tw-mr-2 tw-text-blue-500"
                        icon="fa-duotone fa-envelope-open-text"
                    />{{ __('billing_core:send_documents_to') }}
                </CCardTitle>
            </div>
        </template>
        <NotificationsList
            ref="customerNotification"
            class="tw-max-h-48 tw-overflow-y-auto"
            :customer-model="customerModel"
        />
    </CCard>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import NotificationsList from '@/modules/cashier/components/core/notification/organisms/NotificationsList.vue';

    defineProps<{customerModel: CustomerModel}>();
    defineExpose({refresh});

    const customerNotification = ref<{fetch: () => void}>();

    function refresh() {
        customerNotification.value?.fetch();
    }
</script>
