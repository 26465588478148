<template>
    <CForm>
        <CFormGroup>
            <CLabel>{{ __('common:name') }}*</CLabel>
            <CInput
                v-model="document.attributes.name"
                autofocus
                :has-error="errorForm.has('name')"
                :placeholder="__('document:document_name')"
                type="text"
            />
            <CFormErrorMessageList :errors="errorForm.get('name')" />
        </CFormGroup>
        <CFormGroup v-if="displayVisibilitiesSection">
            <CLabel>
                {{ __('document:visibility_colon') }}
            </CLabel>
            <div
                v-if="displayedVisibilities.staffs_access"
                class="DocumentForm__container"
            >
                <CCheckbox v-model="visibilities.staffs_access">
                    {{ __('common:for_team') }}
                </CCheckbox>
            </div>
            <div
                v-if="displayedVisibilities.parents_access"
                class="DocumentForm__container"
            >
                <CCheckbox v-model="visibilities.parents_access">
                    {{ __('common:for_parents') }}
                </CCheckbox>
            </div>
            <div
                v-if="displayedVisibilities.web_access"
                class="DocumentForm__container"
            >
                <CCheckbox v-model="visibilities.web_access">
                    {{ __('document:meeko_website') }}
                </CCheckbox>
            </div>
        </CFormGroup>
        <CFormGroup v-if="showOrganizations && availableOrganizations.length > 1">
            <CLabel>
                {{ __('common:for_organizations_colon') }}
            </CLabel>
            <OrganizationFinder
                v-model="selectedOrganizations"
                :inject-query="organizationFinderQuery"
                multi
                multi-minimum="1"
            />
        </CFormGroup>
        <div class="DocumentForm__file-selector-container">
            <CFormGroup>
                <CLabel>{{ __('document:type_colon') }}</CLabel>
                <CButtonGroup>
                    <MButton
                        :variant="typeOfDocument === 'file' ? 'primary' : 'light'"
                        @click="swapDocumentType('file')"
                    >
                        {{ __('document:file') }}
                    </MButton>
                    <MButton
                        :variant="typeOfDocument === 'link' ? 'primary' : 'light'"
                        @click="swapDocumentType('link')"
                    >
                        {{ __('document:link') }}
                    </MButton>
                </CButtonGroup>
            </CFormGroup>
            <CFormGroup class="tw-flex-1">
                <template v-if="typeOfDocument === 'link'">
                    <CLabel>{{ __('document:file_link') }}*</CLabel>
                    <CInput
                        v-model="document.attributes.link"
                        placeholder="https://www.meeko.pro/monfichier.pdf"
                    />
                    <CFormErrorMessageList
                        v-if="errorForm.has('link')"
                        :errors="errorForm.get('link')"
                    />
                    <CFormErrorMessageList
                        v-if="errorForm.has('filename')"
                        :errors="errorForm.get('filename')"
                    />
                </template>
                <template v-else>
                    <CLabel>{{ __('document:select_new_file') }}*</CLabel>
                    <CFileInput
                        :max-size="104857600"
                        @error="fileInputErrors = $event"
                        @input="selectFile"
                    />
                    <CFormErrorMessageList
                        v-if="errorForm.has('filename')"
                        :errors="errorForm.get('filename')"
                    />
                    <CVStack
                        v-if="fileInputErrors.length"
                        class="CFormErrorMessageList"
                        gap="2"
                    >
                        <CFormErrorMessage
                            v-for="(error, key) in fileInputErrors"
                            :key="key"
                        >
                            {{ error.message }}
                        </CFormErrorMessage>
                    </CVStack>
                </template>
            </CFormGroup>
        </div>
    </CForm>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import {type Collection} from '@meekohq/lumos';
    import type {VisibilitiesType} from '@/modules/document/stores/useDocumentStore';
    import type DocumentModel from '@/modules/document/models/DocumentModel';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import type useError from '@/modules/app/composables/useError';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';

    interface StoreType {
        activeOrganization: Ref<OrganizationModel | undefined>;
        document: Ref<DocumentModel>;
        file: Ref<File | undefined>;
        fileInputErrors: Ref<Error[]>;
        availableOrganizations: Ref<Collection<OrganizationModel>>;
        selectedOrganizations: Ref<OrganizationModel[]>;
        visibilities: Ref<VisibilitiesType>;
    }

    export default defineComponent({
        components: {OrganizationFinder},
        props: {
            showOrganizations: {
                type: Boolean,
                default: false,
            },
            displayedVisibilities: {
                type: Object as PropType<VisibilitiesType>,
                required: false,
                default: () => ({
                    staffs_access: false,
                    parents_access: false,
                    web_access: false,
                }),
            },
            store: {
                type: Object as PropType<StoreType>,
                required: true,
            },
            errorForm: {type: Object as PropType<ReturnType<typeof useError>>, required: true},
        },
        setup(props) {
            const {
                activeOrganization,
                document,
                file,
                fileInputErrors,
                availableOrganizations,
                selectedOrganizations,
                visibilities,
            } = props.store as StoreType;

            const typeOfDocument = ref(document.value.attributes.link ? 'link' : 'file');

            const organizationFinderQuery = computed(() => {
                return OrganizationModel.query().whereIn(
                    'id',
                    availableOrganizations.value.map(organization => organization.getKey())
                );
            });

            const displayVisibilitiesSection = computed(() => {
                return (
                    props.displayedVisibilities?.parents_access ||
                    props.displayedVisibilities?.staffs_access ||
                    props.displayedVisibilities?.web_access
                );
            });

            // Swap from document to link or the other way around
            function swapDocumentType(newType) {
                // Set the new type
                typeOfDocument.value = newType;

                // Reset the file attribute when the document type changes to link
                if (newType === 'link') {
                    file.value = undefined;
                } else {
                    document.value.attributes.link = undefined;
                }
            }

            // Select a new file
            function selectFile(newFile: File) {
                // Set the new file
                file.value = newFile;

                // Reset the errors when the file is updated
                fileInputErrors.value = [];
            }

            return {
                activeOrganization,
                document,
                file,
                fileInputErrors,
                visibilities,
                availableOrganizations,
                organizationFinderQuery,
                selectedOrganizations,
                displayVisibilitiesSection,
                selectFile,
                swapDocumentType,
                typeOfDocument,
            };
        },
    });
</script>

<style scoped>
    .DocumentForm__container {
        @apply tw-flex tw-items-center tw-gap-1;
    }

    .DocumentForm__file-selector-container {
        @apply tw-flex tw-flex-col tw-gap-4 md:tw-flex-row;
    }
</style>
