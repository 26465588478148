<template>
    <CForm>
        <CFormGroup>
            <CLabel>
                {{ __('health:vaccination_date') }}
            </CLabel>
            <CFormDatepicker v-model="action.attributes.done_at" />
            <CFormErrorMessageList
                v-if="errors.hasError('done_at')"
                :errors="errors.getErrors('done_at')"
            />
        </CFormGroup>
        <CFormGroup>
            <CLabel>
                {{ __('health:vaccination_expected_date') }}
            </CLabel>
            <CFormDatepicker v-model="action.attributes.scheduled_at" />
            <CFormErrorMessageList
                v-if="errors.hasError('scheduled_at')"
                :errors="errors.getErrors('scheduled_at')"
            />
        </CFormGroup>
        <CFormGroup v-if="!action.attributes.done_at && !action.attributes.task_created_at">
            <CLabel>
                {{ __('health:notify') }}
            </CLabel>
            <medical-action-reminder-selector
                :scheduled-at="action.attributes.scheduled_at"
                :task-scheduled-at="action.attributes.task_scheduled_at"
                @changed="action.attributes.task_scheduled_at = $event"
            />
        </CFormGroup>
        <CFormGroup>
            <CLabel>
                {{ __('common:note_one') }}
            </CLabel>
            <CFormTextArea v-model="action.attributes.note" />
            <CFormErrorMessageList
                v-if="errors.hasError('note')"
                :errors="errors.getErrors('note')"
            />
        </CFormGroup>
        <CHStack :distribute="action.exists ? 'between' : 'end'">
            <MButton
                v-if="action.exists"
                :label="__('common:actions.delete')"
                :loading="isDeleting"
                variant="danger"
                @click="deleteAction"
            />
            <CHStack
                distribute="end"
                gap="2"
            >
                <MButton
                    :label="__('common:actions.cancel')"
                    @click="$emit('canceled')"
                />
                <MButton
                    :label="__('common:actions.save')"
                    :loading="isSaving"
                    variant="primary"
                    @click="save"
                />
            </CHStack>
        </CHStack>
    </CForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import type MedicalActionModel from '@/modules/health/models/MedicalActionModel';
    import MedicalActionReminderSelector from '@/modules/health/components/MedicalActionReminderSelector.vue';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import {catcher, ValidationError} from '@meekohq/lumos';
    import useAuth from '@/modules/app/composables/useAuth';

    export default defineComponent({
        components: {
            MedicalActionReminderSelector,
        },
        props: {
            action: {
                type: Object as PropType<MedicalActionModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {user} = useAuth();

            const isSaving = ref(false);
            const isDeleting = ref(false);

            const errors = ref(new ErrorHandler());

            async function save() {
                isSaving.value = true;

                // if the shot related vaccine doesn't exist yet, we save the vaccine first
                if (!props.action.medicalTreatment().value().exists) {
                    await props.action.medicalTreatment().value().save();
                }

                await props.action
                    .save()
                    .then(() => {
                        errors.value.reset();

                        emit('saved', props.action);
                    })
                    .catch(error => {
                        catcher()
                            .on(ValidationError, e => errors.value.reset(e))
                            .catch(error);
                    })
                    .finally(() => {
                        isSaving.value = false;
                    });
            }

            async function deleteAction() {
                isDeleting.value = true;
                await props.action.delete();
                isDeleting.value = false;
                emit('deleted');
            }

            watch(
                () => props.action.attributes.done_at,
                value => {
                    // Disable task creation if action is done and the related task has not been already created
                    if (value && !props.action.attributes.task_created_at) {
                        props.action.attributes.task_scheduled_at = null;
                    }
                }
            );

            return {
                user,
                isSaving,
                save,
                isDeleting,
                deleteAction,
                errors,
            };
        },
    });
</script>
