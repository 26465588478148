<template>
    <div>
        <CHStack
            class="tw-py-2"
            :class="{'tw-opacity-50': !isStaffInFilter}"
        >
            <div class="tw-grid tw-flex-1 tw-grid-cols-12 tw-gap-4">
                <div class="tw-col-span-2">
                    <CText>
                        {{ staffModel.attributes.first_name }}
                        {{ staffModel.attributes.last_name }}
                    </CText>
                </div>
                <div class="tw-col-span-1 tw-place-self-center">
                    <FontAwesomeIcon
                        v-if="staffModel.attributes.gender === 'male'"
                        class="tw-text-2xl"
                        icon="fa-solid fa-mars"
                    />
                    <FontAwesomeIcon
                        v-else-if="staffModel.attributes.gender === 'female'"
                        class="tw-text-2xl"
                        icon="fa-solid fa-venus"
                    />
                    <span v-else> - </span>
                </div>
                <div class="tw-col-span-2 tw-place-self-center">
                    <CText>
                        {{ staffModel.attributes.birthdate || '-' }}
                    </CText>
                </div>
                <div class="tw-col-span-2 tw-place-self-center">
                    <CText>
                        {{ staffModel.attributes.nationality || '-' }}
                    </CText>
                </div>
                <div class="tw-col-span-1 tw-place-self-center">
                    <CText class="tw-truncate">
                        {{ staffModel.extra.firstContract }}
                    </CText>
                </div>
                <div class="tw-col-span-1 tw-place-self-center">
                    <CText class="tw-truncate">
                        {{ getLastDate(true) || '-' }}
                    </CText>
                </div>
                <div class="tw-col-span-2 tw-flex tw-justify-center">
                    <CText class="tw-place-self-center tw-truncate">
                        {{ staffModel.attributes.note || '-' }}
                    </CText>
                </div>
                <div class="tw-col-span-1 tw-items-start tw-place-self-center">
                    <MButton
                        size="sm"
                        variant="link"
                        @click="showContracts = !showContracts"
                    >
                        {{ __('common:details') }}
                        <FontAwesomeIcon
                            class="chevron"
                            :icon="showContracts ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-right'"
                        />
                    </MButton>
                </div>
            </div>
        </CHStack>
        <StaffRegisterContractList
            v-if="showContracts"
            ref="contractList"
            :selected-organizations-ids="selectedOrganizationsIds"
            :staff="staffModel"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import StaffRegisterContractList from '@/modules/human-resources/components/registry/List/StaffRegisterContractList.vue';
    import useContracts from '@/modules/human-resources/composables/staff-register/useContracts';

    export default defineComponent({
        components: {StaffRegisterContractList},
        props: {
            staffModel: {type: Object as PropType<StaffModel>, required: true},
            selectedOrganizationsIds: {required: true, type: Array as PropType<(string | number)[]>},
        },
        setup(props) {
            const showContracts = ref(false);
            const organizationsPivots = ref(props.staffModel.organizationsPivots().value());
            //  Get the last date for the whole contracts of a staff.
            const {getLastDate} = useContracts(props.staffModel);

            // Check if staff is in organizations selected in filter.
            const isStaffInFilter = computed(() => {
                return organizationsPivots.value
                    .whereIn('attributes.organization_id', props.selectedOrganizationsIds)
                    .isNotEmpty();
            });

            return {
                showContracts,
                isStaffInFilter,
                getLastDate,
            };
        },
    });
</script>

<style scoped>
    .chevron {
        @apply tw-pl-1 tw-text-base tw-opacity-50 tw-transition group-hover:tw-opacity-75;
    }
</style>
