<script setup lang="ts">
    import {ref} from 'vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import {EventBus} from '@/modules/legacy/utils/bus';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import RequestModel from '@/modules/request/models/RequestModel';
    import RequestStageModel from '@/modules/request/models/RequestStageModel';
    import SegmentFullDayListItem from '@/modules/human-resources/components/calendar/SegmentFullDayListItem.vue';
    import SegmentRequestMenu from '@/modules/human-resources/components/calendar/SegmentRequestMenu.vue';

    withDefaults(
        defineProps<{
            events: EventModel[];
            size: 'sm' | 'md' | 'lg';
        }>(),
        {
            size: 'lg',
        }
    );

    const {can} = useAbility();
    const segmentMenus = ref();
    const isLoadingEventRequest = ref(false);

    async function onSegmentMenusShown(event: EventModel) {
        // If the event has an associated request and is pending, we need to load the request if not already loaded
        if (can('update', 'hr_request') && event.isPending && event.attributes.request_id && !event.request().value()) {
            isLoadingEventRequest.value = true;

            const request = await RequestModel.query()
                .with(new RequestModel().requestType())
                .with(new RequestModel().source())
                .with(new RequestModel().requestStages(), query => {
                    query.with(new RequestStageModel().requestTypeStage());
                })
                .find(event.attributes.request_id);

            event.request().set(request);

            isLoadingEventRequest.value = false;
        }
    }

    function openEventRequestPopover(event: EventModel) {
        if (can('update', 'staffs_planning') && can('update', 'hr_request')) {
            onSegmentMenusShown(event);
        }
    }

    function openUpdateEventModal(event: EventModel) {
        if (can('update', 'staffs_planning') && !event.request().value()) {
            EventBus.$emit('calendar:staff:update:event', event);
        }
    }

    async function onRequestStageSaved(event: EventModel) {
        await event.fresh();
    }
</script>

<template>
    <div class="SegmentFullDayList">
        <div
            v-for="event in events"
            :key="'staffSegmentFullDayDetails' + event.id"
        >
            <template v-if="event.isPending">
                <MPopover
                    ref="segmentMenus"
                    placement="right"
                    :portal="false"
                    @click.native="openEventRequestPopover(event)"
                >
                    <SegmentFullDayListItem
                        :event="event"
                        :size="size"
                    />
                    <template #content>
                        <Loader
                            v-if="isLoadingEventRequest"
                            size="sm"
                        />
                        <SegmentRequestMenu
                            v-else-if="event.request().value()"
                            :request="event.request().value()"
                            @stageSaved="onRequestStageSaved(event)"
                        />
                    </template>
                </MPopover>
            </template>
            <template v-else>
                <SegmentFullDayListItem
                    :event="event"
                    :size="size"
                    @click.native="openUpdateEventModal(event)"
                />
            </template>
        </div>
    </div>
</template>

<style scoped>
    .SegmentFullDayList {
        @apply tw-flex tw-flex-col tw-gap-2;
    }
</style>
