<template>
    <div class="KidsListHeader">
        <div class="KidsListHeader__container">
            <div :class="showGroup ? 'KidsListHeader__column-name' : 'KidsListHeader__column-name--expanded'">
                <MagicIndexHeaderColumnTitle :title="__('common:name')" />
            </div>
            <div :class="showGroup ? 'KidsListHeader__column-group' : 'KidsListHeader__column-group--hidden'">
                <MagicIndexHeaderColumnTitle :title="__('common:group_one')" />
            </div>
            <div class="KidsListHeader__column-age">
                <MagicIndexHeaderColumnTitle :title="__('common:age')" />
            </div>
            <div class="KidsListHeader__column-contract">
                <MagicIndexHeaderColumnTitle :title="__('common:start_contract')" />
            </div>
            <div class="KidsListHeader__column-contract">
                <MagicIndexHeaderColumnTitle :title="__('common:end_contract')" />
            </div>
            <div class="KidsListHeader__column-tags">
                <MagicIndexHeaderColumnTitle :title="__('common:tags')" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import MagicIndexHeaderColumnTitle from '@/modules/magic-index/components/atoms/MagicIndexHeaderColumnTitle.vue';

    export default defineComponent({
        components: {
            MagicIndexHeaderColumnTitle,
        },
        props: {
            showGroup: {
                type: Boolean,
                required: true,
            },
        },
    });
</script>

<style scoped>
    .KidsListHeader {
        container-name: KidsListHeader;
        container-type: inline-size;
    }

    .KidsListHeader__container {
        @apply tw-hidden;

        @container KidsListHeader (min-width: 48rem) {
            @apply tw-grid tw-grid-cols-24 tw-gap-3;
        }
    }

    .KidsListHeader__column-name {
        @apply tw-col-span-7;

        @container KidsListHeader (min-width: 64rem) {
            @apply tw-col-span-8;
        }
    }

    .KidsListHeader__column-name--expanded {
        @apply tw-col-span-10;

        @container KidsListHeader (min-width: 64rem) {
            @apply tw-col-span-11;
        }
    }

    .KidsListHeader__column-group {
        @apply tw-col-span-3;
    }

    .KidsListHeader__column-group--hidden {
        @apply tw-hidden;
    }

    .KidsListHeader__column-age {
        @apply tw-col-span-3;
    }

    .KidsListHeader__column-contract {
        @apply tw-col-span-3;
    }

    .KidsListHeader__column-tags {
        @apply tw-col-span-4;

        @container KidsListHeader (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }
</style>
