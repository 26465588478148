<template>
    <div class="EditInvoiceDetailsEditorLine">
        <div class="EditInvoiceDetailsEditorLine__item-type">
            <label>
                {{ __('common:type') }}
            </label>
            <invoiceType
                v-model="line.type"
                editable
                @manualChange="line.unit = null"
            />
        </div>
        <div class="EditInvoiceDetailsEditorLine__item-description">
            <label>
                {{ __('common:description') }}
            </label>
            <CFormTextArea
                v-model="line.name"
                :placeholder="__('billing_invoice:package_dots')"
                rows="2"
            />
        </div>
        <div
            v-if="withTva"
            class="EditInvoiceDetailsEditorLine__item EditInvoiceDetailsEditorLine__item-tva"
        >
            <label>
                {{ __('common:vat') }}
            </label>
            <CInputGroup>
                <CInput
                    v-model.number="line.tva"
                    class="!tw-w-32 md:!tw-w-12"
                    placeholder="20"
                />
                <CInputAddon class="!tw-w-8">
                    <FontAwesomeIcon icon="fa-solid fa-percent" />
                </CInputAddon>
            </CInputGroup>
        </div>
        <div class="EditInvoiceDetailsEditorLine__item EditInvoiceDetailsEditorLine__item-price">
            <label>
                {{ __('billing_invoice:price_with_vat') }}
            </label>
            <MCurrencyInput
                v-model="line.price"
                class="tw-w-40 tw-text-right md:!tw-w-28"
                :currency-code="currency"
                :parser-options="lineParserOptions"
            />
        </div>
        <div class="EditInvoiceDetailsEditorLine__item EditInvoiceDetailsEditorLine__item-quantity">
            <label>
                {{ __('billing_invoice:quantity') }}
            </label>
            <CButtonGroup>
                <MNumberInput
                    v-model="line.qty"
                    class="!tw-w-24 tw-text-right"
                    :currency-code="currency"
                    :parser-options="lineParserOptions"
                />
                <CFormSelect
                    v-model="line.unit"
                    button-class="!tw-w-16"
                    :options="quantityUnitFilters"
                    :search-bar="false"
                    text-path="text"
                    :truncate="false"
                    value-path="value"
                />
            </CButtonGroup>
        </div>
        <div class="EditInvoiceDetailsEditorLine__item EditInvoiceDetailsEditorLine__item-total">
            <label>
                {{ __('billing_invoice:total_with_vat') }}
            </label>
            {{ format(getLineTotal(line.qty, line.price)) }}
        </div>
        <div
            class="EditInvoiceDetailsEditorLine__delete"
            @click="$emit('removeLine')"
        >
            <FontAwesomeIcon
                class="tw-text-red-500"
                icon="fa-solid fa-xmark"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import InvoiceType from '@/modules/cashier/components/billing/invoice/InvoiceType.vue';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type {NumericParserContractOptions} from '@/modules/core/infrastructure/NumericParserContract';
    import i18next from 'i18next';
    import {getNumericParserContractLocaleOptions} from '@/modules/core/infrastructure/NumericParserContractPresets';

    export default defineComponent({
        components: {InvoiceType},
        mixins: [invoice],
        props: {
            line: {
                type: Object,
                required: true,
            },
            withTva: {
                type: Boolean,
                required: true,
            },
            grandTotal: {
                type: String,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            currency: {
                type: String,
                default: undefined,
            },
        },
        setup(props) {
            const {format} = useFormatCurrency(props.currency);

            const quantityUnit = ref([
                {
                    value: 'hour',
                    text: 'H.',
                },
                {
                    value: 'day',
                    text: 'J.',
                },
                {
                    value: 'halfday',
                    text: 'D.J.',
                },
                {
                    value: 'pack',
                    text: 'Forf.',
                },
                {
                    value: 'unit',
                    text: 'Unit.',
                },
            ]);

            const lineParserOptions: NumericParserContractOptions = {
                localeOptions: getNumericParserContractLocaleOptions(i18next.language),
                fractionalOptions: {
                    mode: 'round',
                    minFractionalDigits: 2,
                    maxFractionalDigits: 3,
                },
                allowNegative: true,
            };

            const quantityUnitFilters = computed(() => {
                return quantityUnit.value.filter(unit => {
                    return showUnit(props.line.type, unit.value);
                });
            });

            watch(
                quantityUnitFilters,
                () => {
                    const quantityUnitIsInFilter = quantityUnitFilters.value.find(
                        unit => unit.value === props.line.unit
                    );

                    if (quantityUnitIsInFilter) {
                        return;
                    }
                    props.line.unit = quantityUnitFilters.value[0].value;
                },
                {deep: true, immediate: true}
            );

            function getLineTotal(lineQty, linePrice) {
                const sign = lineQty * linePrice >= 0 ? 1 : -1;
                const absoluteTotal = lineQty * linePrice * sign;

                return absoluteTotal * sign;
            }

            function showUnit(lineType, unit) {
                switch (unit) {
                    case 'hour':
                        return !(lineType === 'meals' || lineType === 'health');
                    case 'day':
                        return !(lineType === 'overrun');
                    case 'halfday':
                        return !(lineType === 'overrun');
                    case 'pack':
                        return lineType === 'recurrent' || lineType === 'cmg';
                    case 'unit':
                        return lineType === 'custom' || lineType === 'deposit' || lineType === 'registration';
                }
            }

            return {
                quantityUnit,
                quantityUnitFilters,
                lineParserOptions,
                getLineTotal,
                showUnit,
                format,
            };
        },
    });
</script>

<style scoped>
    .EditInvoiceDetailsEditorLine {
        @apply tw-relative tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-2 md:tw-flex-nowrap;
        @apply odd:tw-bg-blue-50 even:tw-bg-blue-50 md:even:tw-bg-transparent;
        @apply tw-rounded-lg;
        @apply tw-p-2;
    }

    .EditInvoiceDetailsEditorLine__item {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between;

        label {
            @apply tw-block md:tw-hidden;
            @apply tw-font-display tw-text-gray-500;
            @apply tw-shrink-0;
            @apply tw-w-20;
        }
    }

    .EditInvoiceDetailsEditorLine__item-type {
        @apply tw-w-full md:tw-w-12;
        @apply tw-flex tw-flex-row tw-items-center md:tw-justify-between;

        label {
            @apply tw-block md:tw-hidden;
            @apply tw-font-display tw-text-gray-500;
            @apply tw-shrink-0;
            @apply tw-w-auto md:tw-w-24;
            @apply tw-pr-4;
        }
    }

    .EditInvoiceDetailsEditorLine__item-description {
        @apply tw-flex tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-between;
        @apply tw-w-full md:tw-w-24 md:tw-flex-1;

        label {
            @apply tw-block md:tw-hidden;
            @apply tw-font-display tw-text-gray-500;
            @apply tw-shrink-0;
            @apply tw-pb-1;
        }
    }

    .EditInvoiceDetailsEditorLine__item-tva {
        @apply tw-w-full md:tw-w-20;
    }

    .EditInvoiceDetailsEditorLine__item-price {
        @apply tw-w-full md:tw-w-28;
    }

    .EditInvoiceDetailsEditorLine__item-quantity {
        @apply tw-w-full md:tw-w-40;
    }

    .EditInvoiceDetailsEditorLine__quantity-input-group {
        @apply tw-w-full sm:tw-w-40;
    }

    .EditInvoiceDetailsEditorLine__item-total {
        @apply md:tw-justify-end;
        @apply tw-text-gray-800;
        @apply tw-w-full md:tw-w-24;
    }

    .EditInvoiceDetailsEditorLine__delete {
        @apply tw-absolute tw-m-auto hover:tw-cursor-pointer;
        @apply tw--right-2 tw--top-2 md:tw--right-5 md:tw-bottom-0 md:tw-top-0;
        @apply tw-h-5 tw-w-5 tw-rounded-full tw-bg-red-200 md:tw-h-4 md:tw-w-4;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-text-sm;
    }
</style>
