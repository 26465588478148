<template>
    <div id="context-parent">
        <div
            v-if="displayPreference === 'month'"
            class="tw-flex tw-flex-wrap tw-justify-between"
        >
            <div class="tw-order-1 tw-w-auto">
                <MButton
                    class="tw-capitalize print:!tw-hidden"
                    variant="success"
                    @click="
                        date = date.clone().subtract(1, 'month');
                        getPlanning();
                    "
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-angle-left"
                    />
                    {{ fromMoment(date).subMonths(1).monthLong }}
                </MButton>
            </div>
            <div class="tw-order-2 tw-mt-2 tw-w-full tw-text-center sm:tw-order-1 sm:tw-mt-0 sm:tw-w-1/2 md:tw-w-1/3">
                <MHeading class="tw-capitalize">
                    {{ fromMoment(date).toFormat('MMMM yyyy') }}
                </MHeading>
                <div class="tw-mt-1">
                    <EventTotals
                        :forecast="totalWeekPlannings()"
                        :real="totalWeekPresences()"
                    />
                </div>
            </div>
            <div class="tw-order-1 tw-w-auto">
                <MButton
                    class="tw-capitalize print:!tw-hidden"
                    variant="success"
                    @click="
                        date = date.clone().add(1, 'month');
                        getPlanning();
                    "
                >
                    {{ fromMoment(date).addMonths(1).monthLong }}
                    <FontAwesomeIcon
                        class="tw-ml-1"
                        icon="fa-solid fa-angle-right"
                    />
                </MButton>
            </div>
        </div>
        <div
            v-else
            class="tw-flex tw-flex-wrap tw-justify-between"
        >
            <div class="tw-order-1 tw-w-auto">
                <MButton
                    class="print:!tw-hidden"
                    variant="success"
                    @click="
                        date = date.clone().subtract(1, 'weeks');
                        getPlanning();
                    "
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-angle-left"
                    />
                    {{ __('common:longweek_with_number', {week: fromMoment(date).subWeeks(1).weekNumber}) }}
                </MButton>
            </div>
            <div class="tw-order-2 tw-mt-2 tw-w-full tw-text-center sm:tw-order-1 sm:tw-mt-0 sm:tw-w-1/2 md:tw-w-1/3">
                <MHeading>
                    {{ __('common:longweek_with_number', {week: fromMoment(from).weekNumber}) }}
                </MHeading>
                <div class="tw-mt-1">
                    <EventTotals
                        :forecast="totalWeekPlannings()"
                        :real="totalWeekPresences()"
                    />
                </div>
            </div>
            <div class="tw-order-1 tw-w-auto">
                <MButton
                    class="print:!tw-hidden"
                    variant="success"
                    @click="
                        date = date.clone().add(1, 'weeks');
                        getPlanning();
                    "
                >
                    {{ __('common:longweek_with_number', {week: fromMoment(date).addWeeks(1).weekNumber}) }}
                    <FontAwesomeIcon
                        class="tw-ml-1"
                        icon="fa-solid fa-angle-right"
                    />
                </MButton>
            </div>
        </div>

        <div class="tw-my-4 tw-flex tw-flex-wrap tw-justify-between tw-gap-2 print:tw-hidden">
            <div class="tw-flex tw-flex-wrap tw-gap-2">
                <FilterEvents :event-filters.sync="eventFilters" />
                <CButtonGroup>
                    <MButton
                        :variant="displayPreference === 'week' ? 'primary' : 'light'"
                        @click="displayPreference = 'week'"
                    >
                        {{ __('common:week') }}
                    </MButton>
                    <MButton
                        :variant="displayPreference === 'month' ? 'primary' : 'light'"
                        @click="displayPreference = 'month'"
                    >
                        {{ __('common:month') }}
                    </MButton>
                </CButtonGroup>
            </div>

            <div class="tw-flex tw-flex-wrap tw-gap-2">
                <ManagePlanning
                    v-if="$can('create', 'kids_planning')"
                    :date="date"
                    :kid="kid"
                    :nursery="nursery"
                />
                <MButton @click="$refs.planningSummary.$refs.modal.show()">
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-table-list" />
                    </template>
                    {{ __('planning:planning_details') }}
                </MButton>
                <MTooltip placement="top-end">
                    <MButton
                        variant="gray"
                        @click="printPage()"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-print" />
                    </MButton>
                    <template #content>
                        {{ __('planning:print_planning') }}
                    </template>
                </MTooltip>
            </div>
        </div>

        <Loader
            v-if="loading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <div
            v-for="(week, w) in weeksToDisplay"
            v-else
            :key="'week' + w"
            style="page-break-inside: avoid"
        >
            <div
                v-if="w > 0"
                class="tw-mx-5 tw-mb-5 tw-border-b tw-border-gray-200"
            />

            <div
                class="tw-mb-2 tw-hidden tw-w-full sm:tw-block print:tw-block"
                style="padding-left: 85px; padding-right: 55px"
            >
                <Hours
                    :closing="closing(week)"
                    :opening="opening(week)"
                />
            </div>

            <div v-show="!loading">
                <div
                    v-for="(day, i) in enumerateDaysBetweenDates.filter(item => item.week() === week)"
                    :key="`day_${i}week_${w}`"
                >
                    <div
                        class="tw-mb-2 tw-flex tw-w-full tw-flex-wrap tw-items-center sm:tw-flex-nowrap print:tw-flex-nowrap"
                    >
                        <div class="tw-w-full sm:tw-w-28 print:tw-w-28">
                            <MPopover
                                placement="top"
                                trigger="hover"
                            >
                                <MHoverable class="tw-cursor-default tw-font-semibold tw-capitalize">
                                    {{ day.format('ddd DD/MM') }}
                                </MHoverable>
                                <template #content>
                                    <EventTotals
                                        :forecast="
                                            totalWeekPlannings(day.clone().startOf('day'), day.clone().endOf('day'))
                                        "
                                        :real="totalWeekPresences(day.clone().startOf('day'), day.clone().endOf('day'))"
                                    />
                                </template>
                            </MPopover>
                        </div>

                        <div class="tw-w-11/12 sm:tw-w-full print:tw-w-full">
                            <PlanningGenerator
                                :closing="closing(week)"
                                :day="day"
                                :events="computedEvents(week)"
                                inner-text
                                :nursery="nursery"
                                :opening="opening(week)"
                                :show-menu="$can('update', 'kids_planning')"
                                show-note
                                :user="user"
                            />
                        </div>
                        <div class="tw-w-1/12 tw-pl-1 sm:tw-ml-2 sm:tw-w-8 print:tw-ml-2 print:tw-w-8">
                            <MButton
                                v-if="$can('create', 'kids_planning')"
                                class="print:!tw-hidden"
                                variant="primary"
                                @click="addPlanning(day)"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-plus" />
                            </MButton>
                        </div>
                    </div>

                    <div
                        v-if="
                            numberOfWeeks > 1 &&
                            i + 1 === enumerateDaysBetweenDates.filter(item => item.week() === week).length
                        "
                        class="tw-mb-6 tw-ml-4 tw-w-full tw-pt-3"
                    >
                        <div class="tw-flex tw-items-center">
                            <div class="tw-mr-2 tw-text-base tw-font-semibold tw-text-gray-500">
                                {{ __('planning:total_for_week', {value: week}) }}
                            </div>
                            <EventTotals
                                :forecast="totalWeekPlannings(day.clone().startOf('week'), day.clone().endOf('week'))"
                                :real="totalWeekPresences(day.clone().startOf('week'), day.clone().endOf('week'))"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ContextMenu
            id="contextMenu"
            :event="segment"
            :nursery="nursery"
            :visible="toggleMenu"
        />

        <PlanningSummary
            ref="planningSummary"
            :kid="kid"
            :nursery="nursery"
        />
    </div>
</template>

<script>
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _concat from 'lodash-es/concat';
    import _map from 'lodash-es/map';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _debounce from 'lodash-es/debounce';
    import {
        getKidContractOfTheDay,
        getOverrunEvents,
        getPlanningEventsFromKidContracts,
        getPresenceEvents,
    } from '@/modules/legacy/libs/Planning.ts';
    import {daysBetweenDates, getClosing, getOpening, minutesToHours} from '@/modules/legacy/libs/planning/planning';
    import useAuth from '@/modules/app/composables/useAuth';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import ContextMenu from '@/modules/legacy/components/Modules/PlanningGenerator/ContextMenu.vue';
    import ManagePlanning from '@/modules/planning/components/kid/ManagePlanning.vue';
    import PlanningSummary from '@/modules/family/components/kid/Kid/Summary/Planning/PlanningSummary.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import FilterEvents from '@/modules/planning/components/kid/FilterEvents.vue';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import {Epoch} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {useIntervalFn} from '@vueuse/core';
    import usePrint from '@/modules/app/composables/usePrint';

    const displayPrefLocalStorage = 'kids:show:planning:displayRange';

    export default {
        components: {
            Hours,
            ContextMenu,
            ManagePlanning,
            PlanningSummary,
            EventTotals,
            FilterEvents,
        },
        props: ['kid', 'nursery'],
        data: () => ({
            date: moment(),

            computedPlannings: [],
            computedPresences: [],
            computedOverruns: [],

            absences: [],
            presences: [],
            segment: {},
            toggleMenu: false,
            loading: false,
            displayPreference: window.localStorage.getItem(displayPrefLocalStorage) ?? 'week',
            eventFilters: JSON.parse(localStorage.getItem('calendar:kids:filter:events')) || [],
        }),
        computed: {
            Epoch() {
                return Epoch;
            },
            user() {
                return useAuth().user.value;
            },
            moment() {
                return moment;
            },
            from() {
                if (this.displayPreference === 'month') {
                    return this.date.clone().startOf('month');
                }

                return this.date.clone().startOf('week');
            },
            to() {
                if (this.displayPreference === 'month') {
                    return this.date.clone().endOf('month');
                }

                return this.date.clone().endOf('week');
            },
            numberOfWeeks() {
                return this.to.diff(this.from, 'weeks');
            },
            weeksToDisplay() {
                return new Set(this.enumerateDaysBetweenDates.map(item => item.week()));
            },
            enumerateDaysBetweenDates() {
                return daysBetweenDates(this.from, this.to, this.nursery.openingHours);
            },
        },
        watch: {
            'kid.id': 'init',
            'displayPreference': function (val) {
                window.localStorage.setItem(displayPrefLocalStorage, val);
                this.getPlanning();
            },
        },
        mounted() {
            useIntervalFn(() => {
                this.computedPresences = getPresenceEvents(this.presences, this.computedPlannings);
                this.computedOverruns = getOverrunEvents(this.computedPresences, this.computedPlannings);
            }, 1000 * 60);

            this.init();

            this.$bus.$on('clicked:event', segment => {
                this.segment = segment;
                this.segment.start_time = moment.unix(this.segment.started_at).format('HH:mm:ss');
                this.segment.end_time = moment.unix(this.segment.ended_at).format('HH:mm:ss');
                this.toggleMenu = !this.toggleMenu;
            });

            this.$bus.$on('created:absence', absences => {
                if (!absences.length) {
                    absences = [absences];
                }

                absences.forEach(absence => {
                    absence.type = 'absence';
                    absence.event_type = 'absence';
                    absence.nursery_id = this.nursery.id;
                    this.absences.push(absence);
                });
            });

            this.$bus.$on('updated:absence', absence => {
                const abs = this.absences.find(item => item.id === absence.id);
                if (abs) {
                    abs.started_at = absence.started_at;
                    abs.ended_at = absence.ended_at;
                    abs.vacation = absence.vacation;
                    abs.billed = absence.billed;
                    abs.note = absence.note;
                    abs.unit = absence.unit;
                    abs.hours = absence.hours;
                    abs.hourly_rate = absence.hourly_rate;
                }
            });

            this.$bus.$on('deleted:absence', absence => {
                const abs = this.absences.find(item => item.id === absence.id);
                if (abs) {
                    const i = this.absences.indexOf(abs);
                    this.absences.splice(i, 1);
                }
            });

            this.$bus.$on('created:presence', presence => {
                if (presence) {
                    presence.started_at = presence.droped_at;
                    presence.ended_at = presence.picked_up_at;
                    presence.type = 'presence';
                    presence.nursery_id = this.nursery.id;
                    this.presences.push(presence);
                }

                this.computedPresences = getPresenceEvents(this.presences, this.computedPlannings);
                this.computedOverruns = getOverrunEvents(this.computedPresences, this.computedPlannings);
            });

            this.$bus.$on('updated:presence', presence => {
                const pre = this.presences.find(item => item.id === presence.id);
                if (pre) {
                    pre.started_at = presence.droped_at;
                    pre.ended_at = presence.picked_up_at;
                    pre.drop_note = presence.drop_note;
                    pre.pick_up_note = presence.pick_up_note;
                }

                this.computedPresences = getPresenceEvents(this.presences, this.computedPlannings);
                this.computedOverruns = getOverrunEvents(this.computedPresences, this.computedPlannings);
            });

            this.$bus.$on('deleted:presence', presence => {
                const pre = this.presences.find(item => item.id === presence.id);
                if (pre) {
                    const i = this.presences.indexOf(pre);
                    this.presences.splice(i, 1);
                }

                this.computedPresences = getPresenceEvents(this.presences, this.computedPlannings);
                this.computedOverruns = getOverrunEvents(this.computedPresences, this.computedPlannings);
            });

            this.$bus.$on('created:planning', planning => {
                const contract = this.kid.contracts.find(item => item.id === planning.contract_id);
                if (contract) {
                    planning.nursery_id = this.nursery.id;
                    contract.plannings.push(planning);

                    this.computedPlannings = this.computePlannings();
                    this.computedPresences = getPresenceEvents(this.presences, this.computedPlannings);
                    this.computedOverruns = getOverrunEvents(this.computedPresences, this.computedPlannings);
                }
            });

            this.$bus.$on('updated:planning', planning => {
                const contract = this.kid.contracts.find(item => item.id === planning.contract_id);
                if (contract && contract.plannings.length) {
                    const plan = contract.plannings.find(item => item.id === planning.id);
                    if (plan) {
                        plan.week = planning.week;
                        plan.day = planning.day;
                        plan.start_time = planning.start_time;
                        plan.end_time = planning.end_time;
                        plan.unit = planning.unit;
                        plan.hours = planning.hours;
                        plan.hourly_rate = planning.hourly_rate;

                        this.computedPlannings = this.computePlannings();
                        this.computedPresences = getPresenceEvents(this.presences, this.computedPlannings);
                        this.computedOverruns = getOverrunEvents(this.computedPresences, this.computedPlannings);
                    }
                }
            });

            this.$bus.$on('deleted:planning', planning => {
                const contract = this.kid.contracts.find(item => item.id === planning.contract_id);
                if (contract && contract.plannings.length) {
                    const plan = contract.plannings.find(item => item.id === planning.id);
                    if (plan) {
                        const i = contract.plannings.indexOf(plan);
                        contract.plannings.splice(i, 1);

                        this.computedPlannings = this.computePlannings();
                        this.computedPresences = getPresenceEvents(this.presences, this.computedPlannings);
                        this.computedOverruns = getOverrunEvents(this.computedPresences, this.computedPlannings);
                    }

                    this.absences = this.absences.filter(item => item.planning_id !== planning.id);
                }
            });
        },
        beforeDestroy() {
            this.$bus.$off('clicked:event');

            this.$bus.$off('created:absence');
            this.$bus.$off('updated:absence');
            this.$bus.$off('deleted:absence');

            this.$bus.$off('created:presence');
            this.$bus.$off('updated:presence');
            this.$bus.$off('deleted:presence');

            this.$bus.$off('created:planning');
            this.$bus.$off('updated:planning');
            this.$bus.$off('deleted:planning');
        },
        methods: {
            parse: useEpoch().parse,
            fromMoment: useEpoch().fromMoment,

            init() {
                this.getPlanning();
            },
            getPlanning() {
                if (this.kid.contracts.length) {
                    this.loading = true;
                    this.computedPlannings = this.computePlannings();
                    this.debouncePlanning();
                }
            },
            debouncePlanning: _debounce(function () {
                this.getPresences(() => {
                    this.getAbsences(() => {
                        this.loading = false;
                    });
                });
            }, 1000),
            getPresences(callback) {
                this.loading = true;
                useApi()
                    .legacy.get(route('kid.presences.index'), {
                        params: {
                            kid_id: this.kid.id,
                            from: this.from.unix(),
                            to: this.to.unix(),
                        },
                    })
                    .then(response => {
                        this.presences = _map(response.data, presence => {
                            presence.type = 'presence';
                            presence.started_at = presence.droped_at;
                            presence.ended_at = presence.picked_up_at;
                            presence.nursery_id = this.nursery.id;

                            return presence;
                        });

                        this.computedPresences = getPresenceEvents(this.presences, this.computedPlannings);
                        this.computedOverruns = getOverrunEvents(this.computedPresences, this.computedPlannings);

                        callback();
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            getAbsences(callback) {
                this.loading = true;
                useApi()
                    .legacy.get(route('kid.absences.index'), {
                        params: {
                            kid_id: this.kid.id,
                            from: this.from.unix(),
                            to: this.to.unix(),
                        },
                    })
                    .then(response => {
                        this.absences = _map(response.data, absence => {
                            absence.type = 'absence';
                            absence.event_type = 'absence';
                            absence.nursery_id = this.nursery.id;

                            return absence;
                        });
                        callback();
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            opening(week = null) {
                const hoursFromEvents = this.computedEvents(week).map(item =>
                    moment.unix(item.started_at).format('HH:mm:ss')
                );

                return getOpening(this.nursery.openingHours, true, false, hoursFromEvents);
            },
            closing(week = null) {
                const hoursFromEvents = this.computedEvents(week).map(item =>
                    moment.unix(item.ended_at).format('HH:mm:ss')
                );

                return getClosing(this.nursery.openingHours, true, false, hoursFromEvents);
            },
            computePlannings() {
                let events = [];
                _forEach(this.enumerateDaysBetweenDates, day => {
                    events = _concat(events, getPlanningEventsFromKidContracts(this.kid.contracts, day));
                });

                return events;
            },
            computedEvents(week = null) {
                let events = [];

                if (this.eventFilters.includes('forecast')) {
                    events = _concat(events, this.computedPlannings);
                }

                if (this.eventFilters.includes('real')) {
                    events = _concat(events, this.computedPresences, this.computedOverruns);

                    // Absences are considered as real events
                    if (this.eventFilters.includes('absence')) {
                        events = _concat(events, this.absences);
                    }
                }

                if (week) {
                    events = events.filter(item => moment.unix(item.started_at).week() === week);
                }

                // Filter using computed types from lib
                if (!this.eventFilters.includes('recurrent')) {
                    events = events.filter(item => item.type !== 'recurrent' && item.type !== 'presence');
                }
                if (!this.eventFilters.includes('occasional')) {
                    events = events.filter(item => item.type !== 'occasional');
                }
                if (!this.eventFilters.includes('adaptation')) {
                    events = events.filter(item => item.type !== 'adaptation');
                }

                return events;
            },

            addPlanning(day) {
                const contract = getKidContractOfTheDay(this.kid.contracts, day);
                if (contract) {
                    const line = {
                        day,
                        nursery_id: this.nursery.id,
                        kid_id: this.kid.id,
                        type: 'occasional',
                        contract_hourly_rate: contract.hourly_rate,
                        hourly_rate: contract.hourly_rate,
                        unit: 'day',
                    };
                    this.$bus.$emit('clicked:line', line);
                } else {
                    useNotification().error(__('planning:no_contract_for_kid'));
                }
            },

            totalWeekPlannings(from = this.from, to = this.to) {
                let total = 0;

                this.computedPlannings.forEach(planning => {
                    if (moment.unix(planning.started_at).isBetween(from, to, 'seconds', '[]')) {
                        total += (planning.ended_at - planning.started_at) / 60;
                    }
                });

                return minutesToHours(total);
            },
            totalWeekPresences(from = this.from, to = this.to) {
                let total = 0;

                this.computedPresences.forEach(presence => {
                    if (moment.unix(presence.started_at).isBetween(from, to, 'seconds', '[]')) {
                        total += (presence.ended_at - presence.started_at) / 60;
                    }
                });

                return minutesToHours(total);
            },

            printPage() {
                usePrint().print();
            },
        },
    };
</script>
