<template>
    <div>
        <div class="kid tw-relative tw-float-none tw-mb-6 tw-font-display sm:tw-float-left sm:tw-mb-0 sm:tw-pr-6">
            <BadgeTasks
                class="avatar"
                :tasks="tasks"
            >
                <LegacyKidAvatar
                    class="tw-w-10"
                    :kid="kid"
                />
            </BadgeTasks>
            <div
                class="name tw-ml-12 tw-font-grandhotel tw-capitalize hover:tw-text-blue-700 hover:tw-underline"
                :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
            >
                <router-link
                    v-if="$can('read', 'kids_details')"
                    :to="{name: 'kids.show', params: {nursery: nursery.id, kid: kid.id}}"
                >
                    {{ $fullName(kid) }}
                </router-link>
                <template v-else>
                    {{ $fullName(kid) }}
                </template>
            </div>
        </div>
        <div class="planning tw-float-none sm:tw-float-left">
            <planning-generator
                :closing="closing"
                :day="day"
                :events="data"
                inner-text
                :nursery="nursery"
                :opening="opening"
                :show-menu="showMenu"
                :show-note="showNote"
                :user="user"
            />
        </div>
        <div
            v-if="$can('create', 'kids_planning')"
            class="add tw-float-none sm:tw-float-right print:tw-hidden"
        >
            <MButton
                variant="primary"
                @click="addPlanning()"
            >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
            </MButton>
        </div>
    </div>
</template>

<script>
    import _concat from 'lodash-es/concat';
    import __ from '@/modules/app/utils/i18n-facade';
    import moment from 'moment';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import {
        getKidContractOfTheDay,
        getOverrunEvents,
        getPlanningEventsFromKidContracts,
        getPresenceEvents,
    } from '@/modules/legacy/libs/Planning.ts';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {useIntervalFn} from '@vueuse/core';

    export default {
        components: {LegacyKidAvatar, BadgeTasks},
        props: ['nursery', 'date', 'day', 'opening', 'closing', 'kid', 'tasks', 'user', 'showNote', 'showMenu'],
        data: () => ({
            presences: [],
            overruns: [],
        }),
        computed: {
            moment() {
                return moment;
            },
            data() {
                return _concat(this.computedPlanning, this.kid.absences, this.presences, this.overruns);
            },
            computedPlanning() {
                return getPlanningEventsFromKidContracts(this.kid.contracts, this.day);
            },
            hasPresencesWithoutEnd() {
                return this.kid.presences.filter(presence => !presence.ended_at).length > 0;
            },
        },
        watch: {
            'kid.presences': {
                immediate: true,
                deep: true,
                handler() {
                    this.refreshPresences(true);
                },
            },
            'computedPlanning'() {
                this.refreshPresences(true);
            },
        },
        mounted() {
            useIntervalFn(() => {
                this.refreshPresences();
            }, 1000 * 60);
        },
        methods: {
            refreshPresences(force = false) {
                if (force || this.hasPresencesWithoutEnd) {
                    this.presences = getPresenceEvents(this.kid.presences, this.computedPlanning);
                    this.overruns = getOverrunEvents(this.presences, this.computedPlanning);
                }
            },
            addPlanning() {
                const contract = getKidContractOfTheDay(this.kid.contracts, this.day);
                if (contract) {
                    const line = {
                        day: this.day,
                        nursery_id: this.nursery.id,
                        kid_id: this.kid.id,
                        contract_hourly_rate: contract.hourly_rate,
                        hourly_rate: contract.hourly_rate,
                        unit: 'day',
                    };
                    this.$bus.$emit('clicked:line', line);
                } else {
                    useNotification().error(__('planning:no_contract_for_kid'));
                }
            },
            removePlanning() {
                this.$emit('remove', this.day);
            },
        },
    };
</script>

<style scoped>
    @media screen(sm) {
        .kid {
            width: 200px;
        }

        .planning {
            width: calc(100% - 200px - 35px);
        }
    }

    .name {
        position: relative;
        top: 5px;
        width: 150px;
        height: 30px;

        overflow: hidden;
        font-size: 1.5rem;
        line-height: 1.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
            color: inherit;
        }
    }

    .avatar {
        position: absolute;
        top: -4px;
        left: 0;
    }
</style>
