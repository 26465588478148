<template>
    <MModal
        :header-title="$fullName(kid)"
        :modal="modal"
    >
        <KidAvatarEditor
            class="tw-mb-4"
            :kid="newKid"
            @deleteAvatar="deleteAvatar"
            @updateAvatar="updateAvatar($event)"
        />
        <EditKidModalForm
            :errors="errors"
            :groups="groups"
            :new-kid="newKid"
        />
        <template #footer-start>
            <MTooltip
                :hoverable="hasActiveContract"
                placement="bottom-start"
            >
                <MButton
                    :disabled="hasActiveContract"
                    :label="__('common:actions.delete_dots')"
                    variant="danger"
                    @click="remove"
                />
                <template #content>
                    {{ __('family_kid:you_must_delete_the_child_s_contracts_before_you_can_delete_the_child') }}
                </template>
            </MTooltip>
        </template>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.save')"
                variant="primary"
                @click="save"
            />
        </template>
    </MModal>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _isEqual from 'lodash-es/isEqual';
    import __ from '@/modules/app/utils/i18n-facade';
    import KidAvatarEditor from '@/modules/family/components/kid/Kid/Summary/EditKidModal/molecules/KidAvatarEditor.vue';
    import EditKidModalForm from '@/modules/family/components/kid/Kid/Summary/EditKidModal/molecules/EditKidModalForm.vue';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import {upload} from '@/modules/legacy/libs/usercontent';
    import {MqlOperation} from '@meekohq/lumos';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {EditKidModalForm, KidAvatarEditor},
        props: {
            kid: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            modal: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            errors: [],
            newKid: {},
            groups: [],
        }),
        computed: {
            hasActiveContract() {
                return this.kid.contracts.filter(contract => contract.draft === false).length > 0;
            },
        },
        watch: {
            'kid.id': 'init',
        },
        mounted() {
            this.groups = _cloneDeep(this.nursery.groups);
            this.groups.unshift({
                id: null,
                name: __('common:no_group'),
            });
            this.init();
        },
        methods: {
            init() {
                this.newKid = _cloneDeep(this.kid);
            },
            save() {
                useApi()
                    .legacy.put(
                        route('kids.update', {
                            kid: this.kid.id,
                        }),
                        this.newKid
                    )
                    .then(async response => {
                        const oldKid = _cloneDeep(this.kid);

                        this.kid.no = response.data.no;
                        this.kid.gender = response.data.gender;
                        this.kid.first_name = response.data.first_name;
                        this.kid.last_name = response.data.last_name;
                        this.kid.group_id = response.data.group_id;
                        this.kid.birthdate = response.data.birthdate;
                        this.kid.hygiene = response.data.hygiene;
                        this.kid.group = response.data.group;
                        this.kid.note = response.data.note;
                        this.kid.attendance_code = response.data.attendance_code;

                        // If change of birthdate, ask to reset vaccination card
                        if (response.data.birthdate && !_isEqual(oldKid.birthdate, response.data.birthdate)) {
                            await useMagicModal().confirmationModal({
                                title: __('family_kid:vaccine.regenerate_vaccination_record'),
                                text: __('family_kid:vaccine.add_warning'),
                                confirmButtonText: __('family_kid:vaccine.regenerate'),
                                dismissButtonText: __('family_kid:vaccine.do_not_regenerate'),
                                onConfirm: async () => {
                                    await new MqlOperation('health/medical_treatments/reset_vaccination_card', {
                                        kid_id: this.kid.id,
                                    }).run();
                                    this.modal.hide();
                                    useNotification().success(__('family_kid:kid.updated_successfully'));
                                    this.errors = [];
                                },
                            });
                        } else {
                            this.modal.hide();
                            useNotification().success(__('family_kid:kid.updated_successfully'));
                            this.errors = [];
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.errors) {
                            this.errors = error.response.data.errors;
                        } else {
                            this.errors = [];
                        }
                    });
            },
            remove() {
                useMagicModal().deleteConfirmationModal({
                    title: __('family_kid:kid.delete_ask'),
                    text: __('family_kid:kid.delete_warning'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('kids.destroy', {
                                    kid: this.kid.id,
                                })
                            )
                            .then(() => {
                                this.modal.hide();
                                useNotification().success(__('family_kid:kid.deleted_successfully'));
                                this.$router.push({name: 'kids.index', params: {nursery: this.nursery.id}});
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, function (value) {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },
            updateAvatar(event) {
                upload(event.target.files[0]).then(response => {
                    const data = {
                        avatar: {
                            key: response.key,
                            hash: response.hash,
                        },
                    };
                    useApi()
                        .legacy.put(
                            route('kids.update', {
                                kid: this.kid.id,
                            }),
                            data
                        )
                        .then(response => {
                            this.kid.avatar_url = response.data.avatar_url;
                            this.newKid.avatar_url = response.data.avatar_url;
                            useNotification().success(__('common:profile_picture_updated'));
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                });
            },
            deleteAvatar() {
                useApi()
                    .legacy.delete(
                        route('kids.destroyAvatar', {
                            kid: this.kid.id,
                        })
                    )
                    .then(() => {
                        this.newKid.avatar_url = null;
                        useNotification().success(__('common:profile_picture_deleted'));
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
        },
    };
</script>
