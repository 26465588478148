<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import {computed} from 'vue';

    const props = defineProps<{
        invoice: InvoiceModel | LegacyInvoiceType;
        customer: CustomerModel;
    }>();

    const endPeriodDate = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.attributes.end_period_date
                ? Epoch.fromISOString(props.invoice.attributes.end_period_date).toLocaleString(Epoch.presets.DATE_SHORT)
                : '';
        }

        return props.invoice.end_period_date
            ? Epoch.fromISOString(props.invoice.end_period_date).toLocaleString(Epoch.presets.DATE_SHORT)
            : '';
    });
</script>

<template>
    <div class="InvoiceMiniatureInformations">
        <div class="InvoiceMiniatureInformations__item">
            <FontAwesomeIcon
                class="tw-w-4 tw-text-orange-500"
                icon="fa-duotone fa-file-user"
            />
            <div class="tw-font-semibold">
                {{ customer.attributes.name }}
            </div>
        </div>
        <div class="InvoiceMiniatureInformations__item">
            <FontAwesomeIcon
                class="tw-w-4 tw-text-primary-500"
                icon="fa-duotone fa-calendar"
            />
            <div class="tw-font-semibold">
                {{ __('billing_transaction:the_colon') }}
                {{ endPeriodDate }}
            </div>
        </div>
    </div>
</template>

<style scoped>
    .InvoiceMiniatureInformations {
        @apply tw-flex tw-flex-col tw-gap-1;
    }

    .InvoiceMiniatureInformations__item {
        @apply tw-flex tw-flex-row tw-items-baseline tw-gap-1;
    }
</style>
