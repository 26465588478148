<template>
    <div class="event-card">
        <CHStack
            align="center"
            class="event-header"
            gap="1"
            :style="{backgroundColor: colors.header}"
        >
            <div>
                <CCenter
                    class="event-icon-circle"
                    :style="{backgroundColor: colors.icon.background}"
                >
                    <CIcon
                        v-if="icon"
                        class="event-icon"
                        :path="icon.attributes.path"
                        :provider="icon.attributes.provider"
                        :style="{color: colors.icon.text}"
                    />
                </CCenter>
            </div>
            <CText
                font-size="xs"
                font-weight="semibold"
                :style="{color: colors.title}"
            >
                {{ eventType.attributes.name }}
            </CText>
        </CHStack>
        <CVStack
            class="event-content"
            gap="2"
        >
            <router-link
                class="tw-leading-none"
                :to="{
                    name: 'planning.staffs.weekly',
                    params: {nursery: organization.id},
                    query: {date: routerLinkDate},
                }"
            >
                <CText
                    font-size="sm"
                    font-weight="semibold"
                >
                    {{ date }}
                </CText>
            </router-link>
            <CHStack
                align="center"
                gap="1"
            >
                <StaffAvatar
                    class="tw-w-5"
                    :staff-model="staff"
                />
                <router-link :to="{name: 'staffs.show', params: {nursery: activeOrganization.id, staff: staff.id}}">
                    <CText
                        class="tw-leading-none"
                        family="grandhotel"
                        font-size="lg"
                        :variant="staff.attributes.gender === 'male' ? 'boy' : 'girl'"
                    >
                        {{ $capitalize(staff.fullname) }}
                    </CText>
                </router-link>
            </CHStack>
            <CVStack>
                <template v-if="hasManyOrganizations">
                    <CText
                        font-size="xs"
                        variant="disabled"
                    >
                        {{ organization.attributes.name }}
                        <template v-if="organization.attributes.address.city">
                            - {{ organization.attributes.address.city }}
                        </template>
                    </CText>
                    <CText
                        font-size="xs"
                        variant="disabled"
                    >
                        <template v-if="event.kidsGroup().value()">
                            {{ event.kidsGroup().value().attributes.name }}
                        </template>
                        <template v-else>
                            {{ __('common:no_group') }}
                        </template>
                    </CText>
                </template>
                <template v-if="event.balanceAllocations().value().count()">
                    <MPopover
                        class="tw-leading-none"
                        trigger="hover"
                    >
                        <CText
                            font-size="xs"
                            variant="disabled"
                        >
                            {{ __('request:balance', {count: event.balanceAllocations().value().count()}) }}
                        </CText>
                        <template #content>
                            <EventAllocationsList
                                class="tw-p-2"
                                :event="event"
                            />
                        </template>
                    </MPopover>
                </template>
            </CVStack>
        </CVStack>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import useMoment from '@/modules/app/composables/useMoment';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import moment from 'moment';
    import {capitalize} from 'lodash-es';
    import EventAllocationsList from '@/modules/request/components/Resources/Types/Event/EventAllocationsList.vue';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import useManager from '@/modules/app/composables/useManager';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {StaffAvatar, EventAllocationsList},
        props: {
            event: {
                type: Object as PropType<EventModel>,
                required: true,
            },
            staff: {
                type: Object as PropType<StaffModel>,
            },
        },
        setup(props) {
            const {formatDate} = useMoment();
            const {hasManyOrganizations} = useManager();
            const {eventType, organization, group, icon} = getRelationsValues(props);

            const {activeOrganization} = useManager();

            const {date, routerLinkDate} = getEventDate(formatDate, props);

            const colors = computed(() => {
                const generated = useEventStyle(ref(eventType.value.attributes.color), ref(false), 'md', true);

                return {
                    header: generated.event.value.main,
                    title: generated.event.value.alt,
                    icon: {
                        text: generated.icon.value.main,
                        background: generated.icon.value.alt,
                    },
                };
            });

            return {
                date,
                routerLinkDate,
                eventType,
                organization,
                hasManyOrganizations,
                group,
                icon,
                moment,
                formatDate,
                colors,
                activeOrganization,
            };
        },
    });

    export function getRelationsValues(props) {
        const eventType = computed(() => props.event.eventType().value());
        const icon = computed(() => props.event.eventType().value().iconModel().value());
        const organization = computed(() => props.event.organization().value());
        const group = computed(() => props.event.kidsGroup().value());

        return {
            eventType,
            organization,
            group,
            icon,
        };
    }

    export function getEventDate(formatDate, props) {
        const formatHours = function (date) {
            return moment(date).minutes() > 0
                ? moment(date).format('H') + 'h' + moment(date).format('mm')
                : moment(date).format('H') + 'h';
        };

        const routerLinkDate = computed(() => {
            if (props.event.attributes.datetime_event) {
                return moment(props.event.attributes.datetime_event.started_at).unix();
            } else {
                return moment(props.event.attributes.date_event?.started_at).unix();
            }
        });

        const date = computed(() => {
            if (props.event.isSameDay) {
                if (props.event.attributes.datetime_event) {
                    return __('request:from_fromtime_totime', {
                        date: capitalize(formatDate(props.event.attributes.datetime_event.started_at, 'ddd D/MM')),
                        startedAt: formatHours(props.event.attributes.datetime_event.started_at),
                        endedAt: formatHours(props.event.attributes.datetime_event.ended_at),
                    });
                } else {
                    return capitalize(formatDate(props.event.attributes.date_event?.started_at, 'ddd D/MM'));
                }
            } else {
                if (props.event.attributes.datetime_event) {
                    return __('request:from_fromtime_to_totime', {
                        startedAtDate: capitalize(
                            formatDate(props.event.attributes.datetime_event.started_at, 'ddd D/MM')
                        ),
                        startedAtHours: formatHours(props.event.attributes.datetime_event.started_at),
                        endedAtDate: capitalize(formatDate(props.event.attributes.datetime_event.ended_at, 'ddd D/MM')),
                        endedAtHours: formatHours(props.event.attributes.datetime_event.ended_at),
                    });
                } else {
                    return __('common:date_format.human_from_to_short', {
                        from: capitalize(formatDate(props.event.attributes.date_event?.started_at, 'ddd D/MM')),
                        to: capitalize(formatDate(props.event.attributes.date_event?.ended_at, 'ddd D/MM')),
                    });
                }
            }
        });

        return {
            date,
            routerLinkDate,
        };
    }
</script>

<style scoped>
    .event-card {
        @apply tw-rounded-lg tw-shadow-md;
        box-sizing: border-box;

        .event-header {
            @apply tw-rounded-inherit tw-rounded-b-none tw-p-1;

            .event-icon-circle {
                @apply tw-h-4 tw-w-4 tw-rounded-full;
            }

            .event-icon {
                @apply tw-text-sm;
            }
        }

        .event-content {
            @apply tw-rounded-inherit tw-rounded-t-none tw-bg-white tw-px-3 tw-py-2;
        }
    }
</style>
