<template>
    <CVStack>
        <CVStack
            v-for="{calendar, eventTypesModel} in sortedEventTypesByCalendar"
            :key="calendar.id"
            class="tw-mb-5"
        >
            <MHeading
                class="tw-mb-2"
                level="h3"
            >
                {{ calendar.attributes.name }}
            </MHeading>
            <CText
                class="tw-mb-1 tw-text-gray-500"
                emphase
                font-size="sm"
            >
                <template v-if="calendar.attributes.factor === 1">
                    {{ __('hr:schedules_added_to_this_calendar_will_be_added_to_working_time') }}
                </template>
                <template v-else-if="calendar.attributes.factor === -1">
                    {{ __('hr:schedules_added_to_this_calendar_will_be_subtracted_from_working_time') }}
                </template>
                <template v-else-if="calendar.attributes.factor === 0">
                    {{ __('hr:schedules_added_to_this_calendar_will_not_be_counted_as_working_time') }}
                </template>
            </CText>
            <CListHeader class="tw-px-0" />
            <CList>
                <EventTypeListItem
                    v-for="eventType in eventTypesModel"
                    :key="eventType.id"
                    :event-type="eventType"
                    @deleted="deleteEventType"
                />
            </CList>
        </CVStack>
    </CVStack>
</template>

<script lang="ts">
    import EventTypeListItem from '@/modules/human-resources/components/event-type/List/EventTypeListItem.vue';
    import type {Collection, QueryBuilder} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import type CalendarModel from '@/modules/human-resources/models/CalendarModel';
    import type EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import {sortBy} from 'lodash-es';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {EventTypeListItem},
        props: {
            calendars: {
                type: Object as PropType<Collection<CalendarModel>>,
                required: false,
                default: undefined,
            },
            eventTypesRelationsConstraint: {
                type: Function as PropType<(query: QueryBuilder<EventTypeModel>) => void>,
                required: false,
                default: undefined,
            },
        },
        setup(props) {
            const {activeOrganization} = useManager();

            // use computed to react to the EventType relation of the calendars.value
            // return a calendars collection wich contains an ordered EventTypes collection for each calendar
            const sortedEventTypesByCalendar = computed(() => {
                return props.calendars.map(calendar => {
                    // get a collection value of every EventTypes for each calendar
                    const eventTypes = calendar.eventTypes().setQuery(props.eventTypesRelationsConstraint).value();

                    eventTypes.each(eventType => {
                        // get the active eventType pivot
                        const pivot = eventType
                            .organizationsPivots()
                            .value()
                            .first(item => item.attributes.organization_id === activeOrganization.value.id);
                        eventType.extra = {is_archived: pivot?.attributes.archived_at !== null};
                    });

                    // order EventTypes collection by name and if each of it is archived or not
                    const eventTypesModel = collect(
                        sortBy(
                            eventTypes.all(),
                            [model => model.extra.is_archived, model => model.attributes.name],
                            ['asc', 'asc']
                        )
                    );

                    // return a new object with calendarModel and a collection of eventTypesModel to easily display the calendar and its EventTypes
                    return {
                        calendar,
                        eventTypesModel,
                    };
                });
            });

            function deleteEventType(model: EventTypeModel) {
                const calendar = props.calendars.first(item => item.id === model.attributes.calendar_id);

                // mutate the eventTypes relation by rejecting the model passed in parameter of deleteEventType()
                calendar
                    .eventTypes()
                    .setQuery(props.eventTypesRelationsConstraint)
                    .mutate(value => value.unique(item => item.id).reject(item => model.id === item.id));
                useNotification().success(__('hr:event_type_deleted_successfully'));
            }

            function addEventType(model: EventTypeModel) {
                const calendar = props.calendars.first(item => item.id === model.attributes.calendar_id);

                // mutate the eventTypes relation by pushing the model passed in parameter of addEventType()
                calendar
                    .eventTypes()
                    .setQuery(props.eventTypesRelationsConstraint)
                    .mutate(value => value.push(model).unique(item => item.id));
            }

            return {
                sortedEventTypesByCalendar,
                deleteEventType,
                addEventType,
            };
        },
    });
</script>
