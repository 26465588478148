<template>
    <div v-if="staffCopy">
        <CFormTextArea
            v-model="staffCopy.value.attributes.biography"
            :disabled="!$can('update', 'staffs_details')"
            :placeholder="__('hr_staff:biography_posted_on_meeko_family_and_on_the_website_dots')"
            rows="4"
        />

        <div class="tw-flex tw-w-full">
            <MButton
                v-if="$can('update', 'staffs_details')"
                class="tw-mt-2 md:tw-ml-auto"
                :loading="saveLoading"
                variant="primary"
                @click="save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, onMounted, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import Mirror from '@/modules/legacy/helpers/mirror.helper';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
            },
            nursery: {
                type: Object,
            },
        },
        setup(props) {
            const staffCopy = ref<Mirror>();
            const saveLoading = ref(false);

            onMounted(() => {
                staffCopy.value = new Mirror(props.staff);
            });

            function save() {
                saveLoading.value = true;
                staffCopy.value?.value
                    .save(undefined, true)
                    .then(() => {
                        staffCopy.value?.commit();
                        useNotification().success(__('hr_staff:biography_successfully_updated'));
                        saveLoading.value = false;
                    })
                    .catch(() => {
                        saveLoading.value = false;
                    });
            }

            return {
                staffCopy,
                saveLoading,
                save,
            };
        },
    });
</script>
