<template>
    <div>
        <SelectResource
            ref="SelectResource"
            :builder="builder"
            :button-class="buttonClass"
            :has-unselect="true"
            :model="organizationModel"
            :unselect-value="unselectValue"
            :value="value"
            @fallback="showModal"
            @input="onModelSelected"
            @search="onSearchValue"
        >
            <template #button>
                <template v-if="value">
                    <CBadge
                        v-if="value.attributes.city"
                        class="tw-mr-1 tw-whitespace-nowrap tw-text-sm tw-font-semibold tw-text-blue-500"
                        size="sm"
                        variant="primary"
                    >
                        {{ value.attributes.city }}
                    </CBadge>
                    {{ value.attributes.name }}
                </template>
                <template v-else>
                    <slot name="unselectedValue">
                        {{ __('components:choose_organization') }}
                    </slot>
                </template>
            </template>

            <template
                v-if="createOptionEnabled"
                #fallback="{searchValue}"
            >
                <template v-if="searchValue">
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />{{ __('common:actions.add') }} {{ searchValue }}
                </template>
                <template v-else>
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />{{ __('common:actions.add') }}
                </template>
            </template>

            <template
                v-if="value"
                #unselect-item
            >
                <slot name="unselect-item">
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-duotone fa-unlink"
                    />{{ __('components:deselect_organization') }}
                </slot>
            </template>

            <template #default="{option, searchValue}">
                <div class="tw-flex tw-pr-4">
                    <CBadge
                        v-if="option.attributes.city"
                        class="tw-mr-1 tw-whitespace-nowrap tw-text-sm tw-font-semibold tw-text-blue-500"
                        size="sm"
                        variant="primary"
                    >
                        {{ option.attributes.city }}
                    </CBadge>
                    <div
                        class="tw-truncate"
                        v-html="TextHighlighter.highlight(searchValue, option.attributes.name)"
                    />
                </div>
            </template>
        </SelectResource>
    </div>
</template>

<script>
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';
    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import {collect, QueryBuilder} from '@meekohq/lumos';
    import useAuth from '@/modules/app/composables/useAuth';

    export default {
        components: {SelectResource},
        props: {
            unselectValue: {},
            value: {},
            extendBuilder: {
                default: () => new QueryBuilder(),
            },
            disabled: {
                default: false,
            },
            customModal: {
                default: false,
            },
            createOptionEnabled: {
                default: false,
            },
            dropdownPosition: {},
            dropdownSizeClass: {},
            dropdownClass: {},
            buttonClass: {},
        },

        emits: ['input', 'hideModal', 'showModal'],

        data() {
            return {
                organizationModel: OrganizationModel,
                loader: new Loader(),
                TextHighlighter: new TextHighlighter(),
                searchValue: '',
            };
        },

        computed: {
            builder() {
                const organizationIds = collect(useAuth().legacyUser.value.nurseries).keyBy('id').keys().all();

                return OrganizationModel.query()
                    .whereIn('id', organizationIds)
                    .orderBy('name')
                    .whereLike('name', `*${this.searchValue}*`)
                    .scope('notActive');
            },

            isValue() {
                return this.value;
            },

            defaultModal: {
                cache: false,
                get() {
                    return this.$refs.defaultModal;
                },
            },

            finder: {
                cache: false,
                get() {
                    return this.$refs.finder;
                },
            },

            SelectResource: {
                cache: false,
                get() {
                    return this.$refs.SelectResource;
                },
            },
        },

        methods: {
            onSearchValue(event) {
                this.searchValue = event;
            },

            onModelCreated(model) {
                this.hideModal();
                this.onModelSelected(model);
            },

            onModelSelected(model) {
                this.$emit('input', model);
            },

            hideModal() {
                if (this.customModal) {
                    this.$emit('hideModal');
                } else {
                    this.defaultModal.hide();
                }
            },

            showModal() {
                if (this.customModal) {
                    this.$emit('showModal');
                } else {
                    this.defaultModal.show();
                }
                this.SelectResource.hide();
            },
        },
    };
</script>
