import {merge} from 'lodash-es';
import type {FilterOptions, FilterType} from '@/modules/app/composables/useMagicFilter';
import useMagicFilter, {setupFilter} from '@/modules/app/composables/useMagicFilter';
import type {Ref} from 'vue';
import type {Model, ModelAvailableAttributesType, QueryBuilderOrderByDirectionType} from '@meekohq/lumos';
import {Epoch} from '@meekohq/lumos';
import type {PeriodFilterType} from '@/modules/legacy/components/Filter/PeriodFilterType';
import TextSerializer from '@/modules/magic-index/utils/serializers/TextSerializer';
import BooleanSerializer from '@/modules/magic-index/utils/serializers/BooleanSerializer';
import PeriodSerializer from '@/modules/magic-index/utils/serializers/PeriodSerializer';
import AmountSerializer from '@/modules/magic-index/utils/serializers/AmountSerializer';
import ModelSerializer from '@/modules/magic-index/utils/serializers/ModelSerializer';
import useManager from '@/modules/app/composables/useManager';
import type TenantModel from '@/modules/cashier/models/TenantModel';
import OrderBySerializer from '@/modules/magic-index/utils/serializers/OrderBySerializer';
import NumberSerializer from '@/modules/magic-index/utils/serializers/NumberSerializer';
import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';

type Filters =
    | 'search'
    | 'unallocatedOnly'
    | 'period'
    | 'amount'
    | 'tenants'
    | 'orderBy'
    | 'customers'
    | 'paymentMethod'
    | 'page';

interface AmountFilterType {
    min: number | null;
    max: number | null;
}
interface OrderByFilterType {
    orderBy: string;
    queryDirection: QueryBuilderOrderByDirectionType;
}

export interface OutputType {
    searchFilter: FilterType<string | null>;
    unallocatedOnlyFilter: FilterType<boolean>;
    periodFilter: FilterType<PeriodFilterType>;
    amountFilter: FilterType<AmountFilterType>;
    tenantsFilter: FilterType<Model[]>;
    customersFilter: FilterType<Model[]>;
    orderByFilter: FilterType<OrderByFilterType>;
    paymentMethodFilter: FilterType<string | undefined>;
    pageFilter: FilterType<number>;
    activeCount: Ref<number>;
    isReady: Ref<boolean>;
    reset: () => void;
    allFilters: Ref<any[]>;
}

export interface TransactionListFiltersType {
    period: PeriodFilterType;
    amountMin: number | null;
    amountMax: number | null;
    tenants: TenantModel[];
    customers: CustomerModel[];
    invoiceIds: string[];
    transactionIds: string[];
    paymentMethodName: string | undefined;
    hasRemainingAmount: boolean;
    search: string | undefined;
    orderBy: ModelAvailableAttributesType<TransactionModel> | undefined;
    queryDirection: QueryBuilderOrderByDirectionType | undefined;
    with: string[];
    page: number;
}

export default function useFiltersState(options?: Partial<Record<Filters, FilterOptions>>): OutputType {
    const defaultOptions: Record<Filters, FilterOptions> = {
        search: {
            defaultValue: '',
            debounceDelay: 500,
        },
        unallocatedOnly: {
            defaultValue: false,
            serializer: BooleanSerializer,
            debounceDelay: 500,
        },
        period: {
            defaultValue: {
                from: new Epoch().startOfMonth().toISOString(),
                to: new Epoch().endOfMonth().toISOString(),
            },
            serializer: PeriodSerializer,
        },
        amount: {
            defaultValue: {
                min: null,
                max: null,
            },
            serializer: AmountSerializer,
        },
        paymentMethod: {
            defaultValue: undefined,
            serializer: TextSerializer,
        },
        orderBy: {
            defaultValue: {
                orderBy: 'date',
                queryDirection: 'desc',
            },
            serializer: OrderBySerializer,
        },
        page: {
            defaultValue: 1,
            serializer: NumberSerializer,
        },
        tenants: {
            defaultValue: () => {
                const {activeOrganization} = useManager();

                const organizationTenants = activeOrganization.value.tenants().value();

                const output: TenantModel[] = [];
                organizationTenants.each(organizationTenant => {
                    output.push(organizationTenant);
                });

                return output;
            },
            serializer: ModelSerializer,
        },
        customers: {
            defaultValue: [],
            serializer: ModelSerializer,
        },
    };

    const optionsWithDefaults = merge({}, defaultOptions, options);

    const searchFilter = setupFilter<string | null>(optionsWithDefaults.search);
    const unallocatedOnlyFilter = setupFilter<boolean>(optionsWithDefaults.unallocatedOnly);
    const periodFilter = setupFilter<PeriodFilterType>(optionsWithDefaults.period);
    const amountFilter = setupFilter<AmountFilterType>(optionsWithDefaults.amount);
    const paymentMethodFilter = setupFilter<string | undefined>(optionsWithDefaults.paymentMethod);
    const tenantsFilter = setupFilter<Model[]>(optionsWithDefaults.tenants);
    const customersFilter = setupFilter<Model[]>(optionsWithDefaults.customers);
    const orderByFilter = setupFilter<OrderByFilterType>(optionsWithDefaults.orderBy);
    const pageFilter = setupFilter<number>(optionsWithDefaults.page);

    const {activeCount, isReady, reset, allFilters} = useMagicFilter(
        [
            searchFilter,
            unallocatedOnlyFilter,
            periodFilter,
            amountFilter,
            tenantsFilter,
            paymentMethodFilter,
            customersFilter,
            orderByFilter,
        ],
        pageFilter
    );

    return {
        searchFilter,
        unallocatedOnlyFilter,
        periodFilter,
        amountFilter,
        tenantsFilter,
        customersFilter,
        paymentMethodFilter,
        orderByFilter,
        pageFilter,
        activeCount,
        isReady,
        reset,
        allFilters,
    };
}
