<template>
    <MModal
        :header-title="modalTitle"
        :loading="isLoading"
        :modal="modal"
        size="4xl"
        @hidden="$emit('hidden')"
    >
        <TransactionModalContent
            :can-select-customer="canSelectCustomer"
            :customer="customer"
            :invoice="invoice"
            :payment="payment"
            :transaction-state="transactionState"
        />
        <template #footer-end="{hide}">
            <TransactionModalFooter
                :transaction-state="transactionState"
                @hide="hide"
            />
        </template>
    </MModal>
</template>

<script lang="ts">
    import {computed, defineComponent, onBeforeMount, type PropType, ref} from 'vue';
    import {useRoute} from 'vue-router/composables';
    import _round from 'lodash-es/round';
    import TransactionTypeValue from '@/modules/cashier/transaction/domain/TransactionTypeValue';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import {ModelCollection} from '@meekohq/lumos';
    import {loadTransactionsRelationships} from '@/modules/cashier/utils/issuing/transaction/loadTransactionsRelationships';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import type {TransactionAttributesParamType} from '@/modules/cashier/transaction/infrastructure/components/useTransactionState';
    import useTransactionState, {
        makeNewTransactionState,
    } from '@/modules/cashier/transaction/infrastructure/components/useTransactionState';
    import TransactionModalContent from '@/modules/cashier/transaction/infrastructure/components/TransactionModalContent.vue';
    import TransactionModalFooter from '@/modules/cashier/transaction/infrastructure/components/TransactionModalFooter.vue';

    export default defineComponent({
        components: {
            TransactionModalContent,
            TransactionModalFooter,
        },
        props: {
            modal: {
                type: Object as PropType<ModalType>,
                required: true,
            },
            canSelectCustomer: {
                type: Boolean,
                default: true,
            },
            invoice: {
                type: Object as PropType<LegacyInvoiceType>,
                required: false,
                default: undefined,
            },
            tenantId: {
                type: String,
                required: true,
            },
            payment: {
                type: Object as PropType<PaymentModel>,
                required: false,
                default: undefined,
            },
            customer: {
                type: Object as PropType<CustomerModel>,
                default: undefined,
            },
        },
        setup(props, {emit}) {
            const route = useRoute();

            // Get transaction state
            const transactionState = useTransactionState();
            const {bus, transaction} = transactionState;
            const isLoading = ref(false);

            onBeforeMount(async () => {
                await setupState();
            });

            bus.on('saved', async (transactionModel: TransactionModel) => {
                // Here we load the relationships the exact same way as in the TransactionList
                await loadTransactionsRelationships(new ModelCollection<TransactionModel>([transactionModel]));

                props.modal.hide();
                // Then we emit the updated transaction with the relationships loaded
                emit('created', transactionModel);
            });

            async function setupState() {
                isLoading.value = true;

                const attributes: TransactionAttributesParamType = {
                    tenant_id: props.tenantId,
                    customer_id:
                        props.payment?.invoicesRelation.first()?.attributes.customer_id ?? props.customer?.getKey(),
                };

                if (props.payment) {
                    attributes.type = props.payment.attributes.refund
                        ? TransactionTypeValue.debit
                        : TransactionTypeValue.credit;
                    attributes.amount = _round(props.payment.computed.remaining_amount, 2);
                } else {
                    attributes.type = (route.query?.type as TransactionTypeValue) ?? TransactionTypeValue.credit;
                }

                await makeNewTransactionState(transactionState, attributes);

                isLoading.value = false;
            }

            const modalTitle = computed(() => {
                if (transaction.value?.isCredit) {
                    return __('billing_transaction:actions.create_credit');
                }

                return __('billing_transaction:actions.create_debit');
            });

            return {
                bus,
                isLoading,
                modalTitle,
                transaction,
                transactionState,
            };
        },
    });
</script>

<style scoped>
    .TransactionCreateModal__separator {
        @apply tw-my-6 tw-h-1 tw-w-full tw-rounded-full tw-bg-gray-200;
    }

    .TransactionCreateModal__footer {
        @apply tw-flex tw-w-full tw-justify-end tw-gap-3;
    }
</style>
