<template>
    <div class="tw-relative tw-w-full">
        <div
            v-if="!editing && !forceEdit"
            ref="data"
            :class="computedClass"
            @click="edit"
        >
            <div
                v-if="!noEditBtn"
                class="inplace-editor-edit-btn tw-flex tw-items-center tw-justify-center"
            >
                <FontAwesomeIcon icon="fa-solid fa-pen" />
            </div>
            <div
                v-if="showCopyBtn"
                class="inplace-editor-copy-btn tw-flex tw-items-center tw-justify-center"
                @click.stop="copy()"
            >
                <CTooltip>
                    <FontAwesomeIcon icon="fa-solid fa-copy" />
                    <template #content>
                        {{ __('common:actions.copy') }}
                    </template>
                </CTooltip>
            </div>
            <slot />
        </div>
        <div
            v-if="editing || forceEdit"
            v-click-outside="clickOutside"
            class="inplace-editor-editor"
        >
            <div
                class="inplace-editor-save-btn tw-flex tw-items-center tw-justify-between"
                @click="save"
            >
                <div
                    class="inplace-editor-undo-btn tw-ml-1 tw-flex tw-items-center tw-justify-center"
                    @click.stop="undo"
                >
                    <FontAwesomeIcon
                        class="tw-text-black"
                        icon="fa-solid fa-undo"
                    />
                </div>
                <FontAwesomeIcon
                    class="tw-mr-3"
                    icon="fa-solid fa-check"
                />
            </div>

            <slot name="edition" />
        </div>
    </div>
</template>

<script>
    import _join from 'lodash-es/join';
    import _forEach from 'lodash-es/forEach';

    import {useClipboard} from '@vueuse/core';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        name: 'InPlaceEditor',
        props: {
            inputRefs: {
                type: Array,
                required: false,
            },
            forceEdit: {
                type: Boolean,
                default: false,
                required: false,
            },
            dataClass: {
                type: String,
                default: null,
                required: false,
            },
            noEdit: {
                type: Boolean,
                default: false,
                required: false,
            },
            noBorder: {
                type: Boolean,
                default: false,
                required: false,
            },
            noPadding: {
                type: Boolean,
                default: false,
                required: false,
            },
            noEditBtn: {
                type: Boolean,
                default: false,
                required: false,
            },
            showCopyBtn: {
                type: Boolean,
                default: false,
            },
            valueToCopy: {
                type: String,
                default: null,
            },
        },
        data: () => ({
            editing: false,
            triggerOutsideClick: false,
        }),
        computed: {
            refs() {
                // Get all $refs in parent
                return this.$slots.default[0].context.$refs;
            },
            canEdit() {
                return !this.noEdit;
            },
            computedClass() {
                const array = [];

                array.push('inplace-editor-data-wrapper tw-border-dashed tw-border-gray-300 tw-leading-normal');

                if (!this.noBorder) {
                    array.push('tw-border-b');
                }

                if (!this.noPadding) {
                    array.push('tw-pr-5');
                }

                array.push(this.dataClass);

                return _join(array, ' ');
            },
        },
        watch: {
            // editing: function (editing) {
            //     this.triggerOutsideClick = editing;
            //     this.$nextTick(() => {
            //
            //     });
            // },
        },
        methods: {
            clickOutside() {
                this.save();
                // if (this.triggerOutsideClick) {
                // }
            },
            save() {
                _forEach(this.inputRefs, ref => {
                    this.refs[ref].validate();
                });
                this.$emit('save');
                this.editing = false;
            },
            edit() {
                if (this.canEdit) {
                    this.editing = !this.editing;
                    this.$nextTick(() => {
                        this.$emit('editing');
                    });
                }
            },
            copy() {
                useClipboard().copy(this.valueToCopy);
                useNotification().success(__('common:text_copied_successfully'));
            },
            undo() {
                this.reset();
            },
            reset() {
                _forEach(this.inputRefs, ref => {
                    this.refs[ref].undo();
                });
                this.editing = false;
                this.$nextTick(() => {
                    this.$emit('cancel');
                });
            },
        },
    };
</script>

<style scoped>
    .inplace-editor-data-wrapper {
        transition: background-color 200ms;
        @apply tw-relative tw-cursor-pointer;

        &:before {
            content: '';
            @apply tw-absolute tw-inset-0 tw--mx-2 tw--my-1 tw-rounded-lg;
        }

        &:hover {
            &:before {
                background-color: rgba(154, 171, 193, 0.15);
            }

            @apply tw-border-transparent;

            .inplace-editor-edit-btn,
            .inplace-editor-copy-btn {
                @apply tw-top-0 tw--mr-4 tw--mt-4;
                @apply tw-h-6 tw-w-6 tw-bg-blue-500 tw-text-sm tw-text-blue-100;
                @apply tw-shadow;
                transform: scale(1.1);
                animation: inplace-editor-animation-zoom 0.5s 0s;
                transition: all 200ms ease-in-out;
            }

            .inplace-editor-edit-btn {
                @apply tw-right-0;
            }

            .inplace-editor-copy-btn {
                @apply tw-right-8;
            }
        }
    }

    .inplace-editor-edit-btn,
    .inplace-editor-copy-btn {
        @apply tw-absolute tw-z-0;
        @apply tw-rounded-full tw-bg-gray-200;
        @apply tw-h-5 tw-w-5;
        @apply tw-text-[9px] tw-font-semibold tw-text-gray-600;
        transform: scale(1);
        transition: all 200ms ease-in-out;

        &:hover {
            @apply tw-text-white tw-shadow-md;
        }
    }

    .inplace-editor-edit-btn {
        @apply tw-right-0;
    }

    .inplace-editor-copy-btn {
        @apply tw-right-6;
    }

    .inplace-editor-save-btn {
        @apply tw-absolute tw-right-0 tw-top-0 tw-z-0 tw--mt-10 tw-cursor-pointer;
        @apply tw-rounded-full tw-border-transparent tw-bg-green-500;
        @apply tw-h-8 tw-w-16;
        @apply tw-text-sm tw-font-semibold tw-text-green-100;
        @apply tw-shadow;
        transform: scale(1);
        transition: transform 200ms ease-in-out;

        &:hover {
            transform: scale(1.1);
            animation: inplace-editor-animation-zoom 0.5s 0s;
            transition: transform 200ms ease-in-out;
            @apply tw-text-white tw-shadow-md;
        }
    }

    .inplace-editor-undo-btn {
        @apply tw-cursor-pointer;
        @apply tw-rounded-full tw-bg-white;
        @apply tw-h-6 tw-w-6;
        @apply tw-text-sm tw-font-semibold tw-text-gray-300;
        @apply tw-shadow;
        transform: scale(1);
        transition: transform 200ms ease-in-out;

        &:hover {
            transform: scale(1.1);
            animation: inplace-editor-animation-zoom 0.5s 0s;
            transition: transform 200ms ease-in-out;
            @apply tw-text-white tw-shadow-md;
        }
    }

    @keyframes inplace-editor-animation-zoom {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1.1);
        }
    }
</style>
