<template>
    <div class="tw-relative tw-h-full tw-rounded-md tw-bg-gray-50">
        <div
            v-if="loading"
            class="tw-h-full"
        >
            <div class="tw-w-full tw-bg-gray-400" />
        </div>
        <template
            v-for="(segment, i) in segments"
            v-else
        >
            <Segment
                :key="i"
                :class="{'tw-rounded-md odd:tw-mr-[0.5px] even:tw-ml-[0.5px]': halfdayDisplay}"
                :hide-tooltip="hideTooltip"
                :segment="segment"
                :styling-segment-fn="stylingSegmentFn"
                :tooltip-text-fn="tooltipTextFn"
            />
        </template>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import Segment from '@/modules/planning/components/simulation/Summary/Segment.vue';

    export default defineComponent({
        components: {Segment},
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            segments: {
                type: Array as PropType<{count: number}[]>,
                default: () => [],
            },
            stylingSegmentFn: {
                type: Function as PropType<(segment) => object>,
                default: () => ({}),
            },
            tooltipTextFn: {
                type: Function as PropType<(segment) => string>,
                default: undefined,
            },
            hideTooltip: {
                type: Boolean,
                default: false,
            },
            halfdayDisplay: {
                type: Boolean,
                default: false,
            },
        },
    });
</script>
