<script setup lang="ts">
    import FeesInput from '@/modules/cashier/components/settings/RulesCards/FeesInput.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import {getNumericParserContractPresetCurrency} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import i18next from 'i18next';

    defineProps({
        nursery: {
            type: Object,
            required: true,
        },
        fee: {
            type: Object,
            required: true,
        },
    });

    const parserOptionsCurrency = getNumericParserContractPresetCurrency(i18next.language);
    parserOptionsCurrency.allowNegative = true;

    const customFeesPeriodOptions = [
        {
            text: __('common:per_day'),
            value: 'day',
        },
        {
            text: __('common:per_month'),
            value: 'month',
        },
    ];
</script>

<template>
    <div>
        <div class="tw-mb-4 tw-grid tw-grid-cols-4 tw-gap-4">
            <FeesInput
                class="tw-col-span-full md:tw-col-span-2"
                :fee="fee"
                @removeFees="$emit('removeFees', fee)"
            />
        </div>
        <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-4">
            <MCurrencyInput
                v-model="fee.amount"
                :currency-symbol="nursery.currency"
                :parser-options="parserOptionsCurrency"
            />
            <CFormSelect
                v-model="fee.period"
                :options="customFeesPeriodOptions"
                :search-bar="false"
            />
        </div>
    </div>
</template>
