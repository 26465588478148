<script setup lang="ts">
    import {ref, watch} from 'vue';

    const emit = defineEmits<{
        (e: 'input', value: string): void;
    }>();

    const props = withDefaults(
        defineProps<{
            value: string;
            hasError?: boolean;
            readonly?: boolean;
            buttonClass?: string;
            maxHeight?: string;
        }>(),
        {
            hasError: false,
            readonly: false,
            buttonClass: '',
            maxHeight: '256px',
        }
    );

    const selected = ref<string>(props.value);

    const availableTimezones = Intl.supportedValuesOf('timeZone').map((timeZone: string) => ({
        id: timeZone,
        value: timeZone,
    }));

    watch(
        () => props.value,
        value => {
            if (value) {
                selected.value = props.value;
            }
        }
    );

    watch(selected, newSelected => emit('input', newSelected));
</script>
<template>
    <CFormSelect
        v-model="selected"
        :button-class="buttonClass"
        :fallback-text="__('components:select_timezone')"
        funnel
        :has-error="hasError"
        :max-height="maxHeight"
        :options="availableTimezones"
        text-path="value"
        value-path="id"
    />
</template>
