<template>
    <div>
        <MMenu>
            <MMenuButton>
                <MButton icon-ellipsis />
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('billing_customer:update_customer_account')"
                    @click="toggleUpdateModal(true)"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-pen"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :label="__('billing_customer:merge_customer_account_dots')"
                    @click="mergeModal.show"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-compress-alt"
                        />
                    </template>
                </MMenuItem>
                <MMenuDivider />
                <MMenuItem
                    :label="__('billing_customer:delete_customer_account_dots')"
                    variant="danger"
                    @click="toggleDeleteModal(true)"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
        <CustomerModalUpdate
            v-if="isUpdateModalVisible"
            :customer-model="customerModel"
            @closed="toggleUpdateModal(false)"
        />
        <CustomerModalDelete
            v-if="isDeleteModalVisible"
            ref="deleteModal"
            :customer-model="customerModel"
            @deleted="onCustomerDeleted"
            @hidden="toggleDeleteModal(false)"
        />
    </div>
</template>

<script lang="ts">
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import CustomerModalDelete from '@/modules/cashier/components/core/customer/pages/CustomerModalDelete.vue';
    import CustomerModalMerge from '@/modules/cashier/components/core/customer/pages/CustomerModalMerge.vue';
    import CustomerModalUpdate from '@/modules/cashier/components/core/customer/pages/CustomerModalUpdate.vue';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import {useToggle} from '@vueuse/core';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            CustomerModalUpdate,
            CustomerModalDelete,
        },
        props: {
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
        },
        setup(props, {emit}) {
            const [isUpdateModalVisible, toggleUpdateModal] = useToggle(false);
            const [isDeleteModalVisible, toggleDeleteModal] = useToggle(false);

            function onCustomerDeleted() {
                useNotification().success(__('billing_customer:customer_account_deleted'));
                emit('deleted', props.customerModel);
            }

            const mergeModal = useModal({
                component: CustomerModalMerge,
                props: {
                    customerModel: props.customerModel,
                },
            });

            return {
                onCustomerDeleted,
                isUpdateModalVisible,
                isDeleteModalVisible,
                toggleUpdateModal,
                toggleDeleteModal,
                mergeModal,
            };
        },
    });
</script>
