<template>
    <CText v-if="readonly">
        {{ readableCountry }}
    </CText>
    <CFormSelect
        v-else
        v-model="selected"
        :button-class="buttonClass"
        :fallback-text="__('components:select_country')"
        funnel
        :has-error="hasError"
        :max-height="maxHeight"
        :options="availableCountries"
        text-path="value"
        value-path="id"
    />
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import countries from '@/modules/legacy/contents/countries.json';

    export default defineComponent({
        props: {
            value: {
                type: String,
                default: undefined,
            },
            hasError: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            buttonClass: {
                type: String,
                default: '',
            },
            maxHeight: {
                type: String,
                default: '256px',
            },
            countriesFilter: {
                type: Function,
                default: () => countries,
            },
        },
        setup(props, {emit}) {
            const selected: Ref<string | undefined> = ref();

            const readableCountry = computed(() => {
                const country = countries.find(item => item.id === props.value);

                return country ? country.value : null;
            });

            const availableCountries = computed(() => {
                return countries.filter(props.countriesFilter as any);
            });

            watch(
                () => props.value,
                value => {
                    if (value) {
                        selected.value = props.value;
                    }
                },
                {immediate: true}
            );
            watch(selected, value => emit('input', value));

            return {
                availableCountries,
                selected,
                readableCountry,
            };
        },
    });
</script>
