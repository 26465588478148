<template>
    <div>
        <template v-for="medicalActionModel in sortedMedicalActions">
            <div
                :key="medicalActionModel.getKey()"
                class="tw-mx-auto tw-min-w-36 tw-p-2"
            >
                <CTooltip v-if="can('update', 'kids_health')">
                    <CCenter
                        :class="pillClass(medicalActionModel)"
                        @click="editMedicalAction(medicalActionModel)"
                    >
                        <template
                            v-if="medicalActionModel.attributes.done_at || medicalActionModel.attributes.scheduled_at"
                        >
                            <CHStack
                                align="center"
                                distribute="center"
                                gap="2"
                            >
                                <font-awesome-icon
                                    v-if="medicalActionModel.attributes.done_at"
                                    icon="fa-solid fa-circle-check"
                                />
                                <font-awesome-icon
                                    v-else-if="medicalActionModel.isLate"
                                    icon="fa-regular fa-clock"
                                />
                                <font-awesome-icon
                                    v-else
                                    icon="fa-solid fa-right"
                                />
                                <span>{{
                                    $formatDate(
                                        medicalActionModel.attributes.done_at
                                            ? medicalActionModel.attributes.done_at
                                            : medicalActionModel.attributes.scheduled_at
                                    )
                                }}</span>
                            </CHStack>
                        </template>
                        <template v-else>
                            {{ __('health:date_unknown') }}
                        </template>
                    </CCenter>
                    <template #content>
                        <template v-if="medicalActionModel.isLate">
                            {{ __('health:late_click_to_edit') }}
                        </template>
                        <template v-else>
                            {{ __('health:click_to_edit') }}
                        </template>
                    </template>
                </CTooltip>
                <template v-else>
                    <CCenter
                        :class="pillClass(medicalActionModel)"
                        @click="editMedicalAction(medicalActionModel)"
                    >
                        <template v-if="medicalActionModel.attributes.done_at">
                            {{ $formatDate(medicalActionModel.attributes.done_at) }}
                        </template>
                        <template v-else-if="medicalActionModel.attributes.scheduled_at">
                            <FontAwesomeIcon
                                class="tw-mb-1 tw-mr-2"
                                icon="fa-solid fa-alarm-clock"
                            />
                            {{ $formatDate(medicalActionModel.attributes.scheduled_at) }}
                        </template>
                        <template v-else>
                            {{ __('health:date_unknown') }}
                        </template>
                    </CCenter>
                </template>
            </div>
        </template>
        <div
            v-if="can('update', 'kids_health')"
            class="tw-mx-auto tw-min-w-36 tw-p-2"
        >
            <CCenter
                class="pill-item new clickable"
                @click="newMedicalAction"
            >
                <font-awesome-icon icon="fa-solid fa-plus" />
            </CCenter>
        </div>
        <CModal
            v-if="isModalOpen"
            hide-footer
            visible
            @hidden="setIsModalOpen(false)"
        >
            <template #header>
                <CCardHeroTitle class="tw-mx-auto">
                    {{ __('health:update_vaccine') }}
                </CCardHeroTitle>
            </template>
            <MedicalActionForm
                :action="selectedMedicalAction"
                @canceled="setIsModalOpen(false)"
                @deleted="rejectMedicalAction"
                @saved="onMedicalActionSaved"
            />
        </CModal>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type MedicalTreatmentModel from '@/modules/health/models/MedicalTreatmentModel';
    import useAbility from '@/modules/app/composables/useAbility';
    import MedicalActionModel from '@/modules/health/models/MedicalActionModel';
    import MedicalActionForm from '@/modules/health/components/MedicalActionForm.vue';
    import moment from 'moment';
    import useManager from '@/modules/app/composables/useManager';
    import type KidModel from '@/modules/family/models/KidModel';
    import {useToggle} from '@vueuse/core';

    export default defineComponent({
        components: {MedicalActionForm},
        props: {
            treatment: {
                type: Object as PropType<MedicalTreatmentModel>,
                required: true,
            },
            kid: {
                type: Object as PropType<KidModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {activeOrganization} = useManager();

            const {can} = useAbility();

            const [isModalOpen, setIsModalOpen] = useToggle(false);

            const selectedMedicalAction = ref<MedicalActionModel>();

            // Sort MedicalAction by doneAt then ScheduledAt
            const sortedMedicalActions = computed(() =>
                props.treatment
                    .medicalActions()
                    .value()
                    .sort((a, b) => {
                        const aDate = a.attributes.done_at ?? a.attributes.scheduled_at;
                        const bDate = b.attributes.done_at ?? b.attributes.scheduled_at;

                        if (!aDate) {
                            // Handle cases where no date has been set
                            return 1;
                        }

                        return moment(aDate) < moment(bDate) ? -1 : 1;
                    })
            );

            /**
             * Make a new MedicalAction.
             */
            function newMedicalAction() {
                const model = new MedicalActionModel();
                model.attributes.account_id = activeOrganization.value.attributes.account_id;
                model.medicalTreatment().associate(props.treatment);
                model.kid().associate(props.kid);

                selectedMedicalAction.value = model;
                setIsModalOpen(true);
            }

            function editMedicalAction(medicalAction: MedicalActionModel) {
                if (can('update', 'kids_health')) {
                    setIsModalOpen(true);
                    selectedMedicalAction.value = medicalAction.clone();
                }
            }

            function rejectMedicalAction() {
                // Filter the shot from the vaccine prop shots list
                props.treatment.medicalActions().mutate(value => {
                    return value.reject(action => action.getKey() === selectedMedicalAction.value?.getKey());
                });

                setIsModalOpen(false);
                emit('deleted:action');
            }

            function onMedicalActionSaved(model: MedicalActionModel) {
                // Add the MedicalAction to list if not already present ont the treatment.
                const exists = props.treatment
                    .medicalActions()
                    .value()
                    .first(action => action.getKey() === model.getKey());
                if (!exists) {
                    props.treatment.medicalActions().value().push(model);
                }

                setIsModalOpen(false);
                emit('saved:action');
            }

            function pillClass(model: MedicalActionModel) {
                const classes: string[] = [];

                classes.push('pill-item');

                if (model.attributes.done_at) {
                    classes.push('done');
                } else if (model.isLate) {
                    classes.push('late');
                } else {
                    classes.push('pending');
                }

                if (can('update', 'kids_health')) {
                    classes.push('clickable');
                }

                return classes;
            }

            return {
                isModalOpen,
                setIsModalOpen,
                sortedMedicalActions,
                can,
                editMedicalAction,
                selectedMedicalAction,
                rejectMedicalAction,
                pillClass,
                newMedicalAction,
                onMedicalActionSaved,
            };
        },
    });
</script>

<style scoped>
    .pill-item {
        @apply tw-h-full tw-w-full tw-rounded-md tw-px-2 tw-py-0.5 tw-font-semibold;

        &.done {
            @apply tw-border tw-border-success-500 tw-bg-success-500 tw-text-white;
        }

        &.late {
            @apply tw-border tw-border-dashed tw-border-warning-500 tw-bg-warning-100 tw-text-warning-700;
        }

        &.pending {
            @apply tw-border tw-border-dashed tw-border-gray-500 tw-bg-gray-300/40 tw-text-gray-700;
        }

        &.new {
            @apply tw-border tw-border-dashed tw-border-gray-500 tw-bg-gray-300/40 tw-text-gray-700;
        }

        &.clickable {
            @apply tw-cursor-pointer tw-duration-200 tw-ease-in-out hover:tw-scale-105;
        }
    }
</style>
