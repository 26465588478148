<template>
    <div>
        <div
            v-show="notification.data.content"
            class="tw-rounded-lg tw-border tw-border-gray-200 tw-px-2 tw-py-1 tw-text-sm"
        >
            <div class="tw-flex tw-font-semibold tw-text-gray-600">
                <div>
                    <FontAwesomeIcon
                        v-if="notification.referenceable && notification.referenceable.loading"
                        class="tw-mr-1"
                        icon="fa-solid fa-spinner"
                        spin
                    />
                    {{ notification.data.family_first_name + ' ' + notification.data.family_last_name }}
                </div>
                <MTooltip
                    v-if="notification.referenceable && notification.referenceable.manager_only"
                    class="tw-ml-auto tw-pl-2"
                    :label="__('notification:message_sent_to_management_only')"
                >
                    <span class="tw-whitespace-nowrap tw-text-right tw-text-sm">
                        <FontAwesomeIcon icon="fa-regular fa-lock-alt" />
                        {{ __('common:direction') }}
                    </span>
                </MTooltip>
            </div>
            <div class="tw-mt-3 tw-text-gray-700">
                {{ notification.data.content }}
            </div>
            <AttachedFiles
                v-if="
                    notification.referenceable &&
                    notification.referenceable.documents &&
                    notification.referenceable.documents.length
                "
                class="tw-mt-2 tw-border-t tw-border-gray-200 tw-pt-1"
                :files="notification.referenceable.documents"
                :member-id="familyMemberId"
                :portal="false"
            />
        </div>

        <div
            v-if="notification.state === 'pending'"
            class="tw-mt-2"
        >
            <router-link
                class="btn btn-light btn-sm"
                :to="{
                    name: 'familyMembers.show',
                    params: {nursery: notification.data.nursery_id, familyMember: notification.data.family_member_id},
                }"
                @click.native="handlerEmit"
            >
                {{ __('notification:respond_to_message') }}
            </router-link>
        </div>
        <div
            v-else-if="notification.state === 'answered'"
            class="tw-mt-2 tw-text-sm"
        >
            <router-link
                :to="{
                    name: 'familyMembers.show',
                    params: {nursery: notification.data.nursery_id, familyMember: notification.data.family_member_id},
                }"
            >
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fa-solid fa-check"
                />
                {{ __('notification:response_sended') }}
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from 'vue';
    import moment from 'moment';
    import AttachedFiles from '@/modules/document/components/organisms/AttachedFiles.vue';
    import MemberModel from '@/modules/family/models/MemberModel';
    import useNotificationStore from '@/modules/app/composables/useNotificationStore';
    import useMetrics from '@/modules/app/composables/useRum';
    import {Lang} from '@meekohq/lumos';

    export default defineComponent({
        components: {
            AttachedFiles,
        },
        props: {
            notification: {type: Object, required: true},
        },
        setup(props, {emit}) {
            const {setMessagePending} = useNotificationStore();
            const familyMemberId = ref(props.notification.data.family_member_id);
            const {addAction} = useMetrics();

            onMounted(async () => {
                // Replace legacy_id by new uuid
                if (Lang.isNumeric(familyMemberId.value)) {
                    const member = await MemberModel.query().where('legacy_id', familyMemberId.value).first();

                    if (member) {
                        familyMemberId.value = member.getKey();

                        // Add metric to track legacy id usage
                        addAction('M_Notification_Message_Legacy_Id');
                    }
                }
            });

            function handlerEmit() {
                emit('closeModal');

                if (!props.notification.read_at) {
                    props.notification.read_at = moment().unix();
                }

                setMessagePending(props.notification);
            }

            return {
                familyMemberId,
                handlerEmit,
            };
        },
    });
</script>
