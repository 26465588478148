<script setup lang="ts">
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import {forEach, head} from 'lodash-es';
    import CreateKidModal from '@/modules/family/components/kid/CreateKidModal.vue';
    import KidModel from '@/modules/family/models/KidModel';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import useManager from '@/modules/app/composables/useManager';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import type {PropType} from 'vue';
    import {computed, ref, watch} from 'vue';
    import useKidFiltersStore from '@/modules/family/composables/kid/useKidFiltersStore';
    import useAbility from '@/modules/app/composables/useAbility';
    import {until} from '@vueuse/core/index';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import KidsListHeader from '@/pages/nurseries/[id]/family/kids/KidsListHeader.vue';
    import KidsListItem from '@/modules/family/components/kid/Index/KidsListItem.vue';
    import KidsListFilters from '@/modules/family/components/kid/Index/KidsListFilters.vue';
    import useTagLegacyModelBridge from '@/modules/app/composables/useTagLegacyModelBridge';
    import type TagModel from '@/modules/tag/models/TagModel';
    import _debounce from 'lodash-es/debounce';

    const {nursery} = defineProps({
        nursery: {
            type: Object as PropType<{id: string; groups: []}>,
            required: true,
        },
    });

    const {activeOrganization} = useManager();
    const {can} = useAbility();

    const loading = ref(true);
    const paginator = ref<LengthAwarePaginator<any> | undefined>();
    const showCreateKidModal = ref(false);
    const tasksManager = useGetTasks({
        constrainToResourceModel: KidModel,
    });
    const tasks = ref([]);
    const tasksByKidId = ref();
    const kidHasGroup = computed(() => {
        return !!nursery.groups.length;
    });

    const filtersStore = useKidFiltersStore({
        contractStates: {},
        selectedGroup: {},
        tags: {},
        withoutTag: {},
        page: {},
    });

    const {
        contractStatesFilter,
        selectedGroupFilter,
        tagsFilter,
        withoutTagFilter,
        reset: resetFilters,
        activeCount,
        pageFilter,
    } = filtersStore;

    until(filtersStore.isReady)
        .toBe(true)
        .then(async () => {
            if (!nursery.groups || nursery.groups.length === 0) {
                selectedGroupFilter.data.value = '';
            }

            watch(tasks, () => (tasksByKidId.value = tasksManager.groupTasks()));

            watch(pageFilter.data, getKidsDebounced, {immediate: true});

            watch(
                filtersStore.allFilters,
                () => {
                    pageFilter.data.value = 1;
                    getKidsDebounced();
                },
                {deep: true}
            );
        });

    function getKids() {
        loading.value = true;

        const tags = useTagLegacyModelBridge().tagsModelsAndWithoutTagsToLegacyFilter(
            tagsFilter.data.value as TagModel[],
            withoutTagFilter.data.value
        );

        const params: Record<string, any> = {
            nursery_id: activeOrganization.value.getKey(),
            page: pageFilter.data.value,
            per_page: 20,
            tags,
            order_by: window.localStorage.getItem('display:orderBy')
                ? window.localStorage.getItem('display:orderBy')
                : 'first_name',
        };

        if (contractStatesFilter.data.value.length) {
            params.contract_states = contractStatesFilter.data.value;
        }

        if (selectedGroupFilter.data.value) {
            params.group_id = selectedGroupFilter.data.value;
        }

        useApi()
            .legacy.get(route('kids.index'), {
                params,
            })
            .then(async response => {
                const kidsLegaciesAndModels = await getKidsLegaciesAndModels(response.data.data);

                paginator.value = new LengthAwarePaginator(
                    kidsLegaciesAndModels,
                    parseInt(response.data.total),
                    parseInt(response.data.per_page),
                    parseInt(response.data.current_page),
                    parseInt(response.data.last_page)
                );

                tasks.value = await tasksManager.getTasks(response.data.data.map((kid: {id: string}) => kid.id));
            })
            .catch(error => {
                if (error?.response?.status === 422) {
                    forEach(error.response.data.errors, function (value) {
                        useNotification().error(head(value) as string);
                    });
                } else {
                    useNotification().error(error);
                }
            })
            .finally(() => (loading.value = false));
    }

    const getKidsDebounced = _debounce(function () {
        getKids();
    }, 500);

    async function getKidsLegaciesAndModels(kidsLegacies: {id: string}[]) {
        let kidsModels: KidModel[] = [];

        if (kidsLegacies.length) {
            kidsModels = (
                await KidModel.query()
                    .whereIn(
                        'id',
                        kidsLegacies.map(legacy => legacy.id)
                    )
                    .with(new KidModel().tags())
                    .get()
            ).all<KidModel>();
        }

        return kidsLegacies.map(legacy => {
            const model = kidsModels.find(kidModel => kidModel.getKey() === legacy.id);

            return {
                legacy,
                model,
            };
        });
    }

    /**
     * Set the current page to the given page.
     * @param page
     */
    function setPage(page: number) {
        pageFilter.data.value = page;
    }

    function kidRouterLinkCallback(kid: {legacy: {id: string}}) {
        return {name: 'kids.show', params: {nursery: activeOrganization.value.getKey(), kid: kid.legacy.id}};
    }
</script>

<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="family">
                {{ __('app:menu.kids') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MButton
                v-if="can('create', 'kids_details')"
                variant="primary"
                @click="showCreateKidModal = true"
            >
                <template #left-icons>
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </template>
                {{ __('family_kid:add_kid') }}
            </MButton>
            <CreateKidModal
                v-if="showCreateKidModal"
                :organization-model="activeOrganization"
                :visible="true"
                @created="$router.push({name: 'kids.show', params: {nursery: nursery.id, kid: $event.id}})"
                @hidden="showCreateKidModal = false"
            />
        </template>
        <template #content>
            <MagicIndex
                v-if="filtersStore.isReady.value"
                :filter-count="activeCount"
                :loading="loading"
                :paginator="paginator"
                :router-link-fn="kidRouterLinkCallback"
                variant="family"
                @change="setPage($event)"
                @reset-filters="resetFilters"
            >
                <template #filters>
                    <KidsListFilters
                        :filters="filtersStore"
                        :nursery="nursery"
                    />
                </template>
                <template #header>
                    <KidsListHeader :show-group="kidHasGroup" />
                </template>
                <template #item="{item}">
                    <KidsListItem
                        :kid="item.legacy"
                        :kid-model="item.model"
                        :nursery="nursery"
                        :show-group="kidHasGroup"
                        :tasks="tasksByKidId[item.legacy.id]"
                    />
                </template>
                <template #empty-list>
                    {{ __('common:empty_result') }}
                </template>
                <template #empty-list-action>
                    {{ __('common:try_modifying_filters_or_adding_data') }}
                </template>
            </MagicIndex>
        </template>
    </SimplePageTemplate>
</template>
