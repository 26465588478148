import {Model, ServiceProvider} from '@meekohq/lumos';
import IconModel from '@/modules/app/models/IconModel';
import {loadToDom, loadToGlobal} from '@/modules/app/utils/loadZiggyRoute';
import __ from '@/modules/app/utils/i18n-facade';
import swal from 'sweetalert2/dist/sweetalert2';

export default class AppServiceProvider extends ServiceProvider {
    public async boot() {
        // Wait for route file loaded
        if (this.app.isTest) {
            await loadToGlobal();
        } else {
            await loadToDom();
        }

        Model.preventLazyLoading(this.app.isDevelopment);

        this.loadModelsFrom([IconModel]);

        if (!this.app.isTest) {
            swal.setDefaults({
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonClass: 'btn btn-danger tw-mr-2',
                cancelButtonClass: 'btn btn-light',
                confirmButtonText: __('common:actions.delete'),
                cancelButtonText: __('common:actions.cancel'),
                buttonsStyling: false,
                allowEnterKey: false,
                type: 'error',
            });
        }
    }

    public register() {
        //
    }
}
