<script setup lang="ts">
    import FeesInput from '@/modules/cashier/components/settings/RulesCards/FeesInput.vue';
    import {Epoch} from '@meekohq/lumos';
    import {computed} from 'vue';
    import {getNumericParserContractPresetCurrency} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import i18next from 'i18next';

    const props = defineProps<{
        nursery: any;
        fee: any;
    }>();

    const parserOptionsCurrency = getNumericParserContractPresetCurrency(i18next.language);
    parserOptionsCurrency.allowNegative = true;

    const epochHour = computed(() => {
        if (!props.fee.hour) {
            return undefined;
        }

        let hour = Epoch.parse(props.fee.hour, 'HH:mm:ss');
        if (hour && hour.isValid) {
            return hour;
        }

        hour = Epoch.parse(props.fee.hour, 'HH:mm');
        if (hour && hour.isValid) {
            return hour;
        }

        return undefined;
    });

    function setHour(value: Epoch) {
        props.fee.hour = value?.toFormat('HH:mm:ss') ?? null;
    }
</script>

<template>
    <div>
        <div class="tw-mb-4 tw-grid tw-grid-cols-4 tw-gap-4">
            <FeesInput
                class="tw-col-span-full md:tw-col-span-2"
                :fee="fee"
                @removeFees="$emit('removeFees', fee)"
            />
        </div>
        <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-4">
            <CFormGroup>
                <CLabel>
                    {{ __('billing_setting:fees_milk_or_mixed') }}
                </CLabel>
                <MCurrencyInput
                    v-model="fee.amount.milk"
                    :currency-symbol="nursery.currency"
                    :parser-options="parserOptionsCurrency"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    {{ __('billing_setting:fees_crushed_or_mixed') }}
                </CLabel>
                <MCurrencyInput
                    v-model="fee.amount.mix"
                    :currency-symbol="nursery.currency"
                    :parser-options="parserOptionsCurrency"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    {{ __('common:meal_types.normal') }}
                </CLabel>
                <MCurrencyInput
                    v-model="fee.amount.normal"
                    :currency-symbol="nursery.currency"
                    :parser-options="parserOptionsCurrency"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    {{ __('billing_setting:hour_of_bill') }}
                </CLabel>
                <MTooltip
                    class="tw-w-full"
                    :label="__('billing_setting:hour_to_bill_meal')"
                >
                    <MTimePicker
                        allow-undefined
                        class="tw-w-full"
                        :value="epochHour"
                        @input="setHour"
                    />
                </MTooltip>
            </CFormGroup>
        </div>
    </div>
</template>
