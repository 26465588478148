<template>
    <MBox class="TagsManagerListItem">
        <template v-if="tagsWithCategory(category.value).count()">
            <CListRow
                v-for="(tag, j) in tagsWithCategory(category.value)"
                :key="'tag' + j + 'cat' + index"
                class="TagsManagerListItem__container"
                :hover="false"
            >
                <div class="TagsManagerListItem__header">
                    <TagPill :tag="tag.attributes" />
                    <div class="tw-truncate">
                        {{ tag.attributes.name }}
                    </div>
                </div>
                <MButton
                    v-if="can('update', 'tags')"
                    class="tw-shrink-0"
                    variant="link"
                    @click="$emit('copyTagAndShowModal', tag)"
                >
                    <FontAwesomeIcon icon="fa-solid fa-edit" />
                </MButton>
            </CListRow>
        </template>
        <div
            v-else
            class="TagsManagerListItem__empty"
        >
            <h4 class="tw-text-gray-500">
                {{ __('tag:there_is_no_tag') }}
            </h4>
        </div>
    </MBox>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import TagPill from '@/modules/tag/components/TagPill.vue';
    import useAbility from '@/modules/app/composables/useAbility';

    export default defineComponent({
        components: {
            TagPill,
        },
        props: {
            category: {
                type: Object,
                required: true,
            },
            tagsWithCategory: {
                type: Function,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
        },
        setup() {
            const {can} = useAbility();

            return {
                can,
            };
        },
    });
</script>

<style scoped>
    .TagsManagerListItem {
        @apply tw-h-full tw-max-h-60 tw-overflow-auto tw-p-4;
    }

    .TagsManagerListItem__container {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2;
    }

    .TagsManagerListItem__header {
        @apply tw-flex tw-min-w-0 tw-flex-row tw-items-center tw-gap-2;
    }

    .TagsManagerListItem__empty {
        @apply tw-flex tw-h-full tw-items-center tw-justify-center tw-text-center;
    }
</style>
