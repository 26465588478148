<template>
    <CDisclosure
        :open="false"
        :title="__('hr_contract:trial_periods')"
    >
        <template #title>
            <div class="tw-flex tw-items-center">
                {{ __('hr_contract:trial_periods') }}
                <MAlertBadge
                    class="tw-ml-2"
                    :visible="errorHandler.has('trial_periods')"
                />
            </div>
        </template>
        <template #default>
            <CVStack gap="2">
                <CList
                    v-if="trialPeriodsToAdd.isNotEmpty()"
                    :striped="true"
                >
                    <CListRow
                        v-for="trialPeriod in trialPeriodsToAdd.all()"
                        :key="trialPeriod.attributes.id"
                    >
                        <div class="tw-flex tw-flex-wrap tw-items-center">
                            <CText class="tw-w-1/3">
                                <span class="tw-font-semibold">
                                    {{ __('common:end_colon') }}
                                </span>
                                {{
                                    parse(trialPeriod.attributes.ended_at, 'y-MM-dd').toLocaleString(
                                        Epoch.presets.DATE_SHORT
                                    )
                                }}
                            </CText>
                            <CText class="tw-w-1/3">
                                <span class="tw-font-semibold">
                                    {{ __('common:note_colon') }}
                                </span>
                                {{ trialPeriod.attributes.explanation || __('common:n_a') }}
                            </CText>
                            <CHStack
                                class="tw-w-1/3"
                                distribute="end"
                            >
                                <CTooltip
                                    v-if="!disableEditing"
                                    class="zoom-small"
                                >
                                    <template #content>
                                        {{ __('common:actions.delete') }}
                                    </template>
                                    <MButton
                                        round
                                        size="xs"
                                        variant="danger"
                                        @click="deletePeriod(trialPeriod)"
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-trash" />
                                    </MButton>
                                </CTooltip>
                            </CHStack>
                        </div>
                    </CListRow>
                </CList>

                <CFormErrorMessageList
                    v-if="errorHandler.has('trial_periods')"
                    :errors="errorHandler.get('trial_periods')"
                />
                <CFormThreeColumns>
                    <CFormGroup>
                        <CLabel>{{ __('common:end') }}</CLabel>
                        <CFormDatepicker
                            v-model="newTrialPeriod.attributes.ended_at"
                            :disabled="disableEditing"
                            format="YYYY-MM-DD"
                        />
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('common:note_one') }}</CLabel>
                        <CFormTextArea
                            v-model="newTrialPeriod.attributes.explanation"
                            :disabled="disableEditing"
                            :placeholder="__('common:note_one')"
                            rows="1"
                        />
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>&nbsp;</CLabel>
                        <MButton
                            class="tw-w-full"
                            :disabled="hasInvalidNewTrialPeriod"
                            variant="primary"
                            @click="addPeriod(newTrialPeriod)"
                        >
                            {{ __('hr_contract:add_trial_periods') }}
                        </MButton>
                    </CFormGroup>
                </CFormThreeColumns>
                <CFormErrorMessageList
                    v-if="newTrialPeriodErrors.isNotEmpty()"
                    :errors="newTrialPeriodErrors"
                />
            </CVStack>
        </template>
    </CDisclosure>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import {collect, Epoch} from '@meekohq/lumos';
    import type ContractModel from '@/modules/human-resources/models/ContractModel';
    import ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
    import useManager from '@/modules/app/composables/useManager';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import type useError from '@/modules/app/composables/useError';
    import {TrialPeriodEndedBetweenContractDatesRule} from '@/modules/human-resources/contract/domain/rules/TrialPeriodEndedBetweenContractDatesRule';
    import __ from '@/modules/app/utils/i18n-facade';
    import {isNil} from 'lodash-es';
    import MAlertBadge from '@/modules/meeko-ui/components/MAlertBadge.vue';

    export default defineComponent({
        name: 'ContractFormTrialPeriod',
        components: {MAlertBadge},
        props: {
            trialPeriods: {
                type: Object as PropType<Collection<ContractTrialPeriodModel>>,
                required: true,
            },
            contract: {
                type: Object as PropType<ContractModel>,
                required: true,
            },
            disableEditing: {
                type: Boolean,
                required: true,
            },
            errorHandler: {
                type: Object as PropType<ReturnType<typeof useError>>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {parse} = useEpoch();
            const {legacyAccount} = useManager();
            const trialPeriodsToAdd = computed(() => props.trialPeriods.where('markedForDeletion', false));
            const newTrialPeriod = ref(initPeriod()) as Ref<ContractTrialPeriodModel>;

            // Check if the new trial period is invalid
            const hasInvalidNewTrialPeriod = computed(
                () => isNil(newTrialPeriod.value.attributes.ended_at) || newTrialPeriodErrors.value.isNotEmpty()
            );

            // Check if the new trial period has any errors
            const newTrialPeriodErrors = computed(() => {
                const errors: any[] = [];
                if (!new TrialPeriodEndedBetweenContractDatesRule().isValid(newTrialPeriod.value, props.contract)) {
                    errors.push({
                        message: __('hr_contract:errors.0x3115708F32'),
                        code: '0x3115708F32',
                        source: 'new_trial_period',
                    });
                }

                return collect(errors);
            });

            function initPeriod() {
                const newPeriod = new ContractTrialPeriodModel();
                newPeriod.attributes.account_id = legacyAccount.value.id.toString();

                return newPeriod;
            }

            const addPeriod = function (model: ContractTrialPeriodModel) {
                model.contract().associate(props.contract, false);
                emit('added', model);

                // Reset the newTrialPeriod to a new instance
                newTrialPeriod.value = initPeriod();
            };

            const deletePeriod = function (model: ContractTrialPeriodModel) {
                emit('deleted', model);
            };

            return {
                addPeriod,
                deletePeriod,
                Epoch,
                newTrialPeriod,
                parse,
                trialPeriodsToAdd,
                hasInvalidNewTrialPeriod,
                newTrialPeriodErrors,
            };
        },
    });
</script>
