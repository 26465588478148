<template>
    <fieldset class="CFormGroup">
        <slot />
    </fieldset>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style>
    .CFormGroup {
        > .MLabel {
            margin-bottom: theme(margin.1) !important;
        }

        > .CFormErrorMessageList {
            margin-top: theme(margin.2) !important;
        }

        > .CPopover {
            display: block !important;
        }
    }
</style>
