<template>
    <MMenu>
        <MMenuButton>
            <MButton icon-sort>
                <template
                    v-if="value"
                    #left-icons
                >
                    <FontAwesomeIcon
                        :class="selectedOption.classes"
                        :icon="selectedOption.icon"
                    />
                </template>
                <template v-if="value === 'pending'">
                    {{ __('common:pending') }}
                </template>
                <template v-else-if="value === 'accepted_with_draft_contract'">
                    {{ __('registration:registration_status_accepted_with_draft_contract') }}
                </template>
                <template v-else-if="value === 'rejected'">
                    {{ __('registration:registration_status_rejected') }}
                </template>
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem
                v-for="option in options"
                :key="option.state"
                @click="updateState(option.state)"
            >
                <template #icon>
                    <FontAwesomeIcon
                        :class="option.classes"
                        :icon="option.icon"
                    />
                </template>
                {{ option.text }}
            </MMenuItem>
        </MMenuItems>
    </MMenu>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            value: {
                type: String,
                required: true,
            },
        },
        setup(props, {emit}) {
            const options = [
                {
                    state: 'pending',
                    classes: 'tw-text-warning-500',
                    icon: 'fa-solid fa-clock',
                    text: __('common:pending'),
                },
                {
                    state: 'accepted_with_draft_contract',
                    classes: 'tw-text-success-500',
                    icon: 'fa-solid fa-check',
                    text: __('registration:registration_status_accepted_with_draft_contract'),
                },
                {
                    state: 'rejected',
                    classes: 'tw-text-danger-500',
                    icon: 'fa-solid fa-archive',
                    text: __('registration:registration_status_rejected'),
                },
            ];

            const selectedOption = computed(() => options.find(option => option.state === props.value));

            function updateState(state: string) {
                emit('input', state);
            }

            return {
                options,
                selectedOption,
                updateState,
            };
        },
    });
</script>
