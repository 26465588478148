<template>
    <ul class="tw-list-none">
        <li
            v-for="filteredOrganization in filteredUserOrganizations"
            :key="filteredOrganization.getKey()"
            class="tw-truncate"
            :class="
                documentOrganizationsIds.contains(filteredOrganization.getKey())
                    ? 'tw-text-primary-500'
                    : 'tw-text-gray-400'
            "
        >
            <FontAwesomeIcon
                class="tw-mr-1"
                :icon="
                    documentOrganizationsIds.contains(filteredOrganization.getKey())
                        ? 'fa-solid fa-eye'
                        : 'fa-solid fa-eye-slash'
                "
            />
            {{ filteredOrganization.attributes.name + ' - ' + filteredOrganization.attributes.address.city }}
        </li>
    </ul>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type DocumentModel from '@/modules/document/models/DocumentModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        props: {
            document: {
                type: Object as PropType<DocumentModel>,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            // Compute the user organizations that are not the current organization
            const filteredUserOrganizations = computed(() => {
                return props.user
                    .organizations()
                    .value()
                    .filter(item => item.getKey() !== props.organization.getKey());
            });

            const documentOrganizationsIds = computed(() => {
                return props.document
                    .organizations()
                    .value()
                    .map(organization => organization.getKey());
            });

            return {
                filteredUserOrganizations,
                documentOrganizationsIds,
            };
        },
    });
</script>
