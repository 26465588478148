<script setup lang="ts">
    import {computed, inject, ref} from 'vue';
    import {Epoch} from '@meekohq/lumos';
    import {keyMCalendarState} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
    import type {DateTimeSingle} from '@/modules/meeko-ui/types/DateTime';
    import type {NumericParserContractOptions} from '@/modules/core/infrastructure/NumericParserContract';
    import {getNumericParserContractLocaleOptions} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import i18next from 'i18next';

    const props = withDefaults(
        defineProps<{
            value: DateTimeSingle;
            disabled?: boolean;
            autofocus?: boolean;
        }>(),
        {
            disabled: false,
            autofocus: false,
        }
    );

    const emit = defineEmits<{
        (e: 'input', value: DateTimeSingle): void;
    }>();

    const state = inject(keyMCalendarState);
    if (!state) {
        throw new Error('keyMCalendarState must be provided');
    }

    const {allowUndefined, mode} = state;

    const hourEl = ref();
    const minuteEl = ref();

    const valueOrDefault = computed(() => props.value ?? Epoch.now());

    const timeNumberParserOptions: NumericParserContractOptions = {
        localeOptions: getNumericParserContractLocaleOptions(i18next.language),
        allowNegative: false,
    };

    function increaseMinutes() {
        const minutesToAdd = 5 - (valueOrDefault.value.minute % 5);

        emit('input', valueOrDefault.value.addMinutes(minutesToAdd));
    }

    function decreaseMinutes() {
        let minutesToSubtract = valueOrDefault.value.minute % 5;

        if (minutesToSubtract === 0) {
            minutesToSubtract = 5;
        }

        emit('input', valueOrDefault.value.subMinutes(minutesToSubtract));
    }
</script>

<template>
    <div class="tw-flex tw-flex-col tw-gap-1">
        <MButton
            v-if="allowUndefined && mode === 'time'"
            label="Laisser vide"
            size="sm"
            variant="link"
            @click="emit('input', undefined)"
        />
        <div class="tw-flex tw-gap-0.5">
            <div class="tw-flex tw-flex-col tw-gap-2">
                <div
                    class="MTimePicker__minute-button"
                    @click="emit('input', valueOrDefault.addHours(1))"
                >
                    +
                </div>
                <div>
                    <MNumberInput
                        ref="hourEl"
                        :allow-undefined="allowUndefined"
                        :autofocus="autofocus"
                        class="!tw-w-10 tw-text-center tw-font-semibold"
                        :max="23"
                        :min="0"
                        onfocus="this.select()"
                        :parser-options="timeNumberParserOptions"
                        :value="value?.hour"
                        variant="underline"
                        @change="emit('input', valueOrDefault.setHour($event))"
                    />
                </div>
                <div
                    class="MTimePicker__minute-button"
                    @click="emit('input', valueOrDefault.subHours(1))"
                >
                    -
                </div>
            </div>
            <div class="tw-flex tw-items-center tw-text-sm tw-font-semibold tw-text-gray-500">
                {{ __('common:hour_lower_short') }}
            </div>
            <div class="tw-flex tw-flex-col tw-justify-between">
                <div
                    class="MTimePicker__minute-button"
                    @click="increaseMinutes"
                >
                    +
                </div>
                <div>
                    <MNumberInput
                        ref="minuteEl"
                        :allow-undefined="allowUndefined"
                        class="!tw-w-10 tw-text-center tw-font-semibold"
                        :max="59"
                        :min="0"
                        onfocus="this.select()"
                        :parser-options="timeNumberParserOptions"
                        :value="value?.minute"
                        variant="underline"
                        @change="emit('input', valueOrDefault.setMinute($event))"
                    />
                </div>
                <div
                    class="MTimePicker__minute-button"
                    @click="decreaseMinutes"
                >
                    -
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .MTimePicker__button {
        @apply tw-cursor-pointer tw-rounded tw-px-2 tw-py-1 tw-text-center tw-text-sm tw-font-semibold tw-text-primary-500;
        @apply tw-select-none tw-capitalize tw-transition-all hover:tw-bg-primary-100 hover:tw-text-primary-600;
    }

    .MTimePicker__minute-button {
        @apply tw-cursor-pointer tw-select-none tw-rounded tw-py-1 tw-text-center tw-font-display tw-text-sm tw-text-gray-500;
        @apply tw-bg-gray-100 tw-transition-all hover:tw-bg-primary-100 hover:tw-text-primary-600;
    }
</style>
