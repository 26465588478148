import {nextTick, type Ref, ref, watch} from 'vue';
import {
    arrow as fArrow,
    autoUpdate,
    flip,
    offset as fOffset,
    type Placement,
    shift,
    useFloating,
} from '@floating-ui/vue';
import useUniqueComponentId from '@/modules/app/composables/useUniqueComponentId';

interface MFloatingContextInputType {
    placement: Ref<Placement>;
    trigger: Ref<string>;
    initialFocusRefEl: Ref<any>;
    portal: Ref<boolean>;
    arrow: Ref<boolean>;
    arrowPadding: Ref<number>;
    offset: Ref<number>;
    returnFocusOnClose: Ref<boolean>;
    closeOnEscape: Ref<boolean>;
    closeOnBlur: Ref<boolean>;
}

const defaultValues: MFloatingContextInputType = {
    placement: ref<Placement>('bottom-start'),
    trigger: ref('click'),
    initialFocusRefEl: ref(),
    portal: ref(true),
    arrow: ref(false),
    arrowPadding: ref(10),
    offset: ref(11),
    returnFocusOnClose: ref(true),
    closeOnEscape: ref(true),
    closeOnBlur: ref(true),
};

export default function MFloatingContext(opts: Partial<MFloatingContextInputType> = {}) {
    const options = {...defaultValues, ...opts} as MFloatingContextInputType;

    const {
        placement,
        offset,
        arrow,
        arrowPadding,
        portal: usePortal,
        trigger,
        closeOnBlur,
        closeOnEscape,
        initialFocusRefEl,
        returnFocusOnClose,
    } = options;

    const buttonEl = ref<HTMLElement>();
    const panelEl = ref<HTMLElement>();
    const floatingEl = ref<HTMLElement>();
    const arrowEl = ref<HTMLElement>();
    const isInjected = ref(false);
    const isOpen = ref(false);
    const isHovering = ref(false);
    const prevIsOpen = ref(isOpen.value);
    const hasOverlay = ref(false);
    const isReady = ref(false);

    const {
        floatingStyles,
        middlewareData,
        placement: finalPlacement,
        isPositioned,
    } = useFloating(buttonEl, floatingEl, {
        whileElementsMounted: autoUpdate,
        middleware: [
            fOffset(offset.value),
            flip({
                fallbackAxisSideDirection: 'end',
            }),
            shift(),
            fArrow({
                element: arrowEl,
                padding: arrowPadding.value,
            }),
        ],
        placement,
    });

    function togglePopover() {
        if (isOpen.value) {
            closePopover();
        } else {
            openPopover();
        }
    }

    function openPopover() {
        prevIsOpen.value = isOpen.value;
        isInjected.value = true;
        nextTick(() => {
            isOpen.value = true;
        });
    }

    function closePopover(focusButton = false) {
        prevIsOpen.value = isOpen.value;
        isOpen.value = false;

        if (focusButton && !isOpen.value && prevIsOpen.value && trigger.value === 'click' && returnFocusOnClose.value) {
            nextTick(() => {
                if (buttonEl.value && buttonEl.value?.children.length === 1) {
                    const children = buttonEl.value.children[0] as HTMLElement;

                    if (children.getAttribute('data-focus-children')) {
                        (children.children[0] as HTMLElement).focus();

                        return;
                    }

                    children.focus();
                }
            });
        }
    }

    let timeout: any = undefined;

    function setHovering(hovered: boolean) {
        clearTimeout(timeout);
        if (hovered) {
            isHovering.value = true;
            timeout = setTimeout(openPopover, 300);
        } else {
            isHovering.value = false;
            timeout = setTimeout(() => {
                if (isHovering.value === false) {
                    closePopover();
                }
            }, 300);
        }
    }

    function handleBlur(event) {
        if (
            isOpen.value &&
            closeOnBlur.value &&
            buttonEl.value &&
            panelEl.value &&
            !buttonEl.value.contains(event.relatedTarget) &&
            !panelEl.value.contains(event.relatedTarget) &&
            panelEl.value !== document.activeElement
        ) {
            closePopover();
        }
    }

    watch([isOpen, prevIsOpen], () => {
        if (isOpen.value && trigger.value === 'click') {
            nextTick(() => {
                setTimeout(() => {
                    if (initialFocusRefEl.value) {
                        initialFocusRefEl.value.$el.focus();
                    } else {
                        panelEl.value?.focus();
                    }
                }, 200);
            });
        }
    });

    const popoverId = ref(useUniqueComponentId());
    const buttonId = ref(`popover-button-${popoverId.value}`);
    const panelId = ref(`popover-panel-${popoverId.value}`);

    return {
        popoverId,
        buttonId,
        panelId,
        buttonEl,
        panelEl,
        floatingEl,
        arrowEl,
        isInjected,
        isOpen,
        openPopover,
        closePopover,
        togglePopover,
        handleBlur,
        setHovering,
        trigger,
        floatingStyles,
        middlewareData,
        closeOnEscape,
        placement,
        finalPlacement,
        usePortal,
        isPositioned,
        hasOverlay,
        isReady,
        arrow,
    };
}
