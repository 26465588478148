<template>
    <router-link
        class="suggestion"
        :to="to"
    >
        <div class="header tw-flex tw-items-center">
            <MAvatar
                class="avatar"
                fallback-initials
                :gender="suggestion.gender"
                :name="suggestion.title"
                :url="suggestion.avatar_url"
            />
            <div
                class="title"
                v-html="highlighted(suggestion.title)"
            />
        </div>
        <div class="additionals">
            <dl class="additional tw-mb-0">
                <template v-for="value in suggestion.additionals">
                    <dt>{{ translateLabel(value.label) }}</dt>
                    <dd v-html="highlighted(value.value)" />
                </template>
            </dl>
        </div>
    </router-link>
</template>

<script>
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: ['suggestion', 'query', 'nursery'],
        computed: {
            to() {
                return {
                    name: 'registrations.show',
                    params: {
                        nursery: this.nursery.id,
                        registration: this.suggestion.bindings.registration_id,
                    },
                };
            },
        },
        methods: {
            highlighted(value) {
                return useHighlighter().highlight(this.query, value);
            },
            translateLabel(label) {
                if (label === 'parent_1') {
                    return __('common:parent_with_count', {count: 1});
                }

                if (label === 'parent_2') {
                    return __('common:parent_with_count', {count: 2});
                }

                if (label === 'started_at') {
                    return __('common:starting_from');
                }

                if (label === 'requested_at') {
                    return __('registration:requested_at');
                }

                return label;
            },
        },
    };
</script>
