<script setup lang="ts">
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import {computed} from 'vue';

    const props = defineProps<{
        invoice: InvoiceModel | LegacyInvoiceType;
    }>();

    const grandTotal = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.attributes.grand_total;
        }

        return props.invoice.grand_total;
    });

    const currencyIsoCode = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.computed.currency_iso_code;
        }

        return props.invoice.currency_iso_code;
    });

    const {format} = useFormatCurrency(currencyIsoCode);
</script>

<template>
    <div class="InvoiceMiniatureTotal">
        <div class="InvoiceMiniatureTotal__item">
            {{ __('common:total_colon') }}
            <div class="tw-font-semibold">
                {{ format(grandTotal) }}
            </div>
        </div>
        <div
            v-if="invoice.computed.remaining_amount > 0"
            class="InvoiceMiniatureTotal__item"
        >
            {{ __('billing_invoice:outstanding_balance_colon') }}
            <div class="tw-font-semibold">
                {{ format(invoice.computed.remaining_amount) }}
            </div>
        </div>
    </div>
</template>

<style scoped>
    .InvoiceMiniatureTotal {
        @apply tw-border-t tw-border-gray-200;
        @apply tw-flex tw-flex-col tw-items-end tw-gap-1;
    }

    .InvoiceMiniatureTotal__item {
        @apply tw-flex tw-flex-row tw-items-baseline tw-gap-1;
    }
</style>
