<template>
    <CListRow>
        <CInline
            align-x="between"
            align-y="baseline"
        >
            <CInline align-y="center">
                <div
                    class="tw-mr-3 tw-flex tw-h-7 tw-w-7 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full"
                    :style="getIconStyle(eventType.attributes.color)"
                >
                    <CIcon
                        v-if="icon"
                        class="icon tw-justify-center tw-self-center"
                        :path="icon.attributes.path"
                        :provider="icon.attributes.provider"
                    />
                </div>
                <CText>{{ eventType.attributes.name }}</CText>
            </CInline>
            <MButton
                variant="link-danger"
                @click="onDelete"
            >
                <FontAwesomeIcon icon="fa-solid fa-trash" />
            </MButton>
        </CInline>
    </CListRow>
</template>

<script>
    import {ref} from 'vue';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import Manager from '@/modules/legacy/store/manager.store';
    import DataStore from '@/modules/legacy/store/data.store';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';

    export default {
        components: {},
        props: {
            eventType: {
                required: true,
                type: EventTypeModel,
            },
        },
        data() {
            return {
                icon: null,
                loader: new Loader(),
                Manager,
            };
        },

        mounted() {
            this.icon = DataStore.iconPath(this.eventType.attributes.icon_id);
        },

        methods: {
            getIconStyle(color) {
                return color ? useEventStyle(ref(color), ref(false), 'md', true).badgeStyle.value : null;
            },
            onDelete() {
                this.$emit('delete', this.eventType);
            },
        },
    };
</script>
