<template>
    <div class="KidAvatarEditor">
        <MAvatarEditor
            :can-delete="!!kid.avatar_url"
            class="tw-absolute tw-w-20"
            @click.native="fileInputClick"
            @delete="$emit('deleteAvatar')"
        >
            <LegacyKidAvatar
                class="tw-h-full tw-w-full"
                :kid="kid"
            />
        </MAvatarEditor>
        <CText
            font-size="xs"
            variant="gray"
        >
            {{ __('common:edit_avatar_instruction') }}
        </CText>
        <input
            ref="fileInput"
            accept="image/*"
            class="tw-hidden"
            type="file"
            @change="$emit('updateAvatar', $event)"
        />
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';

    export default defineComponent({
        components: {
            LegacyKidAvatar,
        },
        props: {
            kid: {
                type: Object,
                required: true,
            },
        },
        setup() {
            const fileInput = ref();

            function fileInputClick() {
                fileInput.value.click();
            }

            return {
                fileInput,
                fileInputClick,
            };
        },
    });
</script>

<style scoped>
    .KidAvatarEditor {
        @apply tw-flex tw-flex-col tw-items-center tw-gap-2;
    }
</style>
