<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';
    import {Epoch} from '@meekohq/lumos';
    import type {PropType} from 'vue';

    defineProps({
        pastClosedPeriod: {
            type: Array as PropType<
                {
                    id: number;
                    name: string;
                    started_at: string;
                    ended_at: string;
                }[]
            >,
            required: true,
        },
    });

    const emit = defineEmits(['deleteClosedPeriod']);
    const {can} = useAbility();
</script>

<template>
    <CDisclosure
        class="OpeningManagerPastClosedPeriods"
        :open="false"
        :title="__('organization:past_closing_periods')"
    >
        <div class="OpeningManagerPastClosedPeriods__container">
            <CList class="OpeningManagerPastClosedPeriods__list">
                <CListRow
                    v-for="(closedPeriod, i) in pastClosedPeriod"
                    :key="'closed-period' + i"
                    class="OpeningManagerPastClosedPeriods__item"
                    :hover="false"
                >
                    <div class="OpeningManagerPastClosedPeriods__item-header">
                        <MHeading level="h3">
                            {{ closedPeriod.name }}
                        </MHeading>
                        <MButton
                            v-if="can('update', 'nursery')"
                            class="tw-shrink-0"
                            variant="link-danger"
                            @click="emit('deleteClosedPeriod', closedPeriod.id)"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-times" />
                        </MButton>
                    </div>
                    <div class="OpeningManagerPastClosedPeriods__grid">
                        <div class="OpeningManagerPastClosedPeriods__grid-item">
                            <div class="OpeningManagerPastClosedPeriods__grid-item-text">
                                {{ __('common:start_colon') }}
                            </div>
                            {{ Epoch.fromTimestamp(closedPeriod.started_at).toLocaleString(Epoch.presets.DATE_SHORT) }}
                        </div>
                        <div class="OpeningManagerPastClosedPeriods__grid-item">
                            <div class="OpeningManagerPastClosedPeriods__grid-item-text">
                                {{ __('common:end_colon') }}
                            </div>
                            {{ Epoch.fromTimestamp(closedPeriod.ended_at).toLocaleString(Epoch.presets.DATE_SHORT) }}
                        </div>
                    </div>
                </CListRow>
            </CList>
        </div>
    </CDisclosure>
</template>

<style scoped>
    .OpeningManagerPastClosedPeriods__container {
        @apply tw-mt-2 tw-rounded-xl tw-border tw-border-gray-200 tw-p-2;
    }

    .OpeningManagerPastClosedPeriods__list {
        @apply tw-max-h-72 tw-overflow-auto;
    }

    .OpeningManagerPastClosedPeriods__item {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .OpeningManagerPastClosedPeriods__item-header {
        @apply tw-flex tw-items-start tw-justify-between tw-gap-4;
    }

    .OpeningManagerPastClosedPeriods__grid {
        @apply tw-grid tw-grid-cols-2 tw-items-center tw-gap-4;
    }

    .OpeningManagerPastClosedPeriods__grid-item {
        @apply tw-flex tw-flex-wrap tw-items-center tw-gap-1;
    }

    .OpeningManagerPastClosedPeriods__grid-item-text {
        @apply tw-text-sm tw-text-gray-500;
    }
</style>
