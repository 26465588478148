<template>
    <div class="PlanningSettingsGroup">
        <PlanningSettingsAllKids :nursery="nursery" />
        <hr class="hr" />
        <PlanningSettingsAllGroups :nursery="nursery" />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import PlanningSettingsAllKids from '@/modules/planning/components/settings/PlanningSettingsGroup/organisms/PlanningSettingsAllKids.vue';
    import PlanningSettingsAllGroups from '@/modules/planning/components/settings/PlanningSettingsGroup/organisms/PlanningSettingsAllGroups.vue';

    export default defineComponent({
        components: {
            PlanningSettingsAllGroups,
            PlanningSettingsAllKids,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
    });
</script>

<style scoped>
    .PlanningSettingsGroup {
        @apply tw-flex tw-flex-col tw-gap-4;
    }
</style>
