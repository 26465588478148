import type {Ref} from 'vue';
import {computed, ref} from 'vue';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import type MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
import type {ModelCollection} from '@meekohq/lumos';

export default function (excludeParticipants: Ref<ModelCollection<MeetingParticipantModel>>) {
    const selectedStaff = ref<StaffModel>();

    // Get participant staffs already selected to exclude them from finder results
    const staffsExcluded = computed(() =>
        excludeParticipants.value
            ?.filter(participant => participant.attributes.resource_type === new StaffModel().type)
            .pluck('attributes.resource_id')
            .toArray()
    );

    // Get staffs from active organization and not already selected
    const staffFinderQuery = computed(() => {
        let query = StaffModel.query();

        if (staffsExcluded.value) {
            query = query.whereNotIn('id', staffsExcluded.value as string[]);
        }

        return query;
    });

    return {
        selectedStaff,
        staffFinderQuery,
    };
}
