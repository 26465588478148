import Vue from 'vue';
import {get} from 'lodash-es';
import {collect, type Collection, ForbiddenError, ValidationError} from '@meekohq/lumos';
import __ from '@/modules/app/utils/i18n-facade';
import useNotification from '@/modules/meeko-ui/composables/useNotification';

export default class ErrorHandler {
    protected errors: Collection<any> = collect();

    public reset(error: any = null) {
        if (error === null) {
            Vue.set(this, 'errors', collect());
        } else if (error instanceof ValidationError) {
            Vue.set(this, 'errors', error.errors);
        } else if (error instanceof ForbiddenError) {
            Vue.set(this, 'errors', collect());
            useNotification().error(__('common:errors.forbidden'));
        } else if (error?.response?.data?.errors?.length) {
            Vue.set(this, 'errors', collect(error.response.data.errors));
        }
    }

    public hasErrors(): boolean {
        return this.errors.isNotEmpty();
    }

    public hasError(key: string, pointerPrefix = ''): boolean {
        return this.getErrors(key, pointerPrefix).isNotEmpty();
    }

    /*
     * Get errors by matching string concatenation of the function parameters
     * with the resolver object keys. If there is no match the function fallbacks
     * to the common errors translation file.
     *
     * Example 1:
     * resolver object = {foo: {0x3362CF291D: transfile.bar}}
     * function key param = "foo"
     * errors = [{source: {pointer: "foo"}, code: "0x3362CF291D"}]
     *
     * Example 2:
     * resolver object = {foo: {0x3362CF291D: transfile.bar}}
     * function key param = "foo"
     * errors = [{source: [], code: "0x3362CF291D"}]
     * */
    public getErrors(key: string, pointerPrefix = ''): Collection<string> {
        const pointer = pointerPrefix + key;

        // filter errors that are only relevant to search params
        return this.errors
            .filter(item => {
                return item.source?.pointer === pointer;
                // map found errors to the relevant error translation
            })
            .map(item => {
                const fallback = `common:errors.${item.code}`;
                // if item.source.pointer is falsy, we use the pointer from params as the key to match.
                // if no key is found in the resolver object, we use the fallback and search for item.code in the common errors translation file
                const resolvedKey = get(
                    this.resolver(),
                    [(item.source?.pointer as string) ?? pointer, item.code],
                    fallback
                );

                return __(resolvedKey, item.detail as object);
            });
    }

    protected resolver(): any {
        return {};
    }
}
