import type {Collection} from '@meekohq/lumos';
import {collect} from '@meekohq/lumos';
import type ISplittable from '@/modules/legacy/libs/periodSplitter/ISplittable';
import getOverlappingEvents from '@/modules/legacy/libs/periodSplitter/getOverlappingEvents';

export type FilterCallbackType<TSplittable extends ISplittable> = (
    event: TSplittable,
    overlappingEvents: TSplittable[]
) => TSplittable[];

export type MutateEventCallbackType<TSplittable extends ISplittable> = (
    event: TSplittable,
    overlappingEvents: TSplittable[]
) => void;

export default class MergeEvent<TSplittable extends ISplittable> {
    protected _events: Collection<TSplittable>;
    protected _remainingEvents: TSplittable[] = [];

    /**
     * @param events
     */
    public constructor(events: TSplittable[]) {
        this._events = MergeEvent.sortEvents(collect(events));
    }

    /**
     *
     * @param events
     */
    protected static sortEvents<TSplittableIBaseEvent extends ISplittable>(events: Collection<TSplittableIBaseEvent>) {
        return events.sortBy('startedAt');
    }

    /**
     *
     * @param filterCallback
     */
    public setFilterCallback(filterCallback: FilterCallbackType<TSplittable>) {
        this._filterCallback = filterCallback;

        return this;
    }

    /**
     *
     * @param mutateEventCallback
     */
    public setMutateEventCallback(mutateEventCallback: MutateEventCallbackType<TSplittable>) {
        this._mutateEventCallback = mutateEventCallback;

        return this;
    }

    public merge(): TSplittable[] {
        this._remainingEvents = this._events.all();
        const mergeEvents: TSplittable[] = [];

        while (this._remainingEvents.length) {
            const event = this._remainingEvents.pop() as TSplittable;
            mergeEvents.push(this.processItem(event));
        }

        return mergeEvents;
    }

    /**
     *
     * @param event
     * @param overlappingEvents
     */

    protected _filterCallback: FilterCallbackType<TSplittable> = (event, overlappingEvents) => overlappingEvents;

    protected _mutateEventCallback: MutateEventCallbackType<TSplittable> = () => ({});

    /**
     * @param event
     */
    protected processItem(event: TSplittable): TSplittable {
        const remainingEvents = collect(this._remainingEvents);

        // All events overlapping event with callback filter
        const allOverlappingEvents = getOverlappingEvents(event, remainingEvents);
        const filterOverlappingEvents = this.filterOverlappingEvents(event, allOverlappingEvents);

        // Remove items matching rules from remaining events
        this._remainingEvents = remainingEvents.diff(filterOverlappingEvents).all();

        if (filterOverlappingEvents.length) {
            // We get minimum and maximum date from all match including current event
            const eventsWithCurrentEvent = filterOverlappingEvents.concat([event]);

            const [minStartedAt, maxEndedAt] = this.getExtremDatesFromEvents(eventsWithCurrentEvent);

            this.updateEvent(event, filterOverlappingEvents, {minStartedAt, maxEndedAt});

            // If there is no remaining events, event can't be merged with anything else
            if (this._remainingEvents.length) {
                // We retry to merge newly edited event with other events, maybe we can catch others
                this.processItem(event);
            }
        }

        return event;
    }

    /**
     * Update overlapped event properties
     *
     * @param event
     * @param overlappingEvents
     * @param data
     */
    protected updateEvent(
        event: TSplittable,
        overlappingEvents: TSplittable[],
        data: {minStartedAt: number; maxEndedAt: number}
    ): void {
        // Event boundary are set to minimum and maximum values
        event.startedAt = data.minStartedAt !== Infinity ? data.minStartedAt : event.startedAt;
        event.endedAt = data.maxEndedAt !== Infinity ? data.maxEndedAt : event.endedAt;

        // Save sources in event
        event.sources = event.sources.concat(...overlappingEvents.map(item => item.sources));

        this._mutateEventCallback(event, overlappingEvents);
    }

    protected getExtremDatesFromEvents(events: TSplittable[]): number[] {
        const minStartedAt = Math.min(...events.map(item => item.startedAt));
        const maxEndedAt = Math.max(...events.map(item => item.endedAt));

        return [minStartedAt, maxEndedAt];
    }

    /**
     * Filter events overlapping event with filter callback
     *
     * @param event
     * @param overlappingEvents
     */
    protected filterOverlappingEvents(event: TSplittable, overlappingEvents: TSplittable[]): TSplittable[] {
        return this._filterCallback(event, overlappingEvents);
    }
}
