<template>
    <AbstractItem>
        <template #icon>
            <CTooltip placement="bottom-start">
                <CVStack
                    align="center"
                    class="tw-pt-1"
                    gap="1"
                >
                    <FontAwesomeIcon v-bind="icon" />
                    <CText
                        v-if="duration"
                        font-size="xs"
                        font-weight="semibold"
                        variant="disabled"
                    >
                        {{ duration }}
                    </CText>
                </CVStack>
                <template #content>
                    <template v-if="call.attributes.outcome === CallOutcomeValue.connected">
                        <CText>{{ __('common:connected') }}</CText>
                    </template>
                    <template v-else-if="call.attributes.outcome === CallOutcomeValue.noResponse">
                        <CText>{{ __('activity:no_response') }}</CText>
                    </template>
                    <template v-else-if="call.attributes.outcome === CallOutcomeValue.unavailable">
                        <CText>{{ __('activity:unavailable') }}</CText>
                    </template>
                    <template v-else-if="call.attributes.outcome === CallOutcomeValue.wrongNumber">
                        <CText>{{ __('activity:wrong_number') }}</CText>
                    </template>
                    <template v-else-if="call.attributes.outcome === CallOutcomeValue.voicemail">
                        <CText>{{ __('activity:voicemail') }}</CText>
                    </template>
                </template>
            </CTooltip>
        </template>
        <template #title>
            <div
                class="tw-flex-1 sm:tw-rounded-lg sm:tw-p-2"
                :class="{
                    'tw-cursor-pointer sm:tw-transition-colors sm:tw-ease-in-out sm:hover:tw-bg-gray-50': !isEditing,
                }"
                @click="isEditing ? undefined : setIsEditing(true)"
            >
                <CText
                    class="tw-whitespace-pre-line tw-break-all"
                    font-size="lg"
                >
                    <template v-if="call.exists">
                        {{ __('registration:call') }}
                    </template>
                    <template v-else-if="matchingFamilyMember">
                        {{ matchingFamilyMember }}
                    </template>
                    <template v-else>
                        {{ call.attributes.phone }}
                    </template>
                </CText>
            </div>
        </template>
        <template #date>
            <CVStack class="tw-whitespace-nowrap">
                <CText
                    v-if="startedAtFormatted"
                    variant="disabled"
                >
                    {{ startedAtFormatted }}
                </CText>
                <CText
                    v-if="staff"
                    font-size="sm"
                    variant="disabled"
                >
                    {{ __('common:by') }} {{ $capitalize(staff.fullname) }}
                </CText>
            </CVStack>
        </template>
        <template #actions>
            <MMenu v-if="can('update', 'registrations')">
                <MMenuButton>
                    <MButton icon-ellipsis />
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        :disabled="isEditing"
                        :label="__('common:actions.update')"
                        @click="setIsEditing(true)"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-pen"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        :label="__('common:actions.delete')"
                        variant="danger"
                        @click="remove"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-trash"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
        </template>
        <template
            v-if="isEditing"
            #content
        >
            <CallForm
                :call="editedCall"
                :disable-save-button="saving"
                :registration="registration"
                :suggested-phones="suggestedPhones"
                @saved="save"
                @undo="cancel"
            />
        </template>
        <template
            v-else-if="call.attributes.description"
            #content
        >
            <CText>{{ call.attributes.description }}</CText>
        </template>
    </AbstractItem>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, ref, toRef} from 'vue';
    import AbstractItem from '@/modules/registration/components/molecules/Timeline/AbstractItem.vue';
    import CallForm from '@/modules/activity/components/CallForm.vue';
    import type CallModel from '@/modules/activity/models/CallModel';
    import useAttachCall from '@/modules/activity/composables/useAttachCall';
    import useAbility from '@/modules/app/composables/useAbility';
    import CallDirectionValue from '@/modules/activity/utils/CallDirectionValue';
    import CallOutcomeValue from '@/modules/activity/utils/CallOutcomeValue';
    import {useToggle} from '@vueuse/core';
    import __ from '@/modules/app/utils/i18n-facade';
    import {EpochDuration} from '@meekohq/lumos';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';

    export default defineComponent({
        components: {CallForm, AbstractItem},
        props: {
            call: {
                type: Object as PropType<CallModel>,
                required: true,
            },
            registration: {
                type: RegistrationModel,
                required: true,
            },
        },
        setup(props, {emit}) {
            const staff = computed(() => props.call.staff().value());
            const {can} = useAbility();
            const {fromISOString, presets} = useEpoch();
            const [isEditing, setIsEditing] = useToggle(!props.call.exists);
            const saving = ref(false);

            const duration = computed(() => {
                if (!props.call.attributes.duration) {
                    return null;
                }

                if (props.call.attributes.duration >= 60) {
                    return __('registration:duration_in_minutes', {
                        count: Math.floor(EpochDuration.fromSeconds(props.call.attributes.duration).as('minutes')),
                    });
                }

                return __('common:second_narrow_one', {count: props.call.attributes.duration});
            });

            // Edition functions
            const editedCall = ref(props.call.clone()) as Ref<CallModel>;
            const {attach} = useAttachCall(
                toRef(props, 'call'),
                props.registration.getKey().toString(),
                'registration/registrations'
            );

            const firstParent = computed(() => {
                return formatRegistrationParent(props.registration, 'first');
            });

            const secondParent = computed(() => {
                return formatRegistrationParent(props.registration, 'second');
            });

            const startedAtFormatted = computed(() => {
                return props.call.attributes.started_at
                    ? fromISOString(props.call.attributes.started_at as string).toLocaleString(presets.DATETIME_SHORT)
                    : null;
            });

            function formatRegistrationParent(registration: RegistrationModel, parentKey: string) {
                const firstName = registration.attributes[`${parentKey}_parent_first_name`];
                const lastName = registration.attributes[`${parentKey}_parent_last_name`];
                const fallbackName = parentKey === 'first' ? __('common:first_parent') : __('common:second_parent');

                return {
                    fullName: firstName && lastName ? `${firstName} ${lastName}` : fallbackName,
                    phones: [
                        {
                            label: `${__('common:phone.landline')}`,
                            number: registration.attributes[`${parentKey}_parent_phone`],
                        },
                        {
                            label: `${__('common:phone.cellular')}`,
                            number: registration.attributes[`${parentKey}_parent_mobile_phone`],
                        },
                        {
                            label: `${__('common:phone.work')}`,
                            number: registration.attributes[`${parentKey}_parent_office_phone`],
                        },
                        {
                            label: `${__('common:company_one')}`,
                            number: registration.attributes[`${parentKey}_parent_company_phone`],
                        },
                    ].filter(phone => phone.number != null),
                };
            }

            const matchingFamilyMember = computed(() => {
                if (firstParent.value.phones.map(phone => phone.number).includes(props.call.attributes.phone)) {
                    return firstParent.value.fullName;
                }

                if (secondParent.value.phones.map(phone => phone.number).includes(props.call.attributes.phone)) {
                    return secondParent.value.fullName;
                }

                return null;
            });

            const suggestedPhones = computed(() => {
                return [firstParent, secondParent]
                    .map(parent => {
                        return parent.value.phones.map(phone => {
                            phone.label = `${parent.value.fullName} ${phone.label}`;

                            return phone;
                        });
                    })
                    .flat();
            });

            // Upadte call with saved data and create pivot if not exists
            async function save(call: CallModel) {
                saving.value = true;
                await call.copyTo(props.call);
                await attach();

                setIsEditing(false);
                emit('saved', props.call);
                saving.value = false;
            }

            async function remove() {
                await useMagicModal().deleteConfirmationModal({
                    title: __('registration:delete_call'),
                    text: __('registration:do_you_really_want_to_delete_this_call_ask'),
                    onConfirm: async () => {
                        if (props.call.exists) {
                            await props.call.delete();
                        }

                        emit('deleted', props.call);
                    },
                });
            }

            function cancel() {
                editedCall.value.rollback();
                setIsEditing(false);
                if (!props.call.exists) {
                    emit('deleted', props.call);
                }
            }

            const icon = computed(() => {
                let iconValues = 'fa-duotone';

                switch (props.call.attributes.direction) {
                    case CallDirectionValue.incoming:
                        iconValues += ' fa-phone-arrow-down-left';
                        break;
                    case CallDirectionValue.outcoming:
                        iconValues += ' fa-phone-arrow-up-right';
                }

                const classValues: string[] = [];

                switch (props.call.attributes.outcome) {
                    case CallOutcomeValue.connected:
                        classValues.push('tw-text-green-500');
                        break;
                    case CallOutcomeValue.noResponse:
                    case CallOutcomeValue.unavailable:
                    case CallOutcomeValue.wrongNumber:
                        classValues.push('tw-text-red-500');
                        break;
                    case CallOutcomeValue.voicemail:
                        classValues.push('tw-text-blue-500');
                }

                return {
                    'class': classValues,
                    'icon': iconValues,
                    'size': 'lg',
                    'swap-opacity': true,
                    'fixed-width': true,
                };
            });

            return {
                saving,
                editedCall,
                remove,
                save,
                cancel,
                duration,
                staff,
                matchingFamilyMember,
                suggestedPhones,
                can,
                icon,
                isEditing,
                setIsEditing,
                startedAtFormatted,
            };
        },
        computed: {
            CallOutcomeValue() {
                return CallOutcomeValue;
            },
        },
    });
</script>
