import {type QueryBuilder, QueryBuilderDate, QueryBuilderDateTime} from '@meekohq/lumos';
import type {Moment} from 'moment/moment';
import moment from 'moment';

// Scope to filter events begening or ending in the range
function scopeInRange(mainQuery: QueryBuilder<any>, range: [from: string | Moment, to: string | Moment]) {
    const from = range[0];
    const to = range[1];

    const fromMoment = moment.isMoment(from) ? from : moment(from);
    const toMoment = moment.isMoment(to) ? to : moment(to);

    mainQuery.where(query => {
        query
            .where(query1 => {
                query1
                    .whereBetween('datetime_event.started_at', [
                        new QueryBuilderDateTime(fromMoment),
                        new QueryBuilderDateTime(toMoment),
                    ])
                    .orWhereBetween('datetime_event.ended_at', [
                        new QueryBuilderDateTime(fromMoment),
                        new QueryBuilderDateTime(toMoment),
                    ])
                    .orWhere(query2 => {
                        query2.whereDateTime('datetime_event.started_at', '<=', fromMoment).where(query3 => {
                            query3.whereDateTime('datetime_event.ended_at', '>=', toMoment).orWhere(query4 => {
                                query4
                                    .whereNull('datetime_event.ended_at')
                                    .whereBetween('datetime_event.started_at', [
                                        new QueryBuilderDateTime(fromMoment),
                                        new QueryBuilderDateTime(toMoment),
                                    ]);
                            });
                        });
                    });
            })
            .orWhere(query1 => {
                query1
                    .whereBetween('date_event.started_at', [
                        new QueryBuilderDate(fromMoment),
                        new QueryBuilderDate(toMoment),
                    ])
                    .orWhereBetween('date_event.ended_at', [
                        new QueryBuilderDate(fromMoment),
                        new QueryBuilderDate(toMoment),
                    ])
                    .orWhere(query2 => {
                        query2.whereDate('date_event.started_at', '<=', fromMoment).where(query3 => {
                            query3.whereDate('date_event.ended_at', '>=', toMoment).orWhere(query4 => {
                                query4
                                    .whereNull('date_event.ended_at')
                                    .whereBetween('date_event.started_at', [
                                        new QueryBuilderDate(fromMoment),
                                        new QueryBuilderDate(toMoment),
                                    ]);
                            });
                        });
                    });
            });
    });
}

// Scope to filter event strictly in the range, begin and end
function scopeInPeriod(mainQuery: QueryBuilder<any>, range: [from: string | Moment, to: string | Moment]) {
    const from = range[0];
    const to = range[1];

    const fromIso8601 = moment.isMoment(from) ? from.toISOString() : moment(from).toISOString();
    const toIso8601 = moment.isMoment(to) ? to.toISOString() : moment(to).toISOString();

    mainQuery.where(query => {
        query
            .where(query1 => {
                query1
                    .whereDateTime('datetime_event.started_at', '>=', fromIso8601)
                    .whereDateTime('datetime_event.ended_at', '<=', toIso8601);
            })
            .orWhere(query1 => {
                query1
                    .whereDate('date_event.started_at', '>=', fromIso8601)
                    .whereDate('date_event.ended_at', '<=', toIso8601);
            })
            .orWhere(query1 => {
                query1
                    .whereBetween('datetime_event.started_at', [
                        new QueryBuilderDateTime(fromIso8601),
                        new QueryBuilderDateTime(toIso8601),
                    ])
                    .whereNull('datetime_event.ended_at');
            })
            .orWhere(query1 => {
                query1
                    .whereBetween('date_event.started_at', [
                        new QueryBuilderDate(fromIso8601),
                        new QueryBuilderDate(toIso8601),
                    ])
                    .whereNull('date_event.ended_at');
            });
    });
}

export {scopeInPeriod, scopeInRange};
