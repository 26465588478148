<template>
    <div
        class="MagicIndexFilters"
        :class="colorSchemeClass"
    >
        <div
            ref="filterElement"
            class="MagicIndexFilters__icon"
            :class="{'MagicIndexFilters__icon--hovered': filterCount > 0 && isFilterHovered}"
            @click="$emit('reset')"
        >
            <div
                v-if="filterCount > 0"
                class="MagicIndexFilters__count"
            >
                {{ filterCount }}
            </div>
            <font-awesome-icon
                v-if="filterCount > 0 && isFilterHovered"
                class="tw-scale-125"
                fixed-width
                icon="fa-duotone fa-square-xmark"
            />
            <font-awesome-icon
                v-else
                fixed-width
                icon="fa-duotone fa-filter-list"
            />
        </div>
        <template v-if="loading">
            <MSkeleton class="tw-h-5 tw-w-32" />
            <MSkeleton class="tw-h-5 tw-w-48" />
            <MSkeleton class="tw-h-5 tw-w-24" />
            <MSkeleton class="tw-h-5 tw-w-56" />
        </template>
        <template v-else>
            <slot name="filters" />
            <template v-if="$slots['more-filters']">
                <slot
                    v-if="showMore"
                    name="more-filters"
                />
                <button
                    v-if="!showMore"
                    class="MagicIndexFilters__more-button"
                    @click="showMore = true"
                >
                    {{ __('components:more_filters') }}
                    <font-awesome-icon icon="fa-solid fa-angle-right" />
                </button>
                <button
                    v-else
                    class="MagicIndexFilters__more-button"
                    @click="showMore = false"
                >
                    <font-awesome-icon icon="fa-solid fa-angle-left" />
                    {{ __('components:less_filters') }}
                </button>
            </template>
        </template>
    </div>
</template>

<script lang="ts">
    import {computed, ref} from 'vue';
    import {useElementHover} from '@vueuse/core';

    export default {
        props: {
            variant: {
                type: String,
                default: 'base',
            },
            filterCount: {
                type: [String, Number],
                default: 0,
                required: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const filterElement = ref();
            const isFilterHovered = useElementHover(filterElement);

            const showMore = ref(false);

            const colorSchemeClass = computed(() => {
                switch (props.variant) {
                    case 'family':
                        return 'MagicIndexFilters--variant-family';
                    case 'billing':
                        return 'MagicIndexFilters--variant-billing';
                    case 'staff':
                        return 'MagicIndexFilters--variant-staff';
                    case 'registration':
                        return 'MagicIndexFilters--variant-registration';
                    case 'photo':
                        return 'MagicIndexFilters--variant-photo';
                    case 'communication':
                        return 'MagicIndexFilters--variant-communication';
                    case 'equipment':
                        return 'MagicIndexFilters--variant-equipment';
                    default:
                        return 'MagicIndexFilters--variant-base';
                }
            });

            return {
                showMore,
                colorSchemeClass,
                filterElement,
                isFilterHovered,
            };
        },
    };
</script>

<style scoped>
    .MagicIndexFilters {
        @apply tw-bg-white/50 tw-shadow-md;
        @apply tw-flex tw-flex-wrap tw-items-center tw-gap-2;
        @apply tw-rounded-t-xl tw-p-2 tw-pb-5;
        @apply tw--mb-3;
        @apply tw-ring-1 tw-ring-blue-800/5;
    }

    .MagicIndexFilters__icon {
        @apply tw-relative;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-mx-1 tw-h-6 tw-text-xl tw-text-blue-700/50;
    }

    .MagicIndexFilters__icon--hovered {
        @apply tw-cursor-pointer;
    }

    .MagicIndexFilters__count {
        @apply tw-absolute tw--right-2 tw--top-1.5 tw-z-10;
        @apply tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-shadow;
        @apply tw-text-xs tw-font-semibold;
        @apply tw-transition-all tw-ease-in-out;
    }

    .MagicIndexFilters__count--hovered {
        @apply tw-scale-125 tw-text-danger-500;
    }

    .MagicIndexFilters__more-button {
        @apply tw-text-sm tw-font-semibold;
        @apply tw-h-6;
        @apply tw-shrink-0;
    }

    .MagicIndexFilters--variant-base {
        @apply tw-bg-primary-100;
        @apply tw-ring-[#abcdf1];
        .MagicIndexFilters__icon {
            @apply tw-text-primary-800/50;
        }

        .MagicIndexFilters__more-button {
            @apply tw-text-primary-900/60 hover:tw-text-primary-900/80;
        }
    }

    .MagicIndexFilters--variant-family {
        @apply tw-bg-purple-100;
        @apply tw-ring-purple-600/20;

        .MagicIndexFilters__icon {
            @apply tw-text-purple-700/50;
        }

        .MagicIndexFilters__more-button {
            @apply tw-text-purple-800/60 hover:tw-text-purple-800/80;
        }
    }

    .MagicIndexFilters--variant-billing {
        @apply tw-bg-orange-100;
        @apply tw-ring-orange-600/20;

        .MagicIndexFilters__icon {
            @apply tw-text-orange-700/50;
        }

        .MagicIndexFilters__more-button {
            @apply tw-text-orange-800/60 hover:tw-text-orange-800/80;
        }
    }

    .MagicIndexFilters--variant-staff {
        @apply tw-bg-pink-100;
        @apply tw-ring-pink-600/20;

        .MagicIndexFilters__icon {
            @apply tw-text-pink-700/50;
        }

        .MagicIndexFilters__more-button {
            @apply tw-text-pink-800/60 hover:tw-text-pink-800/80;
        }
    }

    .MagicIndexFilters--variant-registration {
        @apply tw-bg-cyan-100;
        @apply tw-ring-cyan-600/30;

        .MagicIndexFilters__icon {
            @apply tw-text-cyan-700/50;
        }

        .MagicIndexFilters__more-button {
            @apply tw-text-cyan-800/70 hover:tw-text-cyan-800/90;
        }
    }

    .MagicIndexFilters--variant-photo {
        @apply tw-bg-primary-100;
        @apply tw-ring-primary-600/20;

        .MagicIndexFilters__icon {
            @apply tw-text-primary-800/50;
        }

        .MagicIndexFilters__more-button {
            @apply tw-text-primary-900/60 hover:tw-text-primary-900/80;
        }
    }

    .MagicIndexFilters--variant-communication {
        @apply tw-bg-yellow-200;
        @apply tw-ring-yellow-600/40;

        .MagicIndexFilters__icon {
            @apply tw-text-yellow-800/50;
        }

        .MagicIndexFilters__more-button {
            @apply tw-text-yellow-900/70 hover:tw-text-yellow-900/90;
        }
    }

    .MagicIndexFilters--variant-equipment {
        @apply tw-bg-green-200;
        @apply tw-ring-green-600/20;

        .MagicIndexFilters__icon {
            @apply tw-text-green-800/50;
        }

        .MagicIndexFilters__more-button {
            @apply tw-text-green-900/70 hover:tw-text-green-900/90;
        }
    }
</style>
