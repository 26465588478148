import {ref, watch} from 'vue';
import moment, {type Moment} from 'moment';
import {collect, Collection, Epoch} from '@meekohq/lumos';

export default function () {
    const years = ref<any[]>([]);

    const from = ref(Epoch.now().startOf('week').toISOString());
    const to = ref(Epoch.now().addMonths(3).startOf('week').toISOString());

    watch(from, () => (years.value = getWeeksByYear()));
    watch(to, () => (years.value = getWeeksByYear()));

    /**
     * Get the weeks between two dates.
     * @param fromEpoch
     * @param toEpoch
     */
    function getWeeksBetweenDates(fromEpoch: Epoch, toEpoch: Epoch) {
        const weeks: {
            year: number;
            week: number;
        }[] = [];

        let date = fromEpoch.startOfWeek();

        while (date.lessThanOrEqualTo(toEpoch.startOfWeek())) {
            weeks.push({year: date.weekYear, week: date.weekNumber});
            date = date.addWeeks(1);
        }
        return weeks;
    }

    /**
     * Get the weeks by year.
     */
    function getWeeksByYear() {
        const fromEpoch = Epoch.fromISOString(from.value);
        const toEpoch = Epoch.fromISOString(to.value);

        if (toEpoch.lessThan(fromEpoch)) {
            return [];
        }

        const weeks = getWeeksBetweenDates(fromEpoch, toEpoch);

        return collect(weeks)
            .map(value => {
                return {
                    year: value.year,
                    week: value.week,
                    date: moment().year(value.year).week(value.week).startOf('week'),
                    selected: false,
                };
            })
            .groupBy<Collection<Record<string, {year: string; weeks: string; date: Moment; selected: boolean}>>>('year')
            .map((value, year) => {
                return {
                    year: year,
                    weeks: value.toArray(),
                };
            })
            .toArray();
    }

    function selectAllWeeks(select: boolean, half: 'odd' | 'even') {
        years.value.forEach(year => {
            year.weeks.forEach(week => {
                if (half === 'odd') {
                    week.week % 2 ? (week.selected = true) : (week.selected = false);
                } else if (half === 'even') {
                    week.week % 2 ? (week.selected = false) : (week.selected = true);
                } else {
                    select ? (week.selected = true) : (week.selected = false);
                }
            });
        });
    }

    return {
        from,
        to,
        years,
        selectAllWeeks,
    };
}
