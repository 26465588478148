<template>
    <div>
        <CashierOrderManager
            :contract="contract"
            :kid="kid"
            :legacy-organization="nursery"
            :payload="cashierPayload"
            @hasCustomer="show = $event"
        />
        <estimate-invoices
            v-if="show"
            class="tw-mt-6"
            :contract="contract"
            :kid="kid"
            :nursery="nursery"
            :updating="updating"
            :user="user"
        />
    </div>
</template>

<script lang="ts">
    import EstimateInvoices from '@/modules/cashier/components/billing/invoice/legacy/EstimateInvoices/Estimate.vue';
    import CashierOrderManager from '@/modules/family/components/kid/CashierOrderManager.vue';
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        components: {
            EstimateInvoices,
            CashierOrderManager,
        },
        props: {
            user: {},
            nursery: {},
            contract: {},
            kid: {},
            cashierPayload: {},
            updating: {
                type: Boolean,
                default: false,
            },
        },
        setup() {
            const show = ref(false);

            return {
                show,
            };
        },
    });
</script>
