<template>
    <div
        class="TaxCertificates__card-container"
        @click="$emit('toggleKid', {kid, customer, selected: !kid.extra.selected})"
    >
        <CCard
            body-class="TaxCertificates__card-body"
            body-size="xs"
            :class="{'TaxCertificates__card-body--is-selected': kid.extra.selected}"
        >
            <div class="TaxCertificates__card-body-content">
                <KidAvatar
                    class="TaxCertificates__avatar tw-w-10"
                    :kid-model="kid"
                />
                <div class="TaxCertificates__informations">
                    <CText
                        as="div"
                        class="TaxCertificates__name"
                        font-weight="semibold"
                        :variant="kid.extra.selected ? 'white' : 'black'"
                    >
                        {{ kid.fullname }}
                    </CText>
                    <CText
                        as="div"
                        class="TaxCertificates__family"
                        font-size="sm"
                        font-weight="semibold"
                        :variant="kid.extra.selected ? 'white' : 'gray'"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-user"
                        />
                        {{ customer.attributes.name }}
                    </CText>
                    <CText
                        v-if="!customer.extra.sentTo || customer.extra.sentTo.length === 0"
                        as="div"
                        class="tw-tracking-wider"
                        font-size="sm"
                        font-weight="semibold"
                        uppercase
                        :variant="kid.extra.selected ? 'white' : 'warning'"
                    >
                        <FontAwesomeIcon icon="fa-duotone fa-exclamation-triangle" />
                        {{ __('common:emails_count', {count: 0}) }}
                    </CText>
                    <MTooltip
                        v-else
                        placement="bottom-start"
                    >
                        <CText
                            as="div"
                            font-size="sm"
                            font-weight="semibold"
                            :variant="kid.extra.selected ? 'white' : 'black'"
                        >
                            {{ __('common:emails_count', {count: customer.extra.sentTo.length}) }}
                        </CText>
                        <template #content>
                            <div
                                v-for="(sentTo, j) in customer.extra.sentTo"
                                :key="'email-' + j"
                                class="TaxCertificates__mail"
                            >
                                {{ sentTo.email }}
                            </div>
                        </template>
                    </MTooltip>
                </div>
            </div>
        </CCard>
    </div>
</template>

<script lang="ts">
    import KidAvatar from '@/modules/app/components/atoms/avatars/KidAvatar.vue';

    export default {
        components: {KidAvatar},
        props: {
            kid: {
                type: Object,
                required: true,
            },
            customer: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<style scoped>
    .TaxCertificates__card-container {
        @apply tw-cursor-pointer;
    }

    .TaxCertificates__card-body {
        @apply tw-flex tw-items-center;
        @apply tw-truncate;
    }

    .TaxCertificates__card-body--is-selected {
        @apply tw-border-blue-500 tw-bg-blue-500 tw-text-white;
    }

    .TaxCertificates__card-body-content {
        @apply tw-flex tw-items-center;
        @apply tw-h-14;
    }

    .TaxCertificates__informations {
        @apply tw-ml-3 tw-min-w-0;
    }

    .TaxCertificates__avatar {
        @apply tw-flex-shrink-0;
    }

    .TaxCertificates__name {
        @apply tw-truncate tw-capitalize;
    }

    .TaxCertificates__family {
        @apply tw-truncate tw-tracking-wide;
    }

    .TaxCertificates__mail {
        @apply tw-truncate tw-lowercase;
    }
</style>
