import {ServiceProvider} from '@meekohq/lumos';
import {GetReportingSettingsInputPortBinding} from '@/modules/meeko-family/domain/use-cases/GetReportingSettingsInputPort';
import {ReportingSettingRepositoryAdapter} from '@/modules/meeko-family/adapters/repositories/ReportingSettingRepositoryAdapter';
import {GetReportingSettingsInteractor} from '@/modules/meeko-family/domain/use-cases/GetReportingSettingsInteractor';
import {StoreReportingSettingsInputPortBinding} from '@/modules/meeko-family/domain/use-cases/StoreReportingSettingsInputPort';
import StoreReportingSettingsInteractor from '@/modules/meeko-family/domain/use-cases/StoreReportingSettingsInteractor';
import {StoreFamilyAppSettingsInputPortBinding} from '@/modules/meeko-family/domain/use-cases/StoreFamilyAppSettingsInputPort';
import {StoreFamilyAppSettingsInteractor} from '@/modules/meeko-family/domain/use-cases/StoreFamilyAppSettingsInteractor';
import {FamilyAppSettingsRepositoryAdapter} from '@/modules/meeko-family/adapters/repositories/FamilyAppSettingsRepositoryAdapter';
import {SendRealTimeDeactivationFeedbackInputPortBinding} from '@/modules/meeko-family/domain/use-cases/SendRealTimeDeactivationFeedbackInputPort';
import {SendRealTimeDeactivationFeedbackInteractor} from '@/modules/meeko-family/domain/use-cases/SendRealTimeDeactivationFeedbackInteractor';
import {ReportingSettingNotifierAdapter} from '@/modules/meeko-family/adapters/notifiers/ReportingSettingNotifierAdapter';

export default class CoreServiceProvider extends ServiceProvider {
    public register() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.app.bind(GetReportingSettingsInputPortBinding, (app, presenter) => {
            return new GetReportingSettingsInteractor(new ReportingSettingRepositoryAdapter(), presenter);
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.app.bind(SendRealTimeDeactivationFeedbackInputPortBinding, (app, presenter) => {
            return new SendRealTimeDeactivationFeedbackInteractor(new ReportingSettingNotifierAdapter(), presenter);
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.app.bind(StoreFamilyAppSettingsInputPortBinding, (app, presenter) => {
            return new StoreFamilyAppSettingsInteractor(new FamilyAppSettingsRepositoryAdapter(), presenter);
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.app.bind(StoreReportingSettingsInputPortBinding, (app, presenter) => {
            return new StoreReportingSettingsInteractor(new ReportingSettingRepositoryAdapter(), presenter);
        });
    }
}
