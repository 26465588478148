<template>
    <MModal
        :modal="modal"
        size="4xl"
    >
        <div>
            <div class="EditInvoice__header">
                <EditInvoiceHeader
                    :invoice="invoice"
                    :sub-total="subTotal"
                />
                <EditInvoiceHeaderActions
                    class="tw-ml-auto"
                    :editable="editable"
                    :editing-allocations="editingAllocations"
                    :invoice="invoice"
                    :is-processing="!!processingActionName"
                    :nursery="nursery"
                    :sub-total="subTotal"
                    @uncollectibleInvoice="openUncollectibleModal"
                    @unlockInvoice="unlockInvoice"
                />
            </div>
            <div class="tw-mb-6">
                <support-meeko
                    :ressource="invoice"
                    :title="__('common:invoice_one')"
                />
                <support-meeko
                    v-if="payments"
                    :ressource="payments.first()"
                    :title="__('billing_invoice:associated_payments')"
                />
            </div>
            <div class="EditInvoice__container">
                <EditInvoiceContentDisabledAlert
                    v-if="editable && editingAllocations"
                    :title="
                        __('billing_invoice:save_breakdowns_first_to_modify_the', {
                            context: subTotal > 0 ? 'invoice' : 'credit_note',
                        })
                    "
                />
                <EditInvoiceNameType
                    :invoice="invoice"
                    :nursery="nursery"
                />
                <EditInvoiceInformations
                    :customer="customer"
                    :editable="editable"
                    :invoice="invoice"
                    :is-new="isNew"
                    :nursery="nursery"
                    :user="user"
                />
                <EditInvoiceRateMade
                    class="tw-mt-10"
                    :editable="editable"
                    :invoice="invoice"
                    :nursery="nursery"
                />
                <EditInvoiceDetailsEditor
                    v-if="editable && $can('update', 'invoices')"
                    class="tw-mt-10"
                    :currency="currency"
                    :errors="invoiceState.errors"
                    :grand-total="grandTotal"
                    :invoice="invoice"
                    :nursery="nursery"
                    :with-tva="withTva"
                    @updated="invoiceState.updateInvoice($event)"
                />
                <EditInvoiceDetails
                    v-else
                    class="tw-mt-10"
                    :currency="currency"
                    :invoice="invoice"
                    :with-tva="withTva"
                />
                <EditInvoiceWarning
                    v-if="editable && invoiceTypeSwitched"
                    class="tw-mt-10"
                    :invoice-type="invoice.invoice_type"
                />
                <EditInvoicetDueDate
                    class="tw-mt-10"
                    :editable="editable"
                    :invoice="invoice"
                />
                <EditInvoiceConditions
                    v-if="invoice.conditions || (editable && $can('update', 'invoices'))"
                    class="tw-mt-10"
                    :editable="editable"
                    :invoice="invoice"
                />
                <EditInvoiceFooter
                    v-if="invoice.footer || nursery.invoice_footer"
                    class="tw-mt-10"
                    :invoice="invoice"
                    :nursery="nursery"
                />
            </div>

            <div
                v-if="invoice.id && $can('read', 'transactions')"
                ref="paymentAllocationsContainer"
                class="EditInvoice__payment-allocations-container"
            >
                <EditInvoiceContentDisabledAlert
                    v-if="hasPaymentsLocked"
                    :title="
                        __('billing_invoice:to_modify_the_breakdowns_save_the', {
                            context: subTotal > 0 ? 'invoice' : 'credit_note',
                        })
                    "
                />
                <EditInvoiceLoader v-if="isLoadingPayment" />
                <template v-if="invoice.invoice_type === 'credit_note'">
                    <PaymentAllocationsListForCreditNote
                        :credit-note="invoice"
                        :editing-allocations="editingAllocations"
                        :payments="payments"
                        @editingAllocations="editingAllocations = $event"
                        @loaded="isLoadingPayment = false"
                        @refreshInvoice="prepareRefresh"
                    />
                </template>
                <template v-else>
                    <PaymentAllocationsListForInvoice
                        :editing-allocations="editingAllocations"
                        :invoice="invoice"
                        :payment="firstInvoicePayment"
                        @editingAllocations="editingAllocations = $event"
                        @loaded="isLoadingPayment = false"
                        @refreshInvoice="prepareRefresh"
                    />
                </template>
            </div>
        </div>
        <template #footer-end="{closeDialog}">
            <div class="tw-flex tw-flex-wrap tw-gap-2">
                <MButton
                    :disabled="!!processingActionName"
                    @click="closeDialog"
                >
                    <template v-if="editable && $can('update', 'invoices')">
                        {{ __('common:actions.cancel') }}
                    </template>
                    <template v-else>
                        {{ __('common:actions.close') }}
                    </template>
                </MButton>
                <MButton
                    v-if="editable && $can('update', 'invoices')"
                    :disabled="!!processingActionName || editingAllocations || invoiceState.errors.hasErrors"
                    :loading="processingActionName === 'save'"
                    @click="save()"
                >
                    <template v-if="invoiceStatus === invoiceStatuses.editing">
                        {{ __('billing_invoice:edit_finish_later') }}
                    </template>
                    <template v-else>
                        {{ __('billing_invoice:save_draft', {context: subTotal > 0 ? 'invoice' : 'credit_note'}) }}
                    </template>
                </MButton>
                <MButton
                    v-if="editable && $can('update', 'invoices')"
                    :disabled="!!processingActionName || editingAllocations || invoiceState.errors.hasErrors"
                    :loading="processingActionName === 'lock'"
                    variant="primary"
                    @click="save(true)"
                >
                    <template
                        v-if="invoiceStatus === invoiceStatuses.editing || invoiceStatus === invoiceStatuses.draft"
                        #left-icons
                    >
                        <FontAwesomeIcon icon="fa-solid fa-lock-keyhole" />
                    </template>
                    <template v-if="invoiceStatus === invoiceStatuses.editing">
                        {{ __('billing_invoice:edit_finish') }}
                    </template>
                    <template v-else>
                        {{ __('billing_invoice:lock', {context: subTotal < 0 ? 'credit_note' : 'invoice'}) }}
                    </template>
                </MButton>
            </div>
        </template>
    </MModal>
</template>

<script>
    import {nextTick, toRef} from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import {Epoch, MqlOperation, Str} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useApi from '@/modules/app/composables/useApi';
    import useFetchInvoicePayments from '@/modules/cashier/composables/billing/payment/useFetchInvoicePayments.ts';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import EditInvoiceDetails from '@/modules/cashier/components/billing/invoice/organisms/EditInvoiceDetails.vue';
    import EditInvoiceDetailsEditor from '@/modules/cashier/components/billing/invoice/organisms/EditInvoiceDetailsEditor.vue';
    import EditInvoiceHeader from '@/modules/cashier/components/billing/invoice/atoms/EditInvoiceHeader.vue';
    import EditInvoiceNameType from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceNameType.vue';
    import EditInvoiceInformations from '@/modules/cashier/components/billing/invoice/organisms/EditInvoiceInformations.vue';
    import EditInvoicetDueDate from '@/modules/cashier/components/billing/invoice/molecules/EditInvoicetDueDate.vue';
    import EditInvoiceFooter from '@/modules/cashier/components/billing/invoice/atoms/EditInvoiceFooter.vue';
    import EditInvoiceConditions from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceConditions.vue';
    import EditInvoiceRateMade from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceRateMade.vue';
    import CurrencySymbolToIsoCode from '@/modules/cashier/utils/core/currency/CurrencySymbolToIsoCode';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import EditInvoiceWarning from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceWarning.vue';
    import EditInvoiceHeaderActions from '@/modules/cashier/components/billing/invoice/organisms/EditInvoiceHeaderActions.vue';
    import PaymentAllocationsListForInvoice from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsListForInvoice.vue';
    import PaymentAllocationsListForCreditNote from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsListForCreditNote.vue';
    import useModal from '@/modules/app/composables/useModal.ts';
    import UncollectibleInvoiceModal from '@/modules/cashier/components/billing/invoice/molecules/UncollectibleInvoiceModal.vue';
    import EditInvoiceContentDisabledAlert from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceContentDisabledAlert.vue';
    import {
        invoiceStatuses,
        useInvoiceStatusState,
    } from '@/modules/cashier/utils/billing/invoice/useInvoiceStatusState';
    import EditInvoiceLoader from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceLoader.vue';
    import InvoiceStatusValue from '@/modules/cashier/utils/billing/invoice/InvoiceStatusValue.ts';
    import {InvoiceAmountService} from '@/modules/cashier/invoice/domain/InvoiceAmountService.ts';
    import {useInvoiceState} from '@/modules/cashier/invoice/composables/useInvoiceState.ts';
    import {InvoiceTotalExceedsLimitError} from '@/modules/cashier/invoice/domain/errors/InvoiceTotalExceedsLimitError.ts';
    import {InvoiceLinePriceExceedsLimitError} from '@/modules/cashier/invoice/domain/errors/InvoiceLinePriceExceedsLimitError.ts';

    export default {
        components: {
            EditInvoiceLoader,
            EditInvoiceContentDisabledAlert,
            EditInvoiceHeaderActions,
            PaymentAllocationsListForCreditNote,
            PaymentAllocationsListForInvoice,
            EditInvoiceWarning,
            EditInvoiceRateMade,
            EditInvoiceConditions,
            EditInvoiceFooter,
            EditInvoicetDueDate,
            EditInvoiceInformations,
            EditInvoiceNameType,
            EditInvoiceHeader,
            EditInvoiceDetailsEditor,
            EditInvoiceDetails,
        },
        mixins: [invoice, nursery],
        provide() {
            return {
                ['customer']: this.customer,
            };
        },
        props: {
            user: {
                type: Object,
                default: () => ({}),
            },
            nursery: {
                type: Object,
                default: () => ({}),
            },
            invoice: {
                type: Object,
                default: () => ({}),
            },
            customer: {
                type: Object,
                default: () => ({}),
            },
            modal: {
                type: Object,
                required: true,
            },
            scrollToPaymentAllocations: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                processingActionName: undefined,
                payments: undefined,
                useFetchInvoicePayments: useFetchInvoicePayments(),
                isLoadingPayment: true,
                editingAllocations: false,
                invoiceStatus: useInvoiceStatusState(toRef(this, 'invoice')).invoiceStatus,
                hasScrolled: false,
                invoiceAmountService: new InvoiceAmountService(this.nursery.country),
                invoiceState: useInvoiceState(this.invoice),
            };
        },
        computed: {
            invoiceStatuses() {
                return invoiceStatuses;
            },
            editable() {
                return this.invoice.status === InvoiceStatusValue.draft;
            },
            hasPaymentsLocked() {
                return this.invoice.status === InvoiceStatusValue.draft && this.invoice.grand_total !== this.subTotal;
            },
            subTotal() {
                if (!this.invoice.lines) {
                    return 0;
                }

                return this.invoiceAmountService.sumInvoiceLines(this.invoice);
            },
            grandTotal() {
                this.setDefaultInvoiceName();

                return useFormatCurrency(this.currency).format(this.subTotal);
            },
            withTva() {
                return this.nursery.invoice_tva > 0;
            },
            invoiceTypeSwitched() {
                return (
                    (this.invoice.invoice_type === 'invoice' && this.subTotal < 0) ||
                    (this.invoice.invoice_type === 'credit_note' && this.subTotal > 0)
                );
            },
            currency() {
                if (this.invoice.currency_iso_code) {
                    return this.invoice.currency_iso_code;
                }

                if (this.customer) {
                    return this.customer.computed.currency_iso_code;
                }

                return CurrencySymbolToIsoCode[nursery.currency];
            },
            isNew() {
                return this.invoice.id === null;
            },
            firstInvoicePayment() {
                return this.payments?.first();
            },
        },
        watch: {
            isLoadingPayment(newValue) {
                // If the modal just loaded payment for the first time amd the props is enabled, scroll to the payment allocations
                if (
                    !newValue &&
                    !this.hasScrolled &&
                    this.scrollToPaymentAllocations &&
                    this.$refs.paymentAllocationsContainer
                ) {
                    this.$refs.paymentAllocationsContainer.scrollIntoView({behavior: 'smooth'});
                    this.hasScrolled = true;
                }
            },
        },
        created() {
            if (this.isNew) {
                this.changeDueAt(this.nursery, this.invoice);
            } else {
                this.fetchPayment();
            }

            // Set the invoice name depending on the invoice type if the invoice name is empty
            if (this.invoice.name === null || this.invoice.name === '' || this.invoice.name === undefined) {
                this.setDefaultInvoiceName();
            }
        },
        methods: {
            save(lockInvoice = false) {
                this.processingActionName = lockInvoice ? 'lock' : 'save';
                const invoice = Object.assign({}, this.invoice);

                if (!this.isNew) {
                    useApi()
                        .legacy.put(
                            route('nurseries.invoices.update', {
                                nurseries: this.invoice.nursery_id,
                                invoice: this.invoice.id,
                            }),
                            invoice
                        )
                        .then(() => {
                            useNotification().success(
                                this.subTotal > 0
                                    ? __('billing_invoice:invoice_updated')
                                    : __('billing_invoice:credit_note_updated')
                            );
                            if (lockInvoice) {
                                this.lockInvoice();
                            } else {
                                // Refresh the invoice to get the fresh invoice status and computed properties
                                this.refreshInvoice(true);
                            }
                        })
                        .catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    const errorMessage = _head(value);
                                    if (
                                        [
                                            InvoiceTotalExceedsLimitError.code,
                                            InvoiceLinePriceExceedsLimitError.code,
                                        ].includes(errorMessage)
                                    ) {
                                        useNotification().error(__('common:errors.' + errorMessage));
                                    } else {
                                        useNotification().error(_head(value));
                                    }
                                });
                            } else {
                                useNotification().error(error);
                            }

                            this.processingActionName = undefined;
                        });
                } else {
                    // Case of a new or duplicate invoice
                    invoice.id = Str.uuid();

                    // Replace invoice id in lines
                    invoice.lines.forEach(line => {
                        line.id = Str.uuid();
                        line.invoice_id = invoice.id;
                    });

                    useApi()
                        .legacy.post(
                            route('nurseries.invoices.store', {
                                nurseries: this.nursery.id,
                            }),
                            invoice
                        )
                        .then(async response => {
                            // Replace null by the new invoice id in current object to be used in the next queries
                            this.invoice.id = response.data.id;

                            // Need to refresh the invoice data to avoid console errors before the global refresh
                            this.invoice.computed = {
                                remaining_amount: response.data.computed.remaining_amount,
                            };
                            this.invoice.currency_iso_code = response.data.currency_iso_code;

                            // Handle the use case when the user creates and locks the invoice directly
                            if (lockInvoice) {
                                try {
                                    await new MqlOperation('cashier/lock_invoices', {
                                        invoice_ids: [this.invoice.id],
                                    }).run();

                                    // Update the invoice increment count for the global nursery object (used in nursery config)
                                    this.nursery.invoice_num++;
                                } catch (e) {
                                    useNotification().error(__('common:errors.generic'));

                                    this.processingActionName = undefined;

                                    return;
                                }
                            } else {
                                // Update the pro forma increment count for the global nursery object (used in nursery config)
                                this.nursery.proforma_num++;
                            }

                            const invoiceTypeMessage =
                                this.subTotal > 0
                                    ? __('billing_invoice:invoice_added')
                                    : __('billing_invoice:credit_note_added');

                            useNotification().success(
                                lockInvoice ? invoiceTypeMessage : __('billing_invoice:draft_added')
                            );

                            this.refreshInvoice(true);
                        })
                        .catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    const errorMessage = _head(value);
                                    if (
                                        [
                                            InvoiceTotalExceedsLimitError.code,
                                            InvoiceLinePriceExceedsLimitError.code,
                                        ].includes(errorMessage)
                                    ) {
                                        useNotification().error(__('common:errors.' + errorMessage));
                                    } else {
                                        useNotification().error(_head(value));
                                    }
                                });
                            } else {
                                useNotification().error(error);
                            }

                            this.processingActionName = undefined;
                        });
                }
            },

            async lockInvoice() {
                // Call the operation that locks the invoice
                try {
                    await new MqlOperation('cashier/lock_invoices', {
                        invoice_ids: [this.invoice.id],
                    }).run();
                } catch (e) {
                    useNotification().error(__('common:errors.generic'));

                    return;
                }

                // Increment the invoice count for the global nursery object (used in nursery config)
                if (!this.invoice.increment) {
                    this.nursery.invoice_num++;
                }

                useNotification().success(
                    this.subTotal > 0 ? __('billing_invoice:invoice_locked') : __('billing_invoice:credit_note_locked')
                );
                // Refresh the invoice to get the new invoice status and computed properties
                this.refreshInvoice(true);
            },

            async unlockInvoice() {
                this.processingActionName = 'unlock';
                try {
                    // Call the operation that unlocks the invoice
                    await new MqlOperation('cashier/unlock_invoices', {
                        invoice_ids: [this.invoice.id],
                    }).run();

                    useNotification().success(
                        this.subTotal > 0
                            ? __('billing_invoice:invoice_unlocked')
                            : __('billing_invoice:credit_note_unlocked')
                    );
                    // Refresh the invoice to get the new invoice status and computed properties
                    await this.refreshInvoice();
                } catch (e) {
                    useNotification().error(__('common:errors.generic'));
                    this.processingActionName = undefined;
                }
            },
            async prepareRefresh() {
                const promises = [];

                // Pre-activate the loading state to avoid flickering
                this.isLoadingPayment = true;

                promises.push(this.refreshInvoice());
                promises.push(this.fetchPayment());

                return Promise.all(promises);
            },
            async refreshInvoice(shouldHideModal = false) {
                await useApi()
                    .legacy.get(
                        route('nurseries.invoices.show', {
                            nurseries: this.nursery.id,
                            invoice: this.invoice.id,
                            with: ['lines', 'customer', 'tags', 'kid'],
                        })
                    )
                    .then(response => {
                        this.invoice.status = response.data.status;
                        this.invoice.computed = response.data.computed;
                        this.invoice.uncollectible_date = response.data.uncollectible_date;
                        this.invoice.uncollectible_description = response.data.uncollectible_description;
                        nextTick(() => {
                            this.$emit('updateInvoices', response.data);
                            if (shouldHideModal) {
                                this.modal.hide();
                            }
                        });
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.processingActionName = undefined;
                    });
            },

            async fetchPayment() {
                this.isLoadingPayment = true;
                this.payments = await this.useFetchInvoicePayments.fetch(this.invoice);
            },
            setDefaultInvoiceName() {
                const nurseryName = [this.nursery.proforma_name, this.nursery.invoice_name, 'Avoir'];

                if (nurseryName.includes(this.invoice.name) || !this.invoice.name) {
                    if (this.subTotal < 0) {
                        this.invoice.name = 'Avoir';
                    } else {
                        this.invoice.name =
                            this.invoice.status === 'draft' ? this.nursery.proforma_name : this.nursery.invoice_name;
                    }
                }
            },
            openUncollectibleModal() {
                const date = this.invoice.uncollectible_date
                    ? Epoch.parse(this.invoice.uncollectible_date, 'yyyy-MM-dd')
                    : null;

                useModal({
                    component: UncollectibleInvoiceModal,
                    props: {
                        date,
                        invoiceId: this.invoice.id,
                        invoiceStatus: this.invoice.status,
                        description: this.invoice.uncollectible_description ?? null,
                    },
                    listeners: modal => ({
                        saved: async () => {
                            modal.hide();
                            await this.refreshInvoice();
                            await this.fetchPayment();
                        },
                    }),
                }).show();
            },
        },
    };
</script>

<style scoped>
    .EditInvoice__header {
        @apply tw-mb-6 tw-flex tw-flex-wrap tw-items-end tw-justify-between tw-gap-4;
        @apply tw-border-b tw-border-gray-200 tw-pb-6;
        @apply tw--mx-5 tw-px-5 sm:tw--mx-6 sm:tw-px-6;
    }

    .EditInvoice__container {
        @apply tw-relative tw-rounded-lg tw-border tw-border-gray-200 tw-p-6;
    }

    .EditInvoice__payment-allocations-container {
        @apply tw-relative tw-rounded-lg tw-border tw-border-gray-200 tw-p-6;
        @apply tw-mt-10;
    }
</style>
