<template>
    <div ref="target" />
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from 'vue';
    import {useElementVisibility} from '@vueuse/core';

    export default defineComponent({
        components: {},
        props: {},
        setup(props, {emit}) {
            const target = ref();
            const targetIsVisible = useElementVisibility(target);

            watch(targetIsVisible, value => {
                if (value) {
                    emit('isVisible', true);
                } else {
                    emit('isHidden', true);
                }
            });

            return {
                target,
            };
        },
    });
</script>
