<template>
    <CList
        v-if="addressModelCollection.isNotEmpty()"
        :striped="true"
    >
        <AddressListItem
            v-for="addressModel in addressModelCollection"
            :key="addressModel.id"
            :address-model="addressModel"
            :customer-model="customerModel"
            @deleted="onAddressDeleted"
        />
    </CList>
    <div v-else>
        <div class="tw-p-4 tw-text-center tw-text-disabled">
            {{ __('billing_core:no_address_saved') }}
        </div>
    </div>
</template>

<script lang="ts">
    import AddressModel from '@/modules/cashier/models/AddressModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import AddressListItem from '@/modules/cashier/components/core/address/molecules/AddressListItem.vue';
    import {collect} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import {useRoute} from 'vue-router/composables';

    export default defineComponent({
        components: {AddressListItem},
        props: {
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
        },
        setup(props) {
            const loading = ref(false);
            const addressModelCollection = ref(collect<AddressModel>());
            const route = useRoute();

            watch(
                () => props.customerModel,
                () => {
                    fetchResults(1);
                }
            );

            function addToResult(addressModel: AddressModel) {
                addressModelCollection.value.prepend(addressModel);
            }

            fetchResults(Number(route?.query.page) || 1);

            function onAddressDeleted(addressModel: AddressModel) {
                addressModelCollection.value = addressModelCollection.value.filter(function (a: AddressModel) {
                    return a.getKey() !== addressModel.getKey();
                });
            }

            async function fetchResults(page = 1, perPage = 15) {
                loading.value = true;

                const paginator = await AddressModel.query()
                    .with(new AddressModel().orders())
                    .where('customer_id', props.customerModel.id as string)
                    .perPage(perPage)
                    .page(page)
                    .paginate(perPage, page);

                addressModelCollection.value = paginator.items();
                loading.value = false;
            }

            return {
                addToResult,
                onAddressDeleted,
                addressModelCollection,
            };
        },
    });
</script>
