<template>
    <MModal
        :header-title="selectedTag.exists ? __('tag:edit_tag') : __('common:actions.add_tag')"
        :modal="tagModal"
    >
        <div>
            <TagsManagerModalForm
                :categories="categories"
                :error-handler="errorHandler"
                :get-category-by-type="getCategoryByType"
                :selected-tag="selectedTag"
            />
            <TagsManagerModalMatchingTags
                v-if="matchingInOtherOrganization && showMatchingTags"
                :matching-in-other-organization="matchingInOtherOrganization"
                :selected-tag="selectedTag"
                @attachTagToOrganization="$emit('attachTagToOrganization', $event)"
            />
        </div>
        <template #footer-start>
            <MTooltip
                v-if="selectedTag.exists && can('delete', 'tags')"
                :label="__('common:actions.delete_dots')"
            >
                <MButton
                    variant="danger"
                    @click="$emit('removeTag', selectedTag)"
                >
                    <FontAwesomeIcon icon="fa-solid fa-trash-alt" />
                </MButton>
            </MTooltip>
            <MTooltip
                v-if="selectedTag.exists && nurseries.length > 1 && can('update', 'tags')"
                :label="__('common:actions.detach')"
            >
                <MButton @click="$emit('detachTag', selectedTag.id)">
                    <FontAwesomeIcon icon="fa-solid fa-unlink" />
                </MButton>
            </MTooltip>
        </template>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                variant="light"
                @click="hide"
            />
            <MButton
                v-if="selectedTag.exists"
                :label="__('common:actions.update')"
                :loading="loading"
                variant="primary"
                @click="$emit('updateTag')"
            />
            <MButton
                v-else
                :label="__('common:actions.add')"
                :loading="loading"
                variant="primary"
                @click="$emit('createTag')"
            />
        </template>
    </MModal>
</template>

<script lang="ts">
    import {defineComponent, type PropType} from 'vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import type ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import type TagModel from '@/modules/tag/models/TagModel';
    import TagsManagerModalForm from '@/modules/organization/components/TagsManager/TagsManagerModalForm.vue';
    import TagsManagerModalMatchingTags from '@/modules/organization/components/TagsManager/TagsManagerModalMatchingTags.vue';

    export default defineComponent({
        components: {
            TagsManagerModalMatchingTags,
            TagsManagerModalForm,
        },
        props: {
            tagModal: {
                type: Object as PropType<ModalType>,
                required: true,
            },
            selectedTag: {
                type: Object as PropType<TagModel>,
                required: true,
            },
            errorHandler: {
                type: Object as PropType<ErrorHandler>,
                required: true,
            },
            categories: {
                type: Array,
                required: true,
            },
            matchingInOtherOrganization: {
                type: Array,
                default: null,
            },
            nurseries: {
                type: Array,
                required: true,
            },
            showMatchingTags: {
                type: Boolean,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
            getCategoryByType: {
                type: Function,
                required: true,
            },
        },
        setup() {
            const {can} = useAbility();

            return {
                can,
            };
        },
    });
</script>
