<template>
    <div>
        <CListHeader>
            <CListHeaderItem class="tw-w-2/12">
                {{ __('common:date') }}
            </CListHeaderItem>
            <CListHeaderItem class="tw-w-2/12">
                {{ __('subscription:invoice_number') }}
            </CListHeaderItem>
            <CListHeaderItem class="tw-w-2/12">
                {{ __('common:amount_one') }}
            </CListHeaderItem>
            <CListHeaderItem class="tw-w-2/12 tw-text-center">
                {{ __('common:address.state') }}
            </CListHeaderItem>
            <CListHeaderItem class="tw-w-2/12" />
            <CListHeaderItem class="tw-w-2/12" />
        </CListHeader>
        <CList>
            <template v-for="invoice in data.invoices">
                <InvoicesListItem
                    :key="invoice.id"
                    :customer="customer"
                    :invoice="invoice"
                    :user="user"
                />
            </template>
        </CList>
    </div>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {defineComponent, reactive, ref, toRef, watch} from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import InvoicesListItem from '@/modules/subscription/components/Invoices/InvoicesListItem.vue';

    export default defineComponent({
        components: {
            InvoicesListItem,
        },
        props: {
            customer: {type: Object, required: true},
            user: {type: Object, required: true},
        },
        setup(props) {
            const data = reactive({
                invoices: [],
            });

            const loader = reactive(new Loader());

            const fetchInvoices = function (customer: Ref) {
                loader.start('invoices');
                useApi()
                    .legacy.get(
                        route('customers.invoices.index', {
                            customer: customer.value.id,
                        })
                    )
                    .then(response => {
                        loader.stop('invoices');
                        data.invoices = response.data;
                    });
            };

            const customer = toRef(props, 'customer');
            watch(
                customer,
                newValue => {
                    fetchInvoices(ref(newValue));
                },
                {
                    immediate: true,
                }
            );

            return {
                loader,
                data,
            };
        },
    });
</script>

<style scoped></style>
