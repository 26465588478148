<template>
    <div ref="ignoreElRef">
        <slot
            name="clickable"
            :toggle="toggle"
        />
        <portal
            v-if="isWidgetEnabled"
            to="topbar-widget"
        >
            <transition
                appear
                name="transition-widget"
            >
                <div
                    v-if="isWidgetVisible"
                    ref="target"
                    class="WidgetPortal"
                >
                    <slot :toggle="toggle" />
                </div>
            </transition>
        </portal>
    </div>
</template>

<script lang="ts">
    import {defineComponent, nextTick, ref} from 'vue';
    import {onClickOutside} from '@vueuse/core';
    import useClickOutsideIgnoredElementsStore from '@/modules/meeko-ui/stores/useClickOutsideIgnoredElementsStore';

    export default defineComponent({
        props: {
            clickOutside: {
                type: Boolean,
                default: true,
            },
        },
        setup(props) {
            const isWidgetEnabled = ref(false);
            const isWidgetVisible = ref(false);

            const ignoreElRef = ref();

            const {ignore, add, remove} = useClickOutsideIgnoredElementsStore();

            const target = ref(null);

            onClickOutside(
                target,
                () => {
                    if (props.clickOutside) {
                        hide();
                    }
                },
                {ignore: ignore}
            );

            function show() {
                isWidgetEnabled.value = true;

                nextTick(() => {
                    add(ignoreElRef);
                    isWidgetVisible.value = true;
                });
            }

            function hide() {
                isWidgetVisible.value = false;
                remove(ignoreElRef);

                nextTick(() => {
                    isWidgetEnabled.value = false;
                });
            }

            function toggle() {
                if (isWidgetEnabled.value) {
                    hide();
                } else {
                    show();
                }
            }

            return {
                isWidgetEnabled,
                isWidgetVisible,
                toggle,
                target,
                ignoreElRef,
                ignore,
            };
        },
    });
</script>

<style>
    .WidgetPortal > * {
        @apply tw-pointer-events-auto;
    }
</style>

<style scoped>
    .WidgetPortal {
        @apply tw--left-1 tw--right-1 tw-top-1 sm:tw--left-2 sm:tw--right-2;
        max-height: calc(100vh - 140px);
        @apply tw-pointer-events-none tw-absolute tw-flex tw-justify-end;
    }

    .transition-widget {
        &-enter-active,
        &-leave-active {
            @apply tw-transition-all tw-duration-300 tw-ease-in-out;
        }

        &-enter {
            @apply tw-translate-x-16 tw-opacity-0;
        }

        &-enter-to {
            @apply tw-translate-x-0 tw-translate-y-0 tw-opacity-100;
        }

        &-leave {
            @apply tw-translate-x-0 tw-translate-y-0 tw-opacity-100;
        }

        &-leave-to {
            @apply tw-translate-x-16 tw-opacity-0;
        }
    }
</style>
