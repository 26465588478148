import {datadogRum} from '@datadog/browser-rum';
import {ServiceProvider} from '@meekohq/lumos';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import useAuth from '@/modules/app/composables/useAuth';
import services from '@/config/services';
import app from '@/config/app';

export default class MonitoringServiceProvider extends ServiceProvider {
    public register() {
        if (services.bugsnag.enabled) {
            Bugsnag.start({
                apiKey: services.bugsnag.key,
                plugins: [new BugsnagPluginVue()],
                enabledReleaseStages: ['production', 'staging'],
                releaseStage: app.env,
                appVersion: app.version,
                onError: function (event) {
                    const user = useAuth().user.value;

                    if (user) {
                        event.setUser(user.getKey(), user.attributes.email, user.fullname);
                        event.addMetadata('accountId', {accountId: user.attributes.account_id});
                    }
                },
            });
        }

        if (services.datadog.enabled) {
            datadogRum.init({
                applicationId: services.datadog.appId,
                clientToken: services.datadog.clientToken,
                site: 'datadoghq.eu',
                service: 'manager',
                env: app.env,
                version: app.version,
                enableExperimentalFeatures: ['feature_flags'],
                traceSampleRate: 50,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                startSessionReplayRecordingManually: true,
                trackResources: true,
                trackLongTasks: true,
                trackUserInteractions: true,
                actionNameAttribute: 'data-action-name',
                defaultPrivacyLevel: 'mask',
                allowedTracingUrls: ['https://api.meeko.app', 'https://meeko.app', 'https://staging-manager.meeko.app'],
            });
        }
    }
}
