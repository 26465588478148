<template>
    <CForm>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>{{ __('activity:task_name') }}*</CLabel>
                <CInput
                    v-model="task.attributes.name"
                    autofocus
                />
                <CFormErrorMessageList
                    v-if="taskErrors.hasError('name')"
                    :errors="taskErrors.getErrors('name')"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('activity:due_date') }}</CLabel>
                <CButtonGroup class="tw-w-full">
                    <CFormDatepicker
                        v-model="task.attributes.expired_at"
                        class="tw-w-full"
                        :time="true"
                    />
                    <CFormSelect
                        v-model="prefieldDate"
                        force-fallback
                        :options="prefieldDateOptions"
                        :search-bar="false"
                    >
                        <template #fallback>
                            <CCenter class="tw-min-w-4">
                                <FontAwesomeIcon icon="fa-regular fa-clock" />
                            </CCenter>
                        </template>
                    </CFormSelect>
                </CButtonGroup>
                <CFormErrorMessageList
                    v-if="taskErrors.hasError('expired_at')"
                    :errors="taskErrors.getErrors('expired_at')"
                />
            </CFormGroup>
        </CFormTwoColumns>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>
                    {{ __('activity:reporter') }}
                    <MHelpTooltip class="tw-ml-2">
                        {{
                            __(
                                'activity:the_staff_record_must_be_associated_with_a_user_account_sharing_the_same_email_address'
                            )
                        }}
                    </MHelpTooltip>
                </CLabel>
                <StaffFinder
                    button-class="tw-w-full"
                    :registration="registration"
                    :only-user-organizations="!registration"
                    :value="task.reporter().value()"
                    with-related-user-count
                    @input="associateReporter($event)"
                >
                    <template #default="{option, searchValue}">
                        <div class="tw-flex tw-items-center tw-pr-4">
                            <div class="tw-mr-2">
                                <StaffAvatar
                                    class="tw-w-6"
                                    :staff-model="option"
                                />
                            </div>
                            <div
                                class="tw-truncate"
                                v-html="highlight(searchValue, option.fullname)"
                            />
                        </div>
                    </template>
                </StaffFinder>
                <CFormErrorMessageList
                    v-if="taskErrors.hasError('reporter_id')"
                    :errors="taskErrors.getErrors('reporter_id')"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('activity:assignee_to') }}</CLabel>
                <StaffFinder
                    ref="assignedAtStaffFinder"
                    button-class="tw-w-full"
                    :registration="registration"
                    :only-user-organizations="!registration"
                    :value="assignee"
                    @fallback="dissociateAssignedAt"
                    @input="task.assignee().associate($event)"
                >
                    <template #button-text-empty>
                        {{ __('common:anyone') }}
                    </template>
                    <template #fallback>
                        {{ __('common:anyone') }}
                    </template>
                </StaffFinder>
                <CFormErrorMessageList
                    v-if="taskErrors.hasError('assignee_id')"
                    :errors="taskErrors.getErrors('assignee_id')"
                />
            </CFormGroup>
        </CFormTwoColumns>
        <CFormGroup
            v-if="multiResources"
            class="TaskForm__resources-container"
        >
            <CLabel>{{ __('activity:the_task_concerns') }}</CLabel>
            <ResourcesSelector
                v-model="resources"
                class="TaskForm__resources-selector"
                :placeholder="__('activity:combine_records')"
                @select="addResource($event)"
                @unselect="deletePivot($event)"
            />
            <CFormErrorMessageList
                v-if="taskErrors.hasError('resources')"
                :errors="taskErrors.getErrors('resources')"
            />
        </CFormGroup>
        <CFormGroup v-if="task.attributes.expired_at">
            <CLabel>{{ __('activity:repeats') }}</CLabel>
            <RuleSet
                v-model="recurrence"
                :disabled="!task.isLastOccurrence"
            />
            <CFormErrorMessageList :errors="taskErrors.getErrors('recurrence_ruleset')" />
        </CFormGroup>
        <CFormGroup v-if="task.attributes.expired_at">
            <CLabel>{{ __('activity:reminder') }}</CLabel>
            <Reminder
                v-model="task.attributes.remind_ruleset"
                :disabled="!task.isLastOccurrence"
                :multi="true"
                :should-set-default-rule="!task.exists"
            />
            <CFormErrorMessageList :errors="taskErrors.getErrors('remind_ruleset')" />
        </CFormGroup>
        <CFormGroup>
            <CLabel>{{ __('common:comment') }}</CLabel>
            <CFormTextArea v-model="task.attributes.description" />
            <CFormErrorMessageList
                v-if="taskErrors.hasError('description')"
                :errors="taskErrors.getErrors('description')"
            />
        </CFormGroup>
        <CHStack
            distribute="end"
            gap="2"
        >
            <MButton
                :label="__('common:actions.cancel')"
                @click="undo"
            />
            <MButton
                :disabled="disableSaveButton"
                :label="__('common:actions.save')"
                :loading="loading"
                variant="primary"
                @click="save"
            />
        </CHStack>
    </CForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';
    import {catcher, ForbiddenError, type Model, MqlTransaction, ValidationError} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import RuleSet from '@/modules/calendar/components/RuleSet/RuleSet.vue';
    import Reminder from '@/modules/calendar/components/Reminder/Reminder.vue';
    import ResourcesSelector from '@/modules/app/components/resource/organisms/ResourcesSelector.vue';
    import useSaveTaskRecurrence from '@/modules/activity/composables/useSaveTaskRecurrence';
    import moment from 'moment';
    import useAuth from '@/modules/app/composables/useAuth';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import TicketPivot from '@/modules/activity/ticket/domain/TicketPivot';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import TaskErrorHandler from '@/modules/activity/utils/taskErrorHandler';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import useMagicModal from '@/modules/app/composables/useMagicModal';

    export default defineComponent({
        components: {StaffAvatar, Reminder, RuleSet, StaffFinder, ResourcesSelector},
        props: {
            task: {
                type: Object as PropType<TicketModel>,
                required: true,
            },
            multiResources: {
                type: Boolean,
                default: true,
            },
            registration: {
                type: RegistrationModel,
                default: undefined,
            },
            disableSaveButton: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const {recurrence, saveTaskRecurrenceChanges} = useSaveTaskRecurrence(props.task);

            const assignedAtStaffFinder = ref();

            const loading = ref(false);
            const {user} = useAuth();
            const {error: toastError} = useNotification();
            const reporterIsInvalid = ref(false);
            const resources = ref(
                props.task
                    .ticketPivots()
                    .value()
                    .map(pivot => pivot.resource().value())
                    .merge<TicketPivot>(props.task.extra?.resources ?? [])
            );

            onMounted(() => {
                if (!props.task.exists) {
                    setDefaultValues();
                }
            });

            async function setDefaultValues() {
                // Set default expired_date in 3 days
                prefieldDate.value = prefieldDateOptions[1].value;

                if (user.value.attributes.email) {
                    // Set default staff for reporter and assignee
                    const staff = await StaffModel.query().where('email', user.value.attributes.email).first();
                    if (staff) {
                        if (!props.task.reporter().value()) {
                            props.task.reporter().associate(staff);
                        }

                        if (!props.task.assignee().value()) {
                            props.task.assignee().associate(staff);
                        }
                    }
                }
            }

            function associateReporter(staff: StaffModel) {
                reporterIsInvalid.value = !staff.relationships.user_count;

                props.task.reporter().associate(staff);
            }

            function dissociateAssignedAt() {
                props.task.assignee().dissociate();
                assignedAtStaffFinder.value.$refs.resourceFinderRef.hide();
            }

            const prefieldDateOptions = [
                {value: {value: 1, unit: 'days'}, text: __('common:tomorrow')},
                {value: {value: 3, unit: 'days'}, text: __('activity:in_three_days')},
                {value: {value: 1, unit: 'weeks'}, text: __('activity:in_one_week')},
                {value: {value: 2, unit: 'weeks'}, text: __('activity:in_two_weeks')},
                {value: {value: 1, unit: 'months'}, text: __('activity:in_one_month')},
            ];

            const taskErrors = ref(new TaskErrorHandler());
            const assignee = computed(() => props.task.assignee().value() ?? undefined);

            const prefieldDate = ref();

            watch(prefieldDate, duration => {
                const newDate = moment().add(duration.value, duration.unit);
                props.task.attributes.expired_at = newDate.add(30 - (newDate.minute() % 30), 'm').toISOString();
            });

            function prevalidate(task: TicketModel): boolean {
                const errors: {message: string; code: string; detail: any; source: {pointer: string}}[] = [];

                if (!task.attributes.name) {
                    errors.push({
                        message: __('common:error.required'),
                        code: '0x2EAA628B5E',
                        detail: {},
                        source: {pointer: 'name'},
                    });
                }

                let areAllTicketPivotsMarkedForDelete = false;

                if (task.ticketPivots().value().count()) {
                    areAllTicketPivotsMarkedForDelete = task
                        .ticketPivots()
                        .value()
                        .every(ticketPivot => {
                            const ticketPivotResource = ticketPivot.resource().value();

                            const addedInResources = resources.value.contains(resource => {
                                const sameId = ticketPivotResource.getKey() === resource.getKey();
                                const sameType = ticketPivotResource.getType() === resource.getType();

                                return sameId && sameType;
                            });
                            // Returns true if the pivot is marked for deletion and not added again afterwards in the resources collection

                            return ticketPivot.markedForDeletion && !addedInResources;
                        });
                }

                if (
                    !resources.value.count() &&
                    (!task.ticketPivots().value().count() || areAllTicketPivotsMarkedForDelete)
                ) {
                    errors.push({
                        message: __('common:error.required'),
                        code: '0x2EAA628B5E',
                        detail: {},
                        source: {pointer: 'resources'},
                    });
                }

                if (reporterIsInvalid.value) {
                    errors.push({
                        message: __('activity:errors.staff_must_have_user_account_with_same_email'),
                        code: '0x3599F1FE54',
                        detail: {},
                        source: {pointer: 'reporter_id'},
                    });
                }

                if (errors.length) {
                    taskErrors.value.reset({response: {data: {errors}}});

                    return false;
                }

                return true;
            }

            async function save() {
                loading.value = true;

                // Add original attributes and old pivots resource types to task extra, so we can compare changes
                // and update the task count correctly
                props.task.extra.oldOriginalAttributes = {
                    ...props.task.getOriginal(),
                };
                props.task.extra.oldResourceTypes = props.task
                    .ticketPivots()
                    .value()
                    .map(ticketPivot => ticketPivot.attributes.resource_type);

                if (!prevalidate(props.task) || reporterIsInvalid.value) {
                    loading.value = false;

                    return;
                }

                if (!props.task.attributes.assignee_id) {
                    const result = await useMagicModal().confirmationModal({
                        title: __('activity:this_task_is_not_assigned_to_anyone_it_ll_therefore_be_displayed_for_all'),
                        confirmButtonText: __('common:actions.save'),
                        dismissButtonText: __('common:actions.cancel'),
                    });

                    if (result.isDismissed) {
                        loading.value = false;

                        return;
                    }
                }

                if (!props.task.exists) {
                    props.task.attributes.recurrence_ruleset = recurrence.value;
                }

                await saveTaskRecurrenceChanges();

                const mqlRunner = new MqlTransaction();

                props.task
                    .save({mqlRunner})
                    .then(() => {
                        taskErrors.value.reset();
                    })
                    .catch(e => {
                        catcher()
                            .on(ValidationError, () => taskErrors.value.reset(e))
                            .catch(e);
                    });

                if (resources.value.count()) {
                    saveResources(mqlRunner).catch(e => {
                        catcher()
                            .on(ForbiddenError, () => {
                                toastError(__('common:errors.forbidden'));
                            })
                            .catch(e);
                    });
                }

                // Check if any pivots need to be deleted before saving
                const ticketPivotsToDelete = props.task
                    .ticketPivots()
                    .value()
                    .filter(ticketPivot => {
                        return ticketPivot.markedForDeletion;
                    });

                ticketPivotsToDelete.each(ticketPivot => ticketPivot.save({mqlRunner}));

                try {
                    await mqlRunner.run(true);

                    // Get fresh pivots with needed relationships after saving them
                    const freshTicketPivotsQuery = TicketPivot.query().where('ticket_id', props.task.getKey());
                    TicketPivot.withResourceOrganizations(freshTicketPivotsQuery);
                    const freshTicketPivots = await freshTicketPivotsQuery.get();

                    // Replace pivots by the fresh ones
                    props.task.ticketPivots().mutate(() => freshTicketPivots);
                    loading.value = false;
                    emit('saved', props.task);

                    if (!resources.value.count()) {
                        loading.value = false;
                        emit('saved', props.task);
                    }
                } catch (e) {
                    toastError(__('common:errors.generic'));
                    loading.value = false;
                }
            }

            watch(assignee, (newAssignee, oldAssignee) => {
                if (!newAssignee) {
                    // If assignee is dissociated, we reset the assigned_at
                    props.task.attributes.assigned_at = null;

                    return;
                }

                const updateTaskAssignedAt =
                    (newAssignee && !oldAssignee) || newAssignee.getKey() != oldAssignee.getKey();
                if (updateTaskAssignedAt) {
                    props.task.attributes.assigned_at = moment().format();
                }
            });

            const undo = function () {
                emit('undo');
            };

            function addResource(resource) {
                const alreadyAdded = resources.value.first(existingResource => {
                    return (
                        existingResource.getKey() === resource.getKey() &&
                        existingResource.getType() === resource.getType()
                    );
                });

                const markedForDeletionTicketPivot = props.task
                    .ticketPivots()
                    .value()
                    .first((ticketPivot: TicketPivot) => {
                        return (
                            ticketPivot.attributes.resource_id === resource.getKey() &&
                            ticketPivot.attributes.resource_type === resource.getType() &&
                            ticketPivot.markedForDeletion
                        );
                    });

                if (markedForDeletionTicketPivot) {
                    markedForDeletionTicketPivot.markedForDeletion = false;
                }

                if (!alreadyAdded) {
                    resources.value.push(resource);
                }
            }

            function saveResources(mqlRunner: MqlTransaction) {
                const pivots = resources.value
                    .filter(resource => {
                        // Filter the resources that do not have a TicketPivot
                        return !props.task
                            .ticketPivots()
                            .value()
                            .contains((ticketPivot: TicketPivot) => {
                                const ticketPivotResource = ticketPivot.resource().value();
                                const sameId = ticketPivotResource.getKey() === resource.getKey();
                                const sameType = ticketPivotResource.getType() === resource.getType();

                                return sameId && sameType;
                            });
                    })
                    .map(resource => {
                        // Map the collection items from resources into TicketPivots
                        const ticketPivot = new TicketPivot();
                        ticketPivot.attributes.ticket_id = props.task.getKey();
                        ticketPivot.attributes.account_id = resource.attributes.account_id;
                        ticketPivot.resource().associate(resource);

                        return ticketPivot;
                    });

                const promises = pivots.map(ticketPivot => ticketPivot.save({mqlRunner}));

                return Promise.all(promises);
            }

            function deletePivot(resourceToDelete: Model) {
                const existingPivot = props.task
                    .ticketPivots()
                    .value()
                    .first(ticketPivot => {
                        return (
                            ticketPivot.attributes.resource_id === resourceToDelete.getKey() &&
                            ticketPivot.attributes.resource_type === resourceToDelete.getType()
                        );
                    });

                if (existingPivot) {
                    existingPivot.markForDeletion();
                }
            }

            return {
                assignedAtStaffFinder,
                prefieldDateOptions,
                prefieldDate,
                taskErrors,
                loading,
                associateReporter,
                dissociateAssignedAt,
                save,
                undo,
                assignee,
                recurrence,
                resources,
                addResource,
                deletePivot,
                ...useHighlighter(),
            };
        },
    });
</script>

<style scoped>
    .TaskForm__resources-container {
        @apply tw-min-w-0;
    }

    .TaskForm__resources-selector {
        @apply tw-flex;
    }
</style>
