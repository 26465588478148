<template>
    <div>
        <SelectResource
            ref="SelectResourceEl"
            :builder="builder"
            :button-class="buttonClass"
            :disabled="disabled"
            :has-unselect="hasUnselect"
            :model="KidModel"
            :value="modelValue"
            @fallback="showModal"
            @input="onModelSelected"
            @search="onSearchValue"
        >
            <template #button>
                <template v-if="value">
                    {{ value.fullname }}
                </template>
                <template v-else-if="fallbackText">
                    {{ fallbackText }}
                </template>
                <template v-else>
                    {{ __('family_kid:find_or_add_kid') }}
                </template>
            </template>

            <template
                v-if="addOptionEnabled"
                #fallback="{searchValue}"
            >
                <template v-if="searchValue">
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />
                    {{ __('common:actions.add') }} {{ searchValue }}
                </template>
                <template v-else>
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />{{ __('family_kid:add_kid') }}
                </template>
            </template>

            <template
                v-if="hasUnselect"
                #unselect-item
            >
                <template v-if="unselectText">
                    {{ unselectText }}
                </template>
                <template v-else>
                    {{ __('family_kid:no_kid') }}
                </template>
            </template>

            <template #default="{option, searchValue}">
                <div class="tw-flex tw-pr-4">
                    <div
                        class="tw-truncate"
                        v-html="textHighlighter.highlight(searchValue, option.fullname)"
                    />
                </div>
            </template>
        </SelectResource>
        <slot
            :modal="{searchValue}"
            name="modal"
        >
            <CreateKidModal
                ref="defaultModal"
                :organization-model="createOrganizationModel"
                @created="onModelCreated"
            />
        </slot>
    </div>
</template>

<script setup lang="ts">
    import {computed, type PropType, ref} from 'vue';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';
    import {QueryBuilder} from '@meekohq/lumos';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import KidModel from '@/modules/family/models/KidModel';
    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import CreateKidModal from '@/modules/family/components/kid/CreateKidModal.vue';
    import {clone} from 'lodash-es';

    const props = defineProps({
        value: {
            type: Object as PropType<KidModel>,
            default: undefined,
        },
        filterOrganizationModel: {
            type: Object as PropType<OrganizationModel>,
            required: true,
        },
        createOrganizationModel: {
            type: Object as PropType<OrganizationModel>,
            required: true,
        },
        extendBuilder: {
            type: Object as PropType<QueryBuilder<any>>,
            default: () => new QueryBuilder(),
        },
        customModal: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        addOptionEnabled: {
            type: Boolean,
            default: true,
        },
        hasUnselect: {
            type: Boolean,
            default: true,
        },
        fallbackText: {
            type: String,
            default: undefined,
        },
        unselectText: {
            type: String,
            default: undefined,
        },
        buttonClass: {
            type: String,
            default: undefined,
        },
    });

    const emit = defineEmits(['hideModal', 'showModal', 'input']);

    const modelValue = ref<KidModel | undefined>(clone(props.value));
    const defaultModal = ref<CreateKidModal>();
    const SelectResourceEl = ref<typeof SelectResource>();
    const searchValue = ref('');
    const textHighlighter = ref(new TextHighlighter());

    const builder = computed(() => {
        return KidModel.query()
            .where(query => {
                query
                    .whereLike('first_name', `*${searchValue.value}*`)
                    .orWhereLike('last_name', `*${searchValue.value}*`);
            })
            .whereHas(new KidModel().family(), query => {
                query.whereHas(new FamilyModel().organizations(), query1 => {
                    query1.where('id', props.filterOrganizationModel.getKey());
                });
            })
            .inject(props.extendBuilder);
    });

    function onSearchValue(event: string) {
        searchValue.value = event;
    }

    function onModelCreated(model: KidModel) {
        hideModal();
        onModelSelected(model);
    }

    function hideModal() {
        if (props.customModal) {
            emit('hideModal');
        } else {
            defaultModal.value?.hide();
        }
    }

    function showModal() {
        if (props.customModal) {
            emit('showModal');
        } else {
            defaultModal.value?.show();
        }

        SelectResourceEl.value?.hide();
    }

    function onModelSelected(model: KidModel | undefined) {
        emit('input', model);
    }
</script>
