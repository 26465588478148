<template>
    <CVStack
        v-if="errors.isNotEmpty()"
        class="CFormErrorMessageList"
        gap="2"
    >
        <CFormErrorMessage
            v-for="(error, key) in errors"
            :key="key"
        >
            <template v-if="typeof error === 'string'">
                {{ error }}
            </template>
            <template v-else>
                {{ error.message }}
            </template>
        </CFormErrorMessage>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import type {ErrorType} from '@/modules/app/composables/useError';

    export default defineComponent({
        components: {},
        props: {
            errors: {type: Object as PropType<Collection<string | ErrorType>>, default: () => collect()},
        },
        setup() {
            return {};
        },
    });
</script>
