<template>
    <div>
        <MMenu placement="bottom-end">
            <MMenuButton>
                <MButton>
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-duotone fa-calendar-alt" />
                    </template>
                    {{ __('planning:manage_planning') }}
                </MButton>
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('planning:add_multiple_plannings')"
                    @click="addMultiplePlannings()"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-plus"
                        />
                    </template>
                </MMenuItem>
                <MMenuDivider />
                <MMenuItem
                    class="tw-w-full"
                    :label="__('planning:add_reservation')"
                    @click="addOccasional(false)"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-calendar-plus"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :label="__('planning:add_adaptation')"
                    @click="addAdaptation"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            class="tw-text-yellow-500"
                            fixed-width
                            icon="fa-solid fa-calendar-plus"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    v-if="kid && kid.id"
                    :label="__('planning:add_absence')"
                    @click="addAbsence"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            class="tw-text-red-500"
                            fixed-width
                            icon="fa-solid fa-calendar-minus"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    v-else
                    :label="__('planning:add_absence')"
                    @click="addAbsences"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            class="tw-text-red-500"
                            fixed-width
                            icon="fa-solid fa-calendar-minus"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
        <AddKidsAbsences
            ref="addKidsAbsences"
            :date="date"
            :nursery="nursery"
        />
        <AddMultiplePlanningsModal
            v-if="showMulplePlanningsModal"
            :kid="kid"
            @hidden="showMulplePlanningsModal = false"
        />
    </div>
</template>

<script>
    import moment from 'moment';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useAuth from '@/modules/app/composables/useAuth';
    import AddKidsAbsences from '@/modules/legacy/components/Modules/PlanningGenerator/AddKidsAbsences.vue';
    import AddMultiplePlanningsModal from '@/modules/legacy/components/Modules/PlanningSummary/AddMultiplePlanningsModal.vue';
    import useEditEventModal from '@/modules/planning/composables/core/useEditEventModal';

    export default {
        components: {
            AddKidsAbsences,
            AddMultiplePlanningsModal,
        },
        mixins: [nursery],
        props: {
            nursery: {},
            date: {},
            kid: {},
        },
        data: () => ({
            showMulplePlanningsModal: false,
        }),
        computed: {
            user() {
                return useAuth().user.value;
            },
        },
        methods: {
            addAbsences() {
                this.$refs.addKidsAbsences.$refs.modal.show();
            },

            addAbsence() {
                const opening = this.$getOpening(this.nursery.openingHours, false);
                const closing = this.$getClosing(this.nursery.openingHours, false);

                const date = moment(this.date).format('YYYY-MM-DD');

                const segment = {};
                segment.started_at = moment(date + ' ' + opening, 'YYYY-MM-DD HH:mm').format();
                segment.ended_at = moment(date + ' ' + closing, 'YYYY-MM-DD HH:mm').format();
                segment.kid_id = this.kid?.id;
                segment.nursery_id = this.nursery.id;
                segment.type = 'absence';
                segment.note = null;
                segment.contract_hourly_rate = null;
                segment.hourly_rate = null;
                segment.isNew = true;
                segment.rangeAbsence = true;
                segment.unit = 'day';

                useEditEventModal().show(segment);
            },

            addOccasional(isAdaptation) {
                const opening = this.$getOpening(this.nursery.openingHours, false);
                const closing = this.$getClosing(this.nursery.openingHours, false);

                const date = moment(this.date).format('YYYY-MM-DD');

                const segment = {};
                segment.started_at = moment(date + ' ' + opening, 'YYYY-MM-DD HH:mm').format();
                segment.ended_at = moment(date + ' ' + closing, 'YYYY-MM-DD HH:mm').format();
                segment.start_time = moment(opening, 'HH:mm').format('HH:mm:ss');
                segment.end_time = moment(closing, 'HH:mm').format('HH:mm:ss');
                segment.kid_id = this.kid?.id;
                segment.nursery_id = this.nursery.id;
                segment.type = isAdaptation ? 'adaptation' : 'occasional';
                segment.note = null;
                segment.contract_hourly_rate = null;
                segment.hourly_rate = null;
                segment.isNew = true;
                segment.unit = 'day';

                useEditEventModal().show(segment);
            },

            addAdaptation() {
                this.addOccasional(true);
            },

            addMultiplePlannings() {
                this.showMulplePlanningsModal = true;
            },
        },
    };
</script>
