<template>
    <CAlert
        :close-button="false"
        :title-text="__('components:item_already_exists')"
    >
        <div>
            <ul class="tw-mb-1">
                <CInline
                    v-for="({model, organizations}, index) in modelInOtherOrganization"
                    :key="index + model.attributes.name"
                    align-x="between"
                    class="tw-items-baseline"
                >
                    <CInline align-y="center">
                        <slot
                            :model="model"
                            name="item"
                        />
                        <template v-if="organizations.count() >= 1">
                            <span class="tw-mr-1 tw-text-base">{{ __('components:is_used_in') }} </span>
                            <CTooltip
                                class="tw-cursor-pointer"
                                placement="bottom"
                            >
                                <template>
                                    <span>
                                        {{
                                            __('components:organization_with_name', {
                                                count: organizations.count(),
                                                name: getName(organizations),
                                            })
                                        }}
                                    </span>
                                </template>
                                <template #content>
                                    <ul>
                                        <div class="tw-mb-2 tw-text-base">
                                            {{ __('common:list_of_organizations') }}
                                        </div>
                                        <li
                                            v-for="(organization, i) in organizations"
                                            :key="i + organization.attributes.name"
                                        >
                                            - {{ organization.attributes.name }}
                                        </li>
                                    </ul>
                                </template>
                            </CTooltip>
                        </template>
                        <template v-else>
                            <span class="tw-text-base">{{ __('components:is_used_in_account') }}</span>
                        </template>
                    </CInline>
                    <div>
                        <MButton
                            :key="index"
                            class="tw-mr-1 tw-mt-1.5"
                            variant="primary"
                            @click="attach(model)"
                        >
                            {{ __('common:actions.import') }}
                        </MButton>
                    </div>
                </CInline>
            </ul>
        </div>
    </CAlert>
</template>

<script>
    export default {
        components: {},
        props: {
            modelInOtherOrganization: {
                required: true,
            },
        },
        methods: {
            attach(model) {
                this.$emit('attachModel', model);
            },

            getName(organizations) {
                return organizations.first().attributes.name;
            },
        },
    };
</script>
