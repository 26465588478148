<template>
    <MPopover placement="top">
        <CCard
            body-size="sm"
            class="tw-cursor-pointer hover:tw-border-blue-200 hover:tw-bg-blue-50"
        >
            <CHStack gap="2">
                <RegistrationItemKidName
                    class="tw-mr-auto"
                    :registration="registration"
                />
                <CTooltip
                    class="tw-cursor-pointer"
                    placement="bottom"
                >
                    <CBadge
                        v-if="isRegistrationAccepted"
                        class="tw-flex tw-items-center tw-p-1"
                        variant="success"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="check"
                        />
                        {{ __('planning:accepted') }}
                    </CBadge>
                    <template #content>
                        <CText>{{ __('planning:contract_instead_of_registration') }}</CText>
                    </template>
                </CTooltip>
                <CBadge
                    v-if="isRegistrationRejected"
                    class="tw-flex tw-items-center tw-p-1"
                    variant="danger"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="archive"
                    />
                    {{ __('planning:rejected') }}
                </CBadge>
            </CHStack>
            <CHStack
                distribute="between"
                gap="4"
                wrap
            >
                <CVStack
                    class="tw-mb-2 xl:tw-mb-0"
                    gap="2"
                >
                    <CHStack gap="4">
                        <CVStack class="tw-mr-auto xl:tw-mr-0">
                            <CText>
                                {{
                                    `${useKidAge().getByBirthdate(
                                        Epoch.fromISOString(registration.attributes.kid_birthdate),
                                        Epoch.fromISOString(registration.attributes.contract_date_period.started_at)
                                    )} ${__('registration:on_entry')}`
                                }}
                            </CText>
                            <CText>
                                {{
                                    __('planning:incomes_colon_amount_and_currency', {
                                        amount:
                                            registration.attributes.first_parent_salary +
                                            registration.attributes.second_parent_salary,
                                        currency: activeOrganization.attributes.currency,
                                    })
                                }}
                            </CText>
                        </CVStack>
                    </CHStack>
                    <CVStack>
                        <CHStack align="center">
                            <CText>
                                {{ contractStartedAt.toLocaleString(Epoch.presets.DATE_SHORT) }}
                            </CText>
                            <i class="fal fa-arrow-right tw-mx-2" />
                            <CText>
                                {{ contractEndedAt.toLocaleString(Epoch.presets.DATE_SHORT) }}
                            </CText>
                        </CHStack>
                        <CText variant="primary">
                            {{
                                useDuration().toHuman({
                                    dateFrom: contractStartedAt,
                                    dateTo: contractEndedAt,
                                    options: ({
                                        customSuffix: ' ',
                                        forKids: true,
                                    }),
                                })
                            }}
                        </CText>
                    </CVStack>
                </CVStack>
                <div class="tw-flex tw-items-center tw-justify-self-center xl:tw-justify-self-end">
                    <KidPlanningSummary
                        class="xl:tw-grid xl:tw-auto-cols-max"
                        :nursery="legacyNursery"
                        :plannings="planningsMapped"
                        :weeks="registration.attributes.contract_recurrent_weeks"
                    />
                </div>
            </CHStack>
        </CCard>
        <template #content>
            <CVStack
                class="tw-cursor-auto"
                gap="2"
            >
                <CHStack
                    class="tw-p-3"
                    gap="4"
                >
                    <CVStack gap="2">
                        <RegistrationItemKidName :registration="registration" />
                        <CVStack>
                            <CText font-size="sm">
                                {{ kidBirthdayInformations }}
                            </CText>
                            <CText
                                v-if="registration.attributes.kid_birthplace"
                                font-size="sm"
                            >
                                {{
                                    __('planning:birthplace', {
                                        birthplace: registration.attributes.kid_birthplace,
                                    })
                                }}
                            </CText>
                        </CVStack>
                    </CVStack>
                    <div>
                        <TagsSelector
                            :editable="can('update', 'registrations')"
                            size="sm"
                            :taggable-model="registration"
                            taggable-type="registration"
                            :tags="registration.tags().value().toArray()"
                            @attached="registration.tags().value().push($event)"
                            @detached="registration.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                        />
                    </div>
                </CHStack>
                <CHStack
                    class="tw-border-t tw-border-gray-300 tw-p-4"
                    distribute="center"
                >
                    <MButton
                        v-if="!isInSimulation"
                        :disabled="!can('update', 'registrations')"
                        :loading="buttonLoading"
                        variant="primary"
                        @click="emitRegistrationWithStopLoadingCallback('add')"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-plus-circle"
                        />
                        {{ __('planning:add_to_list') }}
                    </MButton>
                    <MButton
                        v-else
                        class="tw-text-danger-500"
                        :disabled="!can('update', 'registrations')"
                        :loading="buttonLoading"
                        @click="emitRegistrationWithStopLoadingCallback('remove')"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon
                                class="tw-text-danger-500"
                                icon="fa-solid fa-trash-can"
                            />
                        </template>
                        {{ __('planning:remove_from_list') }}
                    </MButton>
                </CHStack>
            </CVStack>
        </template>
    </MPopover>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import RegistrationItemKidName from '@/modules/planning/components/simulation/RegistrationItemKidName.vue';
    import KidPlanningSummary from '@/modules/legacy/components/Modules/KidPlanningSummary.vue';
    import type RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import useManager from '@/modules/app/composables/useManager';
    import useAbility from '@/modules/app/composables/useAbility';
    import __ from '@/modules/app/utils/i18n-facade';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import {Epoch} from '@meekohq/lumos';
    import useDuration from '@/modules/app/composables/useDuration';
    import useKidAge from '@/modules/family/composables/kid/useKidAge';

    export default defineComponent({
        components: {
            TagsSelector,
            RegistrationItemKidName,
            KidPlanningSummary,
        },
        props: {
            registration: {
                type: Object as PropType<RegistrationModel>,
                required: true,
            },
            isInSimulation: {
                type: Boolean,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {can} = useAbility();

            const {activeOrganization, legacyNursery} = useManager();
            const activeOrganizationTags = computed(() => activeOrganization.value.tags().value());

            const kidBirthdayInformations = computed(() => {
                return `${Epoch.fromISOString(props.registration.attributes.kid_birthdate!).toLocaleString(Epoch.presets.DATE_SHORT)} -  ${useKidAge().getByBirthdate(
                    Epoch.fromISOString(props.registration.attributes.kid_birthdate!),
                    Epoch.fromISOString(props.registration.attributes.contract_date_period.started_at!)
                )} ${__('registration:on_entry')}`;
            });

            const contractStartedAt = computed(() => {
                return Epoch.fromISOString(props.registration.attributes.contract_date_period.started_at!);
            });

            const contractEndedAt = computed(() => {
                return Epoch.fromISOString(props.registration.attributes.contract_date_period.ended_at!);
            });

            const registrationPivot = computed(() => {
                return props.registration
                    .organizations()
                    .value()
                    .first(
                        (organization: OrganizationModel) => organization.getKey() === activeOrganization.value.getKey()
                    )
                    .pivot();
            });

            const isRegistrationAccepted = computed(() => {
                return registrationPivot.value.attributes.kid_id;
            });

            const isRegistrationRejected = computed(() => {
                return registrationPivot.value.attributes.rejected;
            });

            const buttonLoading = ref(false);

            /*
             * TODO: TO REMOVE
             * Retrieved legacy nursery to display KidPlanningSummary component
             * Legacy data like the nursery opening hours is needed and not yet
             * available in the new API. The nursery.openingHours property
             * is computed in the NurseryTransformer.
             *
             * The plannings are mapped for the same reason. The data needed is in the legacy
             * model format and the type property is set in the PlanningTransformer
             */

            const planningsMapped = props.registration
                .plannings()
                .value()
                .map(planning => {
                    const attributes = JSON.parse(JSON.stringify(planning.attributes));
                    attributes.start_time = attributes.time_period.started_at;
                    attributes.end_time = attributes.time_period.ended_at;
                    attributes.type = 'recurrent';

                    return attributes;
                })
                .toArray();

            function emitRegistrationWithStopLoadingCallback(eventName: string) {
                buttonLoading.value = true;
                emit(eventName, {
                    registration: props.registration,
                    stopLoading: () => (buttonLoading.value = false),
                });
            }

            return {
                can,
                activeOrganization,
                activeOrganizationTags,
                kidBirthdayInformations,
                contractStartedAt,
                contractEndedAt,
                Epoch,
                legacyNursery,
                planningsMapped,
                buttonLoading,
                emitRegistrationWithStopLoadingCallback,
                isRegistrationAccepted,
                isRegistrationRejected,
            };
        },
        methods: {useKidAge, useDuration},
    });
</script>
