<template>
    <div class="tw-flex tw-flex-wrap tw-items-center tw-border-t tw-border-gray-200 tw-pt-2 md:tw-text-center">
        <div class="tw-order-1 tw-my-2 tw-w-1/2 md:tw-w-1/6">
            <div class="tw-flex tw-items-center">
                <FontAwesomeIcon
                    v-if="planning.success"
                    class="tw-mr-3 tw-pl-2 tw-text-green-500"
                    icon="fa-solid fa-check"
                />
                <CFormSelect
                    v-model="planning.type"
                    button-class="CButton-sm"
                    class="tw-mr-3 tw-w-full"
                    :disabled="disabled"
                    :options="planningTypeOptions"
                    :search-bar="false"
                    :variant="planningTypeOptions.find(item => item.value === planning.type).variant"
                />
            </div>
        </div>
        <div class="tw-order-3 tw-my-2 tw-w-full md:tw-order-2 md:tw-w-1/6">
            <CFormDatepicker
                v-model="planning.started_at"
                class="tw-mr-3"
                :disabled="disabled"
            />
        </div>
        <div class="tw-order-4 tw-my-2 tw-w-1/2 tw-pr-3 md:tw-order-3 md:tw-w-1/6">
            <MTimePicker
                v-model="planning.start_time"
                class="tw-w-full"
                :disabled="disabled"
                :modifier="startOfMinute"
            />
        </div>
        <div class="tw-order-5 tw-my-2 tw-w-1/2 tw-pr-3 md:tw-order-4 md:tw-w-1/6">
            <MTimePicker
                v-model="planning.end_time"
                class="tw-w-full"
                :disabled="disabled"
                :modifier="startOfMinute"
            />
        </div>
        <div class="tw-order-6 tw-my-2 tw-w-full md:tw-order-5 md:tw-w-1/6">
            <MPopover
                class="tw-w-full"
                placement="top"
                popover-button-class="tw-w-full"
            >
                <MButton
                    class="tw-w-full"
                    :disabled="disabled"
                    icon-edit
                >
                    {{ totalText }}
                </MButton>
                <template #content>
                    <div v-if="planning.type === 'absence'">
                        <CFormGroup>
                            <CLabel>
                                {{ __('planning:how_to_deduct') }}
                            </CLabel>
                            <CButtonGroup>
                                <MButton
                                    size="sm"
                                    :variant="planning.unit === 'day' ? 'primary' : 'light'"
                                    @click="planning.unit = 'day'"
                                >
                                    {{ __('common:the_day') }}
                                </MButton>
                                <MButton
                                    size="sm"
                                    :variant="planning.unit === 'halfday' ? 'primary' : 'light'"
                                    @click="planning.unit = 'halfday'"
                                >
                                    {{ __('common:the_halfday') }}
                                </MButton>
                                <MButton
                                    size="sm"
                                    :variant="planning.unit === 'hour' ? 'primary' : 'light'"
                                    @click="planning.unit = 'hour'"
                                >
                                    {{ __('planning:units.by_the_hour') }}
                                </MButton>
                                <MButton
                                    size="sm"
                                    :variant="planning.unit === 'free' ? 'primary' : 'light'"
                                    @click="planning.unit = 'free'"
                                >
                                    {{ __('planning:units.non_deducted') }}
                                </MButton>
                            </CButtonGroup>
                        </CFormGroup>
                        <CFormGroup class="tw-mt-3">
                            <CCheckbox v-model="planning.vacation">
                                {{ __('planning:take_a_vacation_day_off') }}
                            </CCheckbox>
                        </CFormGroup>
                    </div>
                    <div v-else>
                        <CFormGroup class="tw-mb-4">
                            <CLabel>
                                {{ __('planning:how_to_bill_planning') }}
                            </CLabel>
                            <CButtonGroup>
                                <MButton
                                    size="sm"
                                    :variant="planning.unit === 'day' ? 'primary' : 'light'"
                                    @click="planning.unit = 'day'"
                                >
                                    {{ __('common:the_day') }}
                                </MButton>
                                <MButton
                                    size="sm"
                                    :variant="planning.unit === 'halfday' ? 'primary' : 'light'"
                                    @click="planning.unit = 'halfday'"
                                >
                                    {{ __('common:the_halfday') }}
                                </MButton>
                                <MButton
                                    size="sm"
                                    :variant="planning.unit === 'hour' ? 'primary' : 'light'"
                                    @click="planning.unit = 'hour'"
                                >
                                    {{ __('planning:units.by_the_hour') }}
                                </MButton>
                                <MButton
                                    size="sm"
                                    :variant="planning.unit === 'free' ? 'primary' : 'light'"
                                    @click="planning.unit = 'free'"
                                >
                                    {{ __('planning:units.free_female') }}
                                </MButton>
                            </CButtonGroup>
                        </CFormGroup>
                        <CFormGroup>
                            <div
                                v-if="planning.unit === 'hour'"
                                class="tw-flex tw-items-center tw-gap-4"
                            >
                                <CInputGroup>
                                    <CInput
                                        v-model="planning.hours"
                                        class="tw-w-20"
                                        size="sm"
                                    />
                                    <CInputAddon>{{ __('common:hour_lower_short') }}</CInputAddon>
                                </CInputGroup>

                                <CInputGroup>
                                    <CInput
                                        v-model="planning.hourly_rate"
                                        class="tw-w-20"
                                        size="sm"
                                    />
                                    <CInputAddon>
                                        {{ organization.currency }} {{ __('common:per_hour_short') }}
                                    </CInputAddon>
                                </CInputGroup>
                            </div>
                            <div v-else-if="planning.unit !== 'free'">
                                <CInputGroup>
                                    <CInput
                                        v-model="planning.hourly_rate"
                                        class="tw-w-20"
                                        size="sm"
                                    />
                                    <CInputAddon>
                                        {{ organization.currency }} / {{ __('common:hour_lower_short') }}
                                    </CInputAddon>
                                </CInputGroup>
                            </div>
                        </CFormGroup>
                    </div>
                </template>
            </MPopover>
        </div>
        <div class="tw-order-2 tw-my-2 tw-w-1/2 tw-text-right md:tw-order-6 md:tw-w-1/6">
            <CText
                v-if="planning.loading"
                class="tw-pr-2 tw-text-yellow-600"
                font-weight="semibold"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa-solid fa-circle-notch"
                    spin
                />{{ __('common:in_progress') }}
            </CText>
            <template v-else-if="planning.success">
                <CText
                    class="tw-pr-2"
                    font-weight="semibold"
                    variant="success"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-check"
                    />{{ __('common:actions.added') }}
                </CText>
                <CTooltip>
                    <MButton
                        size="sm"
                        @click="$emit('remove')"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-eye-slash" />
                    </MButton>
                    <div slot="content">
                        {{ __('common:actions.hide') }}
                    </div>
                </CTooltip>
            </template>
            <MButton
                v-else
                :disabled="disabled"
                size="sm"
                variant="danger"
                @click="$emit('remove')"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa-solid fa-trash-alt"
                />{{ __('common:actions.delete') }}
            </MButton>
        </div>
        <CText
            v-if="planning.error"
            as="div"
            class="tw-order-7"
            font-size="sm"
            variant="danger"
        >
            <FontAwesomeIcon
                class="tw-mr-1"
                icon="fa-duotone fa-exclamation-triangle"
            />{{ planning.error }}
        </CText>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, watch} from 'vue';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import {useDateTimeModifiersPresets} from '@/modules/meeko-ui/composables/useDateTimeModifiers';

    export default defineComponent({
        props: {
            planning: {
                type: Object,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {legacyNursery: organization} = useManager();
            const {startOfMinute} = useDateTimeModifiersPresets();

            const planningTypeOptions = [
                {
                    value: 'occasional',
                    text: __('common:occasional'),
                    variant: 'primary',
                },
                {
                    value: 'adaptation',
                    text: __('common:adaptation'),
                    variant: 'yellow',
                },
                {
                    value: 'absence',
                    text: __('common:absence'),
                    variant: 'danger',
                },
            ];

            watch(
                () => props.planning.type,
                (val, oldVal) => {
                    const previousPlanning = _cloneDeep(props.planning);
                    previousPlanning.type = oldVal;
                    emit('change', props.planning, previousPlanning);
                }
            );
            watch(
                () => props.planning.started_at,
                (val, oldVal) => {
                    const previousPlanning = _cloneDeep(props.planning);
                    previousPlanning.started_at = oldVal;
                    emit('change', props.planning, previousPlanning);
                }
            );

            watch(
                () => [props.planning.start_time, props.planning.end_time, props.planning.unit],
                () => {
                    if (props.planning.unit === 'hour') {
                        props.planning.hours = getHours();
                    }
                },
                {immediate: true}
            );

            function getHours() {
                if (!props.planning.start_time || !props.planning.end_time) {
                    return null;
                }

                return props.planning.start_time.difference(props.planning.end_time).as('hour');
            }

            const totalPrice = computed(() => {
                let total = props.planning.hourly_rate;
                const hoursInDay = props.planning.hours_in_day;

                switch (props.planning.unit) {
                    case 'day':
                        total = total * hoursInDay;
                        props.planning.hours = hoursInDay;
                        break;
                    case 'halfday':
                        total = (total * hoursInDay) / 2;
                        props.planning.hours = hoursInDay / 2;
                        break;
                    case 'hour':
                        total = total * props.planning.hours;
                        break;
                    case 'free':
                        total = 0;
                        break;
                    default:
                        total = 0;
                }

                return Math.round(total * 100) / 100;
            });

            const totalText = computed(() => {
                if (props.planning.type === 'absence') {
                    switch (props.planning.unit) {
                        case 'day':
                            return __('planning:units.deducted_day');
                        case 'halfday':
                            return __('planning:units.deducted_halfday');
                        case 'hour':
                            return __('planning:units.deducted_hours');
                        case 'free':
                            return __('planning:units.non_deducted');
                        default:
                            return '';
                    }
                }

                return totalPrice.value
                    ? `${totalPrice.value}${organization.value.currency}`
                    : __('planning:units.free_male');
            });

            const disabled = computed(() => {
                return props.planning.loading || props.planning.success;
            });

            return {
                startOfMinute,
                organization,
                planningTypeOptions,
                totalText,
                disabled,
            };
        },
    });
</script>
