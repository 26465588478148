<template>
    <WeeklyPlanningSkeleton v-if="loading" />
    <CHStack
        v-else-if="!activeContractKids || !activeContractKids.length"
        align="center"
        distribute="center"
    >
        <CText>{{ __('family_kid:no_kid') }}</CText>
    </CHStack>
    <CVStack
        v-else
        gap="2"
    >
        <CHStack
            v-for="(kid, i) in activeContractKids"
            :key="i"
            class="tw-flex-wrap"
        >
            <CTooltip>
                <div class="tw-flex tw-w-full tw-items-center md:tw-w-52">
                    <CHStack
                        align="end"
                        class="tw-mr-2 tw-h-6 tw-w-6"
                    >
                        <LegacyKidAvatar
                            class="tw-w-6"
                            :kid="kid"
                        />
                        <FontAwesomeIcon
                            v-if="kid.contracts[0].draft"
                            class="tw-relative tw--top-3 tw-right-2 tw-text-sm"
                            :class="[kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl']"
                            icon="fa-solid fa-info-circle"
                        />
                    </CHStack>
                    <div
                        class="tw-flex tw-h-full tw-items-center tw-truncate tw-font-grandhotel tw-text-xl tw-capitalize"
                    >
                        <router-link
                            v-if="$can('read', 'kids_details')"
                            :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                            :to="{name: 'kids.show', params: {nursery: activeOrganization.getKey(), kid: kid.id}}"
                        >
                            {{ $fullName(kid) }}
                        </router-link>
                        <span
                            v-else
                            :class="[kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl', 'tw-truncate']"
                        >
                            {{ $fullName(kid) }}
                        </span>
                    </div>
                </div>
                <template
                    v-if="kid.contracts[0].draft"
                    #content
                >
                    {{ __('planning:contract_instead_of_registration') }}
                </template>
            </CTooltip>
            <CHStack
                class="tw-mt-1 tw-w-full md:tw-mt-0 md:tw-flex-1"
                gap="2"
            >
                <DailyKidPlanning
                    v-for="(dayOfWeek, dayIndex) in daysInPeriod"
                    :key="'day-' + dayIndex"
                    :day="dayOfWeek"
                    :kid="kid"
                />
            </CHStack>
        </CHStack>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import DailyKidPlanning from '@/modules/planning/components/simulation/Planning/DailyKidPlanning.vue';
    import WeeklyPlanningSkeleton from '@/modules/planning/components/simulation/Planning/WeeklyPlanningSkeleton.vue';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import useEnumerateBetweenDates from '@/modules/planning/composables/core/useEnumerateBetweenDates';
    import useManager from '@/modules/app/composables/useManager';
    import moment from 'moment';
    import type {CustomObject} from '@meekohq/lumos';
    import {MqlOperation} from '@meekohq/lumos';
    import type RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';

    export default defineComponent({
        components: {LegacyKidAvatar, WeeklyPlanningSkeleton, DailyKidPlanning},
        props: {
            from: {
                type: Number,
                required: true,
            },
            to: {
                type: Number,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            registrations: {
                type: Array as PropType<RegistrationModel[]>,
                default: () => [],
            },
            kidsGroupId: {
                type: String,
                default: () => null,
            },
            kidsTagIds: {
                type: Array as PropType<number[]>,
                default: () => [],
            },
            kidsPlanningTypes: {
                type: Array as PropType<number[]>,
                default: () => [],
            },
        },
        setup(props) {
            const loading = ref(false);

            const {activeOrganization} = useManager();
            const activeContractKids = ref();

            const {getDays} = useEnumerateBetweenDates();
            const daysInPeriod = computed(() => getDays(moment.unix(props.from), moment.unix(props.to)));

            const registrationIds = computed(() => props.registrations.map(registration => registration.getKey()));

            function getActiveContractKidsPlannings() {
                loading.value = true;

                new MqlOperation<CustomObject>('planning/kids_plannings', {
                    from: props.from,
                    to: props.to,
                    organization_id: activeOrganization.value.getKey(),
                    kids_group_ids: props.kidsGroupId ? [props.kidsGroupId] : [],
                    kids_tag_ids: props.kidsTagIds ? props.kidsTagIds : [],
                    kids_planning_types: props.kidsPlanningTypes ? props.kidsPlanningTypes : [],
                    registration_ids: registrationIds.value ? registrationIds.value : [],
                })
                    .run()
                    .then(async result => {
                        loading.value = false;
                        activeContractKids.value = result.content.data;
                    });
            }

            watch(
                props,
                () => {
                    getActiveContractKidsPlannings();
                },
                {immediate: true}
            );

            return {
                activeContractKids,
                activeOrganization,
                daysInPeriod,
                loading,
                moment,
            };
        },
    });
</script>
