<template>
    <div class="table-responsive">
        <div class="small">
            <FontAwesomeIcon
                class="tw-mr-1"
                icon="fa-solid fa-info-circle"
            />{{ __('billing_setting:hour_per_week_explanation') }}
        </div>
        <table class="table-sm">
            <thead>
                <tr>
                    <th scope="col" />
                    <th
                        v-for="(hour, key) in prices.hours"
                        :key="key"
                        scope="col"
                        style="min-width: 100px"
                    >
                        <CInputGroup>
                            <CInputAddon>
                                {{ key === prices.hours.length - 1 ? '>' : '≤' }}
                            </CInputAddon>
                            <MNumberInput
                                v-model.number="hour.value"
                                :parser-options="parserOptionsCurrencyPerHour"
                                placeholder="10"
                            />
                            <CInputAddon>
                                {{ __('common:hour_short') }}
                            </CInputAddon>
                        </CInputGroup>
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-text-right"
                        scope="col"
                    >
                        <MButton
                            class="tw-mr-1"
                            variant="success"
                            @click="addHour"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-plus-circle" />
                        </MButton>
                        <MButton
                            v-if="prices && prices.hours && prices.hours.length > 2"
                            variant="danger"
                            @click="subHour"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-times-circle" />
                        </MButton>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(bracket, index) in prices.brackets"
                    :key="'bracket ' + index"
                >
                    <th
                        v-if="prices.brackets.length > 1"
                        class="tw-pr-2"
                        scope="row"
                    >
                        {{ __('billing_setting:t_value', {value: index + 1}) }}
                    </th>
                    <th
                        v-else
                        scope="row"
                    />
                    <th
                        v-for="(price, i) in bracket"
                        :key="'price ' + i"
                        style="min-width: 100px"
                    >
                        <CInputGroup class="tw-font-normal">
                            <MCurrencyInput
                                v-model.number="price.value"
                                :currency-symbol="nursery.currency"
                                :parser-options="parserOptionsCurrencyPerHour"
                                placeholder="9"
                            >
                                <template #addon>
                                    {{ __('common:currency_per_hour', {currency: nursery.currency}) }}
                                </template>
                            </MCurrencyInput>
                        </CInputGroup>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import _forEach from 'lodash-es/forEach';
    import {getNumericParserContractPresetCurrencyPerHour} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import i18next from 'i18next';

    export default {
        props: ['prices', 'brackets', 'nursery'],
        data() {
            return {
                unit: 'hour',
                hours: [{value: 10}, {value: 20}, {value: 30}, {value: 30}],
                parserOptionsCurrencyPerHour: getNumericParserContractPresetCurrencyPerHour(i18next.language),
            };
        },
        computed: {},
        watch: {
            brackets() {
                this.reset();
            },
        },
        created() {
            this.parserOptionsCurrencyPerHour.allowNegative = true;
        },
        mounted() {
            if (this.prices.unit !== this.unit) {
                this.reset();
            }
        },
        methods: {
            addHour() {
                this.prices.hours.push({value: null});
                _forEach(this.prices.brackets, bracket => {
                    bracket.push({value: 10});
                });
            },
            subHour() {
                this.prices.hours.splice(this.hours.length - 1, 1);
                _forEach(this.prices.brackets, bracket => {
                    bracket.splice(bracket.length - 1, 1);
                });
            },
            reset() {
                const brackets = [];
                for (let i = 0; i < this.brackets; i++) {
                    brackets.push([{value: 10}, {value: 10}, {value: 10}, {value: 10}]);
                }
                this.$emit('updatePrice', {
                    unit: this.unit,
                    hours: this.hours,
                    brackets,
                });
            },
        },
    };
</script>

<style scoped>
    th {
        vertical-align: middle !important;
    }
</style>
