<template>
    <CModal
        ref="modal"
        :header-title="title"
        :size="size"
        visible
        @hidden="$emit('hidden')"
    >
        <template #header-text>
            <slot name="header-text" />
        </template>
        <slot>
            {{ text }}
        </slot>
        <template #footer-end="{closeDialog}">
            <MButton @click="cancel(closeDialog)">
                {{ __('common:actions.cancel') }}
            </MButton>
            <MButton
                :disabled="confirmButtonDisabled"
                :variant="buttonVariant"
                @click="confirm(closeDialog)"
            >
                {{ confirmText }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {},
        props: {
            title: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                default: undefined,
                required: false,
            },
            size: {
                type: String,
                default: 'sm',
                required: false,
            },
            buttonVariant: {
                type: String,
                default: 'danger',
                required: false,
            },
            confirmText: {
                type: String,
                default: () => __('common:actions.delete'),
                required: false,
            },
            confirmButtonDisabled: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        setup(props, {emit}) {
            function cancel(closeDialog) {
                closeDialog();
                emit('canceled');
            }

            function confirm(closeDialog) {
                closeDialog();
                emit('confirmed');
            }

            return {
                cancel,
                confirm,
            };
        },
    });
</script>
