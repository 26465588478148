<template>
    <div>
        <div class="tw-mb-2 tw-flex tw-flex-col lg:tw-flex-row">
            <KidsPlaningFilters
                :contract-states="contractStates"
                :nursery="nursery"
                :selected-group="selectedGroup"
                @contract:updated="contractStates = $event"
                @group:updated="selectedGroup = $event"
                @tags:updated="selectedTags = $event"
            />
        </div>

        <div v-if="!loading">
            <template v-if="kids.length">
                <div class="row">
                    <div
                        v-for="(kid, i) in kids"
                        :key="'kid' + i"
                        class="col-6 col-lg-4"
                    >
                        <div
                            class="card zoom tw-mb-4 tw-cursor-pointer tw-p-2"
                            @click="selectedKid = kid"
                        >
                            <div class="row">
                                <div class="col-4">
                                    <LegacyKidAvatar
                                        class="tw-float-left tw-mr-2 tw-w-12"
                                        :kid="kid"
                                    />
                                </div>
                                <div
                                    class="col-8 tw-self-center sm:tw-pl-1"
                                    :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                >
                                    {{ $fullName(kid) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="total > perPage"
                    class="tw-mt-4 tw-flex tw-justify-center"
                >
                    <MPagination
                        :paginator="paginator"
                        @navigate="currentPage = $event"
                    />
                </div>
            </template>
            <h4
                v-else
                class="h4 tw-text-center"
            >
                {{ __('common:no_kids') }}
            </h4>
        </div>

        <loader
            v-else
            light="true"
            shadow="false"
        />
    </div>
</template>

<script>
    import _debounce from 'lodash-es/debounce';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import KidsPlaningFilters from '@/modules/family/components/kid/Index/KidsPlaningFilters.vue';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import {url} from '@/modules/legacy/mixins/url';

    export default {
        components: {
            MPagination,
            LegacyKidAvatar,
            KidsPlaningFilters,
        },
        mixins: [url],
        props: {
            nursery: {},
        },
        data() {
            return {
                loading: true,
                kids: [],
                selectedKid: null,
                currentPage: 1,
                total: 0,
                perPage: 20,
                search: null,
                contractStates: ['current'],
                selectedGroup: null,
                selectedTags: null,
                paginator: undefined,
            };
        },
        watch: {
            currentPage() {
                this.getKids();
            },
            contractStates() {
                this.currentPage = 1;
                this.getKids();
            },
            selectedGroup() {
                this.currentPage = 1;
                this.getKids();
            },
            selectedTags() {
                this.currentPage = 1;
                this.getKids();
            },
            search: _debounce(function () {
                this.currentPage = 1;
                this.getKids();
            }, 100),
            selectedKid(val) {
                this.$emit('kidChanged', val);
            },
            nursery() {
                this.currentPage = 1;
                this.getKids();
            },
            paginator() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        mounted() {
            this.getKids();
        },
        methods: {
            getKids() {
                this.loading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                useApi()
                    .legacy.get(route('kids.index'), {
                        params: {
                            nursery_id: this.nursery.id,
                            contract_states: Array.isArray(this.contractStates) ? this.contractStates : undefined,
                            page: this.currentPage,
                            per_page: this.perPage,
                            search: this.search,
                            group_id: this.selectedGroup,
                            tags,
                            order_by: window.localStorage.getItem('display:orderBy')
                                ? window.localStorage.getItem('display:orderBy')
                                : 'first_name',
                        },
                    })
                    .then(response => {
                        this.kids = response.data.data;
                        this.total = parseInt(response.data.total);
                        this.perPage = parseInt(response.data.per_page);
                        this.paginator = new LengthAwarePaginator(
                            response.data.data,
                            parseInt(response.data.total),
                            parseInt(response.data.per_page),
                            parseInt(response.data.current_page),
                            parseInt(response.data.last_page)
                        );
                        this.loading = false;
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
        },
    };
</script>
