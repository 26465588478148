<template>
    <MMenu
        arrow
        placement="left"
    >
        <MMenuButton>
            <MButton
                icon-ellipsis
                size="sm"
                variant="ghost"
                @click="onShow"
            />
        </MMenuButton>
        <MMenuItems>
            <MMenuItem
                v-if="!teamModel.attributes.internal_id"
                :label="__('common:actions.update')"
                @click="$refs.updateModal.show()"
            >
                <template #icon>
                    <FontAwesomeIcon
                        fixed-width
                        icon="fa-solid fa-pen"
                    />
                </template>
            </MMenuItem>
            <MMenuItem
                v-if="!pivot.attributes.archived_at"
                :label="__('common:actions.archive')"
                @click="toggleArchive('archive')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        fixed-width
                        icon="fa-solid fa-archive"
                    />
                </template>
            </MMenuItem>
            <MMenuItem
                v-else
                :label="__('common:actions.unarchive')"
                @click="toggleArchive()"
            >
                <template #icon>
                    <FontAwesomeIcon
                        fixed-width
                        icon="fa-solid fa-box-open"
                    />
                </template>
            </MMenuItem>
            <template v-if="user.is_owner && !isOnlyInOrganization">
                <MMenuItem
                    v-if="!pivot.attributes.archived_at"
                    :label="__('common:actions.archive_of_all_organizations')"
                    @click="toggleArchiveAll('archiveAll')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-archive"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    v-else
                    :label="__('common:actions.unarchive_of_all_organizations')"
                    @click="toggleArchiveAll()"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-box-open"
                        />
                    </template>
                </MMenuItem>
            </template>
            <MMenuDivider />
            <MMenuItem
                :label="__('common:actions.delete')"
                variant="danger"
                @click="onDelete"
            >
                <template #icon>
                    <FontAwesomeIcon
                        fixed-width
                        icon="fa-solid fa-trash"
                    />
                </template>
            </MMenuItem>
            <template v-if="user.is_owner && !isOnlyInOrganization">
                <MTooltip
                    class="tw-cursor-not-allowed"
                    :hoverable="teamModelUsed"
                    :label="__('hr:used_team')"
                    placement="bottom"
                >
                    <MMenuItem
                        :disabled="teamModelUsed"
                        :label="__('common:actions.delete_of_all_organizations')"
                        variant="danger"
                        @click="$emit('ownerDelete', teamModel)"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-trash"
                            />
                        </template>
                    </MMenuItem>
                </MTooltip>
            </template>
        </MMenuItems>
        <UpdateTeamModal
            ref="updateModal"
            :team-model="teamModel"
        />
    </MMenu>
</template>

<script lang="ts">
    import {defineComponent, ref, toRef} from 'vue';
    import TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
    import TeamModel from '@/modules/human-resources/models/TeamModel';
    import moment from 'moment';
    import useAuth from '@/modules/app/composables/useAuth';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import UpdateTeamModal from '@/modules/human-resources/components/team/Modals/UpdateTeamModal.vue';
    import {isNil} from 'lodash-es';

    export default defineComponent({
        components: {UpdateTeamModal},
        props: {
            pivot: {
                type: TeamOrganizationPivot,
                required: true,
            },
            teamModel: {
                type: TeamModel,
                required: true,
            },
            isOnlyInOrganization: {
                type: Boolean,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {legacyUser: user} = useAuth();
            const pivot = ref(toRef(props, 'pivot'));
            const teamModelUsed = ref<boolean | null>(null);

            function sendNotification(value: string | null) {
                useNotification().success(
                    __('hr:team_action_on_all_organizations_successfully', {
                        action: value ? __('common:archived') : __('common:unarchived'),
                    })
                );
            }

            function toggleArchive(value: 'archive' | null): void {
                pivot.value.attributes.archived_at = isNil(value) ? null : moment().toISOString(true);
                pivot.value.save();
                sendNotification(value);
            }

            async function toggleArchiveAll(value: 'archiveAll' | null = null) {
                const teamPivots = await TeamOrganizationPivot.query()
                    .where('team_id', '=', props.teamModel.getKey())
                    .get();

                teamPivots.each(teamPivot => {
                    teamPivot.attributes.archived_at = isNil(value) ? null : moment().toISOString(true);
                    teamPivot.save();
                });

                sendNotification(value);
            }

            /*
             * Delete Actions
             */
            async function onDelete() {
                if (props.isOnlyInOrganization) {
                    await removeTeamModel();
                } else {
                    await detach();
                }
            }

            async function removeTeamModel() {
                await props.teamModel.delete();
                emitTeamModelToDelete();
            }

            async function detach() {
                await pivot.value.delete();
                emitTeamModelToDelete();
            }

            function emitTeamModelToDelete() {
                emit('teamModelToDelete', props.teamModel);
                useNotification().success(__('hr:team_deleted_successfully'));
            }

            async function onShow() {
                const fetchTeamModelUsed = await TeamModel.query()
                    .where('id', '=', toRef(props, 'teamModel').value.id as string)
                    .whereHas('staffs')
                    .all(100);
                teamModelUsed.value = fetchTeamModelUsed.isNotEmpty();
            }

            return {
                user,
                onShow,
                toggleArchive,
                toggleArchiveAll,
                onDelete,
                teamModelUsed,
                removeTeamModel,
            };
        },
    });
</script>
