<template>
    <div
        :key="'event-' + event.id"
        ref="eventSegment"
        class="tw-group hover:tw-z-[2000] focus:tw-z-[2000]"
        :class="[eventClass, {'tw-z-[2000]': resizing}]"
        :style="eventStyle"
        tabindex="0"
        @click="shift || alt ? copyEvent() : onSegmentClicked()"
    >
        <div
            v-if="pending"
            class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-animate-pulse tw-bg-white tw-opacity-80"
            :class="eventClass"
        />
        <OverrunSubSegments
            class="tw-absolute tw-inset-0"
            :event="eventCopy.value"
            :events="events"
        />

        <MPopover
            class="tw-min-w-0"
            placement="top"
            popover-button-class="tw-min-w-0"
            trigger="hover"
        >
            <div class="tw-flex tw-h-full tw-w-full tw-items-center">
                <SegmentResizers v-if="resizable" />

                <div
                    v-if="displayIcon"
                    :class="iconClass"
                    :style="iconStyle"
                >
                    <CIcon
                        v-if="pending"
                        :path="loading ? 'fa-duotone fa-spinner-third' : 'fa-duotone fa-hourglass-half'"
                        :provider="eventType.icon.attributes.provider"
                    />
                    <CIcon
                        v-else
                        :path="loading ? 'fa-duotone fa-spinner-third' : eventType.icon.attributes.path"
                        :provider="eventType.icon.attributes.provider"
                    />
                </div>
                <div
                    v-if="displayText"
                    class="tw-z-10 tw-flex tw-h-full tw-w-full tw-flex-wrap tw-items-center tw-truncate"
                    style="margin-left: 2px"
                >
                    <div
                        v-if="size === 'lg'"
                        class="tw-ml-1 tw-w-full tw-truncate"
                    >
                        <div class="tw-w-full tw-truncate tw-text-sm tw-font-semibold">
                            {{ titleHours }}
                        </div>
                        <div class="tw--mt-1 tw-w-full tw-truncate tw-text-sm tw-font-semibold tw-opacity-90">
                            {{
                                showNote && event.attributes.note ? subtitle + ' • ' + event.attributes.note : subtitle
                            }}
                        </div>
                    </div>
                    <div
                        v-else
                        class="tw-w-full tw-text-sm tw-font-semibold"
                    >
                        <div v-if="displayAsCompact">
                            <div>
                                {{ readableHoursFromDate(eventCopy.value.startedAt) }}
                            </div>
                            <div
                                class="tw-ml-auto"
                                style="margin-top: -5px"
                            >
                                {{ readableHoursFromDate(eventCopy.value.endedAt) }}
                            </div>
                        </div>
                        <div v-else>
                            {{ titleHours }}
                        </div>
                    </div>
                </div>
            </div>

            <template #content>
                <div class="tw-flex tw-min-w-60 tw-max-w-80 tw-items-center">
                    <div
                        v-if="eventType.attributes.icon_id"
                        class="tw-mr-3 tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-shadow"
                        :class="iconClass"
                        :style="iconStyle"
                    >
                        <CIcon
                            :path="loading ? 'fa-duotone fa-spinner-third' : eventType.icon.attributes.path"
                            :provider="eventType.icon.attributes.provider"
                        />
                    </div>
                    <div class="tw-mr-3">
                        <div class="tw-mb-1 tw-text-sm tw-font-semibold">
                            {{ titleHours }}
                            <CBadge
                                class="tw-ml-1 !tw-text-sm"
                                variant="cyan"
                            >
                                {{ eventCopy.value.readableHours }}
                            </CBadge>
                        </div>

                        <div
                            v-if="nurseryName || event.kidsGroup().value()"
                            class="tw-text-sm tw-opacity-90"
                        >
                            <template v-if="nurseryName && event.kidsGroup().value()">
                                {{ nurseryName }} •
                                {{ event.kidsGroup().value().attributes.name }}
                            </template>
                            <template v-else-if="nurseryName">
                                {{ nurseryName }}
                            </template>
                            <template v-else-if="event.kidsGroup().value()">
                                {{ event.kidsGroup().value().attributes.name }}
                            </template>
                        </div>

                        <div class="tw-text-sm tw-opacity-90">
                            {{
                                event.attributes.note
                                    ? eventType.attributes.name + ' • ' + event.attributes.note
                                    : eventType.attributes.name
                            }}
                        </div>
                    </div>
                </div>
            </template>
        </MPopover>

        <CDropdown
            v-if="showMenu"
            ref="segmentMenu"
            :arrow="false"
            :clickable="false"
            content-class="!tw-bg-transparent !tw-shadow-none !tw-ring-0 tw-w-48"
            legacy-mode
            no-padding
            overlay
            placement="top-start"
            :prevent-hide-on-click="isDeleting"
            @shown="onSegmentMenusShown(event)"
        >
            <SegmentRequestMenu
                v-if="!isTemplate && event.request().value()"
                :request="event.request().value()"
                @stageSaved="onRequestStageSaved"
            />
            <template v-else-if="!event.isPending">
                <MButton
                    v-if="event.attributes.forecast && can('create', 'staffs_planning')"
                    align="left"
                    class="tw-mt-2 tw-w-full !tw-rounded-full"
                    :disabled="isDeleting"
                    variant="success"
                    @click="addRealEvent"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-calendar-check" />
                    </template>
                    {{ __('common:add_real') }}
                </MButton>
                <MButton
                    align="left"
                    class="tw-mt-2 tw-w-full !tw-rounded-full"
                    :disabled="isDeleting"
                    variant="primary"
                    @click="updateEvent"
                >
                    <FontAwesomeIcon
                        class="tw-ml-1 tw-mr-2"
                        icon="fa-solid fa-edit"
                    />{{ __('common:actions.update') }}
                </MButton>
                <MButton
                    align="left"
                    class="tw-mt-2 tw-w-full !tw-rounded-full"
                    :disabled="isDeleting"
                    variant="black"
                    @click.stop="copyEvent"
                >
                    <div class="tw-flex tw-items-center">
                        <div>
                            <FontAwesomeIcon
                                class="tw-ml-1 tw-mr-2"
                                icon="fa-solid fa-copy"
                            />{{ __('common:actions.copy') }}
                        </div>
                        <div
                            class="tw-ml-auto tw-mr-2 tw-rounded tw-border tw-border-gray-200 tw-px-1 tw-text-sm tw-font-semibold tw-uppercase tw-leading-5 tw-text-gray-200"
                        >
                            {{ __('hr_calendar:shortcut_alt_click') }}
                        </div>
                    </div>
                </MButton>
                <MButton
                    v-if="!hasLockedAllocations && can('delete', 'staffs_planning')"
                    align="left"
                    class="tw-mt-2 tw-w-full !tw-rounded-full"
                    :loading="isDeleting"
                    variant="danger"
                    @click="deleteEvent"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-times" />
                    </template>
                    {{ __('common:actions.delete') }}
                </MButton>
            </template>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import Vue, {computed, defineComponent, onUnmounted, type PropType, ref, toRef} from 'vue';
    import {useMagicKeys} from '@vueuse/core';
    import __ from '@/modules/app/utils/i18n-facade';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import {EventBus} from '@/modules/legacy/utils/bus';
    import {readableHoursFromDate} from '@/modules/legacy/libs/planning/planning';
    import useSegment from '@/modules/human-resources/composables/calendar/useSegment';
    import EventModel from '@/modules/human-resources/models/EventModel';
    import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
    import SegmentResizers from '@/modules/human-resources/components/calendar/SegmentResizers.vue';
    import type CDropdown from '@/modules/meeko-ui/components/MDropdown.vue';
    import OverrunSubSegments from '@/modules/human-resources/components/calendar/OverrunSubSegments.vue';
    import SegmentRequestMenu from '@/modules/human-resources/components/calendar/SegmentRequestMenu.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import RequestModel from '@/modules/request/models/RequestModel';
    import RequestStageModel from '@/modules/request/models/RequestStageModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            OverrunSubSegments,
            SegmentResizers,
            SegmentRequestMenu,
        },
        props: {
            day: {
                type: Object,
            },
            opening: {
                type: String,
            },
            closing: {
                type: String,
            },
            event: {
                type: Object as PropType<EventModel | TemplateEventModel>,
                required: true,
            },
            events: {
                type: Array as PropType<EventModel[] | TemplateEventModel[]>,
                default: () => [],
            },
            showMenu: {
                type: Boolean,
                default: true,
            },
            showNote: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'lg',
            },
            resizable: {
                type: Boolean,
                default: true,
            },
            autoSave: {
                type: Boolean,
                default: true,
            },
            isTemplate: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const {
                eventCopy,
                eventSegment,
                loading,
                eventType,
                eventStyle,
                eventClass,
                iconClass,
                iconStyle,
                displayText,
                displayIcon,
                displayAsCompact,

                titleHours,
                nurseryName,
                subtitle,
                title,
                titlePresence,

                resizing,
                pending,
            } = useSegment(
                toRef(props, 'event'),
                props.day,
                toRef(props, 'opening'),
                toRef(props, 'closing'),
                props.size as 'sm' | 'md' | 'lg',
                props.resizable,
                props.autoSave
            );

            const {can} = useAbility();

            EventBus.$on('segment:dragend', () => {
                eventSegment.value.blur();
            });

            onUnmounted(() => {
                EventBus.$off('segment:dragend');
            });

            const segmentMenu = ref<CDropdown>();
            const isLoadingEventRequest = ref(false);
            const isDeleting = ref(false);

            const hasLockedAllocations = computed(() => {
                if (props.event instanceof EventModel) {
                    return props.event
                        .balanceAllocations()
                        .value()
                        .contains(allocation => allocation.attributes.confirmed_at !== null);
                } else {
                    return false;
                }
            });

            function updateEvent() {
                EventBus.$emit('calendar:staff:update:event', props.event);
            }

            function addRealEvent() {
                const currentEvent = props.event as EventModel;

                const newEvent = new EventModel();
                newEvent.attributes = _cloneDeep(currentEvent.attributes);
                newEvent.attributes.id = newEvent.uuid();
                newEvent.attributes.forecast = false;
                newEvent.eventType().associate(currentEvent.eventType().value());
                newEvent.referenceEvent().associate(currentEvent);
                Vue.set(newEvent.extra, 'forceReal', true);
                EventBus.$emit('calendar:staff:create:event', newEvent);
            }

            function copyEvent() {
                EventBus.$emit('calendar:staff:event:copy', props.event);
                segmentMenu.value?.hide();
                useNotification().success(__('hr_calendar:event_copied'));
            }

            async function deleteEvent() {
                isDeleting.value = true;
                await props.event.delete();
                EventBus.$emit('calendar:staff:delete:event', props.event);
                EventBus.$emit('calendar:staffs:refresh');
                isDeleting.value = false;
            }

            async function onRequestStageSaved() {
                if (props.event instanceof EventModel) {
                    await props.event.fresh();
                    eventCopy.value.value.attributes.status = props.event.attributes.status;
                    eventCopy.value.value
                        .save()
                        .then(() => {
                            eventCopy.value.commit();
                            segmentMenu.value?.hide();
                        })
                        .catch(() => {
                            eventCopy.value.rollback();
                        });
                }
            }

            function onSegmentClicked() {
                if (!resizing.value) {
                    if (props.isTemplate) {
                        EventBus.$emit('calendar:staff:update:templateEvent', props.event);
                    } else if (props.showMenu) {
                        segmentMenu.value?.show();
                    }
                }
            }

            async function onSegmentMenusShown(event: EventModel) {
                // If the event has an associated request and is pending, we need to load the request if not already loaded
                if (
                    !props.isTemplate &&
                    can('update', 'hr_request') &&
                    event.isPending &&
                    event.attributes.request_id &&
                    !event.request().value()
                ) {
                    isLoadingEventRequest.value = true;

                    const request = await RequestModel.query()
                        .with(new RequestModel().events())
                        .with(new RequestModel().requestType())
                        .with(new RequestModel().source())
                        .with(new RequestModel().requestStages(), query => {
                            query.with(new RequestStageModel().requestTypeStage());
                        })
                        .find(event.attributes.request_id);

                    event.request().set(request);

                    isLoadingEventRequest.value = false;
                }
            }

            const {shift, alt} = useMagicKeys();

            return {
                can,
                isLoadingEventRequest,
                isDeleting,
                eventCopy,
                eventSegment,
                loading,
                hasLockedAllocations,
                updateEvent,
                addRealEvent,
                copyEvent,
                deleteEvent,
                onRequestStageSaved,

                eventType,
                eventStyle,
                eventClass,
                iconClass,
                iconStyle,
                displayText,
                displayIcon,
                displayAsCompact,

                titleHours,
                nurseryName,
                title,
                titlePresence,

                resizing,
                pending,

                segmentMenu,
                subtitle,
                onSegmentClicked,
                onSegmentMenusShown,

                shift,
                alt,
                readableHoursFromDate,
            };
        },
    });
</script>
