import type Rule from '@/modules/calendar/utils/RuleSet/Rule';
import type RuleSet from '@/modules/calendar/utils/RuleSet/RuleSet';
import DayValue from '@/modules/calendar/utils/RuleSet/values/DayValue';
import DirectionValue from '@/modules/calendar/utils/RuleSet/values/DirectionValue';
import MonthValue from '@/modules/calendar/utils/RuleSet/values/MonthValue';
import FrequencyValue from '@/modules/calendar/utils/RuleSet/values/FrequencyValue';
import moment from 'moment';
import {collect, EpochInfo} from '@meekohq/lumos';
import __ from '@/modules/app/utils/i18n-facade';

type RuleSetType = 'recurrence' | 'reminder';

export default function () {
    const longWeekDays = EpochInfo.weekdays();
    const longMonths = EpochInfo.months();

    function getRuleSetDetails(ruleset: RuleSet | null, type: RuleSetType) {
        let details = '';

        if (!ruleset) {
            return details;
        }

        ruleset.rules.forEach(rule => {
            if (type === 'recurrence') {
                details += getRecurrenceRuleDetails(rule) + '\n';
            } else {
                const direction = rule.interval && rule.interval < 0 ? DirectionValue.past : DirectionValue.futur;
                const interval = Math.abs(rule.interval || 0);

                details += getReminderRuleDetails(rule, interval, direction) + '\n';
            }
        });

        return details;
    }

    function getReminderRuleDetails(rule: Rule, interval: number, direction: DirectionValue) {
        let details = '';

        if (rule.options === undefined) {
            details += __('common:at_end');
        }

        if (interval && rule.options !== undefined) {
            const context = direction === DirectionValue.past ? 'before' : 'after';

            switch (`${rule.freq}_${context}`) {
                case 'minute_before':
                    details += __('calendar:minute_before', {count: interval});
                    break;
                case 'minute_after':
                    details += __('calendar:minute_after', {count: interval});
                    break;
                case 'hour_before':
                    details += __('calendar:hour_before', {count: interval});
                    break;
                case 'hour_after':
                    details += __('calendar:hour_after', {count: interval});
                    break;
                case 'day_before':
                    details += __('calendar:day_before', {count: interval});
                    break;
                case 'day_after':
                    details += __('calendar:day_after', {count: interval});
                    break;
                case 'week_before':
                    details += __('calendar:week_before', {count: interval});
                    break;
                case 'week_after':
                    details += __('calendar:week_after', {count: interval});
                    break;
                case 'month_before':
                    details += __('calendar:month_before', {count: interval});
                    break;
                case 'month_after':
                    details += __('calendar:month_after', {count: interval});
                    break;
                case 'year_before':
                    details += __('calendar:year_before', {count: interval});
                    break;
                case 'year_after':
                    details += __('calendar:year_after', {count: interval});
                    break;
                default: {
                    details += '';
                }
            }
        }

        return details;
    }

    function getRecurrenceRuleDetails(rule: Rule) {
        let details = getFreqTrans(rule);

        if (rule.bysetpos?.length && rule.byweekday?.length) {
            const position = `${getPosTrans(rule)} ${getWeekDayTrans(rule)}`;

            details += ` ${__('calendar:with_position', {position})}`;
        }

        if (rule.byweekday?.length && !rule.bysetpos?.length) {
            // Format and translation days of week
            const days = collect(rule.byweekday)
                .sort()
                .map(day => {
                    switch (DayValue[day]) {
                        case 'monday':
                            return longWeekDays[0];
                        case 'tuesday':
                            return longWeekDays[1];
                        case 'wednesday':
                            return longWeekDays[2];
                        case 'thursday':
                            return longWeekDays[3];
                        case 'friday':
                            return longWeekDays[4];
                        case 'saturday':
                            return longWeekDays[5];
                        case 'sunday':
                            return longWeekDays[6];
                        default: {
                            throw new Error('Not implemented yet');
                        }
                    }
                })
                .join(', ')
                .replace(/, ([^,]*)$/, ` ${__('common:and')} $1`);

            details += ` ${__('calendar:with_days', {days})}`;
        }

        if (rule.bymonthday?.length) {
            // Format days of month
            const days = collect(rule.bymonthday)
                .sort()
                .implode(', ')
                .replace(/, ([^,]*)$/, ` ${__('common:and')} $1`);

            details += ` ${__('calendar:with_days', {days})}`;
        }

        if (rule.bymonth?.length) {
            // Format and translation months of year
            const months = collect(rule.bymonth)
                .sort()
                .map(month => {
                    switch (MonthValue[month]) {
                        case 'january':
                            return longMonths[0];
                        case 'february':
                            return longMonths[1];
                        case 'march':
                            return longMonths[2];
                        case 'april':
                            return longMonths[3];
                        case 'may':
                            return longMonths[4];
                        case 'june':
                            return longMonths[5];
                        case 'july':
                            return longMonths[6];
                        case 'august':
                            return longMonths[7];
                        case 'september':
                            return longMonths[8];
                        case 'october':
                            return longMonths[9];
                        case 'november':
                            return longMonths[10];
                        case 'december':
                            return longMonths[11];
                        default: {
                            throw new Error('Not implemented yet');
                        }
                    }
                })
                .join(', ')
                .replace(/, ([^,]*)$/, ` ${__('common:and')} $1`);

            details += ` ${__('calendar:with_months', {months})}`;
        }

        if (rule.until) {
            details += ` ${__('calendar:with_until', {until: moment(rule.until).format('DD/MM/YYYY')})}`;
        }

        if (rule.count) {
            details += ` (${__('calendar:with_count', {count: rule.count})})`;
        }

        return details;
    }

    function getFreqTrans(rule: Rule) {
        if (rule.interval) {
            switch (rule.freq) {
                case FrequencyValue.day:
                    return __('calendar:daily', {count: rule.interval});
                case FrequencyValue.week:
                    return __('calendar:weekly', {count: rule.interval});
                case FrequencyValue.month:
                    return __('calendar:monthly', {count: rule.interval});
                case FrequencyValue.year:
                    return __('calendar:yearly', {count: rule.interval});
                default: {
                    return '';
                }
            }
        }
    }

    function getPosTrans(rule: Rule) {
        switch (rule.bysetpos?.[0]) {
            case 1:
                return __('common:position.first');
            case 2:
                return __('common:position.second');
            case 3:
                return __('common:position.third');
            case 4:
                return __('common:position.fourth');
            case 5:
                return __('common:position.fifth');
            case -1:
                return __('common:position.last');
            default: {
                return '';
            }
        }
    }

    function getWeekDayTrans(rule: Rule) {
        if (rule.byweekday?.length === 5) {
            return __('common:day_of_week').toLowerCase();
        } else if (rule.byweekday?.length === 2) {
            return __('common:day_of_weekend').toLowerCase();
        } else {
            switch (rule.byweekday?.[0]) {
                case DayValue.monday:
                    return longWeekDays[0];
                case DayValue.tuesday:
                    return longWeekDays[1];
                case DayValue.wednesday:
                    return longWeekDays[2];
                case DayValue.thursday:
                    return longWeekDays[3];
                case DayValue.friday:
                    return longWeekDays[4];
                case DayValue.saturday:
                    return longWeekDays[5];
                case DayValue.sunday:
                    return longWeekDays[6];
                default: {
                    return '';
                }
            }
        }
    }

    return {
        getRecurrenceRuleDetails,
        getReminderRuleDetails,
        getRuleSetDetails,
    };
}
