import {Model} from '@meekohq/lumos';
import TenantModel from '@/modules/cashier/models/TenantModel';

export default class PaymentMethodModel extends Model {
    static readonly TRANSFER_INTERNAL_ID = '75f813dc-cd1b-4bdd-9e87-f8dceb770699';

    public type = 'cashier/payment_methods';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        name: string | undefined;
        description: string | undefined | null;
        archived_at: string | undefined | null;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
        id: this.uuid(),
        tenant_id: undefined,
        name: undefined,
        description: undefined,
        archived_at: undefined,
        created_at: undefined,
        updated_at: undefined,
    };

    tenant() {
        return this.belongsTo('tenant', TenantModel, 'tenant_id');
    }
}
