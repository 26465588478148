import {type Ref, ref, unref, watch} from 'vue';
import {debounce} from 'lodash-es';
import {app, type LengthAwarePaginator} from '@meekohq/lumos';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import {PaginateTransactionsUseCaseBinding} from '@/modules/cashier/transaction/application/use-cases/PaginateTransactionsUseCase';
import {TransactionFilterDto} from '@/modules/cashier/transaction/application/dto/TransactionFilterDto';
import useConcurrentCallback from '@/modules/app/composables/useConcurrentCallback';
import type {TransactionListFiltersType} from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';

export default function usePaginateTransactions(options: Ref<Partial<TransactionListFiltersType>>) {
    const paginator = ref<LengthAwarePaginator<TransactionModel>>();
    const isLoading = ref(true);
    const {resolveLastCallback} = useConcurrentCallback();

    /**
     * Debounce the paginate method to avoid multi queries.
     */
    const paginate = debounce(
        async (page: number) => {
            isLoading.value = true;

            const optionsUnref = unref(options);

            const dto = TransactionFilterDto.make({
                page,
                period: optionsUnref.period,
                amountMin: optionsUnref.amountMin,
                amountMax: optionsUnref.amountMax,
                tenants: optionsUnref.tenants,
                customers: optionsUnref.customers,
                invoiceIds: optionsUnref.invoiceIds,
                transactionIds: optionsUnref.transactionIds,
                paymentMethodName: optionsUnref.paymentMethodName,
                hasRemainingAmount: optionsUnref.hasRemainingAmount,
                search: optionsUnref.search,
                orderBy: optionsUnref.orderBy,
                queryDirection: optionsUnref.queryDirection,
            });

            // In case of multi paginate calls, we only want to keep the last one
            try {
                paginator.value = await resolveLastCallback(() => {
                    return app(PaginateTransactionsUseCaseBinding).paginateByFilters(dto);
                });

                isLoading.value = false;

                return paginator.value;
            } catch (e) {
                // We don't do anything here, the result is outdated
                return;
            }
        },
        500,
        {leading: true, trailing: true}
    );

    /**
     * Watch for options changes to paginate the transactions.
     */
    function watchOptions() {
        watch(options, () => paginate(options.value.page ?? 1), {immediate: true, deep: true});
    }

    return {
        paginator,
        isLoading,
        paginate,
        watchOptions,
    };
}
