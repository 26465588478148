<script setup lang="ts">
    import SubscriptionModel from '@/modules/cashier/models/SubscriptionModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import OfflineEndpointSubscriberService from '@/modules/cashier/utils/core/notification/OfflineEndpointSubscriberService';
    import type ContactModel from '@/modules/cashier/models/ContactModel';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import type {Model} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import {computed} from 'vue';

    const props = defineProps<{
        resourceModel: Model;
        customerModel: CustomerModel;
        sourceModel: ContactModel | MemberModel;
    }>();

    const emit = defineEmits<{
        (e: 'subscribe', sourceModel: ContactModel | MemberModel): void;
    }>();

    const endpointModel = computed(() => props.sourceModel.notificationEndpoint().value());
    const subscriberService = new OfflineEndpointSubscriberService(endpointModel.value, props.resourceModel);
    const customerSubscriberService = new OfflineEndpointSubscriberService(endpointModel.value, props.customerModel);

    const isDisabled = computed(() => customerSubscriberService.isSubscribedToAll(topics.value));

    const isChecked = computed(() => {
        return (
            subscriberService.isSubscribedToAll(topics.value) ||
            customerSubscriberService.isSubscribedToAll(topics.value)
        );
    });

    const isHalfChecked = computed(() => {
        return (
            (!subscriberService.isSubscribedToAll(topics.value) && subscriberService.isSubscribedToOne(topics.value)) ||
            (!customerSubscriberService.isSubscribedToAll(topics.value) &&
                customerSubscriberService.isSubscribedToOne(topics.value))
        );
    });

    const topics = computed(() => {
        return collect(Object.values(SubscriptionModel.TOPICS))
            .filter(topic => topic !== SubscriptionModel.TOPICS.TAX_CERTIFICATE_SENT)
            .all();
    });

    function swap() {
        if (subscriberService.isSubscribedToAll(topics.value)) {
            topics.value.forEach(topic => {
                if (subscriberService.isSubscribedToTopic(topic)) {
                    subscriberService.unsubscribeFromTopic(endpointModel.value, topic);
                }
            });
        } else {
            emit('subscribe', props.sourceModel);
        }
    }
</script>
<template>
    <CSwitch
        :disabled="isDisabled"
        :half-checked="isHalfChecked"
        :value="isChecked"
        @input="swap()"
    />
</template>
