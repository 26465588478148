<template>
    <div v-if="visible">
        <slot name="content" />
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        setup(props, {emit}) {
            const visible = ref(false);

            function show() {
                visible.value = true;
                emit('shown');
            }

            function hide() {
                visible.value = false;
                emit('hidden');
            }

            function toggle() {
                if (visible.value) {
                    hide();
                } else {
                    show();
                }
            }

            return {
                show,
                hide,
                toggle,
                visible,
            };
        },
    });
</script>
