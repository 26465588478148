import {Model} from '@meekohq/lumos';
import type {ConsumerType} from '@/modules/auth/personal-access-token/domain/ConsumerType';

export default class PersonalAccessTokenModel extends Model {
    public type = 'auth/personal_access_tokens';

    public attributes: {
        id: string | undefined;
        tokenable_id: string | undefined;
        tokenable_type: string | undefined;
        name: string | undefined;
        consumer: ConsumerType | undefined;
        last_used_at: string | undefined;
    } = {
        id: this.uuid(),
        tokenable_id: undefined,
        tokenable_type: undefined,
        name: undefined,
        consumer: undefined,
        last_used_at: undefined,
    };
}
