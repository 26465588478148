import TemplateModel from '@/modules/human-resources/models/TemplateModel';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import type TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import {collect} from '@meekohq/lumos';
import {PlanningFactory} from '@/modules/human-resources/template/infrastructure/PlanningFactory';
import _cloneDeep from 'lodash-es/cloneDeep';

export class TemplateFactory {
    public static duplicateTemplate(template: TemplateModel) {
        // Duplicate the template
        const templateClone = template.clone();
        templateClone.attributes.id = new TemplateModel().uuid();
        templateClone.attributes.name = `${template.attributes.name} (Copy)`;
        templateClone.exists = false;

        // Duplicate the organizations pivots
        const organizationPivotClones = collect();

        templateClone
            .organizations()
            .value()
            .each((organization: OrganizationModel) => {
                const organizationPivotClone = _cloneDeep(organization.pivot().clone());
                organizationPivotClone.attributes.id = new TemplateModel().uuid();
                organizationPivotClone.attributes.template_id = templateClone.getKey();
                organizationPivotClone.exists = false;

                organizationPivotClones.push(organizationPivotClone);
            });

        templateClone.setRelation('organizationsPivots', organizationPivotClones);

        // Duplicate the template plannings
        const planningClones = collect();

        templateClone
            .planningTemplates()
            .value()
            .map((planning: TemplatePlanningModel) => {
                planningClones.push(PlanningFactory.duplicatePlanning(planning, templateClone));
            });

        templateClone.setRelation('planningTemplates', planningClones);

        return templateClone;
    }
}
