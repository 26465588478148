<script setup lang="ts">
    import type {PropType} from 'vue';
    import DeviceReportingMandatoryFor from '@/modules/organization/utils/DeviceReportingMandatoryFor';

    const props = defineProps({
        value: {
            type: Array as PropType<DeviceReportingMandatoryFor[]>,
            required: true,
        },
    });

    const emit = defineEmits(['input']);

    function deviceHasReportingMandatoryAuthorSetting(setting: DeviceReportingMandatoryFor) {
        return props.value.includes(setting);
    }

    function toggleDeviceReportingMandatoryAuthorSetting(setting: DeviceReportingMandatoryFor, checked: boolean) {
        if (checked && !deviceHasReportingMandatoryAuthorSetting(setting)) {
            emit('input', [...props.value, setting]);
        } else if (!checked && deviceHasReportingMandatoryAuthorSetting(setting)) {
            emit(
                'input',
                props.value.filter(item => item !== setting)
            );
        } else {
            throw new Error('Toggle device reporting mandatory author setting error');
        }
    }
</script>

<template>
    <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
        <div>
            <CLabel>{{ __('common:kid_other') }}</CLabel>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.kidAttendances)"
                    @input="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.kidAttendances, $event)
                    "
                >
                    {{ __('common:clocking_in_other') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.meals)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.meals, $event)"
                >
                    {{ __('common:meal') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.activities)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.activities, $event)"
                >
                    {{ __('common:activity_other') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.naps)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.naps, $event)"
                >
                    {{ __('common:naps') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.weights)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.weights, $event)"
                >
                    {{ __('common:weight_measurement') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.drugs)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.drugs, $event)"
                >
                    {{ __('common:drug_intake') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.temperatures)"
                    @input="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.temperatures, $event)
                    "
                >
                    {{ __('common:temperature_measurement') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.diapers)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.diapers, $event)"
                >
                    {{ __('common:diaper') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.photos)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.photos, $event)"
                >
                    {{ __('common:photos') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.observations)"
                    @input="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.observations, $event)
                    "
                >
                    {{ __('common:observation_other') }}
                </CCheckbox>
            </CFormGroup>
        </div>
        <div>
            <CLabel>{{ __('common:team_other') }}</CLabel>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.rooms)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.rooms, $event)"
                >
                    {{ __('common:room_other') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.equipment)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.equipment, $event)"
                >
                    {{ __('common:equipment_one') }}
                </CCheckbox>
            </CFormGroup>
            <CFormGroup>
                <CCheckbox
                    :value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.staffNotes)"
                    @input="toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.staffNotes, $event)"
                >
                    {{ __('organization:teams_notes') }}
                </CCheckbox>
            </CFormGroup>
        </div>
    </div>
</template>
