import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import type {CustomObject, Epoch, LengthAwarePaginator, ModelCollection} from '@meekohq/lumos';
import {collect, MqlOperation} from '@meekohq/lumos';
import {TransformFiltersToQueryBuilderFactory} from '@/modules/cashier/transaction/infrastructure/TransformFiltersToQueryBuilderFactory';
import type {TransactionFilterDto} from '@/modules/cashier/transaction/application/dto/TransactionFilterDto';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import type {TransactionRepositoryPort} from '@/modules/cashier/transaction/application/ports/TransactionRepositoryPort';
import type CurrencyModel from '../../models/CurrencyModel';

export class TransactionRepositoryAdapter implements TransactionRepositoryPort {
    public async sumByCurrenciesAndFilters(
        currencies: CurrencyModel[],
        filters: TransactionFilterDto
    ): Promise<number> {
        const query = TransformFiltersToQueryBuilderFactory.transform(filters);

        return query.whereIn('currency_id', collect(currencies).pluck('attributes.id').toArray()).sum('signed_amount');
    }

    public async refreshTransactionsCollection(collection: ModelCollection<TransactionModel>): Promise<void> {
        for (const transaction of collection.all()) {
            await transaction.refresh();
        }
    }

    public async rejectTransactionsByIds(transactionIds: string[], failureDate: Epoch): Promise<boolean> {
        await new MqlOperation<CustomObject<TransactionModel['attributes'][]>>('cashier/reject_transactions', {
            transaction_ids: transactionIds,
            failure_date: failureDate.toISOString(),
        }).run(true);

        return true;
    }

    public async restoreTransactionsByIds(transactionIds: string[]): Promise<boolean> {
        await new MqlOperation<CustomObject<TransactionModel['attributes'][]>>('cashier/restore_transactions', {
            transaction_ids: transactionIds,
        }).run(true);

        return true;
    }

    public queryDeleteByFilters(ids: string[], filters: TransactionFilterDto): Promise<number> {
        const query = TransformFiltersToQueryBuilderFactory.transform(filters);

        if (ids.length > 0) {
            query.whereIn('id', ids);
        }

        return query.delete();
    }

    public paginateByFilters(filters: TransactionFilterDto): Promise<LengthAwarePaginator<TransactionModel>> {
        const query = TransformFiltersToQueryBuilderFactory.transform(filters);

        return TransactionModel.query().inject(query).paginate(filters.perPage, filters.page);
    }

    public async loadTransactionsRelations(collection: ModelCollection<TransactionModel>) {
        await collection.load({
            allocationsAsSource: query2 => {
                query2.with(new AllocationModel().destination(), query3 => {
                    query3.with(new PaymentModel().invoices(), query4 => {
                        query4.with(new InvoiceModel().payments());
                    });
                });
            },
            allocationsAsDestination: query2 => {
                query2.with(new AllocationModel().source(), query3 => {
                    query3.with(new PaymentModel().creditNotes(), query4 => {
                        query4.with(new InvoiceModel().refunds());
                    });
                });
            },
            tenant: () => {
                //
            },
            customer: () => {
                //
            },
            paymentMethod: () => {
                //
            },
            currency: () => {
                //
            },
        });
    }
}
