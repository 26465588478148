<script setup lang="ts">
    import __ from '@/modules/app/utils/i18n-facade';
    import InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue.js';
    import {computed} from 'vue';

    const props = defineProps<{
        value?: string;
    }>();

    const emit = defineEmits<{
        (e: 'input', value: string | null): void;
    }>();

    const invoiceTypeText = computed(() => {
        if (props.value === InvoiceTypeValue.invoice) {
            return __('common:invoice_other');
        } else if (props.value === InvoiceTypeValue.creditNote) {
            return __('common:credit_note');
        } else {
            return __('billing_invoice:invoice_and_credit_note');
        }
    });
</script>
<template>
    <MMenu>
        <MMenuButton>
            <MButton icon-menu>
                {{ invoiceTypeText }}
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem @click="emit('input', null)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="!value || typeof value === 'undefined' || value.length === 0"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('billing_invoice:invoice_and_credit_note') }}
            </MMenuItem>
            <MMenuItem @click="emit('input', InvoiceTypeValue.invoice)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="value && value.includes('invoice')"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('common:invoice_other') }}
            </MMenuItem>
            <MMenuItem @click="emit('input', InvoiceTypeValue.creditNote)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="value && value.includes('credit_note')"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('common:credit_note') }}
            </MMenuItem>
        </MMenuItems>
    </MMenu>
</template>
