<template>
    <div :class="[cardClass, cardBaseClass]">
        <div
            v-if="$slots.header"
            :class="[headerClass, headerBaseClass]"
        >
            <div
                v-if="collapsable"
                class="tw-flex tw-items-center"
            >
                <div class="tw-flex-1">
                    <slot name="header" />
                </div>
                <MButton
                    class="tw-ml-2"
                    @click="collapsed = !collapsed"
                >
                    {{ __('common:actions.detail') }}
                    <FontAwesomeIcon
                        class="tw-ml-2 tw-text-lg tw-text-blue-400"
                        :icon="'fa-solid ' + iconCollapse"
                    />
                </MButton>
            </div>
            <slot
                v-else
                name="header"
            />
        </div>
        <div
            v-show="!collapsed || !collapsable"
            :class="[bodyClass, bodyBaseClass]"
        >
            <slot />
        </div>
        <div
            v-if="(!collapsed || !collapsable) && $slots.footer"
            :class="[footerClass, footerBaseClass]"
        >
            <slot name="footer" />
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            border: {type: Boolean, default: true, required: false},
            shadow: {type: Boolean, default: false, required: false},
            collapsable: {type: Boolean, default: false, required: false},
            visible: {type: Boolean, default: true, required: false},
            cardClass: {},
            headerBorder: {type: Boolean, default: true, required: false},
            headerClass: {},
            headerSize: {type: String, default: 'default', required: false},
            bodyClass: {},
            bodySize: {type: String, default: 'default', required: false},
            footerClass: {},
            footerSize: {type: String, default: 'default', required: false},
        },
        setup(props) {
            const collapsed = ref(!props.visible);

            const cardBaseClass = computed(() => {
                const output = [] as string[];

                output.push('tw-block tw-bg-white tw-rounded-xl');

                if (props.border) {
                    output.push('tw-border tw-border-gray-200');
                }

                if (props.shadow) {
                    output.push('tw-shadow-md');
                }

                return output;
            });

            const headerBaseClass = computed(() => {
                const output = [] as string[];

                if (props.headerBorder && !collapsed.value) {
                    output.push('tw-border-b tw-border-gray-200');
                }

                const sizes = {
                    '2xs': 'tw-p-1',
                    'xs': 'tw-p-2',
                    'sm': 'tw-p-3',
                    'default': 'tw-px-5 tw-py-4',
                    'none': '',
                };

                output.push(sizes[props.headerSize]);

                return output;
            });

            const footerBaseClass = computed(() => {
                const output = [] as string[];

                output.push('tw-block tw-border-t tw-border-gray-200');

                const sizes = {
                    '2xs': 'tw-p-1',
                    'xs': 'tw-p-2',
                    'sm': 'tw-p-3',
                    'default': 'tw-px-5 tw-py-4',
                    'none': '',
                };

                output.push(sizes[props.footerSize]);

                return output;
            });

            const bodyBaseClass = computed(() => {
                const output = [] as string[];

                const sizes = {
                    '2xs': 'tw-p-1',
                    'xs': 'tw-p-2',
                    'sm': 'tw-p-3',
                    'default': 'tw-p-5',
                    'none': '',
                };

                output.push(sizes[props.bodySize]);

                return output;
            });

            const collapseHeadingClass = computed(() => {
                const output = [] as string[];

                output.push('tw-text-reset tw-font-semibold tw-m-0 tw-inline-block tw-text-lg');
                output.push(
                    'tw-text-reset tw-font-semibold tw-m-0 tw-inline-block tw-text-lg tw-text-blue-500 hover:tw-text-black'
                );

                return output;
            });

            const iconCollapse = computed(() => {
                const result = [] as string[];

                if (collapsed.value) {
                    result.push('fa-chevron-down');
                } else {
                    result.push('fa-chevron-up');
                }

                return result;
            });

            watch(
                () => props.visible,
                visible => {
                    collapsed.value = !visible;
                }
            );

            return {
                collapsed,
                cardBaseClass,
                headerBaseClass,
                footerBaseClass,
                bodyBaseClass,
                collapseHeadingClass,
                iconCollapse,
            };
        },
    });
</script>
