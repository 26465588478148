<template>
    <div class="PlanningSettingsDisplay">
        <div class="PlanningSettingsDisplay__grid">
            <PlanningSettingsDisplayGeneral @saveUserPreferences="saveUserPreferences" />
            <PlanningSettingsDisplayPlannings :save-user-preferences="saveUserPreferences" />
        </div>

        <MButton
            v-if="can('update', 'nursery')"
            class="tw-ml-auto"
            :loading="loading"
            variant="primary"
            @click="saveOrganization"
        >
            {{ __('common:actions.save') }}
        </MButton>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import PlanningSettingsDisplayGeneral from '@/modules/planning/components/settings/PlanningSettingsDisplay/organisms/PlanningSettingsDisplayGeneral.vue';
    import PlanningSettingsDisplayPlannings from '@/modules/planning/components/settings/PlanningSettingsDisplay/organisms/PlanningSettingsDisplayPlannings.vue';
    import useManager from '@/modules/app/composables/useManager';
    import useAbility from '@/modules/app/composables/useAbility';

    export default defineComponent({
        components: {
            PlanningSettingsDisplayPlannings,
            PlanningSettingsDisplayGeneral,
        },
        setup() {
            const {activeOrganization} = useManager();
            const {can} = useAbility();
            const {success, error} = useNotification();
            const loading = ref(false);

            async function saveOrganization() {
                loading.value = true;
                try {
                    await activeOrganization.value.save();
                    success(__('common:parameters_updated_successfully'));
                    loading.value = false;
                } catch (e) {
                    loading.value = false;
                    error(e);
                }
            }

            // No API call but we intentionally show confirmation to the user
            async function saveUserPreferences() {
                loading.value = true;
                setTimeout(() => {
                    success(__('common:parameters_updated_successfully'));
                    loading.value = false;
                }, 500);
            }

            return {
                saveUserPreferences,
                can,
                saveOrganization,
                loading,
            };
        },
    });
</script>

<style scoped>
    .PlanningSettingsDisplay {
        @apply tw-flex tw-flex-col tw-gap-8;
    }

    .PlanningSettingsDisplay__grid {
        @apply tw-grid tw-grid-cols-1 tw-gap-12 lg:tw-grid-cols-2;
    }
</style>
