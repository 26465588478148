<template>
    <div class="TransactionListItemAllocationsProgressBar">
        <div class="TransactionListItemAllocationsProgressBar__label">
            {{ __('billing_transaction:remaining_amount_to_allocate', {value: remainingToDistributeAmount}) }}
        </div>
        <MProgressBar
            class="TransactionListItemAllocationsProgressBar__container"
            :remaining="transaction.computed.remaining_to_distribute_amount"
            :total="transaction.attributes.amount"
        />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, type PropType} from 'vue';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import MProgressBar from '@/modules/meeko-ui/components/MProgressBar.vue';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';

    export default defineComponent({
        components: {MProgressBar},
        props: {
            transaction: {
                type: Object as PropType<TransactionModel>,
                required: true,
            },
        },
        setup(props) {
            const {format} = useFormatCurrency(props.transaction.computed.currency_iso_code);

            const remainingToDistributeAmount = computed(() => {
                return format(props.transaction.computed.remaining_to_distribute_amount);
            });

            return {
                remainingToDistributeAmount,
            };
        },
    });
</script>

<style scoped>
    .TransactionListItemAllocationsProgressBar__label {
        @apply tw-mb-0.5 tw-text-sm tw-font-medium tw-text-gray-500;
    }

    .TransactionListItemAllocationsProgressBar__container {
        @container TransactionListItem (min-width: 48rem) {
            @apply tw-max-w-48;
        }
    }
</style>
