import type JobModel from '@/modules/human-resources/models/JobModel';
import {Binding} from '@meekohq/lumos';

export const JobListContractBinding = new Binding<JobListContract>();

export default interface JobListContract {
    get jobs(): JobModel[];

    loadJobs(): Promise<this>;

    clear(): this;
}
