<template>
    <MModal
        :modal="modal"
        size="5xl"
    >
        <template #header-text> {{ __('user:choose_access') }} - {{ $fullName(user) }} </template>

        <template v-if="user && user.id && user.nurseries">
            <div
                class="tw-flex tw-flex-col tw-gap-2 tw-rounded-xl tw-border tw-border-gray-200 tw-p-2 md:tw-flex-row md:tw-items-center"
                :class="{'tw-border-none': !user.nurseries.length}"
            >
                <div
                    v-if="user.nurseries.length"
                    class="tw-order-2 tw-flex tw-w-full tw-flex-row tw-overflow-x-auto md:tw-order-1"
                >
                    <div
                        v-for="(nursery, i) in user.nurseries"
                        :key="'nursery' + i"
                        class="nursery tw-relative tw-my-2 tw-mr-2 tw-inline-flex tw-cursor-pointer tw-flex-wrap tw-items-center tw-rounded-lg tw-border tw-border-primary-500 tw-p-2 tw-text-blue-800"
                        :class="{'tw-border-0 tw-bg-primary-500 tw-text-white': nursery.id === selectedNursery.id}"
                        @click="switchNursery(nursery)"
                    >
                        <div class="tw-w-full tw-truncate tw-text-sm tw-font-semibold">
                            {{ nursery.name }}
                        </div>
                        <div
                            v-if="nursery.city"
                            class="tw-truncate tw-text-sm"
                        >
                            {{ nursery.city }}
                        </div>
                        <div
                            v-if="selectedNursery.id === nursery.id"
                            class="remove-nursery zoom tw-flex tw-items-center tw-rounded-full tw-bg-danger-500 tw-text-center"
                            @click="detachUser(nursery)"
                        >
                            <MTooltip
                                class="tw-flex tw-w-full tw-items-center tw-justify-center"
                                :label="__('user:detach_from_this_organization')"
                            >
                                <FontAwesomeIcon
                                    class="tw-text-sm tw-text-white"
                                    fixed-width
                                    icon="fa-solid fa-trash-alt"
                                />
                            </MTooltip>
                        </div>
                    </div>
                </div>
                <div
                    v-if="nurseries.length !== user.nurseries.length"
                    class="tw-order-1 tw-ml-auto md:tw-order-2"
                >
                    <MButton
                        icon-plus
                        :label="__('organization:add_organization')"
                        @click="attachUser"
                    />
                </div>
            </div>

            <hr class="hr tw-mx-1 tw-mb-6 tw-border-2" />

            <div
                v-if="selectedNursery && selectedNursery.permissions"
                class="row tw-mx-1"
            >
                <div class="permissions-menu col-12 col-md-4 col-lg-3 tw-rounded-lg">
                    <div class="tw-mt-2">
                        <MButton
                            class="tw-mr-4"
                            variant="link"
                            @click="toggleFullAccess(true)"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-check-double" />
                            </template>
                            {{ __('common:actions.check_all') }}
                        </MButton>
                        <MButton
                            variant="link"
                            @click="toggleFullAccess(false)"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-times-circle" />
                            </template>
                            {{ __('common:actions.uncheck_all') }}
                        </MButton>
                    </div>
                    <hr class="hr tw-mt-2" />
                    <div
                        v-for="(menu, i) in menus"
                        :key="'menu' + i"
                        class="menu tw-mb-6 tw-mt-4"
                        :class="[{selectable: isUniqueMenu(menu)}, {active: selectedRessource.name === menu.name}]"
                        @click="selectRessource(menu.name, isUniqueMenu(menu))"
                    >
                        <div class="tw-mb-2 tw-flex tw-items-center">
                            <i
                                class="icon tw-mr-1 tw-bg-transparent"
                                :class="[menu.icon, menu.class]"
                                style="width: 23px"
                            />{{ menu.displayName }}
                            <FontAwesomeIcon
                                v-if="selectedRessource.name === menu.name && isUniqueMenu(menu)"
                                class="tw-ml-auto tw-mr-2 tw-text-2xl"
                                icon="fa-solid fa-angle-right"
                            />
                        </div>
                        <template v-if="!isUniqueMenu(menu)">
                            <div
                                v-for="(ressource, i) in menu.ressources"
                                :key="'ressource' + i"
                                class="ressource tw-my-1 tw-rounded-lg tw-py-2 tw-pl-6"
                                :class="{active: selectedRessource.name === ressource}"
                                @click="selectRessource(ressource, true)"
                            >
                                <div
                                    v-if="ressourceDisplayName(ressource)"
                                    class="tw-flex tw-items-center tw-text-base"
                                >
                                    {{ ressourceDisplayName(ressource) }}
                                    <FontAwesomeIcon
                                        v-if="selectedRessource.name === ressource"
                                        class="tw-ml-auto tw-mr-2 tw-text-2xl"
                                        icon="fa-solid fa-angle-right"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="permissions-actions col-12 col-md-8 col-lg-9 tw-rounded-lg">
                    <div class="tw-flex tw-flex-col tw-gap-2 lg:tw-flex-row lg:tw-items-center lg:tw-justify-between">
                        <h5 class="h5 tw-my-1 tw-text-blue-800">
                            {{ selectedRessource.displayName }}
                        </h5>
                        <div class="tw-flex tw-flex-wrap tw-gap-2">
                            <MButton @click="toggleAll(selectedRessource, true)">
                                <template #left-icons>
                                    <FontAwesomeIcon
                                        class="tw-text-success-500"
                                        icon="fa-solid fa-check-circle"
                                    />
                                </template>
                                {{ __('user:check_all_actions') }}
                            </MButton>
                            <MButton @click="toggleAll(selectedRessource, false)">
                                <template #left-icons>
                                    <FontAwesomeIcon
                                        class="tw-text-black"
                                        icon="fa-solid fa-eye-slash"
                                    />
                                </template>
                                {{ __('user:uncheck_all_actions') }}
                            </MButton>
                        </div>
                    </div>
                    <hr class="hr" />

                    <div
                        v-for="(action, i) in actions"
                        :key="'action' + i"
                    >
                        <template v-if="ressourceHasAction(selectedRessource, action.name)">
                            <div
                                class="action tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-rounded-lg tw-p-4"
                                :class="{active: userHasAction(selectedRessource, action.name)}"
                                @click="toggleAction(selectedRessource, action.name)"
                            >
                                <div
                                    class="permission-icon tw-flex tw-items-center tw-rounded-full tw-bg-primary-500 tw-text-center"
                                >
                                    <i
                                        class="tw-w-full tw-text-2xl tw-text-white"
                                        :class="action.icon"
                                    />
                                </div>
                                <div class="tw-ml-2 tw-p-1">
                                    <div class="tw-font-semibold">
                                        {{ action.title }}
                                    </div>
                                    <div class="small tw-mt-1">
                                        {{ action.description }}
                                    </div>
                                </div>
                                <div class="tw-ml-auto">
                                    <FontAwesomeIcon
                                        class="tw-text-2xl"
                                        :class="{'tw-text-success-500': userHasAction(selectedRessource, action.name)}"
                                        :icon="[
                                            userHasAction(selectedRessource, action.name)
                                                ? 'fa-solid fa-check-circle'
                                                : 'fa-solid fa-eye-slash',
                                        ]"
                                    />
                                </div>
                            </div>
                            <hr class="hr" />
                        </template>
                    </div>
                </div>
            </div>
            <div v-else>
                <h5 class="h5 tw-w-full tw-p-4 tw-text-center">
                    {{ __('user:empty_organization_warning') }}
                </h5>
            </div>
        </template>

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.close')"
                @click="hide"
            />
        </template>
    </MModal>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import swal from 'sweetalert2/dist/sweetalert2';
    import __ from '@/modules/app/utils/i18n-facade';
    import _includes from 'lodash-es/includes';
    import _debounce from 'lodash-es/debounce';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _differenceBy from 'lodash-es/differenceBy';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import {
        menusWithRessources,
        permissionsActions,
        permissionsRessources,
    } from '@/modules/app/static/legacyPermissions';

    export default {
        props: {
            user: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            nurseries: {
                type: Array,
                required: true,
            },
            modal: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            selectedRessource: {},
            selectedNursery: {},
            menus: [],
            actions: [],
            canUpdate: false,
        }),
        watch: {
            'user.id': function () {
                this.initUserPermissions();
            },
            'selectedNursery.permissions': {
                handler: _debounce(function () {
                    this.saveUserPermissions();
                }, 400),
                deep: true,
            },
        },
        mounted() {
            this.menus = menusWithRessources();
            this.actions = permissionsActions();
            this.selectedRessource = permissionsRessources().find(item => item.name === 'kids_planning');

            this.selectRessource();
            if (this.user.id) {
                this.initUserPermissions();
            }
        },
        methods: {
            initUserPermissions() {
                this.canUpdate = false;

                if (this.user.id && this.user?.nurseries?.length) {
                    const currentNursery = this.user.nurseries.find(item => item.id === this.nursery.id);
                    this.selectedNursery = currentNursery || this.user.nurseries[0];
                } else {
                    this.selectedNursery = {};
                }

                setTimeout(() => {
                    this.canUpdate = true;
                }, 1000);
            },

            saveUserPermissions() {
                if (!this.canUpdate || !this.selectedNursery.permissions) {
                    return;
                }

                useApi()
                    .legacy.put(
                        route('nurseries.users.update', {
                            nurseries: this.selectedNursery.id,
                            user: this.user.id,
                        }),
                        {
                            permissions: this.selectedNursery.permissions,
                        }
                    )
                    .then(response => {
                        this.$emit('edit', response.data);
                        useNotification().success(__('user:access_updated_successfully'));
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            attachUser() {
                const options = [];

                const diffNurseries = _differenceBy(this.nurseries, this.user.nurseries, 'id');
                diffNurseries.forEach(nursery => {
                    options[nursery.id] = nursery.city ? nursery.name + ' - ' + nursery.city : nursery.name;
                });

                swal({
                    title: __('user:add_user_to_organization_colon'),
                    type: null,
                    confirmButtonClass: 'btn btn-primary tw-mr-2',
                    confirmButtonText: __('common:actions.confirm'),
                    cancelButtonText: __('common:actions.cancel'),
                    input: 'select',
                    inputOptions: options,
                }).then(result => {
                    if (result.value) {
                        const data = {};
                        data.permissions = this.user.permissions ? this.user.permissions : [];
                        useApi()
                            .legacy.put(
                                route('nurseries.users.attach', {
                                    nurseries: result.value,
                                    user: this.user.id,
                                }),
                                data
                            )
                            .then(response => {
                                this.user.nurseries = response.data.nurseries;
                                this.$emit('update', response.data);
                                useNotification().success(__('user:organization_attached_successfully'));
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    }
                });
            },

            detachUser(nursery) {
                useMagicModal().deleteConfirmationModal({
                    title: __('user:detach_user_from_this_organization_ask'),
                    text: __('user:user_retains_access_to_other_organization'),
                    confirmButtonText: __('common:actions.detach'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.put(
                                route('nurseries.users.detach', {
                                    nurseries: nursery.id,
                                    user: this.user.id,
                                }),
                                this.user
                            )
                            .then(() => {
                                this.user.nurseries = this.user.nurseries.filter(item => item.id !== nursery.id);
                                this.initUserPermissions();
                                // TODO : Update user in list
                                useNotification().success(__('user:user_detached_to_organization_successfully'));
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            switchNursery(nursery) {
                this.selectedNursery = _cloneDeep(nursery);

                this.canUpdate = false;
                setTimeout(() => {
                    this.canUpdate = true;
                }, 1000);
            },

            toggleFullAccess(active) {
                let userPermissions = this.selectedNursery.permissions;

                if (active) {
                    userPermissions = [];
                    permissionsRessources().forEach(res => {
                        userPermissions.push({
                            name: res.name,
                            actions: res.actions,
                        });
                    });
                } else {
                    userPermissions = [{name: 'kids_planning', actions: []}];
                }

                Vue.set(this.selectedNursery, 'permissions', userPermissions);
            },

            toggleAll(ressource, active) {
                const userPermissions = this.selectedNursery.permissions;

                if (active) {
                    const defaultRessource = permissionsRessources().find(item => item.name === ressource.name);
                    const userRessource = userPermissions.find(item => item.name === ressource.name);
                    if (defaultRessource) {
                        if (userRessource) {
                            userRessource.actions = defaultRessource.actions;
                        } else {
                            userPermissions.push({
                                name: defaultRessource.name,
                                actions: defaultRessource.actions,
                            });
                        }
                    }
                } else {
                    const userRessource = userPermissions.find(item => item.name === ressource.name);
                    if (userRessource) {
                        userRessource.actions = [];
                    }
                }

                Vue.set(this.selectedNursery, 'permissions', userPermissions);
            },

            toggleAction(ressource, action) {
                const userPermissions = _cloneDeep(this.selectedNursery.permissions);

                // User already has action in the given ressource
                if (this.userHasAction(ressource, action)) {
                    const userRessource = userPermissions.find(item => item.name === ressource.name);
                    if (userRessource) {
                        const userAction = userRessource.actions.find(item => item === action);
                        if (userAction) {
                            const index = userRessource.actions.indexOf(userAction);
                            userRessource.actions.splice(index, 1);
                        }
                    }
                }

                // User has ressource but not the given action
                else {
                    const userRessource = userPermissions.find(item => item.name === ressource.name);
                    if (userRessource) {
                        userRessource.actions.push(action);
                    } else {
                        // User doesn't even have this ressource
                        const newRessource = {
                            name: ressource.name,
                            actions: [action],
                        };
                        userPermissions.push(newRessource);
                    }
                }

                Vue.set(this.selectedNursery, 'permissions', userPermissions);

                const nurseryUser = this.user.nurseries.find(item => item.id === this.selectedNursery.id);
                if (nurseryUser) {
                    Vue.set(nurseryUser, 'permissions', userPermissions);
                }
            },

            selectRessource(ressourceName, selectable) {
                if (selectable) {
                    const ressource = permissionsRessources().find(item => item.name === ressourceName);
                    if (ressource) {
                        this.selectedRessource = ressource;
                    }
                }
            },

            userHasAction(ressource, action) {
                const permissions = this.selectedNursery.permissions;
                const userRessource = permissions.find(item => item.name === ressource.name);

                return userRessource ? this.ressourceHasAction(userRessource, action) : false;
            },

            ressourceHasAction(ressource, action) {
                return _includes(ressource.actions, action);
            },

            ressourceDisplayName(ressource) {
                const res = permissionsRessources().find(item => item.name === ressource);

                return res ? res.displayName : null;
            },

            isUniqueMenu(menu) {
                let isUnique = false;
                menu.ressources.forEach(ressource => {
                    isUnique = menu.ressources.length === 1 && ressource === menu.name;
                });

                return isUnique;
            },
        },
    };
</script>

<style scoped>
    .nursery {
        min-width: 140px;
        max-width: 200px;
        min-height: 60px;
        max-height: 70px;

        .remove-nursery {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 22px;
            height: 22px;
        }
    }

    .permissions-menu {
        background: #f1f8ff;
        max-height: 700px;
        overflow: scroll;

        .menu {
            @apply tw-text-gray-500;
            font-weight: 600;

            &.selectable {
                @apply tw-text-primary-600;

                &:hover {
                    cursor: pointer;
                    @apply tw-text-primary-700;
                }

                &.active {
                    @apply tw-text-primary-600;
                }
            }
        }

        .ressource {
            @apply tw-text-gray-400;

            &:hover {
                cursor: pointer;
                background: white;
            }

            &.active {
                @apply tw-text-blue-600;
                background: white;
            }
        }
    }

    .permissions-actions {
        .action {
            opacity: 0.6;

            .permission-icon {
                opacity: 0.5;
                width: 50px;
                height: 50px;
            }

            &.active {
                opacity: 1;

                .permission-icon {
                    opacity: 1;
                }
            }

            &:hover {
                background: rgb(243, 249, 255);
            }
        }
    }
</style>
