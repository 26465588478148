<template>
    <div>
        <MButton
            :disabled="!(model && can('update', 'registrations'))"
            variant="danger"
            :zoom="true"
            @click="toggleModal"
        >
            <FontAwesomeIcon icon="fa-solid fa-trash-alt" />
        </MButton>
        <ConfirmDeleteModal
            v-if="showModal"
            :title="__('planning:confirmation_of_deletions')"
            @confirmed="deleteModel"
            @hide="toggleModal"
        >
            <CVStack>
                <CText>
                    <span v-html="__('planning:list_delete_ask_with_name', {value: model.attributes.name})" />
                </CText>
            </CVStack>
        </ConfirmDeleteModal>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import type SimulationListModel from '@/modules/planning/models/SimulationListModel';
    import ConfirmDeleteModal from '@/modules/app/components/ConfirmDeleteModal.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {ConfirmDeleteModal},
        props: {
            model: {
                type: Object as PropType<SimulationListModel>,
                default: undefined,
            },
        },
        setup(props, {emit}) {
            const showModal = ref(false);
            const {success, error} = useNotification();

            const {can} = useAbility();

            function toggleModal() {
                showModal.value = !showModal.value;
            }

            async function deleteModel() {
                try {
                    await props.model?.delete();
                    success(__('planning:list_deleted_successfully'));
                    emit('deleted');
                } catch (e) {
                    error(__('planning:errors:list_delete'));
                }
            }

            return {
                showModal,
                toggleModal,
                deleteModel,
                can,
            };
        },
    });
</script>
