<script setup lang="ts">
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useAbility from '@/modules/app/composables/useAbility';
    import type {LegacyKidType} from '@/modules/family/utils/kid/LegacyKidType';

    const props = defineProps<{
        kid: LegacyKidType;
    }>();

    const {can} = useAbility();

    function save() {
        useApi()
            .legacy.put(route('kids.update', {kid: props.kid.id}), {
                meal_type: props.kid.meal_type,
                vegetarian: props.kid.vegetarian,
                meat_diet: props.kid.meat_diet,
            })
            .then(() => {
                useNotification().success(__('family_kid:power_preferences_updated_successfully'));
            })
            .catch(error => {
                if (error?.response?.status === 422) {
                    _forEach(error.response.data.errors, value => {
                        useNotification().error(_head(value));
                    });
                } else {
                    useNotification().error(error);
                }
            });
    }
</script>

<template>
    <CForm
        class="KidFoodEatingHabitsForm"
        @submit.prevent
    >
        <CFormGroup>
            <CCheckbox
                v-model="kid.vegetarian"
                :disabled="!can('update', 'kids_food')"
            >
                {{ __('family_kid:vegetarian', {context: kid.gender}) }}
            </CCheckbox>
        </CFormGroup>
        <CFormGroup>
            <MLabel>{{ __('family_kid:specialized_meat_diet_colon') }}</MLabel>
            <CFormTextArea
                v-model="kid.meat_diet"
                :disabled="!can('update', 'kids_food')"
                rows="3"
            />
            <div class="tw-text-sm tw-text-gray-500">
                {{ __('family_kid:leave_empty_if_no') }}
            </div>
        </CFormGroup>
        <CFormGroup
            v-if="can('update', 'kids_food')"
            class="tw-text-right"
        >
            <MButton
                variant="success"
                @click="save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </CFormGroup>
    </CForm>
</template>
