<script lang="ts" setup>
    import {provide, toRef, watch} from 'vue';
    import type {MCalendarMode, UseMCalendar} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
    import useMCalendarState, {keyMCalendarState} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
    import useMCalendarNavigation, {
        keyMCalendarNavigation,
    } from '@/modules/meeko-ui/components/MCalendar/useMCalendarNavigation';
    import {isNil} from 'lodash-es';
    import {Epoch, Lang} from '@meekohq/lumos';
    import type {MCalendarPreset} from '@/modules/meeko-ui/components/MCalendar/useMCalendarPresets';
    import useMCalendarPresets, {
        keyMCalendarPresets,
    } from '@/modules/meeko-ui/components/MCalendar/useMCalendarPresets';
    import type {DateTimeValue} from '@/modules/meeko-ui/types/DateTime';
    import type {DateTimeModifiers} from '@/modules/meeko-ui/composables/useDateTimeModifiers';
    import MCalendarControl from '@/modules/meeko-ui/components/MCalendar/MCalendarControl.vue';
    import MCalendarDays from '@/modules/meeko-ui/components/MCalendar/MCalendarDays.vue';
    import MCalendarYears from '@/modules/meeko-ui/components/MCalendar/MCalendarYears.vue';
    import MCalendarMonths from '@/modules/meeko-ui/components/MCalendar/MCalendarMonths.vue';
    import MCalendarPresets from '@/modules/meeko-ui/components/MCalendar/MCalendarPresets.vue';
    import MCalendarTimes from '@/modules/meeko-ui/components/MCalendar/MCalendarTimes.vue';

    const props = withDefaults(
        defineProps<{
            value: DateTimeValue;
            mode: MCalendarMode;
            isPeriod?: boolean;
            modifiers?: DateTimeModifiers;
            allowUndefined?: boolean;
            // Custom presets
            presets?: MCalendarPreset[];
            // If true, the only presets visible will be the ones provided in the presets prop. Otherwise the default presets will be shown with the custom ones
            overridePresets?: boolean;
            hidePresets?: boolean;
        }>(),
        {
            isPeriod: false,
            presets: undefined,
            modifiers: undefined,
            overridePresets: false,
            allowUndefined: false,
            hidePresets: false,
        }
    );

    const emit = defineEmits<{
        (e: 'input', value: DateTimeValue): void;
    }>();

    validateProps(props.value);

    const state = useMCalendarState(
        {
            isPeriod: props.isPeriod,
            value: toRef(props, 'value'),
        } as UseMCalendar,
        props.mode,
        props.allowUndefined,
        props.modifiers
    );
    provide(keyMCalendarState, state);

    const navigation = useMCalendarNavigation(props.mode);
    provide(keyMCalendarNavigation, navigation);
    const {showTime, activeView, yearsRange, setActiveMonthFromDate, setYearsRangeFromActiveMonth} = navigation;

    const presetsState = useMCalendarPresets(state);
    provide(keyMCalendarPresets, presetsState);
    const {presets: computedPresets} = presetsState;

    watch(() => props.value, validateProps, {deep: true});

    watch(
        state.internalValue,
        newValue => {
            if (newValue.from || newValue.to) {
                if (newValue.from) {
                    setActiveMonthFromDate(newValue.from);
                } else {
                    setActiveMonthFromDate(newValue.to);
                }
            }

            const targetDate = newValue.from ?? newValue.to;
            // If target date is outside the years range, reset the years range to include the target date
            if (targetDate && (yearsRange.value.from > targetDate.year || yearsRange.value.to < targetDate.year)) {
                setYearsRangeFromActiveMonth();
            }

            if (props.isPeriod) {
                emit('input', {
                    from: newValue.from,
                    to: newValue.to,
                });

                return;
            }

            emit('input', newValue.from);
        },
        {deep: true}
    );

    setActiveMonthFromDate(state.internalValue.value.from);
    setYearsRangeFromActiveMonth();

    function validateProps(value: unknown) {
        if (props.isPeriod) {
            validatePeriodModeProps(value);

            return;
        }

        validateSingleModeProps(value);
    }

    function validateSingleModeProps(value: unknown) {
        if (!props.allowUndefined && isNil(value)) {
            throw new Error('value cannot be undefined when allowUndefined is false');
        }

        if (!isNil(value) && !(value instanceof Epoch)) {
            throw new Error('value must be an Epoch instance');
        }
    }

    function validatePeriodModeProps(value: unknown) {
        if (isNil(value)) {
            throw new Error('value cannot be undefined in period mode');
        }

        if (value instanceof Epoch || !Lang.isObject(value)) {
            throw new Error('value must be an object with from and to properties in period mode');
        }

        if ('from' in value && !isNil(value.from) && !(value.from instanceof Epoch)) {
            throw new Error('value.from must be an Epoch instance');
        }

        if ('to' in value && !isNil(value.to) && !(value.to instanceof Epoch)) {
            throw new Error('value.to must be an Epoch instance');
        }
    }
</script>

<template>
    <div class="MCalendar">
        <MCalendarPresets v-if="!hidePresets && computedPresets.length" />
        <div
            v-if="mode !== 'time'"
            class="MCalendar__content"
        >
            <MCalendarControl />
            <MCalendarDays v-if="activeView === 'day'" />
            <MCalendarMonths v-if="activeView === 'month'" />
            <MCalendarYears v-if="activeView === 'year'" />
        </div>
        <MCalendarTimes v-if="showTime" />
    </div>
</template>

<style scoped>
    .MCalendar {
        @apply tw-flex tw-flex-row tw-items-stretch tw-gap-4;
    }

    .MCalendar__content {
        @apply tw-flex tw-h-[280px] tw-w-[268px] tw-shrink-0 tw-flex-col tw-justify-start;
    }
</style>
