import {app, lumosBindings, ServiceProvider, UnauthorizedError} from '@meekohq/lumos';
import useAuth from '@/modules/app/composables/useAuth';
import BasicAuthentication from '@/modules/auth/utils/BasicAuthentication';
import {AuthenticationContractBinding} from '@/modules/auth/utils/AuthenticationContract';
import {datadogRum} from '@datadog/browser-rum';
import {useLaunchDarkly} from '@/modules/app/composables/useLaunchDarkly';
import useApi from '@/modules/app/composables/useApi';
import useAbility from '@/modules/app/composables/useAbility';
import Manager from '@/modules/legacy/store/manager.store';
import useIntercom from '@/modules/app/composables/useIntercom';
import {BroadcastContractBinding} from '@/modules/app/utils/BroadcastContract';
import i18next from 'i18next';
import useJobList from '@/modules/human-resources/composables/job/useJobList';
import DataStore from '@/modules/legacy/store/data.store';
import {GeneratePersonalAccessTokenUseCaseBinding} from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenUseCase';
import {GeneratePersonalAccessTokenInteractor} from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenInteractor';
import {PaginatePersonalAccessTokenUseCaseBinding} from '@/modules/auth/personal-access-token/application/PaginatePersonalAccessTokenUseCase';
import {PaginatePersonalAccessTokenInteractor} from '@/modules/auth/personal-access-token/application/PaginatePersonalAccessTokenInteractor';
import PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
import {UpdatePersonalAccessTokenUseCaseBinding} from '@/modules/auth/personal-access-token/application/UpdatePersonalAccessTokenUseCase';
import {UpdatePersonalAccessTokenInteractor} from '@/modules/auth/personal-access-token/application/UpdatePersonalAccessTokenInteractor';
import {DeletePersonalAccessTokenUseCaseBinding} from '@/modules/auth/personal-access-token/application/DeletePersonalAccessTokenUseCase';
import {DeletePersonalAccessTokenInteractor} from '@/modules/auth/personal-access-token/application/DeletePersonalAccessTokenInteractor';
import {SudoModeUseCaseBinding} from '@/modules/auth/sudo-mode/application/SudoModeUseCase';
import {SudoModeInteractor} from '@/modules/auth/sudo-mode/application/SudoModeInteractor';
import {AxiosApiBinding} from '@/modules/app/utils/AxiosApiBinding';
import {AxiosLegacyApiBinding} from '@/modules/app/utils/AxiosLegacyApiBinding';
import services from '@/config/services';

export default class AuthServiceProvider extends ServiceProvider {
    public async boot() {
        const api = this.app.make(lumosBindings.MqlAxiosBinding);

        this.loadModelsFrom([PersonalAccessTokenModel]);

        api.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error instanceof UnauthorizedError) {
                    this.app.make(AuthenticationContractBinding).logout();
                }

                return Promise.reject(error);
            }
        );

        await this.app.make(AuthenticationContractBinding).loginByCredentials();
    }

    public register() {
        this.app.singleton(AuthenticationContractBinding, app => {
            return new BasicAuthentication(app.make(AxiosLegacyApiBinding));
        });

        this.app.bind(SudoModeUseCaseBinding, () => new SudoModeInteractor());

        this.app.bind(GeneratePersonalAccessTokenUseCaseBinding, () => new GeneratePersonalAccessTokenInteractor());

        this.app.bind(PaginatePersonalAccessTokenUseCaseBinding, () => new PaginatePersonalAccessTokenInteractor());

        this.app.bind(UpdatePersonalAccessTokenUseCaseBinding, () => new UpdatePersonalAccessTokenInteractor());

        this.app.bind(DeletePersonalAccessTokenUseCaseBinding, () => new DeletePersonalAccessTokenInteractor());

        this.app.make(AuthenticationContractBinding).defineBeforeLoginCallback(async credentials => {
            app(AxiosLegacyApiBinding).defaults.headers.common['Authorization'] = `Bearer ${credentials.token}`;
            app(AxiosApiBinding).defaults.headers.common['Authorization'] = `Bearer ${credentials.token}`;

            return true;
        });

        this.app.make(AuthenticationContractBinding).defineAfterLoginCallback(async (data, credentials) => {
            if (services.datadog.enabled) {
                datadogRum.setUser({
                    id: data.userModel.getKey(),
                });
            }

            if (services.launchDarkly.enabled) {
                try {
                    await useLaunchDarkly().initialize({
                        kind: 'user',
                        key: data.userModel.getKey(),
                        name: data.userModel.fullname,
                        release_channel: data.userModel.attributes.release_channel,
                        account_id: data.userModel.attributes.account_id,
                    });
                } catch (e) {
                    console.warn(e);
                }
            }

            if (services.pusher.enabled) {
                await app(BroadcastContractBinding).setup(data.userModel.getKey(), credentials.token);
            }

            if (services.intercom.enabled && !window.localStorage.getItem('support-token')) {
                const {setup: setupIntercom} = useIntercom();
                setupIntercom(data.legacyUser);
            }

            useAuth().setToken(credentials.token);
            useAuth().setLegacyToken(credentials.legacy_token);
            useAuth().setUserModel(data.userModel);
            useAuth().setLegacyUser(data.legacyUser);
            useAuth().setIsAuthenticated(true);

            // Load user locale
            await i18next.changeLanguage(data.userModel.attributes.locale);

            return true;
        });

        this.app.make(AuthenticationContractBinding).defineAfterLogoutCallback(async () => {
            if (services.datadog.enabled) {
                datadogRum.clearUser();
            }

            if (services.launchDarkly.enabled) {
                useLaunchDarkly().close();
            }

            if (services.pusher.enabled) {
                app(BroadcastContractBinding).disconnect();
            }

            useAbility().arePermissionsLoaded.value = false;
            Manager.flush();

            useAuth().setToken(null);
            useAuth().setLegacyToken(null);
            useAuth().setUserModel(null);
            useAuth().setLegacyUser(null);
            useAuth().setIsAuthenticated(false);

            useJobList().clearJobs();
            DataStore.flush();
            this.app.make(lumosBindings.ModelCacheBinding).clear();

            delete useApi().legacy.defaults.headers.common['Authorization'];
            delete useApi().api.defaults.headers.common['Authorization'];
        });
    }
}
