<template>
    <CPopover
        :prevent-default="preventDefault"
        :prevent-hide-on-click="loading"
        :stop-propagation="stopPropagation"
        @hidden="$emit('hidden')"
        @shown="$emit('shown')"
    >
        <MHoverable v-if="selectedTags.count()">
            <div class="tw-flex tw--space-x-1">
                <template v-for="(tag, index) in selectedTags">
                    <TagPill_v2
                        v-if="index < limit"
                        :key="'tag-' + tag.getKey()"
                        :size="size"
                        :tag="tag"
                    />
                    <div
                        v-else-if="index === limit"
                        :key="'tag-' + tag.getKey()"
                        class="TagsSelector__more"
                        :class="{'TagsSelector__more--sm': size === 'sm'}"
                    >
                        +{{ selectedTags.length - limit }}
                    </div>
                </template>
            </div>
        </MHoverable>
        <template v-else>
            <MButton
                icon-menu
                :size="size"
                variant="light"
            >
                Tags
            </MButton>
        </template>
        <template #content>
            <div
                class="tw-grid tw-gap-1"
                :class="{
                    'tw-pointer-events-none': !editable,
                    'tw-grid-cols-1': availableTags.count() <= 2,
                    'tw-grid-cols-2': availableTags.count() > 2 && availableTags.count() <= 4,
                    'tw-grid-cols-3': availableTags.count() > 4,
                }"
            >
                <CListRow
                    v-if="availableTags.isEmpty()"
                    class="tw-pointer-events-none"
                    size="sm"
                >
                    {{ __('tag:no_tags') }}
                </CListRow>
                <TagsSelectorItem
                    v-for="(tag, i) in availableTags"
                    :key="'filteredTags' + i"
                    :active="selectedTags.contains(tag)"
                    :tag="tag"
                    :taggable-model="taggableModel"
                    :taggable-type="taggableType"
                    @attached="$emit('attached', $event)"
                    @detached="$emit('detached', $event)"
                    @loading="loading = $event"
                />
            </div>
        </template>
    </CPopover>
</template>

<script lang="ts">
    import type TagModel from '@/modules/tag/models/TagModel';
    import type {Model, MorphToMany} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import {cloneDeep} from 'lodash-es';
    import TagPill_v2 from '@/modules/tag/components/TagPill_v2.vue';
    import TagsSelectorItem from '@/modules/tag/components/TagsSelectorItem.vue';

    export default defineComponent({
        components: {
            TagsSelectorItem,
            TagPill_v2,
        },
        props: {
            tags: {
                type: Array as PropType<TagModel[]>,
                default: () => [],
            },
            taggableModel: {
                type: Object as PropType<Model & {tags(): MorphToMany}>,
                default: undefined,
            },
            taggableType: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: 'base',
            },
            limit: {
                type: [Number, String],
                default: 6,
            },
            editable: {
                type: Boolean,
                default: true,
            },
            stopPropagation: {type: Boolean, default: false},
            preventDefault: {type: Boolean, default: false},
        },

        setup(props) {
            const {activeOrganization} = useManager();
            const loading = ref(false);

            /**
             * Available tags in active organization
             */
            const availableTags = computed(() => {
                let tags = cloneDeep(activeOrganization.value.tags().value());

                // Filter tags by taggable type
                if (props.taggableType) {
                    tags = tags.filter(tag => tag.attributes.type === props.taggableType);
                }

                // Finnaly, order tags by name
                return tags.sortBy('attributes.name');
            });

            /**
             * Selected tags
             */
            const selectedTags = computed(() => {
                return availableTags.value.filter(tag => {
                    return collect(props.tags).pluck('attributes.id').contains(tag.getKey());
                });
            });

            return {
                availableTags,
                selectedTags,
                loading,
            };
        },
    });
</script>

<style scoped>
    .TagsSelector__more {
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-aspect-square tw-shrink-0 tw-rounded-lg;
        width: 22px;
        font-size: 12px;
        @apply tw-bg-white tw-font-display tw-text-blue-600 tw-shadow-md tw-ring-1 tw-ring-blue-800/20;
    }

    .TagsSelector__more--sm {
        @apply tw-rounded-md;
        width: 20px;
        font-size: 10px;
    }
</style>
