<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle>
                {{ __('app:menu.staffs_planning') }}
            </MPageTitle>
        </template>
        <template #actions>
            <TopActions
                :current-display="__('common:weekly')"
                :nursery="organization"
                @manageTemplates="showManageTemplatesModal = true"
                @useTemplates="showUseTemplateModal = true"
            />
        </template>
        <template #content>
            <TopActionsSwitcher
                :current-display="__('common:weekly')"
                :nursery="organization"
            />
            <CCard
                body-size="sm"
                :border="false"
                class="tw-relative tw-z-0 tw-mt-3"
                :shadow="true"
            >
                <div class="tw-flex tw-flex-wrap print:tw-hidden">
                    <div class="tw-order-2 tw-w-1/2 tw-text-left sm:tw-order-1 sm:tw-w-1/3">
                        <MButton
                            variant="success"
                            @click="date = date.clone().subtract(1, 'weeks')"
                        >
                            <FontAwesomeIcon
                                class="tw-mr-1"
                                icon="fa-solid fa-angle-left"
                            />
                            {{ __('common:week') }}
                            {{ date.clone().subtract(1, 'weeks').week() }}
                        </MButton>
                    </div>
                    <div class="tw-order-1 tw-w-full tw-text-center sm:tw-order-2 sm:tw-w-1/3">
                        <MHeading>
                            {{ __('common:longweek_with_number', {week: date.week()}) }}
                        </MHeading>
                        <div class="tw-text-gray-500">
                            {{ date.clone().startOf('week').format('DD/MM/Y') }}
                            -
                            {{ date.clone().endOf('week').format('DD/MM/Y') }}
                        </div>
                    </div>
                    <div class="tw-order-3 tw-w-1/2 tw-text-right sm:tw-w-1/3">
                        <MButton
                            variant="success"
                            @click="date = date.clone().add(1, 'weeks')"
                        >
                            {{ __('common:week') }}
                            {{ date.clone().add(1, 'weeks').week() }}
                            <FontAwesomeIcon
                                class="tw-ml-1"
                                icon="fa-solid fa-angle-right"
                            />
                        </MButton>
                    </div>
                </div>
                <h4
                    class="h4 tw-mt-0 tw-hidden tw-w-full tw-justify-center tw-pb-5 tw-capitalize tw-text-blue-500 print:tw-flex"
                >
                    {{
                        __('common:date_format.human_date_to_date_with_week', {
                            from: date.clone().startOf('week').format('DD/MM/YYYY'),
                            to: date.clone().endOf('week').format('DD/MM/YYYY'),
                            week: date.week(),
                        })
                    }}
                </h4>

                <div class="tw-overflow-x-auto tw-overflow-y-hidden tw-py-6 print:tw-overflow-x-visible">
                    <loader
                        v-if="loading"
                        light="true"
                        shadow="false"
                        :title="__('common:loading_dots')"
                    />
                    <div
                        v-else-if="mainPayload.processed"
                        class="tw-relative tw-flex tw-justify-between"
                    >
                        <div class="tw-w-28">
                            <div class="tw-mb-6 tw-mt-6">
                                <div class="tw-h-7" />
                                <div>
                                    <FilterGroup
                                        class="tw-mb-2 tw-w-full"
                                        :nursery="organization"
                                        :value="selectedGroup"
                                        variant="primary"
                                        @input="selectedGroup = $event"
                                    />
                                    <div>
                                        <MButton
                                            class="tw-w-full"
                                            icon-menu
                                            variant="primary"
                                            @click="$refs.staffFilter.toggle()"
                                        >
                                            {{
                                                selectedPlanning === 'present' || selectedGroup !== null
                                                    ? __('planning:present_team')
                                                    : __('planning:active_staff')
                                            }}
                                        </MButton>
                                        <CDropdown
                                            ref="staffFilter"
                                            legacy-mode
                                        >
                                            <CList class="tw-cursor-pointer">
                                                <CDropdownMenuItem @click="changePlanningType('present')">
                                                    <FontAwesomeIcon
                                                        v-if="selectedPlanning === 'present' || selectedGroup !== null"
                                                        class="tw-mr-2"
                                                        icon="fa-solid fa-check"
                                                    />
                                                    {{ __('planning:present_team') }}
                                                </CDropdownMenuItem>
                                                <MTooltip
                                                    class="tw-w-full"
                                                    :hoverable="selectedGroup !== null"
                                                    :label="__('planning:group_selected')"
                                                >
                                                    <CDropdownMenuItem
                                                        class="tw-w-full"
                                                        :disabled="selectedGroup !== null"
                                                        @click="changePlanningType('active')"
                                                    >
                                                        <FontAwesomeIcon
                                                            v-if="
                                                                selectedPlanning === 'active' && selectedGroup === null
                                                            "
                                                            class="tw-mr-2"
                                                            icon="fa-solid fa-check"
                                                        />
                                                        {{ __('planning:active_staff') }}
                                                    </CDropdownMenuItem>
                                                </MTooltip>
                                                <MMenuDivider />
                                                <CDropdownMenuItem
                                                    @click="
                                                        includeOtherOrganizationsStaffs =
                                                            !includeOtherOrganizationsStaffs
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        v-if="includeOtherOrganizationsStaffs"
                                                        class="tw-mr-2"
                                                        icon="fa-solid fa-check"
                                                    />
                                                    {{ __('planning:include_multi_organization_staffs') }}
                                                </CDropdownMenuItem>
                                                <MMenuDivider v-if="teamFilter && teamFilter.hasQueryResults" />
                                                <TeamFinder
                                                    ref="teamFilter"
                                                    v-model="selectedTeams"
                                                    button-class="tw-w-full"
                                                    multi
                                                />
                                            </CList>
                                        </CDropdown>
                                    </div>
                                </div>
                            </div>

                            <div v-show="!loading">
                                <div
                                    v-for="(staff, i) in staffs"
                                    :key="'staff' + i"
                                    class="tw-mb-1 tw-h-9"
                                >
                                    <div
                                        class="tw-flex tw-h-full tw-items-center tw-font-grandhotel tw-text-xl tw-capitalize"
                                        :class="staff.genderColor"
                                    >
                                        <BadgeTasks
                                            class="tw-mr-3"
                                            size="sm"
                                            :tasks="tasksByStaffId[staff.getKey()]"
                                        >
                                            <StaffAvatar
                                                class="tw-w-8"
                                                :staff-model="staff"
                                            />
                                        </BadgeTasks>
                                        <router-link
                                            class="tw-truncate tw-text-inherit"
                                            :to="{
                                                name: 'staffs.show',
                                                params: {
                                                    nursery: organization.id,
                                                    staff: staff.id,
                                                },
                                            }"
                                        >
                                            {{ $fullName(staff.attributes) }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tw-flex tw-w-full tw-justify-between">
                            <div
                                v-for="(dayOfWeek, dayIndex) in epochEnumerateDaysBetweenDates"
                                :key="'currentDay_' + dayIndex"
                                class="showDayHoursTrigger tw-ml-3 tw-w-full tw-min-w-48"
                            >
                                <div style="padding-left: 16px">
                                    <router-link
                                        class="tw-cursor-pointer tw-text-center"
                                        tag="div"
                                        :to="{
                                            name: 'planning.staffs.daily',
                                            params: {
                                                nursery: organization.id,
                                                date: dayOfWeek.toLocaleString(Epoch.presets.DATE_SHORT),
                                            },
                                        }"
                                    >
                                        <h6 class="h6 tw-relative tw-h-5">
                                            <div
                                                class="showDay tw-w-full print:tw-hidden"
                                                :class="[
                                                    {
                                                        'tw-font-semibold tw-text-blue-500': dayOfWeek.isToday,
                                                    },
                                                ]"
                                            >
                                                {{ $capitalize(dayOfWeek.toFormat('cccc - dd/MM')) }}
                                                <CBadge
                                                    v-if="
                                                        !occupationLoading &&
                                                        getOccupationRate(dayOfWeek.toISOString()) == null
                                                    "
                                                    size="sm"
                                                >
                                                    {{ __('common:closed') }}
                                                </CBadge>
                                            </div>
                                            <div class="tw-hidden tw-w-full print:tw-block">
                                                {{ $capitalize(dayOfWeek.toFormat('cccc - dd/MM')) }}
                                            </div>
                                        </h6>
                                    </router-link>
                                    <div class="tw--ml-2 tw--mt-1 tw-mb-1 tw-h-7 tw-pr-3.5">
                                        <Hours
                                            class="showDayHours"
                                            :closing="mainPayload.closings[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                            grid-on-hover
                                            :opening="mainPayload.openings[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                            simple
                                            small
                                        />
                                    </div>
                                    <div class="tw-mb-5">
                                        <summary-bar
                                            class="tw-mb-2"
                                            :closing="mainPayload.closings[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                            :day="dayOfWeek"
                                            :loading="
                                                occupationLoading ||
                                                !mainPayload.processed ||
                                                !occupationPayload.processed
                                            "
                                            :nursery="organization"
                                            :opening="mainPayload.openings[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                            small
                                            :summary="occupationPayload.data[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                        />
                                        <summary-bar-staff
                                            :closing="mainPayload.closings[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                            :day="dayOfWeek"
                                            :jobs="jobs"
                                            :loading="
                                                supervisionLoading ||
                                                !mainPayload.processed ||
                                                !supervisionPayload.processed
                                            "
                                            :nursery="organization"
                                            :opening="mainPayload.openings[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                            small
                                            :summary="supervisionPayload.data[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                        />
                                    </div>
                                </div>

                                <div
                                    v-for="(staff, i) in staffs"
                                    :key="'staff_planning' + i"
                                    class="tw-mb-1 tw-flex tw-h-9 tw-items-center"
                                >
                                    <PlanningGenerator
                                        always-show-full-day
                                        always-show-ghost
                                        :closing="mainPayload.closings[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                        :create-with-modal="true"
                                        :day="dayOfWeek"
                                        :events="
                                            staff
                                                .events()
                                                .value()
                                                .all()
                                                .filter(event => event.attributes.date_event === null)
                                        "
                                        :full-day-events="mainPayload.fullDayEvents[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                        :opening="mainPayload.openings[dayOfWeek.toFormat('yyyy-MM-dd')]"
                                        :show-divider="false"
                                        show-note
                                        size="md"
                                        :staff="staff"
                                        @add="newEvent(dayOfWeek, staff, true, true)"
                                        @pushEvent="staff.events().value().all().push($event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h1
                    v-if="!loading && staffs.length === 0"
                    class="h1 tw-pt-4 tw-text-center tw-font-grandhotel"
                >
                    {{ __('planning:there_are_no_team_member') }}
                </h1>
            </CCard>

            <ManageTemplatesModal
                v-if="showManageTemplatesModal"
                :current-week="date.week()"
                :staffs-from-current-week="staffs"
                @hidden="showManageTemplatesModal = false"
            />

            <UseTemplateModal
                v-if="showUseTemplateModal"
                @hidden="showUseTemplateModal = false"
                @update:plannings="getCalendar()"
            />

            <EventModal
                v-if="selectedEvent"
                ref="eventModal"
                :event="selectedEvent"
                @add="onEventAdded"
                @delete="onEventDeleted"
                @hidden="selectedEvent = null"
            />
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {defineComponent, onMounted, onUnmounted, ref, watch} from 'vue';
    import moment from 'moment';
    import useCalendar from '@/modules/human-resources/composables/calendar/useCalendar';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import ManageTemplatesModal from '@/modules/human-resources/components/calendar/CalendarTemplate/Weekly/ManageTemplates.vue';
    import UseTemplateModal from '@/modules/human-resources/components/calendar/CalendarTemplate/Weekly/UseTemplate.vue';
    import Summary from '@/modules/legacy/components/Modules/PlanningGenerator/Summary.vue';
    import StaffSummary from '@/modules/legacy/components/Modules/PlanningGenerator/StaffSummary.vue';
    import TopActions from '@/modules/planning/components/staff/TopActions.vue';
    import FilterGroup from '@/modules/family/components/kid/Index/FilterGroup.vue';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import TeamFinder from '@/modules/human-resources/components/team/TeamFinder.vue';
    import JobModel from '@/modules/human-resources/models/JobModel';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import type TeamModel from '@/modules/human-resources/models/TeamModel';
    import useMetrics from '@/modules/app/composables/useRum';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import TopActionsSwitcher from '@/modules/planning/components/staff/TopActionsSwitcher.vue';
    import {whenever} from '@vueuse/core';
    import {EventBus} from '@/modules/legacy/utils/bus';
    import {Epoch} from '@meekohq/lumos';

    export default defineComponent({
        components: {
            TopActionsSwitcher,
            SimplePageTemplate,
            StaffAvatar,
            'PlanningGenerator': Line,
            Hours,
            EventModal,
            ManageTemplatesModal,
            UseTemplateModal,

            'summary-bar': Summary,
            'summary-bar-staff': StaffSummary,
            TopActions,
            FilterGroup,
            TeamFinder,
            BadgeTasks,
        },
        setup() {
            const teamFilter = ref();
            const {addAction} = useMetrics();
            const {selectedOptions: selectedTeams} = useUserFilters('calendar:staffs:teams', OutputType.array, []);

            const {selectedOptions: selectedPlanning} = useUserFilters(
                'calendar:staffs:planningType',
                OutputType.value,
                'present'
            );

            const {selectedOptions: selectedGroup} = useUserFilters('calendar:staffs:group', OutputType.value, null);

            const {selectedOptions: includeOtherOrganizationsStaffs} = useUserFilters(
                'calendar:staffs:includeOtherOrganizationsStaffs',
                OutputType.value,
                true
            );

            const {
                organization,

                changePlanningType,
                date,
                epochEnumerateDaysBetweenDates,
                opening,
                closing,

                loading,
                getCalendar,

                staffs,

                occupationLoading,
                supervisionLoading,
                weekOccupationRateBar,
                weekOccupationRateBarStaff,

                fullDayEvents,
                selectedEvent,

                newEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,

                getOccupationRate,
            } = useCalendar(
                undefined,
                undefined,
                null,
                true,
                true,
                true,
                ref({
                    teams: selectedTeams as Ref<TeamModel[]>,
                    planningType: selectedPlanning as Ref<'present' | 'active'>,
                    group: selectedGroup as Ref<string | null>,
                    includeOtherOrganizationsStaffs: includeOtherOrganizationsStaffs as Ref<boolean>,
                }),
                'week'
            );

            const {
                tasks,
                tasksGroupByModelId: tasksByStaffId,
                getTasks: getTasksRelatedToStaffs,
            } = useGetTasks({
                constrainToResourceModel: StaffModel,
            });

            watch(staffs, newStaffs => {
                getTasksRelatedToStaffs(newStaffs.map(staff => staff.getKey())).then(
                    tasksRelatedToStaffs => (tasks.value = tasksRelatedToStaffs)
                );
            });

            watch(selectedTeams, () => {
                getCalendar();
            });

            onMounted(() => {
                addAction('M_Planning_Staff_Display', {value: 'week'});

                // Update opening and closing hours when the calendar is refreshed
                EventBus.$on('calendar:staffs:refresh', () => {
                    refreshPayload();
                });
            });

            onUnmounted(() => {
                EventBus.$off('calendar:staffs:refresh');
            });

            const jobs = ref<any[]>([]);

            JobModel.query()
                .get()
                .then(data => {
                    jobs.value = data.all();
                });

            const showManageTemplatesModal = ref(false);
            const showUseTemplateModal = ref(false);

            // ****** Keep data in cache for performance
            const mainPayload = ref({
                processed: false,
                openings: {},
                closings: {},
                fullDayEvents: {},
            });

            whenever(
                () => loading.value === false,
                () => {
                    refreshPayload();
                    mainPayload.value.processed = true;
                }
            );

            const occupationPayload = ref({
                processed: false,
                data: {},
            });

            whenever(
                () => occupationLoading.value === false,
                () => {
                    for (const day of epochEnumerateDaysBetweenDates.value) {
                        occupationPayload.value.data[day.toFormat('yyyy-MM-dd')] = weekOccupationRateBar(
                            day.toISOString()
                        );
                    }
                    occupationPayload.value.processed = true;
                }
            );

            const supervisionPayload = ref({
                processed: false,
                data: {},
            });

            whenever(
                () => supervisionLoading.value === false,
                () => {
                    for (const day of epochEnumerateDaysBetweenDates.value) {
                        supervisionPayload.value.data[day.toFormat('yyyy-MM-dd')] = weekOccupationRateBarStaff(
                            day.toISOString()
                        );
                    }
                    supervisionPayload.value.processed = true;
                }
            );

            function refreshPayload() {
                for (const day of epochEnumerateDaysBetweenDates.value) {
                    mainPayload.value.openings[day.toFormat('yyyy-MM-dd')] = opening(day.toISOString(), true);
                    mainPayload.value.closings[day.toFormat('yyyy-MM-dd')] = closing(day.toISOString(), true);
                    mainPayload.value.fullDayEvents[day.toFormat('yyyy-MM-dd')] = fullDayEvents(
                        day.toISOString()
                    ).all();
                }
            }

            return {
                Epoch,
                mainPayload,
                occupationPayload,
                supervisionPayload,

                changePlanningType,
                moment,

                organization,

                date,
                epochEnumerateDaysBetweenDates,

                loading,
                getCalendar,

                teamFilter,
                selectedTeams,
                staffs,
                selectedPlanning,
                selectedGroup,
                includeOtherOrganizationsStaffs,

                occupationLoading,
                supervisionLoading,
                weekOccupationRateBar,
                weekOccupationRateBarStaff,

                fullDayEvents,
                selectedEvent,

                newEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,

                getOccupationRate,

                jobs,

                showManageTemplatesModal,
                showUseTemplateModal,
                tasksByStaffId,
            };
        },
    });
</script>

<style scoped>
    .showDayHours {
        @apply tw-opacity-0 tw-transition-all tw-duration-300 tw-ease-in-out;
    }

    .showDay {
        @apply tw-absolute tw-top-5 tw-transition-all tw-ease-in-out;
    }

    .showDayHoursTrigger:hover {
        .showDayHours {
            @apply tw-opacity-100;
        }

        .showDay {
            @apply tw-top-0;
        }
    }

    @media print {
        .showDayHoursTrigger {
            .showDayHours {
                @apply tw-opacity-100;
            }

            .showDay {
                @apply tw-top-0;
            }
        }
    }
</style>
