<template>
    <div :class="styleItem">
        <div :class="showEmployeesList ? 'tw-w-3/12' : 'tw-w-2/12'">
            <CText v-if="job">
                {{ job.attributes.name }}
            </CText>
            <CText v-else> - </CText>
            <MHelpTooltip
                v-if="showTooltip"
                class="tw-ml-1"
            >
                <div>
                    <MHeading
                        class="tw-mb-2"
                        level="h4"
                        variant="white"
                    >
                        {{ __('common:additional_information') }}
                    </MHeading>
                    <ul class="tw-flex tw-flex-col tw-justify-around">
                        <li class="tw-mb-2 tw-flex tw-flex-col">
                            <MHeading
                                level="h4"
                                variant="white"
                            >
                                {{ __('hr:mentor') }}
                            </MHeading>
                            <div
                                v-if="
                                    contract.attributes.supervisor_first_name &&
                                    contract.attributes.supervisor_last_name
                                "
                            >
                                {{ contract.attributes.supervisor_first_name }}
                                {{ contract.attributes.supervisor_last_name }}
                            </div>
                            <div v-else>-</div>
                        </li>
                        <li class="tw-flex tw-flex-col">
                            <MHeading
                                level="h4"
                                variant="white"
                            >
                                {{ __('hr:presence_location') }}
                            </MHeading>
                            <div class="tw-text-white">
                                {{ staff.attributes.address.line1 }}
                                {{ staff.attributes.address.line2 }}
                                {{ staff.attributes.address.postal_code }}
                                {{ staff.attributes.address.city }}
                            </div>
                        </li>
                    </ul>
                </div>
            </MHelpTooltip>
        </div>
        <template v-if="!showEmployeesList">
            <div class="tw-w-2/12">
                <CText v-if="contract.attributes.supervisor_first_name && contract.attributes.supervisor_last_name">
                    {{ contract.attributes.supervisor_first_name }} {{ contract.attributes.supervisor_last_name }}
                </CText>
                <CText v-else> - </CText>
            </div>
            <div class="tw-w-1/12 tw-truncate">
                <CText>
                    {{ staff.attributes.address.line1 }} {{ staff.attributes.address.line2 }}
                    {{ staff.attributes.address.postal_code }} {{ staff.attributes.address.city }}
                </CText>
            </div>
        </template>
        <div
            v-if="showEmployeesList"
            class="tw-w-2/12"
        >
            <CText v-if="contractType">
                <template v-if="contract.attributes.part_time_work">
                    {{ __('hr:contract_name_with_part_time', {value: contractType.attributes.name}) }}
                </template>
                <template v-else>
                    {{ contractType.attributes.name }}
                </template>
            </CText>
            <CText v-else> - </CText>
        </div>
        <div :class="showEmployeesList ? 'tw-w-2/12' : 'tw-w-1/12'">
            <CText>
                {{ formatDate(contract.attributes.started_at) }}
            </CText>
        </div>
        <div :class="showEmployeesList ? 'tw-w-2/12' : 'tw-w-1/12'">
            <CText>
                {{ formatDate(contract.attributes.ended_at) || '-' }}
            </CText>
        </div>
        <div :class="showEmployeesList ? 'tw-w-2/12' : 'tw-w-1/12'">
            <CText>
                {{ formatDate(contract.attributes.broked_at) || '-' }}
            </CText>
        </div>
        <div class="tw-w-2/12 tw-truncate">
            <CText>
                {{ contract.attributes.qualification || '-' }}
            </CText>
        </div>
        <div class="tw-w-1/12 tw-truncate">
            <CText>
                {{ contract.attributes.note || '-' }}
            </CText>
        </div>
    </div>
</template>
<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type ContractModel from '@/modules/human-resources/models/ContractModel';
    import useMoment from '@/modules/app/composables/useMoment';
    import useStaffList from '@/modules/human-resources/composables/staff-register/useStaffList';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import {jobName} from '@/modules/human-resources/models/JobModel';

    export default defineComponent({
        components: {},
        props: {
            contract: {type: Object as PropType<ContractModel>, required: true},
            selectedOrganizationsIds: {required: true, type: Array as PropType<(string | number)[]>},
            staff: {type: Object as PropType<StaffModel>, required: true},
        },
        setup(props) {
            const {formatDate} = useMoment();
            const {showEmployeesList} = useStaffList();
            // Get the contract's job and contractType.
            const job = computed(() => props.contract.job().value());
            const contractType = computed(() => props.contract.contractType().value());
            // Check if contract is in organization selected in filter.
            const isContractInFilter = computed(() =>
                props.contract.organizations().value().whereIn('id', props.selectedOrganizationsIds)
            );

            // Return a boolean if tooltip can be show or not.
            const showTooltip = computed(() => {
                if (job.value) {
                    // If job exist, return true if it's an intern job and if the employees list is showed.
                    return job.value.attributes.internal_id === jobName.intern && showEmployeesList.value;
                }

                return false;
            });

            const styleItem = computed(() => {
                const output = ['tw-flex tw-items-end'];
                if (!isContractInFilter.value.isNotEmpty()) {
                    output.push('tw-opacity-50');
                }

                return output;
            });

            return {
                formatDate,
                contractType,
                isContractInFilter,
                styleItem,
                showEmployeesList,
                job,
                showTooltip,
            };
        },
    });
</script>
