<template>
    <MMenu>
        <MMenuButton>
            <MButton
                icon-menu
                :size="size"
            >
                {{ contractStatesText }}
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem @click="updateContractStates(null)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="!value || typeof value === 'undefined' || value.length === 0"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                </template>
                {{ __('common:every_contract') }}
            </MMenuItem>
            <MMenuDivider />
            <template v-if="value && Array.isArray(value)">
                <MMenuItem
                    :auto-close-popover="false"
                    @click="updateContractStates('coming')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="value && value.includes('coming')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('common:upcoming_contract') }}
                </MMenuItem>
                <MMenuItem
                    :auto-close-popover="false"
                    @click="updateContractStates('current')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="value && value.includes('current')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('common:current_contract') }}
                </MMenuItem>
                <MMenuItem
                    :auto-close-popover="false"
                    @click="updateContractStates('past')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="value && value.includes('past')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('common:past_contract') }}
                </MMenuItem>
                <MMenuDivider />
                <MMenuItem
                    :auto-close-popover="false"
                    :disabled="value && value.length === 0"
                    @click="value && value.length > 0 ? updateContractStates('including_draft') : null"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="value && value.includes('including_draft')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('common:include_drafts') }}
                </MMenuItem>
            </template>
        </MMenuItems>
    </MMenu>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import _isEqual from 'lodash-es/isEqual';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            size: {
                type: String,
                default: 'base',
            },
        },
        setup(props, {emit}) {
            const contractStatesText = computed(() => {
                let txt = __('hr_contract:filter_by_contract');
                if (!props.value || props.value.length === 0) {
                    txt = __('common:every_contract');
                }

                if (props.value.length === 1) {
                    if (props.value.includes('coming')) {
                        txt = __('common:upcoming_contract');
                    }

                    if (props.value.includes('current')) {
                        txt = __('common:current_contract');
                    }

                    if (props.value.includes('past')) {
                        txt = __('common:past_contract');
                    }

                    if (props.value.includes('including_draft')) {
                        txt = __('common:include_drafts');
                    }
                }

                return txt;
            });

            function updateContractStates(val) {
                let states = props.value ? props.value.slice() : [];

                if (!val) {
                    states = [];
                } else if (states.includes(val)) {
                    states = states.filter(e => e !== val);
                } else {
                    states.push(val);

                    const allStates = ['coming', 'current', 'past', 'including_draft'];
                    if (_isEqual([...allStates].sort(), [...states].sort())) {
                        states = [];
                    }
                }

                // Remove 'including_draft' filter if it's the only selected filter
                if (_isEqual(['including_draft'], states)) {
                    states = [];
                }

                emit('input', states);
            }

            return {
                contractStatesText,
                updateContractStates,
            };
        },
    });
</script>
