import {ServiceProvider} from '@meekohq/lumos';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import ContactModel from '@/modules/cashier/models/ContactModel';
import AddressModel from '@/modules/cashier/models/AddressModel';
import OrderModel from '@/modules/cashier/models/OrderModel';
import TenantModel from '@/modules/cashier/models/TenantModel';
import SubscriptionModel from '@/modules/cashier/models/SubscriptionModel';
import EndpointModel from '@/modules/cashier/models/EndpointModel';
import TaxModel from '@/modules/cashier/models/TaxModel';
import CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import NumberFormatModel from '@/modules/cashier/models/NumberFormatModel';
import PaymentMethodModel from '@/modules/cashier/models/PaymentMethodModel';
import PaymentSchedulerModel from '@/modules/cashier/models/PaymentSchedulerModel';
import PaymentSchedulerRuleModel from '@/modules/cashier/models/PaymentSchedulerRuleModel';
import UnitModel from '@/modules/cashier/models/UnitModel';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import {TransactionManagerService} from '@/modules/cashier/transaction/application/TransactionManagerService';
import {PaginateTransactionsUseCaseBinding} from '@/modules/cashier/transaction/application/use-cases/PaginateTransactionsUseCase';
import {TransactionRepositoryAdapter} from '@/modules/cashier/transaction/infrastructure/TransactionRepositoryAdapter';
import {MassDeleteTransactionsByFiltersUseCaseBinding} from '@/modules/cashier/transaction/application/use-cases/MassDeleteTransactionsByFiltersUseCase';
import {RejectAndRestoreTransactionsUseCaseBinding} from '@/modules/cashier/transaction/application/use-cases/RejectAndRestoreTransactionsUseCase';
import {SumTransactionsByCurrenciesUseCaseBinding} from '@/modules/cashier/transaction/application/use-cases/SumTransactionsByCurrenciesUseCase';
import {MQLAllocationAggregateRepository} from '@/modules/cashier/payment/infrastructure/MQLAllocationAggregateRepository';
import {DetachCreditNoteToInvoiceUseCaseBinding} from '@/modules/cashier/payment/application/DetachCreditNoteToInvoiceUseCase';
import {DetachCreditNoteToInvoiceInteractor} from '@/modules/cashier/payment/application/DetachCreditNoteToInvoiceInteractor';
import {MQLCreditNoteAllocationAggregateRepository} from '@/modules/cashier/payment/infrastructure/MQLCreditNoteAllocationAggregateRepository';
import {MQLInvoiceAllocationAggregateRepository} from '@/modules/cashier/payment/infrastructure/MQLInvoiceAllocationAggregateRepository';
import {SaveAllocationAggregateUseCaseBinding} from '@/modules/cashier/payment/application/SaveAllocationAggregateUseCase';
import {SaveAllocationAggregateInteractor} from '@/modules/cashier/payment/application/SaveAllocationAggregateInteractor';
import {GetAllocationsForInvoiceUseCaseBinding} from '@/modules/cashier/payment/application/GetAllocationsForInvoiceUseCase';
import {GetAllocationsForInvoiceInteractor} from '@/modules/cashier/payment/application/GetAllocationsForInvoiceInteractor';
import {GetAllocationsForCreditNoteUseCaseBinding} from '@/modules/cashier/payment/application/GetAllocationsForCreditNoteUseCase';
import {GetAllocationsForCreditNoteInteractor} from '@/modules/cashier/payment/application/GetAllocationsForCreditNoteInteractor';
import {GetAllocationsSuggestionsForPaymentUseCaseBinding} from '@/modules/cashier/payment/application/GetAllocationsSuggestionsForPaymentUseCase';
import {GetAllocationsSuggestionsForPaymentInteractor} from '@/modules/cashier/payment/application/GetAllocationsSuggestionsForPaymentInteractor';
import {UpdateInvoiceInputPortBinding} from '@/modules/cashier/invoice/domain/use-cases/UpdateInvoiceInputPort';
import {UpdateInvoiceInteractor} from '@/modules/cashier/invoice/domain/use-cases/UpdateInvoiceInteractor';

export default class CashierServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            CustomerModel,
            ContactModel,
            AddressModel,
            OrderModel,
            TenantModel,
            SubscriptionModel,
            EndpointModel,
            TaxModel,
            CurrencyModel,
            NumberFormatModel,
            PaymentMethodModel,
            PaymentSchedulerModel,
            PaymentSchedulerRuleModel,
            UnitModel,
            AllocationModel,
            TransactionModel,
            PaymentModel,
            InvoiceModel,
        ]);
    }

    public register() {
        this.app.bind(SaveAllocationAggregateUseCaseBinding, () => {
            return new SaveAllocationAggregateInteractor(new MQLAllocationAggregateRepository());
        });

        this.app.bind(GetAllocationsSuggestionsForPaymentUseCaseBinding, () => {
            return new GetAllocationsSuggestionsForPaymentInteractor(
                new MQLInvoiceAllocationAggregateRepository(),
                new MQLCreditNoteAllocationAggregateRepository()
            );
        });

        this.app.bind(GetAllocationsForInvoiceUseCaseBinding, () => {
            return new GetAllocationsForInvoiceInteractor(new MQLInvoiceAllocationAggregateRepository());
        });

        this.app.bind(GetAllocationsForCreditNoteUseCaseBinding, () => {
            return new GetAllocationsForCreditNoteInteractor(new MQLCreditNoteAllocationAggregateRepository());
        });

        this.app.bind(DetachCreditNoteToInvoiceUseCaseBinding, () => {
            return new DetachCreditNoteToInvoiceInteractor();
        });

        this.app.bind(MassDeleteTransactionsByFiltersUseCaseBinding, () => {
            return new TransactionManagerService(new TransactionRepositoryAdapter());
        });

        this.app.bind(PaginateTransactionsUseCaseBinding, () => {
            return new TransactionManagerService(new TransactionRepositoryAdapter());
        });

        this.app.bind(RejectAndRestoreTransactionsUseCaseBinding, () => {
            return new TransactionManagerService(new TransactionRepositoryAdapter());
        });

        this.app.bind(SumTransactionsByCurrenciesUseCaseBinding, () => {
            return new TransactionManagerService(new TransactionRepositoryAdapter());
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.app.bind(UpdateInvoiceInputPortBinding, (app, presenter) => {
            return new UpdateInvoiceInteractor(presenter);
        });
    }
}
