<template>
    <div>
        <MMenu>
            <MMenuButton>
                <MButton
                    icon-ellipsis
                    size="sm"
                    variant="ghost"
                />
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('billing_core:set_as_default')"
                    @click="setDefaultBillingAddress"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-check"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :label="__('billing_setting:update_address')"
                    @click="editModal.show"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-pen"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :disabled="orders.isNotEmpty()"
                    :label="__('billing_core:delete_address_dots')"
                    variant="danger"
                    @click="tryToDelete"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
        <DeleteAddressModal
            ref="deleteModal"
            :address-model="addressModel"
            @deleted="$emit('deleted', addressModel)"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, toRef} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type AddressModel from '@/modules/cashier/models/AddressModel';
    import type OrderModel from '@/modules/cashier/models/OrderModel';
    import DeleteAddressModal from '@/modules/cashier/components/core/address/organisms/DeleteAddressModal.vue';
    import UpdateAddressModal from '@/modules/cashier/components/core/address/organisms/UpdateAddressModal.vue';
    import useModal from '@/modules/app/composables/useModal';

    export default defineComponent({
        components: {DeleteAddressModal},
        props: {
            customerModel: {
                type: Object as PropType<CustomerModel>,
                required: true,
            },
            addressModel: {
                type: Object as PropType<AddressModel>,
                required: true,
            },
        },

        setup(props) {
            const menu = ref();
            const deleteModal = ref();
            const customerModel = toRef(props, 'customerModel');
            const addressModel = toRef(props, 'addressModel');

            const orders = computed((): Collection<OrderModel> => {
                return props.addressModel.orders().value();
            });

            function setDefaultBillingAddress() {
                customerModel.value.attributes.default_billing_address_id = addressModel.value.id;
                customerModel.value.save();
            }

            function tryToDelete() {
                if (orders.value.isEmpty()) {
                    deleteModal.value.show();
                }
            }

            const editModal = useModal({
                component: UpdateAddressModal,
                props: {
                    addressModel: computed(() => addressModel.value.clone()),
                },
                listeners: () => ({
                    update: (newAddressModel: AddressModel) => {
                        newAddressModel.copyTo(props.addressModel);
                    },
                }),
            });

            return {
                menu,
                deleteModal,
                orders,
                setDefaultBillingAddress,
                tryToDelete,
                editModal,
            };
        },
    });
</script>
