<template>
    <MModal
        :modal="modal"
        size="5xl"
    >
        <template #header-text>
            {{ __('billing_invoice:lock_invoices') }}
            -
            <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
        </template>

        <SelectInvoices
            action="convert"
            :date="date"
            :nursery="nursery"
            :selected-invoices.sync="selectedInvoices"
        />

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :loading="loading"
                variant="primary"
                @click="convert"
            >
                {{ __('common:actions.lock') }}
                -
                <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
            </MButton>
        </template>
    </MModal>
</template>

<script>
    import _map from 'lodash-es/map';
    import SelectInvoices from './SelectInvoices.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {MqlOperation} from '@meekohq/lumos';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import moment from 'moment';

    export default {
        components: {
            SelectInvoices,
        },
        props: {
            nursery: {
                type: OrganizationModel,
                required: true,
            },
            date: {
                type: moment,
                required: true,
            },
            modal: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                selectedInvoices: [],
                loading: false,
            };
        },
        methods: {
            async convert() {
                if (this.loading || !this.selectedInvoices.length) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await new MqlOperation('cashier/lock_invoices', {
                        invoice_ids: _map(this.selectedInvoices, 'id'),
                    }).run();
                    this.$emit('updated', response.content.data);
                    useNotification().success(
                        __('billing_invoice:invoices_converted_successfully', {count: this.selectedInvoices.length})
                    );
                    this.loading = false;
                } catch (e) {
                    useNotification().error(__('common:errors.generic'));
                    this.loading = false;
                }
            },
        },
    };
</script>
