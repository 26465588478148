<template>
    <MMenu>
        <MMenuButton>
            <MButton
                icon-menu
                :size="size"
            >
                <template #left-icons>
                    <FontAwesomeIcon
                        :icon="
                            filters.queryDirection === 'asc'
                                ? 'fa-solid fa-sort-alpha-down'
                                : 'fa-solid fa-sort-alpha-up'
                        "
                    />
                </template>
                <template v-for="option in options">
                    <template v-if="filters.orderBy === option.value">
                        {{ option.label }}
                    </template>
                </template>
            </MButton>
        </MMenuButton>

        <MMenuItems>
            <MMenuItem
                :label="__('common:ascending')"
                @click="filters.queryDirection = 'asc'"
            >
                <template #icon>
                    <FontAwesomeIcon
                        fixed-width
                        icon="fa-solid fa-sort-alpha-down"
                    />
                </template>
            </MMenuItem>
            <MMenuItem
                :label="__('common:descending')"
                @click="filters.queryDirection = 'desc'"
            >
                <template #icon>
                    <FontAwesomeIcon
                        fixed-width
                        icon="fa-solid fa-sort-alpha-up"
                    />
                </template>
            </MMenuItem>
            <MMenuDivider />
            <MMenuItem
                v-for="option in options"
                :key="option.value"
                :label="option.sortLabel"
                @click="filters.orderBy = option.value"
            >
                <template
                    v-if="option.icon"
                    #icon
                >
                    <FontAwesomeIcon
                        fixed-width
                        :icon="option.icon"
                    />
                </template>
            </MMenuItem>
        </MMenuItems>
    </MMenu>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type {QueryBuilderOrderByDirectionType} from '@meekohq/lumos';

    export default defineComponent({
        props: {
            filters: {
                type: Object as PropType<{orderBy: string; queryDirection: QueryBuilderOrderByDirectionType}>,
                required: true,
            },
            size: {type: String, default: 'sm'},
            options: {
                type: Array as PropType<{value: string; label: string; sortLabel: string; icon: string}[]>,
                required: true,
            },
        },
    });
</script>
