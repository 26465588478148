import {Model} from '@meekohq/lumos';
import JobModel from '@/modules/human-resources/models/JobModel';

export default class JobOrganizationPivot extends Model {
    public type = 'hr/jobs_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        job_id: string | undefined;
        organization_id: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        job_id: undefined,
        organization_id: undefined,
    };

    job() {
        return this.belongsTo('job', JobModel, 'job_id');
    }
}
