<template>
    <WidgetCore class="TodoWidgetTemplate">
        <div class="TodoWidgetTemplate__nav">
            <slot name="nav" />
        </div>
        <div class="TodoWidgetTemplate__content">
            <slot name="content" />
        </div>
    </WidgetCore>
</template>

<script lang="ts">
    import WidgetCore from '@/modules/app/components/atoms/WidgetCore.vue';
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {WidgetCore},
        props: {},
        setup() {
            return {};
        },
    });
</script>
<style scoped>
    .TodoWidgetTemplate {
        @apply tw-w-full tw-overflow-auto md:tw-w-[652px];
    }

    .TodoWidgetTemplate__nav {
        @apply tw-p-2;
    }

    .TodoWidgetTemplate__content {
        /*68px is the height of the nav*/
        height: calc(100% - 68px);
    }
</style>
