import type {Ref} from 'vue';
import {computed} from 'vue';
import type EventModel from '@/modules/human-resources/models/EventModel';
import moment from 'moment';
import OverrunsFinder from '@/modules/human-resources/utils/planning/OverrunsFinder';
import type OverrunEvent from '@/modules/human-resources/utils/planning/OverrunEvent';

export default class MakeOverrunSegments {
    private readonly event: Ref<EventModel>;
    private readonly events: Ref<EventModel[]>;

    public constructor(event: Ref<EventModel>, events: Ref<EventModel[]>) {
        this.event = event;
        this.events = events;
    }

    public get segments() {
        return computed(() => {
            return this.getOverrunEvents().map(overrun => {
                return MakeOverrunSegments.getStyle(overrun, this.event.value.startedAt, this.event.value.endedAt);
            });
        });
    }

    private static toUnix(date: string) {
        return moment(date).unix();
    }

    private static getStartTimeAsPercentage(overrun: OverrunEvent, startedAt: string, endedAt: string) {
        return (
            ((overrun.startedAt - MakeOverrunSegments.toUnix(startedAt)) * 100) /
            (MakeOverrunSegments.toUnix(endedAt) - MakeOverrunSegments.toUnix(startedAt))
        );
    }

    private static getEndTimeAsPercentage(overrun: OverrunEvent, startedAt, endedAt) {
        return (
            100 -
            ((overrun.endedAt - MakeOverrunSegments.toUnix(startedAt)) * 100) /
                (MakeOverrunSegments.toUnix(endedAt) - MakeOverrunSegments.toUnix(startedAt))
        );
    }

    private static getStyle(overrun: OverrunEvent, startedAt, endedAt) {
        return {
            backgroundColor: 'rgba(255, 255, 255, .2)',
            left: MakeOverrunSegments.getStartTimeAsPercentage(overrun, startedAt, endedAt) + '%',
            right: MakeOverrunSegments.getEndTimeAsPercentage(overrun, startedAt, endedAt) + '%',
        };
    }

    private getOverrunEvents() {
        return new OverrunsFinder(this.event.value, this.events.value).getOverruns();
    }
}
