import {Epoch, Model} from '@meekohq/lumos';
import TransactionStatusValue from '@/modules/cashier/transaction/domain/TransactionStatusValue';
import TenantModel from '@/modules/cashier/models/TenantModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import PaymentMethodModel from '@/modules/cashier/models/PaymentMethodModel';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import TransactionTypeValue from '@/modules/cashier/transaction/domain/TransactionTypeValue';

export default class TransactionModel extends Model {
    public type = 'cashier/transactions';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        customer_id: string | undefined;
        currency_id: string | undefined;
        payment_method_id: string | null;
        date: string | undefined;
        amount: number | undefined;
        status: TransactionStatusValue | undefined;
        reference: string | null;
        description: string | null;
        failure_date: string | null;
        failure_code: string | null;
        failure_message: string | null;
        type: TransactionTypeValue | undefined;
    } = {
        id: this.uuid(),
        tenant_id: undefined,
        customer_id: undefined,
        currency_id: undefined,
        payment_method_id: null,
        date: undefined,
        amount: undefined,
        status: undefined,
        reference: null,
        description: null,
        failure_date: null,
        failure_code: null,
        failure_message: null,
        type: undefined,
    };

    public computed: {
        distributed_amount: number;
        remaining_to_distribute_amount: number;
        currency_iso_code: string;
    } = {
        distributed_amount: 0,
        remaining_to_distribute_amount: 0,
        currency_iso_code: '',
    };

    get paymentsRelation() {
        return this.isDebit ? this.refunds().value() : this.payments().value();
    }

    get allocations() {
        return this.isDebit ? this.allocationsAsDestination().value() : this.allocationsAsSource().value();
    }

    get isDebit() {
        return this.attributes.type === TransactionTypeValue.debit;
    }

    get isCredit() {
        return this.attributes.type === TransactionTypeValue.credit;
    }

    get isPending() {
        return this.attributes.status === TransactionStatusValue.pending;
    }

    get isFailed() {
        return this.attributes.status === TransactionStatusValue.failed;
    }

    get isSucceeded() {
        return this.attributes.status === TransactionStatusValue.succeeded;
    }

    get date(): Epoch | undefined {
        if (!this.attributes.date) {
            return undefined;
        }

        return Epoch.fromISOString(this.attributes.date);
    }

    tenant() {
        return this.belongsTo('tenant', TenantModel, 'tenant_id');
    }

    customer() {
        return this.belongsTo('customer', CustomerModel, 'customer_id');
    }

    currency() {
        return this.belongsTo('currency', CurrencyModel, 'currency_id');
    }

    paymentMethod() {
        return this.belongsTo('paymentMethod', PaymentMethodModel, 'payment_method_id');
    }

    /**
     * Allocations from this transaction to other resources.
     */
    allocationsAsSource() {
        return this.morphMany('allocationsAsSource', AllocationModel, 'source');
    }

    /**
     * Allocations from other resources to this transaction.
     */
    allocationsAsDestination() {
        return this.morphMany('allocationsAsDestination', AllocationModel, 'destination');
    }

    payments() {
        return this.morphToMany(
            'payments',
            PaymentModel,
            AllocationModel,
            'source',
            'source_id',
            'destination_id'
        ).wherePivot('destination_type', new PaymentModel().getType());
    }

    refunds() {
        return this.morphToMany(
            'refunds',
            PaymentModel,
            AllocationModel,
            'destination',
            'destination_id',
            'source_id'
        ).wherePivot('source_type', new PaymentModel().getType());
    }
}
