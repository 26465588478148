<template>
    <footer class="MagicIndexPaginator">
        <CText
            class="MagicIndexPaginator__result-count"
            variant="gray"
        >
            {{ __('common:result_with_count', {count: paginator.total()}) }}
        </CText>
        <MPagination
            v-if="paginator.lastPage() > 1"
            :paginator="paginator"
            @navigate="$emit('change', $event)"
        />
    </footer>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import type {LengthAwarePaginator} from '@meekohq/lumos';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';

    export default {
        components: {MPagination},
        props: {
            paginator: {
                type: Object as PropType<LengthAwarePaginator<unknown>>,
                required: true,
            },
        },
    };
</script>

<style scoped>
    .MagicIndexPaginator {
        @apply tw-relative tw-flex tw-min-h-[56px] tw-items-center tw-justify-center tw-py-3;
    }

    .MagicIndexPaginator__result-count {
        @apply tw-hidden tw-font-display;
        @media screen(lg) {
            @apply tw-absolute tw-left-3 tw-block;
        }
    }
</style>
