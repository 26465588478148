<template>
    <CButtonGroup class="FeesInput">
        <MInput
            v-model="fee.name"
            :placeholder="__('billing_setting:fees_dots')"
            type="text"
        />
        <MPopover v-if="fee.period !== 'month'">
            <MButton
                class="tw-h-full"
                variant="gray"
            >
                <div class="FeesInput__icon-container">
                    <FontAwesomeIcon
                        class="tw-px-1"
                        icon="fa-solid fa-cog"
                    />
                </div>
            </MButton>
            <template #content>
                <div>
                    <MHeading
                        class="tw-mb-4"
                        level="h3"
                    >
                        {{ __('common:settings') }}
                    </MHeading>
                    <CForm @submit.prevent>
                        <CFormGroup>
                            <CLabel>
                                {{ __('billing_setting:include_in_deposit') }}
                            </CLabel>
                            <CFormSelect
                                v-model="fee.in_deposit"
                                :options="[
                                    {value: true, text: __('common:yes')},
                                    {value: false, text: __('common:no')},
                                ]"
                                text-path="text"
                                value-path="value"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>
                                {{ __('billing_setting:record_it') }}
                            </CLabel>
                            <CFormSelect
                                v-model="fee.smoothing"
                                :options="[
                                    {value: 'none', text: __('billing_setting:at_actual_cost')},
                                    {value: 'pack', text: __('billing_setting:flat_rate_without_smoothing')},
                                    {value: 'year', text: __('billing_setting:flat_rate_with_smoothing')},
                                ]"
                                text-path="text"
                                value-path="value"
                            />
                        </CFormGroup>
                    </CForm>
                </div>
            </template>
        </MPopover>
        <MButton
            variant="danger"
            @click="$emit('removeFees')"
        >
            <div class="FeesInput__icon-container">
                <FontAwesomeIcon
                    class="tw-px-1"
                    icon="fa-solid fa-times"
                />
            </div>
        </MButton>
    </CButtonGroup>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            fee: {
                type: Object,
                required: true,
            },
        },
    });
</script>

<style scoped>
    .FeesInput {
        @apply tw-w-full;
    }

    .FeesInput__icon-container {
        @apply tw-flex tw-items-center tw-justify-center;
    }
</style>
