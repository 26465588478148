<template>
    <transition
        mode="out-in"
        name="fade"
    >
        <div>
            <div>
                <div class="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-between tw-gap-2">
                    <MHeading>
                        {{ __('common:the_contract_other') }}
                    </MHeading>
                    <MButton
                        v-if="$can('create', 'kids_contracts') && $can('update', 'kids_contracts')"
                        icon-plus
                        :label="__('common:add_contract_button')"
                        variant="primary"
                        @click="newContract"
                    />
                </div>
                <div v-if="currentContracts.length">
                    <template v-if="kid.contracts.length > 1">
                        <div class="tw-text-sm tw-font-semibold tw-uppercase tw-tracking-wide tw-text-gray-500">
                            {{ __('common:in_progress') }}
                        </div>
                        <hr class="hr tw-mb-2 tw-mt-1" />
                    </template>
                    <div
                        v-for="(contract, i) in currentContracts"
                        :key="'current_contract' + i"
                        class="contract tw-flex tw-flex-col tw-justify-between tw-space-x-0 tw-space-y-3 xl:tw-flex-row xl:tw-space-x-40 xl:tw-space-y-0"
                    >
                        <div class="tw-flex tw-min-w-0 tw-justify-between">
                            <div class="tw-overflow-hidden">
                                <div class="tw-flex tw-flex-col tw-items-start sm:tw-flex-row sm:tw-items-center">
                                    <div class="tw-flex tw-flex-row tw-space-x-2 sm:tw-mr-2">
                                        <CBadge
                                            v-if="contract.no"
                                            :variant="computedContractTypeColor(contract, 'badge')"
                                        >
                                            {{ contract.no }}
                                            <span
                                                v-if="contract.draft || contract.broked_at"
                                                :class="computedContractTypeColor(contract, 'separator')"
                                            >
                                                {{ contract.draft ? __('common:draft') : __('common:broken') }}
                                            </span>
                                        </CBadge>
                                    </div>
                                    <span
                                        class="tw-font-grandhotel tw-text-3xl hover:tw-cursor-pointer hover:tw-text-blue-500 hover:tw-underline"
                                        :class="computedContractTypeColor(contract, 'text')"
                                        @click="showContract(contract)"
                                    >
                                        {{
                                            contract.type === 'recurrent'
                                                ? __('family_contract:regular_contract')
                                                : __('family_contract:occasional_contract')
                                        }}
                                    </span>
                                </div>
                                <CFormErrorMessage
                                    v-if="!contract.first_family_member_id && !contract.second_family_member_id"
                                >
                                    {{ __('family_contract:no_parent_in_contract') }}
                                </CFormErrorMessage>
                                <div
                                    v-if="contract.note"
                                    class="tw-flex tw-flex-col"
                                >
                                    <CTooltip placement="bottom-start">
                                        <p class="tw-truncate tw-text-gray-400">
                                            {{ __('common:note_colon') }}
                                            <span class="tw-italic">{{ contract.note }}</span>
                                        </p>
                                        <template #content>
                                            {{ contract.note }}
                                        </template>
                                    </CTooltip>
                                </div>
                            </div>
                            <MButton
                                class="tw-ml-2 tw-flex tw-items-center tw-justify-center xl:tw-hidden"
                                round
                                variant="primary"
                                zoom
                                @click="showContract(contract)"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-pencil" />
                            </MButton>
                        </div>
                        <div
                            class="tw-flex tw-flex-col tw-justify-between tw-space-x-0 tw-space-y-3 xl:tw-flex-row xl:tw-space-x-3 xl:tw-space-y-0"
                        >
                            <div
                                class="tw-flex tw-w-80 tw-flex-col tw-items-start tw-justify-start tw-space-x-0 tw-space-y-0.5 xl:tw-flex-row xl:tw-items-center xl:tw-space-x-6 xl:tw-space-y-0"
                            >
                                <dl
                                    v-if="contract.started_at"
                                    class="tw-text-lg xl:tw-text-center"
                                >
                                    <dt
                                        class="tw-mr-1 tw-inline-block tw-font-normal tw-text-gray-400 xl:tw-mr-0 xl:tw-block"
                                    >
                                        {{ __('common:beginning') }}
                                    </dt>
                                    <dd class="tw-inline-block tw-text-gray-600">
                                        {{
                                            parse(contract.started_at, 'yyyy-MM-dd').toLocaleString(
                                                Epoch.presets.DATE_SHORT
                                            )
                                        }}
                                    </dd>
                                </dl>
                                <dl class="tw-text-left tw-text-lg xl:tw-text-center">
                                    <dt
                                        class="tw-mr-1 tw-inline-block tw-font-normal tw-text-gray-400 xl:tw-mr-0 xl:tw-block"
                                    >
                                        {{ __('common:end') }}
                                    </dt>
                                    <dd class="tw-inline-block tw-text-gray-600">
                                        <template v-if="contract.ended_at">
                                            {{
                                                parse(contract.ended_at, 'yyyy-MM-dd').toLocaleString(
                                                    Epoch.presets.DATE_SHORT
                                                )
                                            }}
                                        </template>
                                        <template v-else>
                                            {{ __('common:unspecified') }}
                                        </template>
                                    </dd>
                                </dl>
                                <dl
                                    v-if="contract.broked_at"
                                    class="tw-text-left tw-text-lg xl:tw-text-center"
                                >
                                    <dt
                                        class="tw-mr-1 tw-inline-block tw-font-normal tw-text-gray-400 xl:tw-mr-0 xl:tw-block"
                                    >
                                        {{ __('common:broken') }}
                                    </dt>
                                    <dd class="tw-inline-block tw-text-gray-600">
                                        <template v-if="contract.broked_at">
                                            {{
                                                parse(contract.broked_at, 'yyyy-MM-dd').toLocaleString(
                                                    Epoch.presets.DATE_SHORT
                                                )
                                            }}
                                        </template>
                                        <template v-else>
                                            {{ __('family_contract:contract_no_broken') }}
                                        </template>
                                    </dd>
                                </dl>
                            </div>
                            <div class="tw-flex tw-w-44 tw-justify-start xl:tw-justify-end">
                                <div class="tw-flex tw-items-center">
                                    <MTooltip
                                        v-if="$can('create', 'kids_contracts')"
                                        :label="__('common:actions.duplicate')"
                                    >
                                        <MButton
                                            class="tw-mr-2"
                                            round
                                            variant="info"
                                            @click="showContract(contract, true)"
                                        >
                                            <FontAwesomeIcon
                                                fixed-width
                                                icon="fa-solid fa-copy"
                                            />
                                        </MButton>
                                    </MTooltip>
                                    <template
                                        v-if="
                                            !contract.broked_at &&
                                            !contract.draft &&
                                            (contract.family_order_id || contract.company_order_id) &&
                                            $can('create', 'invoices')
                                        "
                                    >
                                        <MTooltip
                                            :label="
                                                contract.creatingInvoices
                                                    ? __('common:creation_in_progress_dots')
                                                    : __('family_contract:create_invoices')
                                            "
                                        >
                                            <MButton
                                                class="tw-mr-2"
                                                :disabled="contract.creatingInvoices"
                                                round
                                                variant="orange"
                                                @click="generateInvoices(contract)"
                                            >
                                                <FontAwesomeIcon
                                                    v-if="contract.creatingInvoices"
                                                    fixed-width
                                                    icon="fa-solid fa-circle-notch"
                                                    spin
                                                />
                                                <FontAwesomeIcon
                                                    v-else
                                                    fixed-width
                                                    icon="fa-solid fa-file-invoice"
                                                />
                                            </MButton>
                                        </MTooltip>
                                    </template>
                                    <MTooltip
                                        v-if="
                                            !contract.broked_at && !contract.draft && $can('update', 'kids_contracts')
                                        "
                                        :label="__('common:actions.break')"
                                    >
                                        <MButton
                                            class="tw-mr-2"
                                            round
                                            @click="modalBroke(contract)"
                                        >
                                            <FontAwesomeIcon
                                                fixed-width
                                                icon="fa-solid fa-page-break"
                                            />
                                        </MButton>
                                    </MTooltip>
                                    <MTooltip
                                        v-if="$can('delete', 'kids_contracts')"
                                        :label="__('common:actions.delete')"
                                    >
                                        <MButton
                                            round
                                            variant="danger"
                                            @click="remove(contract)"
                                        >
                                            <FontAwesomeIcon
                                                fixed-width
                                                icon="fa-solid fa-trash"
                                            />
                                        </MButton>
                                    </MTooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="currentContracts.length && otherContracts.length">
                    <div class="tw-mt-10 tw-text-sm tw-font-semibold tw-uppercase tw-tracking-wide tw-text-gray-500">
                        {{ __('family_contract:other_contracts') }}
                    </div>
                    <hr class="hr tw-mb-2 tw-mt-1" />
                </template>
                <div
                    v-for="(contract, i) in otherContracts"
                    :key="'contract' + i"
                    class="contract tw-flex tw-flex-col tw-justify-between tw-space-x-0 tw-space-y-3 xl:tw-flex-row xl:tw-space-x-40 xl:tw-space-y-0"
                >
                    <div class="tw-flex tw-min-w-0 tw-justify-between">
                        <div class="tw-overflow-hidden">
                            <div class="tw-flex tw-flex-col tw-items-start sm:tw-flex-row sm:tw-items-center">
                                <div class="tw-flex tw-flex-row tw-space-x-2 sm:tw-mr-2">
                                    <CBadge
                                        v-if="contract.no"
                                        :variant="computedContractTypeColor(contract, 'badge')"
                                    >
                                        {{ contract.no }}
                                        <span
                                            v-if="contract.draft || contract.broked_at"
                                            :class="computedContractTypeColor(contract, 'separator')"
                                        >
                                            {{ contract.draft ? __('common:draft') : __('common:broken') }}
                                        </span>
                                    </CBadge>
                                </div>
                                <span
                                    class="tw-font-grandhotel tw-text-3xl hover:tw-cursor-pointer hover:tw-text-blue-500 hover:tw-underline"
                                    :class="computedContractTypeColor(contract, 'text')"
                                    @click="showContract(contract)"
                                >
                                    {{
                                        contract.type === 'recurrent'
                                            ? __('family_contract:regular_contract')
                                            : __('family_contract:occasional_contract')
                                    }}
                                </span>
                            </div>
                            <CFormErrorMessage
                                v-if="!contract.first_family_member_id && !contract.second_family_member_id"
                            >
                                {{ __('family_contract:no_parent_in_contract') }}
                            </CFormErrorMessage>
                            <div
                                v-if="contract.note"
                                class="tw-flex tw-flex-col"
                            >
                                <CTooltip placement="bottom-start">
                                    <p class="tw-truncate tw-text-gray-400">
                                        {{ __('common:note_colon') }}
                                        <span class="tw-italic">{{ contract.note }}</span>
                                    </p>
                                    <template #content>
                                        {{ contract.note }}
                                    </template>
                                </CTooltip>
                            </div>
                        </div>
                        <MButton
                            class="tw-ml-2 tw-flex tw-items-center tw-justify-center xl:tw-hidden"
                            round
                            variant="primary"
                            zoom
                            @click="showContract(contract)"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-pencil" />
                        </MButton>
                    </div>
                    <div
                        class="tw-flex tw-flex-col tw-justify-between tw-space-x-0 tw-space-y-3 xl:tw-flex-row xl:tw-space-x-3 xl:tw-space-y-0"
                    >
                        <div
                            class="tw-flex tw-w-80 tw-flex-col tw-items-start tw-justify-start tw-space-x-0 tw-space-y-0.5 xl:tw-flex-row xl:tw-items-center xl:tw-space-x-6 xl:tw-space-y-0"
                        >
                            <dl
                                v-if="contract.started_at"
                                class="tw-text-left tw-text-lg xl:tw-text-center"
                            >
                                <dt
                                    class="tw-mr-1 tw-inline-block tw-font-normal tw-text-gray-400 xl:tw-mr-0 xl:tw-block"
                                >
                                    {{ __('common:beginning') }}
                                </dt>
                                <dd class="tw-inline-block tw-text-gray-600">
                                    {{
                                        parse(contract.started_at, 'yyyy-MM-dd').toLocaleString(
                                            Epoch.presets.DATE_SHORT
                                        )
                                    }}
                                </dd>
                            </dl>
                            <dl class="tw-text-left tw-text-lg xl:tw-text-center">
                                <dt
                                    class="tw-mr-1 tw-inline-block tw-font-normal tw-text-gray-400 xl:tw-mr-0 xl:tw-block"
                                >
                                    {{ __('common:end') }}
                                </dt>
                                <dd class="tw-inline-block tw-text-gray-600">
                                    <template v-if="contract.ended_at">
                                        {{
                                            parse(contract.ended_at, 'yyyy-MM-dd').toLocaleString(
                                                Epoch.presets.DATE_SHORT
                                            )
                                        }}
                                    </template>
                                    <template v-else>
                                        {{ __('common:unspecified') }}
                                    </template>
                                </dd>
                            </dl>
                            <dl
                                v-if="contract.broked_at"
                                class="tw-text-left tw-text-lg xl:tw-text-center"
                            >
                                <dt
                                    class="tw-mr-1 tw-inline-block tw-font-normal tw-text-gray-400 xl:tw-mr-0 xl:tw-block"
                                >
                                    {{ __('common:broken') }}
                                </dt>
                                <dd class="tw-inline-block tw-text-gray-600">
                                    <template v-if="contract.broked_at">
                                        {{
                                            parse(contract.broked_at, 'yyyy-MM-dd').toLocaleString(
                                                Epoch.presets.DATE_SHORT
                                            )
                                        }}
                                    </template>
                                    <template v-else>
                                        {{ __('family_contract:contract_no_broken') }}
                                    </template>
                                </dd>
                            </dl>
                        </div>
                        <div class="tw-flex tw-w-44 tw-justify-start xl:tw-justify-end">
                            <div class="tw-flex tw-items-center">
                                <MTooltip
                                    v-if="$can('create', 'kids_contracts')"
                                    :label="__('common:actions.duplicate')"
                                >
                                    <MButton
                                        class="tw-mr-2"
                                        round
                                        variant="info"
                                        @click="showContract(contract, true)"
                                    >
                                        <FontAwesomeIcon
                                            fixed-width
                                            icon="fa-solid fa-copy"
                                        />
                                    </MButton>
                                </MTooltip>
                                <template
                                    v-if="
                                        !contract.broked_at &&
                                        !contract.draft &&
                                        (contract.family_order_id || contract.company_order_id) &&
                                        $can('create', 'invoices')
                                    "
                                >
                                    <MTooltip
                                        :label="
                                            contract.creatingInvoices
                                                ? __('common:creation_in_progress_dots')
                                                : __('family_contract:create_invoices')
                                        "
                                    >
                                        <MButton
                                            class="tw-mr-2"
                                            :disabled="contract.creatingInvoices"
                                            round
                                            variant="orange"
                                            @click="generateInvoices(contract)"
                                        >
                                            <FontAwesomeIcon
                                                v-if="contract.creatingInvoices"
                                                fixed-width
                                                icon="fa-solid fa-circle-notch"
                                                spin
                                            />
                                            <FontAwesomeIcon
                                                v-else
                                                fixed-width
                                                icon="fa-solid fa-file-invoice"
                                            />
                                        </MButton>
                                    </MTooltip>
                                </template>
                                <MTooltip
                                    v-if="!contract.broked_at && !contract.draft && $can('update', 'kids_contracts')"
                                    :label="__('common:actions.break')"
                                >
                                    <MButton
                                        class="tw-mr-2"
                                        round
                                        @click="modalBroke(contract)"
                                    >
                                        <FontAwesomeIcon
                                            fixed-width
                                            icon="fa-solid fa-page-break"
                                        />
                                    </MButton>
                                </MTooltip>
                                <MTooltip
                                    v-if="$can('delete', 'kids_contracts')"
                                    :label="__('common:actions.delete')"
                                >
                                    <MButton
                                        round
                                        variant="danger"
                                        @click="remove(contract)"
                                    >
                                        <FontAwesomeIcon
                                            fixed-width
                                            icon="fa-solid fa-trash"
                                        />
                                    </MButton>
                                </MTooltip>
                            </div>
                        </div>
                    </div>
                </div>

                <h1
                    v-if="!kid.contracts.length"
                    class="h1 tw-text-center tw-font-grandhotel"
                >
                    {{ __('common:empty_contract_index') }}
                </h1>
            </div>
        </div>
    </transition>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _orderBy from 'lodash-es/orderBy';
    import Vue, {toRef} from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import useModal from '@/modules/app/composables/useModal';
    import ContractStoreModal from '@/modules/family/components/contract/Contract/ContractStoreModal.vue';
    import ContractEditModal from '@/modules/family/components/contract/Contract/ContractEditModal.vue';
    import ContractBrokeModal from '@/modules/family/components/contract/Contract/ContractBrokeModal.vue';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import defaultConfig from '@/modules/legacy/contents/defaultConfig';
    import __ from '@/modules/app/utils/i18n-facade';
    import {Epoch, MqlOperation, now} from '@meekohq/lumos';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import {EventBus} from '@/modules/legacy/utils/bus';
    import {datadogRum} from '@datadog/browser-rum';

    export default {
        mixins: [nursery],
        props: ['kid', 'nursery', 'user'],
        data() {
            return {
                selectedContract: {},
                disabled: false,
                configs: [],
                loading: false,
                Epoch,
                contractBeforeEdit: {},
            };
        },
        computed: {
            currentContracts() {
                const contracts = this.kid.contracts.filter(item => {
                    const endedAt = item.broked_at ? item.broked_at : item.ended_at;
                    const startDate = Epoch.parse(item.started_at, 'yyyy-MM-dd');
                    const endDate = Epoch.parse(endedAt, 'yyyy-MM-dd');

                    return now().between(startDate, endDate) && !item.draft;
                });

                return _orderBy(contracts, 'started_at', 'desc');
            },
            otherContracts() {
                const contracts = this.kid.contracts.filter(item => {
                    const endedAt = item.broked_at ? item.broked_at : item.ended_at;
                    const startDate = Epoch.parse(item.started_at, 'yyyy-MM-dd');
                    const endDate = Epoch.parse(endedAt, 'yyyy-MM-dd');

                    return !now().between(startDate, endDate) || item.draft;
                });

                return _orderBy(contracts, 'started_at', 'desc');
            },
        },
        mounted() {
            datadogRum.startSessionReplayRecording();
            this.configs = _cloneDeep(this.nursery.billingConfigs);
            this.configs.push(_cloneDeep(defaultConfig));
        },
        methods: {
            parse: useEpoch().parse,

            showContract(contract, duplicate) {
                contract.draft ? (this.disabled = false) : (this.disabled = true);

                !contract.cafpro_resources
                    ? (contract.cafpro_resources = contract.first_salary + contract.second_salary)
                    : null;

                this.selectedContract = _cloneDeep(contract);

                // Set config to allow download docuement
                if (!this.selectedContract.config) {
                    const newConfig = _cloneDeep(this.configs[0]);
                    newConfig.id ? (newConfig.original_config_id = newConfig.id) : null;
                    newConfig.id = null;
                    Vue.set(this.selectedContract, 'config', newConfig);
                }

                this.contractBeforeEdit = _cloneDeep(this.selectedContract);

                this.selectedContract.index = this.kid.contracts.indexOf(contract);
                if (duplicate) {
                    this.selectedContract.id = null;
                    this.selectedContract.no = null;
                    this.selectedContract.family_order_id = null;
                    this.selectedContract.company_order_id = null;
                    this.selectedContract.registration_id = null;
                    this.selectedContract.draft = true;
                    this.selectedContract.broked_at = false;
                    this.selectedContract.exceptions = [];
                    this.selectedContract.plannings = this.selectedContract.plannings.filter(
                        item => item.type !== 'adaptation'
                    );
                    this.selectedContract.config ? (this.selectedContract.config.contract_id = null) : null;
                    this.disabled = false;

                    this.openContractStoreModal();

                    return;
                }

                this.openContractEditModal();
            },

            newContract() {
                const newConfig = _cloneDeep(this.configs[0]);
                newConfig.id ? (newConfig.original_config_id = newConfig.id) : null;
                newConfig.id = null;

                this.selectedContract = {
                    id: null,
                    started_at: Epoch.now().toFormat('yyyy-MM-dd'),
                    ended_at: Epoch.now().addMonths(1).toFormat('yyyy-MM-dd'),
                    recurrent_weeks: 1,
                    plannings: [],
                    exceptions: [],
                    type: 'recurrent',
                    draft: false,
                    first_family_member_id: null,
                    second_family_member_id: null,
                    dependent_children: null,
                    handicapped_children: null,
                    first_salary: null,
                    first_pension: null,
                    first_other_income: null,
                    second_salary: null,
                    second_pension: null,
                    second_other_income: null,
                    cafpro_resources: null,
                    contract_template_id: null,
                    company: false,
                    company_address: null,
                    company_email: null,
                    company_total_amount: 0,
                    company_cycle: 0,
                    company_invoices: null,
                    config: newConfig,
                };

                this.openContractStoreModal();
            },

            openContractStoreModal() {
                useModal({
                    component: ContractStoreModal,
                    props: {
                        currentContract: this.selectedContract,
                        kid: this.kid,
                        nursery: this.nursery,
                        user: this.user,
                    },
                }).show();
            },

            openContractEditModal() {
                useModal({
                    component: ContractEditModal,
                    props: {
                        contract: this.selectedContract,
                        disabled: this.disabled,
                        kid: this.kid,
                        nursery: this.nursery,
                        user: this.user,
                    },
                    listeners: () => ({
                        closed: () => {
                            this.shouldDisplayOccasionalPlanningsSyncModal(this.selectedContract);
                        },
                    }),
                }).show();
            },

            generateInvoices(contract) {
                Vue.set(contract, 'creatingInvoices', true);
                useApi()
                    .legacy.post(route('kid.contracts.generateInvoices'), {
                        contracts: [contract.id],
                    })
                    .then(() => {
                        useNotification().success(__('family_contract:invoices_created_successfully'));
                        Vue.set(contract, 'creatingInvoices', false);
                    })
                    .catch(error => {
                        Vue.set(contract, 'creatingInvoices', false);
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            modalBroke(contract) {
                this.selectedContract = Object.assign({}, contract);
                if (!contract.broked_at) {
                    this.selectedContract.broked_at = Epoch.now().toFormat('yyyy-MM-dd');
                }
                this.selectedContract.index = this.kid.contracts.indexOf(contract);

                useModal({
                    component: ContractBrokeModal,
                    props: {
                        selectedContract: this.selectedContract,
                        loading: toRef(this, 'loading'),
                    },
                    listeners: modal => ({
                        broke: () => {
                            this.loading = true;
                            useApi()
                                .legacy.put(
                                    route('kid.contracts.update', {
                                        contract: this.selectedContract.id,
                                    }),
                                    {
                                        broked_at: this.selectedContract.broked_at,
                                    }
                                )
                                .then(response => {
                                    useNotification().success(__('family_contract:contract_brooken_successfully'));
                                    this.kid.contracts[this.selectedContract.index].broked_at = response.data.broked_at;
                                    modal.hide();
                                })
                                .catch(error => {
                                    if (error?.response?.status === 422) {
                                        _forEach(error.response.data.errors, function (value) {
                                            useNotification().error(_head(value));
                                        });
                                    } else {
                                        useNotification().error(error);
                                    }
                                })
                                .finally(() => {
                                    this.loading = false;
                                });
                        },
                    }),
                }).show();
            },

            remove(contract) {
                useMagicModal().deleteConfirmationModal({
                    title: __('family_contract:delete_contract'),
                    text: __('family_contract:delete_contract_warning'),
                    onConfirm: () => {
                        useApi()
                            .legacy.delete(
                                route('kid.contracts.destroy', {
                                    contract: contract.id,
                                })
                            )
                            .then(() => {
                                const index = this.kid.contracts.indexOf(contract);
                                this.kid.contracts.splice(index, 1);
                                useNotification().success(__('family_contract:contract_deleted_successfully'));
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, function (value) {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            computedContractTypeColor(contract, element) {
                const draftContractStyle = {
                    badge: 'cyan',
                    separator: 'tw-border-l tw-border-cyan-950 tw-ml-1 tw-pl-1',
                    text: 'tw-text-info-500',
                };
                const disabledContractStyle = {
                    badge: 'gray',
                    separator: 'tw-border-l tw-border-gray-700 tw-ml-1 tw-pl-1',
                    text: 'tw-text-gray-600',
                };
                const defaultContractStyle = {
                    badge: 'blue',
                    text: 'tw-text-primary-500',
                };

                if (contract.draft) {
                    return draftContractStyle[element];
                }

                const endDate = Epoch.parse(contract.ended_at, 'yyyy-MM-dd');
                const isAfter = now().greaterThan(endDate);
                if (contract.broked_at || isAfter) {
                    return disabledContractStyle[element];
                }

                return defaultContractStyle[element];
            },

            shouldDisplayOccasionalPlanningsSyncModal(contract) {
                const planningHasOccasionals = contract?.plannings?.some(planning => planning.type === 'occasional');

                const contractHasHourlyRateChange = this.contractBeforeEdit?.hourly_rate !== contract?.hourly_rate;
                const configHasPriceChange =
                    this.contractBeforeEdit?.config.pricing?.amount !== contract?.config.pricing?.amount;
                const configHasOccasionalAmountChange =
                    this.contractBeforeEdit?.config.occasionals?.amount !== contract?.config.occasionals?.amount;

                if (
                    planningHasOccasionals &&
                    (contractHasHourlyRateChange || configHasPriceChange || configHasOccasionalAmountChange)
                ) {
                    this.showSyncOccasionalPlanningsModal(contract);
                }
            },

            showSyncOccasionalPlanningsModal(contract) {
                useMagicModal().confirmationModal({
                    title: __('family_contract:update_occasionals_price_question'),
                    text: __('family_contract:update_occasionals_price_info'),
                    confirmButtonText: __('family_contract:update_occasionals_price_confirm'),
                    dismissButtonText: __('family_contract:update_occasionals_price_dismiss'),
                    onConfirm: async () => {
                        try {
                            await new MqlOperation('family/kidContract/sync_occasional_plannings_price', {
                                contract_ids: [contract.id],
                            }).run();

                            useNotification().success(__('family_contract:update_occasionals_success'));
                            // Refresh the kid to update the plannings
                            EventBus.$emit('refresh-kid');
                        } catch (e) {
                            useNotification().error(__('common:errors.generic'));
                        }
                    },
                });
            },
        },
    };
</script>

<style scoped>
    .name {
        @media screen(lg) {
            width: calc(100% - 520px) !important;
        }

        a {
            color: inherit;
        }
    }

    .avatar {
        width: 50px;
        height: 50px;
    }

    .contract {
        margin: 0 -20px;
        padding: 15px 20px;

        &:not(:last-of-type) {
            border-bottom: 1px solid #ecf0f4;
        }
    }
</style>
