<template>
    <CFormGroup>
        <CLabel>{{ __('common:actions.send_selection_to_colon') }}</CLabel>
        <CButtonGroup class="TaxCertificates__send-double-choice">
            <MButton
                :variant="!isSentToSingleEmail ? 'primary' : 'light'"
                @click="$emit('sendToEachParents')"
            >
                {{ __('common:each_parent') }}
            </MButton>
            <MButton
                :variant="isSentToSingleEmail ? 'primary' : 'light'"
                @click="$emit('sendToSingleEmail')"
            >
                {{ __('common:specific_email') }}
            </MButton>
        </CButtonGroup>
        <CInputGroup
            v-if="isSentToSingleEmail"
            class="TaxCertificates__send-by-email"
        >
            <CInputAddon>
                <FontAwesomeIcon icon="fa-solid fa-envelope" />
            </CInputAddon>
            <CInput
                v-model="emailModel"
                :placeholder="__('common:email')"
            />
        </CInputGroup>
    </CFormGroup>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            isSentToSingleEmail: {
                type: Boolean,
                required: true,
            },
            emailModel: {
                type: String,
                required: true,
            },
        },
    });
</script>

<style scoped>
    .TaxCertificates__send-double-choice {
        @apply tw-w-full;
    }

    .TaxCertificates__send-by-email {
        @apply tw-mt-3 tw-w-full lg:tw-w-1/3;
    }
</style>
