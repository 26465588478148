import type {SaveAllocationResult} from '@/modules/cashier/payment/application/SaveAllocationAggregateResponseModel';
import type {DetachCreditNoteToInvoiceRequest} from '@/modules/cashier/payment/application/DetachCreditNoteToInvoiceRequest';
import {Binding} from '@meekohq/lumos';

export const DetachCreditNoteToInvoiceUseCaseBinding = new Binding<DetachCreditNoteToInvoiceUseCase>();

/**
 * Use case to save a payment aggregate.
 */
export interface DetachCreditNoteToInvoiceUseCase {
    /**
     * @throws {AmountToAllocateIsGreaterThanDestinationRemainingError}
     */
    detach(detachCreditNoteRequest: DetachCreditNoteToInvoiceRequest): Promise<SaveAllocationResult>;
}
