<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import {inject} from 'vue';
    import {keyMCalendarState} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
    import MCalendarTime from '@/modules/meeko-ui/components/MCalendar/MCalendarTime.vue';
    import type {
        DateTimeModifierSingle,
        DateTimeModifiersPeriod,
    } from '@/modules/meeko-ui/composables/useDateTimeModifiers';
    import useDateTimeModifiers from '@/modules/meeko-ui/composables/useDateTimeModifiers';

    const state = inject(keyMCalendarState);
    if (!state) {
        throw new Error('keyMCalendarNavigation must be provided');
    }

    const {internalValue, setDate, isPeriod, mode, allowUndefined, modifiers} = state;

    function handleFromTimeInput(value: Epoch | undefined) {
        if (isPeriod) {
            const periodModifiers = modifiers as DateTimeModifiersPeriod;
            const {modifySingle: modifyFrom} = useDateTimeModifiers(periodModifiers?.from as DateTimeModifierSingle);
            const {modifySingle: modifyTo} = useDateTimeModifiers(periodModifiers?.to as DateTimeModifierSingle);

            if (internalValue.value.to && value?.greaterThan(internalValue.value.to)) {
                setDate({from: modifyFrom(value), to: modifyTo(value)});

                return;
            }

            setDate({from: modifyFrom(value), to: internalValue.value.to});

            return;
        }

        const {modifySingle} = useDateTimeModifiers(modifiers as DateTimeModifierSingle);
        setDate(modifySingle(value));
    }

    function handleToTimeInput(value: Epoch | undefined) {
        const periodModifiers = modifiers as DateTimeModifiersPeriod;
        const {modifySingle: modifyFrom} = useDateTimeModifiers(periodModifiers?.from as DateTimeModifierSingle);
        const {modifySingle: modifyTo} = useDateTimeModifiers(periodModifiers?.to as DateTimeModifierSingle);

        if (internalValue.value.from && value?.lessThan(internalValue.value.from)) {
            setDate({from: modifyFrom(value), to: modifyTo(value)});

            return;
        }

        setDate({from: internalValue.value.from, to: modifyTo(value)});
    }
</script>

<template>
    <div
        v-if="mode === 'time' || internalValue.from || internalValue.to"
        class="MCalendarTimes"
        :class="{
            'MCalendarTimes-horizontal': mode === 'time',
        }"
    >
        <div
            v-if="mode === 'time' || internalValue.from"
            class="MCalendarTimes__box"
            :class="{
                'MCalendarTimes__box-horizontal': mode === 'time',
            }"
        >
            <MHeading
                v-if="mode !== 'time'"
                class="MCalendarTimes__box-heading"
                level="h4"
            >
                {{ internalValue.from.toLocaleString(Epoch.presets.DATE_MED) }}
            </MHeading>
            <MCalendarTime
                :allow-undefined="mode === 'time' && allowUndefined"
                :only-time="false"
                :value="internalValue.from"
                @input="handleFromTimeInput"
            />
        </div>
        <FontAwesomeIcon
            v-if="isPeriod && mode === 'time'"
            class="MCalendarTimes__arrow"
            icon="fa-solid fa-arrow-right"
        />
        <div
            v-if="isPeriod && (mode === 'time' || internalValue.to)"
            class="MCalendarTimes__box"
            :class="{
                'MCalendarTimes__box-horizontal': mode === 'time',
            }"
        >
            <MHeading
                v-if="mode !== 'time'"
                class="MCalendarTimes__box-heading"
                level="h4"
            >
                {{ internalValue.to.toLocaleString(Epoch.presets.DATE_MED) }}
            </MHeading>
            <MCalendarTime
                :allow-undefined="mode === 'time' && allowUndefined"
                :only-time="false"
                :value="internalValue.to"
                @input="handleToTimeInput"
            />
        </div>
    </div>
</template>

<style scoped>
    .MCalendarTimes {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .MCalendarTimes-horizontal {
        flex-direction: row !important;
        gap: theme(gap.2) !important;
    }

    .MCalendarTimes__box {
        @apply tw-flex tw-h-1/2 tw-w-28 tw-flex-col tw-items-center tw-justify-between tw-gap-2 tw-rounded-lg tw-border tw-border-gray-200 tw-p-2;
    }

    .MCalendarTimes__box-horizontal {
        @apply tw-h-auto tw-justify-center;
    }

    .MCalendarTimes__box-heading {
        @apply tw-whitespace-nowrap tw-px-1 tw-pt-1;
    }

    .MCalendarTimes__arrow {
        @apply tw-my-auto tw-text-primary-950;
    }
</style>
