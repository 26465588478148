import {computed, ref, watch} from 'vue';
import type {MaybeRefOrGetter} from '@vueuse/core';
import {toValue} from '@vueuse/core';
import {debounce, merge} from 'lodash-es';
import type {
    LengthAwarePaginator,
    ModelAvailableAttributesType,
    QueryBuilderOrderByDirectionType,
} from '@meekohq/lumos';
import useConcurrentCallback from '@/modules/app/composables/useConcurrentCallback';
import type TagModel from '@/modules/tag/models/TagModel';
import TenantModel from '@/modules/cashier/models/TenantModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';

export interface CustomerQueryOptionsType {
    tenants: TenantModel[];
    tags: TagModel[];
    withoutTag: boolean;
    personTypes: string[];
    states: ('up-to-date' | 'pending' | 'unpaid' | 'too-much-collected')[];
    includeArchived: boolean;
    orderBy: ModelAvailableAttributesType<CustomerModel> | undefined;
    queryDirection: QueryBuilderOrderByDirectionType | undefined;
    with: string[];
    page: number;
}

export default function usePaginateCustomer(options: Partial<MaybeRefOrGetter<CustomerQueryOptionsType>>) {
    const defaultOptions: Partial<CustomerQueryOptionsType> = {
        orderBy: 'name',
        queryDirection: 'asc',
        page: 1,
    };

    // Merge the default options with the provided options
    const optionsWithDefault = computed(() => {
        return merge({}, defaultOptions, toValue(options)) as CustomerQueryOptionsType;
    });

    const paginator = ref<LengthAwarePaginator<CustomerModel>>();

    const isLoading = ref(true);

    const {resolveLastCallback} = useConcurrentCallback();

    /**
     * Debounce the paginate method to avoid multi queries.
     */
    const paginate = debounce(
        async () => {
            isLoading.value = true;

            // In case of multi paginate calls, we only want to keep the last one
            try {
                paginator.value = await resolveLastCallback(() => {
                    const query = mapOptionsToQueryBuilder(optionsWithDefault.value);

                    return query.paginate(undefined, optionsWithDefault.value.page);
                });

                isLoading.value = false;

                return paginator.value;
            } catch (e) {
                // We don't do anything here, the result is outdated
                return false;
            }
        },
        500,
        {leading: true, trailing: true}
    );

    /**
     * Watch for options changes to paginate the customers.
     */
    function watchOptions(callback?: () => void) {
        watch(
            optionsWithDefault,
            async () => {
                await paginate();
                if (callback) {
                    callback();
                }
            },
            {immediate: true, deep: true}
        );
    }

    return {
        isLoading,
        paginate,
        paginator,
        watchOptions,
    };
}

export function mapOptionsToQueryBuilder(options: CustomerQueryOptionsType) {
    const query = CustomerModel.query().whereHas(new CustomerModel().tenant(), query1 => {
        // If there are tenants, we filter by them
        if (options.tenants.length) {
            // If there are more than one tenant, we use whereIn
            if (options.tenants.length > 1) {
                query1.whereIn(
                    'id',
                    options.tenants.map(tenant => tenant.getKey())
                );
            } else {
                // Otherwise, we use where
                query1.where('id', options.tenants[0].getKey());
            }
        }
    });

    if (options.tags && options.tags.length > 0) {
        query.whereHas(new CustomerModel().tags(), query1 => {
            query1.whereIn(
                'id',
                options.tags.map(tag => tag.getKey())
            );
        });
    }

    if (options.withoutTag) {
        query.whereDoesntHave(new CustomerModel().tags());
    }

    if (options.personTypes.length) {
        if (options.personTypes.length > 1) {
            query.whereIn('person', options.personTypes);
        } else {
            query.where('person', '=', options.personTypes[0]);
        }
    }

    if (options.states.length) {
        query.where(query2 => {
            if (options.states.includes('up-to-date')) {
                query2.orWhere(query3 => {
                    query3.where('pending_payment_amount', '=', 0);
                    query3.where('upcoming_payment_amount', '=', 0);
                    query3.where('unpaid_payment_amount', '=', 0);
                    query3.where('balance_amount', '=', 0);
                });
            }

            if (options.states.includes('pending')) {
                query2.orWhere('pending_payment_amount', '>', 0);
                query2.orWhere('upcoming_payment_amount', '>', 0);
            }

            if (options.states.includes('unpaid')) {
                query2.orWhere('unpaid_payment_amount', '>', 0);
            }

            if (options.states.includes('too-much-collected')) {
                query2.orWhere('balance_amount', '<', 0);
            }
        });
    }

    if (!options.includeArchived) {
        query.whereNull('archived_at');
    }

    if (options.orderBy) {
        query.orderBy(options.orderBy as string, options.queryDirection);
    }

    if (options.with && options.with.length > 0) {
        options.with.forEach(relation => {
            if (relation === new CustomerModel().tenant().getRelationName()) {
                query.with(relation, query1 => {
                    query1.with(new TenantModel().organizations());
                });
            } else {
                query.with(relation);
            }
        });
    }

    return query;
}
