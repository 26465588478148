import {computed} from 'vue';
import useEnumerateBetweenDates from '@/modules/planning/composables/core/useEnumerateBetweenDates';
import useOrganizationOpening from '@/modules/organization/composables/useOrganizationOpening';
import type {
    AnomalyInterface,
    OccupationInterface,
    SummariesInterface,
    SupervisionInterface,
} from '@/modules/planning/components/simulation/Summary/SummaryTypes';
import type {WeekInterface} from '@/modules/planning/utils/simulation/SimulationTypes';
import type {Collection} from '@meekohq/lumos';
import {collect} from '@meekohq/lumos';
import type {Moment} from 'moment';
import moment from 'moment';
import _range from 'lodash-es/range';

export interface BaseSummariesInterface {
    occupations: BaseSummaryInterface[];
    supervisions: BaseSummaryInterface[];
}

interface BaseSummaryInterface {
    day: number;
    week: number;
    year: number;
    count: number;
    started_at: number;
    ended_at: number;
    rate: number;
    anomalies: AnomalyInterface[];
    is_same_as_previous_week: boolean;
}

export default function useSimulationSummaries() {
    // Check if organization is opened on weekend
    const openingHours = useOrganizationOpening();
    const isOpenedSaturday = computed(() => openingHours.isOpenedOn(moment().day(6)));
    const isOpenedSunday = computed(() => openingHours.isOpenedOn(moment().day(0)));

    // Get range of days by week of simulation
    const daysRange = computed(() => {
        if (isOpenedSaturday.value && isOpenedSunday.value) {
            return _range(1, 8);
        } else if (isOpenedSaturday.value || isOpenedSunday.value) {
            return _range(1, 7);
        } else {
            return _range(1, 6);
        }
    });

    function generateSimulationSummaries(summaries: BaseSummariesInterface) {
        const simulationSummaries = {};

        const {getWeeks, getYears} = useEnumerateBetweenDates();

        const occupations = collect(summaries.occupations);
        const supervisions = collect(summaries.supervisions);

        const startedAt = occupations.min('started_at');
        const endedAt = occupations.max('ended_at');

        // Get list of years in period to group by data
        const years = getYears(moment.unix(startedAt), moment.unix(endedAt));

        years.forEach(year => {
            simulationSummaries[year] = {};

            const startOfYear = moment.utc().year(year).week(1).startOf('week');
            const endOfYear = moment.utc().year(year).week(52).endOf('week');

            // Get list of weeks for specific year in period to group by data
            let startedAtWeek: Moment;
            let endedAtWeek: Moment;

            // If endedAt is after endOfYear, use endOfYear as end of year period else generate weeks to endedAt
            if (endedAt > endOfYear.unix()) {
                endedAtWeek = endOfYear;
            } else {
                endedAtWeek = moment.unix(endedAt);
            }

            // If startedAt is before startOfYear, use startOfYear as begin of year period else generate weeks from startedAt
            if (startedAt < startOfYear.unix()) {
                startedAtWeek = startOfYear;
            } else {
                startedAtWeek = moment.unix(startedAt);
            }

            const weeks = getWeeks(startedAtWeek, endedAtWeek);

            weeks.forEach(week => {
                const weekSummaries = generateWeekSummaries(week, year, occupations, supervisions, simulationSummaries);
                if (weekSummaries) {
                    simulationSummaries[year][week] = {
                        ...weekSummaries,
                    };
                }
            });
        });

        return simulationSummaries;
    }

    function generateWeekSummaries(
        week: number,
        year: number,
        occupations: Collection<BaseSummaryInterface>,
        supervisions: Collection<BaseSummaryInterface>,
        simulationSummaries: SummariesInterface
    ): WeekInterface | null {
        const summaries: WeekInterface = {
            rate: 0,
            summaries: [],
        };

        // Get week occupations and supervisions
        const weekOccupations = occupations.filter(o => o.week === week && o.year === year);
        const weekSupervisions = supervisions.filter(o => o.week === week && o.year === year);

        // Get previous week occupations and supervisions
        const previousWeekOccupations = occupations.filter(o => o.week === week - 1 && o.year === year);
        const previousWeekSupervisions = supervisions.filter(o => o.week === week - 1 && o.year === year);

        // Check if all week summaries are same as previous week
        const isSameWeekForOccupations =
            weekOccupations.filter(o => o.is_same_as_previous_week).count() === weekOccupations.count() &&
            weekOccupations.count() === previousWeekOccupations.count();
        const isSameWeekForSupervisions =
            weekSupervisions.filter(o => o.is_same_as_previous_week).count() === weekSupervisions.count() &&
            weekSupervisions.count() === previousWeekSupervisions.count();

        // Get last week key
        const lastWeek = Object.keys(simulationSummaries[year]).pop() as string;

        // If all week summaries are same as previous week, add it to same week array
        if (isSameWeekForOccupations && isSameWeekForSupervisions && lastWeek) {
            // If same week array not already exists, init it
            if (Object.getOwnPropertyDescriptor(simulationSummaries[year][lastWeek], 'sameWeeks') === undefined) {
                simulationSummaries[year][lastWeek]['sameWeeks'] = [];
            }

            simulationSummaries[year][lastWeek]['sameWeeks'].push(week);

            return null;
        }

        const weekOccupationsByDay = weekOccupations.groupBy('day');
        const weekSupervisionsByDay = weekSupervisions.groupBy('day');

        // Calculate week rate
        summaries.rate = weekOccupations.avg('rate');

        // Format week summaries by day
        daysRange.value.forEach(day => {
            summaries.summaries[day] = {
                occupations: weekOccupationsByDay.has(day)
                    ? (weekOccupationsByDay.get(day) as Collection<OccupationInterface>).all()
                    : [],
                supervisions: weekSupervisionsByDay.has(day)
                    ? (weekSupervisionsByDay.get(day) as Collection<SupervisionInterface>).all()
                    : [],
                isClosed: !weekOccupationsByDay.has(day) && !weekSupervisionsByDay.has(day),
            };
        });

        return summaries;
    }

    return {
        generateSimulationSummaries,
    };
}
